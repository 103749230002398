import { useRequest } from "@onefront/react-sdk";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { SimulationAssetSaleDto } from "../dtos/SimulationAssetSaleDto";

interface UsePostAssetSaleSimulation {
    fetch: Function;
    fetchWithParameters: (
        assetId: string | undefined,
        dataToPost: IPostData | null
    ) => SimulationAssetSaleDto;
}

export interface IPostData {
    businessId?: string;
    date: Date | null;
    disposalRate: number | null;
    operationDescription?: string | null;
    saleAmount: number | null;
    splitCapitalGain?: boolean;
}

export interface UsePostAssetSaleSimulationOptions {
    assetId: string | undefined;
    dataToPost: IPostData | null;
}

export const usePostAssetSaleSimulation = ({
    assetId,
    dataToPost
}: UsePostAssetSaleSimulationOptions): UsePostAssetSaleSimulation => {
    const customerData = useCustomerData();
    const { fetch } = useRequest({
        ...useAxiosConfigRequestParams("PostAssetSaleSimulation"),
        method: "post",
        url: `${API_URL}/v1/asset/${assetId}/simulation/sell`,
        params: omitNilProperties({
            ...useCustomerData()
        }),
        data: {
            ...dataToPost
        },
        lazy: true
    });
    const safeFetch = useCallback(() => fetch(), [fetch]);

    const fetchWithParameters = (
        assetId: string | undefined,
        dataToPost: IPostData | null
    ): SimulationAssetSaleDto => {
        return fetch({
            url: `${API_URL}/v1/asset/${assetId}/simulation/sell`,
            params: {
                ...customerData
            },
            data: {
                ...dataToPost
            }
        });
    };

    return {
        fetch: safeFetch,
        fetchWithParameters
    };
};
