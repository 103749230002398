import { useCurrentTenant, useCurrentWorkspace } from "@drift/oneplatfront";
import { faAddressCard } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    useTheme
} from "@vapor/react-material";
import { useState } from "react";
import { LOGIN_API_URL } from "../../../config";
import getFormattedMessageWithScope from "../../../utils/getFormattedMessageWithScope";
import BackArrow from "../BackArrow";
import { OnboardingStepContract } from "./OnboardingStepContract";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";

export default function OnboardingStepWorkspace({
    onCompletedStep,
    onboardingStepStatus
}: OnboardingStepContract) {
    const theme = useTheme();
    const fsMessage = getFormattedMessageWithScope("components.Onboarding");
    const fsString = useFormattedStringWithScope("components.Onboarding");

    const { tenant } = useCurrentTenant(true);
    const { workspace } = useCurrentWorkspace(true);

    const [isWSDialogOpen, setIsWSDialogOpen] = useState(false);
    const deepLink = `/details/${workspace?.id}/business?itemId=${tenant.id}&customerId=${workspace?.id}&embed=true`;
    const workspaceAppSrc = `${LOGIN_API_URL}/?mode=redirect&appId=WORKSPACE&deepLink=${encodeURIComponent(
        deepLink
    )}`;

    const onCloseDialog = () => {
        setIsWSDialogOpen(false);
        onCompletedStep();
    };

    return (
        <Stack>
            <Dialog fullScreen onClose={onCloseDialog} open={isWSDialogOpen}>
                <DialogTitle>
                    <Box>
                        <IconButton onClick={onCloseDialog}>
                            <BackArrow />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Divider variant="fullWidth" />
                <DialogContent
                    sx={{
                        background: theme.palette.primary.background,
                        margin: "0 !important",
                        overflow: "hidden",
                        padding: "0 !important"
                    }}
                >
                    <iframe
                        height="100%"
                        src={workspaceAppSrc}
                        style={{ border: 0 }}
                        title="workspace-app"
                        width="100%"
                    ></iframe>
                </DialogContent>
            </Dialog>

            {!isWSDialogOpen && onboardingStepStatus === "TODO" && (
                <Stack
                    alignSelf="center"
                    direction="row"
                    m={8}
                    spacing={4}
                    width="33%"
                >
                    <Stack>
                        <FontAwesomeIcon
                            color={theme.palette.primary.textDisabledColor}
                            icon={faAddressCard}
                            size="4x"
                        />
                    </Stack>
                    <Stack spacing={1}>
                        <Typography
                            color={theme.palette.primary.yankeesBlue}
                            textAlign="left"
                            variant="titleSmall"
                        >
                            {`${fsString("steps.workspace.title")} ${
                                workspace?.description
                            }`}
                        </Typography>
                        <Typography
                            color={theme.palette.primary.textSubduedColor}
                            textAlign="left"
                            variant="bodySmall500"
                        >
                            {fsMessage("steps.workspace.description")}
                        </Typography>
                        <Box>
                            <Button
                                onClick={() => setIsWSDialogOpen(true)}
                                variant="contained"
                            >
                                {fsMessage("steps.workspace.buttons.complete")}
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
            )}

            {!isWSDialogOpen && onboardingStepStatus === "PENDING" && (
                <Stack
                    alignSelf="center"
                    direction="row"
                    m={8}
                    spacing={4}
                    width="33%"
                >
                    <Stack>
                        <FontAwesomeIcon
                            color={theme.palette.primary.textDisabledColor}
                            icon={faAddressCard}
                            size="4x"
                        />
                    </Stack>
                    <Stack spacing={1}>
                        <Typography
                            color={theme.palette.primary.yankeesBlue}
                            textAlign="left"
                            variant="titleSmall"
                        >
                            {`Contabilità ${workspace?.description}`}
                        </Typography>
                        <Box>
                            <Button
                                onClick={() => onCompletedStep()}
                                variant="contained"
                            >
                                {fsMessage("steps.workspace.buttons.refresh")}
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}
