import { addMonths, addQuarters } from "date-fns";
import format from "date-fns/format";
import getMonth from "date-fns/getMonth";
import getQuarter from "date-fns/getQuarter";
import getYear from "date-fns/getYear";
import upperCase from "lodash/upperCase";
import { PeriodType } from "./appEnums";

/*
    Examples:
        January -> 1
        Febraury -> if previous then 1 else 2
        First quarter -> if ordinal then 1 else FIRST_QUARTER
        First quarter -> if previous then FOURTH_QUARTER else FIRST_QUARTER
*/
export function getPeriod(
    date: Date,
    type?: PeriodType,
    ordinal: boolean = true,
    previous: boolean = false
) {
    if (type === PeriodType.QUARTERLY) {
        const value = getQuarter(date);

        switch (previous ? value - 1 : value) {
            case 1:
                return ordinal ? "1" : "FIRST_QUARTER";
            case 2:
                return ordinal ? "2" : "SECOND_QUARTER";
            case 3:
                return ordinal ? "3" : "THIRD_QUARTER";
            case 0:
            case 4:
                return ordinal ? "4" : "FOURTH_QUARTER";
            default:
                return "";
        }
    } else {
        return (getMonth(date) + (previous ? 0 : 1)).toString();
    }
}
export function getCurrentPeriod(type?: PeriodType, ordinal?: boolean) {
    return getPeriod(new Date(), type, ordinal, false);
}

export function getPreviousPeriod(type?: PeriodType, ordinal?: boolean) {
    return getPeriod(new Date(), type, ordinal, true);
}

/*
    Returns the current Date but year is transposed to the previous period.
    Previous period is 1 month earlier when type is MONTHLY, 1 quarter earlier when type is QUARTERLY
    Examples:
        14/02/24 and type is MONTHLY -> 14/01/24
        14/02/24 and type is QUARTERLY -> 14/11/23
*/
export function getPreviousPeriodYear(type?: PeriodType) {
    const today = new Date();

    return type && type === PeriodType.QUARTERLY
        ? addQuarters(today, -1)
        : addMonths(today, -1);
}

export function getCurrentQuarter() {
    return getQuarter(new Date()) - 1;
}

export function getPeriodFromDate(date: Date) {
    return getMonth(date).toString();
}

export function getOrdinalFromPeriod(period: string): string {
    return /^-?\d+$/.test(period)
        ? upperCase(
              format(
                  new Date(getYear(new Date()), parseInt(period) - 1, 1),
                  "MMMM"
              )
          )
        : period;
}
