import { isNil } from "lodash";
import { useState } from "react";

import { ProcedureEntryDetailContext } from ".";
import ProcedureEntryDetail from "../../ui/components/ProcedureEntryDetail";

interface ProcedureEntryDetailComponentProps {
    children: any;
}

export function ProcedureEntryDetailComponent({
    children
}: ProcedureEntryDetailComponentProps) {
    const [originalEntryId, setOriginalEntryId] = useState<string | null>(null);

    const open = (draftId: string) => {
        setOriginalEntryId(draftId);
    };

    const close = () => {
        setOriginalEntryId(null);
    };

    const handleClose = () => {
        close();
    };

    return (
        <ProcedureEntryDetailContext.Provider
            value={{
                open
            }}
        >
            {children}
            {!isNil(originalEntryId) && (
                <ProcedureEntryDetail
                    originalEntryId={originalEntryId}
                    onClose={handleClose}
                />
            )}
        </ProcedureEntryDetailContext.Provider>
    );
}
