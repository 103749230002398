import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import CircularProgess from "@vapor/react-material/CircularProgress";
import Divider from "@vapor/react-material/Divider";
import Stack from "@vapor/react-material/Stack";
import { useEffect, useState } from "react";
import { Spinner } from "@comic/precog-components";
import { ButtonGroup } from "@vapor/react-material";
import Stepper from "@vapor/react-material/Stepper";
import Step from "@vapor/react-material/Step";

import usePrevious from "../../../../../core/commons/hooks/usePrevious";
import { Elements } from "../../../../../core/usecases/dtos/ListAssetsDto";
import { useGetAssetLogs } from "../../../../../core/usecases/useGetAssetLogs";
import { Movements, MovementsType } from "../../../../../utils/appEnums";
import getFormattedDate from "../../../../../utils/getFormattedDate";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import ErrorBanner from "../../../../components/ErrorBanner";
import AssetLogsSequence from "./AssetLogs/assetLogsSequence";
import RemovalSubsection from "./MovementSections/RemovalSubsection/removalSubsection";
import RevaluationSubsectionPage from "./MovementSections/RevaluationSubsection/revaluationSubsectionPage";
import AddMovementPopOver from "./addMovementPopOver";
import usePoll from "../../../../../core/commons/hooks/usePoll";
import BoldAmountWithLabel from "../../../../components/BoldAmountWithLabel";
import MovementLog from "./MovementLog/movementLog";

const fs = getFormattedStringWithScope("views.ListAssets.movementDrawer");

interface MovementsTabProps {
    selectedAsset: Elements | null;
    showTabs: boolean;
    page: number;
    onChangeEliminationPage: (page: number) => void;
    setShowTabs: (showTabs: boolean) => void;
    showRevaluation: boolean;
    setShowRevaluation: (showRevaluation: boolean) => void;
    showElimination: boolean;
    setShowElimination: (showElimination: boolean) => void;
    setSelectedTab: Function;
}

export default function MovementsTab({
    selectedAsset,
    showTabs,
    page,
    onChangeEliminationPage,
    setShowTabs,
    showRevaluation,
    setShowRevaluation,
    showElimination,
    setShowElimination,
    setSelectedTab
}: MovementsTabProps) {
    const buttons = [
        Movements.STATUTORY,
        Movements.TAX,
        Movements.IRAP,
        Movements.TAX_RELIEF
    ];

    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const [selectedButton, setSelectedButton] = useState<string>("STATUTORY");
    const [selectedSequence, setSelectedSequence] = useState<string | null>(
        null
    );
    const [showSequenceDrawer, setShowSequenceDrawer] =
        useState<boolean>(false);

    const handleShowTabs = () => {
        setShowTabs(!showTabs);
    };

    const previousSelectedButton = usePrevious(selectedButton);

    const {
        fetch: fetchLogs,
        logs,
        loading,
        hasLoaded,
        error
    } = useGetAssetLogs({
        assetId: selectedAsset?.id,
        businessId: selectedAsset?.businessId,
        type: selectedButton,
        lazy: true
    });

    useEffect(() => {
        if (!hasInitialized) {
            fetchLogs();
            setHasInitialized(true);
        }
    }, [hasInitialized, fetchLogs]);

    useEffect(() => {
        if (previousSelectedButton !== selectedButton) {
            fetchLogs();
        }
    }, [fetchLogs, previousSelectedButton, selectedButton]);

    const { poll, isPolling, stop } = usePoll();

    const pendingDecomissionedlLog = logs?.logs.find(
        (log) =>
            log.type ===
                (MovementsType.DECOMISSIONED ||
                    MovementsType.PARTIALLY_DECOMISSIONED) &&
            log.status === "PENDING"
    );

    useEffect(() => {
        if (!isPolling && pendingDecomissionedlLog) {
            poll(() => fetchLogs());
        } else if (isPolling && !pendingDecomissionedlLog) {
            stop();
        }
    }, [stop, fetchLogs, poll, isPolling, logs, pendingDecomissionedlLog]);

    const handleRefresh = () => {
        fetchLogs();
    };

    return (
        <Box>
            {!hasLoaded && loading && (
                <Stack marginTop={3} alignItems="center">
                    <CircularProgess />
                    <Typography
                        color="primary.interactiveDefault"
                        variant="body"
                    >
                        {fs("drawerLoading")}
                    </Typography>
                </Stack>
            )}
            <Box>
                {!showSequenceDrawer && hasLoaded && (
                    <Stack marginTop={3}>
                        {!showElimination && !showRevaluation && (
                            <Box marginX={5}>
                                <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    height={"100px"}
                                >
                                    <Stack justifyContent={"center"}>
                                        <Typography
                                            variant="body"
                                            alignItems={"left"}
                                        >
                                            {fs("activationDate")}
                                        </Typography>
                                        <Typography
                                            variant="bodyLarge500"
                                            alignItems={"center"}
                                        >
                                            {getFormattedDate(
                                                logs?.activationDate || ""
                                            )}
                                        </Typography>
                                    </Stack>
                                    <Divider
                                        sx={{
                                            marginTop: 3.5,
                                            marginBottom: 3.5
                                        }}
                                        orientation="vertical"
                                        flexItem
                                    />
                                    <Stack
                                        justifyContent={"center"}
                                        alignItems={"flex-end"}
                                    >
                                        <BoldAmountWithLabel
                                            label={fs("existingAssetValue")}
                                            amount={logs?.totalAmount ?? 0}
                                            currency={"EUR"}
                                        />
                                    </Stack>
                                    <Stack
                                        justifyContent={"center"}
                                        alignItems={"flex-end"}
                                        marginRight={3}
                                    >
                                        <BoldAmountWithLabel
                                            label={
                                                fs("residualAt") +
                                                " " +
                                                getFormattedDate(
                                                    logs?.residualReferenceDate ||
                                                        ""
                                                )
                                            }
                                            amount={
                                                logs?.totalResidualAmount ?? 0
                                            }
                                            currency={"EUR"}
                                        />
                                    </Stack>
                                </Stack>
                                <Box
                                    width="65%"
                                    marginTop={4}
                                    marginBottom={3.5}
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <ButtonGroup variant="contained">
                                        {buttons.map((button) => (
                                            <Button
                                                onClick={() =>
                                                    setSelectedButton(button)
                                                }
                                                variant={
                                                    selectedButton === button
                                                        ? "outlined"
                                                        : "contained"
                                                }
                                            >
                                                {fs(`buttons.${button}`)}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </Box>
                                <Typography
                                    marginTop={3}
                                    color="primary.textTitleColor"
                                    variant="bodyLarge"
                                >
                                    {fs("assetMovements")}
                                </Typography>
                                <Spinner loading={loading}>
                                    {error ? (
                                        <ErrorBanner error={error} />
                                    ) : (
                                        <Stepper
                                            orientation={"vertical"}
                                            sx={{
                                                "&.MuiStepper-root.MuiStepper-vertical .MuiStepConnector-root .MuiStepConnector-line.MuiStepConnector-lineVertical":
                                                    {
                                                        marginLeft: 0.8
                                                    }
                                            }}
                                        >
                                            {logs?.logs.map((log, index) => (
                                                <Step key={index}>
                                                    <MovementLog
                                                        setShowSequenceDrawer={
                                                            setShowSequenceDrawer
                                                        }
                                                        showSequenceDrawer={
                                                            showSequenceDrawer
                                                        }
                                                        setSelectedSequence={
                                                            setSelectedSequence
                                                        }
                                                        log={log}
                                                    />
                                                </Step>
                                            ))}
                                        </Stepper>
                                    )}
                                </Spinner>
                                {selectedAsset?.active &&
                                    !pendingDecomissionedlLog && (
                                        <AddMovementPopOver
                                            logs={logs}
                                            onShowTabs={handleShowTabs}
                                            setShowElimination={
                                                setShowElimination
                                            }
                                            setShowRevaluation={
                                                setShowRevaluation
                                            }
                                        />
                                    )}
                            </Box>
                        )}
                        {showElimination && (
                            <RemovalSubsection
                                page={page}
                                onChangeEliminationPage={
                                    onChangeEliminationPage
                                }
                                logs={logs}
                                onDismission={handleRefresh}
                                onShowTabs={handleShowTabs}
                                setShowElimination={setShowElimination}
                                asset={selectedAsset}
                                setSelectedTab={setSelectedTab}
                            />
                        )}
                        {showRevaluation && (
                            <RevaluationSubsectionPage
                                onShowTabs={handleShowTabs}
                                setShowRevaluation={setShowRevaluation}
                                totalResidualAmount={logs?.totalResidualAmount}
                                assetId={selectedAsset?.id}
                                businessId={selectedAsset?.businessId}
                                onRevaluate={handleRefresh}
                            />
                        )}
                    </Stack>
                )}
                {showSequenceDrawer && (
                    <AssetLogsSequence
                        selectedSequence={selectedSequence}
                        selectedAssetId={selectedAsset?.id}
                        selectedBusinessId={selectedAsset?.businessId}
                        selectedChip={selectedButton}
                        showSequenceDrawer={showSequenceDrawer}
                        setShowSequenceDrawer={setShowSequenceDrawer}
                    />
                )}
            </Box>
        </Box>
    );
}
