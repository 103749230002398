import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import { accountingPeriodQueryKeys } from "../../domain/AccountingPeriods/queries";
import useCloseAccountingPeriod from "../../usecases/useCloseAccountingPeriod";
import { useCloseAccountingPeriodSimulationReport } from "../../usecases/useCloseAccountingPeriodSimulationReport";
import {
    GetClosePeriod,
    useCloseAccountingPeriodReport
} from "../../usecases/useCloseAccountinPeriodReport";
import useReopenAccountingPeriod, {
    ReopenPeriodOptions
} from "../../usecases/useReopenAccountingPeriod";
import useSimulateCloseAccountingPeriod, {
    SimulationPeriodOptions
} from "../../usecases/useSimulateCloseAccountingPeriod";

export const accountingPeriodCloseAndReopenQueryKeys = createQueryKeys(
    "accounting-periods-close-and-reopen",
    {
        all: null,
        getSimulationReport: (params: {
            workspaceId: string;
            accountingPeriodId: string;
        }) => [params],
        getCloseReport: (params: {
            workspaceId: string;
            accountingPeriodId: string;
        }) => [params]
    }
);

export const useClosePeriodReport = ({
    workspaceId,
    accountingPeriodId
}: GetClosePeriod) => {
    const { getClosePeriod } = useCloseAccountingPeriodReport();

    return useQuery({
        ...accountingPeriodCloseAndReopenQueryKeys.getCloseReport({
            workspaceId,
            accountingPeriodId
        }),
        queryFn: () => getClosePeriod({ workspaceId, accountingPeriodId })
    });
};

export const useGetReportSimulatePeriod = ({
    workspaceId,
    accountingPeriodId
}: GetClosePeriod) => {
    const { getSimulatePeriod } = useCloseAccountingPeriodSimulationReport();

    return useQuery({
        ...accountingPeriodCloseAndReopenQueryKeys.getSimulationReport({
            workspaceId,
            accountingPeriodId
        }),

        queryFn: () => getSimulatePeriod({ workspaceId, accountingPeriodId })
    });
};

export const useAccountingPeriodClosureSimulation = (
    accountingPeriodId: string
) => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.SimulationPeriod");
    const { simulate } = useSimulateCloseAccountingPeriod();

    return useMutation({
        mutationKey: ["simulate-accounting-periods"],
        mutationFn: async ({
            workspaceId,
            accountingPeriodId
        }: SimulationPeriodOptions) => {
            return simulate({
                workspaceId,
                accountingPeriodId
            });
        },
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        onSuccess: () => {
            enqueueSnackbar(fs("success"), { severity: "success" });
            queryClient.invalidateQueries(
                accountingPeriodQueryKeys.getTasks(accountingPeriodId)
            );
        }
    });
};

export const useCloseAccountingPeriods = (accountingPeriodId: string) => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.ClosePeriod");
    const { closePeriod } = useCloseAccountingPeriod();

    return useMutation({
        mutationKey: ["close-accounting-periods"],
        mutationFn: async ({
            workspaceId,
            accountingPeriodId
        }: SimulationPeriodOptions) => {
            return closePeriod({
                workspaceId,
                accountingPeriodId
            });
        },
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        onSuccess: () => {
            enqueueSnackbar(fs("success"), { severity: "success" });
            queryClient.invalidateQueries(
                accountingPeriodQueryKeys.getTasks(accountingPeriodId)
            );
        }
    });
};

export const useReopenAccountingPeriods = (accountingPeriodId: string) => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const fs = useFormattedStringWithScope("notifications.ReopenPeriod");

    const { reopenPeriod } = useReopenAccountingPeriod();

    return useMutation({
        mutationKey: ["reopen-accounting-periods"],
        mutationFn: async ({
            workspaceId,
            accountingPeriodId
        }: ReopenPeriodOptions) => {
            return reopenPeriod({
                workspaceId,
                accountingPeriodId
            });
        },
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        onSuccess: () => {
            enqueueSnackbar(fs("success"), { severity: "success" });
            queryClient.invalidateQueries(
                accountingPeriodQueryKeys.getTasks(accountingPeriodId)
            );
        }
    });
};
