import { useCurrentWorkspace } from "@drift/oneplatfront-components";
import { some } from "lodash";
import { createContext, useContext, useEffect } from "react";

import { isSimplifiedAccounting as isSimplifiedAccountingUtil } from "../../../utils/businessUtils";
import usePrevious from "../../commons/hooks/usePrevious";
import { BusinessDto } from "../../usecases/dtos/BusinessDto";
import useGetBusinesses from "../../usecases/useGetBusinesses";

interface BusinessesContextType {
    businesses: BusinessDto[];
    simplifiedAccountingBusinesses: BusinessDto[];
    loading: boolean;
    isSimplifiedAccounting: boolean;
}

const BusinessesContext = createContext<BusinessesContextType>({
    businesses: [],
    simplifiedAccountingBusinesses: [],
    loading: true,
    isSimplifiedAccounting: false
});

interface BusinessesProviderProps {
    children: any;
}

export const BusinessesProvider = ({ children }: BusinessesProviderProps) => {
    const { workspace } = useCurrentWorkspace(true);
    const previousWorkspace = usePrevious(workspace);
    const { businesses, getBusinesses, loading } = useGetBusinesses({
        lazy: true
    });

    const isSimplifiedAccounting = some(businesses, isSimplifiedAccountingUtil);

    const simplifiedAccountingBusinesses = businesses.filter(
        isSimplifiedAccountingUtil
    );

    useEffect(() => {
        if (workspace && workspace !== previousWorkspace) {
            getBusinesses();
        }
    }, [workspace, previousWorkspace, getBusinesses]);

    return (
        <BusinessesContext.Provider
            value={{
                businesses,
                simplifiedAccountingBusinesses,
                isSimplifiedAccounting,
                loading: loading
            }}
        >
            {children}
        </BusinessesContext.Provider>
    );
};

export const useBusinesses = () => {
    return useContext(BusinessesContext);
};
