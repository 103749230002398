import Typography from "@vapor/react-extended/ExtendedTypography";
import { Box, Divider, Stack } from "@vapor/react-material";

import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";
import FormattedAmount from "../../../components/FormattedAmount";
import { TotalAmountCard } from "./styled";

interface DocumentAmountsCardProps {
    totalAmount: React.ReactNode;
    totalAmountCurrency: React.ReactNode;
    showTaxAmounts?: boolean;
    taxableAmount?: number;
    taxAmount?: number;
    currency: string;
}

export default function DocumentAmountsCard({
    currency,
    totalAmount,
    totalAmountCurrency,
    showTaxAmounts,
    taxAmount,
    taxableAmount
}: DocumentAmountsCardProps) {
    const fs = useFormattedStringWithScope("components.Header.DocumentAmounts");

    return (
        <TotalAmountCard>
            <Box padding="16px">
                <Stack>
                    <Typography
                        variant="titleSmall"
                        color="richElectricBlue"
                        marginBottom="8px"
                        marginTop="8px"
                    >
                        {fs("total")}
                    </Typography>
                    <Stack direction="row" marginBottom="24px">
                        {totalAmount}
                        {totalAmountCurrency}
                    </Stack>
                    {showTaxAmounts ? (
                        <>
                            <Divider light />
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-end"
                            >
                                <Typography
                                    variant="bodySmall"
                                    marginTop="16px"
                                >
                                    {fs("taxable")}
                                </Typography>
                                <FormattedAmount
                                    variant="bodySmall"
                                    fontWeight="bold"
                                    amount={taxableAmount ?? 0}
                                    currency={currency}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-end"
                            >
                                <Typography
                                    variant="bodySmall"
                                    marginTop="16px"
                                >
                                    {fs("taxes")}
                                </Typography>
                                <FormattedAmount
                                    variant="bodySmall"
                                    fontWeight="bold"
                                    amount={taxAmount ?? 0}
                                    currency={currency}
                                />
                            </Stack>
                        </>
                    ) : null}
                </Stack>
            </Box>
        </TotalAmountCard>
    );
}
