import { Spinner } from "@comic/precog-components";
import { OneBoxPage, useCurrentWorkspace } from "@drift/oneplatfront";
import { Box } from "@vapor/react-material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { useEffect } from "react";

import usePrevious from "../../../core/commons/hooks/usePrevious";
import useAccountingPeriods from "../../../core/usecases/useAccountingPeriods";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import ErrorBanner from "../../components/ErrorBanner";
import AccountingPeriodsNavigation from "./AccountingPeriodsNavigation";
import NoAccountingPeriods from "./NoAccountingPeriods";

const fs = getFormattedStringWithScope("views.AccountingPeriods");

export default function AccountingPeriods() {
    const { workspace: customer } = useCurrentWorkspace(true);
    const previousCustomer = usePrevious(customer);

    const {
        fetch: fetchAccountingPeriods,
        accountingPeriods,
        createAccountingPeriod,
        isFetchingAccountingPeriods,
        errorFetchingAccountingPeriods,
        isCreatingAccountingPeriod
    } = useAccountingPeriods();

    const errorTitle = fs("errorFetchingAccountingPeriods");

    useEffect(() => {
        if (customer && previousCustomer !== customer) {
            fetchAccountingPeriods();
        }
    }, [previousCustomer, customer, fetchAccountingPeriods]);

    const handleCreatePeriod = (startDate: Date, endDate: Date) => {
        createAccountingPeriod({ startDate, endDate });
    };

    return (
        <OneBoxPage title={fs("title")} subtitle={customer?.description}>
            <Spinner loading={!customer || isFetchingAccountingPeriods}>
                {isNil(accountingPeriods) ? (
                    errorFetchingAccountingPeriods ? (
                        <ErrorBanner
                            title={errorTitle}
                            error={errorFetchingAccountingPeriods}
                        />
                    ) : null
                ) : (
                    <Box padding="24px">
                        {isEmpty(accountingPeriods) ? (
                            <NoAccountingPeriods
                                onCreateAccountingPeriod={handleCreatePeriod}
                                isLoadingCreateAccountingPeriod={
                                    isCreatingAccountingPeriod
                                }
                            />
                        ) : (
                            <AccountingPeriodsNavigation
                                accountingPeriods={accountingPeriods}
                            />
                        )}
                    </Box>
                )}
            </Spinner>
        </OneBoxPage>
    );
}
