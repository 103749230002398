import { useCallback } from "react";

import useCreateAccountingPeriod, {
    CreateAccountingPeriodOptions,
    UseCreateAccountingPeriod
} from "../useCreateAccountingPeriod";
import useGetAccountingPeriods, {
    UseGetAccountingPeriods
} from "../useGetAccountingPeriods";

interface UseAccountingPeriods {
    accountingPeriods: UseGetAccountingPeriods["data"];
    fetch: UseGetAccountingPeriods["fetch"];
    isFetchingAccountingPeriods: boolean;
    errorFetchingAccountingPeriods: Error;
    createAccountingPeriod: UseCreateAccountingPeriod["fetch"];
    isCreatingAccountingPeriod: boolean;
}

export default function useAccountingPeriods(): UseAccountingPeriods {
    const useGetAccountingPeriodsHook = useGetAccountingPeriods({
        lazy: true
    });

    const useCreateAccountingPeriodHook = useCreateAccountingPeriod({
        lazy: true
    });

    const fetch = () => {
        useGetAccountingPeriodsHook.fetch();
    };

    const createAccountingPeriod = useCallback(
        (options: CreateAccountingPeriodOptions) => {
            useCreateAccountingPeriodHook.fetch({
                ...options,
                onResponse: () => useGetAccountingPeriodsHook.fetch()
            });
        },
        [useCreateAccountingPeriodHook, useGetAccountingPeriodsHook]
    );
    return {
        fetch,
        accountingPeriods: useGetAccountingPeriodsHook.data,
        createAccountingPeriod,
        isFetchingAccountingPeriods: useGetAccountingPeriodsHook.loading,
        errorFetchingAccountingPeriods: useGetAccountingPeriodsHook.error,
        isCreatingAccountingPeriod: useCreateAccountingPeriodHook.loading
    };
}
