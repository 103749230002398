import { useRequest } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AxiosResponse } from "../interfaces/AxiosResponse";

export interface SimulationPeriodOptions {
    workspaceId: string;
    accountingPeriodId: string;
    openNextPeriod?: boolean;
    dryRun?: boolean;
}

export interface UseSimulationPeriod {
    simulate: (options: SimulationPeriodOptions) => Promise<string>;
}

export default function useSimulateCloseAccountingPeriod(): UseSimulationPeriod {
    const customerData = useCustomerData();

    const { fetch } = useRequest({
        url: `${ASYNC_API_URL}/accounting-periods/close-accounting-period`,
        method: "post",
        ...useAxiosConfigRequestParams("SimulationPeriod"),
        lazy: true
    });

    const simulate = async ({
        workspaceId,
        accountingPeriodId
    }: SimulationPeriodOptions) => {
        return fetch({
            params: {
                ...customerData
            },
            data: {
                workspaceId,
                accountingPeriodId,
                openNextPeriod: true,
                dryRun: true
            }
        }).then((response: AxiosResponse<string>) => response.data);
    };

    return {
        simulate
    };
}
