import {
    faArrowRight,
    IconDefinition
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Card from "@vapor/react-material/Card";
import { MouseEventHandler } from "react";

import "./style.css";

interface HomeCardProps {
    icon: IconDefinition;
    title: JSX.Element;
    onArrowClick: MouseEventHandler<HTMLDivElement>;
}

export default function HomeCard({ icon, title, onArrowClick }: HomeCardProps) {
    return (
        <Card className="c-HomeCard">
            <div className="c-HomeCard-iconContainer">
                <FontAwesomeIcon icon={icon} />
            </div>
            <Box width="100%">
                <Typography variant="titleMedium">{title}</Typography>
            </Box>
            <div
                className="c-HomeCard-arrowIconContainer"
                onClick={onArrowClick}
            >
                <FontAwesomeIcon icon={faArrowRight} />
            </div>
        </Card>
    );
}
