import { useGet } from "@onefront/react-sdk";
import { useCallback } from "react";
import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { DraftUsedGood } from "../dtos/DraftUsedGoodDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

export interface UseGetDraftUsedGood {
    loading: boolean;
    error: any;
    fetchWithParameters: (
        draftId: string,
        usedGoodId: string
    ) => Promise<DraftUsedGood>;
    hasFetched: boolean;
}

export interface UseGetDraftUsedGoodsOptions {
    draftId?: string | null;
    usedGoodId?: string | null;
    lazy?: boolean;
}

export const useGetDraftUsedGood = ({
    draftId,
    usedGoodId,
    lazy
}: UseGetDraftUsedGoodsOptions): UseGetDraftUsedGood => {
    const customerData = useCustomerData();

    const { fetch, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/precog/drafts/${draftId}/usedGoods/${usedGoodId}`,
        {
            ...useAxiosConfigRequestParams("DraftUsedGood"),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: lazy
        }
    );

    const fetchWithParameters = useCallback(
        (draftId: string) => {
            return fetch({
                url: `${API_URL}/v1/precog/drafts/${draftId}/usedGoods/${usedGoodId}`,
                params: {
                    ...customerData
                }
            }).then((res: AxiosResponse<DraftUsedGood>) => res.data);
        },
        [fetch, usedGoodId, customerData]
    );

    return {
        loading: loading,
        error: error,
        hasFetched: hasLoaded,
        fetchWithParameters
    };
};
