import { useGet } from "@onefront/react-sdk";
import isNil from "lodash/isNil";

import { ASYNC_API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AccountingPeriodTasksDto } from "../dtos/AccountingPeriodTasksDto";

export default function useGetAccountingPeriodTasks() {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope(
        "notifications.GetAccountingPeriodTasks"
    );

    const customerData = useCustomerData();

    const {
        fetch: fetchData,
        data,
        loading,
        error
    } = useGet<AccountingPeriodTasksDto>(`ignored`, {
        ...useAxiosConfigRequestParams("GetAccountingPeriodTasks"),
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        lazy: true
    });

    const fetch = (accountingPeriodId: string) =>
        fetchData({
            url: `${ASYNC_API_URL}/general-ledger/accounting-periods/tasks`,
            params: {
                ...customerData,
                accountingPeriodId
            }
        }).then(({ data }) => data);

    return {
        fetch: fetch,
        data: error || isNil(data) || loading ? [] : data.tasks,
        loading: loading,
        error: error
    };
}
