export enum SetupAction {
    CONTINUE = "CONTINUE",
    REGISTER = "REGISTER",
    SKIP = "SKIP",
    IGNORE = "IGNORE"
}

export interface SetupStatusDto {
    actions?: SetupAction[];
    completed: boolean;
}
