import { useRequest } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AxiosResponse } from "../interfaces/AxiosResponse";

export interface ReopenPeriodOptions {
    workspaceId: string;
    accountingPeriodId: string;
}

export interface ReopenAccountingPeriod {
    reopenPeriod: (options: ReopenPeriodOptions) => Promise<string>;
}

export default function useReopenAccountingPeriod(): ReopenAccountingPeriod {
    const customerData = useCustomerData();

    const { fetch } = useRequest({
        url: `${ASYNC_API_URL}/accounting-periods/reopen-accounting-period`,
        method: "post",
        ...useAxiosConfigRequestParams("ReopenPeriod"),
        lazy: true
    });

    const reopenPeriod = async ({
        workspaceId,
        accountingPeriodId
    }: ReopenPeriodOptions) => {
        return fetch({
            params: {
                ...customerData
            },
            data: {
                workspaceId,
                accountingPeriodId,
                openNextPeriod: true,
                dryRun: false
            }
        }).then((response: AxiosResponse<string>) => response.data);
    };

    return {
        reopenPeriod
    };
}
