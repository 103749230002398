import { useRequest } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseCreateDraft {
    create: (reason: string) => Promise<string>;
    loading: boolean;
    draftId?: string;
}

export default function useCreateDraft(): UseCreateDraft {
    const customerData = useCustomerData();

    const axiosConfigRequestParams = useAxiosConfigRequestParams("CreateDraft");

    const fs = useFormattedStringWithScope("notifications.CreateDraft");

    const { enqueueSnackbar } = useSnackbar();

    const { fetch, loading, data } = useRequest({
        method: "post",
        url: `${API_URL}/v1/precog/drafts`,
        ...useAxiosConfigRequestParams("CreateDraft"),
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        lazy: true
    });

    const create = async (reason: string) => {
        return fetch({
            headers: {
                ...axiosConfigRequestParams
            },
            params: {
                ...customerData
            },
            data: {
                reasonId: reason
            }
        }).then((response: AxiosResponse<string>) => response.data);
    };

    return {
        create,
        loading,
        draftId: data
    };
}
