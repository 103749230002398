import { MenuItem, Select, Skeleton } from "@vapor/react-material";

import {
    stringifyBusinessSectional,
    stringifyBusinessSectionalAsStrings
} from "../../../../../../utils/businessSectionalUtils";
import { readOnlySelect } from "../../../../../../core/commons/read-only-select";

export interface BusinessFlatDto {
    businessId: string;
    businessDescription: string;
    sectionalId: string;
    sectionalDescription: string;
    regime?: string;
}

interface SectionalCellProps {
    sectionalId: string;
    businessId: string;
    onChange: (sectional: string) => void;
    sectionals: BusinessFlatDto[] | null;
    loadingSingleDraft: boolean;
    isDisabled: boolean;
}

export default function SectionalCell({
    sectionalId,
    businessId,
    onChange,
    sectionals,
    loadingSingleDraft,
    isDisabled
}: SectionalCellProps) {
    return loadingSingleDraft ? (
        <Skeleton
            variant="rectangular"
            animation="wave"
            width={200}
            height={40}
        />
    ) : (
        <Select
            {...readOnlySelect(isDisabled)}
            sx={{
                background: "transparent",
                "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
                    {
                        background: "transparent !important"
                    },
                "& fieldset": {
                    border: "none"
                },
                "& .MuiSelect-nativeInput": {
                    display: isDisabled ? "none" : "block"
                },
                width: 200
            }}
            slotProps={{
                input: {
                    readOnly: isDisabled
                }
            }}
            value={stringifyBusinessSectionalAsStrings(businessId, sectionalId)}
            onChange={(event) => {
                onChange(event.target.value as string);
            }}
            onClick={(event) => event.stopPropagation()}
        >
            {sectionals?.map((sectional, index) => (
                <MenuItem
                    onClick={(event) => event.stopPropagation()}
                    key={index}
                    value={stringifyBusinessSectional(sectional)}
                >
                    {sectional.sectionalDescription} -{" "}
                    {sectional.businessDescription}
                </MenuItem>
            ))}
        </Select>
    );
}
