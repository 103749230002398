import format from "date-fns/format";
import parse from "date-fns/parse";
import { LedgerEntries } from "../dtos/LedgerEntriesDto";

export default function processLedgerEntries(
    ledgerEntries: LedgerEntries
): LedgerEntries {
    return {
        ...ledgerEntries,
        entries: ledgerEntries.entries.map((ledgerEntry) => ({
            ...ledgerEntry,
            docDate:
                ledgerEntry.docDate &&
                format(
                    parse(ledgerEntry.docDate, "yyyy-MM-dd", new Date()),
                    "dd/MM/yyyy"
                ),
            postingDate:
                ledgerEntry.postingDate &&
                format(
                    parse(ledgerEntry.postingDate, "yyyy-MM-dd", new Date()),
                    "dd/MM/yyyy"
                )
        }))
    };
}
