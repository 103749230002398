import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AccountingLinesResponse } from "../dtos/AccountingLinesDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseGetAccountingLinesV2 {
    loading: boolean;
    error: any;
    accountingLines: AccountingLinesResponse | null;
    get: (draftId: string) => Promise<AccountingLinesResponse>;
}

export const useGetAccountingLinesV2 = (): UseGetAccountingLinesV2 => {
    const customerData = useCustomerData();

    const { fetch, data, loading, error } = useGet(`ignored`, {
        ...useAxiosConfigRequestParams("AccountingLines"),
        params: omitNilProperties({
            ...useCustomerData()
        }),
        lazy: true
    });

    const get = (draftId: string) => {
        return fetch({
            url: `${API_URL}/v2/precog/drafts/${draftId}/accountingLines`,
            params: {
                ...customerData
            }
        }).then(
            (response: AxiosResponse<AccountingLinesResponse>) => response.data
        );
    };

    return {
        get,
        loading: loading,
        error: error,
        accountingLines: data
    };
};
