import { Accordion } from "@comic/precog-components";
import { CircularProgress } from "@vapor/react-material";
import { format } from "date-fns";

import useCurrencySymbol from "../../../../core/commons/hooks/useCurrencySymbol";
import { useDraft } from "../../../../core/domain/Draft/queries";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import Header from "../../../components/Header";

const fs = getFormattedStringWithScope("views.NewRegistration.FillHeader");

type AccordionDescriptionProps = {
    draft?: {
        doc: {
            number: string;
            date?: string;
        };
        amount: number;
        refCurrency: string;
    };
};
const AccordionDescription = (props: AccordionDescriptionProps) => {
    const currencySymbol = useCurrencySymbol();

    if (!props.draft) return <></>;

    const { number, date } = props.draft.doc;
    const { amount, refCurrency } = props.draft;

    if (!date || date === "") return <></>;

    return (
        <>{`${fs("documentNumber")} ${number} ${fs("ofThe")} ${format(
            new Date(date),
            "dd/MM/yyyy"
        )} ${fs("of")} ${amount} ${currencySymbol(refCurrency)}`}</>
    );
};
interface FillHeaderProps {
    step: number;
    isOpen: boolean;
    draftId?: string;
    isCreatingDraft: boolean;
    onClick: () => void;
    onConfirm: () => void;
}
export default function FillHeader({
    draftId,
    isCreatingDraft,
    onClick,
    step,
    isOpen,
    onConfirm
}: FillHeaderProps) {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);

    return (
        <Accordion
            title={`${step}. ${fs("title")}`}
            isOpen={isOpen}
            onClick={onClick}
            description={
                (!isOpen && draft && (
                    <AccordionDescription draft={draft} />
                )) as unknown as string
            }
        >
            {isCreatingDraft || isLoading ? (
                <CircularProgress />
            ) : (
                isSuccess && (
                    <Header
                        draftId={draft.id}
                        withGoOnButton={true}
                        onGoOn={onConfirm}
                    />
                )
            )}
        </Accordion>
    );
}
