import getFormattedDate from "../../../utils/getFormattedDate";
import { VatEntries } from "../dtos/VatEntriesDto";

export default function convertVatEntries(vatEntries: VatEntries[]) {
    return vatEntries.map((vatEntry) => ({
        ...vatEntry,
        registrationDate: vatEntry.registrationDate
            ? getFormattedDate(vatEntry.registrationDate)
            : "",
        docDate: getFormattedDate(vatEntry.docDate)
    }));
}
