import { Spinner } from "@comic/precog-components";
import { OneBoxPage, useCurrentWorkspace } from "@drift/oneplatfront";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack, useTheme } from "@vapor/react-material";
import { useEffect, useState } from "react";
import usePrevious from "../../../core/commons/hooks/usePrevious";
import { CheckOnboardingDto } from "../../../core/usecases/dtos/CheckOnboardingDto";
import useGetOnboarding from "../../../core/usecases/useGetOnboarding";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import SetupBanner from "../SetupBanner";
import OnboardingStepCommencementDate from "./OnboardingStepCommencementDate";
import OnboardingStepWorkspace from "./OnboardingStepWorkspace";
interface OnboardingProps {
    children: unknown;
}

export default function Onboarding({ children }: OnboardingProps) {
    const fs = useFormattedStringWithScope("components.Onboarding");
    const theme = useTheme();

    const { checkOnboarding, loading: loadingCheckOnboarding } =
        useGetOnboarding();

    const workspace = useCurrentWorkspace(true);
    const prevWorkspace = usePrevious(workspace.workspace);

    const [onboardingStatus, setOnboardingStatus] =
        useState<CheckOnboardingDto>();

    const isLastStep =
        !onboardingStatus?.canAct &&
        onboardingStatus?.reason?.step === "ONBOARDING" &&
        onboardingStatus?.reason.status === "PENDING";

    useEffect(() => {
        async function _setOnboardingStatus() {
            setTimeout(
                async () => setOnboardingStatus(await checkOnboarding()),
                500
            );
        }

        if (
            (workspace.workspace && workspace.workspace !== prevWorkspace) ||
            !onboardingStatus ||
            isLastStep
        ) {
            _setOnboardingStatus();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLastStep, onboardingStatus, workspace.workspace]);

    return (
        <>
            {!onboardingStatus?.canAct && (
                <OneBoxPage subtitle={fs("subtitle")} title={fs("title")}>
                    {isLastStep && (
                        <Stack mt={12} spacing={1} textAlign="center">
                            <FontAwesomeIcon
                                color={theme.palette.primary.interactiveDefault}
                                icon={faCircleNotch}
                                size="4x"
                                spin
                            />

                            <Typography>{fs("loadingLastStep")}</Typography>
                        </Stack>
                    )}
                    {!isLastStep && loadingCheckOnboarding && (
                        <Spinner loading={loadingCheckOnboarding} />
                    )}

                    {!loadingCheckOnboarding &&
                        onboardingStatus?.reason?.step === "WORKSPACE" && (
                            <OnboardingStepWorkspace
                                onCompletedStep={async () => {
                                    setOnboardingStatus(
                                        await checkOnboarding()
                                    );
                                }}
                                onboardingStepStatus={
                                    onboardingStatus.reason.status
                                }
                                onboardingStep="WORKSPACE"
                            />
                        )}

                    {!loadingCheckOnboarding &&
                        onboardingStatus?.reason?.step === "ONBOARDING" && (
                            <OnboardingStepCommencementDate
                                onCompletedStep={async () => {
                                    setOnboardingStatus(
                                        await checkOnboarding()
                                    );
                                }}
                                onboardingStep="ONBOARDING"
                                onboardingStepStatus={
                                    onboardingStatus.reason.status
                                }
                            />
                        )}
                </OneBoxPage>
            )}
            {onboardingStatus?.canAct && !loadingCheckOnboarding && (
                <>
                    <SetupBanner />
                    {children}
                </>
            )}
            {onboardingStatus?.canAct &&
                loadingCheckOnboarding &&
                !isLastStep && (
                    <OneBoxPage subtitle={fs("subtitle")} title={fs("title")}>
                        <Spinner loading={loadingCheckOnboarding} />
                    </OneBoxPage>
                )}
        </>
    );
}
