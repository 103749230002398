import { Spinner } from "@comic/precog-components";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Print, Times } from "@vapor/react-icons";
import { Box, Divider, Stack } from "@vapor/react-material";
import { uniq } from "lodash";
import { useEffect, useState } from "react";

import { useRegisters } from "../../../core/contexts/Registers";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import CustomButton from "../../components/CustomButton";
import ErrorBanner from "../../components/ErrorBanner";
import NotFoundBanner from "../../components/NotFoundBanner";

const fsPeriods = getFormattedStringWithScope("commons.period");
const fs = getFormattedStringWithScope("views.AccountingPeriods.Registers");

export default function Registers() {
    const [hasInitialized, setHasInitialized] = useState<boolean>(true);

    const {
        get,
        error,
        registers: allRegisters,
        generate,
        cancel,
        loading,
        generating,
        canceling,
        fetchingCancelation,
        fetchingGeneration,
        year
    } = useRegisters();
    const registers = allRegisters[0];

    useEffect(() => {
        if (!hasInitialized) {
            get();
            setHasInitialized(true);
        }
    }, [hasInitialized, get]);

    useEffect(() => {
        setHasInitialized(false);
    }, [year]);

    return (
        <Stack padding={2}>
            <Typography variant="titleSmall">{fs("VAT")}</Typography>
            <Divider orientation="horizontal" variant="fullWidth" light />
            {error ? (
                <ErrorBanner error={error} />
            ) : registers ? (
                <Stack direction="row">
                    <Stack width="100%">
                        <Typography variant="body500" marginTop={2}>
                            {fs("vatRegisters")}
                        </Typography>
                        {registers.creatable ? (
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography variant="body">
                                    {fs("creatable")}{" "}
                                    {uniq(
                                        registers.creatable.periods.map(
                                            fsPeriods
                                        )
                                    ).join(" - ")}
                                </Typography>
                                <CustomButton
                                    loading={fetchingGeneration}
                                    startIcon={<Print />}
                                    onClick={() => generate()}
                                >
                                    {fs("generate")}
                                </CustomButton>
                            </Stack>
                        ) : null}
                        {registers.generated || registers.deleted ? (
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                {generating ? (
                                    <Typography variant="body">
                                        {fs("generationInProgress")}
                                    </Typography>
                                ) : canceling ? (
                                    <Typography variant="body">
                                        {fs("deletionInProgress")}
                                    </Typography>
                                ) : (
                                    <>
                                        <Typography variant="body">
                                            {fs("available")}{" "}
                                            {uniq(
                                                registers.generated?.periods.map(
                                                    fsPeriods
                                                )
                                            ).join(" - ")}
                                        </Typography>
                                        <Stack direction="row" gap={2}>
                                            <CustomButton
                                                loading={false}
                                                startIcon={
                                                    <FontAwesomeIcon
                                                        icon={faDownload}
                                                    />
                                                }
                                                onClick={() =>
                                                    window
                                                        .open(
                                                            registers.generated
                                                                ?.link,
                                                            "_blank"
                                                        )
                                                        ?.focus()
                                                }
                                            >
                                                {fs("download")}
                                            </CustomButton>
                                            <CustomButton
                                                onClick={cancel}
                                                loading={fetchingCancelation}
                                                startIcon={<Times />}
                                            >
                                                {fs("cancel")}
                                            </CustomButton>
                                        </Stack>
                                    </>
                                )}
                            </Stack>
                        ) : null}
                    </Stack>
                </Stack>
            ) : loading ? (
                <Spinner loading={loading}>
                    <Box height={10} marginTop={20}></Box>
                </Spinner>
            ) : (
                <NotFoundBanner text={fs("noRegisters")} />
            )}
        </Stack>
    );
}
