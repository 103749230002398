import { useDraft } from "../../../core/domain/Draft/queries";
import VatSummary from "../../views/VatSummary";
import { AccountingLinesTable } from "./AccountingLinesTable/table";
import Payments from "./Payments";
import LinkDocuments from "./Payments/LinkDocuments";

interface RegularAccountabilityProps {
    draftId: string;
    readOnly?: boolean;
}

export default function RegularAccountability({
    draftId,
    readOnly = false
}: RegularAccountabilityProps) {
    const { data: draft } = useDraft(draftId);

    return (
        <>
            <AccountingLinesTable draftId={draftId} readOnly={readOnly} />
            {draft?.hasInvoices && (
                <LinkDocuments draftId={draftId} readOnly={readOnly} />
            )}
            {draft?.hasContextualPayment && (
                <Payments draftId={draftId} readOnly={readOnly} />
            )}
            <VatSummary draftId={draftId} />
        </>
    );
}
