import {
    ColumnElement,
    ColumnType,
    HeadCell,
    Table
} from "@comic/precog-components";
import { faAngleRight, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import IconButton from "@vapor/react-material/IconButton";
import { compact } from "lodash";
import { useState } from "react";

import {
    LedgerEntries,
    LedgerEntry
} from "../../../../core/usecases/dtos/LedgerEntriesDto";
import { useDetail } from "../../../../services/DetailFacade";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../components/FormattedAmount";

const fs = getFormattedStringWithScope("components.LedgerTable");

interface LedgerTableProps {
    accountCode?: string;
    ledgerEntries: LedgerEntries;
    disableVerticalNavigation?: boolean;
}

export default function LedgerTable({
    accountCode,
    ledgerEntries,
    disableVerticalNavigation
}: LedgerTableProps) {
    const { open, isOpening } = useDetail();

    const [originalEntryId, setOriginalEntryId] = useState<string | null>(null);

    const openDocumentDetail = async (ledgerEntry: LedgerEntry) => {
        setOriginalEntryId(ledgerEntry.id);
        await open(ledgerEntry, ledgerEntry.reasonId, { readOnly: true });
    };

    const ledgerLastEntry = ledgerEntries.entries.slice(-1)[0];

    const headCells: HeadCell[] = compact([
        {
            id: "registrationDate",
            kind: ColumnType.text,
            label: fs("registrationDate")
        },
        {
            id: "docNumber",
            kind: ColumnType.text,
            label: fs("docNumber")
        },
        {
            id: "docDate",
            kind: ColumnType.text,
            label: fs("docDate")
        },
        {
            id: "reason",
            kind: ColumnType.text,
            label: fs("reason")
        },
        {
            id: "amount",
            kind: ColumnType.text,
            label: fs("amount")
        },
        {
            id: "typeamount",
            kind: ColumnType.numeric,
            label: ""
        },
        {
            id: "total",
            kind: ColumnType.text,
            label: fs("total")
        },
        disableVerticalNavigation
            ? null
            : {
                  id: "cta",
                  kind: ColumnType.empty,
                  label: ""
              }
    ]);

    const columns: ColumnElement[] = compact([
        // registration date
        {
            kind: ColumnType.text,
            render: (ledgerEntry: LedgerEntry) => (
                <Typography>{ledgerEntry.postingDate}</Typography>
            )
        },
        // document number
        {
            kind: ColumnType.text,
            render: (ledgerEntry: LedgerEntry) => (
                <Typography>{ledgerEntry.docNumber}</Typography>
            )
        },
        // document date
        {
            kind: ColumnType.text,
            render: (ledgerEntry: LedgerEntry) => (
                <Typography>{ledgerEntry.docDate}</Typography>
            )
        },
        // reason
        {
            kind: ColumnType.text,
            render: (ledgerEntry: LedgerEntry) => (
                <Typography>{ledgerEntry.reasonDescription}</Typography>
            )
        },
        // amount
        {
            kind: ColumnType.text,
            render: (ledgerEntry: LedgerEntry) => (
                <FormattedAmount
                    amount={
                        ledgerEntry.credit
                            ? ledgerEntry.credit
                            : ledgerEntry.debit
                    }
                    currency={ledgerEntry.currency}
                />
            )
        },
        {
            kind: ColumnType.numeric,
            render: (ledgerEntry: LedgerEntry) => (
                <Typography>{ledgerEntry.credit ? "A" : "D"}</Typography>
            )
        },
        // total
        {
            kind: ColumnType.text,
            render: (ledgerEntry: LedgerEntry) => (
                <FormattedAmount
                    amount={ledgerEntry.cumulativeBalance}
                    currency={ledgerEntry.currency}
                />
            )
        },
        // cta
        disableVerticalNavigation
            ? null
            : {
                  kind: ColumnType.cta,
                  render: (ledgerEntry: LedgerEntry) => (
                      <IconButton
                          color="primary"
                          size="small"
                          onClick={() => openDocumentDetail(ledgerEntry)}
                      >
                          <FontAwesomeIcon
                              icon={
                                  ledgerEntry.id === originalEntryId &&
                                  isOpening
                                      ? faSpinner
                                      : faAngleRight
                              }
                              spin={
                                  ledgerEntry.id === originalEntryId &&
                                  isOpening
                              }
                          />
                      </IconButton>
                  )
              }
    ]);

    return (
        <Box marginRight="24px" marginTop="32px">
            <Box display="flex" width="100%" marginBottom="16px">
                <Box display="flex" alignItems="flex-end" width="100%">
                    <Typography variant="titleSmall">
                        {fs("ledgerOfTheAccount")} {accountCode} -{" "}
                        {ledgerEntries.accountTitle}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    flexDirection="column"
                    marginRight="60px"
                >
                    <Typography variant="titleSmall">
                        {fs("finalBalance")}
                    </Typography>
                    <FormattedAmount
                        minWidth="120px"
                        variant="bodyLarge700"
                        fontSize="32px"
                        component="div"
                        amount={ledgerLastEntry.cumulativeBalance}
                        currency="EUR"
                    />
                </Box>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    flexDirection="column"
                >
                    <Typography variant="titleSmall">
                        {fs("initialBalance")}
                    </Typography>
                    <FormattedAmount
                        minWidth="120px"
                        variant="bodyLarge700"
                        fontSize="32px"
                        component="div"
                        amount={ledgerEntries.initialBalance}
                        currency="EUR"
                    />
                </Box>
            </Box>
            <Table
                headCells={headCells}
                columns={columns}
                data={ledgerEntries.entries}
            />
        </Box>
    );
}
