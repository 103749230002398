import { useQueryClient } from "@tanstack/react-query";
import { isNil } from "lodash";
import { useState } from "react";

import {
    CloseReason,
    DocumentDetailComponentProps,
    DocumentDetailConfig,
    DocumentDetailContext
} from ".";
import { accountingLinesQueryKeys } from "../../core/domain/AccountingLines/queries";
import {
    draftQueryKeys,
    useConfirmDraft,
    useDraft
} from "../../core/domain/Draft/queries";
import { useGetClonedDraft } from "../../core/usecases/useGetClonedDraft";
import { useRestoreDraft } from "../../core/usecases/useRestoreDraft";
import { useStartDraftModification } from "../../core/usecases/useStartDraftModification";
import CloseClonedDialog from "../../ui/components/Accounting/CloseClonedDialog";
import DocumentDetail from "../../ui/components/DocumentDetail";
import { DraftAccountingStatus } from "../../utils/appEnums";

export function DocumentDetailComponent({
    children
}: DocumentDetailComponentProps) {
    const [draftId, setDraftId] = useState<string | undefined>(undefined);
    const [config, setConfig] = useState<DocumentDetailConfig>({});
    const [isCloseClonedDialogOpen, setIsCloseClonedDialogOpen] =
        useState<boolean>(false);

    const queryClient = useQueryClient();
    const { data: draft } = useDraft(draftId);
    const { mutateAsync: confirm } = useConfirmDraft({
        draftId: draftId!,
        revision: draft?.revision
    });

    const open = (draftId: string, config: DocumentDetailConfig = {}) => {
        setDraftId(draftId);
        setConfig(config);
    };

    const close = (closeReason: CloseReason) => {
        !isNil(config.onClose) && config.onClose(closeReason);
        setDraftId(undefined);
    };

    const handleClose = (closeReason: CloseReason) => {
        const isModifyingAccountedDocument =
            draft?.status === DraftAccountingStatus.CLONED;
        if (isModifyingAccountedDocument && closeReason === CloseReason.CLOSE) {
            setIsCloseClonedDialogOpen(true);
        } else {
            close(closeReason);
        }
    };

    const handleCloseFromWarningDialog = async () => {
        if (draftId) {
            await useRestoreDraftHook.restore(draftId);
            close(CloseReason.CLOSE);
            setIsCloseClonedDialogOpen(false);
        }
    };

    const handleConfirmModificationsFromDialog = async () => {
        await confirm();
        close(CloseReason.CONFIRM);
        setIsCloseClonedDialogOpen(false);
    };

    const useStartDraftModificationHook = useStartDraftModification();

    const startModification = async () => {
        if (draftId) {
            const newDraftId =
                await useStartDraftModificationHook.startModification(draftId);
            setDraftId(newDraftId);
            setConfig({
                ...config,
                readOnly: false
            });
        }
    };

    const useRestoreDraftHook = useRestoreDraft();

    const deleteClone = async () => {
        if (draftId) {
            const originalDraftId = await useRestoreDraftHook.restore(
                draftId,
                "PARENT"
            );
            setDraftId(originalDraftId);
            queryClient.invalidateQueries(draftQueryKeys.get(draftId));
            queryClient.invalidateQueries(
                accountingLinesQueryKeys.get({ draftId })
            );
            setConfig({
                ...config,
                readOnly: true
            });
        }
    };

    const useGetClonedDraftHook = useGetClonedDraft();

    const continueEditClone = async () => {
        if (draftId) {
            const clonedDraftId = await useGetClonedDraftHook.get(draftId);
            setDraftId(clonedDraftId);
            setConfig({
                ...config,
                readOnly: false
            });
        }
    };

    return (
        <DocumentDetailContext.Provider
            value={{
                open
            }}
        >
            {children}
            {isNil(draftId) ? null : (
                <DocumentDetail
                    draftId={draftId}
                    onClose={handleClose}
                    onModify={startModification}
                    onDeleteClone={deleteClone}
                    isDeletingClone={useRestoreDraftHook.loading}
                    onContinueEditClone={continueEditClone}
                    isLoadingCloned={useGetClonedDraftHook.loading}
                    isLoadingModification={
                        useStartDraftModificationHook.loading
                    }
                    readOnly={config.readOnly}
                />
            )}
            {isCloseClonedDialogOpen ? (
                <CloseClonedDialog
                    isOpen={isCloseClonedDialogOpen}
                    onClose={() => setIsCloseClonedDialogOpen(false)}
                    onCofirmModifications={handleConfirmModificationsFromDialog}
                    onNotSave={handleCloseFromWarningDialog}
                />
            ) : null}
        </DocumentDetailContext.Provider>
    );
}
