import { Spinner } from "@comic/precog-components";
import { Box } from "@vapor/react-material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface InfiniteScrollProps {
    hasMore: boolean;
    isLoadingMore: boolean;
    fetchMore: Function;
}

export default function InfiniteScroll({
    fetchMore,
    hasMore,
    isLoadingMore
}: InfiniteScrollProps) {
    const ref = useRef<HTMLDivElement | null>(null);

    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIntersecting(entry.isIntersecting)
            ),
        []
    );

    const execute = useCallback(() => {
        hasMore && !isLoadingMore && fetchMore();
    }, [hasMore, isLoadingMore, fetchMore]);

    useEffect(() => {
        observer.observe(ref.current as Element);
        return () => observer.disconnect();
    }, [observer]);

    useEffect(() => {
        isIntersecting && execute();
    }, [isIntersecting, execute]);

    return (
        <>
            {isLoadingMore && (
                <Spinner loading={isLoadingMore}>
                    <Box width="100%" height="80px" marginTop="16px"></Box>
                </Spinner>
            )}
            <Box display="block" height="100px" ref={ref}></Box>
        </>
    );
}
