import { useGet, useSnackbar } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AxiosResponse } from "../interfaces/AxiosResponse";
import { DraftDetail } from "../dtos/DraftDetail";

interface UseGetClonedDraft {
    loading: boolean;
    get: (draftId: string) => Promise<string>;
}

export const useGetClonedDraft = (): UseGetClonedDraft => {
    const { enqueueSnackbar } = useSnackbar();
    const fs = useFormattedStringWithScope("notifications.GetClonedDraft");
    const customerData = useCustomerData();

    const { fetch, loading } = useGet("ignored", {
        ...useAxiosConfigRequestParams("GetClonedDraft"),
        onError: () => {
            enqueueSnackbar(fs("error"), {
                variant: "error"
            });
        },
        lazy: true
    });

    const get = async (draftId: string) => {
        return fetch({
            url: `${API_URL}/v1/precog/drafts/parent/${draftId}`,
            params: {
                ...customerData
            }
        }).then((response: AxiosResponse<DraftDetail>) => response.data.id);
    };

    return {
        get,
        loading: loading
    };
};
