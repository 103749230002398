import { useRequest } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface CreateCSBAccountOptions {
    customerId?: string;
    supplierId?: string;
    bankId?: string;
}

interface UseCreateCSBAccount {
    create: (
        draftId: string,
        options: CreateCSBAccountOptions
    ) => Promise<void>;
    loading: boolean;
    error?: Error;
}

const useCreateCSBAccount = (): UseCreateCSBAccount => {
    const { fetch, loading, error } = useRequest({
        ...useAxiosConfigRequestParams("CreateCSBAccount"),
        params: {
            ...useCustomerData()
        }
    });

    return {
        create: async (draftId: string, options: CreateCSBAccountOptions) => {
            return fetch({
                url: `${API_URL}/v2/precog/drafts/${draftId}/accountingLines/customer-supplier-bank`,
                method: "POST",
                data: options
            });
        },
        loading,
        error
    };
};

export default useCreateCSBAccount;
