import { useRequest } from "@onefront/react-sdk";

import { format } from "date-fns";
import { API_DATE_FORMAT, API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface UsePostAssetRevaluation {
    loading: boolean;
    error: any;
    result: any;
    fetch: () => Promise<void>;
    hasFetched: boolean;
}

export interface IPostData {
    applicationField: string;
    currentValue?: number;
    revaluationDate?: Date;
    revaluationType: string;
}

interface UsePostAssetRevaluationOptions {
    assetId: string | undefined;
    businessId: string | undefined;
    dataToPost: IPostData | null;
    lazy: boolean;
}

export const usePostAssetRevaluation = ({
    assetId,
    businessId,
    dataToPost,
    lazy
}: UsePostAssetRevaluationOptions): UsePostAssetRevaluation => {
    const fs = useFormattedStringWithScope("notifications.AssetRevaluation");

    const { enqueueSnackbar } = useSnackbar();

    const { fetch, data, loading, error, hasLoaded } = useRequest({
        ...useAxiosConfigRequestParams("PostAssetRevaluation"),
        method: "post",
        url: `${API_URL}/v1/asset/${assetId}/simulation/revaluate`,
        params: omitNilProperties({
            ...useCustomerData(),
            businessId
        }),
        data: {
            ...dataToPost,
            revaluationDate: dataToPost?.revaluationDate
                ? format(dataToPost?.revaluationDate, API_DATE_FORMAT)
                : undefined
        },
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        lazy: lazy
    });

    return {
        fetch: fetch,
        loading: loading,
        error: error,
        result: data,
        hasFetched: hasLoaded
    };
};
