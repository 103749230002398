import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import { FormControlLabel } from "@vapor/react-material/FormControlLabel";
import { FormGroup } from "@vapor/react-material/FormGroup";
import InputLabel from "@vapor/react-material/InputLabel";
import Stack from "@vapor/react-material/Stack";
import Switch from "@vapor/react-material/Switch";
import { TextareaAutosize } from "@vapor/react-material/TextareaAutosize";
import TextField from "@vapor/react-material/TextField";
import { DatePicker } from "@vapor/react-x-date-pickers";
import { useEffect, useState } from "react";

import usePrevious from "../../../core/commons/hooks/usePrevious";
import { usePostAssetSaleSimulationWithParam } from "../../../core/domain/Draft/Asset/queries";
import { AssetInfoResponse } from "../../../core/usecases/dtos/AssetInfoDto";
import {
    DraftAssetPatch,
    usePatchDraftAsset
} from "../../../core/usecases/usePatchDraftAsset";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import CurrencyField from "../../components/CurrencyField";
import PercentageField from "../../components/PercentageField";
import { SplitCapitalGain } from "../../../core/usecases/dtos/SimulationAssetSaleDto";

interface SaleProps {
    assetId: string;
    draftId: string | null;
    businessId?: string;
    refCurrency: string | null;
    assetInfo: AssetInfoResponse | null;
    isActive: boolean;
    readOnly?: boolean;
}

const fs = getFormattedStringWithScope(
    "views.Detail.assets.drawer.incrementalSpending"
);
const Sale = ({
    assetId,
    draftId,
    businessId,
    refCurrency,
    assetInfo,
    isActive,
    readOnly
}: SaleProps) => {
    const [draftAssetPatch, setDraftAssetPatch] =
        useState<DraftAssetPatch | null>(null);
    const [currentDistribute, setCurrentDistribute] = useState(
        assetInfo?.distributeCapitalGains
    );

    const previousDraftAssetPatch = usePrevious(draftAssetPatch);

    const [currentAlias, setCurrentAlias] = useState<string | null>(null);

    const { patchDraftAsset, hasResponded: hasPatchResponded } =
        usePatchDraftAsset({
            assetId: assetId,
            draftId: draftId,
            dataToPatch: draftAssetPatch,
            lazy: true
        });

    const { mutateAsync: fetchPostSimulationSale, data: postResult } =
        usePostAssetSaleSimulationWithParam({
            assetId: assetInfo?.assetRegistryId,
            dataToPost: {
                businessId: businessId,
                date: assetInfo && new Date(assetInfo?.operationDate),
                disposalRate: assetInfo && assetInfo?.sellingPercent,
                saleAmount: assetInfo && assetInfo?.amount
            }
        });

    useEffect(() => {
        fetchPostSimulationSale({
            assetId: assetInfo?.assetRegistryId,
            dataToPost: {
                businessId: businessId,
                date: assetInfo && new Date(assetInfo?.operationDate),
                disposalRate: assetInfo?.sellingPercent ?? 100,
                saleAmount: assetInfo && assetInfo?.amount
            }
        });
    }, [assetInfo, businessId, fetchPostSimulationSale]);

    useEffect(() => {
        if (
            !!draftAssetPatch &&
            JSON.stringify(previousDraftAssetPatch) !==
                JSON.stringify(draftAssetPatch) &&
            draftId
        ) {
            patchDraftAsset();
            if (hasPatchResponded) {
                setDraftAssetPatch(null);
            }
        }
    }, [
        draftAssetPatch,
        draftId,
        patchDraftAsset,
        previousDraftAssetPatch,
        hasPatchResponded
    ]);

    const showNonTaxableCapitalGain: boolean = !!(
        postResult?.data.nonDeductibleGain &&
        postResult?.data.nonDeductibleGain > 0
    );

    const showNonDeductibleLoss: boolean = !!(
        postResult?.data.nonDeductibleLoss &&
        postResult?.data.nonDeductibleLoss > 0
    );

    return (
        <>
            <Stack>
                <Typography
                    color="primary.textTitleColor"
                    variant="bodyLarge500"
                >
                    {fs("newOperation")}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between !important",
                        width: "80%"
                    }}
                >
                    <Stack marginTop="17px">
                        <InputLabel>{fs("type")}</InputLabel>
                        <TextField
                            readOnly
                            value={isActive && fs("sale")}
                            sx={{ width: 320 }}
                        />
                    </Stack>
                    <Stack marginTop="20px">
                        <InputLabel>{fs("operationDate")}</InputLabel>
                        {assetInfo && (
                            <DatePicker
                                readOnly
                                value={new Date(assetInfo?.operationDate)}
                            />
                        )}
                    </Stack>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between !important",
                        marginTop: "25px",
                        width: "80%"
                    }}
                >
                    <Stack minWidth="200px">
                        <InputLabel>{fs("sellingPercent")}</InputLabel>
                        <PercentageField
                            value={
                                assetInfo?.sellingPercent
                                    ? assetInfo?.sellingPercent
                                    : 0
                            }
                            onChange={(
                                currentSellingPercent: number | null
                            ) => {
                                if (currentSellingPercent) {
                                    fetchPostSimulationSale({
                                        assetId: assetInfo?.assetRegistryId,
                                        dataToPost: {
                                            businessId: businessId,
                                            date:
                                                assetInfo &&
                                                new Date(
                                                    assetInfo?.operationDate
                                                ),
                                            disposalRate: currentSellingPercent,
                                            saleAmount:
                                                assetInfo && assetInfo?.amount
                                        }
                                    });
                                    setDraftAssetPatch({
                                        sellingPercent: currentSellingPercent
                                    });
                                }
                            }}
                            readOnly={readOnly}
                        />
                    </Stack>
                    <Stack>
                        <InputLabel>{fs("sellingValue")}</InputLabel>
                        <CurrencyField
                            value={assetInfo?.amount ?? 0}
                            readOnly
                            onChange={() => {}}
                            currency={refCurrency ?? ""}
                        />
                    </Stack>
                    <Stack minWidth="200px">
                        <InputLabel>{fs("depreciationDuringYear")}</InputLabel>
                        <CurrencyField
                            value={postResult?.data.depreciationDuringYear ?? 0}
                            readOnly
                            onChange={() => {}}
                            currency={refCurrency ?? ""}
                        />
                    </Stack>
                </Box>
                {!!(
                    postResult?.data.statutoryCapitalGain &&
                    postResult?.data.statutoryCapitalGain > 0
                ) && (
                    <Box sx={{ marginTop: "25px", width: "200px" }}>
                        <Stack>
                            <InputLabel>{fs("civilCapitalGain")}</InputLabel>
                            <CurrencyField
                                value={postResult?.data.statutoryCapitalGain}
                                readOnly
                                onChange={() => {}}
                                currency={refCurrency ?? ""}
                            />
                        </Stack>
                    </Box>
                )}
                {!!(
                    postResult?.data.statutoryCapitalLoss &&
                    postResult?.data.statutoryCapitalLoss > 0
                ) && (
                    <Box sx={{ marginTop: "25px", width: "200px" }}>
                        <Stack>
                            <InputLabel>{fs("civilCapitalLoss")}</InputLabel>
                            <CurrencyField
                                value={postResult?.data.statutoryCapitalLoss}
                                readOnly
                                currency={refCurrency ?? ""}
                            />
                        </Stack>
                    </Box>
                )}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between !important",
                        marginTop: "25px",
                        width: "80%"
                    }}
                >
                    {!!(
                        postResult?.data.taxCapitalGain &&
                        postResult?.data.taxCapitalGain > 0
                    ) && (
                        <Stack minWidth="200px">
                            <InputLabel>{fs("taxCapitalGain")}</InputLabel>
                            <CurrencyField
                                value={postResult?.data.taxCapitalGain}
                                readOnly
                                currency={refCurrency ?? ""}
                            />
                        </Stack>
                    )}
                    {!!(
                        postResult?.data.taxCapitalLoss &&
                        postResult?.data.taxCapitalLoss > 0
                    ) && (
                        <Stack minWidth="200px">
                            <InputLabel>{fs("taxCapitalLoss")}</InputLabel>
                            <CurrencyField
                                value={postResult?.data.taxCapitalLoss}
                                readOnly
                                currency={refCurrency ?? ""}
                            />
                        </Stack>
                    )}
                    {!!(
                        postResult?.data.taxCapitalGain &&
                        postResult?.data.taxCapitalGain > 0
                    ) && (
                        <Box display="flex">
                            <FormGroup sx={{ marginTop: "30px" }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={currentDistribute}
                                            onChange={() => {
                                                setDraftAssetPatch({
                                                    distributeCapitalGains:
                                                        !currentDistribute
                                                });
                                                setCurrentDistribute(
                                                    !currentDistribute
                                                );
                                            }}
                                            disabled={
                                                postResult.data
                                                    .splitCapitalGain ===
                                                SplitCapitalGain.DISABLED
                                            }
                                        />
                                    }
                                    label={fs("toStart")}
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </Box>
                    )}
                    {(showNonTaxableCapitalGain || showNonDeductibleLoss) && (
                        <Typography
                            marginTop="39px"
                            marginLeft="5px"
                            variant="body"
                        >
                            {fs("ofWhich")}
                        </Typography>
                    )}
                    {showNonTaxableCapitalGain && (
                        <Stack minWidth="200px">
                            <InputLabel>
                                {fs("nonTaxableCapitalGain")}
                            </InputLabel>
                            <CurrencyField
                                value={assetInfo?.nonDeductibleGain ?? 0}
                                currency={refCurrency ?? "EUR"}
                                readOnly
                            />
                        </Stack>
                    )}
                    {showNonDeductibleLoss && (
                        <Stack minWidth="200px">
                            <InputLabel>
                                {fs("nonDeductableCapitalLoss")}
                            </InputLabel>
                            <CurrencyField
                                value={assetInfo?.nonDeductibleLoss ?? 0}
                                currency={refCurrency ?? "EUR"}
                                readOnly
                            />
                        </Stack>
                    )}
                </Box>
                <Box marginTop="25px">
                    <Stack>
                        <InputLabel>{fs("description")}</InputLabel>
                        <TextareaAutosize
                            defaultValue={assetInfo?.alias}
                            placeholder={fs("description")}
                            style={{
                                width: 750
                            }}
                            minRows={3}
                            onChange={(event) => {
                                if (event.target.value)
                                    setCurrentAlias(event.target.value);
                            }}
                            onBlur={() => {
                                if (assetInfo?.alias !== currentAlias)
                                    setDraftAssetPatch({
                                        alias: currentAlias
                                    });
                            }}
                            readOnly={readOnly}
                        />
                    </Stack>
                </Box>
            </Stack>
        </>
    );
};

export default Sale;
