import { isNil } from "lodash";

import { AccountDto } from "../core/usecases/dtos/AccountsDto";

export function isCustomerSupplierAccountDestination(
    accountDestinations: string[] | undefined
) {
    if (!accountDestinations) return false;
    return accountDestinations.some(
        (destination) =>
            destination.includes("CUSTOMER") || destination.includes("SUPPLIER")
    );
}

export function isCustomerSupplier(account: AccountDto) {
    return isCustomerSupplierAccountDestination(account.destinations);
}

export function isBank(account: AccountDto) {
    return account.destinations?.includes("BANK");
}

export function isChildAccount(account: AccountDto) {
    return !isNil(account.originCode);
}
