import { isEmpty, isNil } from "lodash";
import getFormattedDate from "../../../utils/getFormattedDate";
import { AccountedForResponse } from "../dtos/AccountedForDto";

export function concatenateData(
    data: AccountedForResponse,
    moreData?: AccountedForResponse
): AccountedForResponse {
    return isNil(moreData) || isEmpty(data)
        ? data
        : {
              ...data,
              entries: {
                  ...data.entries
              }
          };
}

export function processData(data: AccountedForResponse): AccountedForResponse {
    return isNil(data)
        ? data
        : {
              ...data,
              entries: data.entries.map((entry) => ({
                  ...entry,
                  postingDate: entry.postingDate
                      ? getFormattedDate(entry.postingDate)
                      : entry.postingDate,
                  doc: {
                      ...entry.doc,
                      date: entry?.doc?.date
                          ? getFormattedDate(entry?.doc?.date)
                          : entry?.doc?.date
                  }
              }))
          };
}
