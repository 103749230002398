import { TableHead, TableRow } from "@vapor/react-material";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import TableHeadCell from "../../../components/TableHead/TableHeadCell";
import TableHeadText from "../../../components/TableHead/TableHeadText";

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.CloseAndReopenAccountsTable"
);

export const CloseAndReopenAccountTableHead = () => {
    return (
        <TableHead shadow>
            <TableRow>
                <TableHeadCell>
                    <TableHeadText>
                        {fs("columns.accountingAccount")}
                    </TableHeadText>
                </TableHeadCell>
                <TableHeadCell>
                    <TableHeadText>{fs("columns.accountType")}</TableHeadText>
                </TableHeadCell>
                <TableHeadCell>
                    <TableHeadText>
                        {fs("columns.closingBalance")}
                    </TableHeadText>
                </TableHeadCell>
                <TableHeadCell>
                    <TableHeadText>{fs("columns.giveOpening")}</TableHeadText>
                </TableHeadCell>
                <TableHeadCell align="right">
                    <TableHeadText>{fs("columns.haveOpening")}</TableHeadText>
                </TableHeadCell>
            </TableRow>
        </TableHead>
    );
};
