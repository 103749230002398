import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    CircularProgress,
    DialogActions,
    Stack,
    Table,
    TableBody
} from "@vapor/react-material";
import {
    useClosePeriodReport,
    useReopenAccountingPeriods
} from "../../../../core/domain/CloseAndReopenAccount/queries";
import { AccountingPeriodDto } from "../../../../core/usecases/dtos/AccountingPeriodsDto";
import { TaskStatus } from "../../../../core/usecases/dtos/TaskStatus";

import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../../components/CustomButton";
import TaskStatusAlertBanner from "../TaskStatusAlertBanner";
import { CloseAndReopenAccountTableHead } from "./CloseAndReopenAccountTableHead";
import { CloseAndReopenAccountsHeader } from "./CloseAndReopenAccountsHeader";
import CloseAndReopenAccountsLine from "./CloseAndReopenAccountsLines";
import SummaryCloseAndReopenAccountsTable from "./SummaryCloseAndReopenAccountsTable";

interface CloseAndReopenAccountsGeneratedProps {
    workspaceId: string;
    selectedAccountingPeriod: AccountingPeriodDto;
    onClose: () => void;

    statusTask: TaskStatus;
}

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.CloseAndReopenAccountsOverlay"
);

export const CloseAndReopenAccountsGenerated = ({
    workspaceId,
    selectedAccountingPeriod,
    statusTask,

    onClose
}: CloseAndReopenAccountsGeneratedProps) => {
    const { data: closeReport, isLoading: loadingCloseReport } =
        useClosePeriodReport({
            workspaceId,
            accountingPeriodId: selectedAccountingPeriod.id
        });

    const { mutateAsync: reopenPeriod, isPending: loadingReopenPeriod } =
        useReopenAccountingPeriods(selectedAccountingPeriod.id);
    const handleReopenPeriod = async () => {
        await reopenPeriod({
            workspaceId,
            accountingPeriodId: selectedAccountingPeriod.id
        });

        onClose();
    };
    return (
        <>
            <CloseAndReopenAccountsHeader
                selectedAccountingPeriod={selectedAccountingPeriod}
                report={closeReport?.report}
            />
            <TaskStatusAlertBanner status={statusTask} />
            <Stack gap={4} marginBottom={2} marginTop={2}>
                <Typography variant="h6" color="primary.textTitleColor">
                    {fs("title")}
                </Typography>

                {!loadingCloseReport && closeReport && (
                    <Table>
                        <CloseAndReopenAccountTableHead />
                        <TableBody>
                            <CloseAndReopenAccountsLine
                                accounts={closeReport?.report.accounts}
                            />
                            <SummaryCloseAndReopenAccountsTable
                                report={closeReport.report}
                            />
                        </TableBody>
                    </Table>
                )}
                {loadingCloseReport && (
                    <Box
                        justifyContent="center"
                        display="flex"
                        alignContent="center"
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Stack>
            <DialogActions
                sx={{
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                    width: "100%"
                }}
            >
                <CustomButton
                    onClick={handleReopenPeriod}
                    color="primary"
                    variant="contained"
                    disabled={loadingReopenPeriod}
                    loading={loadingReopenPeriod}
                >
                    {fs("openPeriod")}
                </CustomButton>
            </DialogActions>
        </>
    );
};
