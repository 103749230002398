import {
    ColumnElement,
    ColumnType,
    HeadCell,
    Spinner,
    Table
} from "@comic/precog-components";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Grid from "@vapor/react-material/Grid";
import { isEmpty } from "lodash";

import { useDraft } from "../../../core/domain/Draft/queries";
import { useVatLines } from "../../../core/domain/VatLines/queries";
import { PrecogDraftVat } from "../../../core/usecases/dtos/PrecogDraftVatDto";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import { isVat } from "../../../utils/reasonUtils";
import ErrorBanner from "../../components/ErrorBanner";
import FormattedAmount from "../../components/FormattedAmount";

const fs = getFormattedStringWithScope("views.Detail.vatSummary");

interface VatSummaryTableProps {
    vatLines: PrecogDraftVat[] | [];
    refCurrency: string | null;
    isLoading: boolean;
}

const VatSummaryTable = ({
    refCurrency,
    vatLines,
    isLoading
}: VatSummaryTableProps) => {
    const headCells: HeadCell[] = [
        {
            id: "regimeId",
            kind: ColumnType.text,
            label: fs("table.regime")
        },
        {
            id: "taxable",
            kind: ColumnType.text,
            label: fs("table.taxable")
        },
        {
            id: "rate",
            kind: ColumnType.text,
            label: fs("table.rate")
        },
        {
            id: "tax",
            kind: ColumnType.text,
            label: fs("table.tax")
        },
        {
            id: "deductibility",
            kind: ColumnType.text,
            label: fs("table.deductibility")
        },
        {
            id: "",
            kind: ColumnType.text,
            label: ""
        },
        {
            id: "",
            kind: ColumnType.cta,
            label: ""
        }
    ];

    const columns: ColumnElement[] = [
        // regimeId
        {
            kind: ColumnType.text,
            render: (vat: PrecogDraftVat) => (
                <Typography>
                    {vat.regimeId && vat.regimeId !== "-"
                        ? fs(`table.regimes.${vat.regimeId}`)
                        : vat.regimeId}
                </Typography>
            )
        },
        // taxable
        {
            kind: ColumnType.text,
            render: (vat: PrecogDraftVat) => (
                <FormattedAmount
                    amount={vat.taxable}
                    currency={refCurrency ?? ""}
                />
            )
        },
        // rate
        {
            kind: ColumnType.text,
            render: (vat: PrecogDraftVat) => <Typography>{vat.rate}</Typography>
        },
        // tax
        {
            kind: ColumnType.text,
            render: (vat: PrecogDraftVat) => (
                <FormattedAmount
                    amount={vat.tax}
                    currency={refCurrency ?? ""}
                />
            )
        },
        // deductibility
        {
            kind: ColumnType.text,
            render: (vat: PrecogDraftVat) => (
                <FormattedAmount
                    amount={parseFloat(vat.deductibility)}
                    currency={refCurrency ?? ""}
                />
            )
        },
        {
            kind: ColumnType.empty
        },
        {
            kind: ColumnType.empty
        }
    ];

    /**
     * We need the total for footer table
     */

    // @ts-ignore
    const footerTotals: any = vatLines?.reduce(
        (a: any, b: any) => ({
            taxable: (a.taxable ?? 0) + (b.taxable ?? 0),
            tax: (a.tax ?? 0) + (b.tax ?? 0),
            deductibility:
                parseFloat(a.deductibility ?? 0) +
                parseFloat(b.deductibility ?? 0),
            total:
                (a.taxable ?? 0) +
                (b.taxable ?? 0) +
                ((a.tax ?? 0) + (b.tax ?? 0))
        }),
        0
    );

    const footer: ColumnElement[] = [
        // regimeId
        {
            kind: ColumnType.empty
        },
        // taxable
        {
            kind: ColumnType.text,
            render: () => (
                <FormattedAmount
                    amount={footerTotals.taxable}
                    currency={refCurrency ?? ""}
                    variant="body700"
                />
            )
        },
        // rate
        {
            kind: ColumnType.empty
        },
        // tax
        {
            kind: ColumnType.text,
            render: () => (
                <FormattedAmount
                    amount={footerTotals.tax}
                    currency={refCurrency ?? ""}
                    variant="body700"
                />
            )
        },
        // deductibility
        {
            kind: ColumnType.text,
            render: () => (
                <FormattedAmount
                    amount={footerTotals.deductibility}
                    currency={refCurrency ?? ""}
                    variant="body700"
                />
            )
        },
        {
            kind: ColumnType.text,
            render: () => (
                <Grid
                    container
                    alignContent="center"
                    justifyContent="center"
                    sx={{ marginTop: 0 }}
                >
                    <Grid sx={{ marginRight: "8px" }}>
                        <Typography variant="body700">
                            {fs("table.total")}:{" "}
                        </Typography>
                    </Grid>
                    <Grid>
                        <FormattedAmount
                            variant="body700"
                            amount={footerTotals.total}
                            currency={refCurrency ?? ""}
                        />
                    </Grid>
                </Grid>
            )
        },
        {
            kind: ColumnType.text,
            render: () => (
                <Typography color="primary.accentIslamicGreen">
                    <FontAwesomeIcon icon={faCheck} />
                </Typography>
            )
        }
    ];

    return (
        <Box margin="36px 0">
            <Spinner loading={isLoading}>
                <Box sx={{ paddingBottom: "24px" }}>
                    <Typography variant="titleMedium" color="richElectricBlue">
                        {fs("title")}
                    </Typography>
                </Box>
                <Table
                    footer={isEmpty(vatLines) ? [] : footer}
                    headCells={headCells}
                    columns={columns}
                    data={vatLines}
                />
            </Spinner>
        </Box>
    );
};

type VatSummaryProps = {
    draftId: string;
};
const VatSummary = ({ draftId }: VatSummaryProps) => {
    const { data: draft } = useDraft(draftId);
    const { data: vatLines, isLoading, error } = useVatLines(draftId);

    if (error) {
        return <ErrorBanner error={error} />;
    }

    if (!draft?.reasonId! || !isVat(draft?.reasonId)) {
        return null;
    }

    return (
        <VatSummaryTable
            vatLines={vatLines ?? []}
            isLoading={isLoading}
            refCurrency={draft?.refCurrency ?? "EUR"}
        />
    );
};

export default VatSummary;
