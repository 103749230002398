import { SxProps } from "@mui/system";
import {
    Autocomplete,
    Skeleton,
    Stack,
    TextField
} from "@vapor/react-material";
import { capitalize } from "lodash";

import { useVatRegimes } from "../../../core/domain/VatRegimes/queries";
import { VatRegime } from "../../../core/usecases/dtos/VatRegimesDto";
import { Reason, RegimeExtraField } from "../../../utils/appEnums";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import PercentageField from "../PercentageField";
import { TransparentAutoComplete } from "../styled";

interface SelectVatRegimeProps {
    value?: VatRegime | null;
    extraFieldValue?: string | null;
    vatRegimes: VatRegime[];
    onChange: (vatRegime: VatRegime) => void;
    onChangeExtraField: (extraFieldValue: string | null) => void;
    disabled?: boolean;
    disablePortal?: boolean;
    transparent?: boolean;
    readOnly?: boolean;
    sx?: SxProps;
}

export default function SelectVatRegime({
    value,
    extraFieldValue,
    onChange,
    onChangeExtraField,
    vatRegimes,
    disabled = false,
    transparent = false,
    disablePortal = true,
    sx
}: SelectVatRegimeProps) {
    const fs = useFormattedStringWithScope(
        "components.Accounting.AccountingLinesTable.SelectVatRegime"
    );

    const AutoCompleteComponent = transparent
        ? TransparentAutoComplete
        : Autocomplete;

    return (
        <Stack direction="row" alignItems="center" width="100%" spacing={1}>
            <AutoCompleteComponent
                value={value ?? null}
                onChange={(_: any, vatRegime: any) => onChange(vatRegime)}
                options={vatRegimes}
                isOptionEqualToValue={(option, value) =>
                    (option as VatRegime).id === (value as VatRegime).id
                }
                getOptionLabel={(vatRegime: any) =>
                    capitalize((vatRegime as VatRegime).description)
                }
                renderInput={(params) => <TextField {...params} />}
                readOnly={disabled}
                disabled={!transparent && disabled}
                autoComplete={true}
                autoHighlight={true}
                openOnFocus={true}
                disablePortal={disablePortal}
                componentsProps={{
                    popper: {
                        placement: "bottom-start",
                        sx: {
                            width: "fit-content !important"
                        }
                    }
                }}
                sx={{ width: "100%", ...sx }}
            />
            {value?.field ? (
                [
                    RegimeExtraField.COMPENSATION_PERCENTAGE,
                    RegimeExtraField.UNDEDUCTIBILITY_PRECENTAGE
                ].includes(value?.field as RegimeExtraField) &&
                value?.values?.length === 1 ? (
                    <PercentageField
                        value={
                            extraFieldValue && extraFieldValue !== "0.0"
                                ? parseFloat(extraFieldValue)
                                : null
                        }
                        allowNullValues={true}
                        transparent={transparent}
                        placeholder={fs(value.field)}
                        onChange={(value) =>
                            onChangeExtraField(value ? value.toString() : null)
                        }
                        readOnly={disabled}
                    />
                ) : [
                      RegimeExtraField.COMPENSATION_PERCENTAGE,
                      RegimeExtraField.SECOND_VAT_RATE,
                      RegimeExtraField.FORFAIT_PERCENTAGE,
                      RegimeExtraField.USED_GOOD_RATE,
                      RegimeExtraField.RETURN_PERCENTAGE
                  ].includes(value?.field as RegimeExtraField) &&
                  value?.values?.length !== 1 ? (
                    <AutoCompleteComponent
                        value={extraFieldValue}
                        options={value.values ?? []}
                        getOptionLabel={(value: any) => `${value}%`}
                        disablePortal={disablePortal}
                        autoComplete={true}
                        autoHighlight={true}
                        autoSelect={true}
                        openOnFocus={true}
                        readOnly={disabled}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={value.field}
                                readOnly={disabled}
                                InputProps={{
                                    ...params.InputProps,
                                    sx: {
                                        "& input": {
                                            textAlign: "right"
                                        }
                                    }
                                }}
                                type="search"
                            />
                        )}
                        onChange={(_, value: any) =>
                            onChangeExtraField(value as string)
                        }
                        sx={{ width: "136px", ...sx }}
                    />
                ) : null
            ) : null}
        </Stack>
    );
}

type SmartSelectVatRegimeProps = Omit<
    SelectVatRegimeProps,
    "vatRegimes" | "value"
> & { value?: string; reason?: Reason };
export const SmartSelectVatRegime = (props: SmartSelectVatRegimeProps) => {
    const {
        data: vatRegimes,
        isLoading,
        isSuccess
    } = useVatRegimes(props.reason);

    if (isLoading) {
        return (
            <Skeleton>
                <SelectVatRegime
                    onChange={() => {}}
                    onChangeExtraField={() => {}}
                    vatRegimes={[]}
                />
            </Skeleton>
        );
    }

    if (isSuccess) {
        const selectedRegime =
            vatRegimes.find((regime) => regime.id === props.value) || null;

        return (
            <SelectVatRegime
                vatRegimes={vatRegimes}
                {...props}
                value={selectedRegime}
            />
        );
    }

    return null;
};
