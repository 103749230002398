import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import { AssetDetailDto } from "../dtos/AssetDetailDto";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface UseGetAssetDetail {
    loading: boolean;
    error: any;
    assetDetail: AssetDetailDto | null;
    fetch: Function;
}

interface UseGetAssetDetailOptions {
    assetId: string | undefined;
    businessId: string | undefined;
    lazy?: boolean;
}

export const useGetAssetDetail = ({
    assetId,
    businessId,
    lazy
}: UseGetAssetDetailOptions): UseGetAssetDetail => {
    const { fetch, data, loading, error } = useGet(
        `${API_URL}/v1/asset/${assetId}?businessId=${businessId}`,
        {
            ...useAxiosConfigRequestParams("Assets"),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: lazy
        }
    );

    return {
        fetch: fetch,
        loading: loading,
        error: error,
        assetDetail: data
    };
};
