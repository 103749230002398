import { useGet } from "@onefront/react-sdk";
import { useCallback } from "react";
import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

import { ReasonsList } from "../dtos/WithholdingReasonsDto";

export interface UseGetWithholdingsReasons {
    loading: boolean;
    error: any;
    reasons: ReasonsList;
    fetch: (cuReason: string) => void;
    hasFetched: boolean;
}

export interface UseGetWithholdingsReasonsOptions {
    cuReason?: string;
    lazy?: boolean;
}

export const useGetWithholdingsReasons = ({
    lazy
}: UseGetWithholdingsReasonsOptions): UseGetWithholdingsReasons => {
    const customerData = useCustomerData();

    const {
        fetch: fetchData,
        data,
        loading,
        error,
        hasLoaded
    } = useGet(`${API_URL}/v1/withholding/reasons`, {
        ...useAxiosConfigRequestParams("WithholdingReasons"),
        params: omitNilProperties({
            ...useCustomerData()
        }),
        lazy: lazy
    });

    const fetch = useCallback(
        (cuReason: string) => {
            fetchData({
                params: {
                    ...customerData,
                    cuReason: cuReason
                }
            });
        },
        [fetchData, customerData]
    );

    return {
        fetch: fetch,
        loading: loading,
        error: error,
        reasons: data,
        hasFetched: hasLoaded
    };
};
