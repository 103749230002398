import Typography from "@vapor/react-extended/ExtendedTypography";

import notFoundImage from "./notFoundImage.png";
import "./style.css";

interface NotFoundBannerProps {
    text: string;
}

export default function NotFoundBanner({ text }: NotFoundBannerProps) {
    return (
        <div className="c-NotFoundImage">
            <img src={notFoundImage} alt="no results found" />
            <Typography fontSize={24} color="primary.textSubduedColor">
                {text}
            </Typography>
        </div>
    );
}
