import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@vapor/react-material/Button";
import { useNavigate } from "react-router-dom";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";

import routes from "../../commons/routes";

const fs = getFormattedStringWithScope("components.NewRegistrationButton");

export default function NewRegistrationButton() {
    const navigate = useNavigate();

    return (
        <Button
            variant="outlined"
            onClick={() => navigate(routes.newRegistration())}
            startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
        >
            {fs("newRegistration")}
        </Button>
    );
}
