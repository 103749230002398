import { SelectYear } from "@comic/precog-components";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Grid
} from "@vapor/react-material";
import { endOfYear, getYear, setYear, startOfYear } from "date-fns";
import { isNil } from "lodash";
import { useState } from "react";

import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import DateRangePicker, {
    DateRangePickerState
} from "../../components/DateRangePicker";

interface NewAccountingPeriodProps {
    inDialog?: boolean;
    onCreateAccountingPeriod: (startDate: Date, endDate: Date) => void;
    isLoadingCreateAccountingPeriod: boolean;
}

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.NewAccountingPeriod"
);

export default function NewAccountingPeriod({
    inDialog,
    onCreateAccountingPeriod,
    isLoadingCreateAccountingPeriod
}: NewAccountingPeriodProps) {
    const [dateRangePickerState, setDateRangePickerState] =
        useState<DateRangePickerState>({
            startDate: startOfYear(new Date()),
            endDate: endOfYear(new Date()),
            error: false
        });

    const currentYear = getYear(new Date());
    const [selectedYear, setSelectedYear] = useState<number>(currentYear);

    const handleCreatePeriodClick = () => {
        if (dateRangePickerState.startDate && dateRangePickerState.endDate) {
            onCreateAccountingPeriod(
                dateRangePickerState.startDate,
                dateRangePickerState.endDate
            );
        }
    };

    const handleSelectedYearChange = (year: number) => {
        setSelectedYear(year);
        const dateInSelectedYear = setYear(new Date(), year);
        setDateRangePickerState({
            ...dateRangePickerState,
            startDate: startOfYear(dateInSelectedYear),
            endDate: endOfYear(dateInSelectedYear)
        });
    };

    const createPeriodButton = (
        <Button
            variant="contained"
            disabled={
                dateRangePickerState.error || isLoadingCreateAccountingPeriod
            }
            onClick={handleCreatePeriodClick}
        >
            {isLoadingCreateAccountingPeriod ? (
                <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
            ) : null}
            {fs("createNewPeriod")}
        </Button>
    );

    const renderContent = () => (
        <Box>
            <Typography variant="body">
                {fs("insertDataAndCreateNewPeriod")}
            </Typography>
            <Grid container spacing={2} marginTop="16px">
                <Grid item>
                    <SelectYear
                        numberOfYears={5}
                        startingYear={currentYear}
                        value={selectedYear}
                        onChange={handleSelectedYearChange}
                    />
                </Grid>
                <Grid item>
                    <DateRangePicker
                        startDate={dateRangePickerState.startDate}
                        endDate={dateRangePickerState.endDate}
                        isRequired={true}
                        // TODO check if future has to be disabled
                        validate={(date) => !isNil(date)}
                        onChange={(dateRange) =>
                            setDateRangePickerState(dateRange)
                        }
                    />
                </Grid>
            </Grid>
        </Box>
    );

    return inDialog ? (
        <>
            <DialogContent>{renderContent()} </DialogContent>
            <DialogActions>{createPeriodButton}</DialogActions>
        </>
    ) : (
        <>
            {renderContent()}
            <Box marginLeft="-442px">
                <VaporToolbar contentRight={createPeriodButton} />
            </Box>
        </>
    );
}
