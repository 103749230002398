import { Accordion, Spinner } from "@comic/precog-components";
import VaporPage from "@vapor/react-custom/VaporPage";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Button from "@vapor/react-material/Button";
import FormControl from "@vapor/react-material/FormControl";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import Grid from "@vapor/react-material/Grid";
import Switch from "@vapor/react-material/Switch";
import endOfYear from "date-fns/endOfYear";
import format from "date-fns/format";
import getYear from "date-fns/getYear";
import setYear from "date-fns/setYear";
import startOfYear from "date-fns/startOfYear";
import isNil from "lodash/isNil";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGetBalance } from "../../../core/usecases/useGetBalance";
import { QueryParam } from "../../../utils/appEnums";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import { makeUrl, url } from "../../../utils/urlBuilder";
import useQueryParam from "../../../utils/useQueryParam";
import routes, { BALANCE_BASE_URL } from "../../commons/routes";
import BackArrow from "../../components/BackArrow";
import DateRangePicker, {
    DateRangePickerState
} from "../../components/DateRangePicker";
import NotFoundBanner from "../../components/NotFoundBanner";
import SelectYear from "../../components/SelectYear";
import BalanceTable from "./BalanceTable";
import "./style.css";

const fs = getFormattedStringWithScope("views.Balance");

export default function Balance() {
    const navigate = useNavigate();

    const currentYear = getYear(new Date());

    const [selectedYear, setSelectedYear] = useState<number>(currentYear);

    const queryParams = useQueryParam();
    const startDate = queryParams.get(QueryParam.startDate);
    const endDate = queryParams.get(QueryParam.endDate);
    const hasQueryParams = !isNil(startDate) && !isNil(endDate);

    const [hasInitialized, setHasInitialized] = useState<boolean>(false);

    const defaultDateRange: DateRangePickerState = {
        startDate: hasQueryParams
            ? new Date(startDate)
            : startOfYear(new Date()),
        endDate: hasQueryParams ? new Date(endDate) : endOfYear(new Date()),
        error: false
    };

    const [selectedDateRange, setSelectedDateRange] =
        useState<DateRangePickerState>(defaultDateRange);

    const [isRequestingByCompetence, setIsRequestingByCompetence] =
        useState<boolean>(
            queryParams.get(QueryParam.balanceByCompetence) === "true"
        );

    const [includeProvisional, setIncludeProvisional] = useState<boolean>(
        queryParams.get(QueryParam.balanceWithAssetSimulation) === "true"
    );

    const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(true);

    const {
        fetch: fetchBalance,
        balance,
        loading,
        error
    } = useGetBalance({
        lazy: true,
        fromDate: selectedDateRange.startDate,
        toDate: selectedDateRange.endDate,
        requestByCompetence: isRequestingByCompetence,
        includeProvisional: includeProvisional
    });

    useEffect(() => {
        if (hasQueryParams && !hasInitialized) {
            fetchBalance();
            setIsAccordionOpen(false);
            setHasInitialized(true);
        }
    }, [hasQueryParams, hasInitialized, fetchBalance]);

    const handleSelectYearChange = (newSelectedYear: number) => {
        const newSelectedYearDate = setYear(new Date(), newSelectedYear);
        setSelectedDateRange({
            startDate: startOfYear(newSelectedYearDate),
            endDate: setYear(
                newSelectedYear === currentYear
                    ? new Date()
                    : endOfYear(newSelectedYearDate),
                newSelectedYear
            ),
            error: selectedDateRange.error
        });
        setSelectedYear(newSelectedYear);
    };

    const handleClearFilters = () => {
        setSelectedDateRange(defaultDateRange);
        setSelectedYear(currentYear);
    };

    const handleDateRangePickerChange = (
        newDateRange: DateRangePickerState
    ) => {
        setSelectedDateRange(newDateRange);
    };

    const handleSelectedEntryModeChange = () => {
        setIsRequestingByCompetence(!isRequestingByCompetence);
    };

    const handleIncludeProvisionalChange = () => {
        setIncludeProvisional(!includeProvisional);
    };

    const handleSearch = () => {
        fetchBalance();
        setIsAccordionOpen(false);
    };

    const goToLedger = (accountId: string, accountName: string) => {
        const formattedStartDate = format(
            selectedDateRange.startDate!,
            "yyyy-MM-dd"
        );
        const formattedEndDate = format(
            selectedDateRange.endDate!,
            "yyyy-MM-dd"
        );

        navigate(
            routes.ledger(
                accountId,
                accountName,
                formattedStartDate,
                formattedEndDate,
                encodeURIComponent(
                    makeUrl(
                        url(BALANCE_BASE_URL)
                            .queryParam(
                                QueryParam.startDate,
                                formattedStartDate ?? undefined
                            )
                            .queryParam(
                                QueryParam.endDate,
                                formattedEndDate ?? undefined
                            )
                            .queryParam(
                                QueryParam.balanceByCompetence,
                                `${isRequestingByCompetence}`
                            )
                            .queryParam(
                                QueryParam.balanceWithAssetSimulation,
                                `${includeProvisional}`
                            )
                    )
                )
            ),
            {
                replace: true
            }
        );
    };

    return (
        <VaporPage headerLeft={<BackArrow />} title={fs("title")}>
            <div className="c-Balance-content">
                <Accordion
                    defaultExpanded={true}
                    isOpen={isAccordionOpen}
                    title={fs("filtersTitle")}
                    onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                >
                    <Grid container columnSpacing={3}>
                        <Grid item lg={4} xs={8}>
                            <SelectYear
                                numberOfYears={5}
                                onChange={handleSelectYearChange}
                                startingYear={currentYear}
                                value={selectedYear}
                            />
                        </Grid>
                        <Grid item>
                            <DateRangePicker
                                startDate={selectedDateRange.startDate}
                                endDate={selectedDateRange.endDate}
                                onChange={handleDateRangePickerChange}
                                isRequired={true}
                                errorStartDate={isNil(
                                    selectedDateRange.startDate
                                )}
                                errorEndDate={isNil(selectedDateRange.endDate)}
                                validate={(date) => !isNil(date)}
                            />
                        </Grid>
                        <Grid item lg={8} xs={12} paddingTop="24px">
                            <FormControl>
                                <FormControlLabel
                                    label={
                                        <Typography>
                                            {fs("viewByCompetence")}
                                        </Typography>
                                    }
                                    control={
                                        <Switch
                                            checked={isRequestingByCompetence}
                                            onChange={
                                                handleSelectedEntryModeChange
                                            }
                                        />
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                            <FormControl>
                                <FormControlLabel
                                    label={
                                        <Typography>
                                            {fs("includeProvisional")}
                                        </Typography>
                                    }
                                    control={
                                        <Switch
                                            checked={includeProvisional}
                                            onChange={
                                                handleIncludeProvisionalChange
                                            }
                                        />
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} flexDirection="row-reverse">
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={handleSearch}
                                disabled={selectedDateRange.error}
                            >
                                {fs("search")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleClearFilters}>
                                {fs("clearFilters")}
                            </Button>
                        </Grid>
                    </Grid>
                </Accordion>
                <Spinner loading={loading}>
                    {error ? (
                        <NotFoundBanner text={fs("noReportsForSearch")} />
                    ) : (
                        balance && (
                            <BalanceTable
                                balance={balance}
                                onBalanceEntryDetailClick={goToLedger}
                            />
                        )
                    )}
                </Spinner>
            </div>
        </VaporPage>
    );
}
