import React, { useContext } from "react";

import { DocumentDetailComponent } from "./DocumentDetailComponent";

export enum CloseReason {
    CLOSE = "CLOSE",
    CONFIRM = "CONFIRM",
    DELETE = "DELETE"
}

export interface DocumentDetailConfig {
    readOnly?: boolean;
    onClose?: (closeReason: CloseReason) => void;
}

interface DocumentDetailProvider {
    open: (draftId: string, config?: DocumentDetailConfig) => void;
}

export interface DocumentDetailComponentProps {
    children: any;
}

export const DocumentDetailContext =
    React.createContext<DocumentDetailProvider>({
        open: () => {}
    });

export function DocumentDetailWrapper({ children }: { children: any }) {
    return <DocumentDetailComponent>{children}</DocumentDetailComponent>;
}

export const useDocumentDetail = (): DocumentDetailProvider => {
    return useContext(DocumentDetailContext);
};

export const documentDetailService = () => ({
    target: "$REACT_ROOT_WRAPPER",
    handler: {
        component: ({ children }: any) => (
            <DocumentDetailWrapper>{children}</DocumentDetailWrapper>
        )
    }
});

export default DocumentDetailComponent;
