import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Box,
    Button,
    CircularProgress,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@vapor/react-material";
import { toPairs } from "lodash";
import { useState } from "react";

import {
    useAccountingLines,
    useCreateAccountingLine
} from "../../../../core/domain/AccountingLines/queries";
import { useCreateCSBAccount } from "../../../../core/domain/Accounts/queries";
import { useDraft } from "../../../../core/domain/Draft/queries";
import { useCA21Settings } from "../../../../core/domain/Setup/queries";
import { AccountDto } from "../../../../core/usecases/dtos/AccountsDto";
import { AccountingGroup } from "../../../../core/usecases/interfaces/AccountingGroup";
import { Reason } from "../../../../utils/appEnums";
import { isReadOnly } from "../../../../utils/draftUtils";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import BankDialog from "../../CSL/BankDialog";
import CustomButton from "../../CustomButton";
import TableHeadCell from "../../TableHead/TableHeadCell";
import TableHeadText from "../../TableHead/TableHeadText";
import AccountCell from "../AccountingLinesTable/cells/AccountCell";
import VatCompetencePeriodCell from "../AccountingLinesTable/cells/VatCompetencePeirodCell";
import DeleteButton from "../AccountingLinesTable/DeleteButton";
import groupAccountingLines from "../AccountingLinesTable/groupAccountingLines";
import SelectAccount from "../AccountingLinesTable/SelectAccount";
import { AmountCell, TotalCell } from "../AccountingLinesTable/table";
import CustomerSupplier from "./CustomerSupplier";

const fs = getFormattedStringWithScope("components.Accounting.CA21");

interface CA21RowProps {
    draftId: string;
    group: AccountingGroup;
    readOnly?: boolean;
}
const CA21Row = ({ draftId, group, readOnly = false }: CA21RowProps) => {
    const { data: settings, isSuccess: isSuccessSettings } = useCA21Settings();

    if (!isSuccessSettings) return null;

    const line = group.children[0];

    return (
        <TableRow>
            <AccountCell
                draftId={draftId}
                accountingGroup={group}
                isOpen={false}
                onToggleRow={() => {}}
                readOnly={readOnly}
                includeBalanceSheetAccounts={
                    settings.canSearchBalanceSheetAccounts
                }
                includeIncomeStatementAccounts={
                    settings.canSearchIncomeStatementAccounts
                }
            />
            <AmountCell
                draftId={draftId}
                accountingLinesIds={line.uuid!}
                type="debit"
                readOnly={readOnly}
            />
            <AmountCell
                draftId={draftId}
                accountingLinesIds={line.uuid!}
                type="credit"
                readOnly={readOnly}
            />
            {settings.canSetVatCompetencePeriod && (
                <VatCompetencePeriodCell
                    draftId={draftId}
                    accountingLineId={line.uuid ?? []}
                    readOnly={readOnly}
                    isAccountingReadOnly={readOnly}
                    isVisible={true}
                    reason={Reason.CA21}
                    endDate={line.endPeriodDate}
                    startDate={line.startPeriodDate}
                />
            )}
            {!readOnly && (
                <TableCell size="small" padding="checkbox" align="right">
                    <DeleteButton
                        draftId={draftId}
                        accountingLineId={line.uuid!}
                    />
                </TableCell>
            )}
        </TableRow>
    );
};

type CreateAccountFromButtonProps = {
    draftId: string;
};
const CreateAccountFromButton = ({ draftId }: CreateAccountFromButtonProps) => {
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [creatingCustomer, setCreatingCustomer] = useState<boolean>(false);
    const [creatingSupplier, setCreatingSupplier] = useState<boolean>(false);
    const [creatingNewBank, setCreatingNewBank] = useState<boolean>(false);

    const { data: detail, isSuccess } = useDraft(draftId);
    const {
        mutateAsync: create,
        isPending: isCreatingCSBAccount,
        error
    } = useCreateCSBAccount({ draftId });

    if (isSuccess) {
        const handleSelectCustomerSupplier = async (id: string) => {
            if (detail) {
                await create({
                    [`${creatingCustomer ? "customer" : "supplier"}Id`]: id
                });
                setCreatingCustomer(false);
                setCreatingSupplier(false);
            }
        };

        const handleSelectBank = async (id: string) => {
            if (detail) {
                await create({
                    bankId: id
                });
                setCreatingNewBank(false);
            }
        };

        return (
            <>
                <Button
                    variant="text"
                    size="small"
                    startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                    onClick={(event) => setMenuAnchor(event.currentTarget)}
                >
                    {fs("createAccountFrom")}
                </Button>
                <Menu
                    anchorEl={menuAnchor}
                    open={!!menuAnchor}
                    onClose={() => setMenuAnchor(null)}
                >
                    <MenuItem sx={{ padding: "0 !important" }}>
                        <Button
                            variant="text"
                            size="small"
                            startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                            onClick={() => {
                                setCreatingCustomer(true);
                            }}
                            sx={{
                                padding: 0,
                                width: "100%",
                                justifyContent: "flex-start"
                            }}
                        >
                            {fs("customer")}
                        </Button>
                    </MenuItem>
                    <MenuItem sx={{ padding: "0 !important" }}>
                        <Button
                            variant="text"
                            size="small"
                            startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                            onClick={() => {
                                setCreatingSupplier(true);
                            }}
                            sx={{
                                padding: 0,
                                width: "100%",
                                justifyContent: "flex-start"
                            }}
                        >
                            {fs("supplier")}
                        </Button>
                    </MenuItem>
                    <MenuItem sx={{ padding: "0 !important" }}>
                        <Button
                            variant="text"
                            size="small"
                            startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                            onClick={() => setCreatingNewBank(true)}
                            sx={{
                                padding: 0,
                                width: "100%",
                                justifyContent: "flex-start"
                            }}
                        >
                            {fs("newBank")}
                        </Button>
                    </MenuItem>
                </Menu>
                {(creatingCustomer || creatingSupplier) && (
                    <CustomerSupplier
                        open={true}
                        onSelect={handleSelectCustomerSupplier}
                        onClose={() => {
                            setCreatingCustomer(false);
                            setCreatingSupplier(false);
                        }}
                        loading={isCreatingCSBAccount}
                        error={error}
                        relationType={
                            creatingCustomer ? "CUSTOMER" : "SUPPLIER"
                        }
                    />
                )}
                {creatingNewBank && (
                    <BankDialog
                        loading={isCreatingCSBAccount}
                        onClose={() => setCreatingNewBank(false)}
                        onSelect={handleSelectBank}
                        open={true}
                        error={error}
                    />
                )}
            </>
        );
    }
    return null;
};

type CTAsProps = {
    draftId: string;
};
const CTAs = (props: CTAsProps) => {
    const [isAddingAccount, setIsAddingAccount] = useState<boolean>(false);

    const { data: settings, isSuccess: isSuccessSettings } = useCA21Settings();
    const { mutateAsync: create, isPending } = useCreateAccountingLine({
        draftId: props.draftId
    });

    if (!isSuccessSettings) {
        return null;
    }

    const handleAddAccountingLine = async (account: AccountDto) => {
        await create({
            accountCode: account.code
        });
        setIsAddingAccount(false);
    };

    if (isAddingAccount) {
        return (
            <Stack
                direction="column"
                alignItems="flex-start"
                width="50%"
                mt="-39px"
            >
                <SelectAccount
                    draftId={props.draftId}
                    onChange={handleAddAccountingLine}
                    autoFocus={true}
                    hideCustomerSupplier={false}
                    includeBalanceSheetAccounts={
                        settings.canSearchBalanceSheetAccounts
                    }
                    includeIncomeStatementAccounts={
                        settings.canSearchIncomeStatementAccounts
                    }
                />
                <Stack direction="row">
                    <Button
                        size="small"
                        variant="text"
                        onClick={() => setIsAddingAccount(false)}
                    >
                        {fs("cancelAdding")}
                    </Button>
                    <CreateAccountFromButton draftId={props.draftId} />
                </Stack>
            </Stack>
        );
    } else {
        return (
            <Stack direction="row" mt="-35px">
                <CustomButton
                    variant="text"
                    size="small"
                    startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                    loading={isPending}
                    onClick={() => setIsAddingAccount(true)}
                >
                    {fs("account")}
                </CustomButton>
                <CreateAccountFromButton draftId={props.draftId} />
            </Stack>
        );
    }
};

interface AccountingProps {
    draftId: string;
}
const Accounting = ({ draftId }: AccountingProps) => {
    const {
        data: draft,
        isLoading: isLoadingDraft,
        isSuccess: isSuccessDraft
    } = useDraft(draftId);
    const {
        data: accountingLines,
        isLoading: isLoadingAccountingLines,
        isSuccess: isSuccessAccontingLines
    } = useAccountingLines({ draftId });
    const {
        data: settings,
        isLoading: isLoadingSettings,
        isSuccess: isSuccessSettings
    } = useCA21Settings();

    if (isLoadingDraft || isLoadingAccountingLines || isLoadingSettings) {
        return <CircularProgress />;
    }

    if (isSuccessDraft && isSuccessAccontingLines && isSuccessSettings) {
        const { canSetVatCompetencePeriod } = settings;

        const readOnly = isReadOnly(draft?.status);

        const groupedAccountingLines = groupAccountingLines(accountingLines, {
            sort: false,
            incluepreviousBalance: false
        });

        return (
            <>
                <Table>
                    <TableHead shadow>
                        <TableRow>
                            <TableHeadCell>
                                <TableHeadText>{fs("account")}</TableHeadText>
                            </TableHeadCell>
                            <TableHeadCell align="right">
                                <TableHeadText>{fs("give")}</TableHeadText>
                            </TableHeadCell>
                            <TableHeadCell align="right">
                                <TableHeadText>{fs("take")}</TableHeadText>
                            </TableHeadCell>
                            {canSetVatCompetencePeriod && (
                                <TableHeadCell align="right">
                                    <TableHeadText>
                                        {fs("vatCompetencePeriod")}
                                    </TableHeadText>
                                </TableHeadCell>
                            )}
                            {!readOnly && (
                                <TableHeadCell align="right">
                                    {/* actions */}
                                </TableHeadCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {toPairs(groupedAccountingLines).map(
                            ([_key, accountingGroup], index) => (
                                <CA21Row
                                    key={index}
                                    draftId={draftId}
                                    readOnly={readOnly}
                                    group={accountingGroup}
                                />
                            )
                        )}
                        <TableRow>
                            <TableCell></TableCell>
                            <TotalCell draftId={draftId} type="debit" />
                            <TotalCell draftId={draftId} type="credit" />
                            {canSetVatCompetencePeriod && (
                                <TableCell></TableCell>
                            )}
                            {!readOnly && <TableCell></TableCell>}
                        </TableRow>
                    </TableBody>
                </Table>
                <Box mt={-3}>{!readOnly && <CTAs draftId={draftId} />}</Box>
            </>
        );
    }

    return null;
};

export default Accounting;
