import { Spinner } from "@comic/precog-components";
import { VaporToolbar } from "@vapor/react-custom";
import { Times } from "@vapor/react-icons";
import {
    Box,
    Button,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack
} from "@vapor/react-material";
import { isNil } from "lodash";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";

import usePrevious from "../../../core/commons/hooks/usePrevious";
import { useGetProcedureEntryDetail } from "../../../core/usecases/useGetProcedureEntryDetail";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import NotFoundBanner from "../NotFoundBanner";
import OverlayDialog from "../OverlayDialog";
import Header from "./Header";
import ProcedureEntryDetailAccounting from "./ProcedureEntryAccounting";

interface ProcedureEntryDetailProps {
    originalEntryId: string;
    onClose: () => void;
}

export default function ProcedureEntryDetail({
    originalEntryId,
    onClose
}: ProcedureEntryDetailProps) {
    const fs = useFormattedStringWithScope("components.ProcedureEntryDetail");
    const { get, detail, loading } = useGetProcedureEntryDetail();
    const previousOriginalEntryId = usePrevious(originalEntryId);

    useEffect(() => {
        if (
            !isNil(originalEntryId) &&
            previousOriginalEntryId !== originalEntryId
        ) {
            get(originalEntryId);
        }
    }, [originalEntryId, previousOriginalEntryId, get]);

    return (
        <OverlayDialog
            fullScreen={true}
            open={!isNil(originalEntryId)}
            onClose={onClose}
        >
            <DialogTitle>
                {/* for spacing */}
                <Box></Box>
                {fs("procedureEntryDetail")}
                <IconButton color="primary" onClick={onClose}>
                    <Times />
                </IconButton>
            </DialogTitle>
            <Divider variant="fullWidth" /> <Divider />
            <ErrorBoundary fallback={<NotFoundBanner text={fs("error")} />}>
                <DialogContent>
                    {loading ? (
                        <Spinner loading>
                            <Box height={300}></Box>
                        </Spinner>
                    ) : (
                        detail && (
                            <Stack spacing={2} padding={2}>
                                <Header detail={detail} />
                                <ProcedureEntryDetailAccounting
                                    detail={detail}
                                />
                            </Stack>
                        )
                    )}
                </DialogContent>
                <VaporToolbar
                    contentLeft={
                        <Button variant="outlined" onClick={onClose}>
                            {fs("close")}
                        </Button>
                    }
                />
            </ErrorBoundary>
        </OverlayDialog>
    );
}
