import { useMutation } from "@tanstack/react-query";

import { useSnackbar } from "../../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";
import { SimulationAssetSaleDto } from "../../../usecases/dtos/SimulationAssetSaleDto";
import {
    usePostAssetSaleSimulation,
    UsePostAssetSaleSimulationOptions
} from "../../../usecases/usePostAssetSaleSimulation";

export const usePostAssetSaleSimulationWithParam = ({
    assetId,
    dataToPost
}: UsePostAssetSaleSimulationOptions) => {
    const { enqueueSnackbar } = useSnackbar();
    const fs = useFormattedStringWithScope("notifications.ClosePeriod");

    const { fetchWithParameters } = usePostAssetSaleSimulation({
        assetId,
        dataToPost
    });
    return useMutation({
        mutationKey: ["post-asset-sale-simulation-param"],
        mutationFn: async ({
            assetId,
            dataToPost
        }: UsePostAssetSaleSimulationOptions): Promise<SimulationAssetSaleDto> => {
            return fetchWithParameters(assetId, dataToPost);
        },
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" })
    });
};

export const usePostAssetSaleSimulationWithoutParam = ({
    assetId,
    dataToPost
}: UsePostAssetSaleSimulationOptions) => {
    const { enqueueSnackbar } = useSnackbar();
    const fs = useFormattedStringWithScope("notifications.ClosePeriod");

    const { fetch } = usePostAssetSaleSimulation({
        assetId,
        dataToPost
    });
    return useMutation({
        mutationKey: ["post-asset-sale-simulation"],
        mutationFn: async (): Promise<SimulationAssetSaleDto> => fetch(),
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" })
    });
};
