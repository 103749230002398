import isNil from "lodash/isNil";
import { DepreciationSimulationResponseDto } from "../dtos/DepreciationSimulationDto";

export default function processData(
    data: DepreciationSimulationResponseDto
): DepreciationSimulationResponseDto {
    return isNil(data) ? data : removeAccountNameFromChildren(data);
}

function removeAccountNameFromChildren(
    data: DepreciationSimulationResponseDto
): DepreciationSimulationResponseDto {
    return data.map((depreciation) => ({
        ...depreciation,
        business: depreciation.children?.at(0)?.business ?? "",
        children: depreciation.children.map((depreciationChild) => ({
            ...depreciationChild,
            depreciationTitle: depreciationChild.depreciationTitle.replace(
                depreciation.depreciationTitle,
                ""
            )
        }))
    }));
}
