import { Stack, TextField } from "@vapor/react-material";
import { debounce, isNil, pick } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
    NumberFormatValues,
    NumericFormat,
    NumericFormatProps
} from "react-number-format";

import usePrevious from "../../../core/commons/hooks/usePrevious";

export interface PercentageFieldProps
    extends Pick<NumericFormatProps, "readOnly" | "placeholder"> {
    value: number | null;
    onBlur?: (value: number | null) => void;
    onChange: (value: number | null) => void;
    transparent?: boolean;
    big?: boolean;
    error?: boolean;
    allowNullValues?: boolean;
}

export default React.forwardRef(function PercentageField(
    {
        value,
        onBlur,
        onChange,
        transparent = false,
        big = false,
        error = false,
        allowNullValues = false,
        ...numericFormatProps
    }: PercentageFieldProps,
    ref
) {
    const { formatNumber } = useIntl();

    const [internalValue, setInternalValue] = useState<number | null>(value);
    const previousInternalValue = usePrevious(internalValue);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    const handleInputChange = (newValue: NumberFormatValues) => {
        if (isNil(newValue.floatValue)) {
            onChange(0);
            setInternalValue(0);
        } else {
            onChange(newValue.floatValue);
            setInternalValue(newValue.floatValue);
        }
    };

    const debouncedHandleInputChange = debounce(handleInputChange, 300);

    const handleBlur = () => {
        !numericFormatProps.readOnly &&
            internalValue !== previousInternalValue &&
            onBlur?.(internalValue);
    };

    const decimalSeparator = useMemo(
        () => formatNumber(0.1).replace(/\d/g, ""),
        [formatNumber]
    );
    const thousandSeparator = useMemo(
        () => formatNumber(1000).replace(/\d/g, ""),
        [formatNumber]
    );

    return (
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <NumericFormat
                value={allowNullValues ? internalValue : internalValue ?? 0}
                customInput={TextField}
                suffix="%"
                decimalSeparator={decimalSeparator}
                thousandSeparator={thousandSeparator}
                decimalScale={2}
                fixedDecimalScale
                onValueChange={(values, _) =>
                    values && debouncedHandleInputChange(values)
                }
                inputRef={ref}
                onBlur={handleBlur}
                InputProps={{
                    error: error,
                    sx: {
                        bgcolor: transparent ? "transparent !important" : null,
                        fieldset: {
                            border: transparent && !error ? "none" : null
                        },
                        "input ": {
                            textAlign: "right",
                            fontSize: big ? "22px" : null,
                            fontWeight: big ? "bold" : null
                        },
                        "& .MuiInputBase-input.MuiOutlinedInput-input:read-only":
                            {
                                backgroundColor: "unset !important"
                            }
                    }
                }}
                isAllowed={({ floatValue }) =>
                    (allowNullValues && !floatValue) ||
                    (floatValue ? floatValue >= 0 && floatValue <= 100 : true)
                }
                {...pick(numericFormatProps, "readOnly", "placeholder")}
            />
        </Stack>
    );
});
