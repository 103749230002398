export enum TaskStatus {
    INACTIVE = "INACTIVE",
    TODO = "TODO",
    ERROR = "ERROR",
    SIMULATING = "SIMULATING",
    SIMULATED = "SIMULATED",
    SIMULATION_ERROR = "SIMULATION_ERROR",
    GENERATING = "GENERATING",
    GENERATED = "GENERATED",
    GENERATION_ERROR = "GENERATION_ERROR",
    COMPLETED = "COMPLETED",
    REOPENING = "REOPENING",
    REOPEN_ERROR = "REOPEN_ERROR",
    CANCELING = "CANCELING",
    CANCELING_ERROR = "CANCELING_ERROR"
}
