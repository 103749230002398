import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";

import {
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    Stack,
    useTheme
} from "@vapor/react-material";
import isNil from "lodash/isNil";

import { TaskStatus } from "../../../../core/usecases/dtos/TaskStatus";
import FormattedDate from "../../../components/FormattedDate";
import TaskIcon from "./TaskIcon";

export interface TaskListItem {
    completed: boolean;
    onArrowClick?: () => void;
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    status?: TaskStatus | string;
}

interface TasksListProps {
    tasks: TaskListItem[];
}

interface TaskLabelProps {
    date?: string;
    title: string;
}

export const TaskLabel = ({ date, title }: TaskLabelProps) => {
    return (
        <Stack>
            {isNil(date) ? null : (
                <FormattedDate variant="caption" date={date} />
            )}
            <Typography>{title}</Typography>
        </Stack>
    );
};

export default function TasksList({ tasks }: TasksListProps) {
    const theme = useTheme();
    return (
        <List>
            {tasks.map((task, index) => (
                <ListItem
                    key={index}
                    secondaryAction={
                        !isNil(task.onArrowClick) &&
                        task.status !== TaskStatus.INACTIVE ? (
                            <IconButton onClick={task.onArrowClick}>
                                <VaporIcon
                                    color="primary"
                                    icon={faArrowRight}
                                />
                            </IconButton>
                        ) : (
                            <IconButton>
                                <VaporIcon
                                    color="disabled"
                                    icon={faArrowRight}
                                />
                            </IconButton>
                        )
                    }
                >
                    <ListItemIcon>
                        <TaskIcon status={task.status} />
                    </ListItemIcon>
                    <Stack
                        color={
                            task.status === TaskStatus.INACTIVE &&
                            theme.palette.grey["500"]
                        }
                    >
                        {task.title}
                        {task.subtitle}
                    </Stack>
                </ListItem>
            ))}
        </List>
    );
}
