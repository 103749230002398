import { useRequest } from "@onefront/react-sdk";
import format from "date-fns/format";
import isNil from "lodash/isNil";
import { useCallback } from "react";

import { API_DATE_FORMAT, API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface UseCreateAccountingPeriodOptions {
    lazy?: boolean;
}

export interface CreateAccountingPeriodOptions {
    startDate: Date;
    endDate: Date;
    onResponse?: () => void;
}

export interface UseCreateAccountingPeriod {
    fetch: (options: CreateAccountingPeriodOptions) => void;
    loading: boolean;
    error: any;
}

export default function useCreateAccountingPeriod({
    lazy
}: UseCreateAccountingPeriodOptions): UseCreateAccountingPeriod {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope(
        "notifications.CreateAccountingPeriod"
    );

    const customerData = useCustomerData();

    const {
        fetch: fetchData,
        loading,
        error
    } = useRequest({
        url: `${API_URL}/v1/ledger/accountingPeriods`,
        method: "post",
        ...useAxiosConfigRequestParams("CreateAccountingPeriod"),
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        lazy: lazy
    });

    const fetch = useCallback(
        ({ startDate, endDate, onResponse }: CreateAccountingPeriodOptions) => {
            fetchData({
                params: {
                    ...customerData
                },
                data: {
                    startDate: format(startDate, API_DATE_FORMAT),
                    endDate: format(endDate, API_DATE_FORMAT)
                },
                ...(isNil(onResponse) ? undefined : onResponse)
            });
        },
        [fetchData, customerData]
    );

    return {
        fetch: fetch,
        loading: loading,
        error: error
    };
}
