import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import { DepreciationStatus } from "../../usecases/dtos/CheckDepreciationDto";
import {
    CancelDepreciationOptions,
    useCancelDepreciation as useCancelDepreciationApi
} from "../../usecases/useCancelDepreciation";
import {
    GenerateDepreciationOptions,
    useGenerateDepreciation as useGenerateDepreciationApi
} from "../../usecases/useGenerateDepreciation";
import {
    GetDepreciationOptions,
    useGetDepreciation
} from "../../usecases/useGetDepreciation";
import useSimulateDepreciation, {
    SimulateDepreciationOptions,
    SuspensionConfigurations
} from "../../usecases/useSimulateDepreciation";
import { accountingPeriodQueryKeys } from "../AccountingPeriods/queries";

export const fetchDepreciationKey = createQueryKeys("fetch-depreciation", {
    all: null,
    getDepreciation: (params: { period: string }) => [params],
    getSimulateDepreciation: (params: {
        businesess?: string[];
        suspensionConfigurations?: Map<string, SuspensionConfigurations>;
        startDate: string;
        endDate: string;
    }) => [params]
});

export const useFetchDepreciation = (
    { period }: GetDepreciationOptions,
    depreciationStatus: DepreciationStatus | string
) => {
    const { fetch } = useGetDepreciation();

    return useQuery({
        ...fetchDepreciationKey.getDepreciation({ period }),
        enabled: depreciationStatus === DepreciationStatus.TODO,
        queryFn: () => fetch({ period })
    });
};

export const useFetchSimulateDepreciation = (
    {
        businesess,
        suspensionConfigurations,
        startDate,
        endDate
    }: SimulateDepreciationOptions,
    depreciationStatus: DepreciationStatus | string
) => {
    const { simulateDepreciation } = useSimulateDepreciation();

    return useQuery({
        ...fetchDepreciationKey.getSimulateDepreciation({
            businesess,
            suspensionConfigurations,
            startDate,
            endDate
        }),
        enabled: depreciationStatus !== DepreciationStatus.TODO,
        queryFn: () =>
            simulateDepreciation({
                businesess,
                suspensionConfigurations,
                startDate,
                endDate
            })
    });
};

export const useGenerateDepreciation = (accountingPeriodId: string) => {
    const queryClient = useQueryClient();

    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope(
        "notifications.GenerateDepreciation"
    );

    const { generateDepreciation } = useGenerateDepreciationApi();

    return useMutation({
        mutationKey: ["generate-deprecation"],
        mutationFn: async ({
            map = {},
            date,
            period
        }: GenerateDepreciationOptions) => {
            return generateDepreciation({
                map,
                date,
                period
            });
        },
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        onSuccess: () => {
            enqueueSnackbar(fs("success"), { severity: "success" });
            queryClient.invalidateQueries(
                accountingPeriodQueryKeys.getTasks(accountingPeriodId)
            );
        }
    });
};

export const useCancelDepreciation = (accountingPeriodId: string) => {
    const queryClient = useQueryClient();

    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.CancelDepreciation");

    const { cancelDepreciation } = useCancelDepreciationApi();

    return useMutation({
        mutationKey: ["cancel-deprecation"],
        mutationFn: async ({
            workspaceId,
            period
        }: CancelDepreciationOptions) => {
            return cancelDepreciation({
                workspaceId,
                period
            });
        },
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        onSuccess: () => {
            enqueueSnackbar(fs("success"), { severity: "success" });
            queryClient.invalidateQueries(
                accountingPeriodQueryKeys.getTasks(accountingPeriodId)
            );
        }
    });
};
