import { TableCell } from "@vapor/react-material";
import { format, isValid, parse } from "date-fns";
import { debounce, isNil } from "lodash";
import { useState } from "react";

import { API_DATE_FORMAT } from "../../../../../config";
import { useMutateAccountingLine } from "../../../../../core/domain/AccountingLines/queries";
import { Reason } from "../../../../../utils/appEnums";
import { needsCompetence } from "../../../../../utils/reasonUtils";
import { DateRangePickerState } from "../../../DateRangePicker";
import { TransparentDateRangePicker } from "../styled";

interface VatCompetencePeriodCellProps {
    accountingLineId: string | string[];
    draftId: string;
    isAccountingReadOnly: boolean;
    startDate?: string;
    endDate?: string;
    reason: Reason;
    isVisible: boolean;
    readOnly?: boolean;
}

export default function VatCompetencePeriodCell({
    accountingLineId,
    draftId,
    endDate,
    startDate,
    isAccountingReadOnly,
    reason,
    isVisible,
    readOnly
}: VatCompetencePeriodCellProps) {
    const { mutate } = useMutateAccountingLine({
        accountingLineId,
        draftId
    });

    const [dateRange, setDateRange] = useState<DateRangePickerState>({
        endDate:
            (!isNil(endDate) && parse(endDate, API_DATE_FORMAT, new Date())) ||
            null,
        startDate:
            (!isNil(startDate) &&
                parse(startDate, API_DATE_FORMAT, new Date())) ||
            null,
        error: false
    });

    const handleDateRangeChange = (dateRange: DateRangePickerState) => {
        setDateRange(dateRange);
        mutate({
            startPeriodDate:
                (dateRange.startDate &&
                    format(dateRange.startDate, API_DATE_FORMAT)) ??
                undefined,
            endPeriodDate:
                (dateRange.endDate &&
                    format(dateRange.endDate, API_DATE_FORMAT)) ??
                undefined
        });
    };

    const debouncedHandleDateChange = debounce(handleDateRangeChange, 500);

    return needsCompetence(reason) ? (
        <TableCell sx={{ maxWidth: "300px" }} size="small">
            {!isAccountingReadOnly && isVisible && (
                <TransparentDateRangePicker
                    endDate={
                        isValid(dateRange.endDate) ? dateRange.endDate : null
                    }
                    startDate={
                        isValid(dateRange.startDate)
                            ? dateRange.startDate
                            : null
                    }
                    validate={(date) => isValid(date)}
                    onChange={debouncedHandleDateChange}
                    showLabels={false}
                    separator="-"
                    readOnly={readOnly}
                />
            )}
        </TableCell>
    ) : null;
}
