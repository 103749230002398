import { Spinner } from "@comic/precog-components";
import { OneBoxPage, useCurrentWorkspace } from "@drift/oneplatfront";
import Typography from "@vapor/react-extended/ExtendedTypography";
import FormControl from "@vapor/react-material/FormControl";
import Grid from "@vapor/react-material/Grid";
import InputLabel from "@vapor/react-material/InputLabel";
import TextField from "@vapor/react-material/TextField";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { useEffect, useState } from "react";

import usePrevious from "../../../core/commons/hooks/usePrevious";
import { AccountDto } from "../../../core/usecases/dtos/AccountsDto";
import { useGetPagedAccounts } from "../../../core/usecases/useGetPagedAccounts";
import { KeyBoardEventCode } from "../../../utils/appEnums";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import BackArrow from "../../components/BackArrow";
import NotFoundBanner from "../../components/NotFoundBanner";
import AccountsTable from "./AccountsTable";
import "./style.css";
import { IconButton, InputAdornment } from "@vapor/react-material";
import { Search } from "@vapor/react-icons";

const fs = getFormattedStringWithScope("views.ChartAccounts");

export default function ChartAccounts() {
    const [searchString, setSearchString] = useState<string>("");
    const [highlightedAccount, setHighlightedAccount] =
        useState<AccountDto | null>(null);
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const { workspace: customer } = useCurrentWorkspace(true);
    const previousCustomer = usePrevious(customer);
    const hasSelectedCustomer = !isNil(customer);

    const {
        fetch: fetchAccounts,
        fetchMore: fetchMoreAccounts,
        accounts,
        loading: loadingAccounts,
        loadingMore: loadingMoreAccounts,
        error: errorAccounts,
        hasFetched: hasFetchedAccounts,
        hasMore: hasMoreAccounts
    } = useGetPagedAccounts({
        includeSystem: true,
        lazy: true
    });

    useEffect(() => {
        if (hasSelectedCustomer && !hasInitialized) {
            fetchAccounts();
            setHasInitialized(true);
        }
    }, [hasSelectedCustomer, hasInitialized, fetchAccounts]);

    useEffect(() => {
        if (previousCustomer !== customer) {
            fetchAccounts(searchString);
        }
    }, [previousCustomer, customer, fetchAccounts, searchString]);

    const handleSearchInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchString(event.target.value);
        if (event.target.value === "") fetchAccounts();
    };

    const handleDuplicateAccount = async (account: AccountDto) => {
        setSearchString(account.description!);
        setHighlightedAccount(account);
        fetchAccounts(account.description!);
    };

    const handleSearchAccounts = () => {
        fetchAccounts(searchString);
    };

    return (
        <OneBoxPage headerLeft={<BackArrow />} title={fs("title")}>
            <div className="v-accounts-content">
                {hasSelectedCustomer ? (
                    <>
                        <Grid container spacing={3} marginBottom="16px">
                            <Grid item xs={12} lg={4}>
                                <FormControl fullWidth>
                                    <InputLabel>
                                        {fs("selectAccount")}
                                    </InputLabel>
                                    <TextField
                                        type="search"
                                        value={searchString}
                                        onKeyDown={(event) =>
                                            event.code ===
                                                KeyBoardEventCode.ENTER &&
                                            handleSearchAccounts()
                                        }
                                        onChange={handleSearchInputChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    sx={{
                                                        marginRight:
                                                            "0 !important"
                                                    }}
                                                >
                                                    <IconButton
                                                        onClick={
                                                            handleSearchAccounts
                                                        }
                                                    >
                                                        <Search />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Spinner loading={loadingAccounts}>
                            {hasFetchedAccounts &&
                            isEmpty(accounts?.accounts) ? (
                                <NotFoundBanner text={fs("notFound")} />
                            ) : errorAccounts ? (
                                <NotFoundBanner
                                    text={fs("errorLoadingAccounts")}
                                />
                            ) : accounts?.accounts ? (
                                <AccountsTable
                                    accounts={accounts.accounts}
                                    highlightedAccount={highlightedAccount}
                                    hasMore={hasMoreAccounts}
                                    loadMore={fetchMoreAccounts}
                                    isLoadingMore={loadingMoreAccounts}
                                    onDuplicateAccount={handleDuplicateAccount}
                                />
                            ) : null}
                        </Spinner>
                    </>
                ) : (
                    <Typography>{fs("selectCustomerToView")}</Typography>
                )}
            </div>
        </OneBoxPage>
    );
}
