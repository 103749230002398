import Typography from "@vapor/react-extended/ExtendedTypography";
import FormattedAmount from "../FormattedAmount";

interface BoldAmountWithLabelProps {
    label: string;
    amount: number;
    currency: string;
}

export default function BoldAmountWithLabel({
    label,
    amount,
    currency
}: BoldAmountWithLabelProps) {
    return (
        <>
            <Typography variant="body">{label}</Typography>
            <FormattedAmount
                variant="bodyLarge500"
                amount={amount}
                currency={currency}
            />
        </>
    );
}
