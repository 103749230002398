import { usePayments } from "./queries";

export const usePaymentsTotalAmount = (draftId: string) => {
    const { data: payments, isLoading, isSuccess } = usePayments({ draftId });

    if (isLoading || !isSuccess) {
        return 0;
    }

    return parseFloat(
        payments
            .filter((payment) => !payment.lineDisabled)
            .map((payment) => payment.amount)
            .reduce((total, amount) => total + amount, 0)
            .toFixed(2)
    );
};
