import { FormControl, InputLabel, Tooltip } from "@vapor/react-material";
import {
    DatePicker,
    DatePickerProps
} from "@vapor/react-x-date-pickers/DatePicker";
import { debounce, isNil } from "lodash";
import { Controller, useFormContext } from "react-hook-form";

import { FormField } from "./types";

export interface FormDatePickerProps
    extends FormField,
        Omit<DatePickerProps<unknown, unknown>, "label" | "name"> {
    validate?: (value: Date | null) => boolean;
    invalidMessage?: string;
    readOnly?: boolean;
}

export default function FormDatePicker({
    name,
    label,
    required,
    validate,
    invalidMessage,
    disabled,
    readOnly = false,
    ...datepickerProps
}: FormDatePickerProps) {
    const { control, trigger } = useFormContext();

    return (
        <Controller
            shouldUnregister={true}
            control={control}
            name={name}
            rules={{
                required: required && !disabled,
                validate: (value: any) => {
                    if (!isNil(validate)) {
                        return !isNil(value) && validate(value);
                    }
                    return required ? !isNil(value) : true;
                }
            }}
            render={({ field, fieldState }) => {
                const onChange = debounce((e: unknown) => {
                    field.onChange(e);
                    trigger();
                }, 500);
                return (
                    <FormControl error={!isNil(fieldState.error)} fullWidth>
                        {label ? (
                            <InputLabel required={required && !disabled}>
                                {label}
                            </InputLabel>
                        ) : null}
                        <Tooltip
                            title={
                                fieldState.invalid ? invalidMessage : undefined
                            }
                            arrow
                        >
                            <div>
                                <DatePicker
                                    {...datepickerProps}
                                    {...field}
                                    onChange={onChange}
                                    slotProps={{
                                        openPickerIcon: {
                                            sx: {
                                                width: "26px"
                                            }
                                        },
                                        inputAdornment: {
                                            sx: {
                                                marginLeft: "-10px !important"
                                            }
                                        }
                                    }}
                                    disabled={disabled}
                                    readOnly={readOnly}
                                    sx={{
                                        border: fieldState.invalid
                                            ? "1px solid #D82829"
                                            : "inherit",
                                        width: "100%"
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </FormControl>
                );
            }}
        />
    );
}
