import { BusinessDto } from "../core/usecases/dtos/BusinessDto";
import { AccountingType } from "./appEnums";

export function isSimplifiedAccounting(business: BusinessDto) {
    return (
        !!business.accountingConfiguration &&
        business.accountingConfiguration.accountingType ===
            AccountingType.SIMPLIFIED
    );
}
