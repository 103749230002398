import {
    CollapsibleRowType,
    ColumnElement,
    ColumnType
} from "@comic/precog-components";
import { faArrowRight, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import IconButton from "@vapor/react-material/IconButton";
import isNil from "lodash/isNil";
import { useState } from "react";

import { VatEntries } from "../../../../../../../core/usecases/dtos/VatEntriesDto";
import { useDetail } from "../../../../../../../services/DetailFacade";
import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../../../components/FormattedAmount";
import { AliquotesTable } from "./AliquotesTable";

const fs = getFormattedStringWithScope("commons.VatEnum");

export const Columns = (): ColumnElement[] => {
    const [originalEntryId, setOriginalEntryId] = useState<string | null>(null);

    const { open, isOpening } = useDetail();

    const handeSelectVatEntry = async (registration: VatEntries) => {
        setOriginalEntryId(registration.registrationUuid);
        await open(registration, registration.reasonId, {
            readOnly: true
        });
    };

    return [
        // registrationDate
        {
            kind: ColumnType.text,
            render: (detail: VatEntries) => {
                return <Typography>{detail.registrationDate}</Typography>;
            }
        },
        // reasonDescription
        {
            kind: ColumnType.text,
            render: (detail: VatEntries) => (
                <Typography>{detail.reasonDescription}</Typography>
            )
        },
        // docNumber
        {
            kind: ColumnType.text,
            render: (detail: VatEntries) => (
                <Typography>{detail.docNumber}</Typography>
            )
        },
        // docDate
        {
            kind: ColumnType.text,
            render: (detail: VatEntries) => {
                return <Typography>{detail.docDate}</Typography>;
            }
        },
        // counterpart
        {
            kind: ColumnType.text,
            render: (detail: VatEntries) => {
                const counterpartName =
                    detail.customer?.customerName ||
                    detail.supplier?.supplierName;
                return <Typography>{counterpartName}</Typography>;
            }
        },
        // counterpartId
        {
            kind: ColumnType.text,
            render: (detail: VatEntries) => {
                const counterpartId = isNil(detail.customer)
                    ? detail.supplier?.supplierVatNumber ||
                      detail.supplier?.supplierTaxId
                    : detail.customer?.customerVatNumber ||
                      detail.customer?.customerTaxId;
                return <Typography>{counterpartId}</Typography>;
            }
        },
        // vatRate
        {
            kind: ColumnType.text,
            render: (detail: VatEntries) => {
                const vatRate = detail.vatLines?.[0]?.vatRate;
                return (
                    <Typography>
                        {detail.vatLines.length > 1
                            ? "..."
                            : vatRate
                            ? fs(vatRate)
                            : // TODO: handle empty vat lines
                              " - "}
                    </Typography>
                );
            }
        },
        // ctaShowVat
        {
            kind: ColumnType.collapse,
            type: CollapsibleRowType.CHILD_ROWS,
            hasCollapseContent: (detail: VatEntries) =>
                detail.vatLines.length > 1,
            render: (detail: VatEntries) =>
                detail.vatLines.length > 1 ? (
                    <AliquotesTable data={detail.vatLines} />
                ) : (
                    <div />
                )
        },
        // taxable
        {
            kind: ColumnType.numeric,
            render: (detail: VatEntries) => (
                <FormattedAmount
                    amount={detail.vatLines.reduce(
                        (accumulator, line) => accumulator + line.taxable,
                        0
                    )}
                    currency="EUR"
                />
            )
        },
        // tax
        {
            kind: ColumnType.numeric,
            render: (detail: VatEntries) => (
                <FormattedAmount
                    amount={detail.vatLines.reduce(
                        (accumulator, line) => accumulator + line.tax,
                        0
                    )}
                    currency="EUR"
                />
            )
        },
        // total
        {
            kind: ColumnType.numeric,
            render: (detail: VatEntries) => (
                <FormattedAmount
                    amount={detail.vatLines.reduce(
                        (accumulator, line) =>
                            accumulator + line.tax + line.taxable,
                        0
                    )}
                    currency="EUR"
                />
            )
        },
        // ctaDetail
        {
            kind: ColumnType.cta,
            render: (detail: VatEntries) => (
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handeSelectVatEntry(detail)}
                >
                    <FontAwesomeIcon
                        icon={
                            detail.registrationUuid === originalEntryId &&
                            isOpening
                                ? faSpinner
                                : faArrowRight
                        }
                    />
                </IconButton>
            )
        }
    ];
};
