import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { DraftWithholdingDto } from "../dtos/DraftWithholdingDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

export interface UseGetDraftWithholdings {
    loading: boolean;
    error: any;
    withholdings: DraftWithholdingDto[];
    get: (draftId: string) => Promise<DraftWithholdingDto[]>;
}

export const useGetDraftWithholdings = (): UseGetDraftWithholdings => {
    const customerData = useCustomerData();

    const { fetch, data, loading, error } = useGet(`ignored`, {
        ...useAxiosConfigRequestParams("DraftWithholdings"),
        lazy: true
    });

    const get = async (draftId: string) => {
        return fetch({
            url: `${API_URL}/v1/precog/drafts/${draftId}/withholdings`,
            params: customerData
        }).then(
            (response: AxiosResponse<DraftWithholdingDto[]>) => response.data
        );
    };

    return {
        get,
        loading: loading,
        error: error,
        withholdings: data
    };
};
