import {
    ColumnElement,
    ColumnType,
    HeadCell,
    Spinner,
    Table
} from "@comic/precog-components";
import {
    faArrowRight,
    faCircleNotch
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack } from "@vapor/react-material";
import Box from "@vapor/react-material/Box";
import { useState } from "react";
import { Waypoint } from "react-waypoint";

import { Entries } from "../../../../core/usecases/dtos/AccountedForDto";
import { useDetail } from "../../../../services/DetailFacade";
import { CloseReason } from "../../../../services/DocumentDetail";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../components/FormattedAmount";

const fs = getFormattedStringWithScope("views.Dashboard.accountedFor.table");

interface AccountedForTableProps {
    entriesData: Entries[];
    fetchMore: Function;
    isLoadingMore: boolean;
    hasMore: boolean;
}

export default function AccountedForTable({
    fetchMore,
    isLoadingMore,
    hasMore,
    entriesData: entriesDataProp
}: AccountedForTableProps) {
    const [originalEntryId, setOriginalEntryId] = useState<string | null>(null);
    const [deletedEntries, setDeletedEntries] = useState<string[]>([]);

    const { open, isOpening } = useDetail();

    const handleCloseDocumentDetail = (
        closeReason: CloseReason,
        entryId: string
    ) => {
        if (closeReason === CloseReason.DELETE) {
            setDeletedEntries((prevDeletedEntries) =>
                prevDeletedEntries.concat(entryId)
            );
        }
    };

    const handleGoToDetail = async (entry: Entries) => {
        setOriginalEntryId(entry.originalEntryId);
        await open(entry, entry.reasonId, {
            readOnly: true,
            onClose: (closeReason) =>
                handleCloseDocumentDetail(closeReason, entry.originalEntryId)
        });
    };

    const entriesData = entriesDataProp.filter(
        (entry) => !deletedEntries.includes(entry.originalEntryId)
    );

    const headCells: HeadCell[] = [
        {
            id: "postingDate",
            kind: ColumnType.text,
            label: fs("postingDate")
        },

        {
            id: "reasonDescription",
            kind: ColumnType.text,
            label: fs("reason")
        },
        {
            id: "customerOrSupplier",
            kind: ColumnType.text,
            label: fs("customerOrSupplier")
        },
        {
            id: "docNum",
            kind: ColumnType.text,
            label: fs("docNum")
        },
        {
            id: "docDate",
            kind: ColumnType.text,
            label: fs("docDate")
        },

        {
            id: "amount",
            kind: ColumnType.numeric,
            label: fs("amount")
        },
        {
            id: "",
            kind: ColumnType.cta,
            label: ""
        }
    ];

    const columns: ColumnElement[] = [
        // postingDate
        {
            kind: ColumnType.text,
            render: (entry: Entries) => (
                <Typography>{entry.postingDate}</Typography>
            )
        },

        // reasonDescription
        {
            kind: ColumnType.text,
            render: (entry: Entries) => (
                <Typography>{entry.reasonDescription}</Typography>
            )
        },
        // customerOrSupplier
        {
            kind: ColumnType.text,
            render: (entry: Entries) => (
                <Stack direction="column">
                    <Typography>
                        {entry?.customer
                            ? entry.customer?.name
                            : entry?.supplier
                            ? entry.supplier?.name
                            : ""}
                    </Typography>
                    <Typography>
                        {entry?.customer
                            ? entry.customer?.identifier.vatNumber
                            : entry?.supplier
                            ? entry.supplier?.identifier.vatNumber
                            : ""}
                    </Typography>
                </Stack>
            )
        },
        // docNum
        {
            kind: ColumnType.text,
            render: (entry: Entries) => (
                <Typography>{entry.doc.number}</Typography>
            )
        },
        // docDate
        {
            kind: ColumnType.text,
            render: (entry: Entries) => (
                <Typography>{entry.doc.date}</Typography>
            )
        },

        // amount
        {
            kind: ColumnType.numeric,
            render: (entry: Entries) => (
                <FormattedAmount
                    amount={entry.totalAmount.value}
                    currency={entry.totalAmount.currency}
                />
            )
        },
        {
            kind: ColumnType.cta,
            render: (entry: Entries) => (
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleGoToDetail(entry)}
                >
                    <FontAwesomeIcon
                        icon={
                            entry.originalEntryId === originalEntryId &&
                            isOpening
                                ? faCircleNotch
                                : faArrowRight
                        }
                        spin={
                            entry.originalEntryId === originalEntryId &&
                            isOpening
                        }
                    />
                </IconButton>
            )
        }
    ];

    return (
        <Box marginTop="32px">
            <Table headCells={headCells} columns={columns} data={entriesData} />
            {isLoadingMore && (
                <Spinner loading={isLoadingMore}>
                    <Box width="100%" height="80px"></Box>
                </Spinner>
            )}
            <Waypoint
                onEnter={() => {
                    hasMore && !isLoadingMore && fetchMore();
                }}
            />
        </Box>
    );
}
