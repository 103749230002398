import { isEmpty, isNil, uniqBy } from "lodash";
import { DashboardResponse } from "../dtos/DashboardDto";

export function concatenateData(
    data: DashboardResponse,
    moreData?: DashboardResponse
): DashboardResponse {
    return isNil(moreData) || isEmpty(data)
        ? data
        : {
              ...data,
              _embedded: {
                  ...data._embedded,
                  drafts: uniqBy(
                      data._embedded.drafts.concat(moreData._embedded.drafts),
                      "id"
                  )
              }
          };
}
