import { Dictionary, fromPairs, isNil, some, sortBy, toPairs } from "lodash";

import { AccountingLine } from "../../../../core/usecases/dtos/AccountingLinesDto";
import { AccountingGroup } from "../../../../core/usecases/interfaces/AccountingGroup";
import { AccountType } from "../../../../utils/appEnums";

export default function groupAccountingLines(
    accountingLines: AccountingLine[],
    options: {
        sort?: boolean;
        xml?: boolean;
        incluepreviousBalance?: boolean;
    } = {
        sort: true,
        xml: false,
        incluepreviousBalance: true
    }
): Dictionary<AccountingGroup> {
    const { sort, xml } = options;

    let result: Dictionary<AccountingGroup> = {};
    accountingLines.forEach((accountingLine) => {
        let groupCode = accountingLine.accountCode;

        if (!isNil(accountingLine.credit) && accountingLine.credit !== 0) {
            groupCode = groupCode + "c";
        } else if (!isNil(accountingLine.debit) && accountingLine.debit !== 0) {
            groupCode = groupCode + "d";
        } else {
            groupCode = groupCode + "z";
        }

        if (
            !isNil(accountingLine.startPeriodDate) &&
            !isNil(accountingLine.endPeriodDate) &&
            !xml
        ) {
            groupCode =
                groupCode +
                accountingLine.startPeriodDate +
                accountingLine.endPeriodDate;
        }

        result[groupCode] = {
            ...result[groupCode],
            accountCode: accountingLine.accountCode,
            accountDesc:
                accountingLine.accountDesc ?? result[groupCode].accountDesc,
            type: accountingLine.accountType ?? AccountType.BALANCE_SHEET,
            isReadOnly:
                result[groupCode]?.isReadOnly || !!accountingLine.isReadOnly,
            children: result[groupCode]?.children
                ? result[groupCode].children.concat(accountingLine)
                : [accountingLine]
        };

        if (options.incluepreviousBalance) {
            result[groupCode].previousBalance =
                result[groupCode].previousBalance ??
                accountingLine.previousBalance ??
                0;
        }
    });

    Object.keys(result).forEach((key) => {
        result[key] = {
            ...result[key],
            debit: result[key].children
                ?.map((child: any) => child.debit)
                .reduce((a: number, b: number) => a + b, 0),
            credit: result[key].children
                ?.map((child: any) => child.credit)
                .reduce((a: number, b: number) => a + b, 0)
        };
    });

    if (sort) {
        // return groups sorted by groupCode (key)
        // with the following order:
        // customer/supplier account
        // other accounts: all debit accounts, all credit accounts
        // iva account
        return fromPairs(
            sortBy(toPairs(result), ([_, accountingGroup]) =>
                isContoIva(accountingGroup)
                    ? +Infinity
                    : isContoCliente(accountingGroup)
                    ? -Infinity
                    : accountingGroup.credit !== 0
                    ? +1
                    : -1
            )
        );
    } else {
        return result;
    }
}

function isReadOnly(accountingGroup: AccountingGroup) {
    return some(
        accountingGroup.children,
        (accountingLine) => accountingLine.isReadOnly
    );
}

function isContoIva(accountingGroup: AccountingGroup) {
    return (
        isReadOnly(accountingGroup) &&
        accountingGroup.children.some((accountingLine) => accountingLine.isVat)
    );
}

function isContoCliente(accountingGroup: AccountingGroup) {
    return (
        isReadOnly(accountingGroup) &&
        accountingGroup.children.some(
            (accountingLine) =>
                accountingLine.isCustomer || accountingLine.isSupplier
        )
    );
}
