import { useGet } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AssetLogsDto } from "../dtos/AssetLogsDto";

interface UseGetAssetLogs {
    loading: boolean;
    error: any;
    logs: AssetLogsDto | null;
    fetch: Function;
    hasLoaded: boolean;
}

interface UseGetAssetLogsOptions {
    assetId: string | undefined;
    businessId: string | undefined;
    type: string | null;
    lazy?: boolean;
}

export const useGetAssetLogs = ({
    assetId,
    businessId,
    type,
    lazy
}: UseGetAssetLogsOptions): UseGetAssetLogs => {
    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${ASYNC_API_URL}/asset/${assetId}/logs?businessId=${businessId}&type=${type}`,
        {
            ...useAxiosConfigRequestParams("AssetLogs"),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: lazy
        }
    );

    return {
        fetch: fetch,
        loading: loading,
        error: error,
        logs: data,
        hasLoaded: hasLoaded
    };
};
