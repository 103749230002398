import { useRequest } from "@onefront/react-sdk";

import { getYear } from "date-fns";
import { ASYNC_API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { DepreciationSimulationResponseDto } from "../dtos/DepreciationSimulationDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";
import processData from "./processData";

export interface SimulateDepreciationOptions {
    businesess?: string[];
    startDate: string;
    endDate: string;
    suspensionConfigurations?: Map<string, SuspensionConfigurations>;
}

export interface SuspensionConfigurations {
    percentage: number;
    applicationScope: string;
}

interface UseSimulateDepreciation {
    simulateDepreciation: (
        options: SimulateDepreciationOptions
    ) => Promise<DepreciationSimulationResponseDto>;
}

export default function useSimulateDepreciation(): UseSimulateDepreciation {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope(
        "notifications.SimulateDepreciation"
    );

    const customerData = useCustomerData();

    const { fetch } = useRequest({
        url: `${ASYNC_API_URL}/asset/depreciation/simulate`,
        method: "post",
        ...useAxiosConfigRequestParams("SimulateDepreciation"),
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        lazy: true
    });

    const simulateDepreciation = ({
        businesess,
        suspensionConfigurations,
        startDate,
        endDate
    }: SimulateDepreciationOptions) => {
        return fetch({
            params: {
                ...customerData
            },
            data: {
                workspaceId: customerData.workspaceId,
                period: getYear(new Date(endDate)).toString(),
                date: startDate,
                businesess,
                ...(!!suspensionConfigurations
                    ? {
                          suspensionConfigurations
                      }
                    : {})
            }
        })
            .then(
                (
                    response: AxiosResponse<DepreciationSimulationResponseDto>
                ) => ({
                    ...response,
                    data: processData(response.data)
                })
            )
            .then(
                (response: AxiosResponse<DepreciationSimulationResponseDto>) =>
                    response.data
            )
            .catch(() => {});
    };

    return {
        simulateDepreciation
    };
}
