import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AssetSubkindsDto } from "../dtos/AssetSubkindsDto";

interface UseGetSubkinds {
    loading: boolean;
    error: any;
    subkinds: AssetSubkindsDto | null;
    fetch: Function;
    hasLoaded: boolean;
}

interface UseGetSubkindsOptions {
    group?: string | undefined;
    kind?: string | undefined;
    accountCode?: string;
    lazy?: boolean;
}

export const useGetSubkinds = ({
    group,
    kind,
    accountCode,
    lazy
}: UseGetSubkindsOptions): UseGetSubkinds => {
    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/asset/subkinds?accountCode=${accountCode}&group=${group}&kind=${kind}`,
        {
            ...useAxiosConfigRequestParams("Subkinds"),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: lazy
        }
    );

    return {
        fetch: fetch,
        loading: loading,
        error: error,
        subkinds: data,
        hasLoaded: hasLoaded
    };
};
