import { Autocomplete, TextField } from "@vapor/react-material";

import { TRIBUTES } from "../../../utils/tributeUtils";

interface SelectTributeProps {
    value?: string | null;
    readOnly: boolean;
    onChange: (newValue: string) => void;
    transparent?: boolean;
    autoFocus?: boolean;
}

export default function SelectTribute({
    value = null,
    readOnly,
    onChange,
    transparent = false,
    autoFocus = false
}: SelectTributeProps) {
    return (
        <Autocomplete
            value={value}
            options={TRIBUTES}
            isOptionEqualToValue={(option, value) => option.code === value}
            getOptionLabel={(option) => {
                return typeof option === "string" ? option : option.code;
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    readOnly={readOnly}
                    autoFocus={autoFocus}
                />
            )}
            readOnly={readOnly}
            onChange={(_, newValue) => {
                onChange(newValue.code);
            }}
            sx={{
                width: "120px",
                ...(transparent
                    ? {
                          "*": {
                              background: "none !important",
                              border: "none"
                          },
                          "& .MuiAutocomplete-endAdornment": {
                              display: "none"
                          }
                      }
                    : {})
            }}
        />
    );
}
