import { TextField } from "@vapor/react-material";

import { VatDestination } from "../../../../core/usecases/dtos/VatDestinationsDto";
import { TransparentAutoComplete } from "../../styled";

interface SelectVatDestinationProps {
    value?: VatDestination | null;
    vatDestinations: VatDestination[];
    onChange: (vatDestination: VatDestination) => void;
    readOnly?: boolean;
}

export default function SelectVatDestination({
    value,
    vatDestinations,
    onChange,
    readOnly
}: SelectVatDestinationProps) {
    return (
        <TransparentAutoComplete
            value={value}
            options={vatDestinations ?? []}
            onChange={(_: any, vatDestination: any) =>
                onChange(vatDestination as VatDestination)
            }
            renderInput={(params) => <TextField {...params} type="search" />}
            getOptionLabel={(vatDestination: any) => vatDestination.description}
            isOptionEqualToValue={(option, value) =>
                !!option &&
                !!value &&
                (option as VatDestination).id === (value as VatDestination).id
            }
            readOnly={readOnly}
            autoComplete={true}
            autoHighlight={true}
            openOnFocus={true}
            componentsProps={{
                popper: {
                    placement: "bottom-start",
                    sx: {
                        width: "fit-content !important"
                    }
                }
            }}
            sx={{
                width: "240px"
            }}
        />
    );
}
