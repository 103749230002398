import uniqueId from "lodash/uniqueId";

import { SKIP_AUTH } from "../../config";

export const AuthService = () =>
    SKIP_AUTH
        ? {
              target: "$ONE_AUTH_USE_APPLICATION_TOKEN",
              handler: {
                  hook: () => ({
                      set: async () => {},
                      get: async () => ({ applicationToken: "token" }),
                      introspect: async (token: string) => ({
                          token,
                          tokenData: {}
                      }),
                      verify: async (token: string) => ({
                          token,
                          tokenData: {}
                      }),
                      refresh: async (_currentToken: string) => ({
                          token: "newToken-" + uniqueId(),
                          tokenData: {}
                      })
                  })
              }
          }
        : undefined;
