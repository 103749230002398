import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import { Stack, TableCell } from "@vapor/react-material";
import { Tooltip } from "@vapor/react-material/Tooltip";

import { Draft } from "../../../../../../core/usecases/dtos/DashboardDto";
import getFormattedStringWithScope from "../../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../../components/FormattedAmount";

interface ReasonCellProps {
    draft: Draft;
}

const fs = getFormattedStringWithScope(
    "views.Dashboard.toBeAccounted.table.ReasonCell"
);

export default function ReasonCell({ draft }: ReasonCellProps) {
    const tooltipContent = draft?.deferredData?.flatMap((d) => {
        return [
            {
                title: fs("performanceReason"),
                description: d?.reason.toLowerCase()
            },
            {
                title: fs("cuReason"),
                description: d?.cuReason.toLowerCase()
            },
            {
                title: fs("integrationTax"),
                description: d?.cpa
            },
            {
                title: fs("withholdingPercentage"),
                description: (
                    <span>
                        {`${d?.types?.IRPEF?.withholdingRate ?? 0}%`}{" "}
                        <FormattedAmount
                            variant="bodySmall"
                            style={{ color: "#FFF" }}
                            amount={Math.abs(d?.types?.IRPEF?.amount ?? 0)}
                            currency={draft.refCurrency ?? "EUR"}
                        />{" "}
                    </span>
                )
            }
        ];
    });

    return (
        <TableCell size="small">
            <Typography variant="bodyLarge700">
                {draft.reason}{" "}
                {(draft?.reasonId === "FA8" || draft?.reasonId === "FE10") && (
                    <Tooltip
                        arrow={true}
                        title={tooltipContent?.map((t, index) => {
                            return (
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                    key={`tooltip-row_${index}`}
                                >
                                    <div
                                        style={{
                                            width: "50%",
                                            borderBottom:
                                                "1px solid rgb(201, 217, 232)",
                                            padding: "5px"
                                        }}
                                    >
                                        <p className="description">
                                            <b className="title">{t.title}</b>
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            width: "50%",
                                            borderBottom:
                                                "1px solid rgb(201, 217, 232)",
                                            padding: "5px"
                                        }}
                                    >
                                        <p className="description">
                                            {t.description}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    >
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            style={{ color: "#09822a" }}
                        />
                    </Tooltip>
                )}
            </Typography>
            {draft?.fixedAsset && (
                <Stack direction="row" spacing={1}>
                    <VaporIcon icon={faCircleCheck} color="success" />{" "}
                    <Typography variant="caption">
                        {fs("fixedAsset")}
                    </Typography>
                </Stack>
            )}
        </TableCell>
    );
}
