import { useEffect, useRef } from "react";

import { POLLING_INTERVAL_MS } from "../../../config";

type CallbackType = (any?: any) => any;

interface UsePoll {
    poll: (callback: CallbackType) => void;
    stop: () => void;
    isPolling: boolean;
}

export default function usePoll(): UsePoll {
    const pollingInterval = useRef<any>(null);

    const poll = (callback: (any?: any) => any) => {
        if (pollingInterval.current) {
            stop();
        }
        pollingInterval.current = setInterval(callback, POLLING_INTERVAL_MS);
    };

    const stop = () => {
        pollingInterval.current && clearInterval(pollingInterval.current);
        pollingInterval.current = null;
    };

    useEffect(() => {
        return () => stop();
    }, []);

    return {
        poll,
        stop,
        isPolling: !!pollingInterval.current
    };
}
