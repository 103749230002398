import Alert from "@vapor/react-material/Alert";
import AlertTitle from "@vapor/react-material/AlertTitle";
import isNil from "lodash/isNil";

interface InfoBannerProps {
    title?: string;
    message?: string;
}

export default function InfoBanner({ title, message }: InfoBannerProps) {
    return (
        <Alert variant="outlined" severity="info">
            {!isNil(title) ? <AlertTitle>{title}</AlertTitle> : null}
            {message}
        </Alert>
    );
}
