import Typography from "@vapor/react-extended/ExtendedTypography";
import { Divider, Stack } from "@vapor/react-material";

import { GroupedCreditDebitDto } from "../../../../../core/usecases/dtos/BalanceDto";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import BalanceSheetActivities from "./BalanceSheetActivities";
import BalanceSheetPassivities from "./BalanceSheetPassivities";

interface BalanceSheetProps {
    balanceSheet: GroupedCreditDebitDto;
    onRowDetailClick: (accountId: string, accountName: string) => void;
}

const fs = getFormattedStringWithScope("components.BalanceTable");

export default function BalanceSheet({
    balanceSheet,
    onRowDetailClick
}: BalanceSheetProps) {
    const maxBalanceSheetLength = Math.max(
        balanceSheet.credit.accounts
            .map((account) => account.accounts.length + 1)
            .reduce((a, b) => a + b, 0),
        balanceSheet.debit.accounts
            .map((account) => account.accounts.length + 1)
            .reduce((a, b) => a + b, 0)
    );

    const balanceSheetOperatingResult =
        balanceSheet.credit.totalAmount - balanceSheet.debit.totalAmount;

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="titleSmall">
                    {fs("balanceSheet")}
                </Typography>
            </Stack>
            <Stack direction="row" justifyContent="center">
                <BalanceSheetActivities
                    rows={balanceSheet.debit.accounts}
                    total={balanceSheet.debit.totalAmount}
                    numRows={maxBalanceSheetLength}
                    onRowDetailClick={onRowDetailClick}
                    operatingResult={
                        balanceSheetOperatingResult > 0
                            ? Math.abs(balanceSheetOperatingResult)
                            : undefined
                    }
                />
                <Divider orientation="vertical" flexItem />
                <BalanceSheetPassivities
                    rows={balanceSheet.credit.accounts}
                    total={balanceSheet.credit.totalAmount}
                    numRows={maxBalanceSheetLength}
                    onRowDetailClick={onRowDetailClick}
                    operatingResult={
                        balanceSheetOperatingResult < 0
                            ? Math.abs(balanceSheetOperatingResult)
                            : undefined
                    }
                />
            </Stack>
        </>
    );
}
