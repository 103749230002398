import Typography from "@vapor/react-extended/ExtendedTypography";
import { AccountingPeriodDto } from "../../../../core/usecases/dtos/AccountingPeriodsDto";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import { Report } from "../../../../core/usecases/dtos/ReportAccountingPeriodsDto";

import { Stack } from "@vapor/react-material";
import { useCallback } from "react";
import FormattedDate from "../../../components/FormattedDate";
const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.CloseAndReopenAccountsOverlay"
);

interface HeaderLeftProps {
    report?: Report;
    selectedAccountingPeriod: AccountingPeriodDto;
}

export default function HeaderLeft({
    selectedAccountingPeriod,
    report
}: HeaderLeftProps) {
    /* Without simulation we still have to show next year as the opening date */
    const nextYear = useCallback(() => {
        const data = new Date(selectedAccountingPeriod.startDate);
        data.setFullYear(data.getFullYear() + 1);
        return data.toString();
    }, [selectedAccountingPeriod.startDate]);

    const data = [
        {
            label: fs("dateClose"),
            value:
                report?.closingPeriod?.endDate ??
                selectedAccountingPeriod.endDate
        },
        {
            label: fs("dateOpen"),
            value: report?.openingPeriod?.startDate ?? nextYear()
        }
    ];
    return (
        <>
            {data.map((row) => (
                <Stack key={row.label} direction="column" spacing={2}>
                    <Typography variant="body">{row.label}</Typography>
                    <FormattedDate variant="body500" date={row.value} />
                </Stack>
            ))}
        </>
    );
}
