import { Spinner } from "@comic/precog-components";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Divider from "@vapor/react-material/Divider";
import FormLabel from "@vapor/react-material/FormLabel";
import Stack from "@vapor/react-material/Stack";
import TextField from "@vapor/react-material/TextField";

import { isNil, isNumber } from "lodash";
import { useWithholdings } from "../../../core/contexts/Withholdings";
import { DraftWithholdingDto } from "../../../core/usecases/dtos/DraftWithholdingDto";
import { PatchData } from "../../../core/usecases/usePatchDraftWithholding";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../components/FormattedAmount";
import CurrencyField from "../CurrencyField";
import DrawerDatePicker from "../DrawerDatePicker";
import WithholdingDrawerHeader from "./WithholdingDrawerHeader";

const fs = getFormattedStringWithScope("views.Detail.withholdings.inpsTab");

interface InpsManagementDrawerTabProps {
    refCurrency: string;
    withholding: DraftWithholdingDto;
    onWithholdingChange: (
        data: Partial<DraftWithholdingDto & PatchData>
    ) => void;
}

export default function InpsManagementDrawerTab({
    refCurrency,
    withholding,
    onWithholdingChange
}: InpsManagementDrawerTabProps) {
    const {
        detailSimulation: parameters,
        isLoading,
        detail: withholdingDetail
    } = useWithholdings().draft;

    return parameters && withholdingDetail ? (
        <Box marginLeft="30px" display="flex">
            <Stack>
                <WithholdingDrawerHeader
                    withholding={withholding}
                    code={parameters.inpsTributeCode}
                />
                <Box display="flex" marginTop="24px">
                    <Typography variant="body" marginRight="5px">
                        {fs("socialInstituteTaxableAmount")}:
                    </Typography>
                    <FormattedAmount
                        variant="body700"
                        marginLeft="5px"
                        currency="EUR"
                        amount={parameters?.socialInstituteTaxableAmount}
                    />
                </Box>
                <Box width="300px" marginTop="26px">
                    <FormLabel
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: 1
                        }}
                    >
                        <Typography variant="body700">
                            {fs("alreadySubjectedAmount")}
                        </Typography>
                    </FormLabel>
                    <CurrencyField
                        value={withholdingDetail.alreadySubjectedAmount}
                        onChange={async (value: number) => {
                            onWithholdingChange({
                                alreadySubjectedAmount: value
                            });
                        }}
                        currency={refCurrency}
                    />
                </Box>
                <Box display="flex" marginTop="24px">
                    <Typography variant="body" marginRight="5px">
                        {fs("socialInstituteContributionRate")}:
                    </Typography>
                    <Typography variant="body700" marginLeft="5px">
                        {parameters?.inpsRate} %
                    </Typography>
                </Box>
                <Box display="flex" marginTop="24px">
                    <Typography variant="body" marginRight="5px">
                        {fs("recipientContribution")}:
                    </Typography>
                    <FormattedAmount
                        variant="body700"
                        marginLeft="5px"
                        currency="EUR"
                        amount={parameters?.recipientContribution}
                    />
                </Box>
                {!isNil(parameters) &&
                    isNumber(parameters?.companyContribution) && (
                        <Box display="flex" marginTop="24px">
                            <Typography variant="body" marginRight="5px">
                                {fs("companyContribution")}:
                            </Typography>
                            <FormattedAmount
                                variant="body700"
                                marginLeft="5px"
                                currency="EUR"
                                amount={parameters.companyContribution}
                            />
                        </Box>
                    )}
                <Divider
                    sx={{ marginTop: 3, marginRight: 2 }}
                    orientation="horizontal"
                    flexItem
                    light
                />
                <Stack marginTop={2} direction="row" spacing={2}>
                    <Box width="300px">
                        <DrawerDatePicker
                            date={withholding?.activityStartDate}
                            onChangeDate={async (date: Date) =>
                                onWithholdingChange({
                                    activityStartDate: date
                                })
                            }
                            showIcon={false}
                            label={fs("activityStartDate")}
                        />
                    </Box>
                    <Box width="300px">
                        <DrawerDatePicker
                            date={withholding?.activityEndDate}
                            onChangeDate={(newDate: Date) =>
                                onWithholdingChange({
                                    activityEndDate: newDate
                                })
                            }
                            showIcon={false}
                            label={fs("activityEndDate")}
                        />
                    </Box>
                </Stack>
                <Stack marginTop={4} direction="row" spacing={2}>
                    <Box width="300px">
                        <TextField
                            name="emensReportType"
                            onChange={(e) => {
                                if (e?.target?.value)
                                    onWithholdingChange({
                                        emensReportType: e?.target?.value
                                    });
                            }}
                            defaultValue={parameters?.emensReportType}
                            fullWidth
                            label={
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body500">
                                        {fs("emensReportType")}
                                    </Typography>
                                </Box>
                            }
                        />
                    </Box>
                    <Box width="300px">
                        <TextField
                            name="emensActivityCode"
                            defaultValue=""
                            onChange={(e: any) => {
                                if (e?.target?.value)
                                    onWithholdingChange({
                                        emensActivityCode: e?.target?.value
                                    });
                            }}
                            fullWidth
                            label={
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body500">
                                        {fs("emensActivityCode")}
                                    </Typography>
                                </Box>
                            }
                        />
                    </Box>
                </Stack>
                <Box width="300px" marginTop="35px" marginBottom="10px">
                    <TextField
                        name="emensOtherInsurance"
                        defaultValue=""
                        onChange={(e: any) => {
                            if (e?.target?.value)
                                onWithholdingChange({
                                    emensOtherInsurance: e?.target?.value
                                });
                        }}
                        fullWidth
                        label={
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="body500">
                                    {fs("emensOtherInsurance")}
                                </Typography>
                            </Box>
                        }
                    />
                </Box>
            </Stack>
        </Box>
    ) : isLoading ? (
        <Spinner loading={isLoading}>
            <Box marginTop={40}></Box>
        </Spinner>
    ) : null;
}
