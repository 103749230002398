import { Card, CardContent, Stack } from "@vapor/react-material";

import { Tab, Tabs } from "@vapor/react-extended";
import { useState } from "react";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.AccountingPeriodDetail"
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

interface AccountingPeriodDetailProps {
    closingOperations: React.ReactNode;
    registers: React.ReactNode;
}

export default function AccountingPeriodDetail({
    closingOperations,
    registers
}: AccountingPeriodDetailProps) {
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <Card elevation={1} sx={{ height: "100%" }}>
            <CardContent sx={{ padding: 0 }}>
                <Stack>
                    <Tabs
                        onChange={(_, value) => setSelectedTab(value)}
                        size="small"
                        value={selectedTab}
                    >
                        <Tab
                            label={fs("tabs.closingOperations")}
                            value={0}
                            {...a11yProps(0)}
                        />
                        <Tab
                            label={fs("tabs.registers")}
                            value={1}
                            {...a11yProps(1)}
                        />
                        {/* <Tab disabled label={fs("tabs.balance")} value={2} /> */}
                    </Tabs>
                    <CustomTabPanel value={selectedTab} index={0}>
                        {closingOperations}
                    </CustomTabPanel>

                    <CustomTabPanel value={selectedTab} index={1}>
                        {registers}
                    </CustomTabPanel>
                    {/* <CustomTabPanel value={selectedTab} index={2}>
                                Bilancio
                            </CustomTabPanel> */}
                </Stack>
            </CardContent>
        </Card>
    );
}
