import { ColumnType, HeadCell, Table } from "@comic/precog-components";
import Box from "@vapor/react-material/Box";

import { VatEntries } from "../../../../../../../core/usecases/dtos/VatEntriesDto";
import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";
import { Columns } from "./Columns";

const fs = getFormattedStringWithScope("views.VATReport.vatEntries.table");

interface VATReportDetailTableProps {
    data: VatEntries[];
}

const VATReportDetailTable = ({ data }: VATReportDetailTableProps) => {
    const headCells: HeadCell[] = [
        {
            id: "registrationDate",
            kind: ColumnType.text,
            label: fs("registrationDate")
        },
        {
            id: "reasonDescription",
            kind: ColumnType.text,
            label: fs("reason")
        },
        {
            id: "docNumber",
            kind: ColumnType.text,
            label: fs("docNumber")
        },
        {
            id: "docDate",
            kind: ColumnType.text,
            label: fs("documentDate")
        },
        {
            id: "counterpart",
            kind: ColumnType.text,
            label: fs("counterpart")
        },
        {
            id: "counterpartId",
            kind: ColumnType.text,
            label: fs("counterpartId")
        },
        {
            id: "vatRate",
            kind: ColumnType.text,
            label: fs("vatRate")
        },
        {
            id: "",
            kind: ColumnType.cta,
            label: ""
        },
        {
            id: "taxable",
            kind: ColumnType.numeric,
            label: fs("taxable")
        },
        {
            id: "tax",
            kind: ColumnType.numeric,
            label: fs("tax")
        },
        {
            id: "total",
            kind: ColumnType.numeric,
            label: fs("total")
        },
        {
            id: "",
            kind: ColumnType.cta,
            label: ""
        }
    ];

    const columns = Columns();

    return (
        <Box marginTop="32px">
            <Table headCells={headCells} columns={columns} data={data} />
        </Box>
    );
};

export default VATReportDetailTable;
