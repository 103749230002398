import { Typography } from "@vapor/react-extended";
import { Times } from "@vapor/react-icons";
import {
    Box,
    CircularProgress,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    NotificationInline,
    Stack
} from "@vapor/react-material";
import { isNil } from "lodash";
import { ErrorBoundary } from "react-error-boundary";

import { useDraft } from "../../../core/domain/Draft/queries";
import { CloseReason } from "../../../services/DocumentDetail";
import Accounting from "../../../ui/components/Accounting";
import Header from "../../../ui/components/Header";
import OverlayDialog from "../../../ui/components/OverlayDialog";
import { DraftAccountingStatus } from "../../../utils/appEnums";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import AccountToolbar from "../AccountToolbar";
import CustomButton from "../CustomButton";
import NotFoundBanner from "../NotFoundBanner";

interface DocumentDetailProps {
    draftId: string;
    onClose: (closeReason: CloseReason) => void;
    onModify: () => void;
    readOnly?: boolean;
    isLoadingModification?: boolean;
    onDeleteClone: () => void;
    onContinueEditClone: () => void;
    isDeletingClone: boolean;
    isLoadingCloned: boolean;
}

export default function DocumentDetail({
    draftId,
    onClose,
    onModify,
    readOnly = false,
    isLoadingModification = false,
    onDeleteClone,
    onContinueEditClone,
    isDeletingClone,
    isLoadingCloned
}: DocumentDetailProps) {
    const fs = useFormattedStringWithScope("components.DocumentDetail");

    const { data: draft, isLoading } = useDraft(draftId);

    const handleAccount = () => {
        onClose(CloseReason.CONFIRM);
    };

    const handleDelete = () => {
        onClose(CloseReason.DELETE);
    };

    const handleModify = () => {
        onModify();
    };

    const isModifiedWithError =
        draft?.status === DraftAccountingStatus.DEPRECATED;

    return (
        <OverlayDialog
            fullScreen={true}
            open={!isNil(draftId)}
            onClose={() => onClose(CloseReason.CLOSE)}
        >
            <DialogTitle>
                {/* for spacing */}
                <Box></Box>
                {fs("documentDetail")}
                <IconButton
                    color="primary"
                    onClick={() => onClose(CloseReason.CLOSE)}
                >
                    <Times />
                </IconButton>
            </DialogTitle>
            <Divider variant="fullWidth" /> <Divider />
            {isLoading ? (
                <Box m="auto">
                    <CircularProgress />
                </Box>
            ) : (
                <ErrorBoundary fallback={<NotFoundBanner text={fs("error")} />}>
                    <DialogContent>
                        {isModifiedWithError && (
                            <Box marginBottom={4}>
                                <NotificationInline
                                    variant="outlined"
                                    severity="warning"
                                >
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Typography variant="body700">
                                            {fs("modificationFailed")}
                                        </Typography>
                                        <Typography variant="body">
                                            {fs("seeModified")}
                                        </Typography>
                                        <CustomButton
                                            variant="outlined"
                                            size="small"
                                            loading={isDeletingClone}
                                            onClick={onDeleteClone}
                                        >
                                            {fs("noDelete")}
                                        </CustomButton>
                                        <CustomButton
                                            variant="contained"
                                            size="small"
                                            loading={isLoadingCloned}
                                            onClick={onContinueEditClone}
                                        >
                                            {fs("yesModify")}
                                        </CustomButton>
                                    </Stack>
                                </NotificationInline>
                            </Box>
                        )}
                        {draft?.reason && (
                            <>
                                <Header draftId={draftId} />
                                <Accounting
                                    draftId={draftId}
                                    readOnly={readOnly}
                                />
                            </>
                        )}
                    </DialogContent>
                    <AccountToolbar
                        draftId={draftId}
                        onAccount={handleAccount}
                        onClose={() => onClose(CloseReason.CLOSE)}
                        onDelete={handleDelete}
                        onModify={handleModify}
                        isLoadingStartModification={isLoadingModification}
                    />
                </ErrorBoundary>
            )}
        </OverlayDialog>
    );
}
