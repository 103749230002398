import { Accordion, SelectYear, Spinner } from "@comic/precog-components";
import { OneBoxPage, useCurrentWorkspace } from "@drift/oneplatfront";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import { ExtendedTab } from "@vapor/react-extended/ExtendedTab";
import ExtendedTabs from "@vapor/react-extended/ExtendedTabs";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import getYear from "date-fns/getYear";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import usePrevious from "../../../core/commons/hooks/usePrevious";
import { Period } from "../../../core/usecases/dtos/VatSettlementsDto";
import { VatSettlement } from "../../../core/usecases/interfaces/VatSettlement";
import useGetBusinesses from "../../../core/usecases/useGetBusinesses";
import { useGetVatBusinesses } from "../../../core/usecases/useGetVatBusinesses";
import { useGetWorkspaceBusinessFlat } from "../../../core/usecases/useGetWorkspaceBusinessFlat";
import useManageSettlements from "../../../core/usecases/useManageSettlements";
import { useWorkspaceConfig } from "../../../services/WorkspaceConfigService";
import { BusinessId, JournalType, QueryParam } from "../../../utils/appEnums";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import useQueryParam from "../../../utils/useQueryParam";
import routes from "../../commons/routes";
import BackArrow from "../../components/BackArrow";
import CustomButton from "../../components/CustomButton";
import NotFoundBanner from "../../components/NotFoundBanner";
import PeriodCarousel from "./PeriodCarousel";
import VatReportDetail from "./TabContent/VatReport/Detail";
import MultiBusinessesTable from "./TabContent/VatReport/MultiBusinesses/Table";
import VATSettlementDetail from "./TabContent/VatSettlements/Detail";
import MonoActivity from "./TabContent/VatSettlements/MonoActivity";
import MultiActivities from "./TabContent/VatSettlements/MultiActivities";
import "./style.css";

const fs = getFormattedStringWithScope("views.VATSettlement");
const fsPeriod = getFormattedStringWithScope("commons.period");

enum VatTab {
    VAT_SETTLEMENT = "vatSettlement",
    VAT_REPORT = "vatReport"
}

export default function VAT() {
    const navigate = useNavigate();

    const businessId = useQueryParam().get(QueryParam.businessId);
    const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>(
        businessId
    );
    const previousSelectedBusinessId = usePrevious(selectedBusinessId);

    const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(true);
    const [selectedTab, setSelectedTab] = useState<VatTab>(
        VatTab.VAT_SETTLEMENT
    );
    const previousSelectedTab = usePrevious(selectedTab);

    const currentDate = new Date();

    const currentYear = getYear(currentDate);
    const [selectedYear, setSelectedYear] = useState<number>(currentYear);
    const previousSelectedYear = usePrevious(selectedYear);

    const [selectedPeriod, setSelectedPeriod] = useState<Period | null>(null);
    const previousSelectedPeriod = usePrevious(selectedPeriod);

    const [selectedVatCode, setSelectedVatCode] = useState<string>();

    const [selectedJournalType, setSelectedJournalType] =
        useState<JournalType>();

    const [verticalNavigationStep, setVerticalNavigationStep] =
        useState<number>(0);
    const previousVerticalNavigationStep = usePrevious(verticalNavigationStep);

    const { commencementDate } = useWorkspaceConfig();

    const {
        settlements,
        closeSettlement,
        openSettlement,
        fetchSettlements,
        isFetchingSettlements: loadingSettlements,
        isFetchingSettlementClose,
        isFetchingSettlementOpen
    } = useManageSettlements();

    const {
        fetch: fetchVatBusinesses,
        data: vatBusinesses,
        loading: loadingVatBusinesses
    } = useGetVatBusinesses();

    const {
        fetch: fetchWorkspaceBusinesses,
        data: workspaceBusinesses,
        loading: loadingWorkspaceBusinesses
    } = useGetWorkspaceBusinessFlat({
        lazy: true
    });

    const { businesses, getBusinesses } = useGetBusinesses({
        lazy: true
    });

    const currentSettlement = settlements?.filter(
        (settlement) =>
            settlement.period.year === selectedPeriod?.year &&
            settlement.period.ordinal === selectedPeriod?.ordinal
    )[0];

    const isMultiActivity =
        currentSettlement?.businessSettlements.length &&
        currentSettlement?.businessSettlements.length > 1;

    const notFoundBanner = useMemo(
        () => <NotFoundBanner text={fs("notFound")} />,
        []
    );

    const { workspace: customer } = useCurrentWorkspace(true);

    const handleSelectBusiness = useCallback(
        (newSelectedBusinessId: string) => {
            setIsAccordionOpen(false);
            setVerticalNavigationStep(verticalNavigationStep + 1);
            setSelectedBusinessId(newSelectedBusinessId);
        },
        [verticalNavigationStep]
    );

    const handleAliquotesDetailClick = useCallback(
        (vatCode: string, journalType: JournalType) => {
            setSelectedVatCode(vatCode);
            setSelectedJournalType(journalType);
            setVerticalNavigationStep(verticalNavigationStep + 1);
        },
        [verticalNavigationStep]
    );

    const handleClosePeriod = useCallback(
        (settlement: VatSettlement) => {
            closeSettlement({
                settlement,
                year: selectedYear
            });
        },
        [closeSettlement, selectedYear]
    );

    const handleOpenPeriod = useCallback(
        (settlement: VatSettlement) => {
            openSettlement({
                settlement,
                year: selectedYear
            });
        },
        [openSettlement, selectedYear]
    );

    const handleConsolidate = useCallback(() => {
        if (currentSettlement) {
            handleClosePeriod(currentSettlement);
        }
    }, [handleClosePeriod, currentSettlement]);

    const consolidateToolbar = useMemo(() => {
        const currentSettlementCanBeClosed =
            currentSettlement?.status &&
            currentSettlement.status === "CLOSABLE";

        return (
            <Box marginLeft="-42px">
                <VaporToolbar
                    contentRight={
                        <CustomButton
                            variant="contained"
                            disabled={!currentSettlementCanBeClosed}
                            loading={currentSettlement?.status === "CLOSING"}
                            onClick={handleConsolidate}
                        >
                            {fs("consolidate")}
                        </CustomButton>
                    }
                />
            </Box>
        );
    }, [currentSettlement, handleConsolidate]);

    const verticalNavigation = useMemo(() => {
        return {
            [VatTab.VAT_SETTLEMENT]: compact([
                isMultiActivity
                    ? {
                          fetchData: () => {},
                          displayFilterAccordion: true,
                          displayTabs: true,
                          subtitle: fs("selectActivity"),
                          render: () =>
                              currentSettlement && (
                                  <>
                                      <MultiActivities
                                          settlement={currentSettlement}
                                          onSelectBusiness={(
                                              businessId: string
                                          ) => handleSelectBusiness(businessId)}
                                      />
                                      {consolidateToolbar}
                                  </>
                              )
                      }
                    : null,
                {
                    fetchData: () => {
                        if (selectedBusinessId && selectedPeriod)
                            fetchVatBusinesses(
                                selectedBusinessId,
                                selectedPeriod.year,
                                selectedPeriod.ordinal
                            );
                    },
                    displayFilterAccordion: true,
                    displayTabs: true,
                    subtitle: fs("overview"),
                    render: () =>
                        vatBusinesses &&
                        (isEmpty(vatBusinesses) ? (
                            notFoundBanner
                        ) : (
                            <>
                                <MonoActivity
                                    settlement={currentSettlement}
                                    businessSettlements={vatBusinesses}
                                    onAliquotesDetailClick={
                                        handleAliquotesDetailClick
                                    }
                                />
                                {!isMultiActivity && consolidateToolbar}
                            </>
                        ))
                },
                {
                    fetchData: () => {
                        if (
                            selectedPeriod &&
                            previousSelectedPeriod !== selectedPeriod
                        )
                            fetchVatBusinesses(
                                selectedBusinessId!,
                                selectedYear,
                                selectedPeriod.ordinal
                            );
                    },
                    displayFilterAccordion: true,
                    displayTabs: false,
                    subtitle: fs("detail"),
                    render: () =>
                        selectedPeriod &&
                        vatBusinesses &&
                        selectedBusinessId &&
                        selectedJournalType &&
                        selectedVatCode && (
                            <>
                                <VATSettlementDetail
                                    settlement={vatBusinesses}
                                    businessId={selectedBusinessId}
                                    journalType={selectedJournalType}
                                    period={selectedPeriod.ordinal}
                                    vatCode={selectedVatCode}
                                    year={selectedYear}
                                />
                                {!isMultiActivity && consolidateToolbar}
                            </>
                        )
                }
            ]),
            [VatTab.VAT_REPORT]: compact([
                isMultiActivity
                    ? {
                          fetchData: () => {
                              if (!businesses || isEmpty(businesses))
                                  getBusinesses();
                          },
                          displayFilterAccordion: true,
                          displayTabs: true,
                          subtitle: fs("selectActivity"),
                          render: () =>
                              businesses ? (
                                  <MultiBusinessesTable
                                      data={businesses}
                                      onSelectBusiness={handleSelectBusiness}
                                  />
                              ) : (
                                  notFoundBanner
                              )
                      }
                    : null,
                {
                    fetchData: () => {
                        if (!workspaceBusinesses) fetchWorkspaceBusinesses();
                    },
                    displayFilterAccordion: true,
                    displayTabs: true,
                    subtitle: fs("detail"),
                    render: () =>
                        selectedPeriod && (
                            <VatReportDetail
                                workspaceBusinesses={workspaceBusinesses}
                                businesses={businesses}
                                businessId={selectedBusinessId!}
                                period={selectedPeriod?.ordinal}
                                year={selectedYear}
                            />
                        )
                }
            ])
        };
    }, [
        getBusinesses,
        businesses,
        consolidateToolbar,
        currentSettlement,
        fetchVatBusinesses,
        fetchWorkspaceBusinesses,
        handleAliquotesDetailClick,
        handleSelectBusiness,
        isMultiActivity,
        notFoundBanner,
        selectedBusinessId,
        selectedJournalType,
        previousSelectedPeriod,
        selectedPeriod,
        selectedVatCode,
        selectedYear,
        vatBusinesses,
        workspaceBusinesses
    ]);

    useEffect(() => {
        if (previousSelectedYear !== selectedYear) {
            fetchSettlements(selectedYear).then((settlements) => {
                const lastFilledPeriod = settlements
                    .filter(
                        (settlement) => settlement.period.ordinal !== "ANNUAL"
                    )
                    .filter((settlement) => settlement.status)
                    .at(-1)?.period;
                setSelectedPeriod(lastFilledPeriod ?? settlements[0].period);
            });
        }
    }, [previousSelectedYear, selectedYear, fetchSettlements]);

    useEffect(() => {
        if (!isMultiActivity && currentSettlement && !selectedBusinessId) {
            setSelectedBusinessId(
                currentSettlement?.businessSettlements[0]?.id ?? null
            );
        }
    }, [isMultiActivity, currentSettlement, selectedBusinessId]);

    const handleSelectYearChange = (newSelectedYear: number) => {
        setSelectedYear(newSelectedYear);
    };

    const handleSelectTab = (targetTab: VatTab) => {
        setSelectedTab(targetTab);
        setVerticalNavigationStep(
            Math.min(
                verticalNavigationStep,
                verticalNavigation[targetTab].length - 1
            )
        );
        if (
            selectedTab === VatTab.VAT_REPORT &&
            selectedBusinessId === BusinessId.ALL
        ) {
            setSelectedBusinessId(null);
        }
    };

    const handleBackInVerticalNavigation = () => {
        setVerticalNavigationStep(verticalNavigationStep - 1);
    };

    const handleBackClick = () => {
        verticalNavigationStep === 0
            ? navigate(routes.home())
            : handleBackInVerticalNavigation();
    };

    useEffect(() => {
        if (
            previousSelectedBusinessId !== selectedBusinessId ||
            previousSelectedTab !== selectedTab ||
            previousVerticalNavigationStep !== verticalNavigationStep ||
            previousSelectedYear !== selectedYear ||
            previousSelectedPeriod !== selectedPeriod
        ) {
            verticalNavigation[selectedTab][verticalNavigationStep].fetchData();
        }
    }, [
        previousSelectedBusinessId,
        selectedBusinessId,
        verticalNavigation,
        previousSelectedTab,
        selectedTab,
        previousVerticalNavigationStep,
        verticalNavigationStep,
        previousSelectedYear,
        selectedYear,
        previousSelectedPeriod,
        selectedPeriod
    ]);

    const isPageContentLoading =
        loadingSettlements ||
        loadingVatBusinesses ||
        loadingWorkspaceBusinesses;

    const backArrowText =
        verticalNavigationStep - 1 >= 0
            ? `${fs("goBackTo")} ${
                  verticalNavigation[selectedTab][verticalNavigationStep - 1]
                      .subtitle
              }`
            : undefined;

    return (
        <OneBoxPage
            headerLeft={
                <BackArrow onClick={handleBackClick} text={backArrowText} />
            }
            title={fs("title")}
            subtitle={`${customer?.description}: ${verticalNavigation[selectedTab][verticalNavigationStep].subtitle}`}
            showCustomerSelector={false}
        >
            <div className="v-VATSettlement-content">
                {selectedPeriod &&
                verticalNavigation[selectedTab][verticalNavigationStep]
                    .displayFilterAccordion ? (
                    <Accordion
                        defaultExpanded={true}
                        isOpen={isAccordionOpen}
                        title={fs("filtersTitle")}
                        description={`${fsPeriod(
                            selectedPeriod.ordinal
                        )} ${selectedYear}`}
                        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                    >
                        <Box marginBottom="16px" marginLeft="16px">
                            <SelectYear
                                numberOfYears={
                                    commencementDate
                                        ? getYear(currentDate) -
                                          getYear(commencementDate) +
                                          1
                                        : 5
                                }
                                onChange={handleSelectYearChange}
                                startingYear={currentYear}
                                value={selectedYear}
                            />
                        </Box>
                        {!loadingSettlements && !isEmpty(settlements) && (
                            <PeriodCarousel
                                period={selectedPeriod}
                                onSelectPeriod={(period) =>
                                    setSelectedPeriod(period)
                                }
                                onClosePeriod={handleClosePeriod}
                                onOpenPeriod={handleOpenPeriod}
                                isLoadingOpenSettlement={
                                    isFetchingSettlementOpen
                                }
                                isLoadingCloseSettlement={
                                    isFetchingSettlementClose
                                }
                                settlements={settlements}
                                year={selectedYear}
                            />
                        )}
                    </Accordion>
                ) : (
                    selectedPeriod && (
                        <Box
                            display="flex"
                            width="100%"
                            justifyContent="flex-end"
                            marginTop="16px"
                        >
                            <Typography
                                variant="body"
                                color="primary.textSubduedColor"
                                marginRight="48px"
                            >{`${fsPeriod(
                                selectedPeriod.ordinal
                            )} ${selectedYear}`}</Typography>
                        </Box>
                    )
                )}
                {verticalNavigation[selectedTab][verticalNavigationStep]
                    .displayTabs && (
                    <Box marginTop="16px">
                        <ExtendedTabs
                            onChange={(_, value) =>
                                handleSelectTab(value as VatTab)
                            }
                            size="small"
                            variant="standard"
                            value={selectedTab}
                        >
                            <ExtendedTab
                                label={fs("tabs.vatSettlement")}
                                value={VatTab.VAT_SETTLEMENT}
                            />
                            <ExtendedTab
                                label={fs("tabs.vat")}
                                value={VatTab.VAT_REPORT}
                            />
                        </ExtendedTabs>
                    </Box>
                )}
                <Box marginTop="16px">
                    <Spinner loading={isPageContentLoading}>
                        {isEmpty(settlements)
                            ? notFoundBanner
                            : verticalNavigation[selectedTab][
                                  verticalNavigationStep
                              ].render()}
                    </Spinner>
                </Box>
            </div>
        </OneBoxPage>
    );
}
