import { TagProps, VaporTag } from "@vapor/react-custom";
import { Dictionary } from "lodash";

import { AccountType as AccountTypeEnum } from "../../../utils/appEnums";

interface AccountTypeProps {
    type: AccountTypeEnum;
}

const TypePropsMap: Dictionary<TagProps> = {
    [AccountTypeEnum.BALANCE_SHEET]: {
        label: "P",
        type: "blueSapphire",
        variant: "standard"
    },
    [AccountTypeEnum.INCOME_STATEMENT]: {
        label: "E",
        type: "maximumPurple",
        variant: "standard"
    }
};

export default function AccountType({ type }: AccountTypeProps) {
    return <VaporTag {...TypePropsMap[type]} />;
}
