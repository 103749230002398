import { useRequest, useSnackbar } from "@onefront/react-sdk";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AccountingLine } from "../dtos/AccountingLinesDto";

export interface CreateAccountingLineOptions {
    draftId: string;
    accountingLine: Partial<AccountingLine> &
        Pick<AccountingLine, "accountCode">;
}

interface UseCreateAccountingLine {
    loading: boolean;
    error: any;
    result: any;
    create: (params: CreateAccountingLineOptions) => Promise<void>;
}

export const useCreateAccountingLine = (): UseCreateAccountingLine => {
    const { enqueueSnackbar } = useSnackbar();

    const customerData = useCustomerData();

    const fs = useFormattedStringWithScope("notifications.PostDraftConfirm");

    const { fetch, data, loading, error } = useRequest({
        ...useAxiosConfigRequestParams("CreateAccountingLine"),
        method: "post",
        onError: () =>
            enqueueSnackbar(fs("error"), {
                variant: "error"
            }),
        lazy: true
    });

    const create = useCallback(
        async ({ draftId, accountingLine }: CreateAccountingLineOptions) => {
            return fetch({
                url: `${API_URL}/v2/precog/drafts/${draftId}/accountingLines`,
                params: {
                    ...customerData
                },
                data: omitNilProperties(accountingLine)
            });
        },
        [fetch, customerData]
    );

    return {
        create,
        loading: loading,
        error: error,
        result: data
    };
};
