import { FormControlLabel } from "@vapor/react-material/FormControlLabel";
import { FormGroup } from "@vapor/react-material/FormGroup";
import { Switch } from "@vapor/react-material/Switch";
import { isNil } from "lodash";
import { useState } from "react";

import { useDraftAsset } from "../../../core/contexts/DraftAsset";
import omitNilProperties from "../../../utils/omitNilProperties";

interface SwitchIncrementalSpendingProps {
    assetId: string;
    assetGroupId?: string | null;
    isGroupingAsset: boolean;
    assetRegistryId: string | null | undefined;
    groupIncrementalSpending: boolean;
    incrementalSpending: boolean;
    parentId?: string | null;
    selectedAssetIds: string[];
    disabled?: boolean;
}

const SwitchIncrementalSpending = ({
    assetId,
    assetGroupId,
    assetRegistryId,
    incrementalSpending,
    groupIncrementalSpending,
    parentId,
    selectedAssetIds,
    disabled
}: SwitchIncrementalSpendingProps) => {
    const [, setOpenSnackbar] = useState(false);

    const { update } = useDraftAsset();

    const handleIncrementalToggle = () => {
        if (!assetRegistryId) {
            setOpenSnackbar(true);
        }

        if (!!assetGroupId) {
            update(
                omitNilProperties({
                    increaseExistingAsset: !groupIncrementalSpending
                }),
                undefined,
                parentId ?? undefined
            );
        }

        if (!!assetId && isNil(parentId)) {
            update(
                omitNilProperties({
                    increaseExistingAsset: !incrementalSpending
                }),
                assetId
            );
        }

        if (selectedAssetIds.length > 1 && isNil(parentId)) {
            selectedAssetIds.forEach((assetId) => {
                update(
                    omitNilProperties({
                        increaseExistingAsset: !incrementalSpending
                    }),
                    assetId
                );
            });
        }
    };

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Switch
                        disabled={disabled}
                        checked={
                            incrementalSpending || groupIncrementalSpending
                        }
                        onClick={handleIncrementalToggle}
                    />
                }
                label={""}
            />
        </FormGroup>
    );
};

export default SwitchIncrementalSpending;
