import { enUS, itIT } from "@mui/x-date-pickers/locales";
import {
    LocalizationProvider,
    LocalizationProviderProps
} from "@vapor/react-x-date-pickers";
import { AdapterDateFns } from "@vapor/react-x-date-pickers/AdapterDateFns";
import en from "date-fns/locale/en-US";
import it from "date-fns/locale/it";
import { IntlProvider } from "react-intl";

import i18nMessages from "../../i18n";

const itDatesLocale = {
    ...itIT.components.MuiLocalizationProvider.defaultProps.localeText,
    // set the placeholders of the datepickers
    fieldDayPlaceholder: () => "GG",
    fieldMonthPlaceholder: () => "MM",
    fieldYearPlaceholder: () => "AAAA"
};

const enDatesLocale = {
    ...enUS.components.MuiLocalizationProvider.defaultProps.localeText
};

const IntlComponent = ({ children }: any) => {
    const locale = localStorage.getItem("i18nextLng")?.includes("it")
        ? "it-It"
        : "en-US";

    const adapterLocale = locale === "it-It" ? it : en;
    const datesLocale = locale === "it-It" ? itDatesLocale : enDatesLocale;

    return (
        <IntlProvider locale={locale} key={locale} messages={i18nMessages.it}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={adapterLocale}
                // TODO: analyze and fix the interfaces problem
                localeText={
                    datesLocale as LocalizationProviderProps<
                        Date,
                        typeof datesLocale
                    >["localeText"]
                }
            >
                {children}
            </LocalizationProvider>
        </IntlProvider>
    );
};

export const IntlService = () => ({
    target: "$REACT_ROOT_WRAPPER",
    handler: {
        component: ({ children }: any) => (
            <IntlComponent>{children}</IntlComponent>
        )
    }
});
