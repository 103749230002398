import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import { WithholdingType } from "../../../utils/appEnums";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { WithholdingDetails } from "../dtos/WithholdingDetailsDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseGetWithholdingDetails {
    loading: boolean;
    error: any;
    withholdingDetails: WithholdingDetails | null;
    getWithholgingDetails: (
        type: WithholdingType,
        id: string
    ) => Promise<WithholdingDetails>;
}

export const useGetWithholdingDetails = (): UseGetWithholdingDetails => {
    const customerData = useCustomerData();

    const { fetch, data, loading, error } = useGet(`ignored`, {
        ...useAxiosConfigRequestParams("Withholding Details"),
        lazy: true
    });

    const getWithholgingDetails = async (type: WithholdingType, id: string) => {
        return fetch({
            url: `${API_URL}/v1/withholding/${
                type === "ACTIVE" ? "active" : "passive"
            }-details/${id}`,
            params: omitNilProperties({
                ...customerData
            })
        }).then((response: AxiosResponse<WithholdingDetails>) => response.data);
    };

    return {
        getWithholgingDetails,
        loading: loading,
        error: error,
        withholdingDetails: data
    };
};
