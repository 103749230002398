import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useGet } from "@onefront/react-sdk";
import { useQuery } from "@tanstack/react-query";

import { API_URL } from "../../../config";
import { Reason } from "../../../utils/appEnums";
import { isVat } from "../../../utils/reasonUtils";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { VatRegime } from "../../usecases/dtos/VatRegimesDto";

const useGetVatRegimes = () => {
    const customerData = useCustomerData();

    const { fetch } = useGet<VatRegime[]>(`${API_URL}/v1/vat/regimes`, {
        ...useAxiosConfigRequestParams("VatRegimes"),
        lazy: true
    });

    const get = async (reasonId: string) => {
        return fetch({
            params: {
                ...customerData,
                reasonId: reasonId
            }
        }).then(({ data }) => data);
    };

    return {
        get
    };
};

export const vatRegimesQueryKeys = createQueryKeys("vat-regimes", {
    all: (reason: Reason) => [{ reason }]
});

export const useVatRegimes = (reason?: Reason) => {
    const { get } = useGetVatRegimes();

    return useQuery({
        enabled: !!reason && isVat(reason),
        ...vatRegimesQueryKeys.all(reason!),
        queryFn: () => get(reason!)
    });
};
