import { Spinner } from "@comic/precog-components";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Divider from "@vapor/react-material/Divider";
import Stack from "@vapor/react-material/Stack";
import TextField from "@vapor/react-material/TextField";
import { capitalize, isNil } from "lodash";

import { WithholdingDetails } from "../../../../../core/usecases/dtos/WithholdingDetailsDto";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../components/FormattedAmount";
import FormattedPercentage from "../../../../components/FormattedPercentage";
import CurrencyField from "../../../../components/CurrencyField";

const fs = getFormattedStringWithScope("views.Withholdings.drawer.management");

interface ManagementTabProps {
    withholdingDetails: WithholdingDetails | null;
}

export default function ManagementTab({
    withholdingDetails
}: ManagementTabProps) {
    return withholdingDetails ? (
        <Box marginLeft={4} display="flex">
            <Stack marginTop={6} width="100%">
                <Box width={500}>
                    <TextField
                        fullWidth
                        label={
                            <Typography variant="body500">
                                {fs("reason")}
                            </Typography>
                        }
                        readOnly
                        value={`${withholdingDetails.reasonCode} - ${capitalize(
                            withholdingDetails.reasonDescription
                        )}`}
                    />
                </Box>
                {withholdingDetails.cuReason ? (
                    <Stack marginTop={3} direction="row">
                        <Typography variant="body" marginRight={0.7}>
                            {fs("cuReason")}:
                        </Typography>
                        <Typography variant="body700" marginLeft={0.7}>
                            {withholdingDetails.cuReason}
                        </Typography>
                    </Stack>
                ) : null}
                {withholdingDetails.tributeCode ? (
                    <Stack marginTop={3} direction="row">
                        <Typography variant="body" marginRight={0.7}>
                            {fs("tributeCode")}:
                        </Typography>
                        <Typography variant="body700" marginLeft={0.7}>
                            {withholdingDetails.tributeCode}
                        </Typography>
                    </Stack>
                ) : null}
                <Divider
                    sx={{ marginTop: 3, marginRight: 2 }}
                    orientation="horizontal"
                    flexItem
                    light
                />
                {withholdingDetails.baseTaxableShare ? (
                    <Stack marginTop={3} direction="row">
                        <Typography variant="body" marginRight={0.7}>
                            {fs("baseTaxableAmount")}:
                        </Typography>
                        <FormattedPercentage
                            variant="body700"
                            marginLeft={0.7}
                            percentage={withholdingDetails.baseTaxableShare}
                        />
                    </Stack>
                ) : null}
                {withholdingDetails.totalNetAmount ? (
                    <Stack marginTop={3} direction="row">
                        <Typography variant="body" marginRight={0.7}>
                            {fs("totalNetAmount")}:
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            marginLeft={0.7}
                            currency="EUR"
                            amount={withholdingDetails.totalNetAmount}
                        />
                    </Stack>
                ) : null}
                {!isNil(withholdingDetails.cashContribution) && (
                    <Stack marginTop={3} width="40%">
                        <Typography variant="body500">
                            {fs("cashContribution")}
                        </Typography>
                        <CurrencyField
                            value={withholdingDetails.cashContribution}
                            currency={"EUR"}
                            readOnly
                        />
                    </Stack>
                )}
                {!isNil(withholdingDetails.notIncomeAmount) && (
                    <Stack marginTop={3} width="40%">
                        <Typography variant="body500">
                            {fs("notIncomeAmount")}
                        </Typography>
                        <CurrencyField
                            value={withholdingDetails.notIncomeAmount}
                            currency={"EUR"}
                            readOnly
                        />
                    </Stack>
                )}
                {withholdingDetails.exemptIncome && (
                    <Stack marginTop={3} direction="row">
                        <Typography variant="body" marginRight={0.7}>
                            {fs("exemptIncome")}:
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            marginLeft={0.7}
                            currency="EUR"
                            amount={withholdingDetails.exemptIncome}
                        />
                    </Stack>
                )}
                {withholdingDetails.taxableAmount && (
                    <Stack marginTop={3} direction="row">
                        <Typography variant="body" marginRight={0.7}>
                            {fs("taxableAmount")}:
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            marginLeft={0.7}
                            currency="EUR"
                            amount={withholdingDetails.taxableAmount}
                        />
                    </Stack>
                )}
                {withholdingDetails.withholdingRate && (
                    <Stack marginTop={3} direction="row">
                        <Typography variant="body" marginRight={0.7}>
                            {fs("withholdingRate")}:
                        </Typography>
                        <FormattedPercentage
                            variant="body700"
                            marginLeft={0.7}
                            percentage={withholdingDetails.withholdingRate}
                        />
                    </Stack>
                )}
                {withholdingDetails.withHoldingTaxAmount && (
                    <Stack marginTop={3} direction="row">
                        <Typography variant="body" marginRight={0.7}>
                            {fs("withHoldingTaxAmount")}:
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            marginLeft={0.7}
                            currency="EUR"
                            amount={withholdingDetails.withHoldingTaxAmount}
                        />
                    </Stack>
                )}
            </Stack>
        </Box>
    ) : (
        <Spinner loading={true}>
            <Box height={300} />
        </Spinner>
    );
}
