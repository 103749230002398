import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { Reason } from "../../../utils/appEnums";
import { isActive } from "../../../utils/reasonUtils";
import { useGetVatDestinations } from "../../usecases/useGetVatDestinations";

export const vatDestinationsQueryKeys = createQueryKeys("vat-destinations", {
    all: null,
    get: (reason: Reason) => [{ reason }]
});

export const useVatDestinations = (reason?: Reason) => {
    const { fetchWithParameters } = useGetVatDestinations({ lazy: true });

    return useQuery({
        enabled: !!reason,
        ...vatDestinationsQueryKeys.get(reason!),
        queryFn: () => fetchWithParameters(isActive(reason!))
    });
};
