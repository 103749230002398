import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@vapor/react-material";
import Button, { ButtonProps } from "@vapor/react-material/Button";

interface CustomButtonProps extends ButtonProps {
    loading?: boolean;
}

export default function CustomButton({
    loading = false,
    ...buttonProps
}: CustomButtonProps) {
    return (
        <Button
            {...buttonProps}
            startIcon={
                loading ? (
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : (
                    buttonProps.startIcon
                )
            }
            disabled={loading || buttonProps.disabled}
        >
            <Box>{buttonProps.children}</Box>
        </Button>
    );
}
