import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Divider from "@vapor/react-material/Divider";
import FormLabel from "@vapor/react-material/FormLabel";
import { capitalize } from "lodash";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import { DraftWithholdingDto } from "../../../core/usecases/dtos/DraftWithholdingDto";

interface WithholdingDrawerHeaderProps {
    withholding: DraftWithholdingDto;
    code: string;
}

const fs = getFormattedStringWithScope("views.Detail.withholdings.header");

export default function WithholdingDrawerHeader({
    withholding,
    code
}: WithholdingDrawerHeaderProps) {
    return (
        <>
            <Box marginTop={4}>
                <FormLabel
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 1
                    }}
                >
                    <Typography variant="body700">{fs("reason")}</Typography>
                </FormLabel>
                <Typography variant="body500">
                    {capitalize(withholding.description)}
                </Typography>
            </Box>
            <Box display="flex" marginTop="24px">
                <Typography variant="body" marginRight="5px">
                    {fs("cuReason")}:
                </Typography>
                <Typography variant="body700" marginLeft="5px">
                    {withholding?.cuReason}
                </Typography>
            </Box>
            <Box display="flex" marginTop="24px">
                <Typography variant="body" marginRight="5px">
                    {fs("tributeCode")}:
                </Typography>
                <Typography variant="body700" marginLeft="5px">
                    {code}
                </Typography>
            </Box>
            <Divider
                sx={{ marginTop: 3, marginRight: 2 }}
                orientation="horizontal"
                flexItem
                light
            />
        </>
    );
}
