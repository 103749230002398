import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TypographyPropsVariantOverrides } from "@mui/material/Typography";
import { OverridableStringUnion } from "@mui/types";
import { ExtendedTypography as Typography } from "@vapor/react-extended";
import { AlertProps, NotificationInline } from "@vapor/react-material";

interface AlertBannerProps extends AlertProps {
    title: string;
    size?: SizeProp;
    iconProp: IconProp;
    typographyVariant?: OverridableStringUnion<
        "inherit",
        TypographyPropsVariantOverrides
    >;
}

export default function AlertBanner({
    title,
    severity,
    size = "1x",
    variant = "outlined",
    iconProp,
    typographyVariant = "body500"
}: AlertBannerProps) {
    return (
        <NotificationInline
            icon={<FontAwesomeIcon icon={iconProp} size={size} />}
            severity={severity}
            variant={variant}
        >
            <Typography variant={typographyVariant}> {title}</Typography>
        </NotificationInline>
    );
}
