import { useGet } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AvailableRegistersDto } from "../dtos/AvailableRegistersDto";

interface UseGetAvailableRegisters {
    loading: boolean;
    error: any;
    registers: AvailableRegistersDto[];
    fetch: (year: string) => Promise<AvailableRegistersDto[]>;
}

export const useGetAvailableRegisters = (): UseGetAvailableRegisters => {
    const customerData = useCustomerData();

    const {
        fetch: fetchData,
        data,
        loading,
        error
    } = useGet(`${ASYNC_API_URL}/vat/registers`, {
        ...useAxiosConfigRequestParams("GetRegisters"),
        params: omitNilProperties({
            ...useCustomerData()
        }),
        lazy: true
    });

    const fetch = async (year: string) => {
        return fetchData({
            params: {
                workspaceId: customerData.workspaceId,
                year: year
            }
        }).then(({ data }) => data);
    };

    return {
        fetch,
        loading: loading,
        error: error,
        registers: data ?? []
    };
};
