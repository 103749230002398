import { useGet, useSnackbar } from "@onefront/react-sdk";
import format from "date-fns/format";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { LedgerEntries } from "../dtos/LedgerEntriesDto";
import processLedgerEntries from "./processLedgerEntries";

interface UseGetLedgerEntries {
    loading: boolean;
    error: any;
    ledgerEntries: LedgerEntries | null;
    fetch: Function;
    hasFetched: boolean;
}

interface UseGetLedgerEntriesOptions {
    accountId: string;
    fromDate?: Date | null;
    toDate?: Date | null;
    lazy?: boolean;
}

export const useGetLedgerEntries = ({
    accountId,
    fromDate,
    toDate,
    lazy
}: UseGetLedgerEntriesOptions): UseGetLedgerEntries => {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.GetLedgerEntries");

    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/ledger/items/entries`,
        {
            ...useAxiosConfigRequestParams("Ledger"),
            onError: () =>
                enqueueSnackbar(fs("error"), {
                    variant: "error"
                }),
            params: omitNilProperties({
                ...useCustomerData(),
                accountId: accountId,
                fromDate: fromDate ? format(fromDate, "yyyy-MM-dd") : undefined,
                toDate: toDate ? format(toDate, "yyyy-MM-dd") : undefined
            }),
            lazy: lazy
        }
    );

    const safeFetch = useCallback(() => fetch(), [fetch]);

    return {
        fetch: safeFetch,
        loading: loading,
        error: error,
        ledgerEntries: data && processLedgerEntries(data),
        hasFetched: hasLoaded
    };
};
