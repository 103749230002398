import { Typography } from "@vapor/react-extended";
import { Box, Grid, Stack } from "@vapor/react-material";
import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";

interface DocumentNumberAndDateProps {
    documentNumber: React.ReactNode;
    documentDate: React.ReactNode;
    docPreviewer?: React.ReactNode;
}
function DocumentNumberAndDate({
    documentDate,
    documentNumber,
    docPreviewer
}: DocumentNumberAndDateProps) {
    const fs = useFormattedStringWithScope(
        "components.Header.DocumentNumberAndDate"
    );

    return (
        <Stack direction="row">
            <Typography
                variant="titleMedium"
                color="richElectricBlue"
                marginRight="16px"
            >
                {fs("documentNumber")}
            </Typography>
            <Box width="250px">{documentNumber}</Box>
            <Typography
                variant="titleMedium"
                color="richElectricBlue"
                marginX="16px"
            >
                {fs("ofThe")}
            </Typography>
            <Box width="250px">{documentDate}</Box>
            {docPreviewer ?? null}
        </Stack>
    );
}

interface HeaderFieldsProps extends DocumentNumberAndDateProps {
    customer?: React.ReactNode;
    supplier?: React.ReactNode;
    sdiCode?: React.ReactNode;
    registrationDate?: React.ReactNode;
    reason?: React.ReactNode;
    businessAndSectional?: React.ReactNode;
    documentDescription?: React.ReactNode;
    vatRegime?: React.ReactNode;
    vatCompetence?: React.ReactNode;
    vatCollectability?: React.ReactNode;
    totalAmount?: React.ReactNode;
    bank?: React.ReactNode;
    hasReverseCharge?: React.ReactNode;
    reverseCharge?: React.ReactNode;
}

export default function HeaderFields({
    customer,
    supplier,
    sdiCode,
    businessAndSectional,
    documentDescription,
    reason,
    registrationDate,
    totalAmount,
    vatCollectability,
    vatCompetence,
    vatRegime,
    bank,
    hasReverseCharge,
    reverseCharge,
    ...documentNumberAndDateProps
}: HeaderFieldsProps) {
    return (
        <Stack>
            <DocumentNumberAndDate {...documentNumberAndDateProps} />
            <Stack
                direction="row"
                justifyContent="space-between"
                marginTop="64px"
            >
                <Grid container spacing={3} marginRight={2}>
                    <Grid item xs={12} lg={4}>
                        <Stack>
                            {customer ?? supplier ?? bank}
                            {sdiCode}
                        </Stack>
                        {hasReverseCharge}
                    </Grid>
                    {hasReverseCharge && <Grid item></Grid>}
                    <Grid item container xs rowGap={5}>
                        <Grid container alignItems="flex-end" spacing={3}>
                            <Grid item xs={4}>
                                {registrationDate}
                            </Grid>
                            <Grid item xs={4}>
                                {reason}
                            </Grid>
                            <Grid item xs={4}>
                                {businessAndSectional}
                            </Grid>
                            {documentDescription && (
                                <Grid item xs={12} lg={12}>
                                    {documentDescription}
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={3}>
                            {vatRegime && (
                                <Grid item xs={4}>
                                    {vatRegime}
                                </Grid>
                            )}
                            {vatCompetence && (
                                <Grid item xs={4}>
                                    {vatCompetence}
                                </Grid>
                            )}
                            {vatCollectability && (
                                <Grid item xs={4}>
                                    {vatCollectability}
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={3}>
                            {reverseCharge && (
                                <Grid item xs={4}>
                                    {reverseCharge}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {totalAmount}
            </Stack>
        </Stack>
    );
}
