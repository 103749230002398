import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import { FiscalPayment } from "../../../core/usecases/dtos/FiscalPaymentDto";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface GetUnpaidDocumentsOptions {
    businessId: string;
    active?: boolean;
}

interface UseGetUnpaidDocuments {
    loading: boolean;
    unpaidDocuments: FiscalPayment[];
    getUnpaidDocuments: (options: GetUnpaidDocumentsOptions) => Promise<void>;
}

export const useGetUnpaidDocuments = (): UseGetUnpaidDocuments => {
    const customerData = useCustomerData();

    const { fetch, data, loading } = useGet(
        `${API_URL}/v1/fiscal-payment/settle-account`,
        {
            ...useAxiosConfigRequestParams("UnpaidDocumentss"),
            lazy: true
        }
    );

    const getUnpaidDocuments = async ({
        businessId,
        active = true
    }: GetUnpaidDocumentsOptions) => {
        return fetch({
            params: omitNilProperties({
                ...customerData,
                businessId,
                active: active
            })
        }).then(({ data }) => data);
    };

    return {
        getUnpaidDocuments,
        loading: loading,
        unpaidDocuments: data ?? []
    };
};
