import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Stack
} from "@vapor/react-material";
import { capitalize, isNil } from "lodash";
import { useIntl } from "react-intl";

import useCurrencySymbol from "../../../../core/commons/hooks/useCurrencySymbol";
import {
    useDeleteContextualPayment,
    usePayments
} from "../../../../core/domain/Draft/Payments/queries";
import { useDraft } from "../../../../core/domain/Draft/queries";
import { Payment } from "../../../../core/usecases/dtos/PaymentsDto";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../CustomButton";
import FormattedDate from "../../FormattedDate";

interface DeletePaymentDialogProps {
    draftId: string;
    isOpen: boolean;
    payment: Payment;
    onClose: () => void;
}

const fs = getFormattedStringWithScope(
    "components.Accounting.DeletePaymentDialog"
);

export default function DeletePaymentDialog({
    draftId,
    isOpen,
    payment,
    onClose
}: DeletePaymentDialogProps) {
    const { data: draft } = useDraft(draftId);
    const { refCurrency } = draft ?? { refCurrency: "EUR" };
    const { data: payments, isSuccess } = usePayments({
        draftId,
        paymentType: "MANUAL"
    });

    const { mutateAsync, isPending } = useDeleteContextualPayment({
        draftId,
        paymentId: payment.uuid
    });

    const { formatNumber } = useIntl();

    const currencySymbol = useCurrencySymbol();

    if (!isSuccess) {
        return null;
    }

    const [residualPayment] = payments.filter((payment) => payment.isResidual);

    const handleDeletePayment = async () => {
        await mutateAsync();
        onClose();
    };

    const renderPaymentInfo = (payment: Payment) => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Typography variant="bodyLarge" marginTop="8px">
                        {`Importo \t ${formatNumber(
                            payment.amount
                        )} ${currencySymbol(refCurrency)}`}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    {isNil(payment.date) ? (
                        <Typography>&nbsp;</Typography>
                    ) : (
                        <FormattedDate
                            variant="bodyLarge"
                            marginTop="8px"
                            date={payment.date}
                        />
                    )}
                </Grid>
                <Grid item xs={6}>
                    {capitalize(payment.accountDesc)}
                </Grid>
            </Grid>
        );
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{fs("deletePayment")}</DialogTitle>
            <Divider variant="alert" color="#D82829" />
            <DialogContent>
                <Stack
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="center"
                >
                    {renderPaymentInfo(payment)}
                    {residualPayment && renderPaymentInfo(residualPayment)}
                    <Typography variant="bodyLarge" marginTop="24px">
                        <Typography variant="bodyLarge700" marginRight="4px">
                            {fs("warning")}
                        </Typography>
                        {fs("body")}
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={onClose}>
                    {fs("cancel")}
                </Button>
                <CustomButton
                    variant="contained"
                    color="error"
                    loading={isPending}
                    onClick={handleDeletePayment}
                >
                    {fs("delete")}
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
}
