import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Checkbox from "@vapor/react-material/Checkbox";
import Chip from "@vapor/react-material/Chip";
import { Divider } from "@vapor/react-material/Divider";
import FormControl from "@vapor/react-material/FormControl";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import FormGroup from "@vapor/react-material/FormGroup";
import Grid from "@vapor/react-material/Grid";
import InputLabel from "@vapor/react-material/InputLabel";
import Stack from "@vapor/react-material/Stack";
import TextField from "@vapor/react-material/TextField";
import getYear from "date-fns/getYear";
import debounce from "lodash/debounce";
import isNil from "lodash/isNil";
import xor from "lodash/xor";
import { useEffect, useState } from "react";
import { v4 } from "uuid";

import useEnter from "../../../../core/commons/hooks/useEnter";
import { includeSectionsEnum } from "../../../../core/usecases/dtos/AccountedForDto";
import { RegistrationType } from "../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import DateRangePicker from "../../../components/DateRangePicker";
import NewRegistrationButton from "../../../components/NewRegistrationButton";
import SelectYear from "../../../components/SelectYear";
import { useGetCount } from "../../../../core/usecases/useGetCount";
import { CountAll } from "../../../../core/usecases/interfaces/Count";

interface FilterChip {
    key: RegistrationType;
    desc: string;
}

const fs = getFormattedStringWithScope("views.Dashboard.toBeAccounted");

interface FilterToBeAccountedProps {
    activeCategories: RegistrationType[];
    loadingDashboard: boolean;
    dateFrom: Date | null;
    onChangeDateFrom: (date: Date) => void;
    dateTo: Date | null;
    onChangeDateTo: (date: Date) => void;
    onChangeIncludeSections: (sections: any) => void;
    includeSections: string[];
    onChangeDocNumber: (newDocNumber: string) => void;
    year: number | null;
    onChangeYear: Function;
    onChangeCategory: (category: RegistrationType) => void;
}

const FilterToBeAccounted = ({
    activeCategories,
    loadingDashboard,
    dateFrom,
    onChangeDateFrom,
    dateTo,
    onChangeDateTo,
    onChangeIncludeSections,
    includeSections,
    onChangeDocNumber,
    year,
    onChangeYear,
    onChangeCategory
}: FilterToBeAccountedProps) => {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const { count, fetch: fetchCount } = useGetCount({ lazy: true });

    useEffect(() => {
        if (!hasInitialized) {
            fetchCount();
            setHasInitialized(true);
        }
    }, [hasInitialized, fetchCount]);

    let defaultChips: FilterChip[];

    defaultChips = PopulateChipsCounter(count?.notConfirmed);

    const currentYear = getYear(new Date());

    const [docNumberState, setDocNumberState] = useState<string>("");

    const [additionalFiltersVisible, setAdditionalFiltersVisible] =
        useState<boolean>(false);

    const debouncedOnChangeDocNumber = debounce(onChangeDocNumber, 300);

    useEffect(() => {
        debouncedOnChangeDocNumber(docNumberState);
    }, [docNumberState, debouncedOnChangeDocNumber]);

    const handleDocNumberEnter = useEnter({
        callback: (value) => {
            setDocNumberState(value ?? "");
        }
    });

    const handleDocNumberStateChange = (newDocNumber: string) => {
        setDocNumberState(newDocNumber);
    };

    const handleIncludeStateChange = (section: string) => {
        const newIncludeSections = xor(includeSections, [section]);
        onChangeIncludeSections(newIncludeSections);
    };

    return (
        <Box display="flex" width="100%">
            <Grid container spacing={2} alignItems="flex-start">
                {defaultChips.map((chip: FilterChip, index) => {
                    return (
                        <Grid key={v4()} item xs="auto">
                            <Chip
                                disabled={loadingDashboard}
                                key={index}
                                label={chip.desc}
                                clickable
                                variant={
                                    activeCategories.includes(chip.key)
                                        ? "outlined"
                                        : "filled"
                                }
                                onClick={() => {
                                    onChangeCategory(chip.key);
                                }}
                            />
                        </Grid>
                    );
                })}
                {additionalFiltersVisible && (
                    <>
                        <Grid item xs={5}>
                            <DateRangePicker
                                startDate={dateFrom}
                                endDate={dateTo}
                                onChangeStartDate={onChangeDateFrom}
                                onChangeEndDate={onChangeDateTo}
                                validate={(date) => !isNil(date)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel>{fs("documentNumber")}</InputLabel>
                                <TextField
                                    {...handleDocNumberEnter}
                                    onBlur={(event) =>
                                        handleDocNumberStateChange(
                                            event.target.value
                                        )
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <SelectYear
                                inputTitle={fs("selectYear")}
                                value={year}
                                startingYear={currentYear}
                                numberOfYears={5}
                                onChange={onChangeYear}
                            />
                        </Grid>
                        <br />
                        <Grid item xs={12}>
                            <Typography>{fs("documentWith")}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() =>
                                                handleIncludeStateChange(
                                                    includeSectionsEnum.WITHHOLDING
                                                )
                                            }
                                            checked={includeSections?.includes(
                                                includeSectionsEnum.WITHHOLDING
                                            )}
                                        />
                                    }
                                    label={fs("witholdings")}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() =>
                                                handleIncludeStateChange(
                                                    includeSectionsEnum.ASSET
                                                )
                                            }
                                            checked={includeSections?.includes(
                                                includeSectionsEnum.ASSET
                                            )}
                                        />
                                    }
                                    label={fs("assets")}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() =>
                                                handleIncludeStateChange(
                                                    includeSectionsEnum.INTRASTAT
                                                )
                                            }
                                            checked={includeSections?.includes(
                                                includeSectionsEnum.INTRASTAT
                                            )}
                                        />
                                    }
                                    label={fs("intrastat")}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() =>
                                                handleIncludeStateChange(
                                                    includeSectionsEnum.COMPETENCE_DATE
                                                )
                                            }
                                            checked={includeSections?.includes(
                                                includeSectionsEnum.COMPETENCE_DATE
                                            )}
                                        />
                                    }
                                    label={fs("competence")}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() =>
                                                handleIncludeStateChange(
                                                    includeSectionsEnum.REVERSE_CHARGE
                                                )
                                            }
                                            checked={includeSections?.includes(
                                                includeSectionsEnum.REVERSE_CHARGE
                                            )}
                                        />
                                    }
                                    label={fs("reverseCharge")}
                                />
                            </FormGroup>
                        </Grid>
                    </>
                )}
            </Grid>

            <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-end"
                width="100%"
                maxWidth="334px"
            >
                <Stack direction="row" spacing={2}>
                    <Button
                        startIcon={
                            <FontAwesomeIcon
                                icon={
                                    additionalFiltersVisible
                                        ? faAngleUp
                                        : faAngleDown
                                }
                            />
                        }
                        onClick={() =>
                            setAdditionalFiltersVisible(
                                !additionalFiltersVisible
                            )
                        }
                    >
                        {fs("otherFilters")}
                    </Button>
                    <Divider flexItem orientation="vertical" variant="middle" />
                    <NewRegistrationButton />
                </Stack>
            </Box>
        </Box>
    );
};

function PopulateChipsCounter(
    countNotConfirmed: CountAll | undefined
): FilterChip[] {
    let defaultChips: FilterChip[] = [];
    if (countNotConfirmed?.countPassiveInvoice.total) {
        defaultChips.push({
            key: RegistrationType.PURCHASE,
            desc: fs("filters.PURCHASE")
        });
    }
    if (countNotConfirmed?.countActiveInvoice.total) {
        defaultChips.push({
            key: RegistrationType.SALE,
            desc: fs("filters.SALE")
        });
    }
    if (countNotConfirmed?.countReceipt) {
        defaultChips.push({
            key: RegistrationType.RECEIPT,
            desc: fs("filters.RECEIPT")
        });
    }
    if (countNotConfirmed?.countBankTransaction) {
        defaultChips.push({
            key: RegistrationType.BANK_TRANSACTION,
            desc: fs("filters.BANK_TRANSACTION")
        });
    }
    if (countNotConfirmed?.countFinancialTransaction) {
        defaultChips.push({
            key: RegistrationType.FINANCIAL_TRANSACTION,
            desc: fs("filters.FINANCIAL_TRANSACTION")
        });
    }
    if (
        countNotConfirmed?.countOtherTransactions ||
        countNotConfirmed?.countOthers
    ) {
        defaultChips.push({
            key: RegistrationType.OTHER_TRANSACTION,
            desc: fs("filters.OTHER_TRANSACTION")
        });
    }

    return defaultChips;
}

export default FilterToBeAccounted;
