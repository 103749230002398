import { useCurrentWorkspace } from "@drift/oneplatfront";
import { BanksLookup } from "@onefront/workspace-forms";
import { IBankForm } from "@onefront/workspace-forms/typings/interfaces/BanksForm";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider
} from "@vapor/react-material";
import { useState } from "react";

import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import CustomButton from "../CustomButton";
import ErrorBanner from "../ErrorBanner";

const fs = getFormattedStringWithScope("components.CSL.BankDialog");

interface BankDialogProps {
    open: boolean;
    onClose: () => void;
    onSelect: (id: string) => void;
    loading: boolean;
    error?: Error | null;
}

const BankDialog = ({
    open,
    onSelect,
    onClose,
    loading,
    error
}: BankDialogProps) => {
    const { id: workspaceId } = useCurrentWorkspace(true)?.workspace ?? {
        id: ""
    };
    const [hasCreated, setHasCreated] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<string>("");

    const handleCSLSelection = (bank: IBankForm | null) => {
        if (!bank) return;
        const { id } = bank;
        if (hasCreated) {
            id && onSelect(id);
        }
        id && setSelectedId(id);
    };

    const handleSave = () => {
        onSelect(selectedId);
    };

    return (
        <>
            <Dialog
                open={open}
                PaperProps={{
                    sx: {
                        width: 600,
                        height: 484
                    }
                }}
            >
                <DialogTitle>{fs("newBank")}</DialogTitle>
                <Divider />
                <DialogContent>
                    <Box mt={10}>
                        <BanksLookup
                            onSelect={handleCSLSelection}
                            cslId={null}
                            workspaceId={workspaceId}
                            onSubmit={(bank) => {
                                setHasCreated(true);
                                // WARNING: looking at the BanksLookup code,
                                // this is called after the creation succeeds,
                                // so we can assume that the bank is not null
                                handleCSLSelection(bank);
                            }}
                            paperWidth="552px"
                        />
                    </Box>
                    {error && <ErrorBanner error={error} />}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>
                        {fs("cancel")}
                    </Button>
                    <CustomButton
                        variant="contained"
                        onClick={handleSave}
                        loading={loading}
                    >
                        {fs("save")}
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default BankDialog;
