import { useState } from "react";
import Menu from "@vapor/react-material/Menu";
import MenuItem from "@vapor/react-material/MenuItem";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { isNil } from "lodash";

import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import { AssetLogsDto } from "../../../../../core/usecases/dtos/AssetLogsDto";

const fs = getFormattedStringWithScope("views.ListAssets.movementDrawer");

interface AddMovementPopOverProps {
    logs: AssetLogsDto | null;
    onShowTabs: () => void;
    setShowElimination: (showElimination: boolean) => void;
    setShowRevaluation: (showRevaluation: boolean) => void;
}

export default function AddMovementPopOver({
    logs,
    onShowTabs,
    setShowElimination,
    setShowRevaluation
}: AddMovementPopOverProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = !isNil(anchorEl);

    return (
        <Box marginTop="40px" marginLeft="10px">
            <Button onClick={handlePopoverClick}>
                <FontAwesomeIcon icon={faCirclePlus} />
                <Typography
                    color="primary.interactiveDefault"
                    variant="bodyLarge500"
                    marginLeft="8px"
                >
                    {fs("addMovement")}
                </Typography>
            </Button>
            <Menu open={open} anchorEl={anchorEl} onClose={handlePopoverClose}>
                <MenuItem
                    onClick={() => {
                        onShowTabs();
                        setShowElimination(true);
                        setShowRevaluation(false);
                        handlePopoverClose();
                    }}
                >
                    {fs("elimination")}
                </MenuItem>
                {logs?.logs?.find((el) => el?.type?.match(/DEPRECIATION/)) &&
                    !logs?.logs?.find((el) => el?.type?.match(/REVALUATE/)) && (
                        <MenuItem
                            onClick={() => {
                                onShowTabs();
                                setShowRevaluation(true);
                                setShowElimination(false);
                                handlePopoverClose();
                            }}
                        >
                            {fs("revaluation")}
                        </MenuItem>
                    )}
            </Menu>
        </Box>
    );
}
