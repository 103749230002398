export enum DepreciationStatus {
    CANCELING = "CANCELING",
    CANCELING_ERROR = "CANCELING_ERROR",
    ERROR = "ERROR",
    GENERATED = "GENERATED",
    GENERATION_ERROR = "GENERATION_ERROR",
    GENERATING = "GENERATING",
    TODO = "TODO",
    COMPLETED = "COMPLETED"
}

export interface CheckDepreciationDto {
    status: DepreciationStatus;
}
