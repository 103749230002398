import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack } from "@vapor/react-material";
import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";

interface SdiCodeProps {
    sdiCode: string;
}

export default function SdiCode({ sdiCode }: SdiCodeProps) {
    const fs = useFormattedStringWithScope("components.Header");

    if (!sdiCode) {
        return null;
    }

    return (
        <Stack
            height="73px"
            width="320px"
            marginTop="-72px"
            justifyContent="center"
            sx={{
                background: "rgb(242, 245, 248)",
                border: "1px solid rgb(201, 217, 232)"
            }}
        >
            <Stack direction="row">
                <Typography marginRight="8px">{fs("sdiId")}</Typography>
                <Typography fontWeight="bold">{sdiCode}</Typography>
            </Stack>
        </Stack>
    );
}
