import { ColumnElement, ColumnType, TableRows } from "@comic/precog-components";
import { faSquareArrowUpRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import IconButton from "@vapor/react-material/IconButton";

import { DraftWithholdingDto } from "../../../core/usecases/dtos/DraftWithholdingDto";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../FormattedAmount";

interface WithholdingTableRowsProps {
    onOpenDrawer: (selectedWithholding: DraftWithholdingDto) => void;
    refCurrency: string;
    withholding: DraftWithholdingDto | null;
}

const fs = getFormattedStringWithScope("views.Detail.withholdings.table");

export default function WiithholdingTableRows({
    onOpenDrawer,
    refCurrency,
    withholding
}: WithholdingTableRowsProps) {
    const mappedData = withholding
        ? Object.entries(withholding.types).map(([key, value]) => ({
              type: key,
              ...value,
              cuReason: withholding?.cuReason,
              cashContributionNoWithholding:
                  withholding?.cashContributionNoWithholding
          }))
        : [];

    const columns: ColumnElement[] = [
        {
            kind: ColumnType.empty
        },
        {
            kind: ColumnType.empty
        },
        {
            kind: ColumnType.text,
            render: (row) => (
                <Typography variant="body">
                    {row?.type === "OTHER" ? fs("OTHER") : row?.type}
                </Typography>
            )
        },
        {
            kind: ColumnType.text,
            render: (row) => (
                <Typography variant="body500">{row?.cuReason}</Typography>
            )
        },
        {
            kind: ColumnType.text,
            render: (row) => (
                <>
                    <FormattedAmount
                        currency={refCurrency}
                        variant="body"
                        amount={row?.cashContributionNoWithholding}
                    />
                </>
            )
        },
        {
            kind: ColumnType.text,
            render: (row) => (
                <Typography variant="body">{row?.withholdingRate}%</Typography>
            )
        },
        {
            kind: ColumnType.text,
            render: (row) => (
                <FormattedAmount
                    variant="body"
                    amount={row?.amount}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.cta,
            render: () => (
                <IconButton
                    onClick={() => {
                        withholding && onOpenDrawer(withholding);
                    }}
                >
                    <FontAwesomeIcon
                        color="#0090d1"
                        icon={faSquareArrowUpRight}
                    />
                </IconButton>
            )
        }
    ];

    return <TableRows columns={columns} data={mappedData} />;
}
