import F24AccountingLinesTable from "./F24AccountingLinesTable";

interface F24AccountabilityProps {
    draftId: string;
    readOnly?: boolean;
}

export default function F24Accountability({
    draftId,
    readOnly = false
}: F24AccountabilityProps) {
    return <F24AccountingLinesTable draftId={draftId} readOnly={readOnly} />;
}
