import Stack from "@vapor/react-material/Stack";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Avatar, useTheme } from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
    faCircleExclamation,
    faHourglassHalf,
    IconDefinition
} from "@fortawesome/pro-regular-svg-icons";
import Grid from "@vapor/react-material/Grid";
import FormattedAmount from "../../../../../components/FormattedAmount";
import Button from "@vapor/react-material/Button";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import Box from "@vapor/react-material/Box";
import { FormattedDate } from "react-intl";

import { Logs } from "../../../../../../core/usecases/dtos/AssetLogsDto";
import getFormattedStringWithScope from "../../../../../../utils/getFormattedStringWithScope";
import { LogStatus } from "../../../../../../utils/appEnums";

const fs = getFormattedStringWithScope("views.ListAssets.movementDrawer");

interface MovementLogsProps {
    log: Logs;
    setShowSequenceDrawer: (showSequenceDrawer: boolean) => void;
    showSequenceDrawer: boolean;
    setSelectedSequence: (selectedSequence: string) => void;
}

type IconType = {
    [key in LogStatus]: IconDefinition;
};

const LogMessage = ({ log }: { log: Logs }) => {
    return (
        <Typography variant="body500" marginX={2}>
            {fs(`type.${log.type}.${log.status}`)}{" "}
            {log.date && <FormattedDate value={log.date} />}
        </Typography>
    );
};

const getIcon: IconType = {
    [LogStatus.PENDING]: faHourglassHalf,
    [LogStatus.ERROR]: faCircleExclamation,
    [LogStatus.DONE]: faCircleCheck
};

export default function MovementLog({
    log,
    setShowSequenceDrawer,
    showSequenceDrawer,
    setSelectedSequence
}: MovementLogsProps) {
    const theme = useTheme();

    return (
        <Grid container marginY={1}>
            <Grid item xs={6}>
                <Stack direction={"row"}>
                    <Avatar
                        sx={{
                            backgroundColor: theme.palette.grey[100]
                        }}
                    >
                        <FontAwesomeIcon
                            icon={getIcon[log.status]}
                            opacity={1}
                            color={theme.palette.grey[600]}
                        />
                    </Avatar>
                    <LogMessage log={log} />
                </Stack>
            </Grid>
            <Grid item xs={2}>
                <Stack justifyContent={"center"} alignItems={"flex-end"}>
                    <Typography variant="body">{fs("import")}</Typography>
                    <FormattedAmount
                        variant="body500"
                        currency="EUR"
                        amount={log?.logAmount ? log?.logAmount : 0}
                    />
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Stack justifyContent={"center"} alignItems={"flex-end"}>
                    <Typography variant="body">{fs("residual")}</Typography>
                    <FormattedAmount
                        variant="body500"
                        currency="EUR"
                        amount={log?.residualAmount ? log?.residualAmount : 0}
                    />
                </Stack>
            </Grid>
            {log.status === LogStatus.DONE ? (
                <Grid item xs={1}>
                    <Button
                        onClick={() => {
                            setShowSequenceDrawer(!showSequenceDrawer);
                            setSelectedSequence(log?.sequence);
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                </Grid>
            ) : (
                <Grid item xs={2}>
                    <Box marginTop={3} marginX="35px" marginBottom="35px"></Box>
                </Grid>
            )}
        </Grid>
    );
}
