import { every, without } from "lodash";

import { DraftDetail } from "../core/usecases/dtos/DraftDetail";

type PaymentFlagKey = keyof Pick<
    DraftDetail,
    "hasContextualPayment" | "hasAdvances" | "hasCreditNotes"
>;

export function isTheOnlyTypeOfPaymentPresent(
    paymentFlagKey: PaymentFlagKey,
    detail: DraftDetail
) {
    const allPaymentFlagKeys: PaymentFlagKey[] = [
        "hasAdvances",
        "hasContextualPayment",
        "hasCreditNotes"
    ];

    return (
        detail[paymentFlagKey] &&
        every(
            without(allPaymentFlagKeys, paymentFlagKey),
            (flagKey) => !detail[flagKey]
        )
    );
}
