import { Switch } from "@vapor/react-material/Switch/Switch";
import { useDraftUsedGood } from "../../../core/contexts/DraftUsedGood";
import omitNilProperties from "../../../utils/omitNilProperties";

interface IncreaseExistingGoodProps {
    usedGoodId: string;
    increaseExistingGood: boolean;
}

export default function IncreaseExistingGood({
    usedGoodId,
    increaseExistingGood
}: IncreaseExistingGoodProps) {
    const { update } = useDraftUsedGood();

    const handleIncrementalToggle = () => {
        if (usedGoodId) {
            update(
                omitNilProperties({
                    increaseExistingGood: !increaseExistingGood
                }),
                usedGoodId
            );
        }
    };

    return (
        <Switch
            checked={increaseExistingGood}
            onClick={handleIncrementalToggle}
        />
    );
}
