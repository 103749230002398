import {
    faInfoCircle,
    faMinusCircle
} from "@fortawesome/pro-regular-svg-icons";
import { TaskStatus } from "../../../core/usecases/dtos/TaskStatus";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import AlertBanner from "../../components/AlertBanner";
const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.CloseAndReopenAccountsOverlay.Alert"
);
export default function TaskStatusAlertBanner({
    status
}: {
    status?: TaskStatus | string;
}) {
    switch (status) {
        case TaskStatus.TODO:
            return (
                <AlertBanner
                    title={fs("simulationTodo")}
                    severity="info"
                    iconProp={faInfoCircle}
                />
            );
        case TaskStatus.GENERATING:
            return (
                <AlertBanner
                    title={fs("generationInProgress")}
                    severity="warning"
                    iconProp={faInfoCircle}
                />
            );
        case TaskStatus.ERROR:
            return (
                <AlertBanner
                    title={fs("alertInfoError")}
                    severity="error"
                    iconProp={faMinusCircle}
                />
            );
        case TaskStatus.SIMULATING:
            return (
                <AlertBanner
                    title={fs("simulationInProgress")}
                    severity="warning"
                    iconProp={faInfoCircle}
                />
            );
        case TaskStatus.SIMULATION_ERROR:
            return (
                <AlertBanner
                    title={fs("simulationError")}
                    severity="error"
                    iconProp={faMinusCircle}
                />
            );
        case TaskStatus.GENERATION_ERROR:
            return (
                <AlertBanner
                    title={fs("generationError")}
                    severity="error"
                    iconProp={faMinusCircle}
                />
            );

        case TaskStatus.REOPENING:
            return (
                <AlertBanner
                    title={fs("reopeningInProgress")}
                    severity="warning"
                    iconProp={faInfoCircle}
                />
            );
        case TaskStatus.REOPEN_ERROR:
            return (
                <AlertBanner
                    title={fs("reopeningError")}
                    severity="error"
                    iconProp={faMinusCircle}
                />
            );
        default:
            return null;
    }
}
