import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    useTheme
} from "@vapor/react-material";
import { ReactNode } from "react";

interface ActionProps {
    selected: boolean;
    onClick: () => void;
    icon: IconDefinition;
    error?: boolean;
    ctaIcon?: IconDefinition;
    divider?: boolean;
    title: string;
    subtitle?: ReactNode;
}

export const ActionsListDivider = () => (
    <Divider
        orientation="horizontal"
        component="li"
        variant="inset"
        sx={{ margin: "0 !important" }}
    />
);

export const Action = ({
    icon,
    onClick,
    selected,
    ctaIcon,
    error,
    title,
    subtitle,
    divider
}: ActionProps) => {
    const theme = useTheme();

    return (
        <>
            <MenuItem
                selected={selected}
                onClick={onClick}
                sx={{
                    backgroundColor: theme.palette.grey["100"]
                }}
            >
                <ListItemIcon>
                    <FontAwesomeIcon
                        icon={icon}
                        color={error ? theme.palette.error.main : undefined}
                    />
                </ListItemIcon>
                <ListItemText
                    primary={title}
                    secondary={subtitle}
                    sx={{
                        "& p": {
                            color: error
                                ? `${theme.palette.error.main} !important`
                                : undefined
                        }
                    }}
                />
                {ctaIcon && <FontAwesomeIcon icon={ctaIcon} />}
            </MenuItem>
            {divider && <ActionsListDivider />}
        </>
    );
};
