import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VaporToolbar } from "@vapor/react-custom";
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead
} from "@vapor/react-material";
import { isEmpty, xor } from "lodash";
import { useState } from "react";

import { FT_SHOW_DASHBOARD_STATUS_COLUMN } from "../../../../config";
import { Draft } from "../../../../core/usecases/dtos/DashboardDto";
import { useDeleteDraft } from "../../../../core/usecases/useDeleteDraft";
import { usePostDraftConfirm } from "../../../../core/usecases/usePostDraftConfirm";
import { CloseReason } from "../../../../services/DocumentDetail";
import { DraftAccountingStatus } from "../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import InfiniteScroll from "../../../components/InfiniteScroll";
import TableHeadCell from "../../../components/TableHead/TableHeadCell";
import TableHeadText from "../../../components/TableHead/TableHeadText";
import { ToBeAccountedTableContainer } from "../styled";
import DeleteDialog from "./DeleteDialog";
import DraftRow from "./DraftRow";

const fs = getFormattedStringWithScope("views.Dashboard.toBeAccounted.table");

interface ToBeAccountedTableProps {
    drafts: Draft[];
    fetchMore: Function;
    isLoadingMore: boolean;
    hasMore: boolean;
    onDeleteDraft: (draftId: string) => void;
    onConfirmDraft: (draftId: string | string[]) => void;
    onConfirmDraftFromPreview: (draftId: string) => void;
    onCloseDocumentDetail: (
        closeReason: CloseReason
    ) => (draftId: string) => void;
    fetchSingleDraft: (draftId: string) => Promise<void>;
}

export default function ToBeAccountedTable({
    fetchMore,
    isLoadingMore,
    hasMore,
    drafts,
    onDeleteDraft,
    onConfirmDraft,
    onConfirmDraftFromPreview,
    onCloseDocumentDetail,
    fetchSingleDraft
}: ToBeAccountedTableProps) {
    const [draftIdToDelete, setDraftIdToDelete] = useState<string | undefined>(
        undefined
    );
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] =
        useState<boolean>(false);

    const [openRowsIds, setOpenRowsIds] = useState<string[]>([]);
    const [selectedRowsIds, setSelectedRowsIds] = useState<string[]>([]);

    const { deleteDraft, loading: loadingDeleteDraft } = useDeleteDraft();

    const { confirmMultiple, loading: loadingDraftConfirm } =
        usePostDraftConfirm({
            lazy: true
        });

    const handleDelete = async () => {
        if (draftIdToDelete) {
            await deleteDraft(draftIdToDelete);
            onDeleteDraft(draftIdToDelete);
            setIsDeleteDialogOpen(false);
        }
    };

    const handleOpenDeleteDialog = (draftId: string) => {
        setDraftIdToDelete(draftId);
        setIsDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDraftIdToDelete(undefined);
        setIsDeleteDialogOpen(false);
    };

    const handleOpenOrCloseRow = (draftId: string) => {
        setOpenRowsIds((prevOpenRowsIds) => xor(prevOpenRowsIds, [draftId]));
    };

    const handleToggleRow = (draftId: string) => {
        setSelectedRowsIds((prevSelectedRowsIds) =>
            xor(prevSelectedRowsIds, [draftId])
        );
    };

    const handleConfirmDrafts = async () => {
        if (!isEmpty(selectedRowsIds)) {
            await confirmMultiple(selectedRowsIds);
            onConfirmDraft(selectedRowsIds);
            setSelectedRowsIds([]);
        }
    };

    const handleConfirmFromPreview = (draftId: string) => {
        onConfirmDraftFromPreview(draftId);
        setOpenRowsIds((prevOpenRowsIds) =>
            prevOpenRowsIds.filter((id) => id !== draftId)
        );
    };

    const handleCloseDocumentDetail =
        (closeReason: CloseReason) => (draftId: string) => {
            if ([CloseReason.CONFIRM, CloseReason.DELETE].includes(closeReason))
                setOpenRowsIds(
                    openRowsIds.filter((openDraftId) => openDraftId !== draftId)
                );
            onCloseDocumentDetail(closeReason)(draftId);
        };

    return (
        <>
            <ToBeAccountedTableContainer>
                <Table>
                    <TableHead shadow>
                        <TableCell></TableCell>
                        {FT_SHOW_DASHBOARD_STATUS_COLUMN ? (
                            <TableHeadCell>
                                <TableHeadText>{fs("status")}</TableHeadText>
                            </TableHeadCell>
                        ) : null}
                        <TableHeadCell>
                            <TableHeadText>{fs("reason")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell>
                            <TableHeadText>
                                {fs("customerOrSupplier")}
                            </TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell align="right">
                            <TableHeadText>{fs("docNumber")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell align="right">
                            <TableHeadText>{fs("docDate")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell>
                            <TableHeadText>{fs("sectional")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell align="right">
                            <TableHeadText>{fs("amount")}</TableHeadText>
                        </TableHeadCell>
                        <TableCell></TableCell>
                    </TableHead>
                    <TableBody>
                        {drafts.map((draft, index) => {
                            const isRowOpen = openRowsIds.includes(draft.id);
                            const isRowSelected = selectedRowsIds.includes(
                                draft.id
                            );
                            return (
                                <DraftRow
                                    key={index}
                                    draft={draft}
                                    isOpen={isRowOpen}
                                    isSelected={isRowSelected}
                                    canBeSelected={
                                        draft.draftStatus.isConfirmable
                                    }
                                    isDisabled={
                                        draft.status ===
                                        DraftAccountingStatus.PROCESS_CONFIRM
                                    }
                                    isBeingAccounted={
                                        draft.status ===
                                        DraftAccountingStatus.PROCESS_CONFIRM
                                    }
                                    onToggleRow={handleToggleRow}
                                    onRowClick={handleOpenOrCloseRow}
                                    onDeleteButtonClick={handleOpenDeleteDialog}
                                    onConfirmFromPreview={
                                        handleConfirmFromPreview
                                    }
                                    onCloseDocumentDetail={
                                        handleCloseDocumentDetail
                                    }
                                    fetchSingleDraft={fetchSingleDraft}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
                <InfiniteScroll
                    hasMore={hasMore}
                    isLoadingMore={isLoadingMore}
                    fetchMore={fetchMore}
                />
                <DeleteDialog
                    isOpen={isDeleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    onDelete={handleDelete}
                    isLoading={loadingDeleteDraft}
                />
            </ToBeAccountedTableContainer>
            <Box marginLeft="-40px">
                <VaporToolbar
                    contentRight={[
                        <Button
                            variant="contained"
                            disabled={
                                isEmpty(selectedRowsIds) || loadingDraftConfirm
                            }
                            onClick={handleConfirmDrafts}
                        >
                            {loadingDraftConfirm ? (
                                <Box marginRight="8px">
                                    <FontAwesomeIcon
                                        icon={faCircleNotch}
                                        spin
                                    />
                                </Box>
                            ) : null}
                            {fs("account")}
                        </Button>
                    ]}
                />
            </Box>
        </>
    );
}
