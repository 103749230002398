import { VaporHeaderBar } from "@vapor/react-custom";
import { ExtendedTab, ExtendedTabs } from "@vapor/react-extended";
import { Times } from "@vapor/react-icons";
import { Box, Drawer, IconButton } from "@vapor/react-material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

import { useAccountingLines } from "../../../../../core/domain/AccountingLines/queries";
import { useDraft } from "../../../../../core/domain/Draft/queries";
import { isXML } from "../../../../../utils/draftUtils";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import { hasAccountingLines } from "../../../../../utils/reasonUtils";
import AccountRegistryTab from "./AccountRegistry";
import Articles from "./Articles";
import Ledger from "./Ledger";

interface AccountDetailDrawerProps {
    draftId: string;
    isOpen: boolean;
    onClose: () => void;
    accountCode: string;
    readOnly?: boolean;
}

enum Tabs {
    LEDGER = "LEDGER",
    ARTICLES = "ARTICLES",
    ACCOUNT_REGISTRY = "ACCOUNT_REGISTRY"
}

const fs = getFormattedStringWithScope(
    "components.Accounting.AccountingLinesTable.AccountDetailDrawer"
);

export default function AccountDetailDrawer({
    draftId,
    isOpen,
    onClose,
    accountCode,
    readOnly
}: AccountDetailDrawerProps) {
    const { data: draft, isSuccess: isDraftSuccess } = useDraft(draftId);
    const { data: accountingLines, isSuccess: isAccountingLinesSuccess } =
        useAccountingLines({ draftId });

    const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.LEDGER);

    useEffect(() => {
        if (currentTab === Tabs.ARTICLES && isEmpty(accountingLines)) {
            setCurrentTab(Tabs.LEDGER);
        }
    }, [currentTab, accountingLines]);

    if (isDraftSuccess && isAccountingLinesSuccess) {
        const account = accountingLines
            .filter(
                (accountingLine) => accountingLine.accountCode === accountCode
            )
            .map((accountingLine) => ({
                accountCode: accountingLine.accountCode,
                accountDesc: accountingLine.accountDesc,
                isReadOnly: accountingLine.isReadOnly
            }))
            .at(0);

        const handleSelectTab = (tab: Tabs) => {
            setCurrentTab(tab);
        };

        const showArticlesTab =
            hasAccountingLines(draft.reasonId) &&
            !account?.isReadOnly &&
            isXML(draft);

        return (
            <Drawer
                open={isOpen}
                onClose={onClose}
                anchor="right"
                sx={{
                    "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorRight":
                        {
                            width: "calc(100% - 400px)"
                        },
                    zIndex: "2300"
                }}
            >
                <VaporHeaderBar
                    title={fs("title")}
                    description={accountCode}
                    rightItems={
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={onClose}
                        >
                            <Times />
                        </IconButton>
                    }
                />
                <ExtendedTabs
                    onChange={(_, value) => handleSelectTab(value as Tabs)}
                    size="small"
                    secondary={true}
                    value={currentTab}
                >
                    <ExtendedTab label={fs("ledger")} value={Tabs.LEDGER} />
                    {showArticlesTab && (
                        <ExtendedTab
                            label={fs("articles")}
                            value={Tabs.ARTICLES}
                        />
                    )}
                    <ExtendedTab
                        label={fs("accountRegistry")}
                        value={Tabs.ACCOUNT_REGISTRY}
                    />
                </ExtendedTabs>
                <Box padding="16px">
                    {currentTab === Tabs.LEDGER && (
                        <Ledger accountCode={accountCode} />
                    )}
                    {currentTab === Tabs.ARTICLES && (
                        <Articles
                            draftId={draftId}
                            accountDescription={account?.accountDesc}
                            accountCode={accountCode}
                            readOnly={readOnly}
                        />
                    )}
                    {currentTab === Tabs.ACCOUNT_REGISTRY && (
                        <AccountRegistryTab accountCode={accountCode} />
                    )}
                </Box>
            </Drawer>
        );
    }

    return null;
}
