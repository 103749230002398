import {
    faInfoCircle,
    faMinusCircle
} from "@fortawesome/pro-regular-svg-icons";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import { DepreciationStatus } from "../../../../core/usecases/dtos/CheckDepreciationDto";
import AlertBanner from "../../../components/AlertBanner";

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.DepreciationOverlay.Alert"
);
export default function DepreciationAlertBanner({
    status
}: {
    status?: DepreciationStatus | string;
}) {
    switch (status) {
        case DepreciationStatus.GENERATING:
            return (
                <AlertBanner
                    title={fs("generationInProgress")}
                    severity="info"
                    iconProp={faInfoCircle}
                />
            );
        case DepreciationStatus.ERROR:
            return (
                <AlertBanner
                    title={fs("alertInfoError")}
                    severity="error"
                    iconProp={faMinusCircle}
                />
            );
        case DepreciationStatus.GENERATION_ERROR:
            return (
                <AlertBanner
                    title={fs("generateError")}
                    severity="error"
                    iconProp={faMinusCircle}
                />
            );
        case DepreciationStatus.CANCELING:
            return (
                <AlertBanner
                    title={fs("cancelInProgress")}
                    severity="info"
                    iconProp={faInfoCircle}
                />
            );
        case DepreciationStatus.CANCELING_ERROR:
            return (
                <AlertBanner
                    title={fs("cancelError")}
                    severity="info"
                    iconProp={faInfoCircle}
                />
            );
        default:
            return null;
    }
}
