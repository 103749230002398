import { MenuItem, SelectProps, Stack } from "@vapor/react-material";
import { omit } from "lodash";
import React from "react";

import CurrenciesList from "../../../dataSources/currencies.json";
import omitNilProperties from "../../../utils/omitNilProperties";
import { TransparentSelect } from "../Accounting/AccountingLinesTable/styled";
import { ExtendedTypography } from "@vapor/react-extended";

const currencies = Object.entries(CurrenciesList).map((currency) => ({
    name: currency[0],
    symbol: currency[1]
}));

function getCurrencyCharacter(name: string) {
    return currencies.find((currency) => currency.name === name)?.symbol.symbol;
}

export default React.forwardRef(function SelectCurrency(
    { ...props }: SelectProps,
    ref
) {
    return props.readOnly ? (
        <ExtendedTypography
            color="rgb(21, 41, 53)"
            fontFamily="Roboto"
            fontSize="24px"
            fontWeight="bold"
            alignSelf="center"
        >
            {getCurrencyCharacter(props.value as string) ?? null}
        </ExtendedTypography>
    ) : (
        <TransparentSelect
            renderValue={(value) => getCurrencyCharacter(value as string)}
            inputProps={{
                ref: ref
            }}
            {...omitNilProperties(omit(props, "sx"))}
        >
            {currencies.map((currency, index) => (
                <MenuItem key={index} value={currency.name}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        width="100%"
                        maxWidth="200px"
                    >
                        <span>{currency.symbol.name}</span>{" "}
                        <span>{currency.symbol.symbol}</span>
                    </Stack>
                </MenuItem>
            ))}
        </TransparentSelect>
    );
});
