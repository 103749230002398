import { VaporToolbar } from "@vapor/react-custom";
import { Times } from "@vapor/react-icons";
import {
    Box,
    Button,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton
} from "@vapor/react-material";
import { isNil } from "lodash";
import { ReactNode, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { CA21DetailContext } from ".";
import CA21 from "../../ui/components/Accounting/CA21";
import NotFoundBanner from "../../ui/components/NotFoundBanner";
import OverlayDialog from "../../ui/components/OverlayDialog";
import useFormattedStringWithScope from "../../utils/useFormattedStringWithScope";

interface CA21DetailComponentProps {
    children: ReactNode;
}

export function CA21DetailComponent({ children }: CA21DetailComponentProps) {
    const [originalEntryId, setOriginalEntryId] = useState<string | null>(null);
    const fs = useFormattedStringWithScope("components.DocumentDetail");

    const open = (draftId: string) => {
        setOriginalEntryId(draftId);
    };

    const close = () => {
        setOriginalEntryId(null);
    };

    return (
        <CA21DetailContext.Provider
            value={{
                open
            }}
        >
            {children}
            {originalEntryId && (
                <OverlayDialog
                    fullScreen={true}
                    open={!isNil(originalEntryId)}
                    onClose={close}
                >
                    <DialogTitle>
                        {/* for spacing */}
                        <Box></Box>
                        {fs("documentDetail")}
                        <IconButton color="primary" onClick={close}>
                            <Times />
                        </IconButton>
                    </DialogTitle>
                    <Divider variant="fullWidth" />
                    <ErrorBoundary
                        fallback={<NotFoundBanner text={fs("error")} />}
                    >
                        <DialogContent>
                            <CA21 draftId={originalEntryId} />
                        </DialogContent>
                        <VaporToolbar
                            contentLeft={
                                <Button variant="outlined" onClick={close}>
                                    {fs("close")}
                                </Button>
                            }
                        />
                    </ErrorBoundary>
                </OverlayDialog>
            )}
        </CA21DetailContext.Provider>
    );
}
