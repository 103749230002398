import { useCurrentWorkspace } from "@drift/oneplatfront";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Box, Button, Stack, useTheme } from "@vapor/react-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
    useGetSetup,
    usePerformSetupAction
} from "../../../core/domain/Setup/queries";
import { SetupAction } from "../../../core/usecases/dtos/SetupStatusDto";
import getFormattedMessageWithScope from "../../../utils/getFormattedMessageWithScope";
import routes from "../../commons/routes";

export default function SetupBanner() {
    const theme = useTheme();
    const fm = getFormattedMessageWithScope("components.SetupBanner");
    const location = useLocation();

    const { workspace } = useCurrentWorkspace(true);
    const { data: status, isLoading, isSuccess } = useGetSetup();
    const { mutateAsync: performAction } = usePerformSetupAction();
    const navigate = useNavigate();
    const [hide, setHide] = useState(false);
    const [hiddenByUser, setHiddenByUser] = useState(false);

    useEffect(() => {
        if (location.pathname.includes(routes.accountingSetup())) {
            setHide(true);
        } else {
            setHide(false);
        }
    }, [location.pathname]);

    if (isLoading || status?.completed || hide || hiddenByUser) {
        return <></>;
    }

    if (isSuccess) {
        return (
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                p={2}
                sx={{
                    background: "white",
                    borderBottom: `4px solid ${theme.palette.primary.textTitleColor}`
                }}
            >
                {/* customer */}
                <Stack direction="row" spacing={1}>
                    <Box>
                        <FontAwesomeIcon
                            color={theme.palette.primary.textTitleColor}
                            icon={faCircleInfo}
                            size="xl"
                        />
                    </Box>
                    <Stack>
                        <Typography variant="bodyLarge700">
                            {fm({
                                id: "newClient",
                                values: { customer: workspace?.description }
                            })}
                        </Typography>
                        <Typography variant="body">
                            {status.actions?.includes(SetupAction.CONTINUE)
                                ? fm("complete")
                                : fm("question")}
                        </Typography>
                    </Stack>
                </Stack>
                {/* actions */}
                <Stack direction="row" spacing={1}>
                    {status.actions?.some(
                        (action) => action === SetupAction.REGISTER
                    ) && (
                        <Button
                            color="primary"
                            onClick={async () => {
                                await performAction(SetupAction.REGISTER);
                                navigate(routes.accountingSetup());
                            }}
                            variant="contained"
                        >
                            {fm("actions.register")}
                        </Button>
                    )}
                    {status.actions?.some(
                        (action) => action === SetupAction.CONTINUE
                    ) && (
                        <Button
                            color="primary"
                            onClick={async () => {
                                await performAction(SetupAction.CONTINUE);
                                navigate(routes.accountingSetup());
                            }}
                            variant="contained"
                        >
                            {fm("actions.continue")}
                        </Button>
                    )}
                    {status.actions?.some(
                        (action) => action === SetupAction.SKIP
                    ) && (
                        <Button
                            color="primary"
                            onClick={() => {
                                setHiddenByUser(true);
                            }}
                            variant="outlined"
                        >
                            {fm("actions.skip")}
                        </Button>
                    )}
                    {status.actions?.some(
                        (action) => action === SetupAction.IGNORE
                    ) && (
                        <Button
                            color="primary"
                            onClick={async () => {
                                await performAction(SetupAction.IGNORE);
                                setHiddenByUser(true);
                            }}
                            variant="text"
                        >
                            {fm("actions.ignore")}
                        </Button>
                    )}
                </Stack>
            </Stack>
        );
    }

    return <></>;
}
