import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import { Stack, Tooltip } from "@vapor/react-material";
import Box from "@vapor/react-material/Box";
import Card from "@vapor/react-material/Card";
import CardActions from "@vapor/react-material/CardActions";
import CardContent from "@vapor/react-material/CardContent";
import Divider from "@vapor/react-material/Divider";
import { isNil } from "lodash";

import { Period } from "../../../../core/usecases/dtos/VatSettlementsDto";
import { VatSettlement } from "../../../../core/usecases/interfaces/VatSettlement";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../components/FormattedAmount";
import PeriodCardActions from "./PeriodCardActions";

const fs = getFormattedStringWithScope("views.VAT.carousel");
const fsPeriod = getFormattedStringWithScope("commons.period");

const ADVANCE_PREFIX = "ADVANCE";

interface PeriodCardProps {
    period: Period;
    onSelectPeriod: (period: Period) => void;
    onClosePeriod: (settlement: VatSettlement) => void;
    onOpenPeriod: (settlement: VatSettlement) => void;
    isLoadingOpenSettlement: boolean;
    isLoadingCloseSettlement: boolean;
    settlement: VatSettlement;
}

const PeriodCard = ({
    period,
    onSelectPeriod,
    onClosePeriod,
    onOpenPeriod,
    isLoadingOpenSettlement,
    isLoadingCloseSettlement,
    settlement
}: PeriodCardProps) => {
    const disabled = isNil(settlement.status);

    const handleCardClick = () => {
        !disabled && onSelectPeriod(settlement.period);
    };

    const handleReopenPeriod = () => {
        if (settlement.status === "OPENABLE") onOpenPeriod(settlement);
    };

    const handleClosePeriod = () => {
        if (settlement.status === "CLOSABLE") {
            onClosePeriod(settlement);
        }
    };

    return (
        <>
            <Card
                variant="elevation"
                onClick={handleCardClick}
                sx={{
                    cursor: disabled ? "not-allowed" : "pointer",
                    width: "195px",
                    height: "195px",
                    border:
                        period.ordinal === settlement.period.ordinal
                            ? "1px solid #0090d1" // TODO: vapor should have a corresponding card state
                            : undefined
                }}
            >
                <CardContent>
                    <Box position="relative">
                        <Typography
                            variant="titleMedium"
                            color={disabled ? "gainsboro" : undefined}
                            component="div"
                            sx={{ marginBottom: "16px" }}
                        >
                            {settlement.period.ordinal.includes(
                                ADVANCE_PREFIX
                            ) ? (
                                <Stack direction="row" alignItems="center">
                                    {fsPeriod(ADVANCE_PREFIX)}
                                    <Tooltip
                                        title={fsPeriod(
                                            settlement.period.ordinal
                                        )}
                                        placement="top"
                                        arrow
                                        sx={{ marginLeft: "8px" }}
                                    >
                                        <div>
                                            <VaporIcon
                                                color={
                                                    disabled
                                                        ? "disabled"
                                                        : "primary"
                                                }
                                                icon={faCircleInfo}
                                            />
                                        </div>
                                    </Tooltip>
                                </Stack>
                            ) : (
                                fsPeriod(`${settlement.period.ordinal}`)
                            )}
                        </Typography>
                        <Box height="57px">
                            {settlement.normalizedAmount !== 0 && (
                                <>
                                    <Typography
                                        variant="label"
                                        component="div"
                                        gutterBottom
                                    >
                                        {fs(
                                            settlement.normalizedAmount < 0
                                                ? "vatOnCredit"
                                                : "vatOnDebit"
                                        )}
                                    </Typography>
                                    <FormattedAmount
                                        variant="bodySmall"
                                        amount={Math.abs(
                                            settlement.normalizedAmount
                                        )}
                                        currency="EUR"
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                </CardContent>
                <Divider light />
                <CardActions>
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                    >
                        <PeriodCardActions
                            settlement={settlement}
                            onClosePeriod={handleClosePeriod}
                            onReopenPeriod={handleReopenPeriod}
                            loadingCloseSettlement={isLoadingCloseSettlement}
                            loadingOpenSettlement={isLoadingOpenSettlement}
                        />
                    </Stack>
                </CardActions>
            </Card>
        </>
    );
};

export default PeriodCard;
