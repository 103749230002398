import { OneBoxPage } from "@drift/oneplatfront";
import Typography from "@vapor/react-extended/ExtendedTypography";

import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";

const fs = getFormattedStringWithScope("views.Error");

export default function Error() {
    return (
        <OneBoxPage title={fs("error")} showCustomerSelector={true}>
            <Typography
                variant="titleMedium"
                paddingTop="32px"
                paddingLeft="32px"
                component="div"
            >
                {fs("genericConfigError")}
            </Typography>
        </OneBoxPage>
    );
}
