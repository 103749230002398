import { Stack } from "@vapor/react-material";

import { GroupedBalanceDto } from "../../../../core/usecases/dtos/BalanceDto";
import BalanceSheet from "./BalanceSheet";
import IncomeStatement from "./IncomeStatement";

interface BalanceTableProps {
    balance: GroupedBalanceDto;
    onBalanceEntryDetailClick: (accountId: string, accountName: string) => void;
}

export default function BalanceTable({
    balance,
    onBalanceEntryDetailClick
}: BalanceTableProps) {
    return (
        <Stack marginTop="24px">
            <BalanceSheet
                balanceSheet={balance.balanceSheet}
                onRowDetailClick={onBalanceEntryDetailClick}
            />
            <IncomeStatement
                incomeStatement={balance.incomeStatement}
                onRowDetailClick={onBalanceEntryDetailClick}
            />
        </Stack>
    );
}
