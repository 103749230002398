export const readOnlySelect = (readOnly: boolean = true) => ({
    readOnly: readOnly,
    // WARNING: since the props readOnly and disabled
    // don't work as intended (who's fault is this?
    // I have a strong suspition), the following slotProps
    // prop IS REQUIRED to have the select behaving
    // as read only. Otherwise the select menu still opens
    // onclick.
    slotProps: {
        input: {
            readOnly: readOnly
        }
    }
});
