import { Box, Card, styled } from "@vapor/react-material";
import SelectCurrency from "../../SelectCurrency";

export const TotalAmountCard = styled(Card)({
    // !important is needed because when a card is rendered inside a dialog (modal)
    // height and width are both set to 100%
    height: "208px !important",
    width: "224px !important"
});

export const TotalAmountSelectCurrency = styled(SelectCurrency)({
    color: "rgb(21, 41, 53)",
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "bold"
});

export const CustomerSupplierSelectorContainer = styled(Box)<{
    error: boolean;
}>((props) => ({
    "& .MuiPaper-root": {
        height: "unset"
    },
    fieldset: {
        borderColor: props.error ? "#d32f2f" : null
    }
}));
