import { Spinner } from "@comic/precog-components";
import { OneBoxPage, useCurrentWorkspace } from "@drift/oneplatfront";
import {
    faBalanceScaleRight,
    faUniversity
} from "@fortawesome/pro-regular-svg-icons";
import Grid from "@vapor/react-material/Grid";
import { useNavigate } from "react-router-dom";

import getFormattedMessageWithScope from "../../../utils/getFormattedMessageWithScope";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import routes from "../../commons/routes";
import HomeCard from "./HomeCard";
import "./style.css";
import womanImg from "./womanImg.svg";

const fs = getFormattedStringWithScope("views.Home");
const fm = getFormattedMessageWithScope("views.Home");

const Home = () => {
    const navigate = useNavigate();
    const { workspace: customer } = useCurrentWorkspace(true);

    return (
        <OneBoxPage title={fs("title")}>
            <Spinner loading={!customer}>
                <div className="v-Home-content">
                    <div className="v-Home-intro">
                        {fm("selectTypeOfReport")}
                    </div>
                    <Grid container>
                        <HomeCard
                            icon={faBalanceScaleRight}
                            title={fm("balance")}
                            onArrowClick={() => navigate(routes.balance())}
                        />
                        <HomeCard
                            icon={faUniversity}
                            title={fm("vat")}
                            onArrowClick={() => navigate(routes.vat())}
                        />
                        <HomeCard
                            icon={faUniversity}
                            title={fm("ledger")}
                            onArrowClick={() => navigate(routes.ledger())}
                        />
                    </Grid>
                </div>
                <div className="v-Home-womanImg-container">
                    <img
                        className="v-Home-womanImg"
                        src={womanImg}
                        alt="background"
                    />
                </div>
            </Spinner>
        </OneBoxPage>
    );
};

export default Home;
