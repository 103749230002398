import { Accordion, Spinner } from "@comic/precog-components";
import VaporPage from "@vapor/react-custom/VaporPage";
import Button from "@vapor/react-material/Button";
import FormControl from "@vapor/react-material/FormControl";
import Grid from "@vapor/react-material/Grid";
import InputLabel from "@vapor/react-material/InputLabel";
import endOfYear from "date-fns/endOfYear";
import parse from "date-fns/parse";
import startOfYear from "date-fns/startOfYear";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import usePrevious from "../../../core/commons/hooks/usePrevious";
import { AccountDto } from "../../../core/usecases/dtos/AccountsDto";
import { useGetLedgerEntries } from "../../../core/usecases/useGetLedgerEntries";
import { QueryParam } from "../../../utils/appEnums";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import useQueryParam from "../../../utils/useQueryParam";
import routes from "../../commons/routes";
import SelectAccount from "../../components/Accounts/SelectAccount";
import BackArrow from "../../components/BackArrow";
import DateRangePicker, {
    DateRangePickerState
} from "../../components/DateRangePicker";
import NotFoundBanner from "../../components/NotFoundBanner";
import LedgerTable from "./LedgerTable";
import "./style.css";

const fs = getFormattedStringWithScope("views.Ledger");

export default function Ledger() {
    const navigate = useNavigate();

    const queryParams = useQueryParam();
    const presetAccountId = queryParams.get(QueryParam.accountId) ?? null;
    const presetAccountName = queryParams.get(QueryParam.accountName) ?? null;
    const presetStartDate = queryParams.get(QueryParam.startDate) ?? null;
    const presetEndDate = queryParams.get(QueryParam.endDate) ?? null;
    const callbackUrl = queryParams.get(QueryParam.callbackUrl);

    const [hasInitialized, setHasInitialized] = useState<boolean>(false);

    const [selectedAccount, setSelectedAccount] = useState<AccountDto | null>(
        presetAccountName && presetAccountId
            ? {
                  code: presetAccountId,
                  title: presetAccountName
              }
            : null
    );
    const previousSelectedAccount: AccountDto | null =
        usePrevious(selectedAccount);

    const defaultDateRange: DateRangePickerState = {
        startDate: presetStartDate
            ? parse(presetStartDate, "yyyy-MM-dd", new Date())
            : startOfYear(new Date()),
        endDate: presetEndDate
            ? parse(presetEndDate, "yyyy-MM-dd", new Date())
            : endOfYear(new Date()),
        error: false
    };

    const [selectedDateRange, setSelectedDateRange] =
        useState<DateRangePickerState>(defaultDateRange);

    const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(true);

    const {
        fetch: fetchLedgerEntries,
        ledgerEntries,
        loading: loadingLedgerEntries,
        error: errorLedgerEntries,
        hasFetched: hasFetchedLedgerEntries
    } = useGetLedgerEntries({
        accountId: selectedAccount?.code ?? "",
        fromDate: selectedDateRange.startDate,
        toDate: selectedDateRange.endDate,
        lazy: true
    });

    useEffect(() => {
        if (!hasInitialized) {
            if (
                presetAccountName &&
                presetAccountId &&
                previousSelectedAccount?.code !== selectedAccount?.code
            ) {
                fetchLedgerEntries();
                setIsAccordionOpen(false);
            }
            setHasInitialized(true);
        }
    }, [
        hasInitialized,
        fetchLedgerEntries,
        selectedAccount,
        presetAccountId,
        presetAccountName,
        previousSelectedAccount
    ]);

    const handleClearFilters = () => {
        setSelectedAccount(null);
        setSelectedDateRange(defaultDateRange);
    };

    const handleDateRangePickerChange = (
        newDateRange: DateRangePickerState
    ) => {
        setSelectedDateRange(newDateRange);
    };

    const handleSearch = () => {
        fetchLedgerEntries();
        setIsAccordionOpen(false);
    };

    const handleSelectAccount = (newSelectedAccount: AccountDto) => {
        setSelectedAccount(newSelectedAccount);
    };

    return (
        <VaporPage
            headerLeft={
                <BackArrow
                    onClick={() =>
                        callbackUrl
                            ? navigate(decodeURIComponent(callbackUrl), {
                                  replace: true
                              })
                            : navigate(routes.home())
                    }
                />
            }
            title={fs("title")}
        >
            <div className="v-Ledger-content">
                <Accordion
                    defaultExpanded={true}
                    isOpen={isAccordionOpen}
                    title={fs("filtersTitle")}
                    onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                >
                    <Grid container spacing={3} marginBottom="16px">
                        <Grid item xs={12} lg={4}>
                            <FormControl
                                fullWidth
                                required
                                error={!selectedAccount}
                            >
                                <InputLabel>{fs("selectAccount")}</InputLabel>
                                <SelectAccount
                                    value={selectedAccount}
                                    onChange={handleSelectAccount}
                                    transparent={false}
                                    autoFocus={false}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <DateRangePicker
                                startDate={selectedDateRange.startDate}
                                endDate={selectedDateRange.endDate}
                                onChange={handleDateRangePickerChange}
                                validate={(date) => !isNil(date)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} flexDirection="row-reverse">
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={handleSearch}
                                disabled={!selectedAccount}
                            >
                                {fs("search")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleClearFilters}>
                                {fs("clearFilters")}
                            </Button>
                        </Grid>
                    </Grid>
                </Accordion>
                <Spinner loading={loadingLedgerEntries}>
                    {hasFetchedLedgerEntries &&
                    isEmpty(ledgerEntries?.entries) ? (
                        <NotFoundBanner text={fs("ledgerNotFound")} />
                    ) : ledgerEntries ? (
                        <LedgerTable
                            accountCode={selectedAccount?.code}
                            ledgerEntries={ledgerEntries}
                        />
                    ) : errorLedgerEntries ? (
                        <NotFoundBanner text={fs("errorLoadingEntries")} />
                    ) : null}
                </Spinner>
            </div>
        </VaporPage>
    );
}
