import { useGet } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { ReportAccountingPeriodsDto } from "../dtos/ReportAccountingPeriodsDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseCloseAccountingPeriodReport {
    loading: boolean;
    error: any;
    closeReport: ReportAccountingPeriodsDto;
    getClosePeriod: ({
        workspaceId,
        accountingPeriodId
    }: GetClosePeriod) => Promise<ReportAccountingPeriodsDto>;
}

export interface GetClosePeriod {
    workspaceId: string;
    accountingPeriodId: string;
}

export const useCloseAccountingPeriodReport =
    (): UseCloseAccountingPeriodReport => {
        const customerData = useCustomerData();
        const fs = useFormattedStringWithScope(
            "notifications.SimulationReport"
        );

        const { enqueueSnackbar } = useSnackbar();
        const { fetch, data, loading, error } = useGet(`ignored`, {
            ...useAxiosConfigRequestParams("CloseReport"),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
            lazy: true
        });

        const getClosePeriod = ({
            workspaceId,
            accountingPeriodId
        }: GetClosePeriod) => {
            return fetch({
                url: `${ASYNC_API_URL}/accounting-periods/report`,
                params: {
                    ...customerData,
                    workspaceId,
                    accountingPeriodId
                }
            }).then(
                (response: AxiosResponse<ReportAccountingPeriodsDto>) =>
                    response.data
            );
        };

        return {
            getClosePeriod,
            loading,
            error,
            closeReport: data
        };
    };
