import { FormControl, InputLabel } from "@vapor/react-material";
import { isNil } from "lodash";
import { Controller } from "react-hook-form";

import CurrencyField, { CurrencyFieldProps } from "../CurrencyField";
import { FormField } from "./types";
import omitNilProperties from "../../../utils/omitNilProperties";

interface FormCurrencyFieldProps
    extends FormField,
        Omit<CurrencyFieldProps, "onChange" | "value"> {
    currency?: string;
    validate?: (amount: number) => boolean | string;
}

export default function FormCurrencyField({
    name,
    required,
    label,
    validate,
    ...currencyFieldProps
}: FormCurrencyFieldProps) {
    return (
        <Controller
            name={name}
            shouldUnregister={true}
            rules={omitNilProperties({
                required: required,
                validate: validate
            })}
            render={({ field, fieldState }) => (
                <FormControl
                    error={!isNil(fieldState.error)}
                    fullWidth
                    sx={{
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                            border: !isNil(fieldState.error)
                                ? "1px solid #D82829 !important"
                                : null
                        }
                    }}
                >
                    {label ? (
                        <InputLabel required={required}>{label}</InputLabel>
                    ) : null}
                    <CurrencyField {...currencyFieldProps} {...field} />
                </FormControl>
            )}
        />
    );
}
