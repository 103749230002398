import { useGet } from "@onefront/react-sdk";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AssetInfoResponse } from "../dtos/AssetInfoDto";

interface UseGetDraftAsset {
    loading: boolean;
    error: any;
    assetInfo: AssetInfoResponse | null;
    fetch: Function;
    hasFetched: boolean;
}

interface UseGetDraftAssetOptions {
    assetId?: string | null;
    draftId?: string | null;
    lazy?: boolean;
}

export const useGetDraftAsset = ({
    assetId,
    draftId,
    lazy
}: UseGetDraftAssetOptions): UseGetDraftAsset => {
    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/precog/drafts/${draftId}/asset/${assetId}`,
        {
            ...useAxiosConfigRequestParams("AssetIdInfo"),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: lazy
        }
    );

    const safeFetch = useCallback(() => fetch(), [fetch]);

    return {
        fetch: safeFetch,
        loading: loading,
        error: error,
        assetInfo: data,
        hasFetched: hasLoaded
    };
};
