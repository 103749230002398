import { useRequest } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AccountDto } from "../dtos/AccountsDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseCreateBankAccount {
    create: (bankId: string) => Promise<AccountDto>;
    loading: boolean;
    draftId?: string;
}

export default function useCreateBankAccount(): UseCreateBankAccount {
    const customerData = useCustomerData();

    const fs = useFormattedStringWithScope("notifications.CreateDraft");

    const { enqueueSnackbar } = useSnackbar();

    const { fetch, loading, data } = useRequest({
        method: "post",
        url: `${ASYNC_API_URL}/chart-of-accounts/bank-account`,
        ...useAxiosConfigRequestParams("CreateBankAccount"),
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        lazy: true
    });

    const create = async (bankId: string) => {
        return fetch({
            params: {
                ...customerData,
                bankId
            }
        }).then((response: AxiosResponse<AccountDto>) => response.data);
    };

    return {
        create,
        loading,
        draftId: data
    };
}
