import compact from "lodash/compact";
import isNil from "lodash/isNil";

import { QueryParam } from "./appEnums";

interface QueryParamEntry {
    name: QueryParam;
    value: string | number | undefined;
}

export function url(name: string): UrlBuilder {
    return UrlBuilder.get().url(name);
}

export function makeUrl(builder: UrlBuilder): string {
    return builder.build();
}

export class UrlBuilder {
    private urlLevels: string[];
    private queryParams: QueryParamEntry[];

    constructor() {
        this.urlLevels = [];
        this.queryParams = [];
    }

    static get(): UrlBuilder {
        return new UrlBuilder();
    }

    public url(name: string): UrlBuilder {
        this.urlLevels.push(name);
        return this;
    }

    public queryParam(name: QueryParam, value?: string | number): UrlBuilder {
        this.queryParams.push({ name: name, value: value });
        return this;
    }

    public build() {
        return "/".concat(
            compact(this.urlLevels).join("/"),
            "?",
            this.queryParams
                .filter((queryParam) => !isNil(queryParam.value))
                .map((queryParam) => `${queryParam.name}=${queryParam.value}`)
                .join("&")
        );
    }
}
