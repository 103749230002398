import Typography from "@vapor/react-extended/ExtendedTypography";
import { Divider, Stack, useTheme } from "@vapor/react-material";
import { AccountingPeriodDto } from "../../../../core/usecases/dtos/AccountingPeriodsDto";
import { Report } from "../../../../core/usecases/dtos/ReportAccountingPeriodsDto";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";

interface CloseAndReopenAccountsHeaderProps {
    selectedAccountingPeriod: AccountingPeriodDto;
    report: Report | undefined;
}
const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.CloseAndReopenAccountsOverlay"
);

export const CloseAndReopenAccountsHeader = ({
    selectedAccountingPeriod,
    report
}: CloseAndReopenAccountsHeaderProps) => {
    const theme = useTheme();
    return (
        <>
            <Typography variant="h6" color="primary.textTitleColor">
                {`${fs("year")} ${selectedAccountingPeriod.sequenceNumber}`}
            </Typography>
            <Stack
                direction="row"
                marginBottom={6}
                marginTop={6}
                spacing={8}
                sx={{ backgroundColor: theme.palette.grey["100"] }}
                padding={2}
            >
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    display={"flex"}
                    width={"30%"}
                >
                    <HeaderLeft
                        report={report}
                        selectedAccountingPeriod={selectedAccountingPeriod}
                    />
                </Stack>
                <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ opacity: 0.6 }}
                />
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    display={"flex"}
                    width={"100%"}
                >
                    <HeaderRight report={report} />
                </Stack>
            </Stack>
        </>
    );
};
