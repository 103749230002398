interface Tribute {
    code: string;
    isDebit: boolean;
    isCredit: boolean;
}

export const TRIBUTES: Tribute[] = [
    { code: "1001", isDebit: true, isCredit: false },
    { code: "1002", isDebit: true, isCredit: false },
    { code: "1012", isDebit: true, isCredit: false },
    { code: "1040", isDebit: true, isCredit: false },
    { code: "1627", isDebit: false, isCredit: true },
    { code: "1628", isDebit: false, isCredit: true },
    { code: "1655", isDebit: false, isCredit: true },
    { code: "1712", isDebit: true, isCredit: false },
    { code: "1713", isDebit: true, isCredit: false },
    { code: "1989", isDebit: true, isCredit: false },
    { code: "1990", isDebit: true, isCredit: false },
    { code: "1991", isDebit: true, isCredit: false },
    { code: "1993", isDebit: true, isCredit: false },
    { code: "1994", isDebit: true, isCredit: false },
    { code: "1998", isDebit: true, isCredit: false },
    { code: "2001", isDebit: true, isCredit: false },
    { code: "2002", isDebit: true, isCredit: false },
    { code: "2003", isDebit: true, isCredit: true },
    { code: "3800", isDebit: true, isCredit: true },
    { code: "3801", isDebit: true, isCredit: true },
    { code: "3802", isDebit: true, isCredit: false },
    { code: "3803", isDebit: true, isCredit: false },
    { code: "3812", isDebit: true, isCredit: false },
    { code: "3813", isDebit: true, isCredit: false },
    { code: "3843", isDebit: true, isCredit: false },
    { code: "3844", isDebit: true, isCredit: true },
    { code: "3845", isDebit: true, isCredit: false },
    { code: "3846", isDebit: true, isCredit: false },
    { code: "3847", isDebit: true, isCredit: false },
    { code: "3848", isDebit: true, isCredit: false },
    { code: "3850", isDebit: true, isCredit: false },
    { code: "3851", isDebit: true, isCredit: false },
    { code: "3852", isDebit: true, isCredit: false },
    { code: "3913", isDebit: true, isCredit: false },
    { code: "3914", isDebit: true, isCredit: false },
    { code: "3915", isDebit: true, isCredit: false },
    { code: "3916", isDebit: true, isCredit: false },
    { code: "3917", isDebit: true, isCredit: false },
    { code: "3918", isDebit: true, isCredit: false },
    { code: "3919", isDebit: true, isCredit: false },
    { code: "3944", isDebit: true, isCredit: false },
    { code: "3945", isDebit: true, isCredit: false },
    { code: "3946", isDebit: true, isCredit: false },
    { code: "3959", isDebit: true, isCredit: false },
    { code: "3960", isDebit: true, isCredit: false },
    { code: "3961", isDebit: true, isCredit: false },
    { code: "3962", isDebit: true, isCredit: false },
    { code: "3963", isDebit: true, isCredit: false },
    { code: "4001", isDebit: true, isCredit: true },
    { code: "4033", isDebit: true, isCredit: false },
    { code: "4034", isDebit: true, isCredit: false },
    { code: "6001", isDebit: true, isCredit: false },
    { code: "6002", isDebit: true, isCredit: false },
    { code: "6003", isDebit: true, isCredit: false },
    { code: "6004", isDebit: true, isCredit: false },
    { code: "6005", isDebit: true, isCredit: false },
    { code: "6006", isDebit: true, isCredit: false },
    { code: "6007", isDebit: true, isCredit: false },
    { code: "6008", isDebit: true, isCredit: false },
    { code: "6009", isDebit: true, isCredit: false },
    { code: "6010", isDebit: true, isCredit: false },
    { code: "6011", isDebit: true, isCredit: false },
    { code: "6012", isDebit: true, isCredit: false },
    { code: "6013", isDebit: true, isCredit: false },
    { code: "6031", isDebit: true, isCredit: false },
    { code: "6032", isDebit: true, isCredit: false },
    { code: "6033", isDebit: true, isCredit: false },
    { code: "6034", isDebit: true, isCredit: false },
    { code: "6035", isDebit: true, isCredit: false },
    { code: "6099", isDebit: true, isCredit: true },
    { code: "6781", isDebit: false, isCredit: true },
    { code: "6782", isDebit: false, isCredit: true },
    { code: "7085", isDebit: true, isCredit: false },
    { code: "8901", isDebit: true, isCredit: false },
    { code: "8902", isDebit: true, isCredit: false },
    { code: "8904", isDebit: true, isCredit: false },
    { code: "8906", isDebit: true, isCredit: false },
    { code: "8907", isDebit: true, isCredit: false },
    { code: "8918", isDebit: true, isCredit: false },
    { code: "8926", isDebit: true, isCredit: false }
];

export function hasWithholdings(tribute: string) {
    return tribute === "1040";
}

export function isDebit(tribute?: string) {
    return TRIBUTES.find((t) => t.code === tribute)?.isDebit ?? false;
}

export function isCredit(tribute?: string) {
    return TRIBUTES.find((t) => t.code === tribute)?.isCredit ?? false;
}
