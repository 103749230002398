import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    Checkbox,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@vapor/react-material";
import { capitalize, debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { useDraft } from "../../../../core/domain/Draft/queries";
import {
    useCreateFiscalPayment,
    useDeleteFiscalPayment,
    useFiscalPayments,
    useMutateFiscalPayment
} from "../../../../core/domain/FiscalPayment/queries";
import { FiscalPayment } from "../../../../core/usecases/dtos/FiscalPaymentDto";
import { PaymentType, Reason } from "../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CurrencyField from "../../CurrencyField";
import FormattedAmount from "../../FormattedAmount";
import FormattedDate from "../../FormattedDate";
import TableHeadCell from "../../TableHead/TableHeadCell";
import TableHeadText from "../../TableHead/TableHeadText";

export interface Association {
    document: FiscalPayment;
    amount: number;
}

const fs = getFormattedStringWithScope("components.Accounting.LinkInvoices");

type AssociationAmountProps = {
    draftId: string;
    refCurrency: string;
    association: Association;
    residualAmount: number;
};
const AssociationAmount = (props: AssociationAmountProps) => {
    const { association, refCurrency, draftId, residualAmount } = props;
    const { mutate } = useMutateFiscalPayment({
        draftId: draftId,
        fiscalPaymentId: association.document.fiscalPaymentId
    });

    const handleAssociationAmountChange = debounce((amount: number) => {
        mutate({
            amount: amount
        });
    }, 500);

    return (
        <CurrencyField
            value={association.amount}
            readOnly={!association.document.saved}
            currency={refCurrency}
            isAllowed={({ floatValue }) =>
                floatValue
                    ? floatValue <=
                      Math.min(
                          association.document.remainingAmount.amount,
                          residualAmount
                      )
                    : true
            }
            onChange={(value) => handleAssociationAmountChange(value)}
        />
    );
};

type ToggleAssociationProps = {
    draftId: string;
    association: Association;
};
const ToggleAssociation = ({
    draftId,
    association
}: ToggleAssociationProps) => {
    const { mutate: deleteFiscalPayment } = useDeleteFiscalPayment();
    const { mutate: createFiscalPayment } = useCreateFiscalPayment();

    const handleToggleAssociation = () => {
        const saved = association.document.saved;

        if (saved) {
            deleteFiscalPayment({
                draftId: draftId,
                fiscalPaymentIds: association.document.fiscalPaymentId
            });
        } else {
            createFiscalPayment({
                draftId: draftId,
                data: [
                    {
                        type: association.document.type,
                        amount: association.amount,
                        date: association.document.docDate,
                        docNumber: association.document.docNumber,
                        registeredPaymentId: association.document.key
                    }
                ],
                overWrite: false
            });
        }
    };

    return (
        <Checkbox
            checked={association.document.saved}
            onChange={handleToggleAssociation}
        />
    );
};

type LinkDocumentsTableProps = {
    draftId: string;
    isPayment: boolean;
    refCurrency: string;
    associations: Association[];
    readOnly?: boolean;
    totalAmount: number;
};
const LinkDocumentsTable = (props: LinkDocumentsTableProps) => {
    const {
        draftId,
        isPayment,
        associations,
        refCurrency,
        readOnly = false,
        totalAmount
    } = props;

    const displayableAssociations = readOnly
        ? associations.filter((association) => association.document.saved)
        : associations;

    const totalAssociationsAmount = displayableAssociations
        .filter((association) => association.document.saved)
        .map((association) => association.amount)
        .reduce((acc, amount) => acc + amount, 0);

    const residualAmount = totalAmount - totalAssociationsAmount;

    return (
        <Table>
            <TableHead shadow>
                <TableRow>
                    <TableHeadCell sx={{ maxWidth: "48px" }} padding="none">
                        <TableHeadText></TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell>
                        <TableHeadText>{fs("reason")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell>
                        <TableHeadText>{fs("docNumber")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell>
                        <TableHeadText>{fs("docDate")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell align="right">
                        <TableHeadText>{fs("amount")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell align="right">
                        <TableHeadText>{fs("residual")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell align="right">
                        <TableHeadText>
                            {isPayment ? fs("payment") : fs("collection")}
                        </TableHeadText>
                    </TableHeadCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {displayableAssociations.map((association, index) => (
                    <TableRow key={index}>
                        <TableCell
                            sx={{ maxWidth: "48px" }}
                            padding="checkbox"
                            size="small"
                        >
                            {!readOnly && (
                                <ToggleAssociation
                                    draftId={draftId}
                                    association={association}
                                />
                            )}
                        </TableCell>
                        <TableCell size="small">
                            {association.document.reasonDescription}
                        </TableCell>
                        <TableCell size="small">
                            {association.document.docNumber}
                        </TableCell>
                        <TableCell size="small">
                            <FormattedDate
                                date={association.document.docDate}
                            />
                        </TableCell>
                        <TableCell size="small" align="right">
                            <FormattedAmount
                                amount={
                                    association.document.totalDueAmount.amount
                                }
                                currency={
                                    association.document.totalDueAmount.currency
                                }
                            />
                        </TableCell>
                        <TableCell size="small" align="right">
                            <FormattedAmount
                                amount={
                                    association.document.remainingAmount.amount
                                }
                                currency={
                                    association.document.remainingAmount
                                        .currency
                                }
                            />
                        </TableCell>
                        <TableCell size="small" align="right">
                            {readOnly ? (
                                <FormattedAmount
                                    amount={association.amount}
                                    currency={refCurrency}
                                />
                            ) : (
                                <AssociationAmount
                                    association={association}
                                    draftId={draftId}
                                    refCurrency={refCurrency}
                                    residualAmount={residualAmount}
                                />
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

type LinkDocumentsProps = {
    draftId: string;
    readOnly?: boolean;
};
export default function LinkDocuments({
    draftId,
    readOnly = false
}: LinkDocumentsProps) {
    const { data: draft } = useDraft(draftId);
    const { refCurrency, amount } = draft ?? { refCurrency: "EUR" };

    const [associations, setAssociations] = useState<Association[]>([]);

    const isCreditNote =
        draft?.reasonId &&
        [Reason.PNC1, Reason.RNF1].includes(draft.reasonId as Reason);
    const type = isCreditNote ? PaymentType.CREDIT_NOTE : PaymentType.INVOICE;

    const {
        data: allFiscalPayments,
        isLoading,
        isSuccess
    } = useFiscalPayments({
        active: draft?.doc.active,
        factType: draft?.factType,
        reasonId: draft?.reasonId,
        businessId: draft?.businessId,
        draftId: draftId,
        types: [type]
    });
    const fiscalPayments = useMemo(() => {
        return (
            allFiscalPayments?.filter((fiscalPayment) =>
                [draft?.customerTaxId, draft?.supplierTaxId].includes(
                    fiscalPayment.taxId
                )
            ) ?? []
        );
    }, [allFiscalPayments, draft?.customerTaxId, draft?.supplierTaxId]);

    useEffect(() => {
        setAssociations(
            fiscalPayments.map((fiscalPayment) => ({
                document: fiscalPayment,
                amount:
                    fiscalPayment.collection > 0 ? fiscalPayment.collection : 0
            }))
        );
    }, [fiscalPayments]);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (!isSuccess) {
        return null;
    }

    if (associations.length === 0) {
        return null;
    }

    return (
        <Box marginTop="32px">
            <Typography
                variant="titleMedium"
                color="richElectricBlue"
                component="div"
                gutterBottom
            >
                {fs(
                    isCreditNote
                        ? "associateSuspendedCreditNotesTo"
                        : "associateSuspendedInvoicesTo"
                )}{" "}
                {capitalize(draft?.customerName || draft?.supplierName)}
            </Typography>
            <LinkDocumentsTable
                associations={associations}
                draftId={draftId}
                isPayment={!draft?.doc.active}
                refCurrency={refCurrency}
                readOnly={readOnly}
                totalAmount={amount ?? 0}
            />
        </Box>
    );
}
