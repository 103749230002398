import { useCurrentWorkspace } from "@drift/oneplatfront";
import { Times } from "@vapor/react-icons";
import { Box, Divider, Stack } from "@vapor/react-material";

import { AccountingPeriodDto } from "../../../../core/usecases/dtos/AccountingPeriodsDto";
import { TaskStatus } from "../../../../core/usecases/dtos/TaskStatus";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import OverlayDialog from "../../../components/OverlayDialog";
import TitleCenteredDialog from "../../../components/TitleCenteredDialog";
import CloseAndReopenAccountsEmpty from "./CloseAndReopenAccountsEmpty";
import { CloseAndReopenAccountsGenerated } from "./CloseAndReopenAccountsGenerated";
import { CloseAndReopenAccountsSimulation } from "./CloseAndReopenAccountsSimulation";

interface CloseAndReopenAccountsOverlayProps {
    isOpen: boolean;
    onClose: () => void;
    selectedAccountingPeriod: AccountingPeriodDto;
    statusTask?: TaskStatus | string;
}

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.CloseAndReopenAccountsOverlay"
);

export default function CloseAndReopenAccountsOverlay({
    isOpen,
    onClose,
    selectedAccountingPeriod,
    statusTask
}: CloseAndReopenAccountsOverlayProps) {
    const { id: workspaceId } = useCurrentWorkspace(true)?.workspace ?? {
        id: ""
    };

    return (
        <OverlayDialog open={isOpen} onClose={onClose}>
            <TitleCenteredDialog
                title={fs("title")}
                icon={<Times />}
                onClose={onClose}
            />
            <Divider variant="fullWidth" />
            <Box>
                <Box padding={4}>
                    <Stack marginTop={4}>
                        {(statusTask === TaskStatus.TODO ||
                            statusTask === TaskStatus.REOPENING ||
                            statusTask === TaskStatus.SIMULATION_ERROR) && (
                            <CloseAndReopenAccountsEmpty
                                workspaceId={workspaceId}
                                selectedAccountingPeriod={
                                    selectedAccountingPeriod
                                }
                                onClose={onClose}
                                statusTask={statusTask}
                            />
                        )}
                        {(statusTask === TaskStatus.SIMULATED ||
                            statusTask === TaskStatus.SIMULATING ||
                            statusTask === TaskStatus.GENERATION_ERROR ||
                            statusTask === TaskStatus.GENERATING) && (
                            <CloseAndReopenAccountsSimulation
                                workspaceId={workspaceId}
                                selectedAccountingPeriod={
                                    selectedAccountingPeriod
                                }
                                onClose={onClose}
                                statusTask={statusTask}
                            />
                        )}
                        {(statusTask === TaskStatus.GENERATED ||
                            statusTask === TaskStatus.REOPEN_ERROR) && (
                            <CloseAndReopenAccountsGenerated
                                workspaceId={workspaceId}
                                selectedAccountingPeriod={
                                    selectedAccountingPeriod
                                }
                                onClose={onClose}
                                statusTask={statusTask}
                            />
                        )}
                    </Stack>
                </Box>
            </Box>
        </OverlayDialog>
    );
}
