import {
    ColumnElement,
    ColumnType,
    HeadCell,
    Table
} from "@comic/precog-components";
import { faSquareArrowUpRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import IconButton from "@vapor/react-material/IconButton";
import { useState } from "react";

import { Withholding } from "../../../../core/usecases/dtos/WithholdingsDto";
import { WithholdingType } from "../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../components/FormattedAmount";
import FormattedDate from "../../../components/FormattedDate";
import NotFoundBanner from "../../../components/NotFoundBanner";
import WithhodlingsDrawer from "./WithholdingsDrawer";

const fs = getFormattedStringWithScope("views.Withholdings.table");

interface WithholdingTableWithProps {
    withholding: Withholding[];
    withholdingType: WithholdingType;
}

export default function WithholdingsTable({
    withholding,
    withholdingType
}: WithholdingTableWithProps) {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedWithholding, setSelectedWithholding] =
        useState<Withholding | null>(null);

    const headeCells: HeadCell[] = [
        {
            id: withholdingType === "PASSIVE" ? "supplier" : "customer",
            kind: ColumnType.text,
            label:
                withholdingType === "PASSIVE" ? fs("supplier") : fs("customer")
        },
        {
            id: "documentNumber",
            kind: ColumnType.text,
            label: fs("documentNumber")
        },
        {
            id: "documentDate",
            kind: ColumnType.text,
            label: fs("documentDate")
        },
        {
            id: "documentAmount",
            kind: ColumnType.text,
            label: fs("documentAmount")
        },
        {
            id: "totalAmount",
            kind: ColumnType.text,
            label: fs("totalAmount")
        },
        {
            id: "residualAmount",
            kind: ColumnType.text,
            label: fs("residualAmount")
        },
        {
            id: "",
            kind: ColumnType.cta,
            label: ""
        }
    ];

    const columns: ColumnElement[] = [
        {
            kind: ColumnType.text,
            render: (withholding: Withholding) => (
                <Typography variant="body500">
                    {withholdingType === "PASSIVE"
                        ? withholding?.supplier
                        : withholding?.customer}
                </Typography>
            )
        },
        {
            kind: ColumnType.text,
            render: (withholding: Withholding) => (
                <Typography variant="body500">
                    {withholding.documentNumber}
                </Typography>
            )
        },
        {
            kind: ColumnType.text,
            render: (withholding: Withholding) => (
                <FormattedDate
                    variant="body500"
                    date={withholding.documentDate}
                />
            )
        },
        {
            kind: ColumnType.text,
            render: (withholding: Withholding) => (
                <FormattedAmount
                    variant="body500"
                    amount={withholding.documentAmount}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.text,
            render: (withholding: Withholding) => (
                <FormattedAmount
                    variant="body500"
                    amount={withholding.totalAmount}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.text,
            render: (withholding: Withholding) => (
                <FormattedAmount
                    variant="body500"
                    amount={withholding.residualAmount}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.cta,
            render: (withholding: Withholding) => (
                <Box>
                    <IconButton
                        onClick={() => {
                            setSelectedWithholding(withholding);
                            setOpen(!open);
                        }}
                    >
                        <FontAwesomeIcon
                            color="#0090d1"
                            icon={faSquareArrowUpRight}
                            className="v-WithholdingTable-icon"
                        />
                    </IconButton>
                </Box>
            )
        }
    ];

    return (
        <Box>
            {withholding.length > 0 ? (
                <Box
                    style={{
                        height: "500px",
                        width: "100%",
                        marginTop: "32px",
                        overflowX: "auto"
                    }}
                >
                    <Table
                        headCells={headeCells}
                        columns={columns}
                        data={withholding}
                    />
                    {open ? (
                        <WithhodlingsDrawer
                            open={open}
                            onClose={() => setOpen(false)}
                            selectedWithholding={selectedWithholding}
                            withholdingType={withholdingType}
                        />
                    ) : null}
                </Box>
            ) : (
                <NotFoundBanner
                    text={fs(
                        withholdingType === "ACTIVE"
                            ? "noResultsActive"
                            : "noResultsPassive"
                    )}
                />
            )}
        </Box>
    );
}
