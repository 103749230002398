import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import FormControl from "@vapor/react-material/FormControl";
import InputLabel from "@vapor/react-material/InputLabel";
import MenuItem from "@vapor/react-material/MenuItem";
import Select from "@vapor/react-material/Select";
import Stack from "@vapor/react-material/Stack";
import { useState } from "react";

import { usePostAssetRevaluation } from "../../../../../../../core/usecases/usePostAssetRevaluation";
import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";
import CurrencyField from "../../../../../../components/CurrencyField";
import DrawerDatePicker from "../../../../../../components/DrawerDatePicker";

const fs = getFormattedStringWithScope(
    "views.ListAssets.movementDrawer.revaluationSubsection"
);

interface RevaluationSubsectionPageProps {
    setShowRevaluation: Function;
    onShowTabs: () => void;
    totalResidualAmount?: number;
    assetId: string | undefined;
    businessId: string | undefined;
    onRevaluate: () => void;
}

export default function RevaluationSubsectionPage({
    totalResidualAmount,
    assetId,
    businessId,
    onRevaluate
}: RevaluationSubsectionPageProps) {
    const [applicationField, setApplicationField] = useState<string>("");
    const [currentValue, setCurrentValue] = useState<number | undefined>(
        totalResidualAmount
    );
    const [revaluationDate, setShowRevaluationDate] = useState<
        Date | undefined
    >(undefined);
    const [revaluationType, setRevaluationType] = useState<string>("");

    const { fetch: fetchPostData } = usePostAssetRevaluation({
        assetId: assetId,
        businessId: businessId,
        dataToPost: {
            applicationField,
            currentValue,
            revaluationDate,
            revaluationType
        },
        lazy: true
    });

    const handleAppFieldChange = (event: any) => {
        setApplicationField(event?.target?.value as string);
    };

    const revaluationDateChange = (newValue: Date) => {
        setShowRevaluationDate(newValue);
    };

    const revaluationTypeChange = (event: any) => {
        setRevaluationType(event?.target?.value as string);
    };

    const handleValueChange = (newAmount: number) => {
        setCurrentValue(newAmount);
    };

    return (
        <Box marginTop="24px">
            <Stack>
                <Box
                    marginLeft="30px"
                    display="flex"
                    justifyContent="space-between"
                    width="90%"
                >
                    <Box width={300}>
                        <FormControl fullWidth>
                            <InputLabel>{fs("currentValue")}</InputLabel>
                            <CurrencyField
                                value={currentValue ?? 0}
                                currency="EUR"
                                onChange={(value) => {
                                    handleValueChange(value);
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box width="300px">
                        <FormControl sx={{ width: "100%" }}>
                            <DrawerDatePicker
                                showIcon={false}
                                date={revaluationDate}
                                onChangeDate={revaluationDateChange}
                                label={fs("revaluationDate")}
                            />
                        </FormControl>
                    </Box>
                </Box>
                <Box
                    marginLeft="30px"
                    marginTop="55px"
                    display="flex"
                    justifyContent="space-between"
                    width="90%"
                >
                    <Stack width="300px">
                        <FormControl>
                            <InputLabel id="revaluationType">
                                {fs("revaluationType")}
                            </InputLabel>
                            <Select
                                id="revaluationType"
                                label={fs("revaluationType")}
                                onChange={revaluationTypeChange}
                                value={revaluationType}
                                name="revaluationType"
                            >
                                <MenuItem value="REV_COST">
                                    {fs("revaluationCost")}
                                </MenuItem>
                                <MenuItem value="REV_COST_AND_FUND">
                                    {fs("revaluationCostAndFund")}
                                </MenuItem>
                                <MenuItem value="DECR_FUND">
                                    {fs("decrementFund")}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack width="300px">
                        <FormControl>
                            <InputLabel id="applicationField">
                                {fs("applicationFied")}
                            </InputLabel>
                            <Select
                                id="applicationField"
                                label={fs("applicationFied")}
                                onChange={handleAppFieldChange}
                                value={applicationField}
                                name="applicationField"
                            >
                                <MenuItem value="TAX">{fs("tax")}</MenuItem>
                                <MenuItem value="STATUTORY">
                                    {fs("statutory")}
                                </MenuItem>
                                <MenuItem value="STATUTORY_AND_TAX">
                                    {fs("statutoryAndTax")}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </Box>
            </Stack>
            <VaporToolbar
                variant="surface"
                withoutAppBar={false}
                contentRight={
                    <>
                        <Button
                            onClick={() => {
                                fetchPostData().then(() => onRevaluate());
                            }}
                            variant="contained"
                            disabled={
                                !(
                                    applicationField &&
                                    currentValue &&
                                    revaluationDate &&
                                    revaluationType
                                )
                            }
                        >
                            {fs("confirmRevaluation")}
                        </Button>
                    </>
                }
            />
        </Box>
    );
}
