import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { curry } from "lodash";

import { AccountType } from "../../../utils/appEnums";
import { default as useCreateCSBAccountApi } from "../../usecases/useCreateCSBAccount";
import { useGetAccounts } from "../../usecases/useGetAccounts";
import { accountingLinesQueryKeys } from "../AccountingLines/queries";

export type UseAccountsOptions = {
    includeSystemAccounts?: boolean;
    includeBalanceSheetAccounts?: boolean;
    includeIncomeStatementAccounts?: boolean;
    searchString?: string;
};

export const accountsQueryKeys = createQueryKeys("accounts", {
    all: null,
    search: (options: UseAccountsOptions) => [{ options }]
});

function getFilters(options: UseAccountsOptions) {
    if (
        options.includeBalanceSheetAccounts &&
        !options.includeIncomeStatementAccounts
    ) {
        return {
            onlyType: AccountType.BALANCE_SHEET
        };
    } else if (
        options.includeIncomeStatementAccounts &&
        !options.includeBalanceSheetAccounts
    ) {
        return {
            onlyType: AccountType.INCOME_STATEMENT
        };
    }

    return {};
}

export const useAccounts = (
    options: UseAccountsOptions = {
        includeBalanceSheetAccounts: true,
        includeIncomeStatementAccounts: true,
        includeSystemAccounts: false,
        searchString: ""
    }
) => {
    const { get } = useGetAccounts();

    return useQuery({
        ...accountsQueryKeys.search(options),
        queryFn: () =>
            get({ ...getFilters(options), search: options.searchString }),
        select: (data) => data.accounts
    });
};

export const useCreateCSBAccount = (params: { draftId: string }) => {
    const { create } = useCreateCSBAccountApi();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ["createCSBAccount"],
        mutationFn: curry(create)(params.draftId),
        onSuccess: () => {
            queryClient.invalidateQueries(
                accountingLinesQueryKeys.get({
                    draftId: params.draftId
                })
            );
        }
    });
};
