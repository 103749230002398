import { Spinner } from "@comic/precog-components";
import { useCurrentWorkspace } from "@drift/oneplatfront";
import {
    faBadgePercent,
    faCarBuilding,
    faPrescriptionBottleAlt,
    faWallet
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExtendedTab } from "@vapor/react-extended/ExtendedTab";
import ExtendedTabs from "@vapor/react-extended/ExtendedTabs";
import { IconButton, Tooltip } from "@vapor/react-material";
import Box from "@vapor/react-material/Box";
import getYear from "date-fns/getYear";
import isValid from "date-fns/isValid";
import { isArray } from "lodash";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { endOfYear, startOfYear } from "date-fns";
import setYear from "date-fns/setYear";
import usePrevious from "../../../core/commons/hooks/usePrevious";
import {
    BusinessesProvider,
    useBusinesses
} from "../../../core/contexts/Businesses";
import { useGetDashboardPaginated } from "../../../core/usecases/useGetDashboardPaginated";
import {
    UseGetLedgerAccountedEntriesFilters,
    useGetLedgerAccountedEntries
} from "../../../core/usecases/useGetLedgerAccountedEntries";
import { CloseReason } from "../../../services/DocumentDetail";
import { RegistrationType } from "../../../utils/appEnums";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import routes from "../../commons/routes";
import { DateRangePickerState } from "../../components/DateRangePicker";
import NotFoundBanner from "../../components/NotFoundBanner";
import WidgetsContainer from "../../widgets/WidgetsContainer";
import AccountedForTable from "./AccountedForTable";
import FilterAccountedFor from "./Filters/AccountedFor";
import FilterToBeAccounted from "./Filters/ToBeAccounted";
import ToBeAccountedTable from "./ToBeAccountedTable";
import { DashboardPage } from "./styled";

const fs = getFormattedStringWithScope("views.Dashboard");

enum DashboardTab {
    TO_BE_ACCOUNTED = "toBeAccounted",
    ACCOUNTED = "accounted"
}

export default function Dashboard() {
    return (
        <BusinessesProvider>
            <DashboardView />
        </BusinessesProvider>
    );
}

export function DashboardView() {
    const navigate = useNavigate();
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);

    const [categories, setCategories] = useState<RegistrationType[]>([]);

    const previousCategories = usePrevious(categories);
    const [accountEntriesFilters, setAccountEntriesFilters] =
        useState<UseGetLedgerAccountedEntriesFilters>({ includeSections: [] });
    const previousAccountEntriesFilters = usePrevious(accountEntriesFilters);

    const [selectedFromDate, setSelectedFromDate] = useState<Date | null>(null);
    const previousSelectedFromDate = usePrevious(selectedFromDate);

    const [selectedToDate, setSelectedToDate] = useState<Date | null>(null);
    const previousSelectedToDate = usePrevious(selectedToDate);

    const [includeSections, setIncludeSection] = useState<string[]>([]);
    const previousIncludeSection = usePrevious(includeSections);
    const [selectedDocNumber, setSelectedDocNumber] = useState<string | null>(
        null
    );
    const previousSelectedDocNumber = usePrevious(selectedDocNumber);
    const { isSimplifiedAccounting } = useBusinesses();

    const currentYear = getYear(new Date());
    const [selectedYear, setSelectedYear] = useState<number>(currentYear);
    const previousSelectedYear = usePrevious(selectedYear);

    const [deletedDraftIds, setDeletedDraftIds] = useState<string[]>([]);

    const [shouldRefetchCount, setShouldRefetchCount] =
        useState<boolean>(false);

    const [selectedTab, setSelectedTab] = useState<DashboardTab>(
        DashboardTab.TO_BE_ACCOUNTED
    );

    const {
        fetch: fetchDashboardPaginated,
        fetchDraft,
        fetchMore,
        loadingMore,
        hasMore,
        dashboard,
        loading: loadingDashboard,
        error: errorDashboard,
        hasFetched: hasFetchedDashboard
    } = useGetDashboardPaginated({
        categories: categories,
        confirmed: false,
        fromDate: selectedFromDate,
        toDate: selectedToDate,
        documentNumber: selectedDocNumber === "" ? null : selectedDocNumber,
        filters: includeSections,
        year: selectedYear,
        lazy: true
    });

    const {
        fetch: fetchAccountedEntries,
        fetchMore: fetchMoreAccountedEntries,
        loadingMore: loadingMoreAccountedEntries,
        hasMore: hasMoreAccountedEntries,
        accountedForData,
        loading: loadingAccountedEntries,
        error: errorAccountedEntries,
        hasFetched: hasFetchedaccountedForData
    } = useGetLedgerAccountedEntries({
        filters: {
            ...accountEntriesFilters
        },
        lazy: true
    });

    const { workspace: customer } = useCurrentWorkspace(true);
    const previousCustomer = usePrevious(customer);

    const getDashboardPaginated = useCallback(() => {
        setShouldRefetchCount(true);
        fetchDashboardPaginated();
    }, [fetchDashboardPaginated]);

    const getMore = () => {
        fetchMore();
    };

    useEffect(() => {
        if (previousCustomer !== customer) {
            setHasInitialized(false);
        }
    }, [previousCustomer, customer]);

    useEffect(() => {
        if (customer && !hasInitialized) {
            getDashboardPaginated();
            fetchAccountedEntries();
            setHasInitialized(true);
        }
    }, [
        getDashboardPaginated,
        fetchAccountedEntries,
        hasInitialized,
        customer
    ]);

    useEffect(() => {
        if (
            hasInitialized &&
            (categories !== previousCategories ||
                previousSelectedFromDate !== selectedFromDate ||
                previousSelectedToDate !== selectedToDate ||
                previousSelectedDocNumber !== selectedDocNumber ||
                previousSelectedYear !== selectedYear)
        ) {
            getDashboardPaginated();
        }
    }, [
        getDashboardPaginated,
        hasInitialized,
        categories,
        previousCategories,
        previousSelectedFromDate,
        selectedFromDate,
        previousSelectedToDate,
        selectedToDate,
        previousSelectedDocNumber,
        selectedDocNumber,
        previousSelectedYear,
        selectedYear
    ]);

    useEffect(() => {
        if (
            hasInitialized &&
            accountEntriesFilters !== previousAccountEntriesFilters
        ) {
            fetchAccountedEntries();
        }
    }, [
        accountEntriesFilters,
        previousAccountEntriesFilters,
        fetchAccountedEntries,
        hasInitialized
    ]);

    const handleSelectTab = (targetTab: DashboardTab) => {
        setSelectedTab(targetTab);
        if (targetTab === DashboardTab.TO_BE_ACCOUNTED) {
            getDashboardPaginated();
        } else {
            fetchAccountedEntries();
            setShouldRefetchCount(true);
        }
    };

    const handleChangeCategories = (changedCategory: RegistrationType) => {
        setCategories(
            categories.includes(changedCategory)
                ? [
                      ...categories.filter(
                          (category) => category !== changedCategory
                      )
                  ]
                : [...categories.concat(changedCategory)]
        );
    };

    const handleChangeToDate = debounce((newToDate: Date) => {
        if (isValid(newToDate)) {
            setSelectedToDate(newToDate);
        }
    }, 300);

    const handleChangeFromDate = debounce((newFromDate: Date) => {
        if (isValid(newFromDate)) {
            setSelectedFromDate(newFromDate);
        }
    }, 300);

    const handleChangeDocNumber = (newDocNumber: string) => {
        setSelectedDocNumber(newDocNumber);
    };

    const handleSetAccountEntriesFilters = (
        newFilters: UseGetLedgerAccountedEntriesFilters
    ) => {
        setAccountEntriesFilters({
            ...accountEntriesFilters,
            ...newFilters
        });
    };

    const handleIncludeSectionsChange = (newIncludeSections: string[]) => {
        handleSetAccountEntriesFilters({
            includeSections: newIncludeSections
        });
    };

    const handleIncludeRangeDateChange = (range: DateRangePickerState) => {
        handleSetAccountEntriesFilters({
            fromDate: range.startDate,
            toDate: range.endDate
        });
    };

    const handleDeleteDraft = (draftId: string) => {
        setShouldRefetchCount(true);
        setDeletedDraftIds((prevDeletedDraftIds) =>
            prevDeletedDraftIds.concat(draftId)
        );
    };

    const handleConfirmDraft = async (draftId: string | string[]) => {
        if (isArray(draftId)) {
            await Promise.all(draftId.map((id) => fetchDraft(id)));
        } else {
            await fetchDraft(draftId);
        }
    };

    const handleFetchCount = () => {
        setShouldRefetchCount(false);
    };

    const handleCloseDocumentDetail =
        (closeReason: CloseReason) => (draftId: string) => {
            switch (closeReason) {
                case CloseReason.CLOSE:
                    fetchDraft(draftId);
                    break;
                case CloseReason.CONFIRM:
                    handleConfirmDraft(draftId);
                    break;
                case CloseReason.DELETE:
                    handleDeleteDraft(draftId);
                    break;
                default:
                    console.warn(
                        `doc detail close reason: ${closeReason} not recognized`
                    );
            }
            setShouldRefetchCount(true);
        };

    useEffect(() => {
        if (includeSections && includeSections !== previousIncludeSection) {
            getDashboardPaginated();
        }
    }, [includeSections, previousIncludeSection, getDashboardPaginated]);

    const handleSelectYearChange = (newSelectedYear: number) => {
        const newSelectedYearDate = setYear(new Date(), newSelectedYear);
        setSelectedFromDate(startOfYear(newSelectedYearDate));
        setSelectedToDate(endOfYear(newSelectedYearDate));
        setSelectedYear(newSelectedYear);
    };

    return (
        <DashboardPage title={fs("title")} subtitle={fs("subtitle")}>
            <WidgetsContainer
                onFetchCount={handleFetchCount}
                shouldRefetchCount={shouldRefetchCount}
            />
            <Spinner loading={!hasInitialized}>
                <Box padding="24px">
                    <Box marginTop="16px">
                        <ExtendedTabs
                            onChange={(_, value) =>
                                handleSelectTab(value as DashboardTab)
                            }
                            size="medium"
                            variant="standard"
                            value={selectedTab}
                        >
                            <ExtendedTab
                                label={fs("tabs.toBeAccounted")}
                                value={DashboardTab.TO_BE_ACCOUNTED}
                            />
                            <ExtendedTab
                                label={fs("tabs.accounted")}
                                value={DashboardTab.ACCOUNTED}
                            />
                        </ExtendedTabs>
                        <div
                            style={{
                                position: "absolute",
                                right: "32px",
                                transform: "translate(0, -150%)"
                            }}
                        >
                            {isSimplifiedAccounting && (
                                <Tooltip arrow title={fs("settleAccount")}>
                                    <IconButton
                                        color="primary"
                                        onClick={() =>
                                            navigate(routes.settleAccount())
                                        }
                                    >
                                        <FontAwesomeIcon icon={faWallet} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip arrow title={fs("assetsList")}>
                                <IconButton
                                    color="primary"
                                    onClick={() =>
                                        navigate(routes.assetsList())
                                    }
                                >
                                    <FontAwesomeIcon icon={faCarBuilding} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow title={fs("withholdings")}>
                                <IconButton
                                    color="primary"
                                    onClick={() =>
                                        navigate(routes.withholdings())
                                    }
                                >
                                    <FontAwesomeIcon icon={faBadgePercent} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow title={fs("usedGoods")}>
                                <IconButton
                                    color="primary"
                                    onClick={() => navigate(routes.usedGoods())}
                                >
                                    <FontAwesomeIcon
                                        icon={faPrescriptionBottleAlt}
                                    />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Box>
                    {selectedTab === DashboardTab.TO_BE_ACCOUNTED ? (
                        <Box marginTop="24px">
                            <FilterToBeAccounted
                                activeCategories={categories}
                                onChangeIncludeSections={(data: string[]) => {
                                    setIncludeSection(data);
                                }}
                                loadingDashboard={loadingDashboard}
                                includeSections={includeSections}
                                dateFrom={selectedFromDate}
                                dateTo={selectedToDate}
                                year={selectedYear}
                                onChangeDateFrom={handleChangeFromDate}
                                onChangeDateTo={handleChangeToDate}
                                onChangeDocNumber={handleChangeDocNumber}
                                onChangeYear={handleSelectYearChange}
                                onChangeCategory={handleChangeCategories}
                            />
                        </Box>
                    ) : null}
                    {selectedTab === DashboardTab.ACCOUNTED ? (
                        <Box marginTop="24px">
                            <FilterAccountedFor
                                includeSections={
                                    accountEntriesFilters.includeSections
                                }
                                onChangeIncludeSections={
                                    handleIncludeSectionsChange
                                }
                                onChangeIncludeDate={
                                    handleIncludeRangeDateChange
                                }
                            />
                        </Box>
                    ) : null}
                    <Box marginTop="24px">
                        {selectedTab === DashboardTab.TO_BE_ACCOUNTED ? (
                            <Spinner loading={loadingDashboard}>
                                {hasFetchedDashboard &&
                                isEmpty(dashboard?._embedded?.drafts) ? (
                                    <NotFoundBanner text={fs("notFound")} />
                                ) : dashboard?._embedded.drafts ? (
                                    <ToBeAccountedTable
                                        fetchMore={getMore}
                                        isLoadingMore={loadingMore}
                                        hasMore={hasMore}
                                        drafts={dashboard?._embedded?.drafts.filter(
                                            (draft) =>
                                                !deletedDraftIds.includes(
                                                    draft.id
                                                )
                                        )}
                                        fetchSingleDraft={fetchDraft}
                                        onDeleteDraft={handleDeleteDraft}
                                        onConfirmDraft={handleConfirmDraft}
                                        onConfirmDraftFromPreview={
                                            handleConfirmDraft
                                        }
                                        onCloseDocumentDetail={
                                            handleCloseDocumentDetail
                                        }
                                    />
                                ) : errorDashboard ? (
                                    <NotFoundBanner
                                        text={fs("errorLoadingDashboard")}
                                    />
                                ) : null}
                            </Spinner>
                        ) : selectedTab === DashboardTab.ACCOUNTED ? (
                            <Spinner loading={loadingAccountedEntries}>
                                {hasFetchedaccountedForData &&
                                isEmpty(accountedForData?.entries) ? (
                                    <NotFoundBanner text={fs("notFound")} />
                                ) : accountedForData?.entries ? (
                                    <AccountedForTable
                                        fetchMore={fetchMoreAccountedEntries}
                                        isLoadingMore={
                                            loadingMoreAccountedEntries
                                        }
                                        hasMore={hasMoreAccountedEntries}
                                        entriesData={accountedForData?.entries}
                                    />
                                ) : errorAccountedEntries ? (
                                    <NotFoundBanner
                                        text={fs("errorLoadingDashboard")}
                                    />
                                ) : null}
                            </Spinner>
                        ) : null}
                    </Box>
                </Box>
            </Spinner>
        </DashboardPage>
    );
}
