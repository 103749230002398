import { isNil, some } from "lodash";

import {
    CreditDebitDto,
    GroupedCreditDebitDto
} from "../core/usecases/dtos/BalanceDto";

export function hasSimplifiedAccountingProps(
    balanceGroup:
        | GroupedCreditDebitDto["credit"]
        | GroupedCreditDebitDto["debit"]
) {
    return some(balanceGroup.accounts, (accountsList) =>
        some(
            accountsList.accounts,
            (account) => !isNil(account.suspense) && account.suspense !== 0
        )
    );
}

export function balanceHasSimplifiedAccountingProps(
    balanceGroup: CreditDebitDto["credit"] | CreditDebitDto["debit"]
) {
    return some(
        balanceGroup.accounts,
        (account) =>
            (!isNil(account.amount) && account.amount !== 0) ||
            (!isNil(account.suspense) && account.suspense !== 0)
    );
}
