import Typography from "@vapor/react-extended/ExtendedTypography";
import { Divider, Stack, useTheme } from "@vapor/react-material";
import getYear from "date-fns/getYear";
import { groupBy } from "lodash";
import { AccountingPeriodDto } from "../../../../core/usecases/dtos/AccountingPeriodsDto";
import getFormattedDate from "../../../../utils/getFormattedDate";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../components/FormattedAmount";
import { DepreciationData } from "./DepreciationOverlay";
interface DepreciationProps {
    accountingPeriod: AccountingPeriodDto | undefined;
    visibleDepreciations: DepreciationData[];
    refCurrency: string;
}
const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.DepreciationOverlay"
);
export default function DepreciationHeader({
    accountingPeriod,
    visibleDepreciations,
    refCurrency
}: DepreciationProps) {
    const theme = useTheme();
    // labels
    const depreciationDateText = fs("depreciationDate");

    const totalDepreciationValueText = fs("totalDepreciationValue");
    const yearText = fs("year");
    return (
        <>
            <Stack>
                <Typography variant="titleMedium">
                    {`${yearText} ${
                        accountingPeriod?.startDate
                            ? getYear(new Date(accountingPeriod?.startDate))
                            : null
                    }
        `}
                </Typography>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                padding={2}
                sx={{
                    background: theme.palette.primary.background
                }}
            >
                {/* data ammortamento */}
                <Stack alignItems="center" direction="row" spacing={12}>
                    <Stack>
                        <Typography variant="body">
                            {depreciationDateText}
                        </Typography>
                        <Typography variant="bodyLarge500">
                            {accountingPeriod?.endDate
                                ? getFormattedDate(accountingPeriod?.endDate)
                                : null}
                        </Typography>
                    </Stack>
                    <Divider
                        flexItem
                        orientation="vertical"
                        sx={{ opacity: 0.6 }}
                    />
                </Stack>

                <Stack alignItems="center" direction="row" spacing={3}>
                    {/* Grouped depreciations by business */}
                    {visibleDepreciations?.length > 0 && (
                        <Stack direction="row" spacing={12}>
                            {(() => {
                                const groupedVisibleDepreciations = groupBy(
                                    visibleDepreciations,
                                    ({ businessDescription }) =>
                                        businessDescription
                                );

                                return Object.keys(
                                    groupedVisibleDepreciations
                                ).map((item) => {
                                    return (
                                        <Stack alignItems="end" key={item}>
                                            <Typography variant="body">
                                                {item}
                                            </Typography>
                                            <FormattedAmount
                                                amount={groupedVisibleDepreciations[
                                                    item
                                                ]!.reduce(
                                                    (total, depreciation) =>
                                                        total +
                                                        depreciation.depreciationTotalValue,
                                                    0
                                                )}
                                                currency={refCurrency}
                                                variant="bodyLarge500"
                                            />
                                        </Stack>
                                    );
                                });
                            })()}
                        </Stack>
                    )}

                    <Divider
                        flexItem
                        orientation="vertical"
                        sx={{ opacity: 0.6 }}
                    />
                    {/* Total depreciation value */}
                    <Stack alignItems="end">
                        <Typography
                            color="primary.textTitleColor"
                            variant="subtitle"
                        >
                            {totalDepreciationValueText}
                        </Typography>
                        <FormattedAmount
                            amount={visibleDepreciations.reduce(
                                (total, depreciation) =>
                                    total + depreciation.depreciationTotalValue,
                                0
                            )}
                            color="primary"
                            currency={refCurrency}
                            fontSize={24}
                            variant="titleMedium"
                        />
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
}
