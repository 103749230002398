import { AccountingLine } from "../core/usecases/dtos/AccountingLinesDto";
import { Reason, VatRate } from "./appEnums";
import { isVat } from "./reasonUtils";

export const getNewAccountingLine = (
    accountCode: string,
    reason: Reason
): AccountingLine => {
    return {
        accountCode: accountCode,
        // refer to DOP-1810 for the vatRate pre-setting for VAT reasons
        vatRate: isVat(reason) ? VatRate["22.0"] : undefined,
        articleDesc: "Imponibile"
    };
};
