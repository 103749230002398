import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import { POLLING_INTERVAL_MS } from "../../../config";
import { TaskStatus } from "../../usecases/dtos/TaskStatus";
import useGetAccountingPeriodTasks from "../../usecases/useGetAccountingPeriodTasks";

export const accountingPeriodQueryKeys = createQueryKeys("accounting-periods", {
    all: null,
    getTasks: (accountingPeriodId: string) => [accountingPeriodId]
});

export const useAccountingPeriods = (
    accountingPeriodId: string,
    pendingOperation?: boolean
) => {
    const { fetch } = useGetAccountingPeriodTasks();
    return useQuery({
        ...accountingPeriodQueryKeys.getTasks(accountingPeriodId),
        refetchInterval: ({ state }) => {
            const { data } = state;

            const pendingTaskStatuses: TaskStatus[] = [
                TaskStatus.REOPENING,
                TaskStatus.SIMULATING,
                TaskStatus.GENERATING,
                TaskStatus.CANCELING
            ];
            const shouldPoll = data?.tasks.some(({ state }) =>
                pendingTaskStatuses.includes(state)
            );

            return shouldPoll && POLLING_INTERVAL_MS;
        },
        queryFn: () => fetch(accountingPeriodId)
    });
};
