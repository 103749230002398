import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { CommencementDateDto } from "../dtos/CommencementDateDto";

interface UseGetCommencementDate {
    loading: boolean;
    error: any;
    commencementDate: CommencementDateDto | null;
    get: () => Promise<CommencementDateDto>;
}

export const useGetCommencementDate = (): UseGetCommencementDate => {
    const customerData = useCustomerData();

    const { fetch, data, loading, error } = useGet(`ignored`, {
        ...useAxiosConfigRequestParams("GetCommencementDate"),
        lazy: true
    });

    const get = async () => {
        return fetch({
            url: `${API_URL}/v1/ledger/commencement-date`,
            params: {
                workspaceId: customerData.workspaceId
            }
        }).then(({ data }) => data);
    };

    return {
        get,
        loading: loading,
        error: error,
        commencementDate: data
    };
};
