import { FormControl, InputLabel, Tooltip } from "@vapor/react-material";
import { debounce, isEmpty, isNil } from "lodash";
import { useState } from "react";
import { Controller } from "react-hook-form";

import {
    useAccounts,
    UseAccountsOptions
} from "../../../core/domain/Accounts/queries";
import { AccountDto } from "../../../core/usecases/dtos/AccountsDto";
import SelectAccount from "../SelectAccount";
import { FormField } from "./types";

interface FormSelectAccountProps
    extends FormField,
        Omit<UseAccountsOptions, "searchString"> {
    validate?: (value: AccountDto) => boolean | string;
    disablePortal?: boolean;
}

export default function FormSelectAccount({
    name,
    required,
    label,
    validate,
    disabled,
    disablePortal,
    ...options
}: FormSelectAccountProps) {
    const [searchString, setSearchString] = useState<string>("");
    const { data: accounts, isLoading } = useAccounts({
        ...options,
        searchString
    });

    const handleChangeSearchString = debounce(setSearchString, 300);

    const getInvalidMessage = (value: AccountDto) => {
        const invalidMessage = validate && validate(value);

        return typeof invalidMessage === "boolean" ||
            isEmpty(invalidMessage?.toString())
            ? undefined
            : invalidMessage;
    };

    return (
        <Controller
            shouldUnregister={true}
            name={name}
            rules={{ required: required, validate: validate }}
            render={({ field, fieldState }) => (
                <FormControl
                    error={!isNil(fieldState.error) || fieldState.invalid}
                    fullWidth
                    disabled={disabled}
                    sx={{
                        "& .MuiAutocomplete-root": {
                            border: !isNil(fieldState.error)
                                ? "1px solid #D82829 !important"
                                : null
                        }
                    }}
                >
                    {label ? (
                        <InputLabel required={required} disabled={disabled}>
                            {label}
                        </InputLabel>
                    ) : null}
                    <Tooltip
                        arrow={true}
                        open={fieldState.invalid && !disabled}
                        placement="top"
                        title={getInvalidMessage(field.value)}
                        componentsProps={{
                            popper: {
                                sx: {
                                    zIndex: "5000"
                                }
                            }
                        }}
                    >
                        <div>
                            <SelectAccount
                                accounts={accounts ?? []}
                                isLoading={isLoading}
                                onSearchStringChange={handleChangeSearchString}
                                hideAccountCode={false}
                                disablePortal={disablePortal}
                                {...field}
                                disabled={disabled}
                            />
                        </div>
                    </Tooltip>
                </FormControl>
            )}
        />
    );
}
