import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Button from "@vapor/react-material/Button";
import { useNavigate } from "react-router-dom";

import "./style.css";

interface BackArrowProps {
    onClick?: () => void;
    text?: string;
}

export default function BackArrow({ onClick, text }: BackArrowProps) {
    const navigate = useNavigate();

    return (
        <Button
            startIcon={
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="c-BackArrow-icon"
                />
            }
            onClick={() => (onClick ? onClick() : navigate(-1))}
        >
            {text ? <Typography>{text}</Typography> : null}
        </Button>
    );
}
