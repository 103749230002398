import { useGet } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { ReportSimulateAccountingPeriodsDto } from "../dtos/ReportAccountingPeriodsDto";

interface GetSimulatePeriod {
    workspaceId: string;
    accountingPeriodId: string;
}

export const useCloseAccountingPeriodSimulationReport = () => {
    const customerData = useCustomerData();
    const fs = useFormattedStringWithScope("notifications.SimulationReport");

    const { enqueueSnackbar } = useSnackbar();
    const { fetch, data, loading, error } =
        useGet<ReportSimulateAccountingPeriodsDto>(`ignored`, {
            ...useAxiosConfigRequestParams("SimulateReport"),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
            lazy: true
        });

    const getSimulatePeriod = ({
        workspaceId,
        accountingPeriodId
    }: GetSimulatePeriod) => {
        return fetch({
            url: `${ASYNC_API_URL}/accounting-periods/simulation-report`,
            params: {
                ...customerData,
                workspaceId,
                accountingPeriodId
            }
        }).then(({ data }) => data);
    };

    return {
        getSimulatePeriod,
        loading,
        error,
        simulateReport: data
    };
};
