import { VaporToolbar } from "@vapor/react-custom";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Stack
} from "@vapor/react-material";
import { format, parse } from "date-fns";
import { isEmpty, isEqual, isNil, some, sumBy } from "lodash";
import { useEffect, useState } from "react";
import {
    FormProvider,
    SubmitHandler,
    useFieldArray,
    useForm
} from "react-hook-form";
import { useIntl } from "react-intl";

import { API_DATE_FORMAT } from "../../../../config";
import useCurrencySymbol from "../../../../core/commons/hooks/useCurrencySymbol";
import { usePaymentsTotalAmount } from "../../../../core/domain/Draft/Payments/hooks";
import {
    useCreateContextualPayment,
    usePayments,
    useTotalToPay
} from "../../../../core/domain/Draft/Payments/queries";
import { useDraft } from "../../../../core/domain/Draft/queries";
import { AccountDto } from "../../../../core/usecases/dtos/AccountsDto";
import { CreateContextualPaymentsData } from "../../../../core/usecases/dtos/CreatePaymentsDto";
import { DraftDetail } from "../../../../core/usecases/dtos/DraftDetail";
import { Payment } from "../../../../core/usecases/dtos/PaymentsDto";
import { PaymentType } from "../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../CustomButton";
import FormattedAmount from "../../FormattedAmount";
import PaymentRow from "./PaymentRow";

interface FormPayment {
    account?: AccountDto | null;
    amount?: number | null;
    date?: Date | null;
}

export interface FormValues {
    payments: Partial<FormPayment>[];
}

function createEmptyPayment(date: Date | null, amount?: number): FormPayment {
    return {
        // Suggest account for manual payments as per (DOP-2037)
        account: {
            code: "24/15/005",
            title: "DENARO IN CASSA",
            description: "DENARO IN CASSA"
        },
        date: date,
        amount: amount
    };
}

function convertPayments(payments: Payment[]): FormValues["payments"] {
    return payments.map((payment) => ({
        account: {
            code: payment.accountCode,
            title: payment.accountDesc,
            description: payment.accountDesc
        },
        amount: payment.amount,
        date: new Date(payment.date)
    }));
}

const fs = getFormattedStringWithScope("components.Accounting.PaymentsDrawer");

type ContextualPaymentsFormProps = {
    allContextualPayments: Payment[];
    docDate: DraftDetail["doc"]["date"];
    totalToPay: number;
    paymentsTotalAmount: number;
    refCurrency: string;
    isLoading: boolean;
    onCreate: (params: {
        data: CreateContextualPaymentsData[];
        writeOffResidual?: boolean;
        residual?: string;
    }) => void;
};
const ContextualPaymentsForm = (props: ContextualPaymentsFormProps) => {
    const {
        allContextualPayments,
        docDate: propsDocDate,
        refCurrency,
        onCreate,
        paymentsTotalAmount,
        totalToPay,
        isLoading: isCreatingPayments
    } = props;

    const docDate = propsDocDate
        ? parse(propsDocDate, API_DATE_FORMAT, new Date())
        : null;

    const payments = allContextualPayments.filter(
        (payment) => !payment.isResidual && !payment.lineDisabled
    );

    const paymentsHaveWriteOff = some(
        allContextualPayments,
        (payment) => payment.isResidual
    );

    const convertedPayments = convertPayments(payments);

    const [writeOff, setWriteOff] = useState<boolean>(
        some(allContextualPayments, (payment) => payment.isResidual)
    );

    const [residualAmount, setResidualAmount] = useState<number>(0);

    const methods = useForm<FormValues>({
        defaultValues: {
            payments: isEmpty(convertedPayments)
                ? [
                      createEmptyPayment(
                          docDate,
                          totalToPay - paymentsTotalAmount
                      )
                  ]
                : convertedPayments
        }
    });

    //TODO find the best solution when we will have time
    useEffect(() => {
        methods.setValue(
            "payments",
            isEmpty(convertedPayments)
                ? [
                      createEmptyPayment(
                          docDate,
                          totalToPay - paymentsTotalAmount
                      )
                  ]
                : convertedPayments
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalToPay]);

    const currencySymbol = useCurrencySymbol();

    const values = methods.watch();

    const noModificationInPayments =
        isEqual(convertPayments(payments), methods.watch().payments) &&
        paymentsHaveWriteOff === writeOff;

    const contextualPaymentsAmount =
        sumBy(
            values.payments.filter((payment) => !isNil(payment.amount)),
            (payment) => payment.amount!
        ) - paymentsTotalAmount;

    useEffect(() => {
        setResidualAmount(
            totalToPay
                ? totalToPay - paymentsTotalAmount - contextualPaymentsAmount
                : 0
        );
    }, [contextualPaymentsAmount, paymentsTotalAmount, totalToPay]);

    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: "payments",
        shouldUnregister: true,
        rules: {
            validate: () => {
                return residualAmount >= 0;
            }
        }
    });

    const { formatNumber } = useIntl();

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        onCreate({
            data: data.payments.map((payment) => ({
                account: payment.account?.code,
                amount: payment.amount ?? undefined,
                date: payment.date
                    ? format(payment.date, API_DATE_FORMAT)
                    : undefined,
                type: PaymentType.PAYMENT
            })),
            writeOffResidual: writeOff,
            residual: writeOff
                ? `${formatNumber(residualAmount)} ${currencySymbol(
                      refCurrency
                  )}`
                : undefined
        });
    };

    const handleDeletePayment = (deletedIndex: number) => {
        remove(deletedIndex);
    };

    return (
        <>
            <Typography variant="titleSmall" color="#005075">
                {fs("payments")}
            </Typography>

            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    {fields.map((field, index) => (
                        <PaymentRow
                            key={field.id}
                            refCurrency={refCurrency}
                            index={index}
                            showDeleteButton={index !== 0}
                            onDelete={handleDeletePayment}
                        />
                    ))}
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        marginTop="32px"
                    >
                        <Button
                            onClick={() =>
                                append(
                                    createEmptyPayment(docDate, residualAmount)
                                )
                            }
                            startIcon={
                                <VaporIcon
                                    color="primary"
                                    iconName="plus-circle"
                                    iconWeight="regular"
                                />
                            }
                        >
                            {fs("addPayment")}
                        </Button>
                        <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="bodySmall500">
                                {fs("residual")}
                            </Typography>
                            <FormattedAmount
                                variant="bodySmall"
                                amount={residualAmount}
                                currency={refCurrency}
                            />
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Abbuona"
                                checked={writeOff}
                                onChange={() => setWriteOff(!writeOff)}
                                disabled={residualAmount === 0}
                            />
                        </Stack>
                    </Stack>
                    <Typography variant="body" color="red">
                        {!isNil(methods.formState.errors.payments?.root) &&
                            fs("paymentSumCannotExceedDocumentAmount")}
                    </Typography>
                    <Box marginLeft="-16px">
                        <VaporToolbar
                            contentRight={[
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => methods.reset()}
                                >
                                    {fs("cancel")}
                                </Button>,
                                <CustomButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={noModificationInPayments}
                                    loading={isCreatingPayments}
                                >
                                    {fs("save")}
                                </CustomButton>
                            ]}
                        />
                    </Box>
                </form>
            </FormProvider>
        </>
    );
};

type ContextualPaymentsProps = {
    draftId: string;
    onCreate: () => void;
};
const ContextualPayments = (props: ContextualPaymentsProps) => {
    const {
        data: totalToPay,
        isLoading: isLoadingTotalToPay,
        isSuccess: isSuccessTotalToPay
    } = useTotalToPay(props.draftId);

    const {
        data,
        isLoading: isLoadingPayments,
        isFetching: isFetchingPayments,
        isSuccess: isSuccessPayments
    } = usePayments({
        draftId: props.draftId,
        paymentType: "MANUAL"
    });
    const {
        data: draft,
        isLoading: isLoadingDraft,
        isSuccess: isSuccessDraft
    } = useDraft(props.draftId);
    const { mutateAsync: createContextual, isPending: isCreatingPayments } =
        useCreateContextualPayment({
            draftId: props.draftId,
            showNotificationOnSuccess: true
        });
    const paymentsTotalAmount = usePaymentsTotalAmount(props.draftId);

    if (
        isFetchingPayments ||
        isLoadingDraft ||
        isLoadingPayments ||
        isLoadingTotalToPay
    ) {
        return <CircularProgress />;
    }

    if (!isSuccessDraft || !isSuccessPayments || !isSuccessTotalToPay) {
        return null;
    }

    return (
        <ContextualPaymentsForm
            allContextualPayments={data ?? []}
            docDate={draft?.doc.date}
            refCurrency={draft?.refCurrency ?? "EUR"}
            totalToPay={totalToPay}
            paymentsTotalAmount={paymentsTotalAmount}
            isLoading={isCreatingPayments}
            onCreate={async (params) => {
                await createContextual(params);
                props.onCreate();
            }}
        />
    );
};

export default ContextualPayments;
