import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { ExemptionNature } from "../dtos/ExemptionNaturesDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseGetExemptionNatures {
    loading: boolean;
    error: any;
    exemptionNatures: ExemptionNature[] | null;
    fetch: () => Promise<ExemptionNature[]>;
    hasFetched: boolean;
}

interface UseGetExemptionNaturesOptions {
    nature?: string;
    lazy?: boolean;
}

export const useGetExemptionNatures = ({
    nature,
    lazy
}: UseGetExemptionNaturesOptions): UseGetExemptionNatures => {
    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/vat/exemption-natures`,
        {
            ...useAxiosConfigRequestParams("PrecogDetail"),

            params: omitNilProperties({
                ...useCustomerData(),
                nature
            }),
            lazy: lazy
        }
    );

    return {
        fetch: () =>
            fetch().then(
                (response: AxiosResponse<ExemptionNature[]>) => response.data
            ),
        loading: loading,
        error: error,
        exemptionNatures: data,
        hasFetched: hasLoaded
    };
};
