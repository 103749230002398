import { styled } from "@mui/system";
import { Box, Select } from "@vapor/react-material";

import DateRangePicker from "../../../components/DateRangePicker";
import DebouncedControlledInput from "../../../components/DebouncedControlledInput";

export const ArticleDescription = styled(DebouncedControlledInput)({
    "&:before": { border: "none" },
    fontSize: "14px"
});

export const TransparentSelect = styled(Select)({
    background: "transparent",
    "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
        {
            background: "transparent !important"
        },
    "& fieldset": {
        border: "none"
    }
});

export const TransparentDateRangePicker = styled(DateRangePicker)({
    width: "100%",
    "& .MuiStack-root": {
        width: "100%"
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd":
        {
            background: "transparent !important"
        },
    "& fieldset": {
        border: "none"
    }
});

export const BanksLookupContainer = styled(Box)({
    "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium": {
        top: 0
    },
    "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4": {
        top: 40
    }
});
