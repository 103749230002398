import { useCurrentWorkspace } from "@drift/oneplatfront";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { DocPreviewer } from "@onefront/documents-smart-components";
import { Eye, VaporIcon } from "@vapor/react-icons";
import {
    CircularProgress,
    Dialog,
    Divider,
    IconButton,
    Stack
} from "@vapor/react-material";
import { useState } from "react";
import { useDraft } from "../../../core/domain/Draft/queries";
import { DraftSource, FactType } from "../../../utils/appEnums";

type DocumentPreviewButtonProps = {
    docId: string;
    isInvoice: boolean;
};
export const DocumentPreviewButton = ({
    docId,
    isInvoice
}: DocumentPreviewButtonProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const workspaceId = useCurrentWorkspace(true).workspace?.id ?? "";

    return (
        <>
            <IconButton color="primary" onClick={() => setOpen(true)}>
                <Eye />
            </IconButton>
            <Dialog fullScreen onClose={() => setOpen(false)} open={open}>
                <Stack alignItems="end" p={2}>
                    <IconButton color="primary" onClick={() => setOpen(false)}>
                        <VaporIcon color="primary" icon={faClose} />
                    </IconButton>
                </Stack>

                <Divider variant="fullWidth" />
                <Stack p={2} height="100%">
                    <DocPreviewer
                        id={docId}
                        isInvoice={isInvoice}
                        height="100%"
                        workspaceId={workspaceId}
                    />
                </Stack>
            </Dialog>
        </>
    );
};

type DocumentPreviewerProps = {
    draftId: string;
};
export const DocumentPreviewer = ({ draftId }: DocumentPreviewerProps) => {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (isSuccess) {
        if ([DraftSource.B2B, DraftSource.DOC].includes(draft.source)) {
            return null;
        }

        <DocumentPreviewButton
            docId={draft.doc.id}
            isInvoice={draft.factType === FactType.INVOICE}
        />;
    }

    return null;
};
