import {
    ColumnElement,
    ColumnType,
    HeadCell,
    Table
} from "@comic/precog-components";
import debounce from "lodash/debounce";
import IconButton from "@vapor/react-material/IconButton";
import FormControl from "@vapor/react-material/FormControl";
import TextField from "@vapor/react-material/TextField";
import { useState, useEffect } from "react";
import MenuItem from "@vapor/react-material/MenuItem";
import Box from "@vapor/react-material/Box";
import Select from "@vapor/react-material/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMagnifyingGlass,
    faSquareArrowUpRight,
    faFilter
} from "@fortawesome/pro-regular-svg-icons";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { DatePicker } from "@vapor/react-x-date-pickers/DatePicker";

import useEnter from "../../../core/commons/hooks/useEnter";
import { Status } from "../../../core/usecases/useGetAllUsedGoods";
import { UsedGood } from "../../../core/usecases/dtos/UsedGoodsDto";
import FormattedDate from "../../components/FormattedDate";
import FormattedAmount from "../../components/FormattedAmount";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";

interface UsedGoodsListTableProps {
    handleChangeDescription: (newDescription: string) => void;
    handleStartDateChange: (newStartDate: Date) => void;
    handleEndDateChange: (newEndDate: Date) => void;
    handleStatusChange: (newStatus?: Status | null) => void;
    usedGoods: UsedGood[];
}

const fs = getFormattedStringWithScope("views.ListUsedGoods.table");

export default function UsedGoodsListTable({
    handleChangeDescription,
    handleStartDateChange,
    handleEndDateChange,
    handleStatusChange,
    usedGoods
}: UsedGoodsListTableProps) {
    const [enteredDesc, setEnteredDesc] = useState<string>("");
    const [enteredStartDate, setEnteredStartDate] = useState<Date | null>(null);
    const [enteredEndDate, setEnteredEndDate] = useState<Date | null>(null);
    const [selectedStatus, setSelectedStatus] = useState<Status>(Status.ALL);

    const debouncedDescChange = debounce(handleChangeDescription, 300);
    const debouncedStartDateChange = debounce(handleStartDateChange, 300);
    const debouncedEndDateChange = debounce(handleEndDateChange, 300);
    const debouncedStatusChange = debounce(handleStatusChange, 300);

    useEffect(() => {
        if (enteredEndDate) {
            debouncedEndDateChange(enteredEndDate);
        }
    }, [debouncedEndDateChange, enteredEndDate]);

    useEffect(() => {
        if (enteredStartDate) {
            debouncedStartDateChange(enteredStartDate);
        }
    }, [enteredStartDate, debouncedStartDateChange]);

    useEffect(() => {
        debouncedDescChange(enteredDesc);
    }, [enteredDesc, debouncedDescChange]);

    const handleDescStateChange = (newDesc: string) => {
        setEnteredDesc(newDesc);
    };

    const handleDescEnter = useEnter({
        callback: (value) => setEnteredDesc(value ?? "")
    });

    const handleSelectStatusChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const newStatus = event.target.value as Status;

        setSelectedStatus(newStatus);
    };

    const handleSelectStartDate = (date: Date) => {
        setEnteredStartDate(date);
    };

    const handleSelectEndDate = (date: Date) => {
        setEnteredEndDate(date);
    };

    useEffect(() => {
        if (selectedStatus === Status.ALL) {
            debouncedStatusChange(null);
        }

        if (selectedStatus === Status.SOLD) {
            debouncedStatusChange(Status.SOLD);
        }

        if (selectedStatus === Status.IN_PLACE) {
            debouncedStatusChange(Status.IN_PLACE);
        }
    }, [debouncedDescChange, debouncedStatusChange, selectedStatus]);

    const headCells: HeadCell[] = [
        {
            id: "description",
            kind: ColumnType.text,
            label: fs("description"),
            filter: {
                kind: ColumnType.text,
                render: () => {
                    return (
                        <FormControl>
                            <TextField
                                {...handleDescEnter}
                                placeholder={fs("descPlaceholder")}
                                defaultValue={enteredDesc}
                                onBlur={(event) => {
                                    handleDescStateChange(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton color="primary">
                                            <FontAwesomeIcon
                                                icon={faMagnifyingGlass}
                                            />
                                        </IconButton>
                                    )
                                }}
                                sx={{
                                    "& fieldset": {
                                        border: "none",
                                        background: "none"
                                    }
                                }}
                            />
                        </FormControl>
                    );
                }
            }
        },
        {
            id: "identificationCode",
            kind: ColumnType.text,
            label: fs("identificationCode")
        },
        {
            id: "startDate",
            kind: ColumnType.text,
            label: fs("purchaseDate"),
            filter: {
                kind: ColumnType.text,
                render: () => {
                    return (
                        <DatePicker
                            sx={{
                                "& fieldset": {
                                    border: "none",
                                    background: "none"
                                }
                            }}
                            value={enteredStartDate}
                            onChange={(date: any) =>
                                handleSelectStartDate(date)
                            }
                        />
                    );
                }
            }
        },

        {
            id: "endDate",
            kind: ColumnType.text,
            label: fs("saleDate"),
            filter: {
                kind: ColumnType.text,
                render: () => {
                    return (
                        <DatePicker
                            sx={{
                                "& fieldset": {
                                    border: "none",
                                    background: "none"
                                }
                            }}
                            value={enteredEndDate}
                            onChange={(date: any) => handleSelectEndDate(date)}
                        />
                    );
                }
            }
        },
        {
            id: "total",
            kind: ColumnType.text,
            label: fs("totalPurchase")
        },
        {
            id: "additionalExpenses",
            kind: ColumnType.text,
            label: fs("incrementalSpending")
        },
        {
            id: "marginAmount",
            kind: ColumnType.text,
            label: fs("marginAmount")
        },
        {
            id: "status",
            kind: ColumnType.text,
            label: fs("status"),
            filter: {
                kind: ColumnType.text,
                render: () => {
                    return (
                        <Box>
                            <Select
                                sx={{
                                    ".MuiOutlinedInput-notchedOutline": {
                                        border: "none !important"
                                    }
                                }}
                                value={selectedStatus}
                                onChange={(status: any) =>
                                    handleSelectStatusChange(status)
                                }
                            >
                                <MenuItem value={Status.ALL}>
                                    {fs("all")}
                                </MenuItem>
                                <MenuItem value={Status.SOLD}>
                                    {fs("sold")}
                                </MenuItem>
                                <MenuItem value={Status.IN_PLACE}>
                                    {fs("inPlace")}
                                </MenuItem>
                            </Select>
                        </Box>
                    );
                }
            }
        },
        {
            id: "",
            kind: ColumnType.cta,
            label: "",
            filter: {
                kind: ColumnType.text,
                render: () => {
                    return (
                        <Box>
                            <FontAwesomeIcon color="#0090d1" icon={faFilter} />
                        </Box>
                    );
                }
            }
        }
    ];

    const columns: ColumnElement[] = [
        {
            kind: ColumnType.text,
            render: (usedGood: UsedGood) => (
                <Typography display="inline-block" variant="body500">
                    {usedGood.description}
                </Typography>
            )
        },
        {
            kind: ColumnType.text,
            render: (usedGood: UsedGood) => (
                <Typography display="inline-block" variant="body500">
                    {usedGood.identificationCode}
                </Typography>
            )
        },
        {
            kind: ColumnType.text,
            render: (usedGood: UsedGood) => (
                <FormattedDate
                    variant="body500"
                    date={usedGood.purchaseDate.toString()}
                />
            )
        },
        {
            kind: ColumnType.text,
            render: (usedGood: UsedGood) => (
                <FormattedDate
                    variant="body500"
                    date={usedGood.saleDate.toString()}
                />
            )
        },
        {
            kind: ColumnType.text,
            render: (usedGood: UsedGood) => (
                <FormattedAmount
                    variant="body500"
                    amount={usedGood.initialPurchase}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.text,
            render: (usedGood: UsedGood) => (
                <FormattedAmount
                    variant="body500"
                    amount={usedGood.additionalExpenses}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.text,
            render: (usedGood: UsedGood) => (
                <FormattedAmount
                    variant="body500"
                    amount={usedGood.marginAmount}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.text,
            render: (usedGood: UsedGood) => (
                <Typography variant="body500">
                    {fs(`statusSelect.${usedGood.status}`)}
                </Typography>
            )
        },
        {
            kind: ColumnType.text,
            render: () => (
                <Box>
                    <IconButton color="primary" onClick={() => {}}>
                        <FontAwesomeIcon icon={faSquareArrowUpRight} />
                    </IconButton>
                </Box>
            )
        }
    ];

    return <Table headCells={headCells} data={usedGoods} columns={columns} />;
}
