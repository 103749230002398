import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import useCustomerData from "../../commons/hooks/useCustomerData";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import omitNilProperties from "../../../utils/omitNilProperties";
import { AssetTaxRelief } from "../dtos/AssetTaxReliefsDto";

interface UseGetAssetTaxReliefs {
    loading: boolean;
    error: Error;
    data: AssetTaxRelief[];
    fetch: () => void;
    hasLoaded: boolean;
}

interface UseGetAssetTaxReliefsOptions {
    lazy: boolean;
}

export const useGetAssetTaxReliefs = ({
    lazy
}: UseGetAssetTaxReliefsOptions): UseGetAssetTaxReliefs => {
    const { fetch, loading, data, error, hasResponded } = useGet(
        `${API_URL}/v1/asset/tax-reliefs`,
        {
            ...useAxiosConfigRequestParams("SearchTaxRelief"),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: lazy
        }
    );

    return {
        fetch: fetch,
        loading: loading,
        error: error,
        data: data,
        hasLoaded: hasResponded
    };
};
