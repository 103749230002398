import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { ListAssetsResponse } from "../dtos/ListAssetsDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseGetAssetsSearchOptions {
    businessId?: string;
    description?: string;
    purchaseDateFrom?: string;
    purchaseDateTo?: string;
    active?: boolean;
    supplier?: string;
    accountCode?: string;
}

interface UseGetAssetsSearch {
    loading: boolean;
    error: Error;
    assetsList: ListAssetsResponse | null;
    fetch: (params: UseGetAssetsSearchOptions) => Promise<ListAssetsResponse>;
}

export const useGetAssetsSearch = (): UseGetAssetsSearch => {
    const customerData = useCustomerData();

    const {
        fetch: fetchData,
        data,
        loading,
        error
    } = useGet(`${API_URL}/v1/asset`, {
        ...useAxiosConfigRequestParams("SearchAsset"),
        lazy: true
    });

    const fetch = async (params: UseGetAssetsSearchOptions) => {
        const nonNilParams = omitNilProperties({ ...params });
        return fetchData({
            params: {
                ...customerData,
                ...nonNilParams,
                page: 0,
                size: 10
            }
        }).then((response: AxiosResponse<ListAssetsResponse>) => response.data);
    };

    return {
        fetch: fetch,
        loading: loading,
        error: error,
        assetsList: data
    };
};
