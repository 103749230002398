import {
    faChevronDown,
    faChevronRight
} from "@fortawesome/pro-regular-svg-icons";
import { Stack } from "@mui/system";
import { Tag } from "@vapor/react-custom";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import {
    Checkbox,
    IconButton,
    TableCell,
    TableRow
} from "@vapor/react-material";
import { capitalize } from "lodash";
import { useState } from "react";

import { FT_HANDLE_ASSET_DEPRECIATION_SUSPENSION } from "../../../../config";
import FormattedAmount from "../../../components/FormattedAmount";
import PercentageField from "../../../components/PercentageField";
import { DepreciationData } from "./DepreciationOverlay";

interface DepreciationRowProps {
    isSelected: boolean;
    depreciation: DepreciationData;
    onToggleRow: (id: string) => void;
    onChange: (depreciation: DepreciationData) => void;
}

export default function DepreciationRow({
    isSelected,
    depreciation,
    onToggleRow,
    onChange
}: DepreciationRowProps) {
    const [isOpen, setOpen] = useState<boolean>(false);

    const handleDepreciationChange = (depreciation: DepreciationData) => {
        onChange(depreciation);
    };

    return (
        <>
            <TableRow>
                <TableCell size="small" sx={{ paddingLeft: "0" }}>
                    <Stack direction="row" alignItems="center">
                        {FT_HANDLE_ASSET_DEPRECIATION_SUSPENSION ? (
                            <Checkbox
                                size="small"
                                checked={isSelected}
                                onClick={() => onToggleRow(depreciation.key)}
                            />
                        ) : null}
                        <IconButton
                            size="small"
                            onClick={() => setOpen(!isOpen)}
                            sx={{ marginRight: "8px" }}
                        >
                            <VaporIcon
                                icon={isOpen ? faChevronDown : faChevronRight}
                            />
                        </IconButton>
                        <Typography
                            variant="bodySmall500"
                            sx={{ marginRight: "8px" }}
                        >
                            {capitalize(depreciation.depreciationTitle)}
                        </Typography>
                        <Tag
                            variant="ghost"
                            description={depreciation.businessDescription}
                        />
                    </Stack>
                </TableCell>
                {FT_HANDLE_ASSET_DEPRECIATION_SUSPENSION ? (
                    <>
                        <TableCell size="small" align="right">
                            <FormattedAmount
                                variant="bodySmall"
                                amount={depreciation.depreciationTotalValue}
                                currency="EUR"
                            />
                        </TableCell>
                        <TableCell size="small"></TableCell>
                    </>
                ) : null}
                <TableCell size="small" align="right">
                    <FormattedAmount
                        variant="bodySmall"
                        amount={depreciation.effectiveDepreciationValue}
                        currency="EUR"
                    />
                </TableCell>
            </TableRow>
            {isOpen
                ? depreciation.children.map((asset) => (
                      <TableRow
                          key={asset.assetId}
                          sx={{
                              bgcolor: "rgb(222, 240, 247)"
                          }}
                      >
                          <TableCell size="small" sx={{ paddingLeft: "52px" }}>
                              <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                              >
                                  {FT_HANDLE_ASSET_DEPRECIATION_SUSPENSION ? (
                                      <Checkbox size="small" checked={false} />
                                  ) : null}
                                  <Typography variant="bodySmall">
                                      {asset.depreciationTitle}
                                  </Typography>
                              </Stack>
                          </TableCell>
                          {FT_HANDLE_ASSET_DEPRECIATION_SUSPENSION ? (
                              <>
                                  <TableCell size="small" align="right">
                                      <FormattedAmount
                                          variant="bodySmall"
                                          amount={asset.depreciationTotalValue}
                                          currency="EUR"
                                      />
                                  </TableCell>
                                  <TableCell size="small" align="right">
                                      <PercentageField
                                          value={depreciation.percentage}
                                          onChange={(value) =>
                                              handleDepreciationChange({
                                                  ...depreciation,
                                                  percentage: value ?? 0
                                              })
                                          }
                                      />
                                  </TableCell>
                              </>
                          ) : null}
                          <TableCell size="small" align="right">
                              <FormattedAmount
                                  variant="bodySmall"
                                  amount={asset.effectiveDepreciationValue}
                                  currency="EUR"
                              />
                          </TableCell>
                      </TableRow>
                  ))
                : null}
        </>
    );
}
