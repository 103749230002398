import { useGet, useSnackbar } from "@onefront/react-sdk";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { Highlights } from "../dtos/HighlightsDto";

interface UseGetHighlights {
    loading: boolean;
    error: any;
    highlights: Highlights | null;
    fetch: Function;
    hasFetched: boolean;
}

interface UseGetHighlightsOptions {
    lazy?: boolean;
}

export const useGetHighlights = ({
    lazy
}: UseGetHighlightsOptions): UseGetHighlights => {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.GetHighlights");

    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/precog/widget/highlights`,
        {
            ...useAxiosConfigRequestParams("GetHighlights"),
            onError: () =>
                enqueueSnackbar(fs("error"), {
                    variant: "error"
                }),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: lazy
        }
    );

    const safeFetch = useCallback(() => fetch(), [fetch]);

    return {
        fetch: safeFetch,
        loading: loading,
        error: error,
        highlights: data,
        hasFetched: hasLoaded
    };
};
