import { VaporIcon } from "@vapor/react-icons";
import { Button, Menu, MenuItem, Skeleton } from "@vapor/react-material";
import { every, isNil } from "lodash";
import { useState } from "react";

import { useDraft } from "../../../../core/domain/Draft/queries";
import { PaymentsDrawerType, PaymentType } from "../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import { isTheOnlyTypeOfPaymentPresent } from "../../../../utils/paymentUtils";
import {
    FiscalPaymentsDetailDrawer,
    ManualPaymentsDetailDrawer
} from "../Payments";

const fs = getFormattedStringWithScope(
    "components.Accounting.AccountingLinesTable.AddPaymentButton"
);

type AddPaymentButtonProps = {
    draftId: string;
};
export default function AddPaymentButton({ draftId }: AddPaymentButtonProps) {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);

    const [openDrawerType, setOpenDrawerType] = useState<
        "MANUAL" | "CREDIT_NOTE" | "ADVANCE" | null
    >(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
        null
    );
    const isMenuOpen = !isNil(menuAnchorEl);

    if (isLoading)
        return (
            <Skeleton>
                <Button startIcon={<VaporIcon iconName="plus-circle" />} />
            </Skeleton>
        );

    if (isSuccess) {
        const openContextualPayments = () => {
            onAddPayment(PaymentType.PAYMENT, PaymentsDrawerType.CONTEXTUAL);
        };

        const openAdvances = () => {
            onAddPayment(PaymentType.PAYMENT, PaymentsDrawerType.FISCAL);
        };

        const openCreditNotes = () => {
            onAddPayment(PaymentType.CREDIT_NOTE, PaymentsDrawerType.FISCAL);
        };

        const handleOpenMenu = (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => {
            if (isTheOnlyTypeOfPaymentPresent("hasContextualPayment", draft)) {
                openContextualPayments();
            } else if (isTheOnlyTypeOfPaymentPresent("hasAdvances", draft)) {
                openAdvances();
            } else if (isTheOnlyTypeOfPaymentPresent("hasCreditNotes", draft)) {
                openCreditNotes();
            } else {
                setMenuAnchorEl(event.currentTarget);
            }
        };

        const handleClose = () => {
            setMenuAnchorEl(null);
        };

        const onAddPayment = (
            paymentType: PaymentType,
            paymentsDrawerType: PaymentsDrawerType
        ) => {
            if (paymentType === PaymentType.PAYMENT) {
                if (paymentsDrawerType === PaymentsDrawerType.CONTEXTUAL) {
                    setOpenDrawerType("MANUAL");
                } else {
                    setOpenDrawerType("ADVANCE");
                }
            } else if (paymentType === PaymentType.CREDIT_NOTE) {
                setOpenDrawerType("CREDIT_NOTE");
            }
        };

        const noPaymentPossible = every(
            [
                draft.hasContextualPayment,
                draft.hasCreditNotes,
                draft.hasAdvances
            ],
            (type) => !!type === false
        );

        if (noPaymentPossible) {
            return null;
        }

        return (
            <>
                <Button
                    color="primary"
                    startIcon={
                        <VaporIcon
                            color="primary"
                            iconName="plus-circle"
                            iconWeight="regular"
                        />
                    }
                    onClick={handleOpenMenu}
                >
                    {fs("addPayment")}
                </Button>
                <Menu
                    anchorEl={menuAnchorEl}
                    open={isMenuOpen}
                    onClose={handleClose}
                >
                    {draft.hasContextualPayment ? (
                        <MenuItem onClick={openContextualPayments}>
                            {fs("payment")}
                        </MenuItem>
                    ) : null}
                    {draft.hasCreditNotes ? (
                        <MenuItem onClick={openCreditNotes}>
                            {fs("creditNote")}
                        </MenuItem>
                    ) : null}
                    {draft.hasAdvances ? (
                        <MenuItem onClick={openAdvances}>
                            {fs("advance")}
                        </MenuItem>
                    ) : null}
                </Menu>
                {openDrawerType === "MANUAL" && (
                    <ManualPaymentsDetailDrawer
                        draftId={draftId}
                        open
                        onClose={() => setOpenDrawerType(null)}
                    />
                )}
                {(openDrawerType === "ADVANCE" ||
                    openDrawerType === "CREDIT_NOTE") && (
                    <FiscalPaymentsDetailDrawer
                        draftId={draftId}
                        open
                        type={openDrawerType}
                        onClose={() => setOpenDrawerType(null)}
                    />
                )}
            </>
        );
    }

    return null;
}
