import { DraftDetail } from "../core/usecases/dtos/DraftDetail";
import { DraftAccountingStatus, DraftSource } from "./appEnums";

export function isReadOnly(status?: DraftAccountingStatus) {
    if (!status) return true;

    return [
        DraftAccountingStatus.CONFIRMED,
        DraftAccountingStatus.PROCESS_CONFIRM,
        DraftAccountingStatus.PROCESS_DELETE,
        DraftAccountingStatus.PROCESS_UPDATE
    ].includes(status);
}

export function isXML(draft: DraftDetail) {
    return draft.source === DraftSource.B2B;
}
