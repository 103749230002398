import { Spinner } from "@comic/precog-components";
import VaporHeaderBar from "@vapor/react-custom/VaporHeaderBar";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import ExtendedTab from "@vapor/react-extended/ExtendedTab";
import ExtendedTabs from "@vapor/react-extended/ExtendedTabs";
import Times from "@vapor/react-icons/Times";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Drawer from "@vapor/react-material/Drawer";
import IconButton from "@vapor/react-material/IconButton";
import { useState } from "react";

import { AssetDetailDto } from "../../../../../core/usecases/dtos/AssetDetailDto";
import { Elements } from "../../../../../core/usecases/dtos/ListAssetsDto";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import DepreciationTab from "./depreciationTab";
import MovementsTab from "./movementsTab";
import RegistryTab from "./registryTab";
import BackArrow from "../../../../components/BackArrow";

const fs = getFormattedStringWithScope("views.ListAssets");

interface AssetsListDrawerProps {
    open: boolean;
    setOpen: Function;
    selectedAsset: Elements | null;
    assetDetail: AssetDetailDto | null;
    isLoading: boolean;
}

export default function AssetListDrawer({
    open,
    setOpen,
    assetDetail,
    selectedAsset,
    isLoading
}: AssetsListDrawerProps) {
    const [selectedTab, setSelectedTab] = useState<string>("registry");
    const [eliminationPage, setEliminationPage] = useState<number>(1);
    const [selectedSubKindCode, setSelectedSubkindCode] = useState<
        string | null
    >(null);
    const [showTabs, setShowTabs] = useState<boolean>(true);
    const [showRevaluation, setShowRevaluation] = useState<boolean>(false);
    const [showElimination, setShowElimination] = useState<boolean>(false);

    const handleChangeTabs = (_event: React.SyntheticEvent, newTab: string) => {
        setSelectedTab(newTab);
    };

    const title = () => {
        const suffix = showRevaluation
            ? `/ ${fs("revaluation")}`
            : showElimination
            ? `/ ${fs("elimination")}`
            : "";
        return fs("drawerTitle") + suffix;
    };

    const handleChangeEliminationPage = (eliminationPage: number) => {
        setEliminationPage(eliminationPage);
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={() => {
                setOpen(!open);
            }}
            hideBackdrop
            width="50%"
        >
            <VaporHeaderBar
                divider={true}
                rightItems={[
                    <IconButton
                        onClick={() => {
                            setOpen(!open);
                            setEliminationPage(1);
                            setSelectedTab("registry");
                            setShowTabs(true);
                            setShowElimination(false);
                            setShowRevaluation(false);
                        }}
                        color="primary"
                    >
                        <Times />
                    </IconButton>
                ]}
                title={title()}
                size="small"
                leftItems={
                    showElimination && eliminationPage === 2
                        ? [
                              <BackArrow
                                  onClick={() => {
                                      setEliminationPage(1);
                                  }}
                              />
                          ]
                        : showRevaluation || showElimination
                        ? [
                              <BackArrow
                                  onClick={() => {
                                      setShowElimination(false);
                                      setShowRevaluation(false);
                                      setShowTabs(true);
                                  }}
                              />
                          ]
                        : []
                }
            />
            {isLoading ? (
                <Spinner loading={isLoading}>
                    <Box marginTop={10} height={40}></Box>
                </Spinner>
            ) : (
                <Box>
                    {showTabs && (
                        <ExtendedTabs
                            size="small"
                            secondary={true}
                            value={selectedTab}
                            onChange={handleChangeTabs}
                        >
                            <ExtendedTab
                                value="registry"
                                label={fs("drawerLabels.registry")}
                            />
                            <ExtendedTab
                                value="depreciation"
                                label={fs("drawerLabels.depreciation")}
                            />
                            <ExtendedTab
                                value="movements"
                                label={fs("drawerLabels.movements")}
                            />
                        </ExtendedTabs>
                    )}
                    {selectedTab === "registry" && (
                        <RegistryTab
                            open={open}
                            showTabs={showTabs}
                            setShowTabs={setShowTabs}
                            assetDetail={assetDetail}
                            setSelectedSubkindCode={setSelectedSubkindCode}
                        />
                    )}
                    {selectedTab === "depreciation" && (
                        <DepreciationTab
                            selectedSubKindCode={selectedSubKindCode}
                            assetDetail={assetDetail}
                        />
                    )}
                    {selectedTab === "movements" && (
                        <MovementsTab
                            page={eliminationPage}
                            onChangeEliminationPage={
                                handleChangeEliminationPage
                            }
                            showTabs={showTabs}
                            setShowTabs={setShowTabs}
                            showRevaluation={showRevaluation}
                            setShowRevaluation={setShowRevaluation}
                            showElimination={showElimination}
                            setShowElimination={setShowElimination}
                            selectedAsset={selectedAsset}
                            setSelectedTab={setSelectedTab}
                        />
                    )}
                </Box>
            )}
            {showTabs && (
                <VaporToolbar
                    variant="surface"
                    withoutAppBar={false}
                    contentRight={
                        <>
                            <Button
                                onClick={() => {
                                    setOpen(!open);
                                    setSelectedTab("registry");
                                    setShowTabs(true);
                                }}
                                variant="contained"
                            >
                                {fs("close")}
                            </Button>
                        </>
                    }
                />
            )}
        </Drawer>
    );
}
