import { Spinner } from "@comic/precog-components";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

import { AssetDetailDto } from "../../../../../core/usecases/dtos/AssetDetailDto";
import { useGetDepriciation } from "../../../../../core/usecases/useGetDepriciationSubkind";
import AssetDepreciation from "../../../../components/Assets/AssetDepreciation";

interface DepreciationTabProps {
    assetDetail: AssetDetailDto | null;
    selectedSubKindCode: string | null;
}

export default function DepreciationTab({
    assetDetail,
    selectedSubKindCode
}: DepreciationTabProps) {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);

    const {
        fetch: fetchData,
        data,
        loading
    } = useGetDepriciation({
        group: assetDetail?.group,
        kind: assetDetail?.kind,
        subKind: selectedSubKindCode,
        lazy: true
    });

    useEffect(() => {
        if (!hasInitialized) {
            fetchData();
            setHasInitialized(true);
        }
    }, [hasInitialized, fetchData]);

    return loading ? (
        <Spinner loading>
            <Box marginTop={10} height={40}></Box>
        </Spinner>
    ) : (
        <AssetDepreciation
            readOnly={true}
            depreciation={data}
            assetDetail={assetDetail}
        />
    );
}
