import { useGet } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { DraftDetail } from "../dtos/DraftDetail";
import { ProcedureEntryDetail } from "../dtos/ProcedureEntryDetail";

interface UseGetProcedureEntryDetail {
    loading: boolean;
    error: any;
    detail: ProcedureEntryDetail | null;
    get: (accountedEntryId: string) => Promise<DraftDetail>;
}

export const useGetProcedureEntryDetail = (): UseGetProcedureEntryDetail => {
    const customerData = useCustomerData();

    const { fetch, data, loading, error } = useGet(`ignored`, {
        ...useAxiosConfigRequestParams("ProcedureEntryDetail"),
        params: omitNilProperties({
            ...useCustomerData()
        }),
        lazy: true
    });

    const get = async (accountedEntryId: string) => {
        return fetch({
            url: `${ASYNC_API_URL}/detail/procedure-entries/${accountedEntryId}`,
            params: {
                ...customerData
            }
        }).then(({ data }) => data);
    };

    return { get, loading: loading, error: error, detail: data };
};
