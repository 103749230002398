import { TableCell, TableRow } from "@vapor/react-material";
import { Report } from "../../../../core/usecases/dtos/ReportAccountingPeriodsDto";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../components/FormattedAmount";

interface SummaryCloseAndReopenAccountsTableProps {
    report: Report;
}

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.CloseAndReopenAccountsTable"
);
export default function SummaryCloseAndReopenAccountsTable({
    report
}: SummaryCloseAndReopenAccountsTableProps) {
    return (
        <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
                {fs("summary.total")}{" "}
                <FormattedAmount
                    currency={report?.total.costs.currency}
                    amount={0}
                />
            </TableCell>
            <TableCell>
                {fs("summary.total")}{" "}
                <FormattedAmount
                    currency={report?.total.costs.currency}
                    amount={report?.total.openingTotal.debit.value}
                />
            </TableCell>
            <TableCell align="right">
                {fs("summary.total")}
                <FormattedAmount
                    currency={report?.total.costs.currency}
                    amount={report?.total.openingTotal.credit.value}
                />
            </TableCell>
        </TableRow>
    );
}
