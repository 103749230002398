import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import { useGetExemptionNatures } from "../../usecases/useGetExemptionNatures";

export const exemptionNaturesQueryKeys = createQueryKeys("exemption-natures", {
    all: null
});

export const useExemptionNatures = () => {
    const { fetch } = useGetExemptionNatures({ lazy: true });

    return useQuery({
        ...exemptionNaturesQueryKeys.all,
        queryFn: fetch
    });
};
