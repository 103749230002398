import { isNil } from "lodash";
import {
    createContext,
    useState,
    useEffect,
    useCallback,
    useContext
} from "react";
import usePrevious from "../../commons/hooks/usePrevious";
import { AssetLinesResponse } from "../../usecases/dtos/AssetLinesDto";
import { useGetAssetLines } from "../../usecases/useGetAssetLines";
import {
    DraftAssetPatch,
    usePatchDraftAsset
} from "../../usecases/usePatchDraftAsset";
import { useDeleteAssetGroup } from "../../usecases/useDeleteAssetGroup";

interface DraftAssetContextType {
    setDraftId: (draftIdState: string | null) => void;
    loading: boolean;
    error: any;
    update: (
        data: Partial<DraftAssetPatch>,
        assetId?: string,
        parentId?: string | null
    ) => void;
    assetLinesList: AssetLinesResponse | null;
    hasUpdated: boolean;
    get: () => void;
    deleteAssetGroup: (
        draftId?: string | null,
        parentId?: string | null,
        assetId?: string | null
    ) => Promise<void>;
}

const DraftAssetContext = createContext<DraftAssetContextType>({
    setDraftId: () => {},
    loading: false,
    error: null,
    update: () => {},
    assetLinesList: null,
    hasUpdated: false,
    get: () => {},
    deleteAssetGroup: async () => {}
});

interface DraftAssetProviderProps {
    draftId?: string;
    children: React.JSX.Element;
}

export const DraftAssetProvider = ({
    draftId,
    children
}: DraftAssetProviderProps) => {
    const [draftIdState, setDraftIdState] = useState<string | null>(null);
    const previousDraftId = usePrevious(draftIdState);

    const [draftAssetLines, setDraftAssetLines] =
        useState<AssetLinesResponse | null>(null);
    const [hasUpdatedDraft, setHasUpdatedDraft] = useState<boolean>(false);
    const [isLoadingDraft, setIsLoadingDraft] = useState<boolean>(false);

    const useGetDraftAssetLinesHook = useGetAssetLines({ lazy: true });
    const useEditDraftAssetHook = usePatchDraftAsset({ lazy: true });
    const useDeleteAssetGroupHook = useDeleteAssetGroup({ lazy: true });

    useEffect(() => {
        draftId && setDraftIdState(draftId);
    }, [draftId]);

    useEffect(() => {
        isNil(draftIdState) && setDraftAssetLines(null);
    }, [draftIdState]);

    const get = useCallback(() => {
        if (draftIdState) {
            useGetDraftAssetLinesHook.fetchWithParameters(draftIdState);
            setHasUpdatedDraft(false);
        }
    }, [useGetDraftAssetLinesHook, draftIdState]);

    useEffect(() => {
        if (draftIdState && previousDraftId !== draftIdState) {
            get();
            setDraftAssetLines(null);
        }
    }, [draftIdState, previousDraftId, get]);

    useEffect(() => {
        if (!hasUpdatedDraft && useGetDraftAssetLinesHook.hasResponded) {
            setDraftAssetLines(useGetDraftAssetLinesHook.assetLinesList);
            setHasUpdatedDraft(true);
        }
    }, [draftIdState, hasUpdatedDraft, useGetDraftAssetLinesHook]);

    const setDraftId = (newDraftId: string | null) => {
        setDraftIdState(newDraftId);
    };

    const update = useCallback(
        (
            data: Partial<DraftAssetPatch>,
            assetId?: string,
            parentId?: string | null
        ) => {
            if (draftIdState) {
                useEditDraftAssetHook.patchDraftAssetParams(
                    assetId,
                    parentId,
                    draftIdState,
                    data
                );
                setDraftAssetLines((prevDraftAssetLines) => {
                    if (!prevDraftAssetLines) {
                        return null;
                    }

                    const updatedAssetLines =
                        prevDraftAssetLines.assetLines.map((asset) => {
                            if (asset.uuid === assetId) {
                                return {
                                    ...asset,
                                    ...data
                                };
                            }

                            if (
                                !isNil(asset.parentId) &&
                                asset.parentId === parentId
                            ) {
                                return {
                                    ...asset,
                                    ...data
                                };
                            }

                            return asset;
                        });

                    return {
                        ...prevDraftAssetLines,
                        assetLines: updatedAssetLines
                    };
                });
            }
        },
        [draftIdState, useEditDraftAssetHook]
    );

    useEffect(() => {
        if (isNil(draftAssetLines)) {
            setIsLoadingDraft(true);
        } else {
            setIsLoadingDraft(false);
        }
    }, [draftAssetLines]);

    const deleteAssetGroup = useCallback(
        async (draftId?: string | null, parentId?: string | null) => {
            if (draftIdState) {
                return useDeleteAssetGroupHook
                    .deleteAssetGroupParams(draftId, parentId)
                    .then(async () => {
                        await Promise.all(
                            draftAssetLines?.assetLines
                                ? draftAssetLines.assetLines
                                      .filter(
                                          (asset) => asset.parentId === parentId
                                      )
                                      .map((asset) => {
                                          return useEditDraftAssetHook.patchDraftAssetParams(
                                              asset.uuid,
                                              undefined,
                                              draftIdState,
                                              { hasAdditionalCharge: false }
                                          );
                                      })
                                : []
                        );

                        get();
                    });
            }
        },
        [
            draftAssetLines?.assetLines,
            draftIdState,
            useDeleteAssetGroupHook,
            useEditDraftAssetHook,
            get
        ]
    );

    return (
        <DraftAssetContext.Provider
            value={{
                setDraftId,
                loading: isLoadingDraft || useGetDraftAssetLinesHook.loading,
                error: useGetDraftAssetLinesHook.error,
                update,
                assetLinesList: draftAssetLines,
                hasUpdated: useEditDraftAssetHook.hasResponded,
                get,
                deleteAssetGroup
            }}
        >
            {children}
        </DraftAssetContext.Provider>
    );
};

export const useDraftAsset = () => {
    return useContext(DraftAssetContext);
};
