import { Spinner } from "@comic/precog-components";
import { ExtendedTypography as Typography } from "@vapor/react-extended";
import { CardContent, Divider, Stack } from "@vapor/react-material";

import { useGetVatSummary } from "../../../core/usecases/useGetVatSummary";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../components/FormattedAmount";
import WidgetCard from "../../components/WidgetCard";

const fs = getFormattedStringWithScope("widgets.VatSummary");
const fsPeriod = getFormattedStringWithScope("commons.period");

const SettlementRecap = (props: {
    amount: number;
    ordinal: string;
    year: number;
}) => {
    const amountColor = props.amount < 0 ? "red" : "black";
    const title = `${fs("vat")} ${fsPeriod(props.ordinal)} ${props.year}`;

    return (
        <Stack direction="row" justifyContent="space-between">
            <Typography variant="boldLabel">{title}</Typography>
            <FormattedAmount
                amount={props.amount}
                currency="EUR"
                variant="boldLabel"
                color={amountColor}
            />
        </Stack>
    );
};

const VatSummary = () => {
    const { vatSummary, loading, error } = useGetVatSummary();

    return (
        <Spinner loading={loading}>
            <WidgetCard error={error}>
                <CardContent>
                    <Typography variant="titleMedium">{fs("title")}</Typography>
                    <Stack
                        marginTop={1}
                        spacing={1}
                        direction="column"
                        divider={
                            <Divider
                                orientation="horizontal"
                                variant="middle"
                            />
                        }
                    >
                        {vatSummary?.settlementsRecap?.map((summary, index) => (
                            <SettlementRecap
                                ordinal={summary.ordinal}
                                year={summary.year}
                                amount={summary.amount}
                                key={index}
                            />
                        ))}
                    </Stack>
                </CardContent>
            </WidgetCard>
        </Spinner>
    );
};

export default VatSummary;
