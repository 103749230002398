import flattenObject from "../utils/flattenObject";

export default flattenObject({
    notifications: {},
    commons: {},
    components: {
        AppTitle: { title: "Report" },
        DraftCard: {
            date: "Created in date:"
        },
        Spinner: {
            loading: "Loading..."
        }
    },
    views: {
        Home: {
            title: "REPORT"
        }
    }
});
