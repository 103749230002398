import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";

import getFormattedDate from "../../../../../../utils/getFormattedDate";
import getFormattedStringWithScope from "../../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../../components/FormattedAmount";

const fs = getFormattedStringWithScope(
    "views.ListAssets.movementDrawer.purchaseLogs"
);

interface PurchaseLogsDetailsProps {
    filteredLogs: { [key: string]: any };
}

export default function PurchaseLogsDetails({
    filteredLogs
}: PurchaseLogsDetailsProps) {
    return (
        <Box marginRight={3}>
            <Grid container direction="column" marginLeft={1} marginTop={3}>
                {filteredLogs[0]?.type === "PURCHASE" ? (
                    <Typography variant="bodyLarge700">
                        {fs("initialPurchase")}
                    </Typography>
                ) : (
                    <Typography variant="bodyLarge700">
                        {fs("incrementalSpending")}
                    </Typography>
                )}
                <Typography
                    marginTop={2.8}
                    color="primary.textTitleColor"
                    variant="subtitle"
                >
                    {fs("documentInfo")}
                </Typography>
                <Grid container justifyContent="space-between" marginTop={2}>
                    <Stack>
                        <Typography variant="caption">
                            {fs("documentDate")}
                        </Typography>
                        <Typography variant="body700">
                            {getFormattedDate(filteredLogs[0]?.document?.date)}
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography variant="caption">
                            {fs("documentNumber")}
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.document.number}
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography variant="caption">
                            {fs("supplierCompanyName")}
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.supplierName}
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography variant="caption">
                            {fs("activitiesSection")}
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.document?.protocol?.group}
                        </Typography>
                    </Stack>
                </Grid>
                <Typography
                    marginTop="22px"
                    color="primary.textTitleColor"
                    variant="subtitle"
                >
                    {fs("opsInfo")}
                </Typography>
                <Grid container justifyContent="space-between" marginTop={2}>
                    <Stack>
                        <Typography variant="caption">
                            {fs("purchaseDate")}
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.date === null
                                ? "XXXXXXX"
                                : getFormattedDate(filteredLogs[0]?.date)}
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography variant="caption">
                            {fs("assetValues")}
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            amount={filteredLogs[0]?.logAmount}
                            currency="EUR"
                        />
                    </Stack>
                    <Stack>
                        <Typography variant="caption">
                            {fs("nonDeductibleVat")}
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            amount={filteredLogs[0]?.nonDeductibleVat}
                            currency="EUR"
                        />
                    </Stack>
                    <Stack>
                        <Typography variant="caption">
                            {fs("additionalCharge")}
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            amount={filteredLogs[0]?.additionalCharge}
                            currency="EUR"
                        />
                    </Stack>
                    <Stack>
                        <Typography variant="caption">
                            {fs("carryingValue")}
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            amount={filteredLogs[0]?.carryingValue}
                            currency="EUR"
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}
