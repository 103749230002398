import { faCabinetFiling } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Lock, LockOpen } from "@vapor/react-icons";
import { IconButton } from "@vapor/react-material";
import Tooltip from "@vapor/react-material/Tooltip";

import { VatSettlement } from "../../../../core/usecases/interfaces/VatSettlement";
import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";
import CustomButton from "../../../components/CustomButton";

interface PeriodCardActionsProps {
    settlement: VatSettlement;
    onReopenPeriod: () => void;
    onClosePeriod: () => void;
    loadingOpenSettlement: boolean;
    loadingCloseSettlement: boolean;
}

export default function PeriodCardActions({
    settlement,
    onReopenPeriod,
    onClosePeriod,
    loadingCloseSettlement,
    loadingOpenSettlement
}: PeriodCardActionsProps) {
    const fs = useFormattedStringWithScope("views.VAT.carousel");

    switch (settlement.status) {
        case "JOURNALIZED":
            return (
                <Tooltip
                    arrow={true}
                    placement="top"
                    title={fs("tooltipReopenPeriod")}
                >
                    <div>
                        <IconButton disabled>
                            <FontAwesomeIcon icon={faCabinetFiling} />
                        </IconButton>
                    </div>
                </Tooltip>
            );
        case "CLOSED":
            return (
                <IconButton disabled>
                    <Lock color="disabled" />
                </IconButton>
            );
        case "OPENABLE":
            return (
                <CustomButton
                    startIcon={<Lock />}
                    onClick={onReopenPeriod}
                    loading={loadingOpenSettlement}
                >
                    {fs("reopenPeriod")}
                </CustomButton>
            );
        case "CLOSABLE":
            return (
                <CustomButton
                    startIcon={<LockOpen />}
                    onClick={onClosePeriod}
                    loading={loadingCloseSettlement}
                />
            );
        case "OPEN":
            return (
                <IconButton disabled>
                    <LockOpen color="disabled" />
                </IconButton>
            );
        case "CLOSING":
            return (
                <CustomButton disabled loading>
                    {fs("periodClosureInProgress")}
                </CustomButton>
            );
        case "OPENING":
            return (
                <CustomButton disabled loading>
                    {fs("periodOpeningInProgress")}
                </CustomButton>
            );
        case "DISABLED":
        default:
            return null;
    }
}
