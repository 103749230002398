import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";

import getFormattedDate from "../../../../../../utils/getFormattedDate";
import getFormattedStringWithScope from "../../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../../components/FormattedAmount";

const fs = getFormattedStringWithScope(
    "views.ListAssets.movementDrawer.saleLogs"
);

interface SaleLogsDetailsProps {
    filteredLogs: { [key: string]: any };
}

export default function SaleLogsDetails({
    filteredLogs
}: SaleLogsDetailsProps) {
    return (
        <Box marginRight={3}>
            <Grid container direction="column" marginLeft={1} marginTop={3}>
                <Typography variant="bodyLarge700">{fs("sale")}</Typography>
                <Typography
                    marginTop={2.8}
                    color="primary.textTitleColor"
                    variant="subtitle"
                >
                    {fs("documentInfo")}
                </Typography>
                <Grid container justifyContent="space-between" marginTop={2}>
                    <Stack marginTop={2}>
                        <Typography variant="caption">
                            {fs("documentDate")}
                        </Typography>
                        <Typography variant="body700">
                            {getFormattedDate(filteredLogs[0]?.document.date)}
                        </Typography>
                    </Stack>
                    <Stack marginTop={2}>
                        <Typography variant="caption">
                            {fs("documentNumber")}
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.document.number}
                        </Typography>
                    </Stack>
                    <Stack marginTop={2}>
                        <Typography variant="caption">
                            {fs("clientCompanyName")}
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0].supplierName}
                        </Typography>
                    </Stack>
                    <Stack marginTop={2}>
                        <Typography variant="caption">
                            {fs("activitiesSection")}
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.document?.protocol?.group}
                        </Typography>
                    </Stack>
                </Grid>
                <Typography
                    marginTop="22px"
                    color="primary.textTitleColor"
                    variant="subtitle"
                >
                    {fs("opsInfo")}
                </Typography>
                <Grid container justifyContent="space-between" marginTop={2}>
                    <Stack marginTop="16px">
                        <Typography variant="caption">
                            {fs("saleDate")}
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.date === null
                                ? "XXXXXXX"
                                : getFormattedDate(filteredLogs[0]?.date)}
                        </Typography>
                    </Stack>
                    <Stack marginTop="16px">
                        <Typography variant="caption">
                            {fs("salePrice")}
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            amount={filteredLogs[0]?.logAmount}
                            currency="EUR"
                        />
                    </Stack>
                    <Stack marginTop="16px">
                        <Typography variant="caption">
                            {fs("salePercent")}
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.rate === null
                                ? "0 %"
                                : `${filteredLogs[0]?.rate}%`}
                        </Typography>
                    </Stack>
                </Grid>
                {filteredLogs[0]?.capital !== 0 && (
                    <>
                        <Stack marginTop="16px">
                            {filteredLogs[0]?.capital > 0 &&
                                filteredLogs[0]?.trackType === "STATUTORY" && (
                                    <Typography variant="caption">
                                        {fs("civilCapitalGain")}
                                    </Typography>
                                )}
                            {filteredLogs[0]?.capital < 0 &&
                                filteredLogs[0]?.trackType === "STATUTORY" && (
                                    <Typography variant="caption">
                                        {fs("civilCapitalLoss")}
                                    </Typography>
                                )}
                            {filteredLogs[0]?.capital > 0 &&
                                filteredLogs[0]?.trackType === "TAX" && (
                                    <Typography variant="caption">
                                        {fs("taxCapitalGain")}
                                    </Typography>
                                )}
                            {filteredLogs[0]?.capital < 0 &&
                                filteredLogs[0]?.trackType === "TAX" && (
                                    <Typography variant="caption">
                                        {fs("taxCapitalLoss")}
                                    </Typography>
                                )}
                            {/* TODO: waiting for DTO from BE */}
                            {/* <FormattedAmount
                                variant="body700"
                                amount={filteredLogs[0]?.capital}
                                currency="EUR"
                                /> */}
                        </Stack>
                        {/* TODO: waiting for DTO from BE */}
                        {/* <Stack marginTop="16px">
                            <Typography variant="caption">
                                Plusvalenza da ripartire
                            </Typography>
                            <Typography variant="body700">
                                {filteredLogs[0]?.date === null
                                    ? "XXXXXXX"
                                    : getFormattedDate(filteredLogs[0]?.date)}
                            </Typography>
                        </Stack> */}
                        <Stack marginTop="16px">
                            <Typography variant="caption">
                                {fs("residualDepreciation")}
                            </Typography>
                            <FormattedAmount
                                variant="body700"
                                amount={filteredLogs[0]?.carryingValue}
                                currency="EUR"
                            />
                        </Stack>
                    </>
                )}
                <Grid container justifyContent="space-between" marginTop={2}>
                    {/* TODO: waiting for DTO from BE */}
                    {/* <Stack marginTop="16px">
                         <Typography variant="caption">
                            Ammortamento vendita
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.date === null
                                ? "XXXXXXX"
                                : getFormattedDate(filteredLogs[0]?.date)}
                        </Typography>
                    </Stack> */}
                    {/* <Stack marginTop="16px">
                        <Typography variant="caption">
                             Ammortamento indeducibile
                        </Typography>
                         <Typography variant="body700">
                            {filteredLogs[0]?.date === null
                                 ? "XXXXXXX"
                                 : getFormattedDate(filteredLogs[0]?.date)}
                        </Typography>
                    </Stack> */}
                </Grid>
            </Grid>
        </Box>
    );
}
