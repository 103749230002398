import { Input, InputProps } from "@vapor/react-material";
import { useEffect, useState } from "react";

import usePrevious from "../../../core/commons/hooks/usePrevious";

interface DebouncedControlledInputProps
    extends Pick<InputProps, "sx" | "type"> {
    value: string;
    onChange: (value: string) => void;
    readOnly?: boolean;
}

export default function DebouncedControlledInput({
    value,
    onChange,
    readOnly = false,
    ...inputProps
}: DebouncedControlledInputProps) {
    const [internalValue, setInternalValue] = useState<string>(value);
    const previousInternalValue = usePrevious(internalValue);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    useEffect(() => {
        if (value !== internalValue && previousInternalValue !== internalValue)
            onChange(internalValue);
    }, [internalValue, value, onChange, previousInternalValue]);

    const handleInputChange = (newValue: string) => {
        setInternalValue(newValue);
    };

    return (
        <Input
            type="text"
            value={internalValue}
            onChange={(event) => handleInputChange(event.target.value)}
            readOnly={readOnly}
            {...inputProps}
        />
    );
}
