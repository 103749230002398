import Grid from "@vapor/react-material/Grid";
import { useState } from "react";
import { VatSettlementDetail } from "../../../../../../core/usecases/dtos/VatSettlementDto";
import getFormattedStringWithScope from "../../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../../components/FormattedAmount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareArrowUpRight } from "@fortawesome/pro-regular-svg-icons";
import IconButton from "@vapor/react-material/IconButton";
import VentilationDrawer from "./VentilationDrawer";
import Stack from "@vapor/react-material/Stack";
import isEmpty from "lodash/isEmpty";

const fs = getFormattedStringWithScope("views.VATSettlement.monoActivity");

interface SummaryLineWithVentilationProps {
    settlementDetail: VatSettlementDetail;
    ventilations: VatSettlementDetail[];
}
const SummaryLineWithVentilation = ({
    settlementDetail,
    ventilations
}: SummaryLineWithVentilationProps) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <Stack>
            <Grid container>
                <Grid item xs={4} container alignItems="center">
                    <Grid item xs={8}>
                        {fs(settlementDetail.description.toLowerCase())}
                    </Grid>
                    <Grid item xs={4} spacing={1}>
                        <FormattedAmount
                            amount={settlementDetail.vatAmount}
                            currency="EUR"
                            variant="body500"
                        />
                        {!isEmpty(ventilations) && (
                            <IconButton
                                onClick={() => {
                                    setOpen(!open);
                                }}
                            >
                                <FontAwesomeIcon
                                    color=""
                                    icon={faSquareArrowUpRight}
                                    className="v-AssetsListTable-icon"
                                />
                            </IconButton>
                        )}
                    </Grid>
                    <VentilationDrawer
                        open={open}
                        onClose={() => setOpen(false)}
                        ventilations={ventilations}
                    />
                </Grid>
            </Grid>
        </Stack>
    );
};

export default SummaryLineWithVentilation;
