import Typography from "@vapor/react-extended/ExtendedTypography";

import { Box, Stack } from "@vapor/react-material";
import { VatSettlementEntry } from "../../../../../../core/usecases/dtos/VatSettlementDto";
import { Ventilations } from "../../../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../../../utils/getFormattedStringWithScope";
import SummaryLineWithVentilation from "./SummaryLineWithVentilation";
import SummaryLine from "./summary-line";

const fs = getFormattedStringWithScope("views.VATSettlement.monoActivity");

interface SummaryProps {
    selectedSettlement: VatSettlementEntry;
}

const Summary = ({ selectedSettlement }: SummaryProps) => {
    const receiptDetail = selectedSettlement.details.find(
        (x) => x.description === "RECEIPT"
    );
    const othersDetail = selectedSettlement.details.filter(
        (x) =>
            x.description !== "RECEIPT" &&
            x.description !== Ventilations.VENTILATION_RECEIPT &&
            x.description !== Ventilations.VENTILATION_PURCHASE
    );
    const ventilationsDetail = selectedSettlement.details?.filter(
        (vatSettlementDetail) =>
            vatSettlementDetail.description ===
                Ventilations.VENTILATION_RECEIPT ||
            vatSettlementDetail.description ===
                Ventilations.VENTILATION_PURCHASE
    );

    return (
        <div>
            <Box mt={3}>
                <Typography variant="titleSmall">
                    {`${fs("title")} ${selectedSettlement.businessDescription}`}
                </Typography>
            </Box>

            <Stack maxWidth={480} mt={3}>
                {othersDetail.map((detail) => (
                    <SummaryLine detail={detail} />
                ))}

                {receiptDetail && (
                    <Box mb={2}>
                        <SummaryLineWithVentilation
                            settlementDetail={receiptDetail}
                            ventilations={ventilationsDetail}
                        />
                    </Box>
                )}
            </Stack>
        </div>
    );
};

export default Summary;
