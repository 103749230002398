import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";
import Typography from "@vapor/react-extended/ExtendedTypography";

import FormattedAmount from "../../../../../components/FormattedAmount";
import getFormattedDate from "../../../../../../utils/getFormattedDate";
import getFormattedStringWithScope from "../../../../../../utils/getFormattedStringWithScope";

const fs = getFormattedStringWithScope(
    "views.ListAssets.movementDrawer.depreciationLogs"
);

interface DepreciationLogsDetailsProps {
    filteredLogs: { [key: string]: any };
}

export default function DepreciationLogsDetails({
    filteredLogs
}: DepreciationLogsDetailsProps) {
    return (
        <Box
            marginLeft="10px"
            marginTop="22px"
            display="flex"
            flexDirection="column"
        >
            <Typography variant="bodyLarge700">{fs("depreciation")}</Typography>
            <Typography
                marginTop="22px"
                color="primary.textTitleColor"
                variant="subtitle"
            >
                {fs("opsInfo")}
            </Typography>
            <Stack>
                <Box
                    display="flex"
                    width="90%"
                    flexWrap="wrap"
                    justifyContent="space-between"
                >
                    <Stack marginTop="16px">
                        <Typography variant="caption">
                            {fs("depreciationDate")}
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.date === null
                                ? "XXXXXXX"
                                : getFormattedDate(filteredLogs[0]?.date)}
                        </Typography>
                    </Stack>
                    <Stack marginTop="16px">
                        <Typography variant="caption">
                            {fs("depreciationPercent")}
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.rate === null
                                ? "0 %"
                                : `${filteredLogs[0]?.rate}%`}
                        </Typography>
                    </Stack>
                    <Stack marginTop="16px">
                        <Typography variant="caption">
                            {fs("totalToBeDepreciated")}
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            amount={filteredLogs[0]?.amount}
                            currency="EUR"
                        />
                    </Stack>
                    <Stack marginTop="16px">
                        <Typography variant="caption">
                            {fs("depreciationQuota")}
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            amount={filteredLogs[0]?.logAmount}
                            currency="EUR"
                        />
                    </Stack>
                    <Stack marginTop="16px">
                        <Typography variant="caption">
                            {fs("residualDepreciation")}
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            amount={filteredLogs[0]?.carryingValue}
                            currency="EUR"
                        />
                    </Stack>
                    <Stack marginTop="16px">
                        <Typography variant="caption">
                            {fs("accumulatedDepreciation")}
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            amount={filteredLogs[0]?.accumulatedDepreciation}
                            currency="EUR"
                        />
                    </Stack>
                    <Stack marginTop="16px">
                        <Typography variant="caption">
                            {fs("suspensionPercent")}
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.suspesionRate === null
                                ? "0 %"
                                : `${filteredLogs[0]?.suspesionRate}%`}
                        </Typography>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );
}
