import IconButton from "@vapor/react-material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareArrowUpRight } from "@fortawesome/pro-regular-svg-icons";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@vapor/react-material";
import Stack from "@vapor/react-material/Stack";

import FormattedAmount from "../../components/FormattedAmount";
import { UsedGoodApiResList } from "../../../core/usecases/dtos/DraftUsedGoodsDto";
import IncreaseExistingGood from "./IncreaseExistingGood";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";

interface UsedGoodsTableProps {
    totalAmount?: number;
    draftUsedGoods: UsedGoodApiResList[] | null;
    refCurrency: string;
}

const fs = getFormattedStringWithScope("views.Detail.usedGoods");

export default function UsedGoodsTable({
    totalAmount,
    draftUsedGoods,
    refCurrency
}: UsedGoodsTableProps) {
    const renderTableHead = () => {
        return (
            <>
                <TableCell size="medium">
                    <Typography
                        variant="boldLabel"
                        color="primary.textTitleColor"
                    >
                        {fs("article")}
                    </Typography>
                </TableCell>
                <TableCell align="right" size="medium">
                    <Typography
                        variant="boldLabel"
                        color="primary.textTitleColor"
                    >
                        {fs("quantity")}
                    </Typography>
                </TableCell>
                <TableCell align="right" size="medium">
                    <Typography
                        variant="boldLabel"
                        color="primary.textTitleColor"
                    >
                        {fs("total")}
                    </Typography>
                </TableCell>
                <TableCell align="right" size="medium">
                    <Typography
                        variant="boldLabel"
                        color="primary.textTitleColor"
                    >
                        {fs("increaseExistingGood")}
                    </Typography>
                </TableCell>
                <TableCell />
            </>
        );
    };

    return (
        <>
            <Table>
                <TableHead shadow>{renderTableHead()}</TableHead>
                <TableBody>
                    {draftUsedGoods?.map((usedGood, index) => {
                        return (
                            <>
                                <TableRow key={index}>
                                    <TableCell size="small">
                                        <Typography
                                            paddingLeft="5px"
                                            paddingTop="10px"
                                        >
                                            {usedGood.articleDesc}
                                        </Typography>
                                    </TableCell>
                                    <TableCell size="small" align="right">
                                        <Typography>
                                            {usedGood.quantity}
                                        </Typography>
                                    </TableCell>
                                    <TableCell size="small" align="right">
                                        <FormattedAmount
                                            paddingLeft="5px"
                                            amount={usedGood.amount}
                                            currency={refCurrency}
                                        />
                                    </TableCell>
                                    <TableCell size="small" align="right">
                                        <IncreaseExistingGood
                                            usedGoodId={usedGood.id}
                                            increaseExistingGood={
                                                usedGood.increaseExistingGood
                                            }
                                        />
                                    </TableCell>
                                    <TableCell size="small" align="right">
                                        <IconButton onClick={() => {}}>
                                            <FontAwesomeIcon
                                                color="#0090d1"
                                                icon={faSquareArrowUpRight}
                                            />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </>
                        );
                    })}
                    <TableRow>
                        <TableCell borderBottom={false} colSpan={2}></TableCell>
                        <TableCell borderBottom={false}>
                            <Stack direction="row" justifyContent="flex-end">
                                <Typography marginRight={6.2}>
                                    {fs("totalAmount")}
                                </Typography>
                                <FormattedAmount
                                    currency={refCurrency}
                                    amount={totalAmount || 0}
                                />
                            </Stack>
                        </TableCell>
                        <TableCell borderBottom={false} colSpan={2}></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
}
