import { useRequest } from "@onefront/react-sdk";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import omitNilProperties from "../../../utils/omitNilProperties";
import { useSnackbar } from "../../../services/SnackbarService";

interface UseDeleteAssetGroup {
    loading: boolean;
    error: any;
    result: any;
    deleteAssetGroup: Function;
    deleteAssetGroupParams: (
        draftId?: string | null,
        parentId?: string | null
    ) => Promise<void>;
    hasFetched: boolean;
}

interface UseDeleteAssetGroupOptions {
    draftId?: string | null;
    parentId?: string | null;
    lazy: boolean;
}

export const useDeleteAssetGroup = ({
    draftId,
    parentId,
    lazy
}: UseDeleteAssetGroupOptions): UseDeleteAssetGroup => {
    const customerData = useCustomerData();

    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.DeleteAssetGroup");

    const { fetch, data, loading, error, hasLoaded } = useRequest({
        ...useAxiosConfigRequestParams("DeleteAssetGroup"),
        method: "delete",
        url: `${API_URL}/v1/precog/drafts/${draftId}/asset/group/${parentId}`,
        params: omitNilProperties({
            ...useCustomerData()
        }),
        onError: () =>
            enqueueSnackbar(fs("error"), {
                severity: "error"
            }),
        onResponse: () => {
            enqueueSnackbar(fs("success"), { severity: "success" });
        },
        lazy: lazy
    });

    const safeFetch = useCallback(() => {
        return fetch();
    }, [fetch]);

    const deleteAssetGroupParams = useCallback(
        (draftId?: string | null, parentId?: string | null) => {
            return fetch({
                url: `${API_URL}/v1/precog/drafts/${draftId}/asset/group/${parentId}`,
                params: {
                    ...customerData
                }
            });
        },
        [fetch, customerData]
    );

    return {
        deleteAssetGroup: safeFetch,
        deleteAssetGroupParams,
        loading: loading,
        error: error,
        result: data,
        hasFetched: hasLoaded
    };
};
