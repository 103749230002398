import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import { GetReasonsParams, useGetReasons } from "../../usecases/useGetReasons";

export const reasonsQueryKeys = createQueryKeys("reasons", {
    all: null,
    get: (params?: GetReasonsParams) => [params]
});

export const useReasons = (params?: GetReasonsParams) => {
    const { fetch } = useGetReasons();

    return useQuery({
        ...reasonsQueryKeys.get(params),
        queryFn: () => fetch(params)
    });
};
