import { Spinner } from "@comic/precog-components";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";
import { isNil } from "lodash";

import { useWithholdings } from "../../../core/contexts/Withholdings";
import { DraftWithholdingDto } from "../../../core/usecases/dtos/DraftWithholdingDto";
import { PatchData } from "../../../core/usecases/usePatchDraftWithholding";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../components/FormattedAmount";
import CurrencyField from "../CurrencyField";
import FormattedPercentage from "../FormattedPercentage";
import WithholdingDrawerHeader from "./WithholdingDrawerHeader";

const fs = getFormattedStringWithScope(
    "views.Detail.withholdings.managementTab"
);

interface WithholdingManagementTabProps {
    refCurrency: string;
    withholding: DraftWithholdingDto;
    totalTaxable: number;
    onWithholdingChange: (
        data: Partial<DraftWithholdingDto & PatchData>
    ) => void;
}

export default function WithholdingManagementTab({
    refCurrency,
    withholding,
    totalTaxable,
    onWithholdingChange
}: WithholdingManagementTabProps) {
    const {
        detailSimulation: parameters,
        isLoading,
        detail: withholdingDetail
    } = useWithholdings().draft;

    return parameters && withholdingDetail ? (
        <Box marginLeft="30px" display="flex">
            <Stack width="100%">
                <WithholdingDrawerHeader
                    withholding={withholding}
                    code={parameters.tributeCode}
                />
                <Box display="flex" marginTop="24px">
                    <Typography variant="body" marginRight="5px">
                        {fs("baseTaxableAmount")}:
                    </Typography>
                    <FormattedPercentage
                        variant="body700"
                        marginLeft="5px"
                        percentage={parameters?.baseTaxableShare}
                    />
                </Box>
                <Box display="flex" marginTop="24px">
                    <Typography variant="body" marginRight="5px">
                        {fs("totalNetAmount")}:
                    </Typography>
                    <FormattedAmount
                        variant="body700"
                        marginLeft="5px"
                        currency="EUR"
                        amount={totalTaxable}
                    />
                </Box>
                <Stack marginTop={3} spacing={2} justifyContent="space-between">
                    <Stack width="300px">
                        <Typography variant="body500">
                            {fs("cashContribution")}
                        </Typography>
                        <CurrencyField
                            value={
                                withholdingDetail?.cashContributionNoWithholding
                            }
                            currency={refCurrency}
                            onChange={(value) => {
                                onWithholdingChange({
                                    cashContributionNoWithholding: value
                                });
                            }}
                        />
                    </Stack>
                </Stack>
                <Stack marginTop={3} width="300px">
                    <Typography variant="body500">
                        {fs("notIncomeAmount")}
                    </Typography>
                    <CurrencyField
                        value={withholdingDetail?.notIncomeAmount}
                        currency={refCurrency}
                        onChange={(value) => {
                            onWithholdingChange({
                                notIncomeAmount: value
                            });
                        }}
                    />
                </Stack>
                <Box display="flex" marginTop="24px">
                    <Typography variant="body" marginRight="5px">
                        {fs("exemptIncome")}:
                    </Typography>
                    <FormattedAmount
                        variant="body700"
                        marginLeft="5px"
                        currency="EUR"
                        amount={parameters?.exemptIncome}
                    />
                </Box>
                <Box display="flex" marginTop="24px">
                    <Typography variant="body" marginRight="5px">
                        {fs("taxableAmount")}:
                    </Typography>
                    <FormattedAmount
                        variant="body700"
                        marginLeft="5px"
                        currency="EUR"
                        amount={parameters?.taxableAmount}
                    />
                </Box>
                <Box display="flex" marginTop="24px">
                    <Typography variant="body" marginRight="5px">
                        {fs("withholdingRate")}:
                    </Typography>
                    <FormattedPercentage
                        variant="body700"
                        marginLeft="5px"
                        percentage={parameters?.withholdingRate}
                    />
                </Box>
                {!isNil(parameters?.withHoldingTaxAmount) && (
                    <Box display="flex" marginTop="24px">
                        <Typography variant="body" marginRight="5px">
                            {fs("withHoldingTaxAmount")}:
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            marginLeft="5px"
                            currency="EUR"
                            amount={parameters?.withHoldingTaxAmount}
                        />
                    </Box>
                )}
            </Stack>
        </Box>
    ) : isLoading ? (
        <Spinner loading={isLoading}>
            <Box marginTop={40}></Box>
        </Spinner>
    ) : null;
}
