import { ColumnElement, ColumnType, TableRows } from "@comic/precog-components";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import IconButton from "@vapor/react-material/IconButton";
import { capitalize } from "lodash";

import { VatSettlementDetailAliquotes } from "../../../../../../../core/usecases/dtos/VatSettlementDto";
import { JournalType } from "../../../../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../../../components/FormattedAmount";

const fs = getFormattedStringWithScope("commons.VatEnum");

interface AliquotesTableProps {
    aliquotes: VatSettlementDetailAliquotes[];
    journalType: JournalType;
    onAliquotesDetailClick: Function;
}

export default function AliquotesTable({
    aliquotes,
    journalType,
    onAliquotesDetailClick
}: AliquotesTableProps) {
    const columns: ColumnElement[] = [
        {
            kind: ColumnType.empty
        },
        // description
        {
            kind: ColumnType.empty
        },
        // vatRateOrExemption
        {
            kind: ColumnType.text,
            render: (detail: VatSettlementDetailAliquotes) => (
                <Typography variant="bodySmall">
                    {capitalize(fs(detail.type))}
                </Typography>
            )
        },
        // vatDescription
        {
            kind: ColumnType.text,
            render: (detail: VatSettlementDetailAliquotes) => (
                <Typography variant="bodySmall">
                    {capitalize(detail.description.toLowerCase())}
                </Typography>
            )
        },
        // taxable
        {
            kind: ColumnType.numeric,
            render: (detail: VatSettlementDetailAliquotes) => (
                <FormattedAmount
                    variant="bodySmall"
                    amount={detail.taxable}
                    currency="EUR"
                />
            )
        },
        // tax
        {
            kind: ColumnType.numeric,
            render: (detail: VatSettlementDetailAliquotes) => (
                <FormattedAmount
                    variant="bodySmall"
                    amount={detail.tax}
                    currency="EUR"
                />
            )
        },
        // total
        {
            kind: ColumnType.numeric,
            render: (detail: VatSettlementDetailAliquotes) => (
                <FormattedAmount
                    variant="bodySmall"
                    amount={detail.totalAmount}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.cta,
            render: (detail: VatSettlementDetailAliquotes) => (
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() =>
                        onAliquotesDetailClick(detail.type, journalType)
                    }
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </IconButton>
            )
        }
    ];

    return <TableRows columns={columns} data={aliquotes} />;
}
