import React from "react";

import { useAccountingLines } from "../../../../core/domain/AccountingLines/queries";
import { UseAccountsOptions } from "../../../../core/domain/Accounts/queries";
import { AccountDto } from "../../../../core/usecases/dtos/AccountsDto";
import { isCustomerSupplier } from "../../../../utils/accountUtils";
import { default as SelectAccountSmartComponent } from "../../../components/Accounts/SelectAccount";

interface SelectAccountProps extends Omit<UseAccountsOptions, "searchString"> {
    draftId: string;
    value?: AccountDto | null;
    onChange: (account: AccountDto) => void;
    readOnly?: boolean;
    disabled?: boolean;
    transparent?: boolean;
    autoFocus?: boolean;
    hideCustomerSupplier?: boolean;
}

export default React.forwardRef<HTMLInputElement, SelectAccountProps>(
    function SelectAccount(
        {
            draftId,
            value,
            onChange,
            readOnly,
            disabled,
            transparent = true,
            autoFocus = false,
            hideCustomerSupplier = true,
            ...options
        }: SelectAccountProps,
        ref
    ) {
        const { data: accountingLines, isSuccess } = useAccountingLines({
            draftId
        });

        if (isSuccess) {
            const alreadyPresentAccountCodes = accountingLines.map(
                (accountingLine) => accountingLine.accountCode
            );

            const shouldHideAccount = (account: AccountDto) => {
                return (
                    alreadyPresentAccountCodes.includes(account.code) ||
                    (hideCustomerSupplier && isCustomerSupplier(account))
                );
            };

            return (
                <SelectAccountSmartComponent
                    value={value}
                    onChange={onChange}
                    transparent={transparent}
                    disabled={disabled}
                    readOnly={readOnly}
                    ref={ref}
                    shouldHideAccount={shouldHideAccount}
                    autoFocus={autoFocus}
                    {...options}
                />
            );
        }

        return null;
    }
);
