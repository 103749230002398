import { Spinner } from "@comic/precog-components";
import { endOfYear } from "date-fns";
import { isEmpty } from "lodash";
import { useEffect } from "react";

import usePrevious from "../../../../../core/commons/hooks/usePrevious";
import { useGetLedgerEntries } from "../../../../../core/usecases/useGetLedgerEntries";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import LedgerTable from "../../../../views/Ledger/LedgerTable";
import NotFoundBanner from "../../../NotFoundBanner";

interface LedgerProps {
    accountCode: string;
}

const fs = getFormattedStringWithScope(
    "components.Accounting.AccountingLinesTable.AccountDetailDrawer"
);

export default function Ledger({ accountCode }: LedgerProps) {
    const previousAccountCode = usePrevious(accountCode);

    const {
        ledgerEntries,
        error,
        loading,
        fetch: fetchLedgerEntries
    } = useGetLedgerEntries({
        accountId: accountCode,
        // pre-set standard dates since there is no date filter
        toDate: endOfYear(new Date()),
        lazy: true
    });

    useEffect(() => {
        if (accountCode && accountCode !== previousAccountCode) {
            fetchLedgerEntries();
        }
    }, [accountCode, previousAccountCode, fetchLedgerEntries]);

    return (
        <Spinner loading={loading}>
            {error ? (
                <NotFoundBanner text={fs("ledgerNotFound")} />
            ) : ledgerEntries ? (
                isEmpty(ledgerEntries.entries) ? (
                    <NotFoundBanner text={fs("ledgerNotFound")} />
                ) : (
                    <LedgerTable
                        accountCode={accountCode}
                        ledgerEntries={ledgerEntries}
                        disableVerticalNavigation={true}
                    />
                )
            ) : null}
        </Spinner>
    );
}
