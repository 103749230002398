import {
    ColumnElement,
    ColumnType,
    HeadCell,
    Table
} from "@comic/precog-components";
import { faArrowRight, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { IconButton, Stack } from "@vapor/react-material";
import isNil from "lodash/isNil";
import { useState } from "react";

import {
    AccountingPeriodDeferralsDto,
    Deferral
} from "../../../core/usecases/dtos/AccountingPeriodDeferralsDto";
import { useGetDraftFromOriginalEntry } from "../../../core/usecases/useGetDraftFromOriginalEntry";
import { useDocumentDetail } from "../../../services/DocumentDetail";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../components/FormattedAmount";
import FormattedDate from "../../components/FormattedDate";

interface DeferralsTableProps {
    deferrals: AccountingPeriodDeferralsDto;
}

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.DeferralsTable"
);
const fsDeferralType = getFormattedStringWithScope("commons.deferralTypes");

export default function DeferralsTable({ deferrals }: DeferralsTableProps) {
    const { fetch: getDraftId, loading } = useGetDraftFromOriginalEntry();
    const { open } = useDocumentDetail();

    const [deferralEntryId, setDeferralEntryId] = useState<string | null>(null);

    const handleDeferralArrowClick = async (deferralEntryId: string) => {
        setDeferralEntryId(deferralEntryId);
        const draftId = await getDraftId(deferralEntryId);
        open(draftId, { readOnly: true });
    };

    const headCells: HeadCell[] = [
        // registration date
        {
            id: "registration date",
            kind: ColumnType.text,
            label: fs("registrationDate")
        },
        // document date
        {
            id: "document date",
            kind: ColumnType.text,
            label: fs("docDate")
        },
        // document number
        {
            id: "document number",
            kind: ColumnType.text,
            label: fs("docNumber")
        },
        // reason
        {
            id: "reason",
            kind: ColumnType.text,
            label: fs("reason")
        },
        // account
        {
            id: "account",
            kind: ColumnType.text,
            label: fs("account")
        },
        // counterpart
        {
            id: "counterpart",
            kind: ColumnType.text,
            label: fs("counterpart")
        },
        // competence period start and end
        {
            id: "competence period start and end",
            kind: ColumnType.text,
            label: fs("competenceStartEnd")
        },
        // amount
        {
            id: "amount",
            kind: ColumnType.numeric,
            label: fs("amount")
        },
        // days in the period
        {
            id: "days in the period",
            kind: ColumnType.numeric,
            label: fs("daysInPeriod")
        },
        // "giorni comp. success"
        {
            id: "daysAfter",
            kind: ColumnType.numeric,
            label: fs("daysAfter")
        },
        // value
        {
            id: "value",
            kind: ColumnType.numeric,
            label: fs("value")
        },
        // value
        {
            id: "cta",
            kind: ColumnType.cta,
            label: ""
        }
    ];

    const columns: ColumnElement[] = [
        // registration date
        {
            kind: ColumnType.text,
            render: (deferral: Deferral) => (
                <FormattedDate date={deferral.postingDate} />
            )
        },
        // document date
        {
            kind: ColumnType.text,
            render: (deferral: Deferral) => (
                <FormattedDate date={deferral.documentDate} />
            )
        },
        // document number
        {
            kind: ColumnType.text,
            render: (deferral: Deferral) => (
                <Typography>{deferral.documentNumber}</Typography>
            )
        },
        // reason
        {
            kind: ColumnType.text,
            render: (deferral: Deferral) => (
                <Typography>{deferral.reason}</Typography>
            )
        },
        // account
        {
            kind: ColumnType.text,
            render: (deferral: Deferral) => (
                <Typography>{deferral.accountDesc}</Typography>
            )
        },
        // counterpart
        {
            kind: ColumnType.text,
            render: (deferral: Deferral) => (
                <Typography>{fsDeferralType(deferral.deferralType)}</Typography>
            )
        },
        // competence period start and end
        {
            kind: ColumnType.text,
            render: (deferral: Deferral) => {
                const startDate = deferral.startEndCompetence[0];
                const endDate = deferral.startEndCompetence[1];
                return (
                    <Stack>
                        {!isNil(startDate) && (
                            <FormattedDate date={startDate} />
                        )}
                        {!isNil(endDate) && <FormattedDate date={endDate} />}
                    </Stack>
                );
            }
        },
        // amount
        {
            kind: ColumnType.numeric,
            render: (deferral: Deferral) => (
                <FormattedAmount amount={deferral.amount} currency="EUR" />
            )
        },
        // days in the period
        {
            kind: ColumnType.numeric,
            render: (deferral: Deferral) => (
                <Typography>{deferral.accruedDays}</Typography>
            )
        },
        // "giorni comp. success"
        {
            kind: ColumnType.numeric,
            render: (deferral: Deferral) => (
                <Typography>{deferral.days}</Typography>
            )
        },
        // value
        {
            kind: ColumnType.numeric,
            render: (deferral: Deferral) => (
                <FormattedAmount
                    amount={deferral.accruedAmount}
                    currency="EUR"
                />
            )
        },
        // cta
        {
            kind: ColumnType.cta,
            render: (deferral: Deferral) => (
                <IconButton
                    size="small"
                    color="primary"
                    onClick={() =>
                        handleDeferralArrowClick(deferral.originalEntryId)
                    }
                >
                    <FontAwesomeIcon
                        icon={
                            loading &&
                            deferral.originalEntryId === deferralEntryId
                                ? faSpinner
                                : faArrowRight
                        }
                        spin={
                            loading &&
                            deferral.originalEntryId === deferralEntryId
                        }
                    />
                </IconButton>
            )
        }
    ];

    return (
        <Table
            headCells={headCells}
            columns={columns}
            data={deferrals.deferralList}
        />
    );
}
