import { Spinner } from "@comic/precog-components";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import usePrevious from "../../../../../../core/commons/hooks/usePrevious";

import { VatSettlementEntry } from "../../../../../../core/usecases/dtos/VatSettlementDto";
import { useGetVatEntries } from "../../../../../../core/usecases/useGetVatEntries";
import { JournalType } from "../../../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../../../utils/getFormattedStringWithScope";
import NotFoundBanner from "../../../../../components/NotFoundBanner";
import Summary from "../MonoActivity/summary";
import "./style.css";
import VATSettlementDetailTable from "./Table";

interface VATSettlementDetailProps {
    businessId: string;
    year: number;
    period: string;
    vatCode: string;
    journalType: JournalType;
    settlement: VatSettlementEntry[];
}
const fs = getFormattedStringWithScope("views.VATSettlement.vatEntries.table");

export default function VATSettlementDetail({
    businessId,
    year,
    period,
    vatCode,
    journalType,
    settlement
}: VATSettlementDetailProps) {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const previousPeriod = usePrevious(period);

    const {
        fetch: fetchVatEntries,
        loading,
        error,
        vatEntries,
        hasFetched
    } = useGetVatEntries({
        businessId: businessId,
        year: year,
        period: period,
        vatCode: vatCode,
        journalType: journalType,
        lazy: true
    });

    useEffect(() => {
        if (!hasInitialized) {
            fetchVatEntries();
            setHasInitialized(true);
        } else if (previousPeriod !== period) {
            fetchVatEntries();
        }
    }, [hasInitialized, fetchVatEntries, previousPeriod, period]);

    return (
        <div className="v-VATSettlementDetail-content">
            {settlement && settlement.length > 0 && (
                <Summary selectedSettlement={settlement?.[0]} />
            )}
            <Spinner loading={loading}>
                {!settlement ||
                    (hasFetched && isEmpty(vatEntries) && (
                        <NotFoundBanner text={fs("empty")} />
                    ))}
                {hasFetched && error && <NotFoundBanner text={fs("error")} />}
                {settlement && vatEntries && !isEmpty(vatEntries) && (
                    <VATSettlementDetailTable data={vatEntries} />
                )}
            </Spinner>
        </div>
    );
}
