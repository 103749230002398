import { styled } from "@mui/system";
import { Autocomplete } from "@vapor/react-material";
import { MenuItem } from "@vapor/react-material";

export const TransparentAutoComplete = styled(Autocomplete)({
    width: "192px",
    "*": {
        background: "transparent !important"
    },
    "& .MuiAutocomplete-inputRoot": {
        padding: "0px 0px 0px 6px!important"
    },
    " & .MuiAutocomplete-endAdornment": {
        display: "none"
    },
    "& fieldset": {
        border: "none"
    },
    // overwrite background for readonly inputs
    "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused:read-only":
        {
            background: "transparent !important"
        }
});

export const MenuGroupTitle = styled(MenuItem)({
    paddingLeft: "8px !important",
    opacity: "1 !important"
});
