import { Grid, Stack } from "@vapor/react-material";
import { find } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { RegistersProvider } from "../../../core/contexts/Registers";
import { useAccountingPeriods } from "../../../core/domain/AccountingPeriods/queries";
import { AccountingPeriodDto } from "../../../core/usecases/dtos/AccountingPeriodsDto";
import { TaskId } from "../../../core/usecases/dtos/AccountingPeriodTasksDto";
import AccountingPeriodCard from "./AccountingPeriodCard";
import AccountingPeriodDetail from "./AccountingPeriodDetail";
import CloseAndReopenAccountsOverlay from "./CloseAndReopenAccounts/CloseAndReopenAccountsOverlay";
import DeferredRevenuesAndExpensesOverlay from "./DeferredRevenuesAndExpensesOverlay";
import DepreciationOverlay from "./Depreciation/DepreciationOverlay";
import Registers from "./Registers";
import AccountingPeriodTasks from "./Task/AccountingPeriodTasks";

interface AccountingPeriodsNavigationProps {
    accountingPeriods: AccountingPeriodDto[];
}

export default function AccountingPeriodsNavigation({
    accountingPeriods
}: AccountingPeriodsNavigationProps) {
    const sortedAccountingPeriods = useMemo(() => {
        return [...accountingPeriods].sort((a, b) => {
            return (
                new Date(b.startDate).getTime() -
                new Date(a.startDate).getTime()
            );
        });
    }, [accountingPeriods]);

    const [selectedAccountingPeriod, setSelectedAccountingPeriod] = useState(
        sortedAccountingPeriods[0]
    );

    const [
        deferredRevenuesAndExpensesOverlayOpen,
        setDeferredRevenuesAndExpensesOverlayOpen
    ] = useState(false);

    const [depreciationOverlayOpen, setDepreciationOverlayOpen] =
        useState(false);
    const [
        closeAndReopenAccountsOverlayOpen,
        setCloseAndReopenAccountsOverlayOpen
    ] = useState(false);

    const {
        refetch,
        data: { tasks: accountingPeriodTasks } = {},
        isLoading: loadingAccountingPeriodTasks,
        error: errorFetchingAccountingPeriodTasks
    } = useAccountingPeriods(selectedAccountingPeriod?.id);

    const handleOpenDeferredRevenuesAndExpenses = () => {
        setDeferredRevenuesAndExpensesOverlayOpen(true);
    };

    const handleAllocateGain = async () => {
        if (selectedAccountingPeriod) await refetch();
    };

    useEffect(() => {
        setSelectedAccountingPeriod(sortedAccountingPeriods[0]);
    }, [sortedAccountingPeriods]);

    const accountCloseOpen = find(
        accountingPeriodTasks,
        (accountTask) => accountTask.taskId === TaskId.CLOSING_OPENING_ACCOUNTS
    );

    const depreciation = find(
        accountingPeriodTasks,
        (accountTask) => accountTask.taskId === TaskId.DEPRECIATION
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={5}>
                <Stack rowGap={2}>
                    {sortedAccountingPeriods?.length > 0 &&
                        sortedAccountingPeriods.map((period) => {
                            return (
                                <AccountingPeriodCard
                                    key={period.id}
                                    accountingPeriod={period}
                                    isSelected={
                                        period.id ===
                                        selectedAccountingPeriod?.id
                                    }
                                    onClickCard={(
                                        period: AccountingPeriodDto
                                    ) => setSelectedAccountingPeriod(period)}
                                />
                            );
                        })}
                </Stack>
            </Grid>
            <Grid item xs={7}>
                <AccountingPeriodDetail
                    closingOperations={
                        <AccountingPeriodTasks
                            accountingPeriodTasks={accountingPeriodTasks ?? []}
                            errorLoadingTasks={
                                errorFetchingAccountingPeriodTasks
                            }
                            isLoadingTasks={loadingAccountingPeriodTasks}
                            onAllocateGain={handleAllocateGain}
                            onOpenDeferredRevenuesAndExpenses={
                                handleOpenDeferredRevenuesAndExpenses
                            }
                            onOpenDepreciation={() =>
                                setDepreciationOverlayOpen(true)
                            }
                            onOpenPeriod={() =>
                                setCloseAndReopenAccountsOverlayOpen(true)
                            }
                            sequenceNumber={
                                selectedAccountingPeriod.sequenceNumber
                            }
                        />
                    }
                    registers={
                        <RegistersProvider
                            year={new Date(selectedAccountingPeriod.startDate)
                                .getFullYear()
                                .toString()}
                        >
                            <Registers />
                        </RegistersProvider>
                    }
                />
            </Grid>

            <DeferredRevenuesAndExpensesOverlay
                isOpen={deferredRevenuesAndExpensesOverlayOpen}
                onClose={() => setDeferredRevenuesAndExpensesOverlayOpen(false)}
                periodId={selectedAccountingPeriod?.id ?? null}
            />
            {depreciationOverlayOpen && depreciation && (
                <DepreciationOverlay
                    isOpen={depreciationOverlayOpen}
                    onClose={() => setDepreciationOverlayOpen(false)}
                    depreciationStatus={depreciation?.state}
                    selectedAccountingPeriod={selectedAccountingPeriod}
                />
            )}
            {closeAndReopenAccountsOverlayOpen && (
                <CloseAndReopenAccountsOverlay
                    isOpen={closeAndReopenAccountsOverlayOpen}
                    onClose={() => setCloseAndReopenAccountsOverlayOpen(false)}
                    selectedAccountingPeriod={selectedAccountingPeriod}
                    statusTask={accountCloseOpen?.state}
                />
            )}
        </Grid>
    );
}
