import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@vapor/react-material/Box";
import FormControl from "@vapor/react-material/FormControl";
import InputLabel from "@vapor/react-material/InputLabel";
import Tooltip from "@vapor/react-material/Tooltip";
import { DatePicker } from "@vapor/react-x-date-pickers/DatePicker";

interface DrawerDatePickerProps {
    label?: string | null;
    date?: Date | null;
    onChangeDate: Function;
    isRequired?: boolean;
    errorDate?: boolean;
    showIcon: boolean;
    tooltipTitle?: string | null;
}

export default function DrawerDatePicker({
    label,
    date,
    onChangeDate,
    isRequired,
    errorDate,
    showIcon,
    tooltipTitle
}: DrawerDatePickerProps) {
    const handleDateChange = (date: Date) => {
        onChangeDate(date);
    };
    return (
        <FormControl
            sx={{ width: "100%" }}
            required={isRequired}
            error={errorDate}
        >
            <Box display="flex" justifyContent="space-between">
                <InputLabel>{label}</InputLabel>
                {showIcon && (
                    <Tooltip arrow placement="top" title={tooltipTitle}>
                        <FontAwesomeIcon
                            className="v-AssetsListTable-icon"
                            icon={faCircleInfo}
                        />
                    </Tooltip>
                )}
            </Box>
            <DatePicker
                value={date ? new Date(date) : null}
                onChange={(date: any) => {
                    handleDateChange(date);
                }}
            />
        </FormControl>
    );
}
