import { useRequest } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface UseDeleteAccountingLine {
    loading: boolean;
    error: any;
    deleteAccountingLine: (draftId: string, id: string) => Promise<void>;
}

export const useDeleteAccountingLine = (): UseDeleteAccountingLine => {
    const customerData = useCustomerData();

    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope(
        "notifications.DeleteAccountingLine"
    );

    const {
        fetch: fetchData,
        loading,
        error
    } = useRequest({
        ...useAxiosConfigRequestParams("AccountingLine"),
        method: "delete",
        url: `ignored`,
        lazy: true,
        onError: (error: any) =>
            enqueueSnackbar(
                { title: fs("error"), content: error.response.data.message },
                { severity: "error" }
            )
    });

    const deleteAccountingLine = async (draftId: string, id: string) => {
        return fetchData({
            url: `${API_URL}/v2/precog/drafts/${draftId}/accountingLines/single/${id}`,
            params: {
                ...customerData
            }
        });
    };

    return {
        deleteAccountingLine,
        loading: loading,
        error: error
    };
};
