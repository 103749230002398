import { useRequest, useSnackbar } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseRestoreDraft {
    loading: boolean;
    restore: (draftId: string, from?: "PARENT" | "CLONE") => Promise<string>;
}

export const useRestoreDraft = (): UseRestoreDraft => {
    const { enqueueSnackbar } = useSnackbar();
    const fs = useFormattedStringWithScope("notifications.RestoreDraft");
    const customerData = useCustomerData();

    const { fetch, loading } = useRequest({
        method: "POST",
        ...useAxiosConfigRequestParams("RestoreDraft"),
        onError: () => {
            enqueueSnackbar(fs("error"), {
                variant: "error"
            });
        },
        lazy: true
    });

    const restore = async (
        draftId: string,
        from: "PARENT" | "CLONE" = "CLONE"
    ) => {
        return fetch({
            url: `${API_URL}/v1/precog/drafts/${draftId}/restore`,
            params: {
                ...customerData,
                restoreType: from === "PARENT" ? "BY_PARENT_ID" : "BY_CLONE_ID"
            }
        }).then((response: AxiosResponse<string>) => response.data);
    };

    return {
        restore,
        loading: loading
    };
};
