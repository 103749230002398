import { VaporIcon } from "@vapor/react-icons";
import { Box, IconButton, Stack } from "@vapor/react-material";

import FormCurrencyField from "../../FormFields/FormCurrencyField";
import FormDatePicker from "../../FormFields/FormDatePicker";
import FormSelectAccount from "../../FormFields/FormSelectAccount";

interface PaymentRowProps {
    refCurrency: string;
    index: number;
    showDeleteButton: boolean;
    onDelete: (index: number) => void;
}

export default function PaymentRow({
    refCurrency,
    index,
    onDelete,
    showDeleteButton
}: PaymentRowProps) {
    return (
        <Stack
            marginTop="16px"
            marginX="16px"
            spacing={2}
            direction="row"
            alignItems="flex-end"
            justifyContent="center"
        >
            <Box minWidth="152px">
                <FormDatePicker
                    name={`payments.${index}.date`}
                    label="Data di pagamento"
                    required={true}
                    disabled
                />
            </Box>
            <Box minWidth="200px">
                <FormCurrencyField
                    name={`payments.${index}.amount`}
                    label="Importo"
                    required={true}
                    currency={refCurrency}
                    validate={(value) => value !== 0}
                />
            </Box>
            <Box minWidth="245px">
                <FormSelectAccount
                    name={`payments.${index}.account`}
                    label="Conto contabile"
                    required={true}
                />
            </Box>
            {showDeleteButton ? (
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => onDelete(index)}
                    sx={{
                        marginBottom: "8px !important"
                    }}
                >
                    <VaporIcon color="primary" iconName="trash-alt" />
                </IconButton>
            ) : (
                <Box minWidth="32px"></Box>
            )}
        </Stack>
    );
}
