import Button from "@vapor/react-material/Button";
import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { faAngleLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AssetRelationsDto } from "../../../../../core/usecases/dtos/AssetRelationsDto";
import getFormattedMessageWithScope from "../../../../../utils/getFormattedMessageWithScope";

const fs = getFormattedMessageWithScope("views.ListAssets.accountAssetDetail");

interface AccountRelationsTabProps {
    data: AssetRelationsDto | null;
    openAccountRelations: boolean;
    onOpenAccountRelationsTab: () => void | undefined;
}

export default function AccountRelationsTab({
    data,
    onOpenAccountRelationsTab
}: AccountRelationsTabProps) {
    return (
        <Box marginTop="30px" marginLeft="38px">
            <Button
                onClick={() => {
                    onOpenAccountRelationsTab();
                }}
            >
                <Box display="flex" justifyContent="space-between">
                    <FontAwesomeIcon
                        color="#005075"
                        icon={faAngleLeft}
                        style={{ marginTop: "2px", marginRight: "5px" }}
                    />
                    <Typography color="primary.textTitleColor" variant="body">
                        {data?.accountDescription}
                    </Typography>
                </Box>
            </Button>
            <Box marginLeft="10px" marginTop="20px">
                {data?.relatedAccountsDetails.map((relation) => (
                    <Stack>
                        <Typography variant="caption">
                            {fs(`names.${relation?.name}`)}
                        </Typography>
                        <Typography variant="body700" marginBottom="10px">
                            {relation?.description}
                        </Typography>
                    </Stack>
                ))}
            </Box>
        </Box>
    );
}
