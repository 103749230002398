import { useGet } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { ActionsDto } from "../dtos/ActionsDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

export interface UseGetSetupActions {
    fetch: () => Promise<ActionsDto>;
}

export default function useGetSetupActions(): UseGetSetupActions {
    const fs = useFormattedStringWithScope("notifications.GetSetupActions");
    const { enqueueSnackbar } = useSnackbar();

    const { fetch } = useGet(`${ASYNC_API_URL}/setup/actions`, {
        ...useAxiosConfigRequestParams("useGetSetupActions"),
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        params: omitNilProperties({
            ...useCustomerData()
        }),
        lazy: true
    });

    return {
        fetch: async () => {
            return fetch().then(
                (response: AxiosResponse<ActionsDto>) => response.data
            );
        }
    };
}
