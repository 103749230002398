import { useRequest } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface UsePostDraftConfirm {
    loading: boolean;
    error: any;
    result: any;
    confirm: (draftId: string, revision?: string) => Promise<void>;
    confirmMultiple: (draftId: string[]) => Promise<void>;
}

interface UsePostDraftConfirmOptions {
    draftId?: string | null;
    lazy: boolean;
}

export const usePostDraftConfirm = ({
    draftId,
    lazy
}: UsePostDraftConfirmOptions): UsePostDraftConfirm => {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.PostDraftConfirm");

    const customerData = useCustomerData();

    const { fetch, data, loading, error } = useRequest({
        ...useAxiosConfigRequestParams("DraftConfirm"),
        method: "post",
        url: `${API_URL}/v1/precog/drafts/${draftId}/confirm`,
        params: omitNilProperties({
            ...useCustomerData()
        }),
        onError: () =>
            enqueueSnackbar(fs("error"), {
                severity: "error"
            }),
        onResponse: () => {
            enqueueSnackbar(fs("success"), { severity: "success" });
        },
        lazy: lazy
    });

    const confirm = async (draftId: string | string[], revision?: string) => {
        return fetch({
            url: `${API_URL}/v1/precog/drafts/${draftId}/confirm`,
            params: omitNilProperties({
                ...customerData,
                revision
            })
        });
    };

    const confirmMultiple = async (draftId: string[]) => {
        return fetch({
            url: `${API_URL}/v1/precog/drafts/confirm-multiple`,
            params: omitNilProperties({
                ...customerData
            }),
            data: {
                draftIdList: draftId
            }
        });
    };

    return {
        confirm,
        confirmMultiple,
        loading: loading,
        error: error,
        result: data
    };
};
