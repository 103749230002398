import VaporHeaderBar from "@vapor/react-custom/VaporHeaderBar";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import ExtendedTab from "@vapor/react-extended/ExtendedTab";
import ExtendedTabs from "@vapor/react-extended/ExtendedTabs";
import Times from "@vapor/react-icons/Times";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Drawer from "@vapor/react-material/Drawer";
import IconButton from "@vapor/react-material/IconButton";
import { useEffect, useState } from "react";

import { useWithholdings } from "../../../../../core/contexts/Withholdings";
import { Withholding } from "../../../../../core/usecases/dtos/WithholdingsDto";
import {
    WithholdingDrawerTab,
    WithholdingType
} from "../../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import ErrorBanner from "../../../../components/ErrorBanner";
import InpsTab from "./inpsTab";
import ManagementTab from "./managementTab";

const fs = getFormattedStringWithScope("views.Withholdings.drawer");

interface WithhodlingsDrawerProps {
    open: boolean;
    onClose: () => void;
    selectedWithholding: Withholding | null;
    withholdingType: WithholdingType;
}

export default function WithhodlingsDrawer({
    open,
    onClose,
    selectedWithholding,
    withholdingType
}: WithhodlingsDrawerProps) {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<WithholdingDrawerTab>(
        WithholdingDrawerTab.management
    );

    const { getDetail, withholdingDetails, errorLoadingDetail } =
        useWithholdings().accounting;

    useEffect(() => {
        if (!hasInitialized) {
            if (selectedWithholding) {
                getDetail(withholdingType, selectedWithholding.uuid);
            }
            setHasInitialized(true);
        }
    }, [hasInitialized, selectedWithholding, withholdingType, getDetail]);

    const handleChangeTab = (_event: React.SyntheticEvent, newTab: string) => {
        setSelectedTab(newTab as WithholdingDrawerTab);
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            hideBackdrop
            width={720}
        >
            <Box>
                <VaporHeaderBar
                    divider={true}
                    rightItems={
                        <IconButton onClick={onClose} color="primary">
                            <Times />
                        </IconButton>
                    }
                    title={fs("title")}
                    size="small"
                />
                {errorLoadingDetail ? (
                    <ErrorBanner error={errorLoadingDetail} />
                ) : (
                    <Box>
                        <ExtendedTabs
                            size="small"
                            secondary={true}
                            value={selectedTab}
                            onChange={handleChangeTab}
                        >
                            <ExtendedTab
                                value={WithholdingDrawerTab.management}
                                label={fs("managementLabel")}
                            />
                            {withholdingDetails?.hasInps && (
                                <ExtendedTab
                                    value={WithholdingDrawerTab.inps}
                                    label={fs("inpsLabel")}
                                />
                            )}
                        </ExtendedTabs>
                        {selectedTab === WithholdingDrawerTab.management && (
                            <ManagementTab
                                withholdingDetails={withholdingDetails}
                            />
                        )}
                        {selectedTab === WithholdingDrawerTab.inps && (
                            <InpsTab withholdingDetails={withholdingDetails} />
                        )}
                    </Box>
                )}
                <VaporToolbar
                    variant="surface"
                    contentRight={[
                        <Button variant="outlined" onClick={onClose}>
                            {fs("closeButton")}
                        </Button>
                    ]}
                />
            </Box>
        </Drawer>
    );
}
