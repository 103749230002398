import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { ReasonDto } from "../dtos/ReasonsListDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseGetReasonsOptions {
    factType?: string;
    saleOrPurchse?: string;
}

export type GetReasonsParams = UseGetReasonsOptions;

export const useGetReasons = () => {
    const customerData = useCustomerData();

    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/precog/reasons`,
        {
            ...useAxiosConfigRequestParams("ReasonsList"),
            lazy: true
        }
    );

    return {
        fetch: async (params?: GetReasonsParams) =>
            fetch({
                params: omitNilProperties({
                    ...customerData,
                    classification: params?.factType
                        ? params.factType
                        : "INVOICE, BANK_TRANSACTION, RECEIPT",
                    type: params?.saleOrPurchse
                })
            }).then(
                (response: AxiosResponse<ReasonDto[]>) => response.data ?? []
            ) as Promise<ReasonDto[]>,
        loading: loading,
        error: error,
        reasonsList: data,
        hasFetched: hasLoaded
    };
};
