import { Spinner } from "@comic/precog-components";
import { useCurrentWorkspace } from "@drift/oneplatfront";
import Typography from "@vapor/react-extended/ExtendedTypography";
import CardContent from "@vapor/react-material/CardContent";
import Link from "@vapor/react-material/Link";
import Stack from "@vapor/react-material/Stack";
import { useEffect, useState } from "react";

import { useGetHighlights } from "../../../core/usecases/useGetHighlights";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import FormattedDate from "../../components/FormattedDate";
import WidgetCard from "../../components/WidgetCard";
import usePrevious from "../../../core/commons/hooks/usePrevious";

const fs = getFormattedStringWithScope("widgets.Highlight");

export default function Highlight() {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);

    const {
        highlights,
        loading: loadingHighlights,
        error: errorFetchingHighLights,
        fetch: fetchHighlights
    } = useGetHighlights({
        lazy: true
    });

    const { workspace: customer } = useCurrentWorkspace(true);
    const previousCustomer = usePrevious(customer);

    useEffect(() => {
        if (hasInitialized && customer !== previousCustomer) {
            setHasInitialized(false);
        }
    }, [customer, previousCustomer, hasInitialized]);

    useEffect(() => {
        if (customer && !hasInitialized) {
            fetchHighlights();
            setHasInitialized(true);
        }
    }, [hasInitialized, fetchHighlights, customer]);

    const featuredDrafts = highlights?.featuredDrafts;

    return (
        <Spinner loading={loadingHighlights}>
            <WidgetCard error={errorFetchingHighLights}>
                <CardContent>
                    {!loadingHighlights && highlights ? (
                        <>
                            <Typography variant="titleMedium">
                                {fs("title")}
                            </Typography>
                            <Stack marginTop="16px">
                                <Stack marginBottom="16px">
                                    <Typography variant="boldLabel">
                                        {fs("lastRegistrationDate")}
                                    </Typography>
                                    {highlights?.lastRegistrationDate ? (
                                        <FormattedDate
                                            date={
                                                highlights?.lastRegistrationDate
                                            }
                                            variant="bodySmall500"
                                        />
                                    ) : null}
                                </Stack>
                                <Stack>
                                    <Typography
                                        variant="boldLabel"
                                        component="div"
                                        gutterBottom
                                    >
                                        {fs("documentsContaining")}
                                    </Typography>
                                    {featuredDrafts?.withWithHoldings ? (
                                        <Typography
                                            variant="boldLabel"
                                            component="div"
                                            gutterBottom
                                        >
                                            {fs("witholdings")} (
                                            <Link>
                                                {
                                                    featuredDrafts?.withWithHoldings
                                                }
                                            </Link>
                                            )
                                        </Typography>
                                    ) : null}
                                    {featuredDrafts?.withIntrastat ? (
                                        <Typography
                                            variant="boldLabel"
                                            component="div"
                                            gutterBottom
                                        >
                                            {fs("intrastat")} (
                                            <Link>
                                                {featuredDrafts?.withIntrastat}
                                            </Link>
                                            )
                                        </Typography>
                                    ) : null}
                                    {featuredDrafts?.withAssets ? (
                                        <Typography
                                            variant="boldLabel"
                                            component="div"
                                            gutterBottom
                                        >
                                            {fs("assets")} (
                                            <Link>
                                                {featuredDrafts?.withAssets}
                                            </Link>
                                            )
                                        </Typography>
                                    ) : null}
                                </Stack>
                            </Stack>
                        </>
                    ) : null}
                </CardContent>
            </WidgetCard>
        </Spinner>
    );
}
