import { VaporToolbar } from "@vapor/react-custom";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    Stack,
    Table,
    TableCell,
    TableRow,
    TextField
} from "@vapor/react-material";
import { capitalize, every, isEmpty, isNil, xor } from "lodash";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import {
    useAccountingLines,
    useMutateAccountingLine
} from "../../../../../core/domain/AccountingLines/queries";
import { useDraft } from "../../../../../core/domain/Draft/queries";
import { useVatDestinations } from "../../../../../core/domain/VatDestinations/queries";
import { AccountDto } from "../../../../../core/usecases/dtos/AccountsDto";
import { ExemptionNature } from "../../../../../core/usecases/dtos/ExemptionNaturesDto";
import { VatDestination } from "../../../../../core/usecases/dtos/VatDestinationsDto";
import { VatRegime } from "../../../../../core/usecases/dtos/VatRegimesDto";
import { VatRate } from "../../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import omitNilProperties from "../../../../../utils/omitNilProperties";
import CustomButton from "../../../CustomButton";
import FormattedAmount from "../../../FormattedAmount";
import FormSelectAccount from "../../../FormFields/FormSelectAccount";
import FormSelectExemption, {
    MockExemptionNatureToOpenDrawer
} from "../../../FormFields/FormSelectExemption";
import FormSelectVatDestination from "../../../FormFields/FormSelectVatDestination";
import FormSelectVatRegime from "../../../FormFields/FormSelectVatRegime";

import { FormExemptionNaureDialog } from "./styled";

interface FormField {
    account?: AccountDto | null;
    vatRegime?: VatRegime | null;
    regimeExtraField?: string;
    vatRate?: VatRate | null;
    vatDestination: VatDestination | null;
    exemptionNature?: ExemptionNature | null;
}

interface ArticlesProps {
    draftId: string;
    accountCode: string;
    accountDescription?: string;
    readOnly?: boolean;
}

const fs = getFormattedStringWithScope(
    "components.Accounting.AccountingLinesTable.AccountDetailDrawer.Articles"
);

export default function Articles({
    draftId,
    accountCode,
    accountDescription,
    readOnly
}: ArticlesProps) {
    const [selectedArticlesIds, setSelectedArticlesIds] = useState<string[]>(
        []
    );

    const { data: draft, isSuccess: isDraftSuccess } = useDraft(draftId);
    const { data: accountingLines, isSuccess: isAccountingLinesSuccess } =
        useAccountingLines({ draftId });
    const { mutate, isPending } = useMutateAccountingLine({
        accountingLineId: selectedArticlesIds,
        draftId
    });
    const { data: vatDestinations } = useVatDestinations(draft?.reasonId);

    const [isExemptionNatureModalOpen, setIsExemptionNatureModalOpen] =
        useState<boolean>(false);

    const methods = useForm<FormField>({
        defaultValues: {
            account: null,
            vatDestination: null,
            vatRate: null,
            vatRegime: null,
            exemptionNature: null
        }
    });

    const exemptionNature = methods.watch("exemptionNature");

    useEffect(() => {
        if (
            !isExemptionNatureModalOpen &&
            exemptionNature?.id === MockExemptionNatureToOpenDrawer.id
        ) {
            setIsExemptionNatureModalOpen(true);
        }
    }, [exemptionNature, isExemptionNatureModalOpen]);

    if (isDraftSuccess && isAccountingLinesSuccess) {
        const { refCurrency } = draft;

        const onSubmit: SubmitHandler<FormField> = async (data) => {
            mutate(
                omitNilProperties({
                    accountCode: data.account?.code,
                    regimeId: data.vatRegime?.id,
                    vatRate: data.vatRate,
                    vatDestinationId: data.vatDestination?.id,
                    regimeExtraField: data.regimeExtraField
                })
            );
        };

        const noArticleSelected = isEmpty(selectedArticlesIds);

        const articles = accountingLines.filter(
            (accountingLine) => accountingLine.accountCode === accountCode
        );

        const allArticlesHaveZeroVatRate =
            !isEmpty(selectedArticlesIds) &&
            every(
                accountingLines.filter(
                    (accountingLine) =>
                        accountingLine.uuid &&
                        selectedArticlesIds.includes(accountingLine.uuid)
                ),
                (accountingLine) => accountingLine.vatRate === VatRate["0.0"]
            );

        const notPostingAccountCodes = accountingLines
            .filter((accountingLine) => accountingLine.isReadOnly)
            .map((accountingLine) => accountingLine.accountCode);

        const selectAllArticles = () => {
            if (isEmpty(selectedArticlesIds))
                setSelectedArticlesIds(
                    articles.map((article) => article.uuid!)
                );
            else setSelectedArticlesIds([]);
        };

        const handleToggleArticle = (articleId: string) => {
            setSelectedArticlesIds((prevSelectedArtticlesIds) =>
                xor(prevSelectedArtticlesIds, [articleId])
            );
        };

        const handleSelectExemptionFromDialog = (
            exemptionNature: ExemptionNature
        ) => {
            methods.setValue("exemptionNature", exemptionNature);
        };

        return (
            <>
                <Typography variant="titleSmall" color="#005075">
                    {capitalize(accountDescription)}
                </Typography>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Stack
                            direction="row"
                            width="100%"
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <FormSelectAccount
                                name="account"
                                required={true}
                                validate={(account) => {
                                    return isNil(account)
                                        ? true
                                        : account.code === accountCode
                                        ? "No stesso conto"
                                        : notPostingAccountCodes.includes(
                                              account.code
                                          )
                                        ? "No conto di sistema"
                                        : true;
                                }}
                                label={fs("moveTo")}
                                disabled={noArticleSelected || readOnly}
                            />
                            <FormSelectVatRegime
                                name="vatRegime"
                                required={false}
                                label={fs("changeVatRegime")}
                                disabled={noArticleSelected || readOnly}
                            />
                            {allArticlesHaveZeroVatRate ? (
                                <>
                                    <Box minWidth="80px">
                                        <FormControl>
                                            <InputLabel disabled>
                                                {fs("vatRate")}
                                            </InputLabel>
                                            <TextField
                                                readOnly
                                                disabled
                                                value="0 %"
                                                sx={{
                                                    "& input": {
                                                        textAlign: "right"
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box minWidth="174px">
                                        <FormSelectExemption
                                            name="exemptionNature"
                                            label={fs("exemption")}
                                            required={false}
                                            disabled={
                                                noArticleSelected || readOnly
                                            }
                                        />
                                    </Box>
                                </>
                            ) : null}
                            {exemptionNature &&
                                exemptionNature?.id ===
                                    MockExemptionNatureToOpenDrawer.id && (
                                    <FormExemptionNaureDialog
                                        draftId={draftId}
                                        handleSelectExemption={
                                            handleSelectExemptionFromDialog
                                        }
                                        isModalOpen={
                                            exemptionNature.id ===
                                            MockExemptionNatureToOpenDrawer.id
                                        }
                                        onClose={(hasSelected) => {
                                            if (!hasSelected) {
                                                methods.setValue(
                                                    "exemptionNature",
                                                    null
                                                );
                                            }
                                        }}
                                        exemptionNature={exemptionNature.id}
                                    />
                                )}
                            <FormSelectVatDestination
                                name="vatDestination"
                                required={false}
                                label={fs("vatDestination")}
                                disabled={noArticleSelected || readOnly}
                                disablePortal={true}
                            />
                        </Stack>
                        <Box marginLeft="-16px">
                            <VaporToolbar
                                contentRight={
                                    readOnly ? null : (
                                        <CustomButton
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={noArticleSelected}
                                            loading={isPending}
                                        >
                                            {fs("save")}
                                        </CustomButton>
                                    )
                                }
                            />
                        </Box>
                    </form>
                </FormProvider>
                {readOnly ? null : (
                    <FormControlLabel
                        control={<Checkbox />}
                        label={fs("selectAll")}
                        onChange={selectAllArticles}
                        checked={selectedArticlesIds.length === articles.length}
                        sx={{ marginLeft: "16px" }}
                    />
                )}
                <Table>
                    {articles.map((article, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Stack direction="row" alignItems="center">
                                    {readOnly ? null : (
                                        <Checkbox
                                            checked={
                                                !isNil(article.uuid) &&
                                                selectedArticlesIds.includes(
                                                    article.uuid
                                                )
                                            }
                                            onClick={() =>
                                                article.uuid &&
                                                handleToggleArticle(
                                                    article.uuid
                                                )
                                            }
                                        />
                                    )}
                                    {article.articleDesc}
                                </Stack>
                            </TableCell>
                            <TableCell>
                                {
                                    vatDestinations?.find(
                                        (vatDestination) =>
                                            vatDestination.id ===
                                            article.vatDestinationId
                                    )?.description
                                }
                            </TableCell>
                            <TableCell>
                                <FormattedAmount
                                    amount={
                                        article.debit !== 0
                                            ? article.debit ?? 0
                                            : article.credit !== 0
                                            ? article.credit ?? 0
                                            : 0
                                    }
                                    currency={refCurrency}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </Table>
            </>
        );
    }

    return null;
}
