import {
    Autocomplete,
    FormControl,
    InputLabel,
    TextField
} from "@vapor/react-material";
import { isNil } from "lodash";

import { BusinessDto } from "../../../core/usecases/dtos/BusinessDto";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";

interface SelectBusinessProps {
    businesses: BusinessDto[];
    selectedBusiness: BusinessDto | null;
    onSelectBusiness: (business: BusinessDto) => void;
}

const fs = getFormattedStringWithScope("views.SettleAccount");

export default function SelectBusiness({
    businesses,
    selectedBusiness,
    onSelectBusiness
}: SelectBusinessProps) {
    return (
        <FormControl
            error={isNil(selectedBusiness)}
            sx={{ width: "400px" }}
            required
        >
            <InputLabel>{fs("selectActivity")}</InputLabel>
            <Autocomplete
                options={businesses}
                renderInput={(params) => <TextField {...params} />}
                getOptionLabel={(option) => (option as BusinessDto).description}
                value={selectedBusiness}
                onChange={(_, value) => onSelectBusiness(value as BusinessDto)}
                sx={{ width: "100%" }}
            />
        </FormControl>
    );
}
