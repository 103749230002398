import { AccountingLine } from "../core/usecases/dtos/AccountingLinesDto";

export function sortF24Lines(lines: AccountingLine[]) {
    return lines.sort((a, b) => {
        if (a?.tributeCode === null) {
            return -1;
        }
        if (b?.tributeCode === null) {
            return 1;
        }

        return 0;
    });
}
