import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Divider from "@vapor/react-material/Divider";
import Stack from "@vapor/react-material/Stack";
import TextField from "@vapor/react-material/TextField";
import Tooltip from "@vapor/react-material/Tooltip";
import { DatePicker } from "@vapor/react-x-date-pickers";

import { WithholdingDetails } from "../../../../../core/usecases/dtos/WithholdingDetailsDto";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../components/FormattedAmount";
import FormattedPercentage from "../../../../components/FormattedPercentage";
import { isNil, isNumber } from "lodash";

const fs = getFormattedStringWithScope("views.Withholdings.drawer.inps");

interface InpsProps {
    withholdingDetails: WithholdingDetails | null;
}

export default function InpsTab({ withholdingDetails }: InpsProps) {
    return (
        <Box>
            <Box marginLeft="30px" display="flex">
                <Stack marginTop="45px" width="100%">
                    <Box width="300px">
                        <TextField
                            fullWidth
                            label={
                                <Typography variant="body500">
                                    {fs("reason")}
                                </Typography>
                            }
                        />
                    </Box>
                    <Box display="flex" marginTop="24px">
                        <Typography variant="body" marginRight="5px">
                            {fs("cuReason")}:
                        </Typography>
                        <Typography variant="body700" marginLeft="5px">
                            A
                        </Typography>
                    </Box>
                    <Box display="flex" marginTop="24px">
                        <Typography variant="body" marginRight="5px">
                            {fs("tributeCode")}:
                        </Typography>
                        <Typography variant="body700" marginLeft="5px">
                            {withholdingDetails?.inpsTributeCode}
                        </Typography>
                    </Box>
                    <Divider
                        sx={{ marginTop: 3, marginRight: 2 }}
                        orientation="horizontal"
                        flexItem
                        light
                    />
                    <Box display="flex" marginTop="24px">
                        <Typography variant="body" marginRight="5px">
                            {fs("socialInstituteTaxableAmount")}:
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            marginLeft="5px"
                            currency="EUR"
                            amount={
                                withholdingDetails?.socialInstituteTaxableAmount ??
                                0
                            }
                        />
                    </Box>
                    <Box width="300px" marginTop="26px">
                        <TextField
                            value={withholdingDetails?.alreadySubjectedAmount}
                            readOnly
                            label={
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body500">
                                        {fs("alreadySubjectedAmount")}
                                    </Typography>
                                    <Tooltip arrow placement="top" title="TODO">
                                        <FontAwesomeIcon
                                            className="v-AssetsListTable-icon"
                                            icon={faCircleInfo}
                                        />
                                    </Tooltip>
                                </Box>
                            }
                        />
                    </Box>
                    <Box display="flex" marginTop="24px">
                        <Typography variant="body" marginRight="5px">
                            {fs("socialInstituteContributionRate")}:
                        </Typography>
                        <FormattedPercentage
                            variant="body700"
                            marginLeft="5px"
                            percentage={withholdingDetails?.inpsRate ?? 0}
                        />
                    </Box>
                    <Box display="flex" marginTop="24px">
                        <Typography variant="body" marginRight="5px">
                            {fs("recipientContribution")}:
                        </Typography>
                        <FormattedAmount
                            variant="body700"
                            marginLeft="5px"
                            currency="EUR"
                            amount={
                                withholdingDetails?.recipientContribution ?? 0
                            }
                        />
                    </Box>
                    {!isNil(withholdingDetails) &&
                        isNumber(withholdingDetails?.companyContribution) && (
                            <Box display="flex" marginTop="24px">
                                <Typography variant="body" marginRight="5px">
                                    {fs("companyContribution")}:
                                </Typography>
                                <FormattedAmount
                                    variant="body700"
                                    marginLeft="5px"
                                    currency="EUR"
                                    amount={
                                        withholdingDetails?.companyContribution
                                    }
                                />
                            </Box>
                        )}
                    <Divider
                        sx={{ marginTop: 3, marginRight: 2 }}
                        orientation="horizontal"
                        flexItem
                        light
                    />
                    <Box
                        marginTop="24px"
                        display="flex"
                        justifyContent="space-between"
                        marginRight="16px"
                    >
                        <Box width="300px">
                            <DatePicker
                                label={fs("activityStartDate")}
                                value={
                                    withholdingDetails?.activityStartDate &&
                                    new Date(
                                        withholdingDetails?.activityStartDate
                                    )
                                }
                                readOnly
                            />
                        </Box>
                        <Box width="300px">
                            <DatePicker
                                label={fs("activityEndDate")}
                                value={
                                    withholdingDetails?.activityEndDate &&
                                    new Date(withholdingDetails.activityEndDate)
                                }
                                readOnly
                            />
                        </Box>
                    </Box>
                    <Box
                        marginTop="34px"
                        display="flex"
                        justifyContent="space-between"
                        marginRight="16px"
                    >
                        <Box width="300px">
                            <TextField
                                value={withholdingDetails?.emensReportType}
                                readOnly
                                fullWidth
                                label={fs("emensReportType")}
                            />
                        </Box>
                        <Box width="300px">
                            <TextField
                                value={withholdingDetails?.emensActivityCode}
                                fullWidth
                                label={fs("emensActivityCode")}
                            />
                        </Box>
                    </Box>
                    <Box width="300px" marginTop="35px" marginBottom="10px">
                        <TextField
                            value={withholdingDetails?.emensOtherInsurance}
                            fullWidth
                            label={fs("emensOtherInsurance")}
                        />
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
}
