import { useGet, useSnackbar } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { VatSettlementDto } from "../dtos/VatSettlementsDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";
import { VatSettlement } from "../interfaces/VatSettlement";

export interface UseGetSettlements {
    loading: boolean;
    error: Error;
    data: VatSettlement[];
    fetch: (
        year: number | string,
        period?: string
    ) => Promise<VatSettlementDto[]>;
    hasFetched: boolean;
}

export const useGetSettlements = (): UseGetSettlements => {
    const { enqueueSnackbar } = useSnackbar();
    const customerData = useCustomerData();
    const fs = useFormattedStringWithScope("notifications.GetSettlements");
    const {
        fetch: fetchData,
        data,
        loading,
        error,
        hasLoaded
    } = useGet(`ignored`, {
        onError: () => enqueueSnackbar(fs("error"), { variant: "error" }),
        ...useAxiosConfigRequestParams("GetSettlements"),
        lazy: true
    });

    const fetch = async (year: number | string, period?: string) => {
        return fetchData({
            url: `${ASYNC_API_URL}/vat/settlements${
                period ? `/${period}` : ""
            }`,
            params: {
                ...customerData,
                year: year
            }
        }).then((response: AxiosResponse<VatSettlementDto[]>) => response.data);
    };

    return {
        fetch,
        loading: loading,
        error: error,
        data: data,
        hasFetched: hasLoaded
    };
};
