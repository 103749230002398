import Typography from "@vapor/react-extended/ExtendedTypography";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";

import { Stack } from "@vapor/react-material";
import { Report } from "../../../../core/usecases/dtos/ReportAccountingPeriodsDto";
import FormattedAmount from "../../../components/FormattedAmount";
const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.CloseAndReopenAccountsOverlay"
);

interface HeaderRightProps {
    report?: Report;
}

export default function HeaderRight({ report }: HeaderRightProps) {
    const data = [
        {
            label: fs("totalGiving"),
            value: report?.total.openingTotal?.credit.value
        },
        {
            label: fs("totalHave"),
            value: report?.total.openingTotal?.debit.value
        },
        { label: fs("costs"), value: report?.total.costs.value },
        { label: fs("revenues"), value: report?.total.revenues.value },
        { label: fs("profitLoss"), value: report?.total.netIncome.value }
    ];
    return (
        <>
            {data.map((row) => (
                <Stack key={row.label} direction="column" spacing={2}>
                    <Typography variant="body">{row.label}</Typography>
                    {row.value && row.value > 0 ? (
                        <FormattedAmount
                            variant="body500"
                            amount={row.value}
                            currency={report?.total.costs.currency ?? ""}
                        />
                    ) : (
                        "-"
                    )}
                </Stack>
            ))}
        </>
    );
}
