import { useGet } from "@onefront/react-sdk";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface UseGetDraftFromOriginalEntry {
    loading: boolean;
    error: any;
    draftId: string | null;
    fetch: (originalEntryId: string) => Promise<string>;
    hasFetched: boolean;
}

export const useGetDraftFromOriginalEntry =
    (): UseGetDraftFromOriginalEntry => {
        const { enqueueSnackbar } = useSnackbar();
        const customerData = useCustomerData();
        const fs = useFormattedStringWithScope(
            "notifications.GetDraftFromOriginalEntry"
        );

        const {
            fetch: fetchData,
            data,
            loading,
            error,
            hasLoaded
        } = useGet(`ignored`, {
            ...useAxiosConfigRequestParams("DraftFromOriginalEntry"),
            onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
            lazy: true
        });

        const fetch = useCallback(
            (originalEntryId: string) => {
                return fetchData({
                    url: `${API_URL}/v1/precog/drafts/original-entry-id/${originalEntryId}`,
                    params: {
                        ...customerData
                    }
                }).then((response: any) => response.data);
            },
            [fetchData, customerData]
        );

        return {
            fetch,
            loading: loading,
            error: error,
            draftId: data,
            hasFetched: hasLoaded
        };
    };
