import React, { useRef } from "react";
import { KeyBoardEventCode } from "../../../utils/appEnums";

interface UseEnter {
    inputRef: React.Ref<any>;
    onKeyDown: React.KeyboardEventHandler<HTMLDivElement>;
}

interface UseEnterProps {
    callback: (any?: any) => any;
}

export default function useEnter({ callback }: UseEnterProps): UseEnter {
    const ref = useRef<any>(null);

    return {
        inputRef: ref,
        onKeyDown: (event) =>
            event.code === KeyBoardEventCode.ENTER &&
            ref &&
            callback(ref.current?.value)
    };
}
