import { useState, useEffect } from "react";
import Box from "@vapor/react-material/Box";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import Stack from "@vapor/react-material/Stack";
import Divider from "@vapor/react-material/Divider";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Spinner } from "@comic/precog-components";

import {
    IPostData,
    usePostAssetSale
} from "../../../../../../../core/usecases/usePostAssetSale";
import FormattedAmount from "../../../../../../components/FormattedAmount";
import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../../../../../components/CustomButton";
const fs = getFormattedStringWithScope(
    "views.ListAssets.movementDrawer.eliminationSubsection"
);
const fsReason = getFormattedStringWithScope("commons.Reason");

interface SalePageProps {
    assetId?: string;
    isLoading: boolean;
    simulationData: any;
    dataToPost: IPostData;
    setSelectedTab: Function;
    onDismission: () => void;
    onChangeEliminationPage: (page: number) => void;
    onShowTabs: () => void;
    setShowElimination: Function;
}

export default function SalePage({
    assetId,
    isLoading,
    simulationData,
    dataToPost,
    setSelectedTab,
    onChangeEliminationPage,
    onDismission,
    onShowTabs,
    setShowElimination
}: SalePageProps) {
    const [debitList, setDebitList] = useState<[]>([]);
    const [creditList, setCreditList] = useState<[]>([]);

    const { fetch: fetchPostData, loading } = usePostAssetSale({
        assetId: assetId,
        dataToPost: {
            businessId: dataToPost.businessId,
            date: dataToPost.date,
            disposalRate: dataToPost.disposalRate,
            saleAmount: dataToPost.saleAmount,
            splitCapitalGain: false
        },
        lazy: true
    });

    useEffect(() => {
        if (!simulationData) return;
        setDebitList(
            simulationData?.simulation?.accountingLines.filter(
                (el: any) => el.debit["EUR"] > 0
            )
        );
        setCreditList(
            simulationData?.simulation?.accountingLines.filter(
                (el: any) => el.credit["EUR"] > 0
            )
        );
    }, [simulationData]);

    return (
        <Box>
            <Box marginLeft={4}>
                <Typography
                    color="primary.textTitleColor"
                    variant="bodyLarge500"
                >
                    {fs("accountingRecord")}
                </Typography>
                {isLoading ? (
                    <Spinner loading={isLoading}>
                        <Box marginTop={10} height={40}></Box>
                    </Spinner>
                ) : (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        width="90%"
                        height="200px"
                    >
                        <Stack marginTop={3} width="50%">
                            <Box>
                                <Typography variant="body500">
                                    {fs("sectional")}:
                                </Typography>
                                <Typography variant="body700">
                                    {
                                        simulationData?.simulation?.doc.protocol
                                            .group
                                    }
                                </Typography>
                            </Box>
                            <Box
                                marginTop={2}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Typography variant="body">
                                    {fs("accountingAccount")}
                                </Typography>
                                <Typography variant="body500">
                                    {fs("credit")}
                                </Typography>
                            </Box>
                            {debitList?.map((row: any, index) => (
                                <Box
                                    marginTop="8px"
                                    justifyContent="space-between"
                                    display="flex"
                                    key={index}
                                >
                                    <Typography variant="body">
                                        {row?.accountDesc}
                                    </Typography>
                                    <FormattedAmount
                                        variant="body500"
                                        amount={row?.debit?.EUR}
                                        currency="EUR"
                                    />
                                </Box>
                            ))}
                        </Stack>
                        <Divider
                            sx={{
                                marginTop: 7,
                                marginBottom: 4.5,
                                marginLeft: 1.25
                            }}
                            orientation="vertical"
                            flexItem
                        />
                        <Stack marginTop="23px" marginLeft={1} width="50%">
                            <Box>
                                <Typography variant="body">
                                    {fs("accountingReason")}:
                                </Typography>
                                <Typography variant="body700">
                                    {fsReason(
                                        simulationData?.simulation?.reasonId
                                    )}
                                </Typography>
                            </Box>
                            <Box
                                marginTop={2}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Typography variant="body">
                                    {fs("accountingAccount")}
                                </Typography>
                                <Typography variant="body500">
                                    {fs("debit")}
                                </Typography>
                            </Box>
                            {creditList?.map((row: any, index) => (
                                <Box
                                    marginTop={1}
                                    justifyContent="space-between"
                                    display="flex"
                                    key={index}
                                >
                                    <Typography variant="body">
                                        {row?.accountDesc}
                                    </Typography>
                                    <FormattedAmount
                                        variant="body500"
                                        amount={row?.credit?.EUR}
                                        currency="EUR"
                                    />
                                </Box>
                            ))}
                        </Stack>
                    </Box>
                )}
            </Box>
            <VaporToolbar
                variant="surface"
                withoutAppBar={false}
                contentRight={
                    <>
                        <CustomButton
                            onClick={async () => {
                                await fetchPostData();
                                onDismission();
                                onChangeEliminationPage(1);
                                setSelectedTab("movements");
                                onShowTabs();
                                setShowElimination(false);
                            }}
                            loading={loading}
                            variant="contained"
                        >
                            {fs("confirmElimination")}
                        </CustomButton>
                    </>
                }
            />
        </Box>
    );
}
