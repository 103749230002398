import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@vapor/react-material";
import { Typography } from "@vapor/react-extended";
import { DatePicker } from "@vapor/react-x-date-pickers";
import { every, isEmpty, some } from "lodash";

import { Payment } from ".";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import CurrencyField from "../../components/CurrencyField";
import FormattedAmount from "../../components/FormattedAmount";
import FormattedDate from "../../components/FormattedDate";
import NotFoundBanner from "../../components/NotFoundBanner";
import TableHeadCell from "../../components/TableHead/TableHeadCell";
import TableHeadText from "../../components/TableHead/TableHeadText";

interface SettleAccountTableProps {
    active: boolean;
    payments: Payment[];
    onToggle: (paymentId: string) => void;
    onToggleAll: () => void;
    onChangeAmount: (paymentId: string, amount: number) => void;
    onChangeDate: (paymentId: string, date: Date) => void;
}

const fs = getFormattedStringWithScope("views.SettleAccount.Table");

export default function SettleAccountTable({
    active,
    payments,
    onToggle,
    onToggleAll,
    onChangeAmount,
    onChangeDate
}: SettleAccountTableProps) {
    const allPaymentsSelected = every(payments, (payment) => payment.selected);
    const somePaymentsSelected = some(payments, (payment) => payment.selected);
    const allPaymentProcessing = every(
        payments,
        (payment) => payment.processing
    );

    return isEmpty(payments) ? (
        <NotFoundBanner text={fs("notFound")} />
    ) : (
        <Table>
            <TableHead shadow>
                <TableRow>
                    <TableHeadCell>
                        <Checkbox
                            checked={allPaymentsSelected}
                            indeterminate={
                                !allPaymentsSelected && somePaymentsSelected
                            }
                            disabled={allPaymentProcessing}
                            onClick={onToggleAll}
                        />
                    </TableHeadCell>
                    <TableHeadCell>
                        <TableHeadText>{fs("docNumber")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell>
                        <TableHeadText>{fs("docDate")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell>
                        <TableHeadText>{fs("registrationDate")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell>
                        <TableHeadText>
                            {fs(active ? "customer" : "supplier")}
                        </TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell>
                        <TableHeadText>{fs("reason")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell align="right">
                        <TableHeadText>{fs("docAmount")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell align="right">
                        <TableHeadText>{fs("residual")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell align="right">
                        <TableHeadText>{fs("paymentAmount")}</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell>
                        <TableHeadText>{fs("paymentDate")}</TableHeadText>
                    </TableHeadCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {payments.map(
                    (
                        {
                            fiscalPayment: payment,
                            amount,
                            date,
                            selected,
                            processing
                        },
                        index
                    ) => (
                        <TableRow
                            key={index}
                            sx={{
                                cursor: processing ? "not-allowed" : undefined,
                                ...(processing
                                    ? {
                                          "* ": {
                                              opacity: "0.5"
                                          }
                                      }
                                    : {})
                            }}
                        >
                            <TableCell size="small">
                                {processing ? null : (
                                    <Checkbox
                                        checked={selected}
                                        onChange={() => onToggle(payment.key)}
                                    />
                                )}
                            </TableCell>
                            <TableCell size="small">
                                <Typography variant="body">
                                    {payment.docNumber}
                                </Typography>
                            </TableCell>
                            <TableCell size="small">
                                <FormattedDate
                                    variant="body"
                                    date={payment.docDate}
                                />
                            </TableCell>
                            <TableCell size="small">
                                <FormattedDate
                                    variant="body"
                                    date={payment.date}
                                />
                            </TableCell>
                            <TableCell size="small">
                                <Typography variant="body">
                                    {payment.businessName}
                                </Typography>
                            </TableCell>
                            <TableCell size="small">
                                <Typography variant="body">
                                    {payment.reasonDescription}
                                </Typography>
                            </TableCell>
                            <TableCell size="small" align="right">
                                <FormattedAmount
                                    amount={payment.totalDueAmount.amount}
                                    currency={payment.totalDueAmount.currency}
                                />
                            </TableCell>
                            <TableCell size="small" align="right">
                                <FormattedAmount
                                    amount={payment.remainingAmount.amount}
                                    currency={payment.remainingAmount.currency}
                                />
                            </TableCell>
                            <TableCell size="small" align="right">
                                <CurrencyField
                                    value={amount}
                                    currency={payment.totalDueAmount.currency}
                                    onChange={(value) =>
                                        onChangeAmount(payment.key, value)
                                    }
                                    readOnly={processing}
                                    isAllowed={({ floatValue }) =>
                                        floatValue
                                            ? floatValue <=
                                              payment.remainingAmount.amount
                                            : true
                                    }
                                />
                            </TableCell>
                            <TableCell size="small">
                                <DatePicker
                                    value={new Date(date)}
                                    disabled={processing}
                                    onChange={(date: any) =>
                                        onChangeDate(payment.key, date)
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    )
                )}
            </TableBody>
        </Table>
    );
}
