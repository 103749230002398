import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { VaporIcon } from "@vapor/react-icons";
import { Skeleton } from "@vapor/react-material";
import { isNil } from "lodash";
import { useState } from "react";

import { useDeleteDraft, useDraft } from "../../../core/domain/Draft/queries";
import { DraftAccountingStatus, DraftSource } from "../../../utils/appEnums";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import DeleteDialog from "../../views/Dashboard/ToBeAccountedTable/DeleteDialog";
import CustomButton from "../CustomButton";

type DeleteDraftButtonProps = {
    variant?: "button" | "icon";
    draftId: string;
    onDeleteSuccess?: () => void;
    onDeleteError?: () => void;
};
export const DeleteDraftButton = ({
    draftId,
    onDeleteError,
    onDeleteSuccess
}: DeleteDraftButtonProps) => {
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] =
        useState<boolean>(false);
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);
    const { mutateAsync, isPending } = useDeleteDraft(draftId);
    const fs = useFormattedStringWithScope(
        "components.Accounting.DeleteDraftButton"
    );

    if (isLoading) {
        return <Skeleton variant="rectangular" />;
    }

    if (isSuccess) {
        const isCloned = draft?.status === DraftAccountingStatus.CLONED;
        if (isCloned) return null;

        const isAccounted =
            !isNil(draft) && draft.status === DraftAccountingStatus.CONFIRMED;

        const isXML = draft.source === DraftSource.B2B;
        const canBeDeleted = isAccounted || !isXML;

        if (draft?.status === DraftAccountingStatus.CONFIRMED) {
            return null;
        }

        const handleDelete = async () => {
            try {
                await mutateAsync();
                onDeleteSuccess?.();
            } catch (e) {
                onDeleteError?.();
            }
        };

        return (
            <>
                <CustomButton
                    variant="outlined"
                    color="primary"
                    onClick={() => setIsDeleteDialogOpen(true)}
                    startIcon={
                        <VaporIcon
                            color={canBeDeleted ? "interactive" : "disabled"}
                            icon={faTrashAlt}
                        />
                    }
                    disabled={!canBeDeleted}
                    loading={isPending}
                >
                    {fs("delete")}
                </CustomButton>
                <DeleteDialog
                    isOpen={isDeleteDialogOpen}
                    onClose={() => setIsDeleteDialogOpen(false)}
                    onDelete={handleDelete}
                    isLoading={isPending}
                    isXML={isXML}
                    isAccounted={isAccounted}
                />
            </>
        );
    }

    return null;
};
