import { Tooltip } from "@vapor/react-material";
import { isNil } from "lodash";
import { useCanConfirmDraft } from "../../../core/domain/Draft/hooks";
import { useConfirmDraft, useDraft } from "../../../core/domain/Draft/queries";
import { DraftAccountingStatus } from "../../../utils/appEnums";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import CustomButton from "../CustomButton";

type ConfirmDraftButtonProps = {
    draftId: string;
    disabled?: boolean;
    onConfirmSuccess?: () => void;
    onConfirmError?: () => void;
};
export const ConfirmDraftButton = ({
    draftId,
    disabled,
    onConfirmError,
    onConfirmSuccess
}: ConfirmDraftButtonProps) => {
    const { data: draft, isLoading } = useDraft(draftId);
    const { mutateAsync, isPending } = useConfirmDraft({
        draftId,
        revision: draft?.revision
    });
    const { canConfirmDraft, message } = useCanConfirmDraft(draftId);

    const fs = useFormattedStringWithScope(
        "components.Accounting.ConfirmDraftButton"
    );

    const isDraftCloned = draft?.status === DraftAccountingStatus.CLONED;
    const isDraftClonedInError =
        draft?.status === DraftAccountingStatus.ERROR && !isNil(draft.revision);
    const isDeprecated = draft?.status === DraftAccountingStatus.DEPRECATED;

    if (draft?.status === DraftAccountingStatus.CONFIRMED) {
        return null;
    }

    const handleConfirm = async () => {
        try {
            await mutateAsync();
            onConfirmSuccess?.();
        } catch (e) {
            onConfirmError?.();
        }
    };

    return (
        <Tooltip arrow title={message}>
            <div>
                <CustomButton
                    variant="contained"
                    color="primary"
                    onClick={handleConfirm}
                    disabled={
                        !canConfirmDraft ||
                        isDeprecated ||
                        isLoading ||
                        disabled
                    }
                    loading={isPending}
                >
                    {isDraftCloned || isDraftClonedInError
                        ? fs("saveModifications")
                        : fs("confirmDraft")}
                </CustomButton>
            </div>
        </Tooltip>
    );
};
