import VaporHeaderBar from "@vapor/react-custom/VaporHeaderBar";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import ExtendedTab from "@vapor/react-extended/ExtendedTab";
import ExtendedTabs from "@vapor/react-extended/ExtendedTabs";
import Times from "@vapor/react-icons/Times";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Drawer from "@vapor/react-material/Drawer";
import IconButton from "@vapor/react-material/IconButton";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { useDraft } from "../../../core/domain/Draft/queries";

import { useWithholdings } from "../../../core/contexts/Withholdings";
import { DraftWithholdingDto } from "../../../core/usecases/dtos/DraftWithholdingDto";
import { PatchData } from "../../../core/usecases/usePatchDraftWithholding";
import { DrawerTab } from "../../../utils/appEnums";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import InpsManagementDrawerTab from "./InpsManagementDrawerTab";
import ManagementDrawerTab from "./ManagementDrawerTab";

const fs = getFormattedStringWithScope("views.Detail.withholdings.drawer");

interface WithholdingDrawerProps {
    draftId: string;
    selectedWithholding: DraftWithholdingDto | null;
    openDrawer: boolean;
    totalTaxable: number;
    onClose: () => void;
}

export default function WithholdingDrawer({
    draftId,
    selectedWithholding,
    openDrawer,
    totalTaxable,
    onClose
}: WithholdingDrawerProps) {
    const [drawerTab, setDrawerTab] = useState<string>(DrawerTab.MANAGEMENT);
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);

    const {
        getDetailSimulation,
        getDetail,
        patch,
        detail,
        get,
        detailSimulation
    } = useWithholdings().draft;
    const { data: draft } = useDraft(draftId ?? undefined);

    useEffect(() => {
        if (draftId && selectedWithholding?.uuid && !hasInitialized) {
            getDetailSimulation({
                cashContribution:
                    selectedWithholding?.cashContributionNoWithholding,
                notIncomeAmount: selectedWithholding?.notIncomeAmount,
                reasonId: selectedWithholding?.reasonId,
                totalNetAmount: totalTaxable,
                alreadySubjectedAmount:
                    selectedWithholding?.alreadySubjectedAmount,
                accountingReason: draft?.reasonId
            });
            getDetail(draftId, selectedWithholding?.uuid);
            setHasInitialized(true);
        }
    }, [
        getDetailSimulation,
        getDetail,
        totalTaxable,
        hasInitialized,
        draftId,
        selectedWithholding,
        draft?.reasonId
    ]);

    const handleDrawerChange = (
        _event: React.SyntheticEvent,
        newValue: string
    ) => {
        setDrawerTab(newValue);
    };

    const handleWithholdingChange = async (
        data: Partial<DraftWithholdingDto & PatchData>
    ) => {
        if (draft?.id && selectedWithholding) {
            const newWithholding = {
                ...detail,
                ...data
            };
            await patch(draft?.id, selectedWithholding.uuid, {
                ...newWithholding,
                totalNetAmount: totalTaxable,
                accountingReason: draft?.reasonId
            });
            await get(draft?.id);
            await getDetail(draft?.id, selectedWithholding.uuid);
            await getDetailSimulation({
                cashContribution: newWithholding.cashContributionNoWithholding,
                notIncomeAmount: newWithholding.notIncomeAmount,
                reasonId: newWithholding.reasonId,
                totalNetAmount: totalTaxable,
                alreadySubjectedAmount: newWithholding.alreadySubjectedAmount,
                accountingReason: draft?.reasonId
            });
        }
    };

    const hasInps = !isNil(detailSimulation?.inpsRate);

    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={onClose}
            hideBackdrop
            width="720px"
            sx={{
                zIndex: "2300"
            }}
        >
            <VaporHeaderBar
                divider={true}
                rightItems={[
                    <IconButton
                        color="primary"
                        onClick={() => {
                            onClose();
                            setHasInitialized(false);
                        }}
                    >
                        <Times />
                    </IconButton>
                ]}
                title={fs("title")}
                size="small"
            />
            <Box height="60px">
                <ExtendedTabs
                    secondary={true}
                    size="small"
                    value={drawerTab}
                    onChange={handleDrawerChange}
                >
                    <ExtendedTab
                        value={DrawerTab.MANAGEMENT}
                        label={fs("management")}
                    />
                    {hasInps && (
                        <ExtendedTab
                            value={DrawerTab.INPS}
                            label={fs("inps")}
                        />
                    )}
                </ExtendedTabs>
            </Box>
            {drawerTab === DrawerTab.MANAGEMENT && selectedWithholding && (
                <ManagementDrawerTab
                    withholding={selectedWithholding}
                    totalTaxable={totalTaxable}
                    onWithholdingChange={handleWithholdingChange}
                    refCurrency={draft?.refCurrency ?? "EUR"}
                />
            )}
            {drawerTab === DrawerTab.INPS && selectedWithholding && (
                <InpsManagementDrawerTab
                    withholding={selectedWithholding}
                    refCurrency={draft?.refCurrency ?? "EUR"}
                    onWithholdingChange={handleWithholdingChange}
                />
            )}
            <VaporToolbar
                variant="surface"
                contentRight={
                    <Button
                        sx={{ marginRight: "15px" }}
                        variant="outlined"
                        onClick={() => {
                            onClose();
                            setHasInitialized(false);
                        }}
                    >
                        {fs("closeButton")}
                    </Button>
                }
            />
        </Drawer>
    );
}
