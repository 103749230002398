import { useGet, useSnackbar } from "@onefront/react-sdk";
import uniqBy from "lodash/uniqBy";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ACCOUNTS_PAGINATION_SIZE, API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AccountsResponse } from "../dtos/AccountsDto";

interface UseGetPagedAccounts {
    loading: boolean;
    loadingMore: boolean;
    error: any;
    accounts: AccountsResponse | null;
    fetch: (search?: string) => void;
    fetchMore: Function;
    hasFetched: boolean;
    hasMore: boolean;
}

interface UseGetPagedAccountsOptions {
    includeSystem?: boolean | undefined;
    lazy?: boolean;
}

interface PaginationOptions {
    after: number;
}

export const useGetPagedAccounts = ({
    includeSystem,
    lazy
}: UseGetPagedAccountsOptions): UseGetPagedAccounts => {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.GetPagedAccounts");

    const [responseData, setResponseData] = useState<AccountsResponse | null>();

    const [search, setSearch] = useState<string>("");

    const defaultPaginationState: PaginationOptions = useMemo(
        () => ({
            after: 0
        }),
        []
    );
    const [paginationState, setPaginationState] = useState<PaginationOptions>(
        defaultPaginationState
    );

    const [isFetchingAccounts, setIsFetchingAccounts] =
        useState<boolean>(false);

    const [hasFetchedAccounts, setHasFetchedAccounts] =
        useState<boolean>(false);

    const [isFetchingMoreAccounts, setIsFetchingMoreAccounts] =
        useState<boolean>(false);

    const [hasFetchedMoreAccounts, setHasFetchedMoreAccounts] =
        useState<boolean>(false);

    const [accounts, setAccounts] = useState<AccountsResponse>();

    const {
        fetch: fetchAccounts,
        error,
        hasLoaded
    } = useGet(`${API_URL}/v1/accounting/accounts`, {
        ...useAxiosConfigRequestParams("Accounts"),
        onResponse: (response: any) => setResponseData(response.data),
        onError: () => {
            enqueueSnackbar(fs("error"), { variant: "error" });
            setIsFetchingAccounts(false);
            setIsFetchingMoreAccounts(false);
        },
        params: omitNilProperties({
            ...useCustomerData(),
            search: search,
            includeSystem: includeSystem,
            skip: paginationState.after,
            limit: ACCOUNTS_PAGINATION_SIZE
        }),
        lazy: lazy
    });

    const fetch = useCallback(
        (search?: string) => {
            setResponseData(null);
            setPaginationState(defaultPaginationState);
            setSearch(search ?? "");
            setIsFetchingAccounts(true);
            setHasFetchedAccounts(false);
            setIsFetchingMoreAccounts(false);
        },
        [defaultPaginationState]
    );

    const fetchMore = useCallback(() => {
        setResponseData(null);
        setIsFetchingAccounts(false);
        setHasFetchedMoreAccounts(false);
        setIsFetchingMoreAccounts(true);
    }, []);

    useEffect(() => {
        if (isFetchingAccounts && !hasFetchedAccounts) {
            fetchAccounts();
            setHasFetchedAccounts(true);
        }
    }, [isFetchingAccounts, hasFetchedAccounts, fetchAccounts]);

    useEffect(() => {
        if (isFetchingMoreAccounts && !hasFetchedMoreAccounts) {
            fetchAccounts();
            setHasFetchedMoreAccounts(true);
        }
    }, [isFetchingMoreAccounts, hasFetchedMoreAccounts, fetchAccounts]);

    useEffect(() => {
        if (
            isFetchingMoreAccounts &&
            hasFetchedMoreAccounts &&
            responseData &&
            accounts
        ) {
            setAccounts({
                ...accounts,
                accounts: uniqBy(
                    accounts.accounts.concat(responseData.accounts),
                    "code"
                ),
                hasNext: responseData.hasNext
            });
            setIsFetchingMoreAccounts(false);
            setPaginationState({
                after: paginationState.after + ACCOUNTS_PAGINATION_SIZE
            });
        } else if (isFetchingAccounts && hasFetchedAccounts && responseData) {
            setAccounts(responseData);
            setIsFetchingAccounts(false);
            setPaginationState({
                after: defaultPaginationState.after + ACCOUNTS_PAGINATION_SIZE
            });
        }
    }, [
        accounts,
        responseData,
        isFetchingAccounts,
        hasFetchedAccounts,
        isFetchingMoreAccounts,
        hasFetchedMoreAccounts,
        defaultPaginationState.after,
        paginationState.after
    ]);

    return {
        fetch: fetch,
        fetchMore: fetchMore,
        loading: isFetchingAccounts,
        loadingMore: isFetchingMoreAccounts,
        error: error,
        accounts: error ? null : accounts ?? null,
        hasFetched: hasLoaded,
        hasMore: !!accounts?.hasNext
    };
};
