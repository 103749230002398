import { useGet } from "@onefront/react-sdk";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { useCallback, useEffect, useMemo, useState } from "react";

import useAxiosConfigRequestParams from "./useAxiosConfigRequestParams";

interface UsePagedGet<T> {
    loading: boolean;
    loadingMore: boolean;
    error: any;
    data: T | null;
    fetch: (search?: string) => void;
    fetchMore: Function;
    hasFetched: boolean;
    hasMore: boolean;
}

interface UsePagedGetOptions<T> {
    scope: string;
    baseUrl: string;
    params: any;
    processData?: (data: T) => T;
    concatenateData: (data: T, responseData: T) => T | undefined;
    onError: any;
    lazy?: boolean;
}

interface PaginationOptions {
    url: string;
}

const FETCH_MORE_URL_KEY = "_links.next.href";

export default function usePagedGet<T>({
    scope,
    baseUrl,
    params,
    processData,
    concatenateData,
    onError,
    lazy
}: UsePagedGetOptions<T>): UsePagedGet<T> {
    const [responseData, setResponseData] = useState<T | null>();

    const defaultPaginationState: PaginationOptions = useMemo(
        () => ({
            url: baseUrl
        }),
        [baseUrl]
    );
    const [paginationState, setPaginationState] = useState<PaginationOptions>(
        defaultPaginationState
    );

    const [hasMore, setHasMore] = useState<boolean>(false);

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const [hasFetched, setHasFetched] = useState<boolean>(false);

    const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);

    const [hasFetchedMore, setHasFetchedMore] = useState<boolean>(false);

    const [data, setData] = useState<T>();

    const {
        fetch: fetchData,
        error,
        hasLoaded
    } = useGet(paginationState.url, {
        ...useAxiosConfigRequestParams(scope),
        onResponse: (response: any) => setResponseData(response.data),
        onError: onError,
        params: {
            ...(isFetchingMore ? {} : params)
        },
        lazy: lazy
    });

    const fetch = useCallback(() => {
        setResponseData(null);
        setPaginationState(defaultPaginationState);
        setIsFetching(true);
        setHasFetched(false);
        setIsFetchingMore(false);
    }, [defaultPaginationState]);

    const fetchMore = useCallback(() => {
        setResponseData(null);
        setIsFetching(false);
        setHasFetchedMore(false);
        setIsFetchingMore(true);
    }, []);

    useEffect(() => {
        if (isFetching && !hasFetched) {
            fetchData();
            setHasFetched(true);
        }
    }, [isFetching, hasFetched, fetchData]);

    useEffect(() => {
        if (isFetchingMore && !hasFetchedMore) {
            fetchData();
            setHasFetchedMore(true);
        }
    }, [isFetchingMore, hasFetchedMore, fetchData]);

    useEffect(() => {
        const fetchMoreUrl = get(responseData, FETCH_MORE_URL_KEY);
        if (isFetchingMore && hasFetchedMore && responseData && data) {
            setData(
                concatenateData(
                    data,
                    processData ? processData(responseData) : responseData
                )
            );
            setIsFetchingMore(false);
            setHasMore(!isNil(fetchMoreUrl));
            setPaginationState({
                url: fetchMoreUrl || defaultPaginationState.url
            });
        } else if (isFetching && hasFetched && responseData) {
            setData(processData ? processData(responseData) : responseData);
            setIsFetching(false);
            setHasMore(!isNil(fetchMoreUrl));
            setPaginationState({
                url: fetchMoreUrl || defaultPaginationState.url
            });
        }
    }, [
        data,
        processData,
        concatenateData,
        responseData,
        isFetching,
        hasFetched,
        isFetchingMore,
        hasFetchedMore,
        defaultPaginationState.url,
        paginationState.url
    ]);

    return {
        fetch: fetch,
        fetchMore: fetchMore,
        loading: isFetching,
        loadingMore: isFetchingMore,
        error: error,
        data: error ? null : data ?? null,
        hasFetched: hasLoaded,
        hasMore: hasMore
    };
}
