import { OneBoxPage, useCurrentWorkspace } from "@drift/oneplatfront";
import { Typography } from "@vapor/react-extended";
import { Times } from "@vapor/react-icons";
import {
    CircularProgress,
    Grid,
    IconButton,
    useTheme
} from "@vapor/react-material";
import Stack from "@vapor/react-material/Stack";
import { useNavigate } from "react-router-dom";
import { useSetupActions } from "../../../core/domain/AccountingSetup/queries";

import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import routes from "../../commons/routes";
import CA21Action from "./Actions/CA21Action";
import PreviousBalances from "./Actions/PreviousBalances";
import { AccountingSetupProvider, useAccountingSetup } from "./context";

const fs = getFormattedStringWithScope("views.AccountingSetup");

const Content = () => {
    const { action } = useAccountingSetup();

    if (!action) {
        return null;
    }

    switch (action.type) {
        case "PREVIOUS_BALANCES":
            return (
                <CA21Action
                    // use key to force fresh full rerender when action changes
                    key={action.action.draftId ?? action.action.originalEntryId}
                    {...action.action}
                />
            );
        case "OTHER":
            return <>TODO!!</>;
        default:
            return null;
    }
};

const Actions = () => {
    const { isLoading } = useSetupActions();

    if (isLoading) {
        return <CircularProgress />;
    }

    return <PreviousBalances />;
};

export default function AccountingSetup() {
    const { workspace: customer } = useCurrentWorkspace(true);
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <AccountingSetupProvider>
            <OneBoxPage
                title={fs("title")}
                subtitle={`${customer?.description}`}
                showCustomerSelector={process.env.NODE_ENV === "development"}
                headerRight={
                    <IconButton
                        size="small"
                        color="primary"
                        onClick={() => navigate(routes.dashboard())}
                    >
                        <Times color="primary" />
                    </IconButton>
                }
            >
                <Stack direction="row" height="100%">
                    <Grid container>
                        <Grid
                            item
                            container
                            xs={3}
                            sx={{
                                border: `1px solid ${theme.palette.grey["400"]}`,
                                backgroundColor: theme.palette.grey["100"],
                                minHeight: "100vh",
                                height: "100%"
                            }}
                        >
                            <Grid item xs={12}>
                                <Stack
                                    padding={2}
                                    columnGap={2}
                                    direction="column"
                                >
                                    <Typography variant="titleXSmall">
                                        {fs("activity")}
                                    </Typography>
                                    <Typography
                                        variant="body"
                                        color="textSecondary"
                                    >
                                        {fs("registerPreviousSituation")}
                                    </Typography>
                                </Stack>
                                <Actions />
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Content />
                        </Grid>
                    </Grid>
                </Stack>
            </OneBoxPage>
        </AccountingSetupProvider>
    );
}
