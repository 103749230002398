import { Accordion } from "@vapor/react-extended";
import { AngleDown } from "@vapor/react-icons";
import { AccordionSummary, Stack } from "@vapor/react-material";
import { VatSettlementDetail } from "../../../../../../core/usecases/dtos/VatSettlementDto";
import getFormattedStringWithScope from "../../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../../components/FormattedAmount";

const fs = getFormattedStringWithScope("views.VATSettlement.monoActivity");

type SummaryDto = Pick<VatSettlementDetail, "description" | "vatAmount">;

interface SummaryLineProps {
    detail: SummaryDto & { items?: SummaryDto[] };
}

const SummaryLinePlain = (props: SummaryLineProps) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            height={48}
            mx={6}
        >
            <span>{fs(props.detail.description.toLowerCase())}</span>
            <span>
                <FormattedAmount
                    amount={props.detail.vatAmount}
                    currency="EUR"
                    variant="body500"
                />
            </span>
        </Stack>
    );
};

const SummaryLineWithRates = (props: SummaryLineProps) => {
    return (
        <Accordion variant="outlined">
            <AccordionSummary expandIcon={<AngleDown />}>
                <Stack
                    width="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    height={48}
                    pl={1}
                >
                    <span>{fs(props.detail.description.toLowerCase())}</span>
                    <span>
                        <FormattedAmount
                            amount={props.detail.vatAmount}
                            currency="EUR"
                            variant="body500"
                        />
                    </span>
                </Stack>
            </AccordionSummary>
            <Stack>
                {props.detail.items?.map((detail) => (
                    <SummaryLine detail={detail} />
                ))}
            </Stack>
        </Accordion>
    );
};

const SummaryLine = (props: SummaryLineProps) => {
    if (props.detail.items && props.detail.items.length > 0) {
        return <SummaryLineWithRates {...props} />;
    }

    return <SummaryLinePlain {...props} />;
};

export default SummaryLine;
