import { useRequest } from "@onefront/react-sdk";
import format from "date-fns/format";
import { useCallback } from "react";

import { API_DATE_FORMAT, API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface AllocationLine {
    accountId: string;
    debit: { EUR: number };
    credit: { EUR: number };
}

interface AllocateNetIncomeOptions {
    postingDate: Date;
    allocationLines: AllocationLine[];
}

export interface UseAllocateNetIncome {
    loading: boolean;
    error: any;
    result: any;
    fetch: (options: AllocateNetIncomeOptions) => Promise<void>;
    hasFetched: boolean;
}

export interface UseAllocateNetIncomeOptions {
    lazy: boolean;
}

export const useAllocateNetIncome = ({
    lazy
}: UseAllocateNetIncomeOptions): UseAllocateNetIncome => {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.AllocateNetIncome");

    const customerData = useCustomerData();

    const {
        fetch: fetchData,
        data,
        loading,
        error,
        hasLoaded
    } = useRequest({
        url: `${API_URL}/v1/ledger/allocateNetIncome`,
        method: "post",
        ...useAxiosConfigRequestParams("AllocateNetIncome"),
        onResponse: () =>
            enqueueSnackbar(
                { title: fs("success.title"), content: fs("success.content") },
                { severity: "success" }
            ),
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        data: {
            ...useCustomerData()
        },
        lazy: lazy
    });

    const fetch = useCallback(
        ({ postingDate, allocationLines }: AllocateNetIncomeOptions) => {
            return fetchData({
                params: {
                    ...customerData
                },
                data: {
                    postingDate: format(postingDate, API_DATE_FORMAT),
                    allocationLines: allocationLines
                }
            });
        },
        [fetchData, customerData]
    );

    return {
        fetch: fetch,
        loading: loading,
        error: error,
        result: data,
        hasFetched: hasLoaded
    };
};
