import { useGet } from "@onefront/react-sdk";
import { useCallback, useState } from "react";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AssetLinesResponse } from "../dtos/AssetLinesDto";

export interface UseGetAssetLines {
    loading: boolean;
    error: any;
    assetLinesList: AssetLinesResponse | null;
    fetch: Function;
    hasFetched: boolean;
    hasResponded: boolean;
    fetchWithParameters: (draftId: string) => Promise<AssetLinesResponse>;
}

export interface UseGetAssetLinesOptions {
    draftId?: string | null;
    lazy?: boolean;
}

export const useGetAssetLines = ({
    draftId,
    lazy
}: UseGetAssetLinesOptions): UseGetAssetLines => {
    const customerData = useCustomerData();

    const [draftAssetLines, setDraftAssetLines] = useState<any | null>(null);
    const [hasResponded, setHasResponded] = useState<boolean>(false);

    const { fetch, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/precog/drafts/${draftId}/asset`,
        {
            ...useAxiosConfigRequestParams("AssetLines"),
            onResponse: (res: any) => {
                setHasResponded(true);
                setDraftAssetLines(res.data);
            },
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: lazy
        }
    );

    const safeFetch = useCallback(() => fetch(), [fetch]);

    const fetchWithParameters = async (draftId: string) => {
        setHasResponded(false);
        setDraftAssetLines(null);
        return fetch({
            url: `${API_URL}/v1/precog/drafts/${draftId}/asset`,
            params: {
                ...customerData
            }
        }).then(({ data }) => data);
    };

    return {
        fetch: safeFetch,
        loading: loading,
        error: error,
        assetLinesList: draftAssetLines,
        hasFetched: hasLoaded,
        fetchWithParameters,
        hasResponded
    };
};
