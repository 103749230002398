import { useMemo } from "react";

import { useGet, useSnackbar } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { VatSummary } from "../dtos/VatSummaryDto";

export const useGetVatSummary = () => {
    const fs = useFormattedStringWithScope("notifications.GetVatSummary");

    const { enqueueSnackbar } = useSnackbar();
    const { headers } = useAxiosConfigRequestParams("GetVatSummary");
    const { workspaceId } = useCustomerData();

    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${ASYNC_API_URL}/vat/widget`,
        {
            headers,
            onError: () =>
                enqueueSnackbar(fs("error"), {
                    variant: "error"
                }),
            params: { workspaceId },
            lazy: true
        }
    );

    const result = useMemo(() => {
        if (!hasLoaded && !loading && workspaceId) {
            fetch();
        }

        return {
            fetchVatSummary: fetch,
            vatSummary: data as VatSummary,
            hasLoaded,
            loading,
            error
        };
    }, [data, error, hasLoaded, loading, fetch, workspaceId]);

    return result;
};
