import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AssetSubkindsDepriciationDto } from "../dtos/AssetSubkindsDepricitionDto";

interface UseGetDepriciation {
    loading: boolean;
    error: any;
    data: AssetSubkindsDepriciationDto | null;
    fetch: Function;
}

interface UseGetDepriciationOptions {
    group?: string;
    kind?: string;
    subKind?: string | null;
    lazy?: boolean;
}

export const useGetDepriciation = ({
    group,
    kind,
    subKind,
    lazy
}: UseGetDepriciationOptions): UseGetDepriciation => {
    const { fetch, data, loading, error } = useGet(
        `${API_URL}/v1/asset/group/${group}/kind/${kind}/subKind/${subKind}`,
        {
            ...useAxiosConfigRequestParams("Deprictiation"),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: lazy
        }
    );

    return {
        fetch: fetch,
        loading: loading,
        error: error,
        data: data
    };
};
