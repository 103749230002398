import { ColumnElement, ColumnType, TableRows } from "@comic/precog-components";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VatLines } from "../../../../../../../core/usecases/dtos/VatEntriesDto";
import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../../../components/FormattedAmount";

interface AliquotesTableProps {
    data: VatLines[];
}

export const AliquotesTable = ({ data }: AliquotesTableProps) => {
    const fs = getFormattedStringWithScope("commons.VatEnum");

    const columns: ColumnElement[] = [
        // registrationDate
        {
            kind: ColumnType.empty
        },
        // reasonDescription
        {
            kind: ColumnType.empty
        },
        // docNumber
        {
            kind: ColumnType.empty
        },
        // docDate
        {
            kind: ColumnType.empty
        },
        // counterpart
        {
            kind: ColumnType.empty
        },
        // counterpartId
        {
            kind: ColumnType.empty
        },
        // vatRate
        {
            kind: ColumnType.text,
            render: (detail: VatLines) => {
                return <Typography>{fs(detail.vatRate)}</Typography>;
            }
        },
        // ctaShowVat
        {
            kind: ColumnType.empty
        },
        // taxable
        {
            kind: ColumnType.numeric,
            render: (detail: VatLines) => (
                <FormattedAmount amount={detail.taxable} currency="EUR" />
            )
        },
        // tax
        {
            kind: ColumnType.numeric,
            render: (detail: VatLines) => (
                <FormattedAmount amount={detail.tax} currency="EUR" />
            )
        },
        // total
        {
            kind: ColumnType.empty
        },
        // ctaDetail
        {
            kind: ColumnType.empty
        }
    ];

    return <TableRows columns={columns} data={data} />;
};
