import { createContext, useContext } from "react";

import { useGetDraftFromOriginalEntry } from "../../core/usecases/useGetDraftFromOriginalEntry";
import { Reason } from "../../utils/appEnums";
import { isDraftReason } from "../../utils/reasonUtils";
import CA21DetailComponent, { useCA21Detail } from "../CA21Detail";
import {
    DocumentDetailConfig,
    DocumentDetailWrapper,
    useDocumentDetail
} from "../DocumentDetail";
import ProcedureEntryDetailComponent, {
    useProcedureEntryDetail
} from "../ProcedureEntryDetail";

interface RegistrationIdShape {
    registrationUuid?: string;
    originalEntryId?: string;
    id?: string;
}

type DetailContextType = {
    open: (
        id: RegistrationIdShape,
        reasonId?: Reason | string,
        config?: DocumentDetailConfig
    ) => Promise<void>;
    isOpening: boolean;
};

const DetailContext = createContext<DetailContextType>({
    open: () => Promise.resolve(),
    isOpening: false
});

const useDetail = () => {
    return useContext(DetailContext);
};

const DetailContextProvider = ({ children }: { children: any }) => {
    const { open: openDraft } = useDocumentDetail();
    const { open: openProcedure } = useProcedureEntryDetail();
    const { open: openCA21 } = useCA21Detail();

    const useGetDraftFromOriginalEntryHook = useGetDraftFromOriginalEntry();

    const open = async (
        entryWithId: RegistrationIdShape,
        reasonId?: Reason | string,
        config?: DocumentDetailConfig
    ) => {
        if (!reasonId || isDraftReason(reasonId)) {
            const id =
                entryWithId.originalEntryId ??
                entryWithId.registrationUuid ??
                entryWithId.id;
            if (id) {
                const draftId = await useGetDraftFromOriginalEntryHook.fetch(
                    id
                );
                openDraft(draftId, config);
            }
        } else if (reasonId === Reason.CA21) {
            const { originalEntryId: id } = entryWithId;
            if (id) {
                const draftId = await useGetDraftFromOriginalEntryHook.fetch(
                    id
                );
                openCA21(draftId);
            }
        } else {
            const id = entryWithId.id ?? entryWithId.registrationUuid;
            id && openProcedure(id);
        }
    };

    return (
        <DetailContext.Provider
            value={{
                open,
                isOpening: useGetDraftFromOriginalEntryHook.loading
            }}
        >
            {children}
        </DetailContext.Provider>
    );
};

export { DetailContext, DetailContextProvider, useDetail };
export const DetailFacade = () => ({
    target: "$REACT_ROOT_WRAPPER",
    handler: {
        component: ({ children }: any) => (
            <DocumentDetailWrapper>
                <ProcedureEntryDetailComponent>
                    <CA21DetailComponent>
                        <DetailContextProvider>
                            {children}
                        </DetailContextProvider>
                    </CA21DetailComponent>
                </ProcedureEntryDetailComponent>
            </DocumentDetailWrapper>
        )
    }
});
