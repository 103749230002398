import { Spinner } from "@comic/precog-components";
import { OneBoxPage, useCurrentWorkspace } from "@drift/oneplatfront";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VaporToolbar } from "@vapor/react-custom";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    Button,
    Chip,
    FormControl,
    InputLabel,
    Stack
} from "@vapor/react-material";
import { every, isEmpty, isEqual, isNil, some } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import BackArrow from "../../components/BackArrow";
import usePrevious from "../../../core/commons/hooks/usePrevious";
import {
    BusinessesProvider,
    useBusinesses
} from "../../../core/contexts/Businesses";
import { AccountDto } from "../../../core/usecases/dtos/AccountsDto";
import { BusinessDto } from "../../../core/usecases/dtos/BusinessDto";
import { FiscalPayment } from "../../../core/usecases/dtos/FiscalPaymentDto";
import { useGetUnpaidDocuments } from "../../../core/usecases/useGetUnpaidDocuments";
import { useSettleAccount } from "../../../core/usecases/useSettleAccount";
import { QueryParam } from "../../../utils/appEnums";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import useQueryParam from "../../../utils/useQueryParam";
import routes from "../../commons/routes";
import SelectAccount from "../../components/Accounts/SelectAccount";
import SelectBusiness from "./SelectBusiness";
import SettleAccountTable from "./SettleAccountTable";
import { format } from "date-fns";
import { API_DATE_FORMAT } from "../../../config";

const fs = getFormattedStringWithScope("views.SettleAccount");

export interface Payment {
    fiscalPayment: FiscalPayment;
    amount: number;
    date: string;
    selected: boolean;
    processing: boolean;
}

export default function SettleAccount() {
    return (
        <BusinessesProvider>
            <SettleAccountView />
        </BusinessesProvider>
    );
}

export function SettleAccountView() {
    const { workspace, isLoading } = useCurrentWorkspace(true);
    const {
        simplifiedAccountingBusinesses: businesses,
        loading: isLoadingBusinesses
    } = useBusinesses();
    const businessId = useQueryParam().get(QueryParam.businessId);
    const {
        getUnpaidDocuments,
        loading: isLoadingUnpaidDocuments,
        unpaidDocuments
    } = useGetUnpaidDocuments();
    const previousUnpaidDocuments = usePrevious(unpaidDocuments);
    const { settleAccount, loading: isSettlingAccount } = useSettleAccount();
    const navigate = useNavigate();

    const [selectedBusiness, setSelectedBusiness] =
        useState<BusinessDto | null>(null);
    const [selectedAccount, setSelectedAccount] = useState<AccountDto | null>(
        null
    );
    const [active, setActive] = useState<boolean>(true);
    const [payments, setPayments] = useState<Payment[]>([]);

    const somePaymentsSelected = some(payments, (payment) => payment.selected);
    const allPaymentsSelected = every(payments, (payment) => payment.selected);
    const noPaymentsSelected = every(payments, (payment) => !payment.selected);

    const handleSelectBusiness = useCallback(
        async (business: BusinessDto) => {
            setSelectedBusiness(business);
            navigate(routes.settleAccount(business.id), { replace: true });
            await getUnpaidDocuments({
                businessId: business.id,
                active: active
            });
        },
        [getUnpaidDocuments, navigate, active]
    );

    const handleGetActiveDocuments = async () => {
        if (selectedBusiness) {
            setActive(true);
            await getUnpaidDocuments({
                businessId: selectedBusiness.id,
                active: true
            });
        }
    };
    const handleGetPassiveDocuments = async () => {
        if (selectedBusiness) {
            setActive(false);
            await getUnpaidDocuments({
                businessId: selectedBusiness.id,
                active: false
            });
        }
    };

    const handleToggleAllPayments = () => {
        setPayments((prevPayments) =>
            prevPayments.map((payment) => ({
                ...payment,
                selected: payment.processing
                    ? false
                    : noPaymentsSelected ||
                      (somePaymentsSelected && !allPaymentsSelected)
            }))
        );
    };

    const handleTogglePayment = (paymentId: string) => {
        setPayments((prevPayments) =>
            prevPayments.map((payment) =>
                payment.fiscalPayment.key !== paymentId
                    ? payment
                    : {
                          ...payment,
                          selected: !payment.selected
                      }
            )
        );
    };

    const handleChangePaymentAmount = (paymentId: string, amount: number) => {
        setPayments((prevPayments) =>
            prevPayments.map((payment) =>
                payment.fiscalPayment.key !== paymentId
                    ? payment
                    : {
                          ...payment,
                          amount: amount
                      }
            )
        );
    };

    const handleChangePaymentDate = (paymentId: string, date: Date) => {
        setPayments((prevPayments) =>
            prevPayments.map((payment) =>
                payment.fiscalPayment.key !== paymentId
                    ? payment
                    : {
                          ...payment,
                          date: format(date, API_DATE_FORMAT)
                      }
            )
        );
    };

    const handleConfirm = async () => {
        if (selectedBusiness && selectedAccount)
            settleAccount({
                businessId: selectedBusiness?.id,
                accountCode: selectedAccount?.code,
                payments: payments
                    .filter((payment) => payment.selected)
                    .map((payment) => ({
                        ...payment.fiscalPayment,
                        amount: payment.amount,
                        date: payment.date
                    }))
            })
                .then(() => {
                    const confirmedPaymentsIds = payments
                        .filter((payment) => payment.selected)
                        .map((payment) => payment.fiscalPayment.key);
                    setPayments((prevPayments) =>
                        prevPayments.map((payment) =>
                            confirmedPaymentsIds.includes(
                                payment.fiscalPayment.key
                            )
                                ? {
                                      ...payment,
                                      processing: true,
                                      selected: false
                                  }
                                : payment
                        )
                    );
                })
                .catch(() => {});
    };

    useEffect(() => {
        if (!selectedBusiness) {
            const businessFromQueryParam = businesses.find(
                (business) => business.id === businessId
            );
            setSelectedBusiness(businessFromQueryParam ?? null);
        }
    }, [businesses, selectedBusiness, businessId]);

    useEffect(() => {
        if (isNil(selectedBusiness) && !isEmpty(businesses)) {
            handleSelectBusiness(businesses[0]);
        }
    }, [businesses, handleSelectBusiness, selectedBusiness]);

    useEffect(() => {
        if (
            unpaidDocuments &&
            !isEqual(unpaidDocuments, previousUnpaidDocuments) &&
            !isEmpty(unpaidDocuments)
        ) {
            setPayments(
                unpaidDocuments.map((payment) => ({
                    amount: payment.remainingAmount.amount,
                    date: payment.date,
                    fiscalPayment: payment,
                    selected: false,
                    processing: false
                }))
            );
        }
    }, [unpaidDocuments, previousUnpaidDocuments]);

    const hasNotSelectedBusiness = isNil(selectedBusiness);

    return isLoading ? (
        <Spinner loading={true} />
    ) : (
        <>
            <Spinner loading={isLoadingBusinesses}>
                <OneBoxPage
                    title={fs("title")}
                    subtitle={workspace?.description}
                    showCustomerSelector={false}
                    headerLeft={<BackArrow />}
                >
                    <Box padding="32px" gap="24px">
                        {!isLoading &&
                        !isLoadingBusinesses &&
                        isEmpty(businesses) ? (
                            <Typography>
                                {fs("noSimplifiedAccountingBusiness")}
                            </Typography>
                        ) : (
                            <>
                                <Stack direction="row" spacing={3}>
                                    <SelectBusiness
                                        businesses={businesses}
                                        selectedBusiness={selectedBusiness}
                                        onSelectBusiness={handleSelectBusiness}
                                    />
                                    <FormControl
                                        disabled={hasNotSelectedBusiness}
                                        error={
                                            !isNil(selectedBusiness) &&
                                            isNil(selectedAccount)
                                        }
                                        required
                                        sx={{ width: "400px" }}
                                    >
                                        <InputLabel
                                            disabled={hasNotSelectedBusiness}
                                        >
                                            {fs("selectAccount")}
                                        </InputLabel>
                                        <SelectAccount
                                            value={selectedAccount}
                                            onChange={(account) =>
                                                setSelectedAccount(account)
                                            }
                                            transparent={false}
                                            disabled={hasNotSelectedBusiness}
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack
                                    direction="row"
                                    gap="32px"
                                    marginTop="24px"
                                >
                                    <Chip
                                        label={fs("emittedDocuments")}
                                        variant={active ? "outlined" : "filled"}
                                        onClick={handleGetActiveDocuments}
                                        disabled={isNil(selectedBusiness)}
                                    />
                                    <Chip
                                        label={fs("receivedDocuments")}
                                        variant={active ? "filled" : "outlined"}
                                        onClick={handleGetPassiveDocuments}
                                        disabled={isNil(selectedBusiness)}
                                    />
                                </Stack>
                                <Box marginTop="24px">
                                    {isNil(selectedBusiness) ? (
                                        <Typography variant="titleSmall">
                                            {fs("selectActivityToContinue")}
                                        </Typography>
                                    ) : (
                                        <Spinner
                                            loading={isLoadingUnpaidDocuments}
                                        >
                                            <SettleAccountTable
                                                active={active}
                                                payments={payments}
                                                onToggleAll={
                                                    handleToggleAllPayments
                                                }
                                                onToggle={handleTogglePayment}
                                                onChangeAmount={
                                                    handleChangePaymentAmount
                                                }
                                                onChangeDate={
                                                    handleChangePaymentDate
                                                }
                                            />
                                        </Spinner>
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                </OneBoxPage>
            </Spinner>
            <VaporToolbar
                contentRight={
                    <Button
                        variant="contained"
                        disabled={
                            noPaymentsSelected ||
                            isSettlingAccount ||
                            isNil(selectedBusiness) ||
                            isNil(selectedAccount)
                        }
                        onClick={handleConfirm}
                    >
                        {isSettlingAccount ? (
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                        ) : null}
                        {fs("registerPayment")}
                    </Button>
                }
            />
        </>
    );
}
