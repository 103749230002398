import { Grid } from "@vapor/react-material/Grid";

import { VatSettlement } from "../../../../../../core/usecases/interfaces/VatSettlement";
import getFormattedStringWithScope from "../../../../../../utils/getFormattedStringWithScope";
import LargeAmountWithLabel from "../../../../../components/LargeAmountWithLabel";
import { isNumber } from "lodash";

const fs = getFormattedStringWithScope("views.VATSettlement.infoRecap");

interface InfoRecapProps {
    settlement: VatSettlement;
}

const InfoRecap = ({ settlement }: InfoRecapProps) => {
    const previousCreditAmount =
        settlement.creditsAmounts["PRIOR_REGULAR_CREDIT"];
    const previousDebtAmount = settlement.creditsAmounts["PRIOR_REGULAR_DEBT"];
    const creditAmount = settlement.remaindersAmounts["REGULAR_CREDIT"];
    const debtAmount = settlement.remaindersAmounts["REGULAR_DEBT"];

    const amounts = [
        {
            label: fs("previousPeriodVatDebit"),
            value: previousDebtAmount
        },
        {
            label: fs("previousPeriodVatCredit"),
            value: previousCreditAmount
        },
        {
            label: fs("debitAmount"),
            value: debtAmount
        },
        {
            label: fs("creditAmount"),
            value: creditAmount
        }
    ];

    return (
        <Grid container rowGap={1} columnGap={{ xs: 1, sm: 2, md: 3 }}>
            {amounts.map(
                (amount) =>
                    isNumber(amount.value) && (
                        <Grid item>
                            <LargeAmountWithLabel
                                label={amount.label}
                                labelProps={{
                                    variant: "heading",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    color: "primary.textTitleColor"
                                }}
                                amountProps={{
                                    amount: amount.value,
                                    currency: "EUR",
                                    variant: "displaySmall",
                                    fontSize: "20px"
                                }}
                            />
                        </Grid>
                    )
            )}
        </Grid>
    );
};

export default InfoRecap;
