import { useRequest } from "@onefront/react-sdk";
import { useCallback, useState } from "react";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

export interface IDataToPostGroup {
    assetId: string;
    additionalCharge: boolean;
}

interface UsePostDraftAssetGroup {
    loading: boolean;
    error: any;
    result: string;
    fetch: Function;
    hasFetched: boolean;
    hasResponded: boolean;
}

interface UsePostDraftAssetGroupOptions {
    draftId: string | null;
    dataToPost: IDataToPostGroup[] | null;
    lazy: boolean;
}

export const usePostDraftAssetGroup = ({
    draftId,
    dataToPost,
    lazy
}: UsePostDraftAssetGroupOptions): UsePostDraftAssetGroup => {
    const [hasResponded, setHasResponded] = useState<boolean>(false);
    const { fetch, data, loading, error, hasLoaded } = useRequest({
        ...useAxiosConfigRequestParams("PostDraftAssetGroup"),
        method: "post",
        url: `${API_URL}/v1/precog/drafts/${draftId}/asset/group`,
        onResponse: () => setHasResponded(true),
        params: omitNilProperties({
            ...useCustomerData()
        }),
        data: { assetGroupList: dataToPost },
        lazy: lazy
    });

    const safeFetch = useCallback(() => {
        setHasResponded(false);
        fetch();
    }, [fetch]);

    return {
        fetch: safeFetch,
        loading: loading,
        error: error,
        result: data,
        hasFetched: hasLoaded,
        hasResponded: hasResponded
    };
};
