import { SxProps } from "@mui/system";
import {
    Autocomplete,
    FormControl,
    InputLabel,
    TextField
} from "@vapor/react-material";
import { isNil, omit } from "lodash";
import { Controller, useFormContext } from "react-hook-form";

import { VatRate } from "../../../utils/appEnums";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import { FormField } from "./types";

interface FormSelectVatRateProps extends FormField {
    sx?: SxProps;
    disablePortal?: boolean;
}

export default function FormSelectVatRate({
    name,
    label,
    required,
    disabled,
    sx,
    disablePortal
}: FormSelectVatRateProps) {
    const { control } = useFormContext();

    const fs = useFormattedStringWithScope("commons.VatRate");

    const vatRates = Object.values(VatRate);

    return (
        <Controller
            control={control}
            shouldUnregister={true}
            name={name}
            rules={{ required: required }}
            render={({ field, fieldState }) => (
                <FormControl
                    fullWidth
                    error={!isNil(fieldState.error)}
                    disabled={disabled}
                >
                    <InputLabel required={required} disabled={disabled}>
                        {label}
                    </InputLabel>
                    <Autocomplete
                        options={vatRates}
                        renderInput={(params) => (
                            <TextField {...params} disabled={disabled} />
                        )}
                        getOptionLabel={(option) =>
                            option.toString() !== ""
                                ? fs(option.toString())
                                : ""
                        }
                        onChange={(_: any, vatRate: any) =>
                            field.onChange(vatRate)
                        }
                        autoComplete={true}
                        autoHighlight={true}
                        autoSelect={true}
                        openOnFocus={true}
                        disablePortal={disablePortal}
                        disabled={disabled}
                        readOnly={disabled}
                        {...omit(field, "onChange", "disabled")}
                        sx={{ width: "100%", ...sx }}
                    />
                </FormControl>
            )}
        />
    );
}
