import { useRequest } from "@onefront/react-sdk";
import { format, isValid } from "date-fns";

import { API_DATETIME_FORMAT, API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import { DraftSource } from "../../../utils/appEnums";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

export interface MutateDraftOptions {
    registrationDate: Date;
    reasonId: string;
    businessId: string;
    sectionalId: string;
    regimeId: string;
    vatCollectability: string;
    refCurrency: string;
    amount: number;
    docId: string;
    docDate: Date;
    docNumber: string;
    docDescription: string;
    customerId: string;
    customerName: string;
    customerTaxId: string;
    customerVATNumber: string;
    supplierId: string;
    supplierName: string;
    supplierTaxId: string;
    supplierVATNumber: string;
    vatCompetencePeriod: Date;
    bankName: string;
    bankId: string;
    hasReverseCharge: boolean;
    reverseChargeSectionalId: string;
}

export interface UsePatchDraft {
    loading: boolean;
    error: any;
    update: (
        draftId: string,
        data: Partial<MutateDraftOptions>,
        fieldsToDelete?: string[],
        draftSource?: DraftSource
    ) => Promise<void>;
}

interface UsePatchDraftOptions {
    draftId?: string;
    dataToPatch?: MutateDraftOptions | null;
    lazy: boolean;
}

export const usePatchDraft = ({
    draftId,
    dataToPatch,
    lazy
}: UsePatchDraftOptions): UsePatchDraft => {
    const customerData = useCustomerData();

    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.PatchDraft");

    const { fetch, loading, error } = useRequest({
        ...useAxiosConfigRequestParams("PatchDraft"),
        method: "patch",
        url: `${API_URL}/v1/precog/drafts/${draftId}`,
        onError: (error: any) =>
            enqueueSnackbar(
                {
                    title: fs("error"),
                    content: error.response.data.message ?? ""
                },
                { severity: "error" }
            ),
        params: {
            ...useCustomerData()
        },
        data: {
            ...dataToPatch
        },
        lazy: lazy
    });

    const update = async (
        draftId: string,
        patchData: Partial<MutateDraftOptions>,
        fieldsToDelete?: string[]
    ) => {
        const preparedData = {
            ...patchData,
            docDate: patchData.docDate
                ? format(patchData.docDate, API_DATETIME_FORMAT)
                : undefined,
            registrationDate: patchData.registrationDate
                ? format(patchData.registrationDate, API_DATETIME_FORMAT)
                : undefined,
            vatCompetencePeriod:
                patchData.vatCompetencePeriod &&
                isValid(patchData.vatCompetencePeriod)
                    ? format(patchData.vatCompetencePeriod, API_DATETIME_FORMAT)
                    : undefined
        };
        return fetch({
            method: "patch",
            url: `${API_URL}/v1/precog/drafts/${draftId}`,
            params: {
                ...customerData
            },
            data: {
                ...preparedData,
                fieldsToDelete: fieldsToDelete
            }
        });
    };

    return {
        update,
        loading: loading,
        error: error
    };
};
