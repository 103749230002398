import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Stack } from "@vapor/react-material";
import Box from "@vapor/react-material/Box";
import { Button } from "@vapor/react-material/Button";
import { Divider } from "@vapor/react-material/Divider";

import { useAccountingLines } from "../../../../core/domain/AccountingLines/queries";
import { useDraft } from "../../../../core/domain/Draft/queries";

import { Draft } from "../../../../core/usecases/dtos/DashboardDto";
import {
    CloseReason,
    useDocumentDetail
} from "../../../../services/DocumentDetail";
import { Reason } from "../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import { AccountingLinesTable } from "../../../components/Accounting/AccountingLinesTable/table";
import { ConfirmDraftButton } from "../../../components/Accounting/ConfirmDraftButton";
import F24AccountingLinesTable from "../../../components/Accounting/F24AccountingLinesTable";

interface AccountingLinesCollapseProps {
    draft: Draft;
    onConfirm: (draftId: string) => void;
    onCloseDocumentDetail: (
        closeReason: CloseReason
    ) => (draftId: string) => void;
}

const fs = getFormattedStringWithScope("views.Dashboard.toBeAccounted.table");

export default function AccountingLinesCollapse({
    draft: propsDraft,
    onConfirm,
    onCloseDocumentDetail
}: AccountingLinesCollapseProps) {
    const {
        data: draft,
        isSuccess,
        isLoading: isLoadingDraft
    } = useDraft(propsDraft.id);
    const { isLoading: isLoadingAccountingLines } = useAccountingLines({
        draftId: propsDraft.id
    });

    const { open } = useDocumentDetail();

    if (isSuccess) {
        const handleConfirmDraft = async () => {
            onConfirm(draft.id);
        };

        const handleCloseDocumentDetail =
            (closeReason: CloseReason) => (draftId: string) => {
                onCloseDocumentDetail(closeReason)(draftId);
            };

        if (isLoadingDraft || isLoadingAccountingLines) {
            return (
                <Box m="auto">
                    <CircularProgress />
                </Box>
            );
        }

        return (
            <>
                {draft.reasonId &&
                    (draft.reasonId === Reason.PF24 ? (
                        <F24AccountingLinesTable draftId={draft.id} />
                    ) : (
                        <AccountingLinesTable
                            draftId={draft.id}
                            restricted={true}
                        />
                    ))}
                <Box
                    sx={{
                        paddingTop: "42px",
                        paddingBottom: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        flexDirection: "row"
                    }}
                >
                    <Stack direction="row" spacing={2} marginRight="8px">
                        <Box
                            sx={{
                                marginRight: "24px",
                                cursor: "pointer"
                            }}
                            tabIndex={0}
                        >
                            <ConfirmDraftButton
                                draftId={draft.id}
                                onConfirmSuccess={handleConfirmDraft}
                            />
                        </Box>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Box
                        sx={{
                            marginLeft: "16px",
                            cursor: "pointer"
                        }}
                        tabIndex={0}
                    >
                        <Button
                            endIcon={
                                <FontAwesomeIcon
                                    icon={faArrowRight}
                                    width={16}
                                />
                            }
                            onClick={() =>
                                open(draft.id, {
                                    onClose: (closeReason) =>
                                        handleCloseDocumentDetail(closeReason)(
                                            draft.id
                                        )
                                })
                            }
                        >
                            {fs("goDetail")}
                        </Button>
                    </Box>
                </Box>
            </>
        );
    }

    return null;
}
