import { Tag } from "@vapor/react-custom";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Card, CardContent, Stack } from "@vapor/react-material";
import { AccountingPeriodDto } from "../../../core/usecases/dtos/AccountingPeriodsDto";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import FormattedDate from "../../components/FormattedDate";

interface AccountingPeriodCardProps {
    accountingPeriod: AccountingPeriodDto;
    isSelected: boolean;
    onClickCard: (period: AccountingPeriodDto) => unknown;
}

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.AccountingPeriodCard"
);

export default function AccountingPeriodCard({
    accountingPeriod,
    isSelected,
    onClickCard
}: AccountingPeriodCardProps) {
    return (
        <Card
            onClick={() => onClickCard(accountingPeriod)}
            sx={{
                // TODO check if there is a vapor standard for these colors
                borderWidth: isSelected ? "1px 1px 4px 1px" : "",
                borderStyle: isSelected ? "solid" : "",
                borderImage: isSelected
                    ? "linear-gradient(to right, #00BFE6, #0090D1) 1"
                    : "",
                cursor: isSelected ? "default" : "pointer"
            }}
        >
            <CardContent>
                {/* YEAR - STATUS */}
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="titleMedium">
                        {new Date(accountingPeriod.startDate).getFullYear()}
                    </Typography>

                    <Tag
                        label={
                            accountingPeriod.closed
                                ? fs("status.closed")
                                : fs("status.open")
                        }
                        type={
                            accountingPeriod.closed
                                ? "islamicGreen"
                                : "blueSapphire"
                        }
                        variant="ghost"
                    />
                </Stack>
                <Stack direction="row">
                    {/* START PERIOD - END PERIOD - RESULT */}
                    <Stack direction="row">
                        {/* LABELS */}
                        <Stack mr={1}>
                            <Typography variant="body500">
                                {fs("startDate")}
                            </Typography>
                            <Typography variant="body500">
                                {fs("endDate")}
                            </Typography>
                            {/* TODO waiting for data from api */}
                            {/* <Typography variant="body500">
                                    Risultati di esercizio
                                </Typography> */}
                        </Stack>
                        {/* VALUES */}
                        <Stack>
                            <FormattedDate
                                color="primary.textSubduedColor"
                                date={accountingPeriod.startDate}
                                variant="body"
                            />

                            <FormattedDate
                                color="primary.textSubduedColor"
                                date={accountingPeriod.endDate}
                                variant="body"
                            />

                            {/* TODO waiting for risultati di esercizio data from api */}
                            {/* <Typography
                                    color="primary.textSubduedColor"
                                    variant="body"
                                >
                                    -
                                </Typography> */}
                        </Stack>
                    </Stack>

                    {/* TODO Waiting for API */}
                    {/* PROGRESS BAR */}
                    <Stack></Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}
