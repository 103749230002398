import { useRequest } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

export interface UsePatchDraftWithholding {
    loading: boolean;
    error: any;
    patch: (
        draftId: string,
        withholdingId: string,
        data: PatchData
    ) => Promise<void>;
}

export interface PatchData {
    cashContribution?: number;
    cashContributionNoWithholding?: number;
    cuReason?: string;
    description?: string;
    notIncomeAmount?: number;
    reasonId?: string;
    accountingReason?: string;
    totalNetAmount?: number;
    withholdingRate?: number;
    emensOtherInsurance?: string;
    emensActivityCode?: string;
    activityStartDate?: Date;
    activityEndDate?: Date;
    type?: string;
    exemptIncome?: number;
    notWithholdingIncome?: number;
    taxableAmount?: number;
    alreadySubjectedAmount?: number;
    tributeCode?: string;
    inpsContribution?: number;
    companyContribution?: number;
    emensReportType?: string;
    taxableContribution?: boolean;
}

export const usePatchDraftWithholding = (): UsePatchDraftWithholding => {
    const customerData = useCustomerData();

    const { fetch, loading, error } = useRequest({
        ...useAxiosConfigRequestParams("UpdateDraftWithholding"),
        method: "PATCH",
        lazy: true
    });

    const patch = async (
        draftId: string,
        withholdingId: string,
        data: PatchData
    ) => {
        return fetch({
            url: `${API_URL}/v1/precog/drafts/${draftId}/withholdings/${withholdingId}`,
            data,
            params: customerData
        });
    };

    return {
        patch,
        loading: loading,
        error: error
    };
};
