import Typography from "@vapor/react-extended/ExtendedTypography";
import { Divider, Stack } from "@vapor/react-material";

import { GroupedCreditDebitDto } from "../../../../../core/usecases/dtos/BalanceDto";
import { hasSimplifiedAccountingProps } from "../../../../../utils/balanceUtils";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import IncomeStatementActivities from "./IncomeStatementActivities";
import IncomeStatementPassivities from "./IncomeStatementPassivities";
import SimplifiedAccountingIncomeStatementActivities from "./SimplifiedAccountingIncomeStatementActivities";
import SimplifiedAccountingIncomeStatementPassivities from "./SimplifiedAccountingIncomeStatementPassivities";

interface IncomeStatementProps {
    incomeStatement: GroupedCreditDebitDto;
    onRowDetailClick: (accountId: string, accountName: string) => void;
}

const fs = getFormattedStringWithScope("components.BalanceTable");

export default function IncomeStatement({
    incomeStatement,
    onRowDetailClick
}: IncomeStatementProps) {
    const fiscalOperatingResult =
        incomeStatement.debit.totalAmount - incomeStatement.credit.totalAmount;

    const competenceOperatingResult =
        fiscalOperatingResult +
        incomeStatement.debit.totalSuspense -
        incomeStatement.credit.totalSuspense;

    const isSimplifiedAccounting =
        hasSimplifiedAccountingProps(incomeStatement.credit) ||
        hasSimplifiedAccountingProps(incomeStatement.debit);

    const maxIncomeStatementLength = Math.max(
        incomeStatement.credit.accounts
            .map((account) => account.accounts.length + 1)
            .reduce((a, b) => a + b, 0),
        incomeStatement.debit.accounts
            .map((account) => account.accounts.length + 1)
            .reduce((a, b) => a + b, 0)
    );

    return (
        <>
            <Typography variant="titleSmall">
                {fs("incomeStatement")}
            </Typography>
            {isSimplifiedAccounting ? (
                <Stack direction="row" justifyContent="center">
                    <SimplifiedAccountingIncomeStatementActivities
                        rows={incomeStatement.debit.accounts}
                        total={incomeStatement.debit.totalAmount}
                        totalPaid={incomeStatement.debit.totalAmount}
                        totalNotPaid={incomeStatement.debit.totalSuspense}
                        numRows={maxIncomeStatementLength}
                        onRowDetailClick={onRowDetailClick}
                        fiscalOperatingResult={fiscalOperatingResult}
                        competenceOperatingResult={competenceOperatingResult}
                    />
                    <Divider orientation="vertical" flexItem />
                    <SimplifiedAccountingIncomeStatementPassivities
                        rows={incomeStatement.credit.accounts}
                        total={incomeStatement.credit.totalAmount}
                        totalCollected={incomeStatement.credit.totalAmount}
                        totalNotCollected={incomeStatement.credit.totalSuspense}
                        numRows={maxIncomeStatementLength}
                        onRowDetailClick={onRowDetailClick}
                        fiscalOperatingResult={fiscalOperatingResult}
                        competenceOperatingResult={competenceOperatingResult}
                    />
                </Stack>
            ) : (
                <Stack direction="row" justifyContent="center">
                    <IncomeStatementActivities
                        rows={incomeStatement.debit.accounts}
                        total={incomeStatement.debit.totalAmount}
                        numRows={maxIncomeStatementLength + 1}
                        onRowDetailClick={onRowDetailClick}
                        operatingResult={
                            fiscalOperatingResult > 0
                                ? undefined
                                : Math.abs(fiscalOperatingResult)
                        }
                    />
                    <Divider orientation="vertical" flexItem />
                    <IncomeStatementPassivities
                        rows={incomeStatement.credit.accounts}
                        total={incomeStatement.credit.totalAmount}
                        numRows={maxIncomeStatementLength}
                        onRowDetailClick={onRowDetailClick}
                        operatingResult={
                            fiscalOperatingResult > 0
                                ? Math.abs(fiscalOperatingResult)
                                : undefined
                        }
                    />
                </Stack>
            )}
        </>
    );
}
