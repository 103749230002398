import pkg from "../package.json";

export const BASE_APP_NAME = "report-onefront";
export const APP_VERSION = pkg.version;

export const SKIP_AUTH = process.env.REACT_APP_SKIP_AUTH === "true";
export const SKIP_AUTHZ = process.env.REACT_APP_SKIP_AUTH === "true";

// http://localhost:3456 is the default url for the mock-server
export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3456";
export const ASYNC_API_URL =
    process.env.REACT_APP_ASYNC_API_URL || "http://localhost:3456";

export const REACT_APP_REGISTRY_ID = process.env.REACT_APP_REGISTRY_ID;

export const ONEBOX_APP_ID = process.env.REACT_APP_ONEBOX_APP_ID || "PRECOG";

export const API_DATE_FORMAT = "yyyy-MM-dd";

export const API_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";

export const API_DATETIME_FORMAT_WITHOUT_MILLISEDCONDS =
    "yyyy-MM-dd'T'HH:mm:ss'Z'";

export const ACCOUNTS_PAGINATION_SIZE = 20;

export const SNACKBAR_DURATION_MS = 6 * 1000;

export const POLLING_INTERVAL_MS = 5 * 1000;

export const EXEMPTION_NATURES_TO_VISUALIZE_IN_SELECT = 10;

export const FT_USE_NEW_DOC_DETAIL =
    process.env.REACT_APP_FT_USE_NEW_DOC_DETAIL === "true";

export const FT_SHOW_DASHBOARD_STATUS_COLUMN =
    process.env.REACT_APP_FT_SHOW_DASHBOARD_STATUS_COLUMN === "true";

export const FT_SHOW_DEPRECIATION_FIELDS = false;

export const FT_HANDLE_ASSET_DEPRECIATION_SUSPENSION = false;

export const FT_SHOW_APP_TITLE = false;

export const FT_SHOW_USEDGOODS_TABLE_COLUMNS = false;

export const LOGIN_API_URL =
    process.env.REACT_APP_LOGIN_API_URL ||
    "https://login-dev.teamsystemdigital.com";
