import { Stack, TextField } from "@vapor/react-material";
import { DatePicker } from "@vapor/react-x-date-pickers";

import { capitalize } from "lodash";
import { ProcedureEntryDetail } from "../../../../core/usecases/dtos/ProcedureEntryDetail";
import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";
import CurrencyField from "../../CurrencyField";
import CustomerSupplierInformation from "../../CustomerSupplierInformation";
import DocumentAmountsCard from "../../Header/Graphics/DocumentAmountsCard";
import HeaderFields from "../../Header/Graphics/HeaderFields";
import { TotalAmountSelectCurrency } from "../../Header/Graphics/styled";

interface HeaderProps {
    detail: ProcedureEntryDetail;
}

interface HeaderFieldProps {
    value: string;
    label?: string;
}

function HeaderTextField({ value, label }: HeaderFieldProps) {
    return <TextField readOnly value={value} label={label} />;
}

function HeaderDateField({ label, value }: HeaderFieldProps) {
    return (
        <DatePicker
            value={new Date(value)}
            readOnly
            label={label}
            sx={{ width: "100%" }}
        />
    );
}

export default function Header({ detail }: HeaderProps) {
    const fs = useFormattedStringWithScope("components.Header");

    const business = capitalize(detail.business);
    const businessSectional = detail.doc
        ? `${detail.doc?.protocol?.group} - ${business}`
        : business;

    return (
        <Stack spacing={3}>
            <HeaderFields
                documentDate={
                    detail.doc?.date && (
                        <HeaderDateField value={detail.doc.date} />
                    )
                }
                documentNumber={
                    detail.doc?.number && (
                        <HeaderTextField value={detail.doc.number} />
                    )
                }
                businessAndSectional={
                    business === "" ? null : (
                        <HeaderTextField
                            label={
                                businessSectional === business
                                    ? fs("business")
                                    : fs("businessAndSectional")
                            }
                            value={businessSectional}
                        />
                    )
                }
                customer={
                    detail.customer ? (
                        <CustomerSupplierInformation
                            name={detail.customer.name}
                            type="CUSTOMER"
                            taxId={detail.customer.identifier.taxId}
                            vatNumber={detail.customer.identifier.vatNumber}
                        />
                    ) : null
                }
                supplier={
                    detail.supplier ? (
                        <CustomerSupplierInformation
                            name={detail.supplier.name}
                            type="SUPPLIER"
                            taxId={detail.supplier.identifier.taxId}
                            vatNumber={detail.supplier.identifier.vatNumber}
                        />
                    ) : null
                }
                reason={
                    <HeaderTextField
                        label={fs("reason")}
                        value={detail.reason}
                    />
                }
                registrationDate={
                    <HeaderDateField
                        label={fs("registrationDate")}
                        value={detail.postingDate}
                    />
                }
                totalAmount={
                    <DocumentAmountsCard
                        currency={detail.totalAmount.currency}
                        totalAmount={
                            <CurrencyField
                                transparent={true}
                                big={true}
                                readOnly={true}
                                value={detail.totalAmount.value}
                            />
                        }
                        totalAmountCurrency={
                            <TotalAmountSelectCurrency
                                readOnly={true}
                                value={detail.totalAmount.currency}
                            />
                        }
                    />
                }
            />
        </Stack>
    );
}
