import { faAdjust } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { CheckCircle, Circle, HourglassHalf } from "@vapor/react-icons";
import VaporIcon from "@vapor/react-icons/VaporIcon";

import { TaskStatus } from "../../../../core/usecases/dtos/TaskStatus";
interface TaskIconProps {
    status?: TaskStatus | string;
}

export type TaskStatusIconMap = {
    [key in TaskStatus]: JSX.Element;
};

const TaskIcon = ({ status }: TaskIconProps) => {
    const errorIcon = (
        <VaporIcon icon={faExclamationTriangle} color="critical" size="16px" />
    );
    const iconHalf = <VaporIcon icon={faAdjust} color="info" size="16px" />;
    const iconSuccess = <CheckCircle color="info" />;
    const iconHourglassHalf = <HourglassHalf color="info" size="16px" />;

    switch (status) {
        case TaskStatus.TODO:
            return <Circle color="info" />;
        case TaskStatus.INACTIVE:
            return <Circle color="disabled" />;
        case TaskStatus.GENERATING:
            return iconHourglassHalf;
        case TaskStatus.COMPLETED:
            return iconSuccess;
        case TaskStatus.ERROR:
            return errorIcon;
        case TaskStatus.GENERATED:
            return iconSuccess;
        case TaskStatus.SIMULATING:
            return iconHourglassHalf;
        case TaskStatus.SIMULATED:
            return iconHalf;
        case TaskStatus.SIMULATION_ERROR:
            return errorIcon;
        case TaskStatus.GENERATION_ERROR:
            return errorIcon;
        case TaskStatus.REOPENING:
            return iconHourglassHalf;
        case TaskStatus.REOPEN_ERROR:
            return errorIcon;
        case TaskStatus.CANCELING:
            return iconHourglassHalf;
        case TaskStatus.CANCELING_ERROR:
            return errorIcon;
        default:
            return null;
    }
};

export default TaskIcon;
