import { useSnackbar } from "@onefront/react-sdk";
import { format } from "date-fns";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import { ListAssetsResponse } from "../dtos/ListAssetsDto";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import { concatenateData } from "./processData";
import usePagedGet from "../../commons/hooks/usePagedGet";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface UseGetListAssets {
    loading: boolean;
    loadingMore: boolean;
    hasMore: boolean;
    error: any;
    assets: ListAssetsResponse | null;
    fetch: Function;
    fetchMore: Function;
    hasFetched: boolean;
}

interface UseGetListAssetsOptions {
    description?: string | null;
    reference?: string | null;
    purchaseDateFrom?: Date | null;
    purchaseDateTo?: Date | null;
    saleDateFrom?: Date | null;
    saleDateTo?: Date | null;
    active?: boolean | null;
    lazy?: boolean;
    page: number;
    size: number;
}

export const useGetListAssets = ({
    description,
    reference,
    purchaseDateFrom,
    purchaseDateTo,
    saleDateFrom,
    saleDateTo,
    active,
    lazy,
    page,
    size
}: UseGetListAssetsOptions): UseGetListAssets => {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.GetListAssets");

    const concatenateAssetsList = (
        data: ListAssetsResponse,
        responseData: ListAssetsResponse
    ) => {
        return concatenateData(data, responseData);
    };

    const {
        fetch,
        fetchMore,
        data,
        loading,
        loadingMore,
        error,
        hasMore,
        hasFetched
    } = usePagedGet<ListAssetsResponse>({
        baseUrl: `${API_URL}/v2/asset`,
        scope: "ListAssets",
        concatenateData: concatenateAssetsList,
        onError: () => enqueueSnackbar(fs("error"), { variant: "error" }),
        params: omitNilProperties({
            description: description,
            reference: reference,
            purchaseDateFrom: purchaseDateFrom
                ? format(purchaseDateFrom, "yyyy-MM-dd")
                : undefined,
            purchaseDateTo: purchaseDateTo
                ? format(purchaseDateTo, "yyyy-MM-dd")
                : undefined,
            saleDateFrom: saleDateFrom
                ? format(saleDateFrom, "yyyy-MM-dd")
                : undefined,
            saleDateTo: saleDateTo
                ? format(saleDateTo, "yyyy-MM-dd")
                : undefined,
            active: active,
            page: page,
            size: size,
            ...useCustomerData()
        }),
        lazy: lazy
    });

    return {
        fetch: fetch,
        fetchMore: fetchMore,
        loading: loading,
        loadingMore: loadingMore,
        hasMore: hasMore,
        error: error,
        assets: error ? null : data,
        hasFetched: hasFetched
    };
};
