import { useCurrentWorkspace } from "@drift/oneplatfront-components";
import { useAuth } from "@onefront/react-sdk";
import { isNil } from "lodash";
import React, { useContext, useEffect } from "react";

import usePrevious from "../../core/commons/hooks/usePrevious";
import { Config } from "../../core/usecases/dtos/ConfigDto";
import { useGetCommencementDate } from "../../core/usecases/useGetCommencementDate";
import { useGetConfig } from "../../core/usecases/useGetConfig";

interface WorkspaceConfigComponentProps {
    children: any;
}

interface WorkspaceConfigContextType {
    config: Config | null;
    commencementDate: Date | null;
    error: boolean;
}

const WorkspaceConfigContext = React.createContext<WorkspaceConfigContextType>({
    config: null,
    commencementDate: null,
    error: false
});

export default function WorkspaceConfigComponent({
    children
}: WorkspaceConfigComponentProps) {
    const { workspace } = useCurrentWorkspace(true);
    const previousWorkspace = usePrevious(workspace);
    const { config, getConfig, error } = useGetConfig();
    const { commencementDate, get: getCommencementDate } =
        useGetCommencementDate();
    const auth = useAuth();

    // WARNING: if we don't check if the user is logged, requests start
    // no matter what, even if the user is not logged in
    // and if the only page displayed is the login page
    const userIsLogged = !isNil(auth.token) || !isNil(auth.tokenData);

    useEffect(() => {
        if (
            userIsLogged &&
            !isNil(workspace) &&
            workspace !== previousWorkspace
        ) {
            getConfig();
            getCommencementDate();
        }
    }, [
        workspace,
        previousWorkspace,
        getConfig,
        getCommencementDate,
        userIsLogged
    ]);

    return (
        <WorkspaceConfigContext.Provider
            value={{
                config,
                error: !!error,
                commencementDate: commencementDate
                    ? new Date(commencementDate.commencementDate)
                    : null
            }}
        >
            {children}
        </WorkspaceConfigContext.Provider>
    );
}

export const useWorkspaceConfig = () => {
    return useContext(WorkspaceConfigContext);
};

export const WorkspaceConfigService = () => ({
    target: "$REACT_ROOT_WRAPPER",
    handler: {
        component: (props: any) => <WorkspaceConfigComponent {...props} />
    }
});
