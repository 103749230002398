import { Spinner } from "@comic/precog-components";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import { Divider } from "@vapor/react-material/Divider";
import Grid from "@vapor/react-material/Grid";
import TextField from "@vapor/react-material/TextField";
import { useEffect, useState } from "react";

import { useGetAccount } from "../../../../../core/usecases/useGetAccount";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";

interface AccountRegistryTabProps {
    accountCode: string;
}

const fs = getFormattedStringWithScope(
    "components.Accounting.AccountingLinesTable.AccountDetailDrawer.AccountRegistry"
);

// TODO: we temporarily duplicate this component and its translations.
// the component under components/AccountsDetailDrawer will be deleted
export default function AccountRegistryTab({
    accountCode
}: AccountRegistryTabProps) {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);

    const {
        fetch: fetchAccount,
        account,
        loading: isLoadingAccount
    } = useGetAccount({
        accountCode: accountCode,
        lazy: true
    });

    useEffect(() => {
        if (!hasInitialized) {
            fetchAccount();
            setHasInitialized(true);
        }
    }, [fetchAccount, hasInitialized]);

    return (
        <Spinner loading={isLoadingAccount}>
            {account ? (
                <Grid>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "32px",
                            paddingBottom: "32px"
                        }}
                    >
                        <TextField
                            defaultValue={`${account?.description} (${account?.code})`}
                            readOnly
                            label={fs("accountDesc")}
                        />
                        <TextField
                            defaultValue={
                                account?.type ? fs(account?.type) : ""
                            }
                            readOnly
                            label={fs("accountType")}
                        />
                    </Box>
                    <Divider orientation="horizontal" light />
                    <Grid
                        sx={{ padding: "24px 0", display: "flex", gap: "8px" }}
                        direction="column"
                    >
                        <Typography
                            color="primary.textTitleColor"
                            variant="bodyLarge500"
                            sx={{ paddingBottom: "8px" }}
                        >
                            {fs("nonDeductibility")}
                        </Typography>
                        <Typography variant="body">
                            {fs("nonDeductibleRate")}
                            <Typography variant="body700">
                                {` ${account?.accountExtraInfo?.nonDeductibleRate} %`}
                            </Typography>
                        </Typography>
                        <Typography variant="body">
                            {fs("nonDeductibleIrapRate")}
                            <Typography variant="body700">
                                {` ${account?.accountExtraInfo?.nonDeductibleIrapRate} %`}
                            </Typography>
                        </Typography>
                        <Typography variant="body">
                            {fs("nonDeductibleStatutoryIrapRate")}
                            <Typography variant="body700">
                                {` ${account?.accountExtraInfo?.nonDeductibleStatutoryIrapRate} %`}
                            </Typography>
                        </Typography>
                    </Grid>
                    <Divider orientation="horizontal" light />
                    <Grid
                        sx={{ padding: "24px 0", display: "flex", gap: "8px" }}
                        direction="column"
                    >
                        <Typography
                            color="primary.textTitleColor"
                            variant="bodyLarge500"
                            sx={{ paddingBottom: "8px" }}
                        >
                            {fs("vat")}
                        </Typography>
                        <Typography variant="body">
                            {fs("nonDeductibleVATRate")}
                            <Typography variant="body700">
                                {` ${account?.accountExtraInfo?.nonDeductibleVATRate} %`}
                            </Typography>
                        </Typography>
                        <Typography variant="body">
                            {fs("vatDestinationDesc")}
                            <Typography variant="body700">
                                {` ${account?.accountExtraInfo?.vatDestinationDesc}`}
                            </Typography>
                        </Typography>
                    </Grid>
                    <Divider orientation="horizontal" light />
                    <Grid
                        sx={{ padding: "24px 0", display: "flex", gap: "8px" }}
                        direction="column"
                    >
                        <Typography variant="body">
                            {fs("withholding")}
                            <Typography variant="body700">
                                {account?.accountExtraInfo?.withholding
                                    ? " SI"
                                    : " NO"}
                            </Typography>
                        </Typography>
                        <Typography variant="body">
                            {fs("isAsset")}
                            <Typography variant="body700">
                                {account?.accountExtraInfo?.isAsset
                                    ? fs("isAssetTrue")
                                    : fs("isAssetFalse")}
                            </Typography>
                        </Typography>
                    </Grid>
                </Grid>
            ) : null}
        </Spinner>
    );
}
