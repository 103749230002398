import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AssetLogsSequenceDto } from "../dtos/AssetLogsSequenceDto";

interface UseGetAssetLogsSequence {
    loading: boolean;
    error: any;
    data: AssetLogsSequenceDto | null;
    fetch: Function;
}

interface UseGetAssetLogsSequenceOptions {
    assetId: string | undefined;
    businessId: string | undefined;
    type: string | null;
    sequence: string | null;
    lazy?: boolean;
}

export const useGetAssetLogsSequence = ({
    assetId,
    businessId,
    type,
    sequence,
    lazy
}: UseGetAssetLogsSequenceOptions): UseGetAssetLogsSequence => {
    const { fetch, data, loading, error } = useGet(
        `${API_URL}/v1/asset/${assetId}/logs/sequence?businessId=${businessId}&type=${type}&sequence=${sequence}`,
        {
            ...useAxiosConfigRequestParams("AssetLogsSequence"),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: lazy
        }
    );

    return {
        fetch: fetch,
        loading: loading,
        error: error,
        data: data
    };
};
