import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack } from "@vapor/react-material";

import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";

interface CustomerSupplierInformationProps {
    vatNumber: string;
    taxId: string;
    name?: string;
    type?: "CUSTOMER" | "SUPPLIER";
}

const CustomerSupplierInformation = ({
    taxId,
    vatNumber,
    name,
    type = "CUSTOMER"
}: CustomerSupplierInformationProps) => {
    const fs = useFormattedStringWithScope(
        "components.CustomerSupplierInformation"
    );

    return (
        <Stack
            alignItems="start"
            justifyContent="start"
            height="152px"
            marginTop={2}
        >
            {name && (
                <Stack direction="row" marginBottom={2}>
                    <Typography marginRight="8px">{fs(type)}</Typography>
                    <Typography fontWeight="bold">{name}</Typography>
                </Stack>
            )}
            <Stack direction="row">
                {vatNumber && (
                    <>
                        <Typography>{fs("vatNumber")}</Typography>
                        <Typography fontWeight="bold" marginLeft="8px">
                            {vatNumber}
                        </Typography>
                    </>
                )}
            </Stack>
            <Stack direction="row" marginTop="12px">
                <Typography>{fs("taxId")}</Typography>
                <Typography fontWeight="bold" marginLeft="8px">
                    {taxId}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default CustomerSupplierInformation;
