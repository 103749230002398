import { ArrowProps } from "react-multi-carousel/lib/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";

interface CustomArrowProps extends ArrowProps {
    direction: string;
    icon: IconProp;
}

const fs = getFormattedStringWithScope("views.VAT.carousel");

const CustomArrow = ({ onClick, direction, icon }: CustomArrowProps) => {
    return (
        <button
            onClick={onClick}
            aria-label={fs(direction) ?? ""}
            className={`month-carousel__arrow--${direction}`}
            type="button"
            style={{ zIndex: 3 }}
        >
            <FontAwesomeIcon icon={icon} />
        </button>
    );
};

export default CustomArrow;
