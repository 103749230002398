import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Checkbox from "@vapor/react-material/Checkbox";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import FormGroup from "@vapor/react-material/FormGroup";
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import xor from "lodash/xor";
import { useState } from "react";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";
import { differenceInDays } from "date-fns";
import isNil from "lodash/isNil";

import { includeSectionsEnum } from "../../../../core/usecases/dtos/AccountedForDto";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import DateRangePicker, {
    DateRangePickerState
} from "../../../components/DateRangePicker";

const fs = getFormattedStringWithScope("views.Dashboard.accountedFor.filters");

interface AccountedForProps {
    includeSections?: string[] | null;
    onChangeIncludeSections: (section: string[]) => void;
    onChangeIncludeDate: (range: DateRangePickerState) => void;
}

const FilterAccountedFor = ({
    includeSections,
    onChangeIncludeSections,
    onChangeIncludeDate
}: AccountedForProps) => {
    const [additionalFiltersVisible, setAdditionalFiltersVisible] =
        useState<boolean>(true);

    const handleDocNumberStateChange = (section: string) => {
        const newIncludeSections = xor(includeSections, [section]);
        onChangeIncludeSections(newIncludeSections);
    };

    const defaultDateRange: DateRangePickerState = {
        startDate: startOfYear(new Date()),
        endDate: endOfYear(new Date()),
        error: false
    };

    const [errorDateRange, setErrorDateRange] = useState<boolean>();

    const [selectedDateRange, setSelectedDateRange] =
        useState<DateRangePickerState>(defaultDateRange);

    const handleResetFilters = () => {
        handleDateRangePickerChange(defaultDateRange);
        onChangeIncludeSections([]);
    };

    const handleDateRangePickerChange = (
        newDateRange: DateRangePickerState
    ) => {
        setSelectedDateRange(newDateRange);
        if (checkRange1Year(newDateRange)) {
            setErrorDateRange(false);
            onChangeIncludeDate(newDateRange);
        } else {
            setErrorDateRange(true);
        }
    };

    function checkRange1Year(newDateRange: DateRangePickerState): boolean {
        const startDate = newDateRange.startDate ?? startOfYear(new Date());
        const endDate = newDateRange.endDate ?? endOfYear(new Date());
        return differenceInDays(endDate, startDate) <= 365;
    }

    return (
        <Box display="flex" width="100%">
            <Grid container xs={12} spacing={2} alignItems="flex-start">
                {additionalFiltersVisible && (
                    <>
                        <Grid item>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems={"flex-end"}
                            >
                                <DateRangePicker
                                    startDate={selectedDateRange.startDate}
                                    endDate={selectedDateRange.endDate}
                                    onChange={handleDateRangePickerChange}
                                    validate={(date) => !isNil(date)}
                                    isRequired={true}
                                />
                                <Button onClick={() => handleResetFilters()}>
                                    {fs("clearFilters")}
                                </Button>
                            </Stack>
                            {errorDateRange && (
                                <Typography color={"red"} variant={"body"}>
                                    {fs("errorDate")}
                                </Typography>
                            )}
                            {!errorDateRange && <br />}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{fs("documentWith")}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() =>
                                                handleDocNumberStateChange(
                                                    includeSectionsEnum.WITHHOLDING
                                                )
                                            }
                                            checked={includeSections?.includes(
                                                includeSectionsEnum.WITHHOLDING
                                            )}
                                        />
                                    }
                                    label={fs("witholdings")}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() =>
                                                handleDocNumberStateChange(
                                                    includeSectionsEnum.ASSET
                                                )
                                            }
                                            checked={includeSections?.includes(
                                                includeSectionsEnum.ASSET
                                            )}
                                        />
                                    }
                                    label={fs("assets")}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() =>
                                                handleDocNumberStateChange(
                                                    includeSectionsEnum.INTRASTAT
                                                )
                                            }
                                            checked={includeSections?.includes(
                                                includeSectionsEnum.INTRASTAT
                                            )}
                                        />
                                    }
                                    label={fs("intrastat")}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup></FormGroup>
                        </Grid>
                    </>
                )}
            </Grid>
            <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-end"
                width="100%"
                maxWidth="334px"
            >
                <Button
                    startIcon={
                        <FontAwesomeIcon
                            icon={
                                additionalFiltersVisible
                                    ? faAngleUp
                                    : faAngleDown
                            }
                        />
                    }
                    onClick={() =>
                        setAdditionalFiltersVisible(!additionalFiltersVisible)
                    }
                >
                    {fs("otherFilters")}
                </Button>
            </Box>
        </Box>
    );
};

export default FilterAccountedFor;
