import { useEffect, useState } from "react";

import usePrevious from "../../../core/commons/hooks/usePrevious";
import { AssetInfoResponse } from "../../../core/usecases/dtos/AssetInfoDto";
import { useGetGroupKindSubkind } from "../../../core/usecases/useGetGroupKindSubkind";
import {
    DraftAssetPatch,
    usePatchDraftAsset
} from "../../../core/usecases/usePatchDraftAsset";
import { TaxRelief } from "../../../utils/assetUtils";
import AssetDepreciation from "../../components/Assets/AssetDepreciation";

interface IDepreciationProps {
    assetInfo: AssetInfoResponse | null;
    assetId: string | null;
    draftId: string | null;
    subkindId: string | null;
    refCurrency: string | null;
    readOnly?: boolean;
    showReportInDays: boolean;
    onResetTaxBreaks?: () => void;
}

export enum statutoryDepreciationTypeEnum {
    TAX_CRITERIA = "TAX_CRITERIA",
    FIXED_PERCENTAGE = "FIXED_PERCENTAGE",
    DESC_METHOD = "DESC_METHOD",
    PERIOD = "PERIOD"
}

const Depreciation = ({
    assetInfo,
    assetId,
    draftId,
    subkindId,
    refCurrency,
    readOnly,
    showReportInDays,
    onResetTaxBreaks
}: IDepreciationProps) => {
    const groupId = assetInfo?.group;
    const previousGroupId = usePrevious(groupId);
    const kindId = assetInfo?.kind;
    const previousSubkindId = usePrevious(subkindId);
    const previousKindId = usePrevious(kindId);

    const [dataToPatch, setDataToPatch] = useState<DraftAssetPatch | null>(
        null
    );
    const [selectedTaxBreak, setSelectedTaxBreak] = useState<
        TaxRelief | undefined
    >(assetInfo?.taxRelief);

    const previousDataToPatch = usePrevious(dataToPatch);

    const { fetch: fetchGetGroupKindSubkind, groupKindSubkind } =
        useGetGroupKindSubkind({
            group: groupId,
            kind: kindId,
            subkind: subkindId,
            lazy: true
        });

    const { patchDraftAsset, patchDraftAssetParams } = usePatchDraftAsset({
        assetId: assetId,
        draftId: draftId,
        dataToPatch,
        lazy: true
    });

    useEffect(() => {
        if (
            !!dataToPatch &&
            previousDataToPatch !== dataToPatch &&
            draftId &&
            !!dataToPatch.fieldsToDelete &&
            onResetTaxBreaks
        ) {
            patchDraftAsset().then(() => {
                onResetTaxBreaks();
                setDataToPatch(null);
            });
        } else if (
            !!dataToPatch &&
            previousDataToPatch !== dataToPatch &&
            draftId
        ) {
            patchDraftAsset().then(() => setDataToPatch(null));
        }
    }, [
        onResetTaxBreaks,
        dataToPatch,
        draftId,
        patchDraftAsset,
        previousDataToPatch
    ]);

    const [taxDepreciationRate, setTaxDepreciationRate] = useState<
        number | null
    >(assetInfo?.taxDepreciationRate || groupKindSubkind?.ordinary || 0);

    const [taxDepreciationRateType, setTaxDepreciationRateType] = useState<
        string | undefined
    >(assetInfo?.taxDepreciationRateType);

    const [statutoryDepreciationType, setStatutoryDepreciationType] = useState<
        string | undefined
    >(assetInfo?.statutoryDepreciationType);

    const [statutoryDepreciationValue, setStatutoryDepreciationValue] =
        useState<number | undefined>(
            parseFloat(assetInfo?.statutoryDepreciationValue ?? "0")
        );

    const [currentSellingDepreciation, setCurrentSellingDepreciation] =
        useState(assetInfo?.sellingDepreciation);

    useEffect(() => {
        if (
            subkindId &&
            groupId &&
            kindId &&
            (subkindId !== previousSubkindId ||
                groupId !== previousGroupId ||
                kindId !== previousKindId)
        )
            fetchGetGroupKindSubkind();
    }, [
        fetchGetGroupKindSubkind,
        groupId,
        kindId,
        subkindId,
        previousGroupId,
        previousKindId,
        previousSubkindId
    ]);

    const handleChangeTaxDepreciationRate = async (newRate: number) => {
        await patchDraftAssetParams(assetId, undefined, draftId, {
            taxDepreciationRate: newRate
        });
        setTaxDepreciationRate(newRate);
    };

    const handleChangeStatutoryDepreciationValue = async (value: number) => {
        await patchDraftAssetParams(assetId, undefined, draftId, {
            statutoryDepreciationValue: value.toString()
        });
        setStatutoryDepreciationValue(value);
    };

    const handleChangeTaxDepreciationRateType = async (type: string) => {
        await patchDraftAssetParams(assetId, undefined, draftId, {
            taxDepreciationRateType: type
        });
        setTaxDepreciationRateType(type);
    };

    const handleChangeStatutoryDepreciationType = async (type: string) => {
        await patchDraftAssetParams(assetId, undefined, draftId, {
            statutoryDepreciationType: type,
            statutoryDepreciationValue: "0"
        });
        setStatutoryDepreciationValue(0);
        setStatutoryDepreciationType(type);
    };

    return (
        <AssetDepreciation
            refCurrency={refCurrency}
            assetInfo={assetInfo}
            onTaxDepreciationRateTypeChange={
                handleChangeTaxDepreciationRateType
            }
            setDataToPatch={setDataToPatch}
            taxDepreciationRateType={taxDepreciationRateType}
            taxDepreciationRate={taxDepreciationRate}
            groupKindSubkind={groupKindSubkind}
            handleChangeTaxDepreciationRate={handleChangeTaxDepreciationRate}
            statutoryDepreciationType={statutoryDepreciationType}
            onChangeStatutoryDepreciationType={
                handleChangeStatutoryDepreciationType
            }
            statutoryDepreciationValue={statutoryDepreciationValue}
            handleChangeStatutoryDepreciationValue={
                handleChangeStatutoryDepreciationValue
            }
            currentSellingDepreciation={currentSellingDepreciation}
            setCurrentSellingDepreciation={setCurrentSellingDepreciation}
            selectedTaxBreak={selectedTaxBreak}
            setSelectedTaxBreak={setSelectedTaxBreak}
            draftReadOnly={readOnly}
            showReportInDays={showReportInDays}
        />
    );
};

export default Depreciation;
