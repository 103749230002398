import { debounce } from "lodash";

import DebouncedControlledTextField from "../../DebouncedControlledTextField";

interface SelectTributeYearProps {
    value: string | null;
    readOnly?: boolean;
    onChange: (newValue: string) => void;
}

export default function SelectTributeYear({
    value,
    readOnly = false,
    onChange
}: SelectTributeYearProps) {
    const onc = debounce(onChange, 500);

    return (
        <DebouncedControlledTextField
            value={value ?? ""}
            type="number"
            readOnly={readOnly}
            onChange={(value) => onc(value)}
            sx={{
                "*": {
                    background: "none !important",
                    border: "none",
                    textAlign: "right"
                },
                // hide arrows in number input
                /* Chrome, Safari, Edge, Opera */
                "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button":
                    {
                        "-webkit-appearance": "none",
                        margin: 0
                    },

                /* Firefox */
                "input[type=number]": {
                    "-moz-appearance": "textfield"
                },
                // hide border-bottom
                "&::before": {
                    display: "none"
                },
                "&::after": {
                    display: "none"
                }
            }}
        />
    );
}
