import { useGet } from "@onefront/react-sdk";
import isNil from "lodash/isNil";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import {
    AccountingPeriodDto,
    AccountingPeriodsDto
} from "../dtos/AccountingPeriodsDto";

interface UseGetAccountingPeriodsOptions {
    lazy?: boolean;
}

export interface UseGetAccountingPeriods {
    fetch: () => void;
    data: AccountingPeriodDto[] | null;
    loading: boolean;
    error: any;
}

export default function useGetAccountingPeriods({
    lazy
}: UseGetAccountingPeriodsOptions): UseGetAccountingPeriods {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope(
        "notifications.GetAccountingPeriods"
    );

    const customerData = useCustomerData();

    const {
        fetch: fetchData,
        data,
        loading,
        error
    } = useGet(`${API_URL}/v1/ledger/accountingPeriods`, {
        ...useAxiosConfigRequestParams("GetAccountingPeriods"),
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        lazy: lazy
    });

    const fetch = useCallback(() => {
        fetchData({
            params: {
                ...customerData
            }
        });
    }, [fetchData, customerData]);

    return {
        fetch: fetch,
        data: error
            ? null
            : isNil(data)
            ? null
            : (data as AccountingPeriodsDto).periods,
        loading: loading,
        error: error
    };
}
