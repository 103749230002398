import { useGet } from "@onefront/react-sdk";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import { JournalType } from "../../../utils/appEnums";
import omitNilProperties from "../../../utils/omitNilProperties";
import { getOrdinalFromPeriod } from "../../../utils/periodAndOrdinalUtils";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { VatEntries } from "../dtos/VatEntriesDto";
import convertVatEntries from "./convertVatEntries";

interface UseGetVatEntries {
    loading: boolean;
    error: any;
    vatEntries: VatEntries[] | null;
    fetch: Function;
    hasFetched: boolean;
}

interface UseGetVatEntriesOptions {
    businessId?: string;
    year?: number;
    period?: string;
    vatCode?: string;
    journalType?: JournalType;
    sectional?: string | null;
    lazy: boolean;
}

export const useGetVatEntries = ({
    businessId,
    year,
    period,
    vatCode,
    journalType,
    sectional,
    lazy
}: UseGetVatEntriesOptions): UseGetVatEntries => {
    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/vat/businesses/vat-entries`,
        {
            ...useAxiosConfigRequestParams("VatEntries"),
            params: omitNilProperties({
                ...useCustomerData(),
                businessId: businessId,
                year: year,
                ordinal: period ? getOrdinalFromPeriod(period) : undefined,
                vatCode: vatCode,
                journalType:
                    journalType === JournalType.ALL ? undefined : journalType,
                sectional: sectional
            }),
            lazy: lazy
        }
    );

    const convertedData = data ? convertVatEntries(data) : null;

    const safeFetch = useCallback(() => fetch(), [fetch]);

    return {
        fetch: safeFetch,
        loading: loading,
        error: error,
        vatEntries: convertedData,
        hasFetched: hasLoaded
    };
};
