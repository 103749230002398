import { useCurrentTenant, useCurrentWorkspace } from "@drift/oneplatfront";
import { isNil } from "lodash";
import Onboarding from "../Onboarding";

interface ViewWrapperProps {
    children: any;
}

export default function ViewWrapper({ children }: ViewWrapperProps) {
    const { workspace, isLoading: loadingWorkspace } =
        useCurrentWorkspace(true);
    const { tenant, isLoading: loadingTenant } = useCurrentTenant(true);

    return loadingWorkspace ||
        loadingTenant ||
        isNil(workspace) ||
        isNil(tenant) ? null : (
        <Onboarding children={children} />
    );
}
