import { Spinner } from "@comic/precog-components";
import { faCircleNotch, faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VaporToolbar } from "@vapor/react-custom";
import { ExtendedTab } from "@vapor/react-extended/ExtendedTab";
import ExtendedTabs from "@vapor/react-extended/ExtendedTabs";
import { DialogContent } from "@vapor/react-material";
import { Box } from "@vapor/react-material/Box";
import { Button } from "@vapor/react-material/Button";
import { DialogTitle } from "@vapor/react-material/DialogTitle";
import { Divider } from "@vapor/react-material/Divider";
import { Drawer } from "@vapor/react-material/Drawer";
import { Grid } from "@vapor/react-material/Grid";
import { isNil } from "lodash";
import { useEffect, useState } from "react";

import usePrevious from "../../../core/commons/hooks/usePrevious";
import { useDraftAsset } from "../../../core/contexts/DraftAsset";
import { AssetLineDto } from "../../../core/usecases/dtos/AssetLinesDto";
import { useGetDraftAsset } from "../../../core/usecases/useGetDraftAsset";
import { useGetSubkinds } from "../../../core/usecases/useGetSubkinds";
import { usePatchDraftAsset } from "../../../core/usecases/usePatchDraftAsset";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import Depreciation from "./Depreciation";
import DrawerHeader from "./DrawerHeader";
import PurchaseIncrementalSpending from "./PurchaseIncrementalSpending";
import Registry from "./Registry";
import Sale from "./Sale";
import AssetsSearch from "./Search";

interface AssetsDrawerProps {
    assetIdOpened: string | null;
    assetInfoOpened: AssetLineDto | undefined;
    businessId: string | undefined;
    draftId: string | null;
    handleDrawer: (parentId?: string | null, uuid?: string | null) => void;
    isActive: boolean;
    isIncrementalSpending: boolean | null;
    openDrawer: boolean;
    refCurrency: string | null;
    newParentGroupId: string;
    assetGroupName?: string;
    isGroupingAsset: boolean;
    onCancelGrouping: () => void;
    readOnly?: boolean;
}

const fs = getFormattedStringWithScope("views.Detail.assets");

export enum AssetDrawerTab {
    REGISTRY = "registry",
    DEPRECIATION = "depreciation"
}

const AssetDrawer = ({
    assetIdOpened,
    assetInfoOpened,
    businessId,
    draftId,
    handleDrawer,
    isActive,
    isIncrementalSpending,
    openDrawer,
    refCurrency,
    assetGroupName,
    isGroupingAsset,
    onCancelGrouping,
    readOnly
}: AssetsDrawerProps) => {
    const {
        fetch: fetchAssetInfo,
        assetInfo,
        loading: loadingAssetInfo
    } = useGetDraftAsset({
        assetId: assetIdOpened,
        draftId: draftId,
        lazy: true
    });
    const [selectedAsset, setSelectedAsset] = useState<string | undefined>(
        undefined
    );
    const [showTabs, setShowTabs] = useState<boolean>(true);

    const previousOpenDrawer = usePrevious(openDrawer);
    const previousGroup = usePrevious(assetInfo?.group);
    const previousKind = usePrevious(assetInfo?.kind);
    const previousAssetIdOpened = usePrevious(assetIdOpened);

    const handleResetTaxBreaks = () => {
        fetchAssetInfo();
    };

    const { fetch: fetchSubSpeciesList, subkinds: subspeciesList } =
        useGetSubkinds({
            group: assetInfo?.group,
            kind: assetInfo?.kind,
            accountCode: assetInfo?.accountCode,
            lazy: true
        });

    const { deleteAssetGroup } = useDraftAsset();

    const { patchDraftAsset, loading, deleteFields } = usePatchDraftAsset({
        assetId: assetIdOpened,
        draftId: draftId,
        dataToPatch: {
            assetRegistryId: selectedAsset
        },
        lazy: true
    });

    const [selectedTab, setSelectedTab] = useState<AssetDrawerTab>(
        AssetDrawerTab.REGISTRY
    );
    const [currentSubkindId, setCurrentSubkindId] = useState<string | null>(
        null
    );

    const isPassiveAndIncremental = !isActive && isIncrementalSpending;

    const isSelectedExistingAsset = !!assetInfo?.assetRegistryId;

    useEffect(() => {
        if (
            (!subspeciesList ||
                subspeciesList.favourites.length === 0 ||
                subspeciesList.others.length === 0) &&
            previousGroup !== assetInfo?.group &&
            previousKind !== assetInfo?.kind
        )
            fetchSubSpeciesList();
    }, [
        subspeciesList,
        previousGroup,
        previousKind,
        assetInfo?.group,
        assetInfo?.kind,
        fetchSubSpeciesList
    ]);

    useEffect(() => {
        if (
            assetIdOpened !== previousAssetIdOpened &&
            openDrawer &&
            previousOpenDrawer !== openDrawer
        )
            fetchAssetInfo();
    }, [
        assetIdOpened,
        openDrawer,
        previousOpenDrawer,
        previousAssetIdOpened,
        fetchAssetInfo,
        selectedTab
    ]);

    const handleCancelAssociation = async () => {
        if (draftId) {
            await deleteFields(draftId, assetInfo?.uuid, assetInfo?.parentId, [
                "assetRegistryId"
            ]);
            await fetchAssetInfo();
        }
    };

    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={() => {
                handleDrawer();
            }}
            hideBackdrop
            width="50%"
            sx={{
                zIndex: "5500"
            }}
        >
            <DialogTitle>
                <Grid container alignItems={"center"}>
                    <Grid
                        item
                        xs
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                    >
                        {fs(
                            !isActive || isSelectedExistingAsset
                                ? "drawer.title"
                                : "drawer.titleSearch"
                        )}
                    </Grid>
                    <Grid item sx={{ marginRight: "-16px" }}>
                        <Button
                            onClick={() => {
                                handleDrawer();
                            }}
                        >
                            <FontAwesomeIcon icon={faClose} />
                        </Button>
                    </Grid>
                </Grid>
            </DialogTitle>

            <Divider variant="fullWidth" />
            <DialogContent>
                {showTabs && (
                    <Box sx={{ width: "100%" }}>
                        {((!isActive && !isIncrementalSpending) ||
                            isSelectedExistingAsset) && (
                            <ExtendedTabs
                                secondary={true}
                                size="small"
                                value={selectedTab}
                                onChange={(_, value) => {
                                    setSelectedTab(value as AssetDrawerTab);
                                    fetchAssetInfo();
                                }}
                            >
                                <ExtendedTab
                                    value={AssetDrawerTab.REGISTRY}
                                    label={fs("drawer.tabs.registry")}
                                />

                                <ExtendedTab
                                    value={AssetDrawerTab.DEPRECIATION}
                                    label={fs("drawer.tabs.depreciation")}
                                />
                            </ExtendedTabs>
                        )}
                    </Box>
                )}
                {loadingAssetInfo ? (
                    <Spinner loading={loadingAssetInfo}>
                        <Box height={10} marginTop={4}></Box>
                    </Spinner>
                ) : (
                    <>
                        <Box sx={{ width: "100%" }}>
                            {selectedTab === AssetDrawerTab.DEPRECIATION && (
                                <Box>
                                    <Depreciation
                                        onResetTaxBreaks={handleResetTaxBreaks}
                                        assetInfo={assetInfo}
                                        draftId={draftId}
                                        assetId={assetIdOpened}
                                        subkindId={currentSubkindId}
                                        refCurrency={refCurrency}
                                        readOnly={readOnly}
                                        showReportInDays={
                                            !isActive &&
                                            !assetInfo?.materialGood
                                        }
                                    />
                                </Box>
                            )}

                            {assetIdOpened &&
                                selectedTab === AssetDrawerTab.REGISTRY &&
                                (!isPassiveAndIncremental &&
                                !isActive &&
                                assetInfo !== null &&
                                !isSelectedExistingAsset ? (
                                    <Box>
                                        <Registry
                                            assetId={assetIdOpened}
                                            draftId={draftId}
                                            isGroupingAsset={
                                                isGroupingAsset ||
                                                !isNil(assetGroupName)
                                            }
                                            setCurrentSubkindId={
                                                setCurrentSubkindId
                                            }
                                            assetInfo={assetInfo}
                                            refCurrency={refCurrency}
                                            subspeciesList={subspeciesList}
                                            assetGroupName={assetGroupName}
                                            showTabs={showTabs}
                                            setShowTabs={setShowTabs}
                                            readOnly={readOnly}
                                        />
                                    </Box>
                                ) : (
                                    <Box marginTop={3} marginLeft={5}>
                                        {assetInfo &&
                                        isSelectedExistingAsset ? (
                                            <DrawerHeader
                                                refCurrency={
                                                    refCurrency ?? "EUR"
                                                }
                                                asset={assetInfo}
                                                onCancelAssociation={
                                                    handleCancelAssociation
                                                }
                                            />
                                        ) : null}
                                        {assetIdOpened &&
                                            isSelectedExistingAsset &&
                                            (isActive ? (
                                                <Sale
                                                    assetId={assetIdOpened}
                                                    businessId={businessId}
                                                    draftId={draftId}
                                                    refCurrency={refCurrency}
                                                    assetInfo={assetInfo}
                                                    isActive={isActive}
                                                    readOnly={readOnly}
                                                />
                                            ) : (
                                                <PurchaseIncrementalSpending
                                                    assetId={assetIdOpened}
                                                    businessId={businessId}
                                                    draftId={draftId}
                                                    refCurrency={refCurrency}
                                                    assetInfo={assetInfo}
                                                    readOnly={readOnly}
                                                />
                                            ))}
                                    </Box>
                                ))}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "end"
                            }}
                        >
                            <Grid container>
                                <Grid item xs>
                                    {
                                        /** if it is a grouping you can cancel it */
                                        !!assetInfoOpened?.parentId &&
                                            !readOnly && (
                                                <Box>
                                                    <Button
                                                        variant="text"
                                                        onClick={() =>
                                                            deleteAssetGroup(
                                                                draftId,
                                                                assetInfoOpened?.parentId
                                                            ).then(() => {
                                                                onCancelGrouping();
                                                                handleDrawer();
                                                            })
                                                        }
                                                    >
                                                        {fs(
                                                            "drawer.buttons.cancelGrouping"
                                                        )}
                                                    </Button>
                                                </Box>
                                            )
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                        <Box marginLeft={-3}>
                            {!(assetIdOpened && isSelectedExistingAsset) &&
                                (isActive || isPassiveAndIncremental) && (
                                    <AssetsSearch
                                        businessId={businessId}
                                        refCurrency={refCurrency}
                                        selectedAsset={selectedAsset}
                                        onConfirm={setSelectedAsset}
                                        accountCode={
                                            assetInfoOpened?.accountCode
                                        }
                                    />
                                )}
                            {!isActive && showTabs && (
                                <VaporToolbar
                                    variant="surface"
                                    withoutAppBar={false}
                                    contentRight={
                                        <>
                                            <Button
                                                variant="contained"
                                                sx={{ ml: 1 }}
                                                onClick={() => {
                                                    handleDrawer();
                                                }}
                                            >
                                                {fs("buttons.cancel")}
                                            </Button>
                                        </>
                                    }
                                />
                            )}
                            {isActive && isSelectedExistingAsset && (
                                <VaporToolbar
                                    variant="surface"
                                    withoutAppBar={false}
                                    contentRight={
                                        <>
                                            <Button
                                                variant="contained"
                                                sx={{ ml: 1 }}
                                                onClick={() => {
                                                    handleDrawer();
                                                }}
                                            >
                                                {fs("buttons.save")}
                                            </Button>
                                        </>
                                    }
                                />
                            )}
                            {!(assetIdOpened && isSelectedExistingAsset) &&
                                (isActive || isPassiveAndIncremental) && (
                                    <VaporToolbar
                                        variant="surface"
                                        withoutAppBar={false}
                                        contentRight={
                                            <Button
                                                variant="contained"
                                                disabled={
                                                    !selectedAsset || loading
                                                }
                                                sx={{ ml: 1 }}
                                                onClick={() => {
                                                    patchDraftAsset().then(
                                                        () => {
                                                            fetchAssetInfo();
                                                        }
                                                    );
                                                    setSelectedAsset(undefined);
                                                }}
                                            >
                                                {loading ? (
                                                    <FontAwesomeIcon
                                                        icon={faCircleNotch}
                                                        spin
                                                    />
                                                ) : (
                                                    fs("buttons.confirm")
                                                )}
                                            </Button>
                                        }
                                    />
                                )}
                        </Box>
                    </>
                )}
            </DialogContent>
        </Drawer>
    );
};

export default AssetDrawer;
