import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useGet } from "@onefront/react-sdk";
import { useQuery } from "@tanstack/react-query";

import { API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { PrecogDraftVat } from "../../usecases/dtos/PrecogDraftVatDto";

const useGetPrecogDraftVat = () => {
    const customerData = useCustomerData();

    const { fetch } = useGet<PrecogDraftVat[]>(`ignored`, {
        ...useAxiosConfigRequestParams("PrecogDraftVat"),
        lazy: true
    });

    return {
        fetch: async (draftId: string) => {
            return fetch({
                url: `${API_URL}/v1/precog/drafts/${draftId}/vat`,
                params: {
                    ...customerData
                }
            }).then((response) => response.data);
        }
    };
};

export const vatLinesQueryKeys = createQueryKeys("precog-draft-vat", {
    all: (draftId: string) => [{ draftId }],
    get: (draftId: string) => [{ draftId }]
});

export const useVatLines = (draftId?: string) => {
    const { fetch } = useGetPrecogDraftVat();

    const { queryKey } = vatLinesQueryKeys.get(draftId!);

    return useQuery({
        queryKey,
        enabled: !!draftId,
        queryFn: () => fetch(draftId!)
    });
};
