import { Spinner } from "@comic/precog-components";
import { Times } from "@vapor/react-icons";
import {
    Box,
    Chip,
    DialogActions,
    Divider,
    Stack
} from "@vapor/react-material";
import { getYear } from "date-fns";
import { xor } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { useCurrentWorkspace } from "@drift/oneplatfront";
import {
    useCancelDepreciation,
    useFetchDepreciation,
    useFetchSimulateDepreciation,
    useGenerateDepreciation
} from "../../../../core/domain/Depreciation/queries";
import { AccountingPeriodDto } from "../../../../core/usecases/dtos/AccountingPeriodsDto";
import { DepreciationStatus } from "../../../../core/usecases/dtos/CheckDepreciationDto";
import { DepreciationSimulationDto } from "../../../../core/usecases/dtos/DepreciationSimulationDto";
import useGetBusinesses from "../../../../core/usecases/useGetBusinesses";
import { DepreciationScope } from "../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../../components/CustomButton";
import OverlayDialog from "../../../components/OverlayDialog";
import TitleCenteredDialog from "../../../components/TitleCenteredDialog";
import DepreciationAlertBanner from "./DepreciationAlertBanner";
import DepreciationForm from "./DepreciationForm";
import DepreciationHeader from "./DepreciationHeader";

export interface DepreciationData extends DepreciationSimulationDto {
    percentage: number;
    applicationScope: DepreciationScope | null;
    businessDescription: string;
}

interface DepreciationOverlayProps {
    isOpen: boolean;
    onClose: () => void;
    depreciationStatus?: DepreciationStatus | string;
    selectedAccountingPeriod: AccountingPeriodDto;
}

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.DepreciationOverlay"
);

export default function DepreciationOverlay({
    isOpen,
    onClose,
    depreciationStatus,
    selectedAccountingPeriod
}: DepreciationOverlayProps) {
    const { id: workspaceId } = useCurrentWorkspace(true)?.workspace ?? {
        id: ""
    };

    const {
        data: simulation,
        isLoading: simulating,
        isFetching: isFetchingSimulate
    } = useFetchSimulateDepreciation(
        {
            endDate: selectedAccountingPeriod.endDate,
            startDate: selectedAccountingPeriod.startDate
        },
        depreciationStatus ?? ""
    );
    const {
        data: depreciation,
        isLoading: loadingDepreciation,
        isFetching
    } = useFetchDepreciation(
        {
            period: getYear(
                new Date(selectedAccountingPeriod?.endDate ?? "")
            ).toString()
        },
        depreciationStatus ?? ""
    );

    const {
        mutateAsync: generateDepreciation,
        isPending: isPendingGenerateDepreciation
    } = useGenerateDepreciation(selectedAccountingPeriod.id);

    const {
        mutateAsync: cancelDepreciation,
        isPending: isCancelDepreciationPending
    } = useCancelDepreciation(selectedAccountingPeriod.id);

    const { businesses } = useGetBusinesses();
    const refCurrency = "EUR";

    const [depreciations, setDepreciations] = useState<DepreciationData[]>([]);
    const [selectedBusinesses, setSelectedBusinesses] = useState<string[]>([]);
    const visibleDepreciations = useMemo(() => {
        return depreciations.filter((depreciation) =>
            selectedBusinesses?.includes(depreciation.business)
        );
    }, [selectedBusinesses, depreciations]);

    useEffect(() => {
        setSelectedBusinesses(businesses.map((business) => business.id));
    }, [businesses]);

    useEffect(() => {
        if ((simulation || depreciation) && businesses) {
            setDepreciations(
                (depreciation ?? simulation ?? []).map((depreciation) => ({
                    ...depreciation,
                    percentage: 0,
                    applicationScope: null,
                    checked: false,
                    businessDescription:
                        businesses.find(
                            (business) => business.id === depreciation.business
                        )?.description ?? ""
                }))
            );
        }
    }, [businesses, depreciation, simulation]);

    const handleChangeDepreciation = (depreciation: DepreciationData) => {
        setDepreciations((prevDepreciations) =>
            prevDepreciations.map((prevDepreciation) =>
                prevDepreciation.key === depreciation.key
                    ? depreciation
                    : prevDepreciation
            )
        );
    };

    const handleCancelDepreciation = async () => {
        if (selectedAccountingPeriod) {
            await cancelDepreciation({
                workspaceId: workspaceId,
                period: getYear(
                    new Date(selectedAccountingPeriod.endDate)
                ).toString()
            });
            onClose();
        }
    };

    const handleGenerateDepreciation = async () => {
        if (selectedAccountingPeriod) {
            await generateDepreciation({
                period: getYear(
                    new Date(selectedAccountingPeriod.endDate)
                ).toString(),
                date: new Date(selectedAccountingPeriod.endDate)
            });
            onClose();
        }
    };

    const handleToggleBusiness = (business: string) => {
        setSelectedBusinesses((prevSelectedBusinesses) =>
            prevSelectedBusinesses.includes(business) &&
            prevSelectedBusinesses.length === 1
                ? prevSelectedBusinesses
                : xor(prevSelectedBusinesses, [business])
        );
    };

    return (
        <OverlayDialog open={isOpen} onClose={onClose}>
            <TitleCenteredDialog
                title={fs("depreciation")}
                icon={<Times />}
                onClose={onClose}
            />
            <Divider variant="fullWidth" />
            <Box>
                <Spinner loading={simulating || loadingDepreciation}>
                    <Box padding={4}>
                        <Box>
                            <Stack
                                direction="column"
                                marginBottom={2}
                                spacing={2}
                            >
                                <DepreciationHeader
                                    accountingPeriod={selectedAccountingPeriod}
                                    visibleDepreciations={visibleDepreciations}
                                    refCurrency={refCurrency}
                                />
                                <Stack>
                                    <DepreciationAlertBanner
                                        status={depreciationStatus}
                                    />
                                    <Stack
                                        alignItems="flex-start"
                                        columnGap={3}
                                        direction="row"
                                        flexWrap="wrap"
                                        paddingTop={4}
                                        rowGap={3}
                                    >
                                        {businesses.map((business) => (
                                            <Chip
                                                key={business.id}
                                                label={business.description}
                                                variant={
                                                    selectedBusinesses?.includes(
                                                        business.id
                                                    )
                                                        ? "outlined"
                                                        : "filled"
                                                }
                                                onClick={() =>
                                                    handleToggleBusiness(
                                                        business.id
                                                    )
                                                }
                                            />
                                        ))}
                                    </Stack>
                                </Stack>
                            </Stack>
                            <DepreciationForm
                                depreciations={visibleDepreciations}
                                onChangeDepreciation={handleChangeDepreciation}
                            />
                        </Box>
                    </Box>
                </Spinner>
                <DialogActions
                    sx={{
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        width: "100%"
                    }}
                >
                    {(depreciationStatus === DepreciationStatus.TODO ||
                        depreciationStatus ===
                            DepreciationStatus.GENERATION_ERROR) && (
                        <CustomButton
                            color="primary"
                            disabled={isFetching}
                            onClick={handleGenerateDepreciation}
                            variant="contained"
                            loading={isPendingGenerateDepreciation}
                        >
                            {fs("generateDepreciation")}
                        </CustomButton>
                    )}
                    {depreciationStatus === DepreciationStatus.GENERATING && (
                        <CustomButton
                            color="primary"
                            disabled
                            loading={true}
                            variant="contained"
                        >
                            {fs("generatingDepreciation")}
                        </CustomButton>
                    )}
                    {(depreciationStatus === DepreciationStatus.GENERATED ||
                        depreciationStatus === DepreciationStatus.COMPLETED ||
                        depreciationStatus === DepreciationStatus.CANCELING ||
                        depreciationStatus ===
                            DepreciationStatus.CANCELING_ERROR) && (
                        <CustomButton
                            onClick={handleCancelDepreciation}
                            disabled={
                                isCancelDepreciationPending ||
                                depreciationStatus ===
                                    DepreciationStatus.CANCELING ||
                                isFetchingSimulate
                            }
                            loading={
                                isCancelDepreciationPending ||
                                depreciationStatus ===
                                    DepreciationStatus.CANCELING
                            }
                            color="primary"
                            variant="contained"
                        >
                            {fs("cancelDepreciation")}
                        </CustomButton>
                    )}
                </DialogActions>
            </Box>
        </OverlayDialog>
    );
}
