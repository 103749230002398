import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExtendedTypography } from "@vapor/react-extended";
import { Menu, MenuItem } from "@vapor/react-material";
import IconButton from "@vapor/react-material/IconButton";
import { capitalize, isEmpty, isNil, uniqBy } from "lodash";
import { useState } from "react";

import {
    useAccountingLines,
    useMutateAccountingLine
} from "../../../../../core/domain/AccountingLines/queries";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import { MenuGroupTitle } from "../../../styled";
import { useAddedAccounts } from "../state";
import SplitArticleDialog from "./SplitArticleDialog";

interface ArticleActionsProps {
    draftId: string;
    accountingLineId?: string;
}

const fs = getFormattedStringWithScope(
    "components.Accounting.AccountingLinesTable.ArticleActions"
);

export default function ArticleActions({
    draftId,
    accountingLineId
}: ArticleActionsProps) {
    const { data: accountingLines, isSuccess } = useAccountingLines({
        draftId
    });
    const { mutate } = useMutateAccountingLine({
        draftId,
        accountingLineId: accountingLineId!
    });

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
        null
    );

    const [isSplitArticleDrawerOpen, setIsSplitArticleDrawerOpen] =
        useState<boolean>(false);

    const addedAccounts = useAddedAccounts();

    if (!isSuccess) {
        return null;
    }

    const { accountCode } = accountingLines.find(
        (accountingLine) => accountingLine.uuid === accountingLineId
    ) ?? { accountCode: "" };

    const isOpen = !isNil(menuAnchorEl);

    const handleShowMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    const handleSplitArticleClick = () => {
        setIsSplitArticleDrawerOpen(true);
        setMenuAnchorEl(null);
    };

    const handleCloseSplitArticleDialog = () => {
        setIsSplitArticleDrawerOpen(false);
    };

    const handleMoveArticle = (accountCode: string, accountDesc?: string) => {
        mutate({
            accountCode: accountCode,
            accountDesc: accountDesc
        });
        setMenuAnchorEl(null);
    };

    const accountingLinesWithoutCurrentAccountAndTechAccounts =
        accountingLines.filter(
            (accountingLine) =>
                accountingLine.accountCode !== accountCode &&
                !accountingLine.isReadOnly
        );

    const uniqueAccountCodesAndDesc = uniqBy(
        addedAccounts
            .map((addedAccount) => ({
                accountCode: addedAccount.code,
                accountDesc: addedAccount.description
            }))
            .concat(
                accountingLinesWithoutCurrentAccountAndTechAccounts.map(
                    (accountingLine) => ({
                        accountCode: accountingLine.accountCode,
                        accountDesc: accountingLine.accountDesc
                    })
                )
            ),
        "accountCode"
    );

    const menuOptions = uniqueAccountCodesAndDesc.map((account, index) => (
        <MenuItem
            key={index}
            onClick={() =>
                handleMoveArticle(account.accountCode, account.accountDesc)
            }
        >
            {capitalize(account.accountDesc)}
        </MenuItem>
    ));

    return (
        <>
            <IconButton
                color="primary"
                onClick={handleShowMenu}
                id="basic-button"
            >
                <FontAwesomeIcon icon={faEllipsisVertical} />
            </IconButton>
            <Menu anchorEl={menuAnchorEl} open={isOpen} onClose={handleClose}>
                <MenuGroupTitle disabled>
                    <ExtendedTypography variant="body500">
                        {fs("moveArticleIn")}
                    </ExtendedTypography>
                </MenuGroupTitle>
                {isEmpty(menuOptions) ? (
                    <MenuItem disabled>{fs("noAvailableAccount")}</MenuItem>
                ) : (
                    menuOptions
                )}
                <MenuGroupTitle disabled>
                    <ExtendedTypography variant="body500">
                        {fs("otherActions")}
                    </ExtendedTypography>
                </MenuGroupTitle>
                <MenuItem onClick={handleSplitArticleClick}>
                    {fs("splitArticle")}
                </MenuItem>
            </Menu>
            <SplitArticleDialog
                draftId={draftId}
                isOpen={isSplitArticleDrawerOpen}
                onClose={handleCloseSplitArticleDialog}
                accountingLine={accountingLines.find(
                    (accountingLine) => accountingLine.uuid === accountingLineId
                )}
            />
        </>
    );
}
