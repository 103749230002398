import { useRequest } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { VatSettlementEntry } from "../dtos/VatSettlementDto";

export interface OpenSettlementOptions
    extends Pick<VatSettlementEntry, "year" | "ordinal"> {}
interface UsePostVatSettlementOpen {
    loading: boolean;
    error: any;
    result: any;
    fetch: (options: OpenSettlementOptions) => Promise<void>;
    hasFetched: boolean;
}

export const usePostVatSettlementOpen = (): UsePostVatSettlementOpen => {
    const customerData = useCustomerData();

    const fs = useFormattedStringWithScope("notifications.OpenSettlement");

    const { enqueueSnackbar } = useSnackbar();

    const {
        fetch: fetchData,
        data,
        loading,
        error,
        hasLoaded
    } = useRequest({
        url: `${ASYNC_API_URL}/vat/settlements/open`,
        method: "post",
        ...useAxiosConfigRequestParams("VatSettlementOpen"),
        onResponse: () =>
            enqueueSnackbar(fs("success"), { severity: "success" }),
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        lazy: true
    });

    const fetch = ({ year, ordinal }: OpenSettlementOptions) => {
        return fetchData({
            data: {
                ...customerData,
                year: year,
                period: ordinal
            }
        });
    };

    return {
        fetch: fetch,
        loading: loading,
        error: error,
        result: data,
        hasFetched: hasLoaded
    };
};
