import { Box } from "@vapor/react-material";
import Autocomplete from "@vapor/react-material/Autocomplete";
import TextField from "@vapor/react-material/TextField";
import { capitalize } from "lodash";

import { useWithholdings } from "../../../core/contexts/Withholdings";
import { DraftWithholdingDto } from "../../../core/usecases/dtos/DraftWithholdingDto";
import { Reasons } from "../../../core/usecases/dtos/WithholdingReasonsDto";
import { useGetWithholdingsReasons } from "../../../core/usecases/useGetWithholdingsReasons";

interface SelectWithholdingDescProps {
    draftId: string;
    withholding: DraftWithholdingDto;
    totalNetAmount: number;
    cuReason: any;
}

export default function SelectWithholdingDesc({
    draftId,
    withholding,
    cuReason,
    totalNetAmount
}: SelectWithholdingDescProps) {
    const {
        fetch: fetchReasons,
        reasons,
        loading
    } = useGetWithholdingsReasons({
        lazy: true
    });

    const { patch, get } = useWithholdings().draft;

    const compareOptionAndValue = (option: any, value: any) => {
        return option.code === value.code;
    };

    const handleChangeReason = async (reason: Reasons) => {
        if (draftId) {
            await patch(draftId, withholding.uuid, {
                reasonId: reason.code,
                description: reason.description,
                cuReason: reason.cuReason,
                withholdingRate: reason.withholdingRate,
                notIncomeAmount: withholding.notIncomeAmount,
                cashContributionNoWithholding:
                    withholding.cashContributionNoWithholding,
                totalNetAmount
            });
            await get(draftId);
        }
    };

    const value: Reasons = reasons?.reasons?.find(
        (reason: Reasons) => reason.code === withholding.reasonId
    ) ?? {
        code: withholding.reasonId,
        baseTaxableShare: 0,
        cuReason: withholding.cuReason,
        description: withholding.description,
        shortDescription: "",
        withholdingRate: 0
    };

    return (
        <Autocomplete
            onOpen={() => fetchReasons(cuReason)}
            value={value}
            isOptionEqualToValue={compareOptionAndValue}
            options={reasons?.reasons ?? []}
            renderInput={(params) => <TextField {...params} type="search" />}
            renderOption={(props, reason: any) => (
                <Box component="li" {...props}>
                    {capitalize(reason.description)}
                </Box>
            )}
            getOptionLabel={(reason: any) => capitalize(reason.description)}
            onChange={(_: any, reason) => handleChangeReason(reason as Reasons)}
            loading={loading}
            // filterOptions defaulted as per https://mui.com/material-ui/react-autocomplete/
            filterOptions={(x) => x}
            componentsProps={{
                popper: {
                    placement: "bottom-start",
                    sx: {
                        minWidth: "max-content"
                    }
                }
            }}
            sx={{
                width: "100%",
                "*": {
                    background: "none !important",
                    border: "none"
                },
                "& .MuiAutocomplete-endAdornment": {
                    display: "none"
                }
            }}
        />
    );
}
