import {
    ColumnElement,
    ColumnType,
    HeadCell,
    Table
} from "@comic/precog-components";
import { faArrowRight, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import IconButton from "@vapor/react-material/IconButton";
import { useState } from "react";

import { VatEntries } from "../../../../../../../core/usecases/dtos/VatEntriesDto";
import { useDetail } from "../../../../../../../services/DetailFacade";
import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../../../components/FormattedAmount";

const fs = getFormattedStringWithScope("views.VATSettlement.vatEntries.table");

interface VATSettlementDetailTableProps {
    data: VatEntries[];
}

const VATSettlementDetailTable = ({ data }: VATSettlementDetailTableProps) => {
    const { open, isOpening } = useDetail();

    const [originalEntryId, setOriginalEntryId] = useState<string | null>(null);

    const handleOpenDetailClick = async (registration: VatEntries) => {
        setOriginalEntryId(registration.registrationUuid);
        await open(registration, registration.reasonId, {
            readOnly: true
        });
    };

    const headCells: HeadCell[] = [
        {
            id: "reasonDescription",
            kind: ColumnType.text,
            label: fs("reason")
        },
        {
            id: "registrationDate",
            kind: ColumnType.text,
            label: fs("registrationDate")
        },
        {
            id: "docNumber",
            kind: ColumnType.text,
            label: fs("docNumber")
        },
        {
            id: "docDate",
            kind: ColumnType.text,
            label: fs("documentDate")
        },
        {
            id: "vatCollectability",
            kind: ColumnType.text,
            label: fs("vatCollectability")
        },
        {
            id: "taxable",
            kind: ColumnType.text,
            label: fs("taxable")
        },
        {
            id: "tax",
            kind: ColumnType.text,
            label: fs("tax")
        },
        {
            id: "",
            kind: ColumnType.cta,
            label: ""
        }
    ];

    const columns: ColumnElement[] = [
        // reasonDescription
        {
            kind: ColumnType.text,
            render: (detail: VatEntries) => (
                <Typography>{detail.reasonDescription}</Typography>
            )
        },
        // registrationDate
        {
            kind: ColumnType.text,
            render: (detail: VatEntries) => {
                return <Typography>{detail.registrationDate}</Typography>;
            }
        },
        // docNumber
        {
            kind: ColumnType.text,
            render: (detail: VatEntries) => (
                <Typography>{detail.docNumber}</Typography>
            )
        },
        // docDate
        {
            kind: ColumnType.text,
            render: (detail: VatEntries) => {
                return <Typography>{detail.docDate}</Typography>;
            }
        },
        // nonDeductibility
        {
            kind: ColumnType.text,
            render: (detail: VatEntries) => {
                const vatCollectability =
                    detail.vatLines?.[0]?.vatCollectability;
                return (
                    <Typography>
                        {vatCollectability &&
                            fs(
                                `vatCollectabilityType.${detail.vatLines?.[0]?.vatCollectability}`
                            )}
                    </Typography>
                );
            }
        },
        // taxable
        {
            kind: ColumnType.text,
            render: (detail: VatEntries) => (
                <FormattedAmount
                    amount={detail.vatLines.reduce(
                        (accumulator, line) => accumulator + line.taxable,
                        0
                    )}
                    currency="EUR"
                />
            )
        },
        // tax
        {
            kind: ColumnType.text,
            render: (detail: VatEntries) => (
                <FormattedAmount
                    amount={detail.vatLines.reduce(
                        (accumulator, line) => accumulator + line.tax,
                        0
                    )}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.cta,
            render: (detail: VatEntries) => (
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleOpenDetailClick(detail)}
                >
                    <FontAwesomeIcon
                        icon={
                            detail.registrationUuid === originalEntryId &&
                            isOpening
                                ? faSpinner
                                : faArrowRight
                        }
                        spin={
                            detail.registrationUuid === originalEntryId &&
                            isOpening
                        }
                    />
                </IconButton>
            )
        }
    ];

    return <Table headCells={headCells} columns={columns} data={data} />;
};

export default VATSettlementDetailTable;
