import Typography, {
    ExtendedTypographyProps
} from "@vapor/react-extended/ExtendedTypography";
import isNil from "lodash/isNil";
import omit from "lodash/omit";
import { FormattedDate as ReactIntlFormattedDate } from "react-intl";

export interface FormattedDateProps extends ExtendedTypographyProps {
    date?: string;
}

export default function FormattedDate({
    date,
    ...typographyProps
}: FormattedDateProps) {
    return isNil(date) ? null : (
        <Typography
            // we have to omit the sx props because types are incompatible
            {...omit(typographyProps, "sx")}
        >
            <ReactIntlFormattedDate
                value={date}
                day="2-digit"
                month="2-digit"
                year="numeric"
            />
        </Typography>
    );
}
