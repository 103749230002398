import { TextField } from "@mui/material";

import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack
} from "@vapor/react-material";
import { DatePicker } from "@vapor/react-x-date-pickers";
import { fromUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import {
    useDraft,
    useMutateDraft
} from "../../../../core/domain/Draft/queries";

import { BusinessDto } from "../../../../core/usecases/dtos/BusinessDto";
import useGetBusinesses from "../../../../core/usecases/useGetBusinesses";
import { isReadOnly } from "../../../../utils/draftUtils";
import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";
import { InfoIcon } from "../../InfoIcon";
import { readOnlySelect } from "../../../../core/commons/read-only-select";

type HeaderProps = {
    draftId: string;
};
const Header = ({ draftId }: HeaderProps) => {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const [businesses, setBusinesses] = useState<BusinessDto[]>([]);
    const { data: draft, isLoading: isLoadingDraft } = useDraft(draftId);
    const { mutate: update } = useMutateDraft(draftId);
    const readOnly = isReadOnly(draft?.status);
    const { getBusinesses, loading: loadingBusinesses } = useGetBusinesses({
        lazy: true
    });
    const [businessId, setBusinessId] = useState<string>(
        draft?.businessId ?? ""
    );
    const fs = useFormattedStringWithScope("components.Accounting.CA21.Header");

    useEffect(() => {
        if (!hasInitialized) {
            getBusinesses().then(setBusinesses);
            setHasInitialized(true);
        }
    }, [getBusinesses, hasInitialized]);

    useEffect(() => {
        if (draft?.businessId) {
            setBusinessId(draft.businessId);
        }
    }, [draft?.businessId]);

    const handleBusinessChange = (businessId: string) => {
        update({
            businessId
        });
        setBusinessId(businessId);
    };

    return (
        <Grid container rowGap={3} columnGap={3}>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel>{fs("reason")}</InputLabel>
                    {isLoadingDraft ? (
                        <Skeleton variant="rectangular">
                            <TextField fullWidth />
                        </Skeleton>
                    ) : (
                        <TextField
                            fullWidth
                            value={draft?.reason}
                            InputProps={{
                                readOnly: true
                            }}
                        />
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl>
                    <InputLabel>
                        <Stack direction="row" justifyContent="space-between">
                            {fs("registrationDate")}
                        </Stack>
                    </InputLabel>
                    {isLoadingDraft ? (
                        <Skeleton variant="rectangular">
                            <DatePicker />
                        </Skeleton>
                    ) : (
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <DatePicker
                                value={
                                    draft?.registrationDate
                                        ? fromUnixTime(draft?.registrationDate)
                                        : null
                                }
                                readOnly
                            />
                            <InfoIcon text={fs("commencementDate")} />
                        </Stack>
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={3} alignItems="end">
                <FormControl fullWidth>
                    <InputLabel>{fs("selectBusiness")}</InputLabel>
                    {loadingBusinesses || isLoadingDraft ? (
                        <Skeleton variant="rectangular" width="100%">
                            <Select />
                        </Skeleton>
                    ) : (
                        <Select
                            value={businessId}
                            onChange={(event) =>
                                handleBusinessChange(
                                    event.target.value as string
                                )
                            }
                            {...readOnlySelect(readOnly)}
                        >
                            {businesses
                                ? businesses.map((business, index) => (
                                      <MenuItem key={index} value={business.id}>
                                          {business.description}
                                      </MenuItem>
                                  ))
                                : []}
                        </Select>
                    )}
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default Header;
