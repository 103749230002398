import { isEmpty } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";

import {
    useCreateSetupDraft,
    useSetupActions
} from "../../../core/domain/AccountingSetup/queries";
import { PreviousBalance } from "../../../core/usecases/dtos/ActionsDto";

type PreviousBalanceAction = {
    type: "PREVIOUS_BALANCES";
    action: PreviousBalance;
};

type OtherAction = {
    type: "OTHER";
    action: any;
};

type None = {
    type: "NONE";
};

export type ActionType = PreviousBalanceAction | OtherAction | None;

interface AccountingSetupContextType {
    action: ActionType | null;
    selectAction: (action: ActionType) => void;
}

const AccountingSetupContext = createContext<
    AccountingSetupContextType | undefined
>(undefined);

interface AccountingSetupProviderProps {
    children: React.ReactNode;
}

export const AccountingSetupProvider = ({
    children
}: AccountingSetupProviderProps) => {
    const [action, setAction] = useState<ActionType>({
        type: "NONE"
    });
    const { data: setupActions } = useSetupActions();
    const { mutateAsync: createDraft } = useCreateSetupDraft();

    const selectAction = (action: ActionType) => {
        setAction(action);
    };

    useEffect(() => {
        if (!setupActions) return;

        const onlyPreviosBalancesActions =
            Object.keys(setupActions).length === 1;

        if (
            isEmpty(setupActions.PREVIOUS_BALANCES.registrations) &&
            onlyPreviosBalancesActions
        ) {
            createDraft();
        }
    }, [createDraft, setupActions]);

    useEffect(() => {
        if (!setupActions?.PREVIOUS_BALANCES) return;

        // select the last action
        const lastPreviousBalanceAction =
            setupActions.PREVIOUS_BALANCES.registrations.slice(-1)[0];

        if (lastPreviousBalanceAction) {
            setAction({
                type: "PREVIOUS_BALANCES",
                action: lastPreviousBalanceAction
            });
        }
    }, [setupActions]);

    return (
        <AccountingSetupContext.Provider
            value={{
                action,
                selectAction
            }}
        >
            {children}
        </AccountingSetupContext.Provider>
    );
};

export const useAccountingSetup = (): AccountingSetupContextType => {
    const context = useContext(AccountingSetupContext);
    if (!context) {
        throw new Error(
            "useAccountingSetup must be used within a AccountingSetupProvider"
        );
    }
    return context;
};
