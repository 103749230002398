import Alert from "@vapor/react-material/Alert";
import AlertTitle from "@vapor/react-material/AlertTitle";
import isNil from "lodash/isNil";

interface ErrorBannerProps {
    title?: string;
    error?: Error | string;
}

export default function ErrorBanner({ title, error }: ErrorBannerProps) {
    return (
        <Alert variant="outlined" severity="error">
            {!isNil(title) ? <AlertTitle>{title}</AlertTitle> : null}
            {typeof error === "string" ? error : error?.message}
        </Alert>
    );
}
