import {
    faChevronDown,
    faChevronUp,
    faSquareArrowUpRight
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@vapor/react-material";
import IconButton from "@vapor/react-material/IconButton";
import { pickBy } from "lodash";
import { useEffect, useState } from "react";

import { useWithholdings } from "../../../core/contexts/Withholdings";
import {
    DraftWithholdingDto,
    Types
} from "../../../core/usecases/dtos/DraftWithholdingDto";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../FormattedAmount";
import SelectWithholdingDesc from "../SelectWithholdingDesc";
import WiithholdingTableRows from "./WiithholdingTableRows";

const fs = getFormattedStringWithScope("views.Detail.withholdings.table");

interface WithholdingsTableProps {
    draftId: string;
    onOpenDrawer: (selectedWithholding: DraftWithholdingDto) => void;
    refCurrency: string;
    totalNetAmount: number;
}

export default function WithholdingsTable({
    draftId,
    onOpenDrawer,
    refCurrency,
    totalNetAmount
}: WithholdingsTableProps) {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const [currentRowOpenIds, setCurrentRowOpenIds] = useState<string[]>([]);

    const { get, withholdings: allWithholdings } = useWithholdings().draft;
    const withholdings = allWithholdings?.map((item) => ({
        ...item,
        types: pickBy(
            item.types,
            (type) => type.withholdingRate !== 0 || type.amount !== 0
        ) as Types[]
    }));

    useEffect(() => {
        if (!hasInitialized && draftId) {
            get(draftId).then((withholdings) => {
                setCurrentRowOpenIds((prevState) => {
                    const newState = [...prevState];

                    withholdings.forEach((withholding) => {
                        if (Object.keys(withholding.types).length > 1) {
                            newState.push(withholding.uuid);
                        }
                    });

                    return newState;
                });
            });
            setHasInitialized(true);
        }
    }, [get, draftId, hasInitialized]);

    const handleCollapseRowOpen = (uuid: string) => {
        const isIdOpen = currentRowOpenIds.includes(uuid);
        if (isIdOpen) {
            setCurrentRowOpenIds((prevState) =>
                prevState.filter((id) => id !== uuid)
            );
        } else {
            setCurrentRowOpenIds((prevState) => [...prevState, uuid]);
        }
    };

    const renderTableHead = () => {
        return (
            <>
                <TableCell size="medium">
                    <Typography
                        variant="boldLabel"
                        color="primary.textTitleColor"
                    >
                        {fs("casualePerformance")}
                    </Typography>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                    <Typography
                        variant="boldLabel"
                        color="primary.textTitleColor"
                    >
                        {fs("withholdingType")}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography
                        variant="boldLabel"
                        color="primary.textTitleColor"
                    >
                        {fs("reasonCUModel")}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography
                        variant="boldLabel"
                        color="primary.textTitleColor"
                    >
                        {fs("integrativeContribution")}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography
                        variant="boldLabel"
                        color="primary.textTitleColor"
                    >
                        {fs("withholdingRate")}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography
                        variant="boldLabel"
                        color="primary.textTitleColor"
                    >
                        {fs("withholdingAmount")}
                    </Typography>
                </TableCell>
                <TableCell></TableCell>
            </>
        );
    };

    return (
        <>
            <Table>
                <TableHead shadow>{renderTableHead()}</TableHead>
                <TableBody>
                    {withholdings?.map((withholding) => {
                        const withholdingtypesList = Object.keys(
                            withholding.types
                        );

                        const showCollapsibleRow =
                            withholdingtypesList.length > 1;

                        const showFirstRow = withholdingtypesList.length < 2;

                        return (
                            <>
                                <TableRow>
                                    <TableCell size="small">
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                        >
                                            <SelectWithholdingDesc
                                                draftId={draftId}
                                                withholding={withholding}
                                                totalNetAmount={totalNetAmount}
                                                cuReason={withholding.cuReason}
                                            />
                                            {showCollapsibleRow && (
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        handleCollapseRowOpen(
                                                            withholding.uuid
                                                        )
                                                    }
                                                >
                                                    <VaporIcon
                                                        icon={
                                                            currentRowOpenIds.includes(
                                                                withholding.uuid
                                                            )
                                                                ? faChevronUp
                                                                : faChevronDown
                                                        }
                                                    />
                                                </IconButton>
                                            )}
                                        </Stack>
                                    </TableCell>
                                    <TableCell size="small"></TableCell>
                                    <TableCell size="small">
                                        {showFirstRow && (
                                            <Typography variant="body">
                                                {withholdingtypesList.length ===
                                                1
                                                    ? withholdingtypesList[0] ===
                                                      "OTHER"
                                                        ? fs("OTHER")
                                                        : withholdingtypesList[0]
                                                    : null}
                                            </Typography>
                                        )}
                                    </TableCell>
                                    <TableCell size="small">
                                        {showFirstRow && (
                                            <Typography variant="body500">
                                                {withholding?.cuReason}
                                            </Typography>
                                        )}
                                    </TableCell>
                                    <TableCell size="small">
                                        {showFirstRow && (
                                            <FormattedAmount
                                                currency={refCurrency}
                                                variant="body"
                                                amount={
                                                    withholding.cashContributionNoWithholding ??
                                                    0
                                                }
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell size="small">
                                        {showFirstRow && (
                                            <Typography variant="body">
                                                {
                                                    Object.values(
                                                        withholding.types
                                                    )[0].withholdingRate
                                                }
                                                %
                                            </Typography>
                                        )}
                                    </TableCell>
                                    <TableCell size="small">
                                        {showFirstRow && (
                                            <FormattedAmount
                                                variant="body"
                                                amount={
                                                    Object.values(
                                                        withholding.types
                                                    )[0].amount
                                                }
                                                currency={refCurrency}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell size="small">
                                        <Box width={8}>
                                            {showFirstRow && (
                                                <IconButton
                                                    onClick={() =>
                                                        onOpenDrawer(
                                                            withholding
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        color="#0090d1"
                                                        icon={
                                                            faSquareArrowUpRight
                                                        }
                                                    />
                                                </IconButton>
                                            )}
                                        </Box>
                                    </TableCell>
                                </TableRow>

                                {showCollapsibleRow &&
                                    currentRowOpenIds.includes(
                                        withholding.uuid
                                    ) && (
                                        <WiithholdingTableRows
                                            onOpenDrawer={onOpenDrawer}
                                            withholding={withholding}
                                            refCurrency={refCurrency}
                                        />
                                    )}
                            </>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
}
