import { Typography } from "@mui/material";
import { CircularProgress, Stack } from "@vapor/react-material";
import { useEffect, useState } from "react";

import { PreviousBalance } from "../../../../core/usecases/dtos/ActionsDto";
import { useGetDraftFromOriginalEntry } from "../../../../core/usecases/useGetDraftFromOriginalEntry";
import { DraftAccountingStatus } from "../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CA21 from "../../../components/Accounting/CA21";
import ErrorBanner from "../../../components/ErrorBanner";
import InfoBanner from "../../../components/InfoBanner";

const fs = getFormattedStringWithScope("views.AccountingSetup");

type CA21ActionProps = PreviousBalance;

const CA21Action = ({
    draftId,
    status,
    message,
    originalEntryId
}: CA21ActionProps) => {
    const [docId, setDocId] = useState<string | undefined>(draftId);
    const {
        fetch: getDraftIdFromOriginalEntryId,
        loading,
        error
    } = useGetDraftFromOriginalEntry();

    useEffect(
        () => {
            if (!draftId && originalEntryId) {
                // fetch draft id from original entry id
                getDraftIdFromOriginalEntryId(originalEntryId).then(
                    (response) => setDocId(response)
                );
            } else if (draftId) {
                setDocId(draftId);
            }
        },
        // eslint-disable-next-line
        [draftId, originalEntryId]
    );

    return (
        <Stack spacing={3} padding={2}>
            {status === DraftAccountingStatus.ERROR && (
                <ErrorBanner title={fs("registrationError")} error={message} />
            )}
            {status === DraftAccountingStatus.PROCESS_CONFIRM && (
                <InfoBanner message={fs("registrationInProgress")} />
            )}
            <Typography variant="titleSmall">
                {fs("previousBalance")}
            </Typography>
            {error ? (
                <ErrorBanner title={fs("errorDocument")} error={error} />
            ) : loading ? (
                <CircularProgress />
            ) : (
                docId && <CA21 draftId={docId} />
            )}
        </Stack>
    );
};

export default CA21Action;
