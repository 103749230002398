import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, useTheme } from "@vapor/react-material";

interface InfoIconProps {
    text?: string;
    onClick?: () => void;
}

export const InfoIcon = (props: InfoIconProps) => {
    const theme = useTheme();

    return (
        <Tooltip title={props.text} arrow>
            <span>
                <FontAwesomeIcon
                    icon={faInfoCircle}
                    onClick={props.onClick}
                    color={theme.palette.primary.primary}
                />
            </span>
        </Tooltip>
    );
};
