import { Spinner } from "@comic/precog-components";
import { OneBoxPage } from "@drift/oneplatfront";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCreateDraft, useDraft } from "../../../core/domain/Draft/queries";
import { useReasons } from "../../../core/domain/Reasons/queries";
import { Reason } from "../../../utils/appEnums";
import { filteredReasonsForNewRegistration } from "../../../utils/reasonUtils";
import routes from "../../commons/routes";
import AccountToolbar from "../../components/AccountToolbar";
import BackArrow from "../../components/BackArrow";
import AccountingLines from "./Steps/AccountingLines";
import FillHeader from "./Steps/FillHeader";
import SelectReason from "./Steps/SelectReason";

export default function NewRegistration() {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [hasSetReason, setHasSetReason] = useState<boolean>(false);
    const [hasFreeRoaming, setHasFreeRoaming] = useState<boolean>(false);
    const [selectedReason, setSelectedReason] = useState<Reason | null>(null);
    const [draftId, setDraftId] = useState<string | undefined>();

    const navigate = useNavigate();

    const { data: reasonsList, isLoading: isLoadingReasons } = useReasons();
    const reasons = useMemo(
        () => filteredReasonsForNewRegistration(reasonsList),
        [reasonsList]
    );
    const { mutateAsync, isPending: isCreatingDraft } = useCreateDraft();
    const { data: draft } = useDraft(draftId);

    const handleSelectReason = async (reason: Reason) => {
        const draftId = await mutateAsync(reason);
        setDraftId(draftId);
        setSelectedReason(reason);
        setHasSetReason(true);
        setCurrentStep(2);
    };

    const handleResetReason = () => {
        setHasSetReason(false);
    };

    const handleSetHeader = () => {
        setHasFreeRoaming(true);
        setCurrentStep(3);
    };

    const handleGoToSelectReason = () => {
        setCurrentStep(1);
    };

    const handleGoToHeader = useCallback(() => {
        if (hasSetReason) {
            setCurrentStep(2);
        }
    }, [hasSetReason]);

    const handleGoToAccountingLines = useCallback(() => {
        if (hasFreeRoaming) {
            setCurrentStep(3);
        }
    }, [hasFreeRoaming]);

    const navigateBack = useCallback(() => {
        navigate(routes.dashboard());
    }, [navigate]);

    const handleDeleteDraft = () => {
        navigateBack();
    };

    const handleAccount = () => {
        navigateBack();
    };

    return (
        <OneBoxPage headerLeft={<BackArrow />}>
            {isLoadingReasons ? (
                <Spinner loading={isLoadingReasons} />
            ) : reasons ? (
                <>
                    <SelectReason
                        step={1}
                        isOpen={currentStep === 1}
                        onClick={handleGoToSelectReason}
                        reasons={reasons}
                        value={selectedReason}
                        onConfirmReasonSelection={handleSelectReason}
                        onReset={handleResetReason}
                        isLoading={false}
                        factType={draft?.factType}
                        active={draft?.doc.active}
                        disabled={hasSetReason}
                    />
                    <FillHeader
                        step={2}
                        isOpen={currentStep === 2}
                        draftId={draft?.id}
                        isCreatingDraft={isCreatingDraft}
                        onClick={handleGoToHeader}
                        onConfirm={handleSetHeader}
                    />
                    <AccountingLines
                        step={3}
                        isOpen={currentStep === 3}
                        draftId={draftId!}
                        onClick={handleGoToAccountingLines}
                    />
                </>
            ) : null}
            {draftId && (
                <AccountToolbar
                    draftId={draft?.id!}
                    onAccount={handleAccount}
                    onClose={() => navigateBack()}
                    onDelete={handleDeleteDraft}
                />
            )}
        </OneBoxPage>
    );
}
