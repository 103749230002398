import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import Box from "@vapor/react-material/Box";
import getYear from "date-fns/getYear";
import { useLayoutEffect, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { VatSettlement } from "../../../../core/usecases/interfaces/VatSettlement";
import CustomButton from "./CustomArrow";
import PeriodCard from "./PeriodCard";
import "./style.css";
import { Period } from "../../../../core/usecases/dtos/VatSettlementsDto";

interface PeriodCarouselProps {
    period: Period;
    onSelectPeriod: (period: Period) => void;
    onClosePeriod: (settlement: VatSettlement) => void;
    onOpenPeriod: (settlement: VatSettlement) => void;
    isLoadingOpenSettlement: boolean;
    isLoadingCloseSettlement: boolean;
    settlements: VatSettlement[] | null;
    year: number;
}
const PeriodCarousel = ({
    period,
    onSelectPeriod,
    onClosePeriod,
    onOpenPeriod,
    isLoadingOpenSettlement,
    isLoadingCloseSettlement,
    settlements,
    year
}: PeriodCarouselProps) => {
    const carouselRef = useRef<Carousel>(null);
    const currentDate = new Date();
    const currentYear = getYear(currentDate);

    useLayoutEffect(() => {
        if (!carouselRef.current || year !== currentYear) return;

        if (carouselRef && carouselRef.current) {
            const slideNumber: number =
                settlements?.findIndex(
                    (settlement) => settlement.period === period
                ) ?? 0;

            carouselRef.current.goToSlide(Math.max(0, slideNumber));
        }
    }, [carouselRef, currentYear, settlements, period, year]);

    return (
        <Box width="calc(100vw - 280px)">
            <Box margin="5px" height="235px" position="relative">
                <Carousel
                    focusOnSelect={true}
                    ref={carouselRef}
                    additionalTransfrom={0}
                    arrows
                    customLeftArrow={
                        <CustomButton direction="left" icon={faAngleLeft} />
                    }
                    customRightArrow={
                        <CustomButton direction="right" icon={faAngleRight} />
                    }
                    autoPlaySpeed={3000}
                    containerClass="container"
                    draggable
                    infinite={false}
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        screen12: {
                            breakpoint: {
                                max: 10000,
                                min: 2727
                            },
                            items: 12
                        },
                        screen11: {
                            breakpoint: {
                                max: 2727,
                                min: 2522
                            },
                            items: 11
                        },
                        screen10: {
                            breakpoint: {
                                max: 2522,
                                min: 2317
                            },
                            items: 10
                        },
                        screen9: {
                            breakpoint: {
                                max: 2317,
                                min: 2112
                            },
                            items: 9
                        },
                        screen8: {
                            breakpoint: {
                                max: 2112,
                                min: 1907
                            },
                            items: 8
                        },
                        screen7: {
                            breakpoint: {
                                max: 1907,
                                min: 1702
                            },
                            items: 7
                        },
                        screen6: {
                            breakpoint: {
                                max: 1702,
                                min: 1497
                            },
                            items: 6
                        },
                        screen5: {
                            breakpoint: {
                                max: 1497,
                                min: 1292
                            },
                            items: 5
                        },
                        screen4: {
                            breakpoint: {
                                max: 1292,
                                min: 1087
                            },
                            items: 4
                        },
                        screen3: {
                            breakpoint: {
                                max: 1087,
                                min: 882
                            },
                            items: 3
                        },

                        screen2: {
                            breakpoint: {
                                max: 882,
                                min: 677
                            },
                            items: 2
                        },
                        screen1: {
                            breakpoint: {
                                max: 677,
                                min: 0
                            },
                            items: 1
                        }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    slidesToSlide={1}
                    swipeable
                    partialVisible={false}
                    centerMode={false}
                >
                    {settlements
                        ? settlements.map((settlement, index) => (
                              <PeriodCard
                                  key={index}
                                  period={period}
                                  onSelectPeriod={onSelectPeriod}
                                  onClosePeriod={onClosePeriod}
                                  onOpenPeriod={onOpenPeriod}
                                  settlement={settlement}
                                  isLoadingOpenSettlement={
                                      isLoadingOpenSettlement
                                  }
                                  isLoadingCloseSettlement={
                                      isLoadingCloseSettlement
                                  }
                              />
                          ))
                        : []}
                </Carousel>
            </Box>
        </Box>
    );
};

export default PeriodCarousel;
