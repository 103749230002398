import Grid from "@vapor/react-material/Grid";

import Highlight from "../Highlight";
import ToAccount from "../ToAccount";
import VatSummary from "../VatSummary";

interface WidgetsContainerProps {
    shouldRefetchCount: boolean;
    onFetchCount: () => void;
}

export default function WidgetsContainer({
    onFetchCount,
    shouldRefetchCount
}: WidgetsContainerProps) {
    return (
        <Grid container columnSpacing={4} marginTop="32px" marginLeft="24px">
            <Grid item>
                <Highlight />
            </Grid>
            <Grid item>
                <ToAccount
                    onFetchCount={onFetchCount}
                    shouldRefetchCount={shouldRefetchCount}
                />
            </Grid>
            <Grid item>
                <VatSummary />
            </Grid>
        </Grid>
    );
}
