import { isNil } from "lodash";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";

import usePrevious from "../../commons/hooks/usePrevious";
import { DraftUsedGood } from "../../usecases/dtos/DraftUsedGoodDto";
import { UsedGoodApiResList } from "../../usecases/dtos/DraftUsedGoodsDto";
import { useGetDraftUsedGood } from "../../usecases/useGetDraftUsedGood";
import { useGetDraftUsedGoods } from "../../usecases/useGetDraftUsedGoods";
import {
    UpdateOptions,
    usePatchDraftUsedGood
} from "../../usecases/usePatchDraftUsedGood";

interface DraftUsedGoodContextType {
    setDraftId: (draftIdState: string | null) => void;
    loading: boolean;
    error: any;
    update: (data: UpdateOptions, usedGoodId?: string) => void;
    draftUsedGoods: UsedGoodApiResList[] | null;
    draftUsedGood: DraftUsedGood | null;
    hasUpdated: boolean;
    getAllDraftUsedGoods: () => void;
    getDraftUsedGoodById: (usedGoodId: string) => void;
}

const DraftUsedGoodContext = createContext<DraftUsedGoodContextType>({
    setDraftId: () => {},
    loading: false,
    error: null,
    update: () => {},
    draftUsedGoods: null,
    draftUsedGood: null,
    hasUpdated: false,
    getAllDraftUsedGoods: () => {},
    getDraftUsedGoodById: () => {}
});

interface DraftUsedGoodProviderProps {
    draftId?: string;
    children: React.JSX.Element;
}

export const DraftUsedGoodProvider = ({
    draftId,
    children
}: DraftUsedGoodProviderProps) => {
    const [draftIdState, setDraftIdState] = useState<string | null>(null);
    const previousDraftId = usePrevious(draftIdState);

    const [draftUsedGoods, setDraftUsedGoods] = useState<
        UsedGoodApiResList[] | null
    >(null);
    const [draftUsedGood, setDraftUsedGood] = useState<DraftUsedGood | null>(
        null
    );
    const [isLoadingDraft, setIsLoadingDraft] = useState<boolean>(false);

    const useGetDraftUsedGoodsHook = useGetDraftUsedGoods({ lazy: true });
    const useGetDraftUsedGoodHook = useGetDraftUsedGood({ lazy: true });
    const usedEditDraftUsedGoodHook = usePatchDraftUsedGood();

    useEffect(() => {
        draftId && setDraftIdState(draftId);
    }, [draftId]);

    const getDraftUsedGoodById = useCallback(
        async (usedGoodId: string) => {
            if (!isNil(draftIdState) && !isNil(usedGoodId)) {
                const drafUsedGood =
                    await useGetDraftUsedGoodHook.fetchWithParameters(
                        draftIdState,
                        usedGoodId
                    );
                setDraftUsedGood(drafUsedGood);
            }
        },
        [draftIdState, useGetDraftUsedGoodHook]
    );

    const getAllDraftUsedGoods = useCallback(async () => {
        if (draftIdState) {
            const draftUsedGoods =
                await useGetDraftUsedGoodsHook.fetchWithParameters(
                    draftIdState
                );
            setDraftUsedGoods(draftUsedGoods);
        }
    }, [draftIdState, useGetDraftUsedGoodsHook]);

    useEffect(() => {
        if (draftIdState && previousDraftId !== draftIdState) {
            getAllDraftUsedGoods();
            setDraftUsedGoods(null);
        }
    }, [draftIdState, previousDraftId, getAllDraftUsedGoods]);

    const setDraftId = (newDraftId: string | null) => {
        setDraftIdState(newDraftId);
    };

    const update = useCallback(
        (data: UpdateOptions, usedGoodId?: string) => {
            if (draftIdState) {
                usedEditDraftUsedGoodHook.patchDraftUsedGoodParams(
                    usedGoodId,
                    draftIdState,
                    data
                );
                setDraftUsedGoods((prevDraftUsedGoods) => {
                    if (!prevDraftUsedGoods) {
                        return null;
                    }

                    return prevDraftUsedGoods.map((usedGood) =>
                        usedGood.id === usedGoodId
                            ? { ...usedGood, ...data }
                            : usedGood
                    );
                });
            }
        },
        [draftIdState, usedEditDraftUsedGoodHook]
    );

    useEffect(() => {
        if (isNil(draftUsedGoods)) {
            setIsLoadingDraft(true);
        } else {
            setIsLoadingDraft(false);
        }
    }, [draftUsedGoods]);

    return (
        <DraftUsedGoodContext.Provider
            value={{
                setDraftId,
                loading: isLoadingDraft || useGetDraftUsedGoodsHook.loading,
                error: useGetDraftUsedGoodsHook.error,
                update,
                draftUsedGoods,
                draftUsedGood,
                getAllDraftUsedGoods,
                getDraftUsedGoodById,
                hasUpdated: usedEditDraftUsedGoodHook.hasResponded
            }}
        >
            {children}
        </DraftUsedGoodContext.Provider>
    );
};

export const useDraftUsedGood = () => {
    return useContext(DraftUsedGoodContext);
};
