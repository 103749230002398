import { useRequest } from "@onefront/react-sdk";
import { useCallback } from "react";

import { ASYNC_API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface UsePostAssetSale {
    loading: boolean;
    error: any;
    result: any;
    fetch: Function;
    hasFetched: boolean;
}

export interface IPostData {
    businessId?: string;
    date: Date | null;
    disposalRate: number | null;
    saleAmount: number | null;
    splitCapitalGain?: boolean;
}

interface UsePostAssetSaleOptions {
    assetId: string | undefined;
    dataToPost: IPostData | null;
    lazy: boolean;
}

export const usePostAssetSale = ({
    assetId,
    dataToPost,
    lazy
}: UsePostAssetSaleOptions): UsePostAssetSale => {
    const { fetch, data, loading, error, hasLoaded } = useRequest({
        ...useAxiosConfigRequestParams("PostAsserSaleSimulation"),
        method: "post",
        url: `${ASYNC_API_URL}/asset/dismiss`,
        data: {
            ...dataToPost,
            ...useCustomerData(),
            id: assetId
        },
        lazy: lazy
    });

    const safeFetch = useCallback(() => fetch(), [fetch]);

    return {
        fetch: safeFetch,
        loading: loading,
        error: error,
        result: data,
        hasFetched: hasLoaded
    };
};
