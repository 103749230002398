import Typography from "@vapor/react-extended/ExtendedTypography";
import { Skeleton } from "@vapor/react-material";
import { formatDistanceToNow } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import it from "date-fns/locale/it";

import { useDraft } from "../../../core/domain/Draft/queries";
import { DraftAccountingStatus } from "../../../utils/appEnums";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";

// import { useAccountingLines } from "../AccountingLinesTable/queries";

type LastDraftUpdateProps = {
    draftId: string;
};
export const LastDraftUpdate = ({ draftId }: LastDraftUpdateProps) => {
    const {
        data: draft,
        isLoading: isLoadingDraft,
        isSuccess: isSuccessDraft
    } = useDraft(draftId);
    // const {
    //     data: accountingLines,
    //     isLoading: isLoadingAccountingLines,
    //     isSuccess: isSuccessAccountingLines
    // } = useAccountingLines({ draftId });
    const fs = useFormattedStringWithScope(
        "components.Accounting.LastDraftUpdate"
    );

    if (isLoadingDraft) {
        return <Skeleton variant="rectangular" />;
    }

    if (isSuccessDraft) {
        const isDraft = draft?.status === DraftAccountingStatus.WAITING;
        if (!isDraft) return null;

        // TODO: Uncomment this when accounting lines' updatedOn is available
        //       (remember to add isLoadingAccountLines and isSuccessAccountingLines to the previous ifs)
        // const [latestUpdatedOn] = accountingLines
        //     .map((line) => line.updatedOn)
        //     .sort();

        // TODO: use latestUpdatedOn instead of draft.updatedOn
        const localizedDraftUpdateDate = toZonedTime(
            // assume that the date is in UTC
            `${draft.updatedOn}Z`,
            Intl.DateTimeFormat().resolvedOptions().timeZone
        );

        const minutesSinceLastUpdate =
            localizedDraftUpdateDate &&
            formatDistanceToNow(localizedDraftUpdateDate, {
                locale: it,
                addSuffix: true
            });

        return (
            <Typography variant="pageDescription" fontSize={14}>
                {`${fs("draftSaved")} ${minutesSinceLastUpdate}`}
            </Typography>
        );
    }

    return null;
};
