import { useGet } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { DepreciationSimulationResponseDto } from "../dtos/DepreciationSimulationDto";
import processData from "../useSimulateDepreciation/processData";

export interface GetDepreciationOptions {
    period: string;
}

interface UseGetDepreciation {
    fetch: (
        options: GetDepreciationOptions
    ) => Promise<DepreciationSimulationResponseDto>;
}

export const useGetDepreciation = (): UseGetDepreciation => {
    const customerData = useCustomerData();

    const { fetch } = useGet(`${ASYNC_API_URL}/asset/depreciation`, {
        ...useAxiosConfigRequestParams("GetDepreciation"),
        params: omitNilProperties({
            ...useCustomerData()
        }),
        lazy: true
    });

    const fetchDepreciation = async ({ period }: GetDepreciationOptions) => {
        return fetch({
            params: {
                ...customerData,
                workspaceId: customerData.workspaceId,
                period: period
            }
        })
            .then((response) => ({
                ...response,
                data: processData(response.data)
            }))
            .then(({ data }) => data);
    };

    return {
        fetch: fetchDepreciation
    };
};
