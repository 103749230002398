import { useCurrentWorkspace } from "@drift/oneplatfront";

interface UseCustomerData {
    workspaceId?: string;
}

export default function useCustomerData(): UseCustomerData {
    const { workspace } = useCurrentWorkspace(true);

    return {
        workspaceId: workspace?.id
    };
}
