import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@vapor/react-material";

import { ProcedureEntryDetail } from "../../../../core/usecases/dtos/ProcedureEntryDetail";
import TableHeadCell from "../../TableHead/TableHeadCell";
import TableHeadText from "../../TableHead/TableHeadText";
import FormattedAmount from "../../FormattedAmount";
import AccountType from "../../AccountType";
import { capitalize } from "lodash";

interface ProcedureEntryAccoutingLinesTableProps {
    lines: ProcedureEntryDetail["lines"];
}

export default function ProcedureEntryAccoutingLinesTable({
    lines
}: ProcedureEntryAccoutingLinesTableProps) {
    return (
        <Table>
            <TableHead shadow>
                <TableRow>
                    <TableHeadCell>
                        <TableHeadText>Conto contabile</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell align="right">
                        <TableHeadText>Dare</TableHeadText>
                    </TableHeadCell>
                    <TableHeadCell align="right">
                        <TableHeadText>Avere</TableHeadText>
                    </TableHeadCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {lines.map((line) => {
                    return (
                        <TableRow>
                            <TableCell size="small" width={500}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body">
                                        {capitalize(line.account.description)}
                                    </Typography>
                                    <AccountType type={line.account.type} />
                                </Stack>
                            </TableCell>
                            <TableCell size="small" align="right">
                                {line.debit.value !== 0 ? (
                                    <FormattedAmount
                                        amount={line.debit.value}
                                        currency={line.debit.currency}
                                    />
                                ) : null}
                            </TableCell>
                            <TableCell size="small" align="right">
                                {line.credit.value !== 0 ? (
                                    <FormattedAmount
                                        color="primary.error"
                                        amount={line.credit.value}
                                        currency={line.credit.currency}
                                    />
                                ) : null}
                            </TableCell>
                        </TableRow>
                    );
                })}
                <TableRow>
                    <TableCell size="small"></TableCell>
                    <TableCell align="right" size="small">
                        <FormattedAmount
                            amount={lines.reduce(
                                (total, line) => total + line.debit.value,
                                0
                            )}
                            currency={lines[0].debit.currency}
                            variant="bodyLarge700"
                        />
                    </TableCell>
                    <TableCell align="right" size="small">
                        <FormattedAmount
                            amount={lines.reduce(
                                (total, line) => total + line.credit.value,
                                0
                            )}
                            currency={lines[0].credit.currency}
                            variant="bodyLarge700"
                        />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}
