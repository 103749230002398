import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";
import Typography from "@vapor/react-extended/ExtendedTypography";

import getFormattedDate from "../../../../../../utils/getFormattedDate";
import FormattedAmount from "../../../../../components/FormattedAmount";
import getFormattedStringWithScope from "../../../../../../utils/getFormattedStringWithScope";

const fs = getFormattedStringWithScope(
    "views.ListAssets.movementDrawer.disposalLogs"
);

interface DisposalLogsDetailsProps {
    filteredLogs: { [key: string]: any };
}

export default function DisposalLogsDetails({
    filteredLogs
}: DisposalLogsDetailsProps) {
    return (
        <Box
            marginLeft="10px"
            marginTop="22px"
            display="flex"
            flexDirection="column"
        >
            <Typography variant="bodyLarge700">{fs("disposal")}</Typography>
            <Typography
                marginTop="22px"
                color="primary.textTitleColor"
                variant="subtitle"
            >
                {fs("opsInfo")}
            </Typography>
            <Stack>
                <Box display="flex" width="80%" justifyContent="space-between">
                    <Stack marginTop="16px">
                        <Typography variant="caption">
                            {fs("disposalDate")}
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.date === null
                                ? "XXXXXXX"
                                : getFormattedDate(filteredLogs[0]?.date)}
                        </Typography>
                    </Stack>
                    <Stack marginTop="16px">
                        <Typography variant="caption">
                            {fs("disposalPercent")}
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.rate === null
                                ? "0 %"
                                : `${filteredLogs[0]?.rate}%`}
                        </Typography>
                    </Stack>
                </Box>
                {filteredLogs[0]?.capital !== 0 && (
                    <Box
                        marginTop="24px"
                        display="flex"
                        width="80%"
                        justifyContent="space-between"
                    >
                        <Stack marginTop="16px">
                            {filteredLogs[0]?.capital > 0 &&
                                filteredLogs[0]?.trackType === "STATUTORY" && (
                                    <Typography variant="caption">
                                        {fs("civilaCapitalGain")}
                                    </Typography>
                                )}
                            {filteredLogs[0]?.capital < 0 &&
                                filteredLogs[0]?.trackType === "STATUTORY" && (
                                    <Typography variant="caption">
                                        {fs("civilCapitalLoss")}
                                    </Typography>
                                )}
                            {filteredLogs[0]?.capital > 0 &&
                                filteredLogs[0]?.trackType === "TAX" && (
                                    <Typography variant="caption">
                                        {fs("taxCapitalGain")}
                                    </Typography>
                                )}
                            {filteredLogs[0]?.capital < 0 &&
                                filteredLogs[0]?.trackType === "TAX" && (
                                    <Typography variant="caption">
                                        {fs("taxCapitalLoss")}
                                    </Typography>
                                )}
                            <FormattedAmount
                                variant="body700"
                                amount={filteredLogs[0]?.capital}
                                currency="EUR"
                            />
                        </Stack>
                        {/* <Stack marginTop="16px">
                        <Typography variant="caption">
                            Plusvalenza da ripartire
                        </Typography>
                        <Typography variant="body700">
                            {filteredLogs[0]?.date === null
                                ? "XXXXXXX"
                                : getFormattedDate(filteredLogs[0]?.date)}
                        </Typography>
                    </Stack> */}
                        <Stack marginTop="16px">
                            <Typography variant="caption">
                                {fs("residualDepreciation")}
                            </Typography>
                            <FormattedAmount
                                variant="body700"
                                amount={filteredLogs[0]?.carryingValue}
                                currency="EUR"
                            />
                        </Stack>
                    </Box>
                )}
            </Stack>
        </Box>
    );
}
