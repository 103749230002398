import { useGet } from "@onefront/react-sdk";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { VatDestination } from "../dtos/VatDestinationsDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseGetVatDestinations {
    loading: boolean;
    error: any;
    vatDestinations: VatDestination[] | null;
    fetch: Function;
    hasFetched: boolean;
    fetchWithParameters: (active?: boolean) => Promise<VatDestination[]>;
}

interface useGetVatDestinationsOptions {
    active?: string;
    lazy?: boolean;
}

export const useGetVatDestinations = ({
    active,
    lazy
}: useGetVatDestinationsOptions): UseGetVatDestinations => {
    const customerData = useCustomerData();

    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/vat/destinations`,
        {
            ...useAxiosConfigRequestParams("VatDestinations"),
            params: omitNilProperties({
                ...useCustomerData(),
                active: active
            }),
            lazy: lazy
        }
    );

    const fetchWithParameters = useCallback(
        (active?: boolean) => {
            return fetch({
                params: {
                    ...customerData,
                    active: `${active}`
                }
            }).then(
                (response: AxiosResponse<VatDestination[]>) => response.data
            );
        },
        [fetch, customerData]
    );

    const safeFetch = useCallback(() => fetch(), [fetch]);

    return {
        fetch: safeFetch,
        loading: loading,
        error: error,
        vatDestinations: data,
        hasFetched: hasLoaded,
        fetchWithParameters
    };
};
