import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { IconButton } from "@vapor/react-material/IconButton";
import { compact } from "lodash";

import {
    AccountDto,
    GroupedAccountsDto
} from "../../../../../core/usecases/dtos/BalanceDto";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../components/FormattedAmount";
import HierarchyTable, {
    ColumnType
} from "../../../../components/HierarchyTable";

interface BalanceSheetPassivitiesProps {
    numRows: number;
    rows: GroupedAccountsDto[];
    total: number;
    operatingResult?: number;
    onRowDetailClick: (accountId: string, accountName: string) => void;
}

const fs = getFormattedStringWithScope("components.BalanceTable");

export default function BalanceSheetPassivities({
    numRows,
    onRowDetailClick,
    rows,
    total,
    operatingResult
}: BalanceSheetPassivitiesProps) {
    const headCells = [
        {
            id: "passivity",
            kind: ColumnType.text,
            label: fs("passivity")
        },
        {
            id: "operatingBalance",
            kind: ColumnType.numeric,
            label: fs("operatingBalance")
        }
    ];

    const parentColumns = [
        // account name
        {
            kind: ColumnType.text,
            render: (balanceEntry: AccountDto) => (
                <Typography variant="body" fontWeight="500">
                    {`${balanceEntry.accountId} ${balanceEntry.accountName}`}
                </Typography>
            )
        },
        // account value
        {
            kind: ColumnType.numeric,
            render: (balanceEntry: AccountDto) => (
                <FormattedAmount
                    variant="body"
                    fontWeight="500"
                    amount={balanceEntry.amount}
                    currency="EUR"
                />
            )
        },
        // empty cell for alignment
        {
            render: () => <></>
        }
    ];

    const columns = [
        // account name
        {
            kind: ColumnType.text,
            render: (balanceEntry: AccountDto) => (
                <Typography variant="body" marginLeft="16px">
                    {balanceEntry.accountName}
                </Typography>
            )
        },
        // account value
        {
            kind: ColumnType.numeric,
            render: (balanceEntry: AccountDto) => (
                <FormattedAmount
                    variant="body"
                    amount={balanceEntry.amount}
                    currency="EUR"
                />
            )
        },
        // CTA
        {
            kind: ColumnType.cta,
            render: (balanceEntry: AccountDto) => (
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() =>
                        onRowDetailClick(
                            balanceEntry.accountId,
                            balanceEntry.accountName
                        )
                    }
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </IconButton>
            )
        }
    ];

    const balanceSheetFooter = operatingResult
        ? [
              // label
              {
                  kind: ColumnType.text,
                  render: () => (
                      <Typography variant="boldLabel">
                          {fs("operatingResult")}
                      </Typography>
                  )
              },
              // total value
              {
                  kind: ColumnType.numeric,
                  render: () => (
                      <FormattedAmount
                          variant="boldLabel"
                          amount={operatingResult}
                          currency="EUR"
                      />
                  )
              },
              // empty cell for alignment
              {
                  render: () => {}
              }
          ]
        : null;

    const tableFooter = [
        // label
        {
            kind: ColumnType.text,
            render: () => (
                <Typography variant="boldLabel">
                    {fs("totalPassiveBalanceSheet")}
                </Typography>
            )
        },
        // account value
        {
            kind: ColumnType.numeric,
            render: () => (
                <FormattedAmount
                    variant="boldLabel"
                    amount={total}
                    currency="EUR"
                />
            )
        },
        // empty cell for alignment
        {
            render: () => {}
        }
    ];

    const emptyRowFooter = [
        // empty cell for alignment
        {
            render: () => {}
        },
        // empty cell for alignment
        {
            render: () => {}
        },
        // empty cell for alignment
        {
            render: () => {}
        }
    ];

    return (
        <HierarchyTable
            headCells={headCells}
            data={rows}
            parentColumns={parentColumns}
            parentRowKey="parentAccount"
            childrenColumns={columns}
            childrenRowKey="accounts"
            numRows={numRows}
            footer={compact([
                emptyRowFooter,
                tableFooter,
                balanceSheetFooter,
                operatingResult ? null : emptyRowFooter
            ])}
        />
    );
}
