import { TableCell, TableCellProps } from "@vapor/react-material";

interface TableHeadCellProps extends TableCellProps {}

export default function TableHeadCell({
    children,
    ...tableCellProps
}: TableHeadCellProps) {
    return (
        <TableCell size="medium" {...tableCellProps}>
            {children}
        </TableCell>
    );
}
