import { faCircleNotch, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@vapor/react-material";

import {
    useAccountingLines,
    useDeleteAccountingLine
} from "../../../../core/domain/AccountingLines/queries";
import { useDraft } from "../../../../core/domain/Draft/queries";
import { Reason } from "../../../../utils/appEnums";
import { isXML } from "../../../../utils/draftUtils";

interface DeleteButtonProps {
    draftId: string;
    accountingLineId: string;
}

export default function DeleteButton({
    draftId,
    accountingLineId
}: DeleteButtonProps) {
    const { data: draft, isSuccess: isSuccessDraft } = useDraft(draftId);
    const { data: accountingLines, isSuccess: isSuccessAccountingLines } =
        useAccountingLines({ draftId });
    const { mutate, isPending } = useDeleteAccountingLine({
        accountingLineId: accountingLineId,
        draftId: draftId
    });

    if (!isSuccessAccountingLines || !isSuccessDraft) {
        return null;
    }

    const accountingLine = accountingLines.find(
        (accountingLine) => accountingLine.uuid === accountingLineId
    );

    if (!accountingLine) {
        return null;
    }

    const canAccountingLineBeDeleted = () => {
        if (isXML(draft)) {
            return false;
        }

        if (draft.reason === Reason.CA21) {
            return true;
        }

        if (draft.reasonId === Reason.PF24) {
            return (
                !accountingLine.isCustomer &&
                !accountingLine.isSupplier &&
                !accountingLine.isBank
            );
        }

        return (
            !accountingLine.isCustomer &&
            !accountingLine.isSupplier &&
            !accountingLine.isVat
        );
    };

    if (!canAccountingLineBeDeleted()) {
        return null;
    }

    const handleDeleteClick = () => {
        mutate();
    };

    return (
        <IconButton
            size="small"
            color="primary"
            onClick={handleDeleteClick}
            disabled={isPending}
        >
            <FontAwesomeIcon
                icon={isPending ? faCircleNotch : faTrashAlt}
                color="primary"
                spin={isPending}
            />
        </IconButton>
    );
}
