import { useCallback } from "react";
import { useIntl } from "react-intl";

export default function useCurrencySymbol() {
    const { formatNumberToParts } = useIntl();

    const currencySymbol =
        useCallback(
            (currency: string) =>
                formatNumberToParts(0, {
                    currency: currency,
                    currencyDisplay: "symbol",
                    currencySign: "accounting",
                    style: "currency"
                }).find((part) => part.type === "currency")?.value,
            [formatNumberToParts]
        ) ?? null;

    return currencySymbol;
}
