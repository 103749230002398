import { useGet } from "@onefront/react-sdk";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface UseGetAssetDeductibilityGoods {
    loading: boolean;
    error: any;
    deductibilityGoods: string[] | null;
    fetch: Function;
    hasFetched: boolean;
}

interface UseGetAssetDeductibilityGoodsOptions {
    accountCode?: string | null;
    lazy?: boolean;
}

export const useGetAssetDeductibilityGoods = ({
    accountCode,

    lazy
}: UseGetAssetDeductibilityGoodsOptions): UseGetAssetDeductibilityGoods => {
    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/asset/deductibilityGoods`,
        {
            ...useAxiosConfigRequestParams("DeductibilityGoods"),
            params: omitNilProperties({
                ...useCustomerData(),
                accountCode: accountCode
            }),
            lazy: lazy
        }
    );

    const safeFetch = useCallback(() => fetch(), [fetch]);

    return {
        fetch: safeFetch,
        loading: loading,
        error: error,
        deductibilityGoods: data,
        hasFetched: hasLoaded
    };
};
