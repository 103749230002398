import { useGet } from "@onefront/react-sdk";
import { useCallback, useState } from "react";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { UsedGoodApiResList } from "../dtos/DraftUsedGoodsDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

export interface UseGetDraftUsedGoods {
    loading: boolean;
    error: any;
    hasFetched: boolean;
    fetchWithParameters: (draftId: string) => Promise<UsedGoodApiResList[]>;
    hasResponded: boolean;
}

export interface UseGetDraftUsedGoodsOptions {
    draftId?: string | null;
    lazy?: boolean;
}

export const useGetDraftUsedGoods = ({
    draftId,
    lazy
}: UseGetDraftUsedGoodsOptions): UseGetDraftUsedGoods => {
    const customerData = useCustomerData();
    const [hasResponded, setHasResponded] = useState<boolean>(false);

    const { fetch, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/precog/drafts/${draftId}/usedGoods`,
        {
            ...useAxiosConfigRequestParams("DraftUsedGoods"),
            onResponse: () => {
                setHasResponded(true);
            },
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: lazy
        }
    );

    const fetchWithParameters = useCallback(
        (draftId: string) => {
            setHasResponded(false);
            return fetch({
                url: `${API_URL}/v1/precog/drafts/${draftId}/usedGoods`,
                params: {
                    ...customerData
                }
            }).then((res: AxiosResponse<UsedGoodApiResList[]>) => res.data);
        },
        [fetch, customerData]
    );

    return {
        loading: loading,
        error: error,
        hasFetched: hasLoaded,
        fetchWithParameters,
        hasResponded
    };
};
