import { SxProps } from "@mui/system";
import {
    Autocomplete,
    FormControl,
    InputLabel,
    Skeleton,
    TextField
} from "@vapor/react-material";
import { isNil, omit, uniqBy } from "lodash";
import { Controller, useFormContext } from "react-hook-form";

import { EXEMPTION_NATURES_TO_VISUALIZE_IN_SELECT } from "../../../config";
import { useExemptionNatures } from "../../../core/domain/ExemptionNatures/queries";
import { ExemptionNature } from "../../../core/usecases/dtos/ExemptionNaturesDto";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import { FormField } from "./types";

interface FormSelectVatRateProps extends FormField {
    sx?: SxProps;
}

export const MockExemptionNatureToOpenDrawer: ExemptionNature = {
    id: "MOCK",
    description: "Vedi tutti",
    nature: "MOCK",
    shortDescription: "MOCK"
};

export default function FormSelectExemption({
    name,
    label,
    required,
    disabled,
    sx
}: FormSelectVatRateProps) {
    const fs = useFormattedStringWithScope(
        "components.FormSelectExemptionNature"
    );

    const { control } = useFormContext();

    const {
        data: exemptionNatures,
        isLoading,
        isSuccess
    } = useExemptionNatures();

    if (isLoading) {
        return <Skeleton variant="rectangular" />;
    }

    if (isSuccess) {
        const getExemptionNaturesToVisualize = (
            currentValue: ExemptionNature
        ) => {
            const exemptionNaturesToVisualize = exemptionNatures
                .slice(EXEMPTION_NATURES_TO_VISUALIZE_IN_SELECT)
                .concat(MockExemptionNatureToOpenDrawer);

            return !isNil(currentValue)
                ? uniqBy(
                      [currentValue].concat(exemptionNaturesToVisualize),
                      "id"
                  )
                : exemptionNaturesToVisualize;
        };

        return (
            <Controller
                control={control}
                shouldUnregister={true}
                name={name}
                rules={{ required: required }}
                render={({ field, fieldState }) => (
                    <FormControl
                        fullWidth
                        error={!isNil(fieldState.error)}
                        disabled={disabled}
                    >
                        <InputLabel required={required}>{label}</InputLabel>
                        <Autocomplete
                            options={getExemptionNaturesToVisualize(
                                field.value
                            )}
                            loading={isLoading}
                            renderInput={(params) => <TextField {...params} />}
                            getOptionLabel={(option) =>
                                (option as ExemptionNature).id ===
                                MockExemptionNatureToOpenDrawer.id
                                    ? fs("seeAll")
                                    : (option as ExemptionNature).description
                            }
                            onChange={(_: any, vatRate: any) =>
                                field.onChange(vatRate)
                            }
                            autoComplete={true}
                            autoHighlight={true}
                            openOnFocus={true}
                            disablePortal={true}
                            disabled={disabled}
                            {...omit(field, "onChange", "disabled")}
                            sx={{ width: "100%", ...sx }}
                            componentsProps={{
                                popper: {
                                    placement: "bottom-start",
                                    sx: {
                                        width: "fit-content !important"
                                    }
                                }
                            }}
                        />
                    </FormControl>
                )}
            />
        );
    }

    return null;
}
