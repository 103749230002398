import Typography, {
    ExtendedTypographyProps
} from "@vapor/react-extended/ExtendedTypography";
import omit from "lodash/omit";
import { FormattedNumber } from "react-intl";

export interface FormattedAmountProps extends ExtendedTypographyProps {
    amount: number;
    currency: string;
    color?: string;
    // this is a workaround for the style props of the FormattedAmount component
    // the style prop is misinterpreted by the linter causing a warning
    formattedNumberStyle?: "currency";
}

export default function FormattedAmount({
    amount,
    currency,
    color,
    // this is part of the aforementioned workaround.
    // for the moment we have to leave it like this
    formattedNumberStyle = "currency",
    ...typographyProps
}: FormattedAmountProps) {
    function renderFormattedAmounts(formattedNumber: string) {
        return (
            <Typography
                color={color || ""}
                // we have to omit the sx props because types are incompatible
                {...omit(typographyProps, "sx")}
            >
                {formattedNumber}
            </Typography>
        );
    }
    return (
        <FormattedNumber
            style={formattedNumberStyle}
            value={amount}
            currency={currency}
        >
            {renderFormattedAmounts}
        </FormattedNumber>
    );
}
