import { useRequest } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AccountDto } from "../dtos/AccountsDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

type DuplicateAccountOptions = Pick<
    AccountDto,
    "code" | "description" | "title"
>;

interface UseDuplicateAccount {
    duplicate: (options: DuplicateAccountOptions) => Promise<AccountDto>;
    error: any;
    loading: boolean;
}

export default function useDuplicateAccount(): UseDuplicateAccount {
    const fs = useFormattedStringWithScope("notifications.DuplicateAccount");
    const { enqueueSnackbar } = useSnackbar();
    const customerData = useCustomerData();

    const { fetch, loading, error } = useRequest({
        url: `${API_URL}/v1/accounting/postingAccounts/duplicate`,
        method: "post",
        ...useAxiosConfigRequestParams("useDuplicateAccount"),
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        onResponse: () =>
            enqueueSnackbar(fs("success"), { severity: "success" }),
        lazy: true
    });

    const duplicate = async (options: DuplicateAccountOptions) => {
        return fetch({
            params: {
                ...customerData
            },
            data: {
                title: options.title,
                description: options.description,
                accountCode: options.code
            }
        }).then((response: AxiosResponse<AccountDto>) => response.data);
    };

    return {
        error,
        loading,
        duplicate
    };
}
