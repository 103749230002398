import { VatSettlementEntry } from "../../../../../../core/usecases/dtos/VatSettlementDto";
import { VatSettlement } from "../../../../../../core/usecases/interfaces/VatSettlement";
import { Ventilations } from "../../../../../../utils/appEnums";
import InfoRecap from "../MultiActivities/infoRecap";
import Summary from "./summary";
import MonoActivityTable from "./Table";

const hiddenActivities = [
    "IVA_INDETRAIBILE_PRORATA",
    "IVA_DETRAIBILE_REGIME_SPECIALE_AGRICOLTURA",
    "SUSPENDED_PURCHASE_PAID",
    "SUSPENDED_PURCHASE",
    "SUSPENDED_SALE",
    "SUSPENDED_SALE_PAID"
];

interface MonoActivityProps {
    settlement?: VatSettlement;
    businessSettlements: VatSettlementEntry[];
    onAliquotesDetailClick: Function;
}

const MonoActivity = ({
    settlement,
    businessSettlements,
    onAliquotesDetailClick
}: MonoActivityProps) => {
    const selectedSettlement = businessSettlements?.[0];

    const filteredSettlement = selectedSettlement.details?.filter(
        (vatSettlementDetail) =>
            vatSettlementDetail.description !==
                Ventilations.VENTILATION_RECEIPT &&
            vatSettlementDetail.description !==
                Ventilations.VENTILATION_PURCHASE
    );

    return selectedSettlement ? (
        <div>
            {settlement && <InfoRecap settlement={settlement} />}
            <Summary selectedSettlement={selectedSettlement} />
            <MonoActivityTable
                data={filteredSettlement.filter(
                    (detail) => !hiddenActivities.includes(detail.description)
                )}
                onAliquotesDetailClick={onAliquotesDetailClick}
            />
        </div>
    ) : null;
};

export default MonoActivity;
