import { TaskStatus } from "./TaskStatus";

export interface AccountingPeriodTasksDto {
    workspaceId: string;
    accountingPeriodId: string;
    tasks: AccountingPeriodTaskDto[];
}

export enum TaskState {
    COMPLETED = "COMPLETED",
    ACTION_REQUIRED = "ACTION_REQUIRED"
}

export enum TaskId {
    OPENING_NET_INCOME = "OPENING_NET_INCOME",
    DEFERRED_REVENUES = "DEFERRED_REVENUES",
    DEFERRED_EXPENSES = "DEFERRED_EXPENSES",
    CLOSING_OPENING_ACCOUNTS = "CLOSING_OPENING_ACCOUNTS",
    DEPRECIATION = "DEPRECIATION"
}

export interface AccountingPeriodTaskDto {
    taskId: TaskId;
    amount: Amount;
    state: TaskStatus;
    date: string;
}

export interface Amount {
    [key: string]: number;
}
