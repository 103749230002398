import isNil from "lodash/isNil";

import { CountAllDto, CountDto } from "../dtos/CountDto";
import { Count } from "../interfaces/Count";

export function convertCount(count: CountDto): Count {
    return isNil(count)
        ? count
        : {
              ...count,
              confirmed: {
                  ...count.confirmed,
                  total: getTotalConfirmed(count.confirmed)
              },
              notConfirmed: {
                  ...count.notConfirmed,
                  total: getTotalConfirmed(count.notConfirmed)
              }
          };
}

function getTotalConfirmed(countAll: CountAllDto): number {
    return (
        countAll.countActiveInvoice.total +
        countAll.countPassiveInvoice.total +
        countAll.countBankTransaction +
        countAll.countFinancialTransaction +
        countAll.countOtherTransactions +
        countAll.countReceipt +
        countAll.countOthers
    );
}
