import { AxiosResponse } from "../interfaces/AxiosResponse";
import { useGet, useSnackbar } from "@onefront/react-sdk";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import { CheckOnboardingDto } from "../dtos/CheckOnboardingDto";

import { ASYNC_API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface UseGetOnboarding {
    checkOnboarding: () => Promise<CheckOnboardingDto>;
    error: any;
    loading: boolean;
}

export default function useGetOnboarding(): UseGetOnboarding {
    const fs = useFormattedStringWithScope("notifications.GetOnboarding");
    const { enqueueSnackbar } = useSnackbar();

    const { fetch, loading, error } = useGet(
        `${ASYNC_API_URL}/frontend-auth/canAct`,
        {
            ...useAxiosConfigRequestParams("useGetOnboarding"),
            onError: () =>
                enqueueSnackbar(fs("error"), {
                    variant: "error"
                }),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: true
        }
    );

    return {
        checkOnboarding: async () => {
            return fetch().then(
                (response: AxiosResponse<CheckOnboardingDto>) => response.data
            );
        },
        loading: loading,
        error: error
    };
}
