import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    CircularProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@vapor/react-material";
import { useState } from "react";

import {
    useAccountingLines,
    useCreateF24AccountingLine
} from "../../../../core/domain/AccountingLines/queries";
import { sortF24Lines } from "../../../../utils/f24Utils";
import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";
import CustomButton from "../../CustomButton";
import SelectTribute from "../../SelectTribute";
import TableHeadCell from "../../TableHead/TableHeadCell";
import TableHeadText from "../../TableHead/TableHeadText";
import { TotalCell } from "../AccountingLinesTable/table";
import F24Row from "./F24Row";

type AddTributeButtonProps = {
    draftId: string;
};
const AddTributeButton = (props: AddTributeButtonProps) => {
    const fs = useFormattedStringWithScope(
        "components.Accounting.F24AccountingLinesTable"
    );
    const [open, setOpen] = useState<boolean>(false);
    const { mutate: create, isPending } = useCreateF24AccountingLine({
        draftId: props.draftId
    });

    const handleAddTribute = (tributeCode: string) => {
        create({
            tributeCode,
            accountCode: tributeCode,
            tributeYear: new Date().getFullYear().toString()
        });
        setOpen(false);
    };

    if (open) {
        return (
            <Stack direction="row">
                <SelectTribute
                    readOnly={false}
                    onChange={handleAddTribute}
                    transparent={true}
                    autoFocus={true}
                />
                <Button variant="text" onClick={() => setOpen(false)}>
                    {fs("cancelAddTribute")}
                </Button>
            </Stack>
        );
    } else {
        return (
            <CustomButton
                variant="text"
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                loading={isPending}
                onClick={() => setOpen(true)}
            >
                {fs("addTribute")}
            </CustomButton>
        );
    }
};

interface F24AccountingLinesTableProps {
    draftId: string;
    readOnly?: boolean;
}

export default function F24AccountingLinesTable({
    draftId,
    readOnly = false
}: F24AccountingLinesTableProps) {
    const fs = useFormattedStringWithScope(
        "components.Accounting.F24AccountingLinesTable"
    );

    const {
        data: accountingLines,
        isLoading,
        isSuccess
    } = useAccountingLines({ draftId });

    if (isLoading) {
        return <CircularProgress />;
    }

    if (isSuccess) {
        const sortedAccountingLines = sortF24Lines(accountingLines);

        return (
            <>
                <Table>
                    <TableHead shadow>
                        <TableHeadCell>
                            <TableHeadText>{fs("tributeCode")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell>
                            <TableHeadText>{fs("accountCode")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell align="right">
                            <TableHeadText>{fs("tributeYear")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell
                            align="right"
                            sx={{
                                paddingRight: "24px !important"
                            }}
                        >
                            <TableHeadText>{fs("give")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell
                            align="right"
                            sx={{
                                paddingRight: "24px !important"
                            }}
                        >
                            <TableHeadText>{fs("take")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell>
                            {/* placeholder for ctas */}
                        </TableHeadCell>
                    </TableHead>
                    <TableBody>
                        {sortedAccountingLines.map((accountingLine) => (
                            <F24Row
                                key={accountingLine.uuid}
                                draftId={draftId}
                                line={accountingLine}
                                readOnly={readOnly}
                            />
                        ))}
                        <TableRow>
                            {!readOnly && (
                                <TableCell size="small" colSpan={3}>
                                    <AddTributeButton draftId={draftId} />{" "}
                                </TableCell>
                            )}
                            {readOnly && <TableCell colSpan={3} />}
                            <TotalCell draftId={draftId} type="debit" />
                            <TotalCell draftId={draftId} type="credit" />
                            <TableCell />
                        </TableRow>
                    </TableBody>
                </Table>
            </>
        );
    }

    return null;
}
