import { TextField, useTheme } from "@vapor/react-material";
import { isNil, pick } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
    NumberFormatValues,
    NumericFormat,
    NumericFormatProps,
    SourceInfo
} from "react-number-format";

import useCurrencySymbol from "../../../core/commons/hooks/useCurrencySymbol";
import usePrevious from "../../../core/commons/hooks/usePrevious";

export interface CurrencyFieldProps
    extends Pick<NumericFormatProps, "isAllowed" | "readOnly"> {
    value: number;
    currency?: string;
    onBlur?: (value: number) => void;
    onChange?: (value: number) => void;
    displayCurrency?: boolean;
    transparent?: boolean;
    big?: boolean;
    error?: boolean;
    currencyPrefix?: boolean;
    red?: boolean;
}

export default React.forwardRef(function CurrencyField(
    {
        value,
        currency,
        onBlur,
        onChange,
        displayCurrency = true,
        transparent = false,
        big = false,
        error = false,
        currencyPrefix = false,
        red = false,
        ...numericFormatProps
    }: CurrencyFieldProps,
    ref
) {
    const { formatNumber } = useIntl();
    const theme = useTheme();
    const currencySymbol = useCurrencySymbol();
    const [internalValue, setInternalValue] = useState<number>(value ?? 0);
    const previousInternalValue = usePrevious(internalValue);

    useEffect(() => {
        setInternalValue(value ?? 0);
    }, [value]);

    const handleInputChange = (
        newValue: NumberFormatValues,
        source: SourceInfo["source"]
    ) => {
        // do not update internal value if the change came from props
        // WARNING: SourceType is not correctly exported from react-number-format
        // and causes a compilation error, so we manually check for the string value
        if (source === ("prop" as unknown)) return;

        if (isNil(newValue.floatValue)) {
            setInternalValue(0);
            onChange && onChange(0);
        } else {
            setInternalValue(newValue.floatValue);
            onChange && onChange(newValue.floatValue);
        }
    };

    const handleBlur = () => {
        !numericFormatProps.readOnly &&
            internalValue !== previousInternalValue &&
            onBlur &&
            onBlur(internalValue);
    };

    const decimalSeparator = useMemo(
        () => formatNumber(0.1).replace(/\d/g, ""),
        [formatNumber]
    );
    const thousandSeparator = useMemo(
        () => formatNumber(1000).replace(/\d/g, ""),
        [formatNumber]
    );

    return (
        <NumericFormat
            value={internalValue ?? 0}
            prefix={
                currencyPrefix
                    ? displayCurrency && currency
                        ? currencySymbol(currency) + " "
                        : undefined
                    : undefined
            }
            suffix={
                currencyPrefix
                    ? undefined
                    : displayCurrency && currency
                    ? " " + currencySymbol(currency)
                    : undefined
            }
            customInput={TextField}
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            decimalScale={2}
            fixedDecimalScale
            onValueChange={(values, { source }) => {
                values && handleInputChange(values, source);
            }}
            inputRef={ref}
            onBlur={handleBlur}
            onFocus={(event) => event.target.select()}
            InputProps={{
                error: error,
                sx: {
                    color: red ? theme.palette.primary.baseCriticalColor : null,
                    bgcolor: transparent ? "transparent !important" : null,
                    fieldset: {
                        border: transparent && !error ? "none" : null
                    },
                    "input ": {
                        textAlign: "right",
                        fontSize: big ? "22px" : null,
                        fontWeight: big ? "bold" : null
                    }
                }
            }}
            {...pick(numericFormatProps, "isAllowed", "readOnly")}
        />
    );
});
