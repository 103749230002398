import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { WithholdingParametersDto } from "../dtos/WithholdingParametersDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

export interface GetWithholdingWithParametersOptions {
    reasonId?: string;
    totalNetAmount?: number;
    cashContribution?: number;
    notIncomeAmount?: number;
    alreadySubjectedAmount?: number;
    accountingReason?: string;
}

export interface UseGetWithholdingWithParameters {
    loading: boolean;
    error: any;
    parameters: WithholdingParametersDto;
    get: (
        options: GetWithholdingWithParametersOptions
    ) => Promise<WithholdingParametersDto>;
    hasFetched: boolean;
}

export const useGetWithholdingWithParameters =
    (): UseGetWithholdingWithParameters => {
        const cusomterData = useCustomerData();

        const { fetch, data, loading, error, hasLoaded } = useGet(
            `${API_URL}/v1/withholding/with-parameters`,
            {
                ...useAxiosConfigRequestParams("WithholdingParameters"),
                lazy: true
            }
        );

        const get = async ({
            cashContribution,
            notIncomeAmount,
            reasonId,
            accountingReason,
            totalNetAmount,
            alreadySubjectedAmount
        }: GetWithholdingWithParametersOptions) => {
            return fetch({
                params: omitNilProperties({
                    ...cusomterData,
                    reasonId,
                    totalNetAmount,
                    cashContribution,
                    notIncomeAmount,
                    accountingReason,
                    alreadySubjectedAmount
                })
            }).then(
                (response: AxiosResponse<WithholdingParametersDto>) =>
                    response.data
            );
        };

        return {
            get,
            loading: loading,
            error: error,
            parameters: data,
            hasFetched: hasLoaded
        };
    };
