import { useGet } from "@onefront/react-sdk";
import isNil from "lodash/isNil";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AccountingPeriodDeferralsDto } from "../dtos/AccountingPeriodDeferralsDto";

interface UseGetAccountingPeriodDeferralsOptions {
    lazy?: boolean;
}

interface UseGetAccountingPeriodDeferrals {
    fetch: (accountingPeriodId: string) => void;
    data: AccountingPeriodDeferralsDto | null;
    loading: boolean;
    error: any;
}

export default function useGetAccountingPeriodDeferrals({
    lazy
}: UseGetAccountingPeriodDeferralsOptions): UseGetAccountingPeriodDeferrals {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope(
        "notifications.GetAccountingPeriodDeferrals"
    );

    const customerData = useCustomerData();

    const {
        fetch: fetchData,
        data,
        loading,
        error
    } = useGet(`ignored`, {
        ...useAxiosConfigRequestParams("GetAccountingPeriodDeferrals"),
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        lazy: lazy
    });

    const fetch = useCallback(
        (accountingPeriodId: string) => {
            fetchData({
                url: `${API_URL}/v1/ledger/accountingPeriods/${accountingPeriodId}/deferrals`,
                params: {
                    ...customerData
                }
            });
        },
        [fetchData, customerData]
    );

    return {
        fetch: fetch,
        data: error || isNil(data) || loading ? null : data,
        loading: loading,
        error: error
    };
}
