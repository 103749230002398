import { faListDropdown } from "@fortawesome/pro-regular-svg-icons";
import { VaporIcon } from "@vapor/react-icons";
import { IconButton, Stack, TextField, Tooltip } from "@vapor/react-material";
import { isNil } from "lodash";
import { useEffect, useState } from "react";

import usePrevious from "../../../../core/commons/hooks/usePrevious";
import { AccountingLine } from "../../../../core/usecases/dtos/AccountingLinesDto";
import { ExemptionNature } from "../../../../core/usecases/dtos/ExemptionNaturesDto";
import { VatRate } from "../../../../utils/appEnums";
import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";
import ExemptionNatureDialog from "../../../components/ExemptionNatureDialog";
import ReverseChargeDialog from "../../ReverseChargeDialog";
import { TransparentAutoComplete } from "../../styled";

interface SelectVatRateProps {
    draftId: string;
    value?: VatRate | null;
    disabled?: boolean;
    reverseCharge?: boolean;
    onChange: (vatRate: VatRate, exemptionNature?: ExemptionNature) => void;
    onReverseChargeRateChange?: (reverseChargeRate: VatRate) => void;
    accountingLine: AccountingLine;
}

export default function SelectVatRate({
    draftId,
    value,
    accountingLine,
    reverseCharge = false,
    disabled = false,
    onChange,
    onReverseChargeRateChange = () => {}
}: SelectVatRateProps) {
    const {
        doNotApplyReverseCharge,
        reverseChargeRate,
        exemptionNature,
        exemptionNatureId
    } = accountingLine;
    const vatRates = Object.keys(VatRate);
    const fs = useFormattedStringWithScope(
        "components.Accounting.AccountingLinesTable.SelectVatRate"
    );
    const fsVatRate = useFormattedStringWithScope("commons.VatRate");
    const previousValue = usePrevious(value);
    const handleExemption = value === VatRate["0.0"];
    const hasHandledReverseCharge =
        !isNil(doNotApplyReverseCharge) || !isNil(reverseChargeRate);
    const [isExemptionNatureModalOpen, setIsExemptionNatureModalOpen] =
        useState<boolean>(false);
    const [isReverseChargeModalOpen, setIsReverseChargeModalOpen] =
        useState<boolean>(false);

    useEffect(() => {
        previousValue &&
            previousValue !== value &&
            handleExemption &&
            setIsExemptionNatureModalOpen(true);
    }, [value, previousValue, handleExemption]);

    return (
        <>
            <Stack direction="row" alignItems="center">
                <TransparentAutoComplete
                    value={value}
                    onChange={(_: any, vatRate: any) => onChange(vatRate)}
                    options={vatRates}
                    renderInput={(params) => <TextField {...params} />}
                    getOptionLabel={(option) =>
                        handleExemption && option.toString() === VatRate["0.0"]
                            ? fsVatRate(option.toString()) +
                              " - " +
                              (exemptionNature ?? fs("selectNature"))
                            : fsVatRate(option.toString())
                    }
                    readOnly={disabled}
                    autoComplete={true}
                    autoHighlight={true}
                    openOnFocus={true}
                    sx={{
                        width: "96px"
                    }}
                />
                {/* TODO: verify feasibility of this handling of exemption natures */}
                <Tooltip title={fs("selectNature")}>
                    <div>
                        {handleExemption && (
                            <IconButton
                                color="primary"
                                onClick={() =>
                                    setIsExemptionNatureModalOpen(true)
                                }
                            >
                                <VaporIcon
                                    color={
                                        isNil(exemptionNature)
                                            ? "warning"
                                            : "primary"
                                    }
                                    icon={faListDropdown}
                                />
                            </IconButton>
                        )}
                    </div>
                </Tooltip>
                {reverseCharge ? (
                    <>
                        <Tooltip title={fs("handleReverseCharge")}>
                            <div>
                                <IconButton
                                    onClick={() =>
                                        setIsReverseChargeModalOpen(true)
                                    }
                                >
                                    <VaporIcon
                                        color={
                                            hasHandledReverseCharge
                                                ? "primary"
                                                : "warning"
                                        }
                                        iconName={
                                            hasHandledReverseCharge
                                                ? "edit"
                                                : "exclamation-triangle"
                                        }
                                    />
                                </IconButton>
                            </div>
                        </Tooltip>
                        {doNotApplyReverseCharge ? null : (
                            <TransparentAutoComplete
                                value={reverseChargeRate ?? null}
                                onChange={(_: any, reverseChargeRate: any) =>
                                    onReverseChargeRateChange(reverseChargeRate)
                                }
                                options={vatRates.filter(
                                    (vatRate) => vatRate !== VatRate["0.0"]
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        placeholder={fs("reverseChargeRate")}
                                        {...params}
                                    />
                                )}
                                getOptionLabel={(option) =>
                                    fsVatRate(option.toString())
                                }
                                readOnly={disabled}
                                autoComplete={true}
                                openOnFocus={true}
                                sx={{
                                    width: "96px"
                                }}
                            />
                        )}
                    </>
                ) : null}
            </Stack>
            <ExemptionNatureDialog
                draftId={draftId}
                isModalOpen={isExemptionNatureModalOpen}
                handleSelectExemption={(exemption) =>
                    onChange(VatRate["0.0"], exemption)
                }
                exemptionNature={exemptionNature}
                natureId={exemptionNatureId}
                onClose={() => setIsExemptionNatureModalOpen(false)}
            />
            <ReverseChargeDialog
                draftId={draftId}
                isModalOpen={isReverseChargeModalOpen}
                accountingLine={accountingLine}
                onClose={() => setIsReverseChargeModalOpen(false)}
            />
        </>
    );
}
