import { DialogTitleProps } from "@mui/material/DialogTitle";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { DialogTitle, IconButton } from "@vapor/react-material";
import { ReactElement } from "react";

interface TitleCenteredDialogProps extends DialogTitleProps {
    title: string;
    onClose: () => void;
    icon: ReactElement;
}

export default function TitleCenteredDialog(props: TitleCenteredDialogProps) {
    return (
        <DialogTitle>
            <Typography sx={{ width: "100%", textAlign: "center" }}>
                {props.title}
            </Typography>
            <IconButton color="primary" onClick={props.onClose}>
                {props.icon}
            </IconButton>
        </DialogTitle>
    );
}
