import { useRequest } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface UseDeleteDraft {
    deleteDraft: (draftId: string) => Promise<void>;
    loading: boolean;
}

export const useDeleteDraft = (): UseDeleteDraft => {
    const { enqueueSnackbar } = useSnackbar();

    const customerData = useCustomerData();

    const fs = useFormattedStringWithScope("notifications.DeleteDraft");

    const { fetch, loading } = useRequest({
        ...useAxiosConfigRequestParams("DeleteDraft"),
        method: "delete",
        params: omitNilProperties({
            ...useCustomerData()
        }),
        onError: () =>
            enqueueSnackbar(fs("error"), {
                severity: "error"
            }),
        onResponse: () => {
            enqueueSnackbar(fs("success"), { severity: "success" });
        },
        lazy: true
    });

    const deleteDraft = (draftId: string) => {
        return fetch({
            url: `${API_URL}/v1/precog/drafts/${draftId}`,
            params: omitNilProperties({
                ...customerData
            })
        });
    };

    return {
        deleteDraft,
        loading
    };
};
