import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useGet } from "@onefront/react-sdk";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ASYNC_API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { SetupAction } from "../../usecases/dtos/SetupStatusDto";
import { default as useGetSetupApi } from "../../usecases/useGetSetup";
import usePostSetup from "../../usecases/usePostSetup";

export const setupBanner = createQueryKeys("setup", {
    all: null,
    registrationSettings: null
});

export const useGetSetup = () => {
    const { fetchStatus } = useGetSetupApi();

    return useQuery({
        ...setupBanner.all,
        queryFn: fetchStatus
    });
};

export const usePerformSetupAction = () => {
    const queryClient = useQueryClient();
    const { performAction } = usePostSetup();

    return useMutation({
        mutationFn: async (action: SetupAction) => {
            return performAction(action);
        },
        onSuccess: () => {
            queryClient.refetchQueries(setupBanner.all);
        }
    });
};

type RegistrationSettings = {
    canSearchBalanceSheetAccounts: boolean;
    canSearchIncomeStatementAccounts: boolean;
    canSetVatCompetencePeriod: boolean;
};
export const useCA21Settings = () => {
    const { fetch } = useGet<RegistrationSettings>(
        `${ASYNC_API_URL}/setup/registration-settings`,
        {
            ...useAxiosConfigRequestParams("GetCA21Settings"),
            params: {
                ...useCustomerData()
            },
            lazy: true
        }
    );

    return useQuery({
        ...setupBanner.registrationSettings,
        queryFn: () => fetch(),
        select: ({ data }) => data,
        // This query is not expected to change, so we can cache it indefinitely
        // since the setup registration settings depend on the commencement date
        // and it is fixed during the customer onboarding process and never changes
        staleTime: Infinity
    });
};
