import Stack from "@vapor/react-material/Stack";
import Chip from "@vapor/react-material/Chip";

import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";
import { JournalType } from "../../../../../../../utils/appEnums";

const fs = getFormattedStringWithScope(
    "views.VATReport.vatEntries.filter.reportType"
);

interface FilterReportJournalTypeProps {
    journalType?: JournalType;
    onSelectJournalType: (value: string) => void;
}

const FilterReportJournalType = ({
    journalType,
    onSelectJournalType
}: FilterReportJournalTypeProps) => {
    const chips: { label: string; value: string }[] = [
        {
            label: fs("all"),
            value: JournalType.ALL
        },
        {
            label: fs("sale"),
            value: JournalType.SALE
        },
        {
            label: fs("purchase"),
            value: JournalType.PURCHASE
        },
        {
            label: fs("receipt"),
            value: JournalType.RECEIPTS
        }
    ];

    const handleSelectChip = (journalType: JournalType) => {
        onSelectJournalType(journalType);
    };

    return (
        <Stack direction="row" spacing={1}>
            {chips.map((chip) => (
                <Chip
                    key={chip.value}
                    label={chip.label}
                    clickable
                    variant={journalType === chip.value ? "outlined" : "filled"}
                    onClick={() => handleSelectChip(chip.value as JournalType)}
                />
            ))}
        </Stack>
    );
};

export default FilterReportJournalType;
