import { useEffect, useRef } from "react";

import usePoll from "../../commons/hooks/usePoll";
import { VatSettlementDto } from "../dtos/VatSettlementsDto";
import { VatSettlement } from "../interfaces/VatSettlement";
import { UseGetSettlements, useGetSettlements } from "../useGetSettlements";
import { usePostVatSettlementClose } from "../usePostVatSettlementClose";
import { usePostVatSettlementOpen } from "../usePostVatSettlementOpen";

interface CloseSettlementOptions {
    settlement: VatSettlementDto;
    year: number;
}

interface OpenSettlementOptions {
    settlement: VatSettlementDto;
    year: number;
}

interface UseManageSettlements {
    settlements: VatSettlement[] | null;
    fetchSettlements: UseGetSettlements["fetch"];
    isFetchingSettlements: boolean;
    closeSettlement: (options: CloseSettlementOptions) => void;
    openSettlement: (options: OpenSettlementOptions) => void;
    isFetchingSettlementClose: boolean;
    isFetchingSettlementOpen: boolean;
}

export default function useManageSettlements(): UseManageSettlements {
    const useGetSettlementsHook = useGetSettlements();
    const settlements = useGetSettlementsHook.data;
    const showLoading = useRef<boolean>(true);
    const useCloseSettlementHook = usePostVatSettlementClose();
    const useOpenSettlementHook = usePostVatSettlementOpen();
    const { poll, stop, isPolling } = usePoll();

    const closeSettlement = async ({
        settlement,
        year
    }: CloseSettlementOptions) => {
        await useCloseSettlementHook.fetch({
            year: year,
            ordinal: settlement.period.ordinal
        });
        showLoading.current = false;
        await useGetSettlementsHook.fetch(year);
        showLoading.current = true;
    };

    const openSettlement = async ({
        settlement,
        year
    }: OpenSettlementOptions) => {
        await useOpenSettlementHook.fetch({
            year: year,
            ordinal: settlement.period.ordinal
        });
        showLoading.current = false;
        await useGetSettlementsHook.fetch(year);
        showLoading.current = true;
    };

    useEffect(() => {
        if (settlements && settlements.length > 0) {
            const isSomeSettlementProcessing = settlements.some(
                (settlement) =>
                    settlement.status === "CLOSING" ||
                    settlement.status === "OPENING"
            );
            if (isSomeSettlementProcessing) {
                !isPolling &&
                    poll(() =>
                        useGetSettlementsHook.fetch(settlements[0].period.year)
                    );
            } else {
                stop();
            }
        }
    }, [
        settlements,
        useGetSettlementsHook,
        isPolling,
        poll,
        stop,
        useCloseSettlementHook,
        useOpenSettlementHook
    ]);

    return {
        settlements: settlements,
        fetchSettlements: useGetSettlementsHook.fetch,
        isFetchingSettlements:
            showLoading.current && !isPolling && useGetSettlementsHook.loading,
        closeSettlement: closeSettlement,
        openSettlement: openSettlement,
        isFetchingSettlementClose: useCloseSettlementHook.loading,
        isFetchingSettlementOpen: useOpenSettlementHook.loading
    };
}
