import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";
import { useState } from "react";

import { DraftWithholdingDto } from "../../../core/usecases/dtos/DraftWithholdingDto";
import { PrecogDraftVat } from "../../../core/usecases/dtos/PrecogDraftVatDto";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../FormattedAmount";
import WithholdingDrawer from "./Drawer";
import WithholdingsTable from "./Table";

const fs = getFormattedStringWithScope("views.Detail.withholdings");

interface WithholdingsProps {
    draftId: string;
    draftVat: PrecogDraftVat[];
    refCurrency: string;
}

export default function Withholdings({
    draftId,
    draftVat,
    refCurrency
}: WithholdingsProps) {
    const [isWithholdingDrawerOpen, setIsWithholdingDrawerOpen] =
        useState<boolean>(false);
    const [selectedWithholding, setSelectedWithholding] =
        useState<DraftWithholdingDto | null>(null);

    const totalTaxable = draftVat.reduce((acc, item) => acc + item.taxable, 0);

    const openDrawer = (withholding: DraftWithholdingDto) => {
        setSelectedWithholding(withholding);
        setIsWithholdingDrawerOpen(true);
    };

    const closeDrawer = () => {
        setSelectedWithholding(null);
        setIsWithholdingDrawerOpen(false);
    };

    return (
        <Box>
            <Box display="flex" justifyContent="flex-end" marginBottom="25px">
                <Stack alignItems="flex-end">
                    <Typography
                        color="primary.textTitleColor"
                        variant="subtitle"
                    >
                        {fs("totInvoiceNetVat")}
                    </Typography>
                    <FormattedAmount
                        variant="displayLarge"
                        amount={totalTaxable}
                        currency="EUR"
                    />
                </Stack>
            </Box>
            <WithholdingsTable
                draftId={draftId}
                totalNetAmount={totalTaxable}
                onOpenDrawer={openDrawer}
                refCurrency={refCurrency}
            />
            {isWithholdingDrawerOpen && (
                <WithholdingDrawer
                    draftId={draftId}
                    totalTaxable={totalTaxable}
                    selectedWithholding={selectedWithholding}
                    openDrawer={isWithholdingDrawerOpen}
                    onClose={closeDrawer}
                />
            )}
        </Box>
    );
}
