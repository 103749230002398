import {
    ColumnElement,
    ColumnType,
    HeadCell,
    Spinner,
    Table
} from "@comic/precog-components";
import Box from "@vapor/react-material/Box";
import IconButton from "@vapor/react-material/IconButton";
import TextField from "@vapor/react-material/TextField";
import Select from "@vapor/react-material/Select";
import MenuItem from "@vapor/react-material/MenuItem";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSquareArrowUpRight,
    faMagnifyingGlass,
    faFilter
} from "@fortawesome/pro-regular-svg-icons";
import { Waypoint } from "react-waypoint";
import debounce from "lodash/debounce";
import { useState, useEffect } from "react";
import FormControl from "@vapor/react-material/FormControl";

import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import { Elements } from "../../../../core/usecases/dtos/ListAssetsDto";
import "./style.css";
import FormattedAmount from "../../../components/FormattedAmount";
import useEnter from "../../../../core/commons/hooks/useEnter";
import AssetListDrawer from "./AssetListDrawer";
import FormattedDate from "../../../components/FormattedDate";
import { useGetAssetDetail } from "../../../../core/usecases/useGetAssetDetails";
import usePrevious from "../../../../core/commons/hooks/usePrevious";
import { ActiveStatus } from "../../../../utils/appEnums";

const fs = getFormattedStringWithScope("views.ListAssets.table");

interface AssetsListTableProps {
    assetsList: Elements[];
    fetchMore: Function;
    isLoadingMore: boolean;
    hasMore: boolean;
    onDescriptionChange: (newDescription: string) => void;
    onReferenceChange: (newReference: string) => void;
    purchaseDateFrom?: Date | null;
    onPurchaseDateFromChange: Function;
    purchaseDateTo?: Date | null;
    onPurchaseDateToChange: Function;
    saleDateFrom?: Date | null;
    onSaleDateFromChange: Function;
    saleDateTo?: Date | null;
    onSaleDateToChange: Function;
    active?: boolean | null;
    handleActive: (newActive: boolean | null) => void;
    isLoading: boolean;
}

export default function AssetsListTable({
    assetsList,
    fetchMore,
    isLoadingMore,
    hasMore,
    onDescriptionChange,
    onReferenceChange,
    handleActive,
    isLoading
}: AssetsListTableProps) {
    const [descr, setDescr] = useState<string>("");
    const [inputReference, setInputReference] = useState<string>("");
    const [activeStatus, setActiveStatus] = useState<string>("ALL");
    const [selectedAsset, setSelectedAsset] = useState<Elements | null>(null);

    const [open, setOpen] = useState<boolean>(false);

    const debouncedHandleDescChange = debounce(onDescriptionChange, 300);
    const debouncedHandleReferencesChange = debounce(onReferenceChange, 300);
    const debouncedHandleActiveChange = debounce(handleActive, 300);

    useEffect(() => {
        debouncedHandleDescChange(descr);
    }, [descr, debouncedHandleDescChange]);

    useEffect(() => {
        if (activeStatus === ActiveStatus.ALL) {
            debouncedHandleActiveChange(null);
        }
        if (activeStatus === ActiveStatus.ACTIVE) {
            debouncedHandleActiveChange(true);
        }
        if (activeStatus === ActiveStatus.PASSIVE) {
            debouncedHandleActiveChange(false);
        }
    }, [activeStatus, debouncedHandleActiveChange]);

    const handleDescriptionEnter = useEnter({
        callback: (value) => setDescr(value ?? "")
    });

    const handleDecriptionStateChange = (newDescription: string) =>
        setDescr(newDescription);

    const handleStatusChange = (event: any) => {
        setActiveStatus(event.target.value as string);
    };

    useEffect(() => {
        debouncedHandleReferencesChange(inputReference);
    }, [inputReference, debouncedHandleReferencesChange]);

    const handleAliasEnter = useEnter({
        callback: (value) => setInputReference(value ?? "")
    });

    const handleReferenceStateChange = (newReference: string) =>
        setInputReference(newReference);

    const previousAssetId = usePrevious(selectedAsset?.id);

    const {
        fetch: fetchAssetDetail,
        assetDetail,
        loading
    } = useGetAssetDetail({
        assetId: selectedAsset?.id,
        businessId: selectedAsset?.businessId,
        lazy: true
    });

    useEffect(() => {
        if (
            !loading &&
            selectedAsset?.id !== undefined &&
            previousAssetId !== selectedAsset?.id
        ) {
            fetchAssetDetail();
        }
    }, [previousAssetId, fetchAssetDetail, loading, selectedAsset?.id]);

    const headCells: HeadCell[] = [
        {
            id: "description",
            kind: ColumnType.text,
            label: fs("description"),
            filter: {
                kind: ColumnType.text,
                render: () => {
                    return (
                        <FormControl>
                            <TextField
                                {...handleDescriptionEnter}
                                placeholder={fs("searchPlaceholder")}
                                defaultValue={descr}
                                onBlur={(event) =>
                                    handleDecriptionStateChange(
                                        event.target.value
                                    )
                                }
                                InputProps={{
                                    endAdornment: (
                                        <IconButton>
                                            <FontAwesomeIcon
                                                className="v-AssetsListTable-icon"
                                                icon={faMagnifyingGlass}
                                            />
                                        </IconButton>
                                    )
                                }}
                                sx={{
                                    "& fieldset": {
                                        border: "none",
                                        background: "none"
                                    }
                                }}
                            />
                        </FormControl>
                    );
                }
            }
        },
        {
            id: "identificationCode",
            kind: ColumnType.text,
            label: fs("identificationCode"),
            filter: {
                kind: ColumnType.text,
                render: () => {
                    return (
                        <FormControl>
                            <TextField
                                {...handleAliasEnter}
                                sx={{
                                    "& fieldset": {
                                        border: "none",
                                        background: "none"
                                    }
                                }}
                                defaultValue={inputReference}
                                placeholder={fs("searchPlaceholder")}
                                onBlur={(event) =>
                                    handleReferenceStateChange(
                                        event.target.value
                                    )
                                }
                                InputProps={{
                                    endAdornment: (
                                        <IconButton>
                                            <FontAwesomeIcon
                                                className="v-AssetsListTable-icon"
                                                icon={faMagnifyingGlass}
                                            />
                                        </IconButton>
                                    )
                                }}
                            />
                        </FormControl>
                    );
                }
            }
        },
        {
            id: "purchaseDate",
            kind: ColumnType.text,
            label: fs("purchaseDate")
        },
        {
            id: "saleDate",
            kind: ColumnType.text,
            label: fs("saleDate")
        },
        {
            id: "assetValue",
            kind: ColumnType.text,
            label: fs("assetValue"),
            filter: {
                kind: ColumnType.text,
                render: () => {
                    return <div></div>;
                }
            }
        },
        {
            id: "totalDepricated",
            kind: ColumnType.text,
            label: fs("totalDepricated"),
            filter: {
                kind: ColumnType.text,
                render: () => {
                    return <div></div>;
                }
            }
        },
        {
            id: "residualValue",
            kind: ColumnType.text,
            label: fs("residualValue"),
            filter: {
                kind: ColumnType.text,
                render: () => {
                    return <div></div>;
                }
            }
        },
        {
            id: "state",
            kind: ColumnType.text,
            label: fs("state"),
            filter: {
                kind: ColumnType.text,
                render: () => {
                    return (
                        <Box>
                            <Select
                                sx={{
                                    ".MuiOutlinedInput-notchedOutline": {
                                        border: "none !important"
                                    }
                                }}
                                value={activeStatus}
                                onChange={handleStatusChange}
                            >
                                <MenuItem value={ActiveStatus.ALL}>
                                    {fs("status.all")}
                                </MenuItem>
                                <MenuItem value={ActiveStatus.ACTIVE}>
                                    {fs("status.active")}
                                </MenuItem>
                                <MenuItem value={ActiveStatus.PASSIVE}>
                                    {fs("status.notActive")}
                                </MenuItem>
                            </Select>
                        </Box>
                    );
                }
            }
        },
        {
            id: "",
            kind: ColumnType.cta,
            label: "",
            filter: {
                kind: ColumnType.text,
                render: () => {
                    return (
                        <Box>
                            <FontAwesomeIcon
                                className="v-AssetsListTable-icon"
                                icon={faFilter}
                            />
                        </Box>
                    );
                }
            }
        }
    ];

    const columns: ColumnElement[] = [
        {
            kind: ColumnType.text,
            render: (asset: Elements) => (
                <Typography display="inline-block" variant="body500">
                    {asset.name}
                </Typography>
            )
        },
        {
            kind: ColumnType.text,
            render: (asset: Elements) => (
                <Typography variant="body500">{asset.alias}</Typography>
            )
        },
        {
            kind: ColumnType.text,
            render: (asset: Elements) => (
                <FormattedDate variant="body500" date={asset.purchaseDate} />
            )
        },
        {
            kind: ColumnType.text,
            render: (asset: Elements) => (
                <FormattedDate variant="body500" date={asset.saleDate} />
            )
        },
        {
            kind: ColumnType.text,
            render: (asset: Elements) => (
                <FormattedAmount
                    variant="body500"
                    amount={asset.amount}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.text,
            render: (asset: Elements) => (
                <FormattedAmount
                    variant="body500"
                    amount={asset.accumulatedDepreciation}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.text,
            render: (asset: Elements) => (
                <FormattedAmount
                    variant="body500"
                    amount={asset.carryingValue}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.text,
            render: (asset: Elements) => (
                <Typography variant="body500">
                    {asset.active
                        ? fs("status.active")
                        : fs("status.notActive")}
                </Typography>
            )
        },
        {
            kind: ColumnType.cta,
            render: (asset: Elements) => (
                <Box>
                    <IconButton
                        onClick={() => {
                            setSelectedAsset(asset);
                            setOpen(!open);
                        }}
                    >
                        <FontAwesomeIcon
                            color=""
                            icon={faSquareArrowUpRight}
                            className="v-AssetsListTable-icon"
                        />
                    </IconButton>
                </Box>
            )
        }
    ];

    return (
        <Box marginTop="32px">
            <Table headCells={headCells} columns={columns} data={assetsList} />
            <AssetListDrawer
                open={open}
                setOpen={setOpen}
                assetDetail={assetDetail}
                isLoading={loading}
                selectedAsset={selectedAsset}
            />
            {isLoadingMore && (
                <Spinner loading={isLoadingMore}>
                    <Box width="100%" height={10}></Box>
                </Spinner>
            )}
            <Waypoint
                onEnter={() => {
                    hasMore && !isLoadingMore && !isLoading && fetchMore();
                }}
            />
        </Box>
    );
}
