import { useCurrentWorkspace } from "@drift/oneplatfront";
import { CslFormLookup } from "@onefront/workspace-forms";
import { CslSearchDto } from "@onefront/workspace-forms/typings/dtos/csl/CslSearchDto";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider
} from "@vapor/react-material";
import { useState } from "react";

import CustomButton from "../../CustomButton";
import ErrorBanner from "../../ErrorBanner";

interface CustomerSupplierProps {
    open: boolean;
    relationType: "CUSTOMER" | "SUPPLIER";
    onClose: () => void;
    onSelect: (id: string) => void;
    loading: boolean;
    error?: Error | null;
}

const CustomerSupplier = ({
    open,
    relationType,
    onSelect,
    onClose,
    loading,
    error
}: CustomerSupplierProps) => {
    const { id: workspaceId } = useCurrentWorkspace(true)?.workspace ?? {
        id: ""
    };
    const [hasCreated, setHasCreated] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<string>("");

    const handleCSLSelection = (data: CslSearchDto) => {
        const cslId = data?.list?.find(
            (listItem: any) => listItem.relationType === relationType
        )?.id;
        if (hasCreated) {
            cslId && onSelect(cslId);
        }
        cslId && setSelectedId(cslId);
    };

    const handleSave = () => {
        onSelect(selectedId);
    };

    return (
        <>
            <Dialog
                open={open}
                PaperProps={{
                    sx: {
                        width: 600,
                        height: 484
                    }
                }}
            >
                <DialogTitle>Cliente / fornitore</DialogTitle>
                <Divider />
                <DialogContent>
                    <Box mt={10}>
                        <CslFormLookup
                            onSelect={handleCSLSelection}
                            relationType={relationType}
                            workspaceId={workspaceId}
                            onSubmit={() => setHasCreated(true)}
                            paperWidth="552px"
                        />
                    </Box>
                    {error && <ErrorBanner error={error} />}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>
                        Annulla
                    </Button>
                    <CustomButton
                        variant="contained"
                        onClick={handleSave}
                        loading={loading}
                    >
                        Salva
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CustomerSupplier;
