import { useRequest } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AccountingLine } from "../dtos/AccountingLinesDto";

export interface SplitAccountingLineOptions {
    draftId: string;
    originalAccountingLine: AccountingLine;
    resultingAccountingLines: Partial<AccountingLine>[];
}

interface UseSplitAccountingLine {
    loading: boolean;
    split: (params: SplitAccountingLineOptions) => Promise<void>;
}

export const useSplitAccountingLine = (): UseSplitAccountingLine => {
    const { enqueueSnackbar } = useSnackbar();

    const customerData = useCustomerData();

    const fs = useFormattedStringWithScope("notifications.SplitAccountingLine");

    const { fetch, loading } = useRequest({
        ...useAxiosConfigRequestParams("CreateAccountingLine"),
        method: "post",
        onError: () => {
            enqueueSnackbar(fs("error"), {
                severity: "error"
            });
        },
        lazy: true
    });

    const split = async ({
        draftId,
        originalAccountingLine,
        resultingAccountingLines
    }: SplitAccountingLineOptions) => {
        return fetch({
            url: `${API_URL}/v2/precog/drafts/${draftId}/accountingLines/split`,
            params: {
                ...customerData
            },
            data: {
                updateBody: {
                    ...omitNilProperties(originalAccountingLine)
                },
                updateIdList: [originalAccountingLine.uuid],
                createBodyList: resultingAccountingLines.map(omitNilProperties)
            }
        });
    };

    return {
        split,
        loading
    };
};
