import Typography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack";
import Tooltip from "@vapor/react-material/Tooltip";

export interface CountsBarElement {
    count: number;
    label?: string;
    color: string;
}

interface CountsBarProps {
    barHeight: string;
    counts: CountsBarElement[];
    total: number;
    containerWidthReference?: number;
    includeCountsInTooltip?: boolean;
}

export default function CountsBar({
    barHeight,
    counts,
    total,
    containerWidthReference,
    includeCountsInTooltip
}: CountsBarProps) {
    const totalCounts = counts
        .map((count) => count.count)
        .reduce((a, b) => a + b, 0);

    const reference = containerWidthReference ?? total;

    const percentageContainerWidth = (total / reference) * 100;

    const countsWithTotal =
        totalCounts !== total
            ? counts.concat([{ color: "#00AA00", count: total - totalCounts }])
            : counts;

    return (
        <Stack
            direction="row"
            width={`${percentageContainerWidth}%`}
            alignItems="center"
        >
            {countsWithTotal.map((count, index) => {
                const percentageWidth = (count.count / totalCounts) * 100;
                const bar = (
                    // we have to use div otherwise the tooltip does not work
                    <div
                        key={index}
                        style={{
                            height: barHeight,
                            width: `${percentageWidth}%`,
                            backgroundColor: count.color,
                            minWidth: "16px"
                        }}
                    ></div>
                );

                const tooltipTitle = includeCountsInTooltip ? (
                    count.label ? (
                        <>
                            <Typography variant="bodySmall" color="white">
                                {count.label}{" "}
                                <Typography
                                    variant="bodySmall"
                                    fontWeight="bold"
                                    color="white"
                                >
                                    {count.count}
                                </Typography>
                            </Typography>
                        </>
                    ) : (
                        count.label
                    )
                ) : null;

                return (
                    <Tooltip arrow={true} placement="top" title={tooltipTitle}>
                        {bar}
                    </Tooltip>
                );
            })}
        </Stack>
    );
}
