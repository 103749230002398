import { isNil } from "lodash";
import { BusinessFlatDto } from "../ui/views/Dashboard/ToBeAccountedTable/DraftRow/Cells/SectionalCell";

const BUSINESS_SECTIONAL_DIVIDER = "/";

export function stringifyBusinessSectional(
    businessSectional: BusinessFlatDto
): string {
    return ""
        .concat(businessSectional.businessId)
        .concat(BUSINESS_SECTIONAL_DIVIDER)
        .concat(businessSectional.sectionalId);
}

export function stringifyBusinessSectionalAsStrings(
    businessId?: string,
    sectionalId?: string
): string {
    if (isNil(businessId) || isNil(sectionalId)) return "";

    return ""
        .concat(businessId)
        .concat(BUSINESS_SECTIONAL_DIVIDER)
        .concat(sectionalId);
}

export function parseBusinessSectional(businessSectional: string | undefined) {
    if (isNil(businessSectional))
        return {
            businessId: "",
            sectionalId: ""
        };
    const values = businessSectional.split("/");
    return {
        businessId: values[0],
        sectionalId: values[1]
    };
}
