import { useCurrentWorkspace } from "@drift/oneplatfront";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";

import getFormattedMessageWithScope from "../../../utils/getFormattedMessageWithScope";
import NewAccountingPeriod from "./NewAccountingPeriod";
import noAccountingPeriodsLeft from "./noAccountingPeriodsLeft.png";
import noAccountingPeriodsRight from "./noAccountingPeriodsRight.png";

interface NoAccountingPeriodsProps {
    onCreateAccountingPeriod: (startDate: Date, endDate: Date) => void;
    isLoadingCreateAccountingPeriod: boolean;
}

const fm = getFormattedMessageWithScope(
    "views.AccountingPeriods.NewAccountingPeriod"
);

export default function NoAccountingPeriods({
    onCreateAccountingPeriod,
    isLoadingCreateAccountingPeriod
}: NoAccountingPeriodsProps) {
    const { workspace: customer } = useCurrentWorkspace(true);
    return (
        <Box>
            <Box position="absolute" top="0" left="0">
                <img src={noAccountingPeriodsLeft} alt="" />
            </Box>
            <Box marginTop="88px" marginLeft="400px">
                <Typography variant="titleMedium">
                    {fm({
                        id: "noAccountingPeriods",
                        values: { companyName: customer?.description }
                    })}
                </Typography>
                <br />
                <Typography variant="titleMedium">{fm("openNow")}</Typography>
                <br />
                <NewAccountingPeriod
                    onCreateAccountingPeriod={onCreateAccountingPeriod}
                    isLoadingCreateAccountingPeriod={
                        isLoadingCreateAccountingPeriod
                    }
                />
            </Box>
            <Box position="absolute" top="0" right="0">
                <img src={noAccountingPeriodsRight} alt="" />
            </Box>
        </Box>
    );
}
