import { useCurrentWorkspace, useCurrentTenant } from "@drift/oneplatfront";
import { useTokenData } from "@onefront/react-sdk";
import { Dictionary } from "lodash";
import { v4 } from "uuid";
import { APP_VERSION, BASE_APP_NAME } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";

interface UseAxiosConfigRequestParams {
    headers: Dictionary<string>;
}

export default function useAxiosConfigRequestParams(
    appNameScope: string
): UseAxiosConfigRequestParams {
    const tokenData = useTokenData();
    const { tenant } = useCurrentTenant(true);
    const { workspace } = useCurrentWorkspace(true);

    return {
        headers: omitNilProperties({
            "x-App-Name": `${BASE_APP_NAME}.${appNameScope}`,
            "x-App-Version": APP_VERSION,
            "x-Request-ID": v4(),
            "x-Correlation-ID": v4(),
            "X-User-ID": tokenData?.sub ?? "unknown",
            "X-Workspace-Id": workspace?.id ?? "unknown",
            "X-Item-ID": workspace?.itemId ?? "unknown",
            "X-Manager-ID": tenant?.itemId ?? "unknown"
        })
    };
}
