import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import { AccountType } from "../../../utils/appEnums";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AccountsResponse } from "../dtos/AccountsDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseGetAccounts {
    get: (options?: UseGetAccountsOptions) => Promise<AccountsResponse>;
}

interface UseGetAccountsOptions {
    search?: string;
    includeSystem?: boolean;
    onlyType?: AccountType;
}

export const useGetAccounts = (): UseGetAccounts => {
    const customerData = useCustomerData();

    const { fetch } = useGet(`${API_URL}/v1/accounting/accounts`, {
        ...useAxiosConfigRequestParams("GetAccounts"),
        lazy: true
    });

    const get = (
        options: UseGetAccountsOptions = {
            includeSystem: false,
            search: ""
        }
    ) =>
        fetch({
            params: omitNilProperties({
                ...customerData,
                ...options
            })
        }).then((response: AxiosResponse<AccountsResponse>) => response.data);

    return {
        get
    };
};
