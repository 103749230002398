import { createContext, ReactNode, useContext } from "react";

import { CA21DetailComponent } from "./CA21DetailComponent";

interface CA21DetailProvider {
    open: (draftId: string) => void;
}

export interface CA21DetailComponentProps {
    children: ReactNode;
}

export const CA21DetailContext = createContext<CA21DetailProvider>({
    open: () => {}
});

export const useCA21Detail = (): CA21DetailProvider => {
    return useContext(CA21DetailContext);
};

export const CA21DetailService = () => ({
    target: "$REACT_ROOT_WRAPPER",
    handler: {
        component: ({ children }: any) => (
            <CA21DetailComponent>{children}</CA21DetailComponent>
        )
    }
});

export default CA21DetailComponent;
