import { Spinner } from "@comic/precog-components";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import usePrevious from "../../../../../../core/commons/hooks/usePrevious";
import { BusinessFlatDto } from "../../../../../../core/usecases/dtos/BusinessFlatDto";
import { useGetVatEntries } from "../../../../../../core/usecases/useGetVatEntries";
import { JournalType } from "../../../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../../../utils/getFormattedStringWithScope";
import NotFoundBanner from "../../../../../components/NotFoundBanner";
import FilterReportJournalType from "./Filter/reportType";
import FilterReportSectionalType from "./Filter/sectionalType";
import "./style.css";
import VATReportDetailTable from "./Table";
import { BusinessDto } from "../../../../../../core/usecases/dtos/BusinessDto";

const fs = getFormattedStringWithScope("views.VATSettlement.vatEntries");

interface VATReportDetailProps {
    businessId: string;
    workspaceBusinesses: BusinessFlatDto[] | null;
    businesses: BusinessDto[] | null;
    period: string;
    year: number;
}

export default function VatReportDetail({
    businessId,
    workspaceBusinesses,
    businesses,
    period,
    year
}: VATReportDetailProps) {
    const location = useLocation();

    const [journalType, setJournalType] = useState<JournalType>(
        JournalType.ALL
    );
    const previousJournalType = usePrevious(journalType);

    const [sectionalFilter, setSectionalFilter] = useState<{
        id: string;
        description: string;
    }>({ id: "", description: "" });
    const previousSectionalFilter = usePrevious(sectionalFilter);

    const previousPeriod = usePrevious(period);

    const {
        fetch: fetchVatEntries,
        loading,
        error,
        vatEntries,
        hasFetched
    } = useGetVatEntries({
        businessId: businessId !== "Tutti" ? businessId : undefined,
        year: year,
        period: period,
        journalType: journalType,
        sectional: sectionalFilter.description || null,
        lazy: true
    });

    useEffect(() => {
        if (
            journalType !== previousJournalType ||
            sectionalFilter !== previousSectionalFilter ||
            previousPeriod !== period
        ) {
            fetchVatEntries();
        }
    }, [
        location,
        journalType,
        sectionalFilter,
        fetchVatEntries,
        previousJournalType,
        previousSectionalFilter,
        period,
        previousPeriod
    ]);

    const renderBusinessSelected = useCallback(() => {
        if (businessId === "Tutti") {
            const arrayBusinessesValue: any = [];
            businesses?.forEach((business: BusinessDto) => {
                arrayBusinessesValue.push(business.description);
            });
            return arrayBusinessesValue.join(", ");
        } else {
            const currentBusiness = businesses?.find(
                (business: BusinessDto) => business.id === businessId
            );
            return currentBusiness ? currentBusiness.description : "";
        }
    }, [businesses, businessId]);

    return (
        <Spinner loading={loading}>
            {hasFetched && (
                <FilterReportJournalType
                    journalType={journalType}
                    onSelectJournalType={(value: string) =>
                        setJournalType(value as JournalType)
                    }
                />
            )}
            <Box marginTop="24px">
                <Typography variant="titleSmall" fontFamily="Cairo">
                    {fs("summary")} {renderBusinessSelected()}
                </Typography>
            </Box>
            {hasFetched && (
                <FilterReportSectionalType
                    businesses={workspaceBusinesses}
                    sectionalFilter={sectionalFilter.id}
                    onSelectSectional={(value: {
                        id: string;
                        description: string;
                    }) => setSectionalFilter(value)}
                />
            )}
            {hasFetched && isEmpty(vatEntries) && (
                <NotFoundBanner text={fs("table.empty")} />
            )}
            {hasFetched && error && <NotFoundBanner text={fs("table.error")} />}
            {vatEntries && !isEmpty(vatEntries) && (
                <VATReportDetailTable data={vatEntries} />
            )}
        </Spinner>
    );
}
