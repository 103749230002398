import { SxProps } from "@mui/system";
import { FormControl, InputLabel } from "@vapor/react-material";
import { isNil } from "lodash";
import { Controller, useFormContext } from "react-hook-form";

import { useEffect } from "react";
import { useVatRegimes } from "../../../core/domain/VatRegimes/queries";
import SelectVatRegime from "../SelectVatRegime";
import { FormField } from "./types";

interface FormSelectVatRegimeProps extends FormField {
    baseName?: string;
    disablePortal?: boolean;
    extraFieldValue?: string | null;
    sx?: SxProps;
}

export default function FormSelectVatRegime({
    name,
    baseName,
    label,
    required,
    disabled,
    disablePortal = true,
    extraFieldValue,
    sx
}: FormSelectVatRegimeProps) {
    const { control, register, unregister, setValue } = useFormContext();

    const { data: vatRegimes } = useVatRegimes();

    const regimeExtraFieldName = baseName + ".regimeExtraField";

    useEffect(() => {
        register(regimeExtraFieldName, { value: extraFieldValue });

        return () => unregister(regimeExtraFieldName);
    }, [register, unregister, extraFieldValue, regimeExtraFieldName]);

    return (
        <Controller
            control={control}
            shouldUnregister={true}
            name={name}
            rules={{ required: required }}
            render={({ field, fieldState }) => (
                <FormControl
                    fullWidth
                    error={!isNil(fieldState.error)}
                    disabled={disabled}
                >
                    <InputLabel required={required} disabled={disabled}>
                        {label}
                    </InputLabel>
                    <SelectVatRegime
                        vatRegimes={vatRegimes ?? []}
                        extraFieldValue={extraFieldValue}
                        onChangeExtraField={(extraField) =>
                            setValue(regimeExtraFieldName, extraField)
                        }
                        disablePortal={disablePortal}
                        {...field}
                        disabled={disabled}
                        sx={sx}
                    />
                </FormControl>
            )}
        />
    );
}
