import { VaporIcon } from "@vapor/react-icons";
import { IconButton } from "@vapor/react-material";
import { useState } from "react";
import AccountDetailDrawer from "./AccountDetailDrawer";

interface AccountDetailButtonProps {
    draftId: string;
    accountCode: string;
    readOnly?: boolean;
}

export default function AccountDetailButton({
    draftId,
    accountCode,
    readOnly = false
}: AccountDetailButtonProps) {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <IconButton size="small" onClick={() => setOpen(true)}>
                <VaporIcon
                    color="primary"
                    iconName="square-arrow-up-right"
                    iconWeight="regular"
                />
            </IconButton>
            <AccountDetailDrawer
                draftId={draftId}
                isOpen={open}
                onClose={() => setOpen(false)}
                accountCode={accountCode}
                readOnly={readOnly}
            />
        </>
    );
}
