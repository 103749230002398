import { Switch } from "@vapor/react-material/Switch";
import Tooltip from "@vapor/react-material/Tooltip";
import { useEffect, useState } from "react";

import { useDraftAsset } from "../../../core/contexts/DraftAsset";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";

interface SwitchAdditionalChargeProps {
    assetId: string;
    draftId: string | null;
    hasAdditionalCharge: boolean;
    disabled?: boolean;
    wouldBrakeAdditionalChargeRules: boolean;
}

const fs = getFormattedStringWithScope("views.Detail.assets");

const SwitchAdditionalCharge = ({
    assetId,
    hasAdditionalCharge,
    disabled,
    wouldBrakeAdditionalChargeRules
}: SwitchAdditionalChargeProps) => {
    const [shouldUpdateAssets, setShouldUpdateAssets] =
        useState<boolean>(false);

    const { update, get: getDraftAsset, hasUpdated } = useDraftAsset();

    useEffect(() => {
        if (hasUpdated && shouldUpdateAssets) {
            getDraftAsset();
            setShouldUpdateAssets(false);
        }
    }, [hasUpdated, getDraftAsset, shouldUpdateAssets]);

    const SwitchElement = () => {
        return (
            <Switch
                name={`Switch_${assetId}`}
                key={`Switch_${assetId}`}
                disabled={disabled}
                checked={hasAdditionalCharge}
                onClick={() => {
                    update(
                        {
                            hasAdditionalCharge: !hasAdditionalCharge
                        },
                        assetId
                    );
                    setShouldUpdateAssets(true);
                }}
            />
        );
    };

    return wouldBrakeAdditionalChargeRules ? (
        <Tooltip
            arrow={true}
            placement="top"
            title={fs("tooltipHasAdditionalCharge")}
        >
            <div style={{ display: "inline-block" }}>
                <SwitchElement />
            </div>
        </Tooltip>
    ) : (
        <SwitchElement />
    );
};

export default SwitchAdditionalCharge;
