import onefront from "@drift/oneplatfront";
import compact from "lodash/compact";

import { APP_VERSION, ONEBOX_APP_ID, REACT_APP_REGISTRY_ID } from "./config";
import { AuthService } from "./services/AuthService";
import { ComponentsLibraryWrapperService } from "./services/ComponentsLibraryWrapperService";
import { DetailFacade } from "./services/DetailFacade";
import { IntlService } from "./services/IntlService";
import { ReactQuery } from "./services/ReactQuery";
import { SnackbarService } from "./services/SnackbarService";
import { WorkspaceConfigService } from "./services/WorkspaceConfigService";
import { Root } from "./ui/views/Root";

onefront
    .run({
        trace: "compact",
        settings: {
            one: {
                auth: {
                    token: { verify: true, refresh: true }
                },
                axios: {
                    proxy: {
                        baseUrl: process.env.REACT_APP_PROXY_BASE_URL
                    }
                },
                login: {
                    target: {
                        params: [
                            "mode=redirect",
                            `appId=${REACT_APP_REGISTRY_ID}`
                        ]
                    }
                },
                gainsight: {
                    enabled: true
                },
                box: {
                    module: {
                        name: REACT_APP_REGISTRY_ID,
                        version: APP_VERSION
                    },
                    app: {
                        id: ONEBOX_APP_ID
                    }
                }
            }
        },
        features: [Root],
        services: compact([
            ReactQuery,
            AuthService,
            IntlService,
            ComponentsLibraryWrapperService,
            SnackbarService,
            WorkspaceConfigService,
            DetailFacade
        ])
    })
    .catch(console.error);
