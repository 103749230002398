import { useIntl } from "react-intl";

interface IdValue {
    id: string | number;
    values: any;
}

const addScope = (scope: string, id: string | number): string =>
    `${scope}.${id}`;

const getFormattedStringWithScope =
    (scope: string) => (idOrProps: string | number | IdValue) => {
        const intl = useIntl();
        const tags = {
            b: (msg: string) => <b>{msg}</b>,
            i: (msg: string) => <i>{msg}</i>
        };
        return intl.formatMessage({
            ...(typeof idOrProps === "string" || typeof idOrProps === "number"
                ? {
                      id: addScope(scope, idOrProps),
                      values: {
                          ...tags
                      }
                  }
                : {
                      ...idOrProps,
                      id: addScope(scope, idOrProps.id),
                      values: {
                          ...idOrProps.values,
                          ...tags
                      }
                  })
        });
    };

export default getFormattedStringWithScope;
