import { useGet } from "@onefront/react-sdk";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { GroupKindSubkind } from "../dtos/GroupKindSubkind";

interface UseGetGroupKindSubkind {
    loading: boolean;
    error: any;
    groupKindSubkind: GroupKindSubkind | null;
    fetch: Function;
    hasFetched: boolean;
}

interface UseGetGroupKindSubkindOptions {
    group?: string | undefined;
    kind?: string | null;
    subkind?: string | null;
    lazy?: boolean;
}

export const useGetGroupKindSubkind = ({
    group,
    kind,
    subkind,
    lazy
}: UseGetGroupKindSubkindOptions): UseGetGroupKindSubkind => {
    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/asset/group/${group}/kind/${kind}/subKind/${subkind}`,
        {
            ...useAxiosConfigRequestParams("GroupKindSubkind"),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: lazy
        }
    );

    const safeFetch = useCallback(() => fetch(), [fetch]);

    return {
        fetch: safeFetch,
        loading: loading,
        error: error,
        groupKindSubkind: data,
        hasFetched: hasLoaded
    };
};
