import Box from "@vapor/react-material/Box";
import { Spinner } from "@comic/precog-components";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import Typography from "@vapor/react-extended/ExtendedTypography";
import debounce from "lodash/debounce";
import { isValid } from "date-fns";
import Stack from "@vapor/react-material/Stack";
import { OneBoxPage, useCurrentWorkspace } from "@drift/oneplatfront";

import AssetsListTable from "./AssetsListTable";
import { useGetListAssets } from "../../../core/usecases/useGetListAssets";
import NotFoundBanner from "../../components/NotFoundBanner";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../components/FormattedAmount";
import usePrevious from "../../../core/commons/hooks/usePrevious";
import BackArrow from "../../components/BackArrow";

const fs = getFormattedStringWithScope("views.ListAssets");

export default function AssetsList() {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const [description, setDescription] = useState<string | null>(null);
    const previousDescription = usePrevious(description);

    const [reference, setReference] = useState<string | null>(null);
    const previousReference = usePrevious(reference);

    const [purchaseDateFrom, setPurchaseDateFrom] = useState<Date | null>(null);
    const previousPurchaseDateFrom = usePrevious(purchaseDateFrom);

    const [purchaseDateTo, setPurchaseDateTo] = useState<Date | null>(null);
    const previousPurchaseDateTo = usePrevious(purchaseDateTo);

    const [saleDateFrom, setSaleDateFrom] = useState<Date | null>(null);
    const previousSaleDateFrom = usePrevious(saleDateFrom);

    const [saleDateTo, setSaleDateTo] = useState<Date | null>(null);
    const previousSaleDateTo = usePrevious(saleDateTo);

    const [active, setActive] = useState<boolean | null>(null);
    const previousActive = usePrevious(active);

    const {
        fetch: fetchAssetsList,
        fetchMore,
        loadingMore,
        hasMore,
        assets,
        loading: loadingAssetsList,
        error: errorAssetsList,
        hasFetched: hasFetchedAssetsList
    } = useGetListAssets({
        lazy: true,
        description: description === "" ? null : description,
        reference: reference === "" ? null : reference,
        purchaseDateFrom: purchaseDateFrom,
        purchaseDateTo: purchaseDateTo,
        saleDateFrom: saleDateFrom,
        saleDateTo: saleDateTo,
        active: active,
        page: 0,
        size: 16
    });

    const { workspace: customer } = useCurrentWorkspace(true);
    const previousCustomer = usePrevious(customer);

    useEffect(() => {
        if (previousCustomer !== customer) {
            setHasInitialized(false);
        }
    }, [previousCustomer, customer]);

    useEffect(() => {
        if (customer && !hasInitialized) {
            fetchAssetsList();
            setHasInitialized(true);
        }
    }, [hasInitialized, fetchAssetsList, customer]);

    useEffect(() => {
        if (
            hasInitialized &&
            (description !== previousDescription ||
                previousReference !== reference ||
                purchaseDateFrom !== previousPurchaseDateFrom ||
                purchaseDateTo !== previousPurchaseDateTo ||
                saleDateFrom !== previousSaleDateFrom ||
                saleDateTo !== previousSaleDateTo ||
                active !== previousActive)
        ) {
            fetchAssetsList();
        }
    }, [
        fetchAssetsList,
        hasInitialized,
        description,
        previousDescription,
        reference,
        previousReference,
        purchaseDateFrom,
        previousPurchaseDateFrom,
        purchaseDateTo,
        previousPurchaseDateTo,
        saleDateFrom,
        previousSaleDateFrom,
        saleDateTo,
        previousSaleDateTo,
        active,
        previousActive
    ]);

    const handleChangePurchaseDateFrom = debounce((newFromDate: Date) => {
        if (isValid(newFromDate)) {
            setPurchaseDateFrom(newFromDate);
        }
    }, 300);

    const handleChangePurchaseDateTo = debounce((newToDate: Date) => {
        if (isValid(newToDate)) {
            setPurchaseDateTo(newToDate);
        }
    }, 300);

    const handleChangeSaleDateFrom = debounce((newFromDate: Date) => {
        if (isValid(newFromDate)) {
            setSaleDateFrom(newFromDate);
        }
    }, 300);

    const handleChangeSaleDateTo = debounce((newToDate: Date) => {
        if (isValid(newToDate)) {
            setSaleDateTo(newToDate);
        }
    }, 300);

    const handleChangeDescription = (newDescription: string) => {
        setDescription(newDescription);
    };

    const handleChangeReference = (newReference: string) => {
        setReference(newReference);
    };

    const handleActive = (newActiveStatus: boolean | null) => {
        setActive(newActiveStatus);
    };

    return (
        <OneBoxPage headerLeft={<BackArrow />} title={fs("title")}>
            <Spinner loading={loadingAssetsList}>
                <Box display="flex" gap={6} marginLeft={1.8} marginRight={1.8}>
                    <Stack>
                        <Typography
                            variant="subtitle"
                            color="primary.textTitleColor"
                        >
                            {fs("totalAmount")}
                        </Typography>
                        <FormattedAmount
                            variant="displayLarge"
                            amount={
                                assets?._embedded.totalAmount
                                    ? assets?._embedded.totalAmount
                                    : 0
                            }
                            currency="EUR"
                        />
                    </Stack>
                    <Stack>
                        <Typography
                            variant="subtitle"
                            color="primary.textTitleColor"
                        >
                            {fs("totalDepreciatedAmount")}
                        </Typography>
                        <FormattedAmount
                            variant="displayLarge"
                            amount={
                                assets?._embedded.totalDepreciatedAmount
                                    ? assets?._embedded.totalDepreciatedAmount
                                    : 0
                            }
                            currency="EUR"
                        />
                    </Stack>
                    <Stack>
                        <Typography
                            variant="subtitle"
                            color="primary.textTitleColor"
                        >
                            {fs("totalResidualAmount")}
                        </Typography>
                        <FormattedAmount
                            variant="displayLarge"
                            amount={
                                assets?._embedded.totalResidualAmount
                                    ? assets?._embedded.totalResidualAmount
                                    : 0
                            }
                            currency="EUR"
                        />
                    </Stack>
                </Box>
                <Box marginTop={3} marginLeft={1.8} marginRight={1.8}>
                    {hasFetchedAssetsList ? (
                        assets?._embedded?.elements ? (
                            <>
                                <AssetsListTable
                                    isLoading={loadingAssetsList}
                                    fetchMore={fetchMore}
                                    isLoadingMore={loadingMore}
                                    hasMore={hasMore}
                                    onDescriptionChange={
                                        handleChangeDescription
                                    }
                                    onReferenceChange={handleChangeReference}
                                    purchaseDateFrom={purchaseDateFrom}
                                    onPurchaseDateFromChange={
                                        handleChangePurchaseDateFrom
                                    }
                                    purchaseDateTo={purchaseDateTo}
                                    onPurchaseDateToChange={
                                        handleChangePurchaseDateTo
                                    }
                                    saleDateFrom={saleDateFrom}
                                    onSaleDateFromChange={
                                        handleChangeSaleDateFrom
                                    }
                                    saleDateTo={saleDateTo}
                                    onSaleDateToChange={handleChangeSaleDateTo}
                                    active={active}
                                    handleActive={handleActive}
                                    assetsList={assets._embedded.elements}
                                />
                                {isEmpty(assets?._embedded?.elements) ? (
                                    <NotFoundBanner text={fs("notFound")} />
                                ) : null}
                            </>
                        ) : errorAssetsList ? (
                            <NotFoundBanner text={fs("errorLoadingTable")} />
                        ) : null
                    ) : null}
                </Box>
            </Spinner>
        </OneBoxPage>
    );
}
