import Box from "@vapor/react-material/Box";

import UsedGoodsTable from "./UsedGoodsTable";
import { useDraftUsedGood } from "../../../core/contexts/DraftUsedGood";

interface UsedGoodsProps {
    refCurrency: string;
}

export default function UsedGoods({ refCurrency }: UsedGoodsProps) {
    const { draftUsedGoods } = useDraftUsedGood();

    const totalAmount = draftUsedGoods?.reduce(
        (acc, item) => acc + item.amount,
        0
    );

    return (
        <Box marginTop={2.5}>
            <UsedGoodsTable
                totalAmount={totalAmount}
                draftUsedGoods={draftUsedGoods}
                refCurrency={refCurrency}
            />
        </Box>
    );
}
