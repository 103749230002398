import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Divider from "@vapor/react-material/Divider";
import InputLabel from "@vapor/react-material/InputLabel";
import Stack from "@vapor/react-material/Stack";
import TextField from "@vapor/react-material/TextField";

import { AssetInfoResponse } from "../../../core/usecases/dtos/AssetInfoDto";
import getFormattedDate from "../../../utils/getFormattedDate";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../components/FormattedAmount";
import FormattedDate from "../../components/FormattedDate";

interface DrawerHeaderProps {
    asset: AssetInfoResponse;
    onCancelAssociation: () => void;
    refCurrency: string;
}

const fs = getFormattedStringWithScope(
    "views.Detail.assets.drawer.incrementalSpending"
);

export default function DrawerHeader({
    asset,
    onCancelAssociation,
    refCurrency
}: DrawerHeaderProps) {
    return (
        <Box display="flex" justifyContent="space-between" width="65%">
            <Stack>
                <InputLabel>{fs("existingAsset")}</InputLabel>
                <Stack alignItems="end" direction="row">
                    <TextField value={asset?.assetDescription} />
                    <Button
                        size="medium"
                        sx={{
                            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium":
                                {
                                    borderRadius: 0,
                                    minWidth: 40,
                                    p: 1
                                }
                        }}
                        variant="contained"
                        onClick={onCancelAssociation}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </Stack>
                <Box display="flex" marginTop="15px">
                    <Typography
                        variant="caption"
                        color="primary.interactiveDefault"
                        fontWeight={600}
                    >
                        {fs("count")} {asset?.accountCode}{" "}
                    </Typography>
                    {asset?.activationDate && (
                        <Typography variant="caption" fontWeight={600}>
                            - {fs("activationDate")}{" "}
                            {getFormattedDate(asset?.activationDate)}
                        </Typography>
                    )}
                </Box>
            </Stack>
            <Divider
                sx={{ margin: "0 25px", height: "130px" }}
                flexItem
                orientation="vertical"
                variant="middle"
            />
            <Stack>
                <Stack marginBottom="30px">
                    <FormattedAmount
                        variant="titleMedium"
                        color="primary.main"
                        amount={asset?.assetAmount ?? 0}
                        currency={refCurrency ?? "EUR"}
                    />
                    <Typography variant="caption">
                        {fs("existingAssetValue")}
                    </Typography>
                </Stack>
                <Stack>
                    <FormattedAmount
                        variant="displayMedium"
                        color="primary.main"
                        amount={asset?.residualAmount ?? 0}
                        currency={refCurrency ?? ""}
                    />
                    <Stack direction="row">
                        <Typography variant="caption" marginRight={0.4}>
                            {fs("residualAt")}
                        </Typography>
                        <FormattedDate
                            variant="caption"
                            date={asset?.operationDate}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}
