import { useSnackbar } from "@onefront/react-sdk";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useState } from "react";

import { API_URL } from "../../../config";
import { RegistrationType } from "../../../utils/appEnums";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useCustomerData from "../../commons/hooks/useCustomerData";
import usePagedGet from "../../commons/hooks/usePagedGet";
import { DashboardResponse } from "../dtos/DashboardDto";
import { useGetDraft } from "../useGetDraft";
import { concatenateData } from "./processData";

interface UseGetDashboardPaginated {
    loading: boolean;
    loadingMore: boolean;
    hasMore: boolean;
    error: any;
    dashboard: DashboardResponse | null;
    fetch: Function;
    fetchDraft: (draftId: string) => Promise<void>;
    fetchMore: Function;
    hasFetched: boolean;
}

interface UseGetDashboardPaginatedOptions {
    categories: RegistrationType[];
    confirmed: boolean;
    fromDate?: Date | null;
    toDate?: Date | null;
    documentNumber?: string | null;
    year?: number | null;
    filters?: string[];
    lazy: boolean;
}

export interface UseGetDashboardPaginatedFilters {
    fromDocDate?: Date | null;
    toDocDate?: Date | null;
    fromDate?: Date | null;
    toDate?: Date | null;
    sections?: string[] | null;
}

export const useGetDashboardPaginated = ({
    categories,
    confirmed,
    fromDate,
    toDate,
    documentNumber,
    filters,
    year,
    lazy
}: UseGetDashboardPaginatedOptions): UseGetDashboardPaginated => {
    const { enqueueSnackbar } = useSnackbar();
    const fs = useFormattedStringWithScope(
        "notifications.GetDashboardPaginated"
    );

    const processCategories = categories.includes(
        RegistrationType.OTHER_TRANSACTION
    )
        ? categories.concat(RegistrationType.DOCUMENT)
        : categories;

    const [dashboard, setDashboard] = useState<DashboardResponse | null>(null);

    const concatenateDashboardElements = (
        data: DashboardResponse,
        responseData: DashboardResponse
    ) => {
        return concatenateData(data, responseData);
    };

    const {
        fetch: fetchDashboard,
        fetchMore,
        data,
        loading,
        loadingMore,
        error,
        hasMore,
        hasFetched
    } = usePagedGet<DashboardResponse>({
        baseUrl: `${API_URL}/v2/precog/dashboard-paginated`,
        scope: "DashboardPaginated",
        concatenateData: concatenateDashboardElements,
        onError: () => enqueueSnackbar(fs("error"), { variant: "error" }),
        params: omitNilProperties({
            category: isEmpty(processCategories)
                ? null
                : processCategories.join(","),
            confirmed: confirmed,
            dateFrom:
                fromDate && isValid(fromDate)
                    ? format(fromDate, "yyyy-MM-dd")
                    : undefined,
            dateTo:
                toDate && isValid(toDate)
                    ? format(toDate, "yyyy-MM-dd")
                    : undefined,
            docNumber: documentNumber,
            includes: filters && filters?.join(","),
            year: year,
            ...useCustomerData()
        }),
        lazy: lazy
    });

    const { get: getSingleDraft } = useGetDraft();

    const fetchDraft = useCallback(
        async (draftId: string) => {
            const draft = await getSingleDraft(draftId);
            setDashboard((prevDashboard) =>
                !prevDashboard
                    ? prevDashboard
                    : {
                          ...prevDashboard,
                          _embedded: {
                              ...prevDashboard._embedded,
                              drafts: prevDashboard._embedded.drafts.map(
                                  (prevDraft) =>
                                      prevDraft.id === draft.id
                                          ? draft
                                          : prevDraft
                              )
                          }
                      }
            );
        },
        [getSingleDraft]
    );

    useEffect(() => {
        setDashboard(data);
    }, [data]);

    return {
        fetch: fetchDashboard,
        fetchDraft,
        fetchMore: fetchMore,
        loading: loading,
        loadingMore: loadingMore,
        hasMore: hasMore,
        error: error,
        dashboard: error ? null : dashboard,
        hasFetched: hasFetched
    };
};
