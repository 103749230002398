import {
    ColumnElement,
    ColumnType,
    HeadCell,
    Table
} from "@comic/precog-components";
import { faExternalLinkSquareAlt } from "@fortawesome/pro-regular-svg-icons";
import { faObjectGroup } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Checkbox from "@vapor/react-material/Checkbox";
import { IconButton } from "@vapor/react-material/IconButton";
import { every, some } from "lodash";
import { FormattedNumber } from "react-intl";

import { Box } from "@vapor/react-material";
import { AssetLineDto } from "../../../core/usecases/dtos/AssetLinesDto";
import { getAssetsInGroup } from "../../../utils/assetUtils";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../components/FormattedAmount";
import SwitchAdditionalCharge from "./SwitchAdditionalCharge";
import SwitchIncrementalSpending from "./SwitchIncrementalSpending";

const fs = getFormattedStringWithScope("views.Detail.assets");

interface AssetTableProps {
    draftId: string | null;
    groupedAccountCodeGroup: AssetLineDto[];
    isActive: boolean;
    refCurrency: string | null;
    onAssetDetailClick: (
        parentId?: string | null,
        uuid?: string | null
    ) => void;
    onToggleAsset: (asset: AssetLineDto) => void;
    selectedAssetIds: string[];
    isGroupingAsset: boolean;
    newParentGroupId: string;
    readOnly?: boolean;
}

const AssetTable = ({
    draftId,
    groupedAccountCodeGroup,
    onAssetDetailClick,
    isActive,
    refCurrency,
    onToggleAsset,
    selectedAssetIds,
    isGroupingAsset,
    newParentGroupId,
    readOnly
}: AssetTableProps) => {
    // TODO snackbar

    const headCells: HeadCell[] = [
        {
            id: "",
            kind: ColumnType.cta,
            label: ""
        },
        {
            id: "asset",
            kind: ColumnType.text,
            label: fs("table.asset")
        },
        {
            id: "accessoryCharges",
            kind: ColumnType.text,
            label: fs("table.accessoryCharges")
        },
        {
            id: "assetName",
            kind: ColumnType.text,
            label: fs("table.assetName")
        },
        {
            id: "subspecies",
            kind: ColumnType.text,
            label: fs("table.subspecies")
        },
        {
            id: "taxDepreciationCriterion",
            kind: ColumnType.text,
            label: fs("table.taxDepreciationCriterion")
        },
        {
            id: "total",
            kind: ColumnType.text,
            label: fs("table.total")
        },

        {
            id: "incrementalSpending",
            kind: isActive ? ColumnType.empty : ColumnType.text,
            label: !isActive ? fs("table.incrementalSpending") : ""
        },
        {
            id: "",
            kind: ColumnType.cta,
            label: ""
        }
    ];

    const columns: ColumnElement[] = [
        {
            kind: ColumnType.cta,
            render: (assetLine: AssetLineDto) => {
                const isAssetInAGroup = !!assetLine.parentId;
                return (
                    <Checkbox
                        color="primary"
                        checked={
                            selectedAssetIds.includes(assetLine.uuid) ||
                            isAssetInAGroup
                        }
                        key={`Checkbox_${assetLine.uuid}`}
                        disabled={isAssetInAGroup}
                        onClick={() => {
                            onToggleAsset(assetLine);
                        }}
                        inputProps={{
                            "aria-labelledby": ""
                        }}
                        readOnly={readOnly}
                    />
                );
            }
        },
        // account
        {
            kind: ColumnType.text,
            render: (assetLine: AssetLineDto) => (
                <Typography>{assetLine.articleDesc}</Typography>
            )
        },
        // accessory charges
        {
            kind: ColumnType.text,
            render: (assetLine: AssetLineDto) => {
                const assetsInGroup = getAssetsInGroup(
                    assetLine,
                    groupedAccountCodeGroup,
                    selectedAssetIds
                );

                const isArticleTheOnlyOneWithoutAdditionalCharge =
                    every(
                        assetsInGroup.filter(
                            (asset) => assetLine.uuid !== asset.uuid
                        ),
                        (asset) => asset.hasAdditionalCharge
                    ) && !assetLine.hasAdditionalCharge;

                const groupIsIncrementalSpenting = some(
                    assetsInGroup.filter(
                        (asset) => assetLine.uuid !== asset.uuid
                    ),
                    (asset) => asset.increaseExistingAsset
                );

                return (
                    <SwitchAdditionalCharge
                        assetId={assetLine.uuid}
                        draftId={draftId}
                        hasAdditionalCharge={assetLine?.hasAdditionalCharge}
                        disabled={
                            isArticleTheOnlyOneWithoutAdditionalCharge ||
                            groupIsIncrementalSpenting ||
                            readOnly
                        }
                        wouldBrakeAdditionalChargeRules={
                            isArticleTheOnlyOneWithoutAdditionalCharge &&
                            assetsInGroup.length !== 0
                        }
                    />
                );
            }
        },
        // assetName
        {
            kind: ColumnType.text,
            render: (assetLine: AssetLineDto) => (
                <Typography>
                    {assetLine.parentId && (
                        <>
                            <FontAwesomeIcon
                                color="#008bcc"
                                icon={faObjectGroup}
                            />{" "}
                        </>
                    )}
                    {assetLine.assetGroupName}
                </Typography>
            )
        },
        // subspecies
        {
            kind: ColumnType.text,
            render: (assetLine: AssetLineDto) => (
                <Typography>{assetLine.subKindDesc}</Typography>
            )
        },
        // taxDepreciationCriterion
        {
            kind: ColumnType.text,
            render: (assetLine: AssetLineDto) => (
                <Typography>
                    {fs(`table.criteria.${assetLine.taxDepreciationRateType}`)}{" "}
                    <FormattedNumber
                        minimumFractionDigits={2}
                        value={assetLine.taxDepreciationRate ?? 0}
                    />
                    %
                </Typography>
            )
        },
        // total
        {
            kind: ColumnType.text,
            render: (assetLine: AssetLineDto) => (
                <Typography>
                    <FormattedAmount
                        amount={assetLine.amount}
                        currency={refCurrency ?? "EUR"}
                    />
                </Typography>
            )
        },
        //incrementalSpending
        {
            kind: ColumnType.text,
            render: (assetLine: AssetLineDto) => {
                const assetsInGroup = getAssetsInGroup(
                    assetLine,
                    groupedAccountCodeGroup,
                    selectedAssetIds
                );
                const groupIncreaseExisting = assetsInGroup.some(
                    (assetLine) => assetLine.increaseExistingAsset
                );

                const groupHasAdditionalCharge = some(
                    assetsInGroup,
                    (asset) => asset.hasAdditionalCharge
                );

                return !isActive ? (
                    <SwitchIncrementalSpending
                        assetId={assetLine.uuid}
                        parentId={assetLine?.parentId}
                        assetGroupId={newParentGroupId || assetLine.parentId}
                        isGroupingAsset={isGroupingAsset}
                        assetRegistryId={assetLine?.assetRegistryId}
                        groupIncrementalSpending={groupIncreaseExisting}
                        incrementalSpending={assetLine.increaseExistingAsset}
                        selectedAssetIds={selectedAssetIds}
                        disabled={groupHasAdditionalCharge || readOnly}
                    />
                ) : (
                    <div></div>
                );
            }
        },
        // cta
        {
            kind: ColumnType.cta,
            render: (assetLine: AssetLineDto) => (
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                        // I check if the parentId is present I have a grouping and the data are pertaining to the group otherwise to the individual asset
                        onAssetDetailClick(
                            assetLine?.parentId,
                            assetLine?.uuid
                        );
                    }}
                >
                    <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
                </IconButton>
            )
        }
    ];

    return (
        <Box sx={{ "th:not(:first-of-type)": { width: "240px" } }}>
            <Table
                headCells={headCells}
                columns={columns}
                data={groupedAccountCodeGroup || []}
            />
        </Box>
    );
};

export default AssetTable;
