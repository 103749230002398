export interface SimulationAssetSaleDto {
    data: Response;
}

export interface Response {
    depreciationDuringYear: number;
    simulation: Simulation;
    nonDeductibleGain: number;
    nonDeductibleLoss: number;
    statutoryCapitalGain: number;
    statutoryCapitalLoss: number;
    taxCapitalGain: number;
    taxCapitalLoss: number;
    splitCapitalGain: SplitCapitalGain;
}

export enum SplitCapitalGain {
    ENABLED = "ENABLED",
    DISABLED = "DISABLED"
}

export interface Simulation {
    accountingLines: AccountingLines[];
    businessId: string;
    id: string;
    postingDate: string;
    reasonId: string;
    refCurrency: string;
    workspaceId: string;
}

export interface AccountingLines {
    credit: Credit;
    debit: Debit;
    accountId: string;
    accountDesc: string;
}

export interface Credit {
    EUR: number;
}

export interface Debit {
    EUR: number;
}
