import { useGet, useSnackbar } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { VatSettlementEntry } from "../dtos/VatSettlementDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseGetVatBusinesses {
    loading: boolean;
    error: any;
    data: VatSettlementEntry[] | null;
    fetch: (
        businessId: string,
        year: number | string,
        ordinal: string
    ) => Promise<VatSettlementEntry[]>;
    hasFetched: boolean;
}

export const useGetVatBusinesses = (): UseGetVatBusinesses => {
    const { enqueueSnackbar } = useSnackbar();
    const customerData = useCustomerData();
    const fs = useFormattedStringWithScope("notifications.GetVatBusinesses");

    const { fetch, data, loading, error, hasLoaded } = useGet(`ignored`, {
        ...useAxiosConfigRequestParams("VatSettlement"),
        onError: () =>
            enqueueSnackbar(fs("error"), {
                variant: "error"
            }),
        lazy: true
    });

    const get = async (
        businessId: string,
        year: number | string,
        ordinal: string
    ) => {
        return fetch({
            url: `${API_URL}/v1/vat/businesses/${businessId}/settlements`,
            params: {
                ...customerData,
                year: year,
                ordinal: ordinal
            }
        }).then(
            (response: AxiosResponse<VatSettlementEntry[]>) => response.data
        );
    };

    return {
        fetch: get,
        loading: loading,
        error: error,
        data: data,
        hasFetched: hasLoaded
    };
};
