import { useRequest, useSnackbar } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface UseCancelRegister {
    loading: boolean;
    error: any;
    fetch: (year: string) => Promise<void>;
}

export const useCancelRegister = (): UseCancelRegister => {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.GetRegisters");

    const customerData = useCustomerData();

    const {
        fetch: fetchData,
        loading,
        error
    } = useRequest({
        method: "delete",
        url: `${ASYNC_API_URL}/vat/registers`,
        ...useAxiosConfigRequestParams("CancelRegister"),
        onError: () =>
            enqueueSnackbar(fs("error"), {
                variant: "error"
            }),
        lazy: true
    });

    const fetch = (year: string) => {
        return fetchData({
            data: {
                workspaceId: customerData.workspaceId,
                year: year
            }
        });
    };

    return {
        fetch,
        loading: loading,
        error: error
    };
};
