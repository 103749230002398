import { createContext, useCallback, useContext, useEffect } from "react";

import usePoll from "../../commons/hooks/usePoll";
import { AvailableRegistersDto } from "../../usecases/dtos/AvailableRegistersDto";
import { useCancelRegister } from "../../usecases/useCancelRegister";
import { useGenerateRegister } from "../../usecases/useGenerateRegister";
import { useGetAvailableRegisters } from "../../usecases/useGetAvailableRegisters";

interface RegistersContextType {
    registers: AvailableRegistersDto[];
    get: () => Promise<AvailableRegistersDto[]>;
    generate: () => Promise<void>;
    cancel: () => Promise<void>;
    loading: boolean;
    fetchingGeneration: boolean;
    fetchingCancelation: boolean;
    generating: boolean;
    canceling: boolean;
    error: any;
    year: string;
}

const RegistersContext = createContext<RegistersContextType>({
    cancel: async () => {},
    generate: async () => {},
    get: async () => [],
    registers: [],
    loading: false,
    generating: false,
    canceling: false,
    fetchingGeneration: false,
    fetchingCancelation: false,
    error: null,
    year: ""
});

interface RegistersProviderProps {
    year: string;
    children: any;
}

export const RegistersProvider = ({
    year,
    children
}: RegistersProviderProps) => {
    const useGetAvailableRegistersHook = useGetAvailableRegisters();
    const useGenerateRegisterHook = useGenerateRegister();
    const useCancelRegisterHook = useCancelRegister();
    const { poll, stop, isPolling } = usePoll();

    const get = useCallback(
        async () => await useGetAvailableRegistersHook.fetch(year),
        [useGetAvailableRegistersHook, year]
    );

    const generate = async () => {
        await useGenerateRegisterHook.fetch(year);
        await get();
    };

    const cancel = async () => {
        await useCancelRegisterHook.fetch(year);
        await get();
    };

    const generating =
        useGetAvailableRegistersHook.registers?.[0]?.generated?.status ===
        "PENDING";
    const canceling =
        useGetAvailableRegistersHook.registers?.[0]?.deleted?.status ===
        "PENDING";

    useEffect(() => {
        const pendingOperation = generating || canceling;

        if (pendingOperation) {
            if (!isPolling) {
                poll(() => get());
            }
        } else {
            stop();
        }
    }, [generating, canceling, get, poll, isPolling, stop]);

    return (
        <RegistersContext.Provider
            value={{
                registers: useGetAvailableRegistersHook.registers,
                get,
                generate,
                cancel,
                fetchingGeneration: useGenerateRegisterHook.loading,
                fetchingCancelation: useCancelRegisterHook.loading,
                loading: useGetAvailableRegistersHook.loading,
                generating,
                canceling,
                error:
                    useGetAvailableRegistersHook.error?.response?.data ??
                    useGetAvailableRegistersHook.error ??
                    undefined,
                year: year
            }}
        >
            {children}
        </RegistersContext.Provider>
    );
};

export const useRegisters = () => {
    return useContext(RegistersContext);
};
