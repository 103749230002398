import { useRequest } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AxiosResponse } from "../interfaces/AxiosResponse";

export interface ClosePeriodOptions {
    workspaceId: string;
    accountingPeriodId: string;
    openNextPeriod?: boolean;
    dryRun?: boolean;
}

export interface CloseAccountingPeriod {
    closePeriod: (options: ClosePeriodOptions) => Promise<string>;
}

export default function useCloseAccountingPeriod(): CloseAccountingPeriod {
    const customerData = useCustomerData();

    const { fetch } = useRequest({
        url: `${ASYNC_API_URL}/accounting-periods/close-accounting-period`,
        method: "post",
        ...useAxiosConfigRequestParams("ClosePeriod"),
        lazy: true
    });

    const closePeriod = async ({
        workspaceId,
        accountingPeriodId
    }: ClosePeriodOptions) => {
        return fetch({
            params: {
                ...customerData
            },
            data: {
                workspaceId,
                accountingPeriodId,
                openNextPeriod: true,
                dryRun: false
            }
        }).then((response: AxiosResponse<string>) => response.data);
    };

    return {
        closePeriod
    };
}
