import { some } from "lodash";
import { useEffect, useRef, useState } from "react";

import usePrevious from "../../../core/commons/hooks/usePrevious";
import { AssetInfoResponse } from "../../../core/usecases/dtos/AssetInfoDto";
import {
    AssetSubkindDto,
    AssetSubkindsDto
} from "../../../core/usecases/dtos/AssetSubkindsDto";
import { useGetAssetDeductibilityGoods } from "../../../core/usecases/useGetAssetDeductibilityGoods";
import { useGetAssetRelations } from "../../../core/usecases/useGetAssetRelations";
import {
    DraftAssetPatch,
    ReducedDeductibilityGoods,
    usePatchDraftAsset
} from "../../../core/usecases/usePatchDraftAsset";
import AssetRegistry from "../../components/Assets/AssetRegistry";

interface IRegistryProps {
    assetId: string | null;
    draftId: string | null;
    setCurrentSubkindId: (currentSubkindId: string | null) => void;
    assetInfo: AssetInfoResponse | null;
    refCurrency: string | null;
    subspeciesList: AssetSubkindsDto | null;
    isGroupingAsset: boolean;
    assetGroupName?: string; // is grouped articles
    showTabs: boolean;
    setShowTabs: (showTabs: boolean) => void;
    readOnly?: boolean;
}

export enum ReducedDeductibilityTypes {
    BUSINESS_VEHICLES = "BUSINESS_VEHICLES",
    EMPLOYEE = "EMPLOYEE",
    ADMINISTRATOR = "ADMINISTRATOR",
    NO = "NO",
    BUILDING_AND_LAND = "BUILDING_AND_LAND"
}

const Registry = ({
    assetId,
    draftId,
    assetInfo,
    refCurrency,
    setCurrentSubkindId,
    isGroupingAsset,
    subspeciesList,
    assetGroupName,
    showTabs,
    setShowTabs,
    readOnly
}: IRegistryProps) => {
    const previousAccountCode = usePrevious(assetInfo?.accountCode);
    const shouldShowMenus =
        assetInfo?.reducedDeductibility ===
            ReducedDeductibilityTypes.BUSINESS_VEHICLES ||
        assetInfo?.reducedDeductibility ===
            ReducedDeductibilityTypes.BUILDING_AND_LAND;
    const shouldShowDependentMenus = some(
        assetInfo?.reducedDeductibilityGoods,
        (item) =>
            item.mixedUse === ReducedDeductibilityTypes.EMPLOYEE ||
            item.mixedUse === ReducedDeductibilityTypes.ADMINISTRATOR
    );

    const [currentDescription, setCurrentDescription] = useState<
        string | undefined
    >(assetInfo?.assetFullDescription);
    const [isReducedDeductibility, setIsReducedDeductibility] = useState(
        !!assetInfo?.reducedDeductibility
    );
    const [currentArticleDesc, setCurrentArticleDesc] = useState(
        assetInfo?.articleDesc
    );

    const [currentAlias, setCurrentAlias] = useState(assetInfo?.alias);

    const [dataToPatch, setDataToPatch] = useState<DraftAssetPatch | null>(
        null
    );
    const [selectedSubkindId, setSelectedSubkindId] = useState<string | null>(
        null
    );
    const [openAccountRelations, setOpenAccountRelations] =
        useState<boolean>(false);
    const [showUsageMenu, setShowUsageMenu] =
        useState<ReducedDeductibilityTypes | null>(
            shouldShowMenus
                ? (assetInfo.reducedDeductibility as ReducedDeductibilityTypes)
                : null
        );
    const [showDependentMenus, setShowDependentMenus] = useState<boolean>(
        shouldShowDependentMenus
    );
    const [reducedDeductibilityGoods, setIsReducedDeductibilityGoods] =
        useState<ReducedDeductibilityGoods>({
            mixedUse: null,
            assignmentDate: null,
            revocationDate: null
        });
    const previousDataToPatch = usePrevious(dataToPatch);

    const textRef = useRef<HTMLTextAreaElement | null>(null);

    const {
        patchDraftAsset,
        hasResponded: hasPatchResponded,
        deleteFields
    } = usePatchDraftAsset({
        assetId: assetId,
        draftId: draftId,
        dataToPatch,
        lazy: true
    });

    const { fetch: fetchDeductibilityGoods, deductibilityGoods } =
        useGetAssetDeductibilityGoods({
            accountCode: assetInfo?.accountCode,
            lazy: true
        });

    useEffect(() => {
        if (
            !!dataToPatch &&
            JSON.stringify(previousDataToPatch) !==
                JSON.stringify(dataToPatch) &&
            draftId
        ) {
            patchDraftAsset();
            if (hasPatchResponded) setDataToPatch(null);
        }
    }, [
        dataToPatch,
        draftId,
        patchDraftAsset,
        hasPatchResponded,
        previousDataToPatch
    ]);

    const {
        fetch: fetchAccountRelations,
        data,
        loading
    } = useGetAssetRelations({
        accountCode: assetInfo?.accountCode,
        lazy: true
    });

    useEffect(() => {
        if (
            !loading &&
            assetInfo?.accountCode !== null &&
            previousAccountCode !== assetInfo?.accountCode
        ) {
            fetchAccountRelations();
        }
    }, [
        loading,
        assetInfo?.accountCode,
        fetchAccountRelations,
        previousAccountCode
    ]);

    useEffect(() => {
        if (previousAccountCode !== assetInfo?.accountCode) {
            fetchDeductibilityGoods();
        }
    }, [previousAccountCode, assetInfo?.accountCode, fetchDeductibilityGoods]);

    useEffect(() => {
        if (assetInfo?.reducedDeductibility && shouldShowMenus) {
            setShowUsageMenu(
                assetInfo.reducedDeductibility as ReducedDeductibilityTypes
            );
        }
    }, [assetInfo?.reducedDeductibility, shouldShowMenus]);

    useEffect(() => {
        const subkind = (list: AssetSubkindDto[], subKind: string) => {
            return (
                list && list.find((subkind: any) => subkind.subKind === subKind)
            );
        };

        const favouriteSubkind = subkind(
            subspeciesList?.favourites ?? [],
            assetInfo?.subKind ?? ""
        );
        const otherSubkind = subkind(
            subspeciesList?.others ?? [],
            assetInfo?.subKind ?? ""
        );

        if (favouriteSubkind) {
            setSelectedSubkindId(favouriteSubkind.subKind);
            setCurrentSubkindId(favouriteSubkind.subKind);
        } else if (otherSubkind) {
            setSelectedSubkindId(otherSubkind.subKind);
            setCurrentSubkindId(otherSubkind.subKind);
        } else {
            setSelectedSubkindId(null);
        }
    }, [setCurrentSubkindId, setSelectedSubkindId, subspeciesList, assetInfo]);

    const handleOpenAccountRelationsTab = () => {
        setOpenAccountRelations(!openAccountRelations);
        setShowTabs(!showTabs);
    };

    const handleSetReducedDeductibility = async () => {
        setIsReducedDeductibility(!isReducedDeductibility);
        if (isReducedDeductibility && assetInfo?.uuid && draftId) {
            await deleteFields(draftId, assetInfo?.uuid, assetInfo?.parentId, [
                "reducedDeductibility",
                "reducedDeductibilityGoods",
                "isReducedDeductibility"
            ]);
            setShowUsageMenu(null);
            setShowDependentMenus(false);
        }
    };

    const handleSetArticleDesc = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setCurrentArticleDesc(event.target.value);
    };

    return (
        <AssetRegistry
            openAccountRelations={openAccountRelations}
            data={data}
            onOpenAccountRelationsTab={handleOpenAccountRelationsTab}
            isGroupingAsset={isGroupingAsset}
            assetGroupName={assetGroupName}
            assetInfo={assetInfo}
            onSetArticleDesc={handleSetArticleDesc}
            currentArticleDesc={currentArticleDesc}
            setDataToPatch={setDataToPatch}
            refCurrency={refCurrency}
            textRef={textRef}
            setSelectedSubkindId={setSelectedSubkindId}
            selectedSubkindId={selectedSubkindId}
            subspeciesList={subspeciesList}
            currentDescription={currentDescription}
            setCurrentDescription={setCurrentDescription}
            setIsReducedDeductibilityGoods={setIsReducedDeductibilityGoods}
            reducedDeductibilityGoods={reducedDeductibilityGoods}
            isReducedDeductibility={isReducedDeductibility}
            onSetReducedDeductibility={handleSetReducedDeductibility}
            setShowDependentMenus={setShowDependentMenus}
            showDependentMenus={showDependentMenus}
            showUsageMenu={showUsageMenu}
            setShowUsageMenu={setShowUsageMenu}
            deductibilityGoods={deductibilityGoods}
            currentAlias={currentAlias}
            setCurrentAlias={setCurrentAlias}
            draftReadOnly={readOnly}
        />
    );
};

export default Registry;
