import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Stack
} from "@vapor/react-material";
import { isNil } from "lodash";
import { useDraft } from "../../../../../core/domain/Draft/queries";

import { AccountingLine } from "../../../../../core/usecases/dtos/AccountingLinesDto";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../FormattedAmount";
import SplitAricleForm from "./SplitArticleForm";

interface SplitArticleDialogProps {
    draftId: string;
    isOpen: boolean;
    onClose: () => void;
    accountingLine?: AccountingLine;
}

const fs = getFormattedStringWithScope(
    "components.Accounting.AccountingLinesTable.SplitArticleDialog"
);

export default function SplitArticleDialog({
    draftId,
    isOpen,
    onClose,
    accountingLine
}: SplitArticleDialogProps) {
    const { data: draft, isSuccess } = useDraft(draftId);

    if (isSuccess) {
        const { refCurrency } = draft;

        return isNil(accountingLine) ? null : (
            <Dialog
                open={isOpen}
                onClose={onClose}
                sx={{
                    "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
                        minWidth: "fit-content"
                    }
                }}
            >
                <DialogTitle>{fs("splitArticle")}</DialogTitle>
                <Divider variant="fullWidth" />
                <DialogContent>
                    <Typography
                        variant="filedText"
                        component="div"
                        gutterBottom
                    >
                        {fs("caption")}
                    </Typography>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        marginBottom="24px"
                    >
                        <Typography
                            variant="bodyLarge700"
                            component="div"
                            gutterBottom
                        >
                            {accountingLine.articleDesc} {fs("of")}
                        </Typography>
                        <FormattedAmount
                            variant="bodyLarge"
                            amount={
                                (accountingLine.debit ||
                                    accountingLine.credit) ??
                                0
                            }
                            currency={refCurrency}
                        />
                    </Stack>
                    <SplitAricleForm
                        draftId={draftId}
                        accountingLine={accountingLine}
                        onClose={onClose}
                    />
                </DialogContent>
            </Dialog>
        );
    }

    return null;
}
