import Typography, {
    ExtendedTypographyProps
} from "@vapor/react-extended/ExtendedTypography";
import omit from "lodash/omit";
import { FormattedNumber } from "react-intl";

export interface FormattedPercentageProps extends ExtendedTypographyProps {
    percentage: number;
    color?: string;
    // this is a workaround for the style props of the FormattedPercentage component
    // the style prop is misinterpreted by the linter causing a warning
    formattedNumberStyle?: "percent";
}

export default function FormattedPercentage({
    percentage,
    color,
    // this is part of the aforementioned workaround.
    // for the moment we have to leave it like this
    formattedNumberStyle = "percent",
    ...typographyProps
}: FormattedPercentageProps) {
    function renderFormattedPercentages(formattedNumber: string) {
        return (
            <Typography
                color={color || ""}
                // we have to omit the sx props because types are incompatible
                {...omit(typographyProps, "sx")}
            >
                {formattedNumber}
            </Typography>
        );
    }
    return (
        <FormattedNumber
            style={formattedNumberStyle}
            value={percentage / 100}
            minimumFractionDigits={2}
        >
            {renderFormattedPercentages}
        </FormattedNumber>
    );
}
