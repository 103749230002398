import Typography, {
    ExtendedTypographyProps
} from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import omit from "lodash/omit";

import FormattedAmount, { FormattedAmountProps } from "../FormattedAmount";

interface LargeAmountWithLabelProps {
    label: string;
    labelProps?: ExtendedTypographyProps;
    amountProps?: FormattedAmountProps;
}

export default function LargeAmountWithLabel({
    label,
    labelProps,
    amountProps
}: LargeAmountWithLabelProps) {
    return (
        <Box display="flex" justifyContent="flex-end" flexDirection="column">
            <Typography variant="titleSmall" {...omit(labelProps, "sx")}>
                {label}
            </Typography>
            <FormattedAmount
                minWidth="120px"
                variant="bodyLarge700"
                fontSize="32px"
                component="div"
                {...omit(amountProps, "sx")}
            />
        </Box>
    );
}
