import { useMutateAccountingLine } from "../../../../core/domain/AccountingLines/queries";
import { default as SmartSelectTribute } from "../../SelectTribute";

interface SelectTributeProps {
    draftId: string;
    lineId?: string;
    value: string | null;
    readOnly?: boolean;
}

export default function SelectTribute({
    draftId,
    lineId,
    value,
    readOnly = false
}: SelectTributeProps) {
    const { mutate } = useMutateAccountingLine({
        draftId,
        accountingLineId: lineId ? [lineId] : []
    });

    const handleTributeCodeChange = async (tributeCode: string | null) => {
        tributeCode &&
            mutate({
                tributeCode
            });
    };

    return (
        <SmartSelectTribute
            value={value}
            readOnly={readOnly}
            onChange={handleTributeCodeChange}
            transparent={true}
        />
    );
}
