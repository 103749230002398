import { ExtendedTab, ExtendedTabs } from "@vapor/react-extended";
import { compact, some } from "lodash";
import { useState } from "react";

import { DraftAssetProvider } from "../../../core/contexts/DraftAsset";
import { DraftUsedGoodProvider } from "../../../core/contexts/DraftUsedGood";
import WithholdingsProvider from "../../../core/contexts/Withholdings";
import { useAccountingLines } from "../../../core/domain/AccountingLines/queries";
import { useDraft } from "../../../core/domain/Draft/queries";
import { useVatLines } from "../../../core/domain/VatLines/queries";
import { Reason } from "../../../utils/appEnums";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import Assets from "../../views/Assets";
import UsedGoods from "../../views/UsedGoods";
import Withholdings from "../Withholdings";
import F24Accountability from "./F24Accountability";
import RegularAccountability from "./RegularAccountability";

enum Tabs {
    ACCOUNTABILITY = "ACCOUNTABILITY",
    ASSET = "ASSET",
    WITHHOLDING = "WITHHOLDING",
    USEDGOODS = "USEDGOODS"
}

const fs = getFormattedStringWithScope("components.Accounting");

interface AccountingProps {
    draftId: string;
    readOnly?: boolean;
}

export default function Accounting({ draftId, readOnly }: AccountingProps) {
    const { data: draft, isSuccess: isDraftSuccess } = useDraft(draftId);
    const { data: vatLines } = useVatLines(draftId);
    const { data: accountingLines, isSuccess: isAccountingLinesSuccess } =
        useAccountingLines({ draftId });

    const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.ACCOUNTABILITY);

    if (isDraftSuccess && isAccountingLinesSuccess) {
        const hasAssets = some(
            accountingLines,
            (accountingLine) => accountingLine.isAsset
        );

        const availableTabs = compact([
            Tabs.ACCOUNTABILITY,
            draft?.hasWithHoldings ? Tabs.WITHHOLDING : null,
            hasAssets ? Tabs.ASSET : null,
            draft?.hasUsedGoods ? Tabs.USEDGOODS : null
        ]);

        const { reasonId, refCurrency } = draft;
        return (
            <>
                <ExtendedTabs
                    value={currentTab}
                    onChange={(_, value) => setCurrentTab(value as Tabs)}
                    sx={{
                        marginBottom: "24px"
                    }}
                >
                    {availableTabs.map((tab, index) => (
                        <ExtendedTab
                            key={index}
                            label={fs(`tabs.${tab}`)}
                            value={tab}
                        ></ExtendedTab>
                    ))}
                </ExtendedTabs>
                {currentTab === Tabs.ACCOUNTABILITY ? (
                    reasonId === Reason.PF24 ? (
                        <F24Accountability
                            draftId={draftId}
                            readOnly={readOnly}
                        />
                    ) : (
                        <RegularAccountability
                            draftId={draftId}
                            readOnly={readOnly}
                        />
                    )
                ) : null}
                {currentTab === Tabs.ASSET && draft?.id ? (
                    <DraftAssetProvider draftId={draft?.id}>
                        <Assets
                            draftId={draft?.id}
                            businessId={draft?.businessId}
                            isActive={draft?.doc.active ?? false}
                            refCurrency={refCurrency}
                            readOnly={readOnly}
                        />
                    </DraftAssetProvider>
                ) : null}
                {currentTab === Tabs.WITHHOLDING && draft?.id ? (
                    <WithholdingsProvider>
                        <Withholdings
                            draftId={draft.id}
                            draftVat={vatLines ?? []}
                            refCurrency={refCurrency}
                        />
                    </WithholdingsProvider>
                ) : null}
                {currentTab === Tabs.USEDGOODS && draft?.id ? (
                    <DraftUsedGoodProvider draftId={draft?.id}>
                        <UsedGoods refCurrency={refCurrency} />
                    </DraftUsedGoodProvider>
                ) : null}
            </>
        );
    }

    return null;
}
