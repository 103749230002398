export interface ExemptionNature {
    id: string | ExemptionNatureCode;
    shortDescription: string;
    description: string;
    nature: string;
    disabled?: boolean;
}

export enum ExemptionNatureCode {
    NVI000 = "NVI000"
}
