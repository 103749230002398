import Typography from "@vapor/react-extended/ExtendedTypography";
import FormControl from "@vapor/react-material/FormControl";
import InputLabel from "@vapor/react-material/InputLabel";
import MenuItem from "@vapor/react-material/MenuItem";
import Select from "@vapor/react-material/Select";
import isNil from "lodash/isNil";
import range from "lodash/range";

import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";

const fs = getFormattedStringWithScope("components.SelectYear");

export interface SelectYearProps {
    inputTitle?: string;
    value: number | null;
    startingYear: number;
    numberOfYears: number;
    onChange: Function;
}

export default function SelectYear({
    inputTitle,
    value,
    startingYear,
    numberOfYears,
    onChange
}: SelectYearProps) {
    return (
        <FormControl fullWidth>
            <InputLabel>
                {isNil(inputTitle) ? fs("year") : inputTitle}
            </InputLabel>
            <Select
                value={value}
                onChange={(event) => onChange(event.target.value)}
            >
                {range(numberOfYears).map((_, index) => (
                    <MenuItem key={index} value={startingYear - index}>
                        <Typography>{startingYear - index}</Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
