import { Spinner } from "@comic/precog-components";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Checkbox,
    InputLabel,
    MenuItem,
    Select,
    Stack
} from "@vapor/react-material";
import Button from "@vapor/react-material/Button";
import Dialog from "@vapor/react-material/Dialog";
import DialogActions from "@vapor/react-material/DialogActions";
import DialogContent from "@vapor/react-material/DialogContent";
import DialogTitle from "@vapor/react-material/DialogTitle";
import Divider from "@vapor/react-material/Divider";
import FormControl from "@vapor/react-material/FormControl";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import IconButton from "@vapor/react-material/IconButton";
import { isNil } from "lodash";
import { useEffect, useMemo, useState } from "react";

import {
    useAccountingLines,
    useMutateAccountingLine
} from "../../../core/domain/AccountingLines/queries";
import { useExemptionNatures } from "../../../core/domain/ExemptionNatures/queries";
import { AccountingLine } from "../../../core/usecases/dtos/AccountingLinesDto";
import { VatRate } from "../../../utils/appEnums";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import { NatureGroupForReverseCharge } from "../../../utils/vatUtils";

interface ReverseChargeDialogProps {
    draftId: string;
    isModalOpen: boolean;
    onClose: (hasSelected?: boolean) => void;
    accountingLine?: AccountingLine;
}

export default function ReverseChargeDialog({
    draftId,
    isModalOpen,
    onClose,
    accountingLine
}: ReverseChargeDialogProps) {
    const fs = useFormattedStringWithScope("components.ReverseChargeDialog");
    const fsVatRate = useFormattedStringWithScope("commons.VatRate");
    const allVatRates = Object.values(VatRate);
    const vatRates = allVatRates.filter(
        (vatRate) => vatRate !== VatRate["0.0"]
    );
    const [applyToAllOtherArticles, setApplyToAllOtherArticles] =
        useState<boolean>(false);
    const [selectedVatRate, setSelectedVatRate] = useState<VatRate | null>(
        accountingLine?.reverseChargeRate ?? null
    );
    const [doNotApplyReverseCharge, setDoNotApplyReverseCharge] =
        useState<boolean>(!!accountingLine?.doNotApplyReverseCharge);
    const { data: accountingLines, isSuccess } = useAccountingLines({
        draftId
    });

    const { mutate } = useMutateAccountingLine({
        draftId,
        accountingLineId: applyToAllOtherArticles
            ? accountingLines
                  ?.filter((accountingLine) =>
                      accountingLine.exemptionNature?.includes(
                          NatureGroupForReverseCharge
                      )
                  )
                  .map((accountingLine) => accountingLine.uuid!) ?? []
            : accountingLine?.uuid!
    });

    const { data: allExemptionNatures, isLoading: isLoadingExemptionNatures } =
        useExemptionNatures();

    const reverseChargeNatures = useMemo(
        () =>
            allExemptionNatures?.filter((exemptionNature) =>
                exemptionNature.nature.includes(NatureGroupForReverseCharge)
            ) ?? [],
        [allExemptionNatures]
    );

    const exemption =
        reverseChargeNatures.find(
            (exemptionNature) =>
                exemptionNature.id === accountingLine?.exemptionNatureId
        ) ?? null;

    useEffect(() => {
        if (accountingLine) {
            setDoNotApplyReverseCharge(
                !!accountingLine.doNotApplyReverseCharge
            );
            setSelectedVatRate(accountingLine.reverseChargeRate ?? null);
        }
    }, [accountingLine]);

    if (isSuccess) {
        const handleSumbit = async () => {
            // update doNotApplyReverseCharge value in the accountingLine
            const mutationBody: {
                doNotApplyReverseCharge: boolean;
                reverseChargeRate?: VatRate;
            } = {
                doNotApplyReverseCharge: doNotApplyReverseCharge
            };
            if (!doNotApplyReverseCharge) {
                mutationBody.reverseChargeRate = selectedVatRate ?? undefined;
            }

            mutate(mutationBody);

            onClose(true);
        };

        return (
            <Dialog
                open={isModalOpen}
                onClose={() => onClose()}
                sx={{
                    zIndex: "5000",
                    "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm":
                        {
                            minWidth: "680px"
                        }
                }}
            >
                <DialogTitle>
                    {fs("title")}
                    <IconButton color="primary" onClick={() => onClose()}>
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                </DialogTitle>
                <Divider variant="fullWidth" />
                <DialogContent>
                    <Spinner loading={isLoadingExemptionNatures}>
                        <Stack spacing={2}>
                            <Typography>
                                {exemption?.nature} {exemption?.description}
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={() =>
                                            setDoNotApplyReverseCharge(
                                                !doNotApplyReverseCharge
                                            )
                                        }
                                    />
                                }
                                label={fs("doNotApplyReverseCharge")}
                                value={doNotApplyReverseCharge}
                                checked={doNotApplyReverseCharge}
                            />
                            <Stack>
                                <FormControl
                                    fullWidth
                                    disabled={
                                        isNil(exemption) ||
                                        doNotApplyReverseCharge
                                    }
                                    sx={{ width: "240px" }}
                                >
                                    <InputLabel required>
                                        {fs("selectRate")}
                                    </InputLabel>
                                    <Select
                                        value={selectedVatRate}
                                        onChange={(event) =>
                                            setSelectedVatRate(
                                                event.target.value as VatRate
                                            )
                                        }
                                        MenuProps={{
                                            sx: {
                                                zIndex: "5001"
                                            }
                                        }}
                                    >
                                        {vatRates.map((vatRate) => (
                                            <MenuItem
                                                key={vatRate}
                                                value={vatRate}
                                            >
                                                {fsVatRate(vatRate)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl
                                    disabled={
                                        isNil(exemption) ||
                                        doNotApplyReverseCharge
                                    }
                                    sx={{
                                        mt: 4
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    applyToAllOtherArticles
                                                }
                                                onChange={() =>
                                                    setApplyToAllOtherArticles(
                                                        !applyToAllOtherArticles
                                                    )
                                                }
                                            />
                                        }
                                        label={fs("applyToAllOthers")}
                                    />
                                </FormControl>
                            </Stack>
                        </Stack>
                    </Spinner>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => onClose()}>
                        {fs("cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        disabled={
                            doNotApplyReverseCharge
                                ? isNil(exemption)
                                : isNil(selectedVatRate) || isNil(exemption)
                        }
                        onClick={() => {
                            if (exemption) {
                                handleSumbit();
                                onClose(true);
                            } else {
                                onClose();
                            }
                        }}
                    >
                        {fs("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return null;
}
