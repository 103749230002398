import {
    ColumnElement,
    ColumnType,
    HeadCell,
    Table
} from "@comic/precog-components";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { capitalize } from "lodash";
import getFormattedStringWithScope from "../../../../../../../../utils/getFormattedStringWithScope";
import {
    VatSettlementDetail,
    VatSettlementDetailAliquotes
} from "../../../../../../../../core/usecases/dtos/VatSettlementDto";
import { Ventilations } from "../../../../../../../../utils/appEnums";
import FormattedAmount from "../../../../../../../components/FormattedAmount";
import FormattedPercentage from "../../../../../../../components/FormattedPercentage";
import Stack from "@vapor/react-material/Stack";
import { useMemo } from "react";

const fs = getFormattedStringWithScope("views.VATSettlement.monoActivity");
const fsVat = getFormattedStringWithScope("commons.VatEnum");

interface VentilationTableProps {
    data: VatSettlementDetail;
}

export default function VentilationTable({ data }: VentilationTableProps) {
    const sortedAliquotes = useMemo(
        () =>
            data.aliquotes.sort((a, b) =>
                parseInt(a.type.split("IVA")[1]) <
                parseInt(b.type.split("IVA")[1])
                    ? -1
                    : 1
            ),
        [data]
    );

    const columns: ColumnElement[] = [
        // description
        {
            kind: ColumnType.text,
            render: (detail: VatSettlementDetailAliquotes) => (
                <Typography>{fsVat(detail.type)}</Typography>
            )
        }
    ];

    const headCells: HeadCell[] = [
        {
            id: "vatRate",
            kind: ColumnType.text,
            label: fs("table.vatRate")
        }
    ];

    if (data.description === Ventilations.VENTILATION_RECEIPT) {
        headCells.push({
            id: "taxableAmount",
            kind: ColumnType.numeric,
            label: fs("table.taxableAmount")
        });

        columns.push({
            kind: ColumnType.numeric,
            render: (detail: VatSettlementDetailAliquotes) => (
                <FormattedAmount
                    variant="body500"
                    amount={detail.totalAmount}
                    currency="EUR"
                />
            )
        });
    }

    headCells.push(
        {
            id: "taxable",
            kind: ColumnType.numeric,
            label: fs("table.taxable")
        },
        {
            id: "tax",
            kind: ColumnType.numeric,
            label: fs("table.tax")
        }
    );

    columns.push(
        {
            kind: ColumnType.numeric,
            render: (detail: VatSettlementDetailAliquotes) => (
                <FormattedAmount
                    variant="body500"
                    amount={detail.taxable}
                    currency="EUR"
                />
            )
        },
        // taxable
        {
            kind: ColumnType.numeric,
            render: (detail: VatSettlementDetailAliquotes) => (
                <FormattedAmount
                    variant="body500"
                    amount={detail.tax}
                    currency="EUR"
                />
            )
        }
    );

    if (data.description === Ventilations.VENTILATION_RECEIPT) {
        data.aliquotes.forEach((aliquote) => {
            aliquote.repartition_perc =
                (aliquote.totalAmount * 100) / data.totalAmount;
        });
        headCells.push({
            id: "startReceipt",
            kind: ColumnType.numeric,
            label: fs("table.startReceipt")
        });
        columns.push({
            kind: ColumnType.numeric,
            render: (detail: VatSettlementDetailAliquotes) => (
                <FormattedPercentage
                    variant="body500"
                    percentage={detail.repartition_perc ?? 0}
                />
            )
        });
    } else {
        headCells.push({
            id: "totalAmount",
            kind: ColumnType.numeric,
            label: fs("table.totalAmount")
        });
        columns.push({
            kind: ColumnType.numeric,
            render: (detail: VatSettlementDetail) => (
                <FormattedAmount
                    variant="body500"
                    amount={detail.totalAmount}
                    currency="EUR"
                />
            )
        });
    }

    const columnsFooter: ColumnElement[] = [
        // description
        {
            kind: ColumnType.empty
        },
        {
            kind: ColumnType.numeric,
            render: () => (
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    <Typography fontWeight={"bold"}>
                        {capitalize(fs("table.total"))}:
                    </Typography>
                    <FormattedAmount
                        fontWeight={"bold"}
                        variant="body500"
                        amount={data.taxableAmount}
                        currency="EUR"
                    />
                </Stack>
            )
        },
        {
            kind: ColumnType.numeric,
            render: () => (
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    <Typography fontWeight={"bold"}>
                        {capitalize(fs("table.total"))}:
                    </Typography>
                    <FormattedAmount
                        fontWeight={"bold"}
                        variant="body500"
                        amount={data.vatAmount}
                        currency="EUR"
                    />
                </Stack>
            )
        },
        {
            kind: ColumnType.empty
        }
    ];

    return (
        <Table
            footer={
                data.description === Ventilations.VENTILATION_PURCHASE
                    ? columnsFooter
                    : undefined
            }
            headCells={headCells}
            columns={columns}
            data={sortedAliquotes}
        />
    );
}
