import { Spinner } from "@comic/precog-components";
import { useCurrentWorkspace } from "@drift/oneplatfront";
import Typography from "@vapor/react-extended/ExtendedTypography";
import CardContent from "@vapor/react-material/CardContent";
import Stack from "@vapor/react-material/Stack";
import { sum } from "lodash";
import compact from "lodash/compact";
import { useEffect, useState } from "react";

import { useGetCount } from "../../../core/usecases/useGetCount";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import CountsBar, { CountsBarElement } from "../../components/CountsBar";
import WidgetCard from "../../components/WidgetCard";
import usePrevious from "../../../core/commons/hooks/usePrevious";

const fs = getFormattedStringWithScope("widgets.ToAccount");

interface ToAccountProps {
    shouldRefetchCount: boolean;
    onFetchCount: () => void;
}

export default function ToAccount({
    onFetchCount,
    shouldRefetchCount
}: ToAccountProps) {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const [hasRefetchedCount, setHasRefetchedCount] = useState<boolean>(false);

    const {
        count,
        loading: loadingCount,
        error: errorFetchingCount,
        fetch: fetchCount,
        hasResponded
    } = useGetCount({ lazy: true });

    const { workspace: customer } = useCurrentWorkspace(true);
    const previousCustomer = usePrevious(customer);

    useEffect(() => {
        if (hasInitialized && customer !== previousCustomer) {
            setHasInitialized(false);
        }
    }, [customer, previousCustomer, hasInitialized]);

    useEffect(() => {
        if (customer && !hasInitialized) {
            fetchCount();
            setHasInitialized(true);
        }
    }, [hasInitialized, fetchCount, customer]);

    useEffect(() => {
        if (
            customer &&
            hasInitialized &&
            shouldRefetchCount &&
            !hasRefetchedCount
        ) {
            fetchCount();
            setHasRefetchedCount(true);
        }
    }, [
        hasInitialized,
        fetchCount,
        customer,
        shouldRefetchCount,
        hasRefetchedCount
    ]);

    useEffect(() => {
        if (!shouldRefetchCount) setHasRefetchedCount(false);
    }, [shouldRefetchCount]);

    useEffect(() => {
        if (shouldRefetchCount && hasResponded) {
            onFetchCount();
        }
    }, [shouldRefetchCount, hasResponded, onFetchCount]);

    const passiveInvoiceCount = count?.notConfirmed.countPassiveInvoice;
    const passiveInvoiceCountsBarElements: CountsBarElement[] = compact([
        passiveInvoiceCount?.withWithHoldings
            ? {
                  count: passiveInvoiceCount.withWithHoldings,
                  color: "#15939e",
                  label: fs("witholdings")
              }
            : null,
        passiveInvoiceCount?.withIntrastat
            ? {
                  count: passiveInvoiceCount.withIntrastat,
                  color: "#79c0c6",
                  label: fs("intrastat")
              }
            : null,
        passiveInvoiceCount?.withAssets
            ? {
                  count: passiveInvoiceCount.withAssets,
                  color: "#9edfde",
                  label: fs("assets")
              }
            : null
    ]);

    const activeInvoiceCount = count?.notConfirmed.countActiveInvoice;
    const activeInvoiceCountsBarElements: CountsBarElement[] = compact([
        activeInvoiceCount?.withWithHoldings
            ? {
                  count: activeInvoiceCount.withWithHoldings,
                  color: "#15939e",
                  label: fs("witholdings")
              }
            : null,
        activeInvoiceCount?.withIntrastat
            ? {
                  count: activeInvoiceCount.withIntrastat,
                  color: "#79c0c6",
                  label: fs("intrastat")
              }
            : null,
        activeInvoiceCount?.withAssets
            ? {
                  count: activeInvoiceCount.withAssets,
                  color: "#9edfde",
                  label: fs("assets")
              }
            : null
    ]);

    const receiptsCount = count?.notConfirmed.countReceipt;
    const receiptsCountsBarElements: CountsBarElement[] = compact([
        receiptsCount
            ? {
                  count: receiptsCount,
                  color: "#375188"
              }
            : null
    ]);

    const bankTransactionsCount = count?.notConfirmed.countBankTransaction;
    const bankTransactionsCountsBarElements: CountsBarElement[] = compact([
        bankTransactionsCount
            ? {
                  count: bankTransactionsCount,
                  color: "#7b94dd"
              }
            : null
    ]);

    const otherCount = sum(
        compact([
            count?.notConfirmed.countOthers,
            count?.notConfirmed.countFinancialTransaction,
            count?.notConfirmed.countOtherTransactions
        ])
    );
    const otherCountsBarElements: CountsBarElement[] = compact([
        otherCount
            ? {
                  count: otherCount,
                  color: "#c89bd7"
              }
            : null
    ]);

    return (
        <Spinner loading={loadingCount}>
            <WidgetCard error={errorFetchingCount}>
                <CardContent>
                    {!loadingCount && count?.confirmed ? (
                        <>
                            <Stack
                                direction="row"
                                width="100%"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography variant="titleMedium">
                                    {fs("toAccount")}
                                </Typography>
                                <Typography
                                    variant="displayMedium"
                                    color="primary.interactiveDefault"
                                >
                                    {count?.notConfirmed.total}
                                </Typography>
                            </Stack>
                            {/* Typographies are one inside the other because, as for now, 
                        they have different line height and it's a mess to align the two texts */}
                            <Typography
                                variant="titleXSmall"
                                color="primary.textSubduedColor"
                            >
                                {fs("accountedThisYear")}{" "}
                                <Typography
                                    variant="bodySmall"
                                    fontWeight="500"
                                    color="primary.textDefaultColor"
                                >
                                    {count?.confirmed.total}
                                </Typography>
                            </Typography>
                            <Stack>
                                {passiveInvoiceCount ? (
                                    <Stack>
                                        <Typography variant="boldLabel">
                                            {fs("purchaseInvoices")}{" "}
                                            <Typography
                                                variant="boldLabel"
                                                color="primary.interactiveDefault"
                                            >
                                                {passiveInvoiceCount.total}
                                            </Typography>
                                        </Typography>
                                        <CountsBar
                                            counts={
                                                passiveInvoiceCountsBarElements
                                            }
                                            barHeight="16px"
                                            total={passiveInvoiceCount.total}
                                            containerWidthReference={
                                                count?.notConfirmed.total
                                            }
                                            includeCountsInTooltip={true}
                                        />
                                    </Stack>
                                ) : null}
                                {activeInvoiceCount ? (
                                    <Stack>
                                        <Typography
                                            variant="boldLabel"
                                            marginTop="4px"
                                        >
                                            {" "}
                                            {fs("salesInvoices")}{" "}
                                            <Typography
                                                variant="boldLabel"
                                                color="primary.interactiveDefault"
                                            >
                                                {activeInvoiceCount.total}
                                            </Typography>
                                        </Typography>
                                        <CountsBar
                                            counts={
                                                activeInvoiceCountsBarElements
                                            }
                                            barHeight="16px"
                                            total={activeInvoiceCount.total}
                                            containerWidthReference={
                                                count?.notConfirmed.total
                                            }
                                            includeCountsInTooltip={true}
                                        />
                                    </Stack>
                                ) : null}
                                {receiptsCount ? (
                                    <Stack>
                                        <Typography
                                            variant="boldLabel"
                                            marginTop="4px"
                                        >
                                            {" "}
                                            {fs("receipts")}{" "}
                                            <Typography
                                                variant="boldLabel"
                                                color="primary.interactiveDefault"
                                            >
                                                {receiptsCount}
                                            </Typography>
                                        </Typography>
                                        <CountsBar
                                            counts={receiptsCountsBarElements}
                                            barHeight="16px"
                                            total={receiptsCount}
                                            containerWidthReference={
                                                count?.notConfirmed.total
                                            }
                                        />
                                    </Stack>
                                ) : null}
                                {bankTransactionsCount ? (
                                    <Stack>
                                        <Typography
                                            variant="boldLabel"
                                            marginTop="4px"
                                        >
                                            {" "}
                                            {fs("bankTransactions")}{" "}
                                            <Typography
                                                variant="boldLabel"
                                                color="primary.interactiveDefault"
                                            >
                                                {bankTransactionsCount}
                                            </Typography>
                                        </Typography>
                                        <CountsBar
                                            counts={
                                                bankTransactionsCountsBarElements
                                            }
                                            barHeight="16px"
                                            total={bankTransactionsCount}
                                            containerWidthReference={
                                                count?.notConfirmed.total
                                            }
                                        />
                                    </Stack>
                                ) : null}
                                {otherCount ? (
                                    <Stack>
                                        <Typography
                                            variant="boldLabel"
                                            marginTop="4px"
                                        >
                                            {" "}
                                            {fs("other")}{" "}
                                            <Typography
                                                variant="boldLabel"
                                                color="primary.interactiveDefault"
                                            >
                                                {otherCount}
                                            </Typography>
                                        </Typography>
                                        <CountsBar
                                            counts={otherCountsBarElements}
                                            barHeight="16px"
                                            total={otherCount}
                                            containerWidthReference={
                                                count?.notConfirmed.total
                                            }
                                        />
                                    </Stack>
                                ) : null}
                            </Stack>
                        </>
                    ) : null}
                </CardContent>
            </WidgetCard>
        </Spinner>
    );
}
