export enum AccountingType {
    ORDINARY = "ORDINARY",
    SIMPLIFIED = "SIMPLIFIED"
}

export enum BalanceEntryMode {
    ENTRY = "ENTRY",
    COMPETENCE = "COMPETENCE"
}

export enum BusinessId {
    ALL = "Tutti"
}

export enum Language {
    it = "it-IT",
    en = "en-GB"
}

export enum KeyBoardEventCode {
    ENTER = "Enter"
}

export enum JournalType {
    ALL = "",
    SALE = "SALE",
    PURCHASE = "PURCHASE",
    RECEIPTS = "RECEIPT"
}

export enum RegistrationType {
    RECEIPT = "RECEIPT",
    DOCUMENT = "DOCUMENT",
    SALE = "SALE",
    PURCHASE = "PURCHASE",
    BANK_TRANSACTION = "BANK_TRANSACTION",
    FINANCIAL_TRANSACTION = "FINANCIAL_TRANSACTION",
    OTHER_TRANSACTION = "OTHER_TRANSACTION"
}

export enum SectionalType {
    ALL = ""
}

export enum QueryParam {
    accountId = "accountId",
    accountName = "accountName",
    businessId = "businessId",
    journalType = "journalType",
    vatCode = "vatCode",
    startDate = "startDate",
    endDate = "endDate",
    callbackUrl = "callbackUrl",
    balanceByCompetence = "balanceByCompetence",
    balanceWithAssetSimulation = "balanceWithAssetSimulation"
}

export enum WithholdingFilter {
    paid = "paid",
    partialPaid = "partialPaid",
    toBePaid = "toBePaid"
}

export enum PeriodType {
    MONTHLY = "MONTHLY",
    QUARTERLY = "QUARTERLY",
    MIXED = "MIXED"
}

export enum ShellCompany {
    BUILDINGS_AND_OTHER_ASSETS = "BUILDINGS_AND_OTHER_ASSETS",
    OFFICE_BUILDINGS = "OFFICE_BUILDINGS",
    RESIDENTIAL_BUILDINGS = "RESIDENTIAL_BUILDINGS",
    OTHER_FIXED_ASSETS = "OTHER_FIXED_ASSETS",
    BUILDINGS_AND_OTHER_ASSETS_SMALL_MUNICIPALITIES = "BUILDINGS_AND_OTHER_ASSETS_SMALL_MUNICIPALITIES"
}

export enum MovementsType {
    PURCHASE = "PURCHASE",
    INCREASE = "INCREASE",
    SALE = "SALE",
    PARTIALLY_SALE = "PARTIALLY_SALE",
    DECOMISSIONED = "DECOMISSIONED",
    PARTIALLY_DECOMISSIONED = "PARTIALLY_DECOMISSIONED",
    DEPRECIATION = "DEPRECIATION",
    REVALUATE = "REVALUATE",
    DEVALUATE = "DEVALUATE",
    DEVALUATE_ADJUSTMENT = "DEVALUATE_ADJUSTMENT"
}

export enum LogStatus {
    PENDING = "PENDING",
    ERROR = "ERROR",
    DONE = "DONE"
}

export enum WithholdingDrawerTab {
    management = "management",
    movements = "movements",
    inps = "inps"
}

export enum Movements {
    STATUTORY = "STATUTORY",
    TAX = "TAX",
    IRAP = "IRAP",
    TAX_RELIEF = "TAX_RELIEF",
    vatCode = "vatCode",
    startDate = "startDate",
    endDate = "endDate"
}

export enum AccountType {
    INCOME_STATEMENT = "INCOME_STATEMENT",
    BALANCE_SHEET = "BALANCE_SHEET"
}

export enum DrawerTab {
    MANAGEMENT = "management",
    INPS = "inps"
}

export enum VatCollectability {
    IMMEDIATE = "IMMEDIATE",
    DEFERRED = "DEFERRED",
    SPLIT_PAYMENT = "SPLIT_PAYMENT"
}

export enum Reason {
    CG1 = "CG1",
    CO1 = "CO1",
    CR1 = "CR1",
    FA1 = "FA1",
    FA3 = "FA3",
    FA4 = "FA4",
    FA5 = "FA5",
    FA7 = "FA7",
    FA8 = "FA8",
    FA401 = "FA401",
    FE1 = "FE1",
    FE3 = "FE3",
    FE4 = "FE4",
    FE5 = "FE5",
    FE8 = "FE8",
    FE9 = "FE9",
    FE10 = "FE10",
    FE401 = "FE401",
    IP1 = "IP1",
    MG1 = "MG1",
    MI1 = "MI1",
    PB1 = "PB1",
    PF1 = "PF1",
    PAF1 = "PAF1",
    PS1 = "PS1",
    RAC1 = "RAC1",
    PNC1 = "PNC1",
    RF1 = "RF1",
    RG1 = "RG1",
    VB1 = "VB1",
    RNF1 = "RNF1",
    PF24 = "PF24",
    CA21 = "CA21",
    GC1 = "GC1"
}

export enum ReasonType {
    PASSIVE = "PASSIVE",
    ACTIVE = "ACTIVE"
}

export enum VatRate {
    "0.0" = "0.0",
    "4.0" = "4.0",
    "5.0" = "5.0",
    "10.0" = "10.0",
    "22.0" = "22.0"
}

export enum CslType {
    CUSTOMER = "CUSTOMER",
    SUPPLIER = "SUPPLIER"
}

export enum DraftSource {
    B2B = "B2B",
    MANUAL = "MANUAL",
    DOC = "DOC"
}

export enum PaymentsDrawerType {
    CONTEXTUAL = "CONTEXTUAL",
    FISCAL = "FISCAL"
}

export enum PaymentType {
    PAYMENT = "PAYMENT",
    CREDIT_NOTE = "CREDIT_NOTE",
    INVOICE = "INVOICE"
}

export enum RegimeExtraField {
    COMPENSATION_PERCENTAGE = "% di compensazione",
    USED_GOOD_RATE = "Aliquota iva Bene Usato",
    FORFAIT_PERCENTAGE = "% forfettizzazione",
    UNDEDUCTIBILITY_PRECENTAGE = "% indetraibilità",
    RETURN_PERCENTAGE = "% di reso",
    SECOND_VAT_RATE = "2a Aliqu. IVA"
}

export enum ActiveStatus {
    ACTIVE = "ACTIVE",
    PASSIVE = "PASSIVE",
    ALL = "ALL"
}

export enum DepreciationScope {
    STATUTORY = "STATUTORY",
    STATUTORY_TAX = "STATUTORY_TAX"
}

export enum DraftAccountingStatus {
    CONFIRMED = "CONFIRMED",
    WAITING = "WAITING",
    ERROR = "ERROR",
    CLONED = "CLONED",
    DEPRECATED = "DEPRECATED",
    PROCESS_CONFIRM = "PROCESS_CONFIRM",
    PROCESS_UPDATE = "PROCESS_UPDATE",
    PROCESS_DELETE = "PROCESS_DELETE"
}

export enum AssetLogsSequenceOptions {
    PURCHASE = "PURCHASE",
    INCREASE = "INCREASE",
    DEPRECIATION = "DEPRECIATION",
    REVALUATE = "REVALUATE",
    DISMISS = "DISMISS",
    PARTIALLY_DISMISS = "PARTIALLY_DISMISS",
    PARTIALLY_DECOMISSIONED = "PARTIALLY_DECOMISSIONED",
    DECOMISSIONED = "DECOMISSIONED",
    SALE = "SALE",
    PARTIALLY_SALE = "PARTIALLY_SALE"
}

export enum FactType {
    INVOICE = "INVOICE",
    BANK_TRANSACTION = "BANK_TRANSACTION",
    RECEIPT = "RECEIPT",
    DOCUMENT = "DOCUMENT",
    NOT_AVAILABLE = "NOT_AVAILABLE"
}

export type WithholdingType = "ACTIVE" | "PASSIVE";

export enum Ventilations {
    VENTILATION_RECEIPT = "VENTILAZIONE_RECEIPT",
    VENTILATION_PURCHASE = "VENTILAZIONE_PURCHASE_YEARLY"
}
