import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { IconButton } from "@vapor/react-material/IconButton";

import {
    GroupedAccountDto,
    GroupedAccountsDto
} from "../../../../../core/usecases/dtos/BalanceDto";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../components/FormattedAmount";
import HierarchyTable, {
    ColumnType
} from "../../../../components/HierarchyTable";
import { compact } from "lodash";

interface SimplifiedAccountingIncomeStatementPassivitiesProps {
    numRows: number;
    rows: GroupedAccountsDto[];
    total: number;
    totalCollected: number;
    totalNotCollected: number;
    fiscalOperatingResult: number;
    competenceOperatingResult: number;
    onRowDetailClick: (accountId: string, accountName: string) => void;
}

const fs = getFormattedStringWithScope("components.BalanceTable");

export default function SimplifiedAccountingIncomeStatementPassivities({
    numRows,
    onRowDetailClick,
    rows,
    competenceOperatingResult,
    fiscalOperatingResult,
    total,
    totalCollected,
    totalNotCollected
}: SimplifiedAccountingIncomeStatementPassivitiesProps) {
    // TODO: this definition of the head cells is taken directly from the
    // vapor/material storybook, I don't like it but for now we can keep it like
    // this, until we figure out a better way to render a table
    const headCells = [
        {
            id: "passivity",
            kind: ColumnType.text,
            label: fs("revenues")
        },
        {
            id: "collected",
            kind: ColumnType.numeric,
            label: fs("collected")
        },
        {
            id: "not collected",
            kind: ColumnType.numeric,
            label: fs("notCollected")
        },
        {
            id: "total",
            kind: ColumnType.numeric,
            label: fs("total")
        },
        {
            id: "empty",
            kind: ColumnType.cta,
            label: ""
        }
    ];

    const parentColumns = [
        // account name
        {
            kind: ColumnType.text,
            render: (balanceEntry: GroupedAccountDto) => (
                <Typography variant="body" fontWeight="500">
                    {`${balanceEntry.accountId} ${balanceEntry.accountName}`}
                </Typography>
            )
        },
        // empty cell for alignment
        {
            render: () => <></>
        },
        // empty cell for alignment
        {
            render: () => <></>
        },
        // account value
        {
            kind: ColumnType.numeric,
            render: (balanceEntry: GroupedAccountDto) => (
                <FormattedAmount
                    variant="body"
                    fontWeight="500"
                    amount={balanceEntry.value}
                    currency="EUR"
                />
            )
        },
        // empty cell for alignment
        {
            render: () => <></>
        }
    ];

    const columns = [
        // account name
        {
            kind: ColumnType.text,
            render: (balanceEntry: GroupedAccountDto) => (
                <Typography variant="body" marginLeft="16px">
                    {balanceEntry.accountName}
                </Typography>
            )
        },
        // collected
        {
            kind: ColumnType.numeric,
            render: (balanceEntry: GroupedAccountDto) => (
                <FormattedAmount
                    variant="body"
                    amount={balanceEntry.amount}
                    currency="EUR"
                />
            )
        },
        // not collected
        {
            kind: ColumnType.numeric,
            render: (balanceEntry: GroupedAccountDto) => (
                <FormattedAmount
                    variant="body"
                    amount={balanceEntry.suspense}
                    currency="EUR"
                />
            )
        },
        // account value
        {
            kind: ColumnType.numeric,
            render: (balanceEntry: GroupedAccountDto) => (
                <FormattedAmount
                    variant="body"
                    amount={balanceEntry.value}
                    currency="EUR"
                />
            )
        },
        // CTA
        {
            kind: ColumnType.cta,
            render: (balanceEntry: GroupedAccountDto) => (
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() =>
                        onRowDetailClick(
                            balanceEntry.accountId,
                            balanceEntry.accountName
                        )
                    }
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </IconButton>
            )
        }
    ];

    const tableFooter = [
        // label
        {
            kind: ColumnType.text,
            render: () => (
                <Typography variant="boldLabel">
                    {fs("totalRevenues")}
                </Typography>
            )
        },
        // total not collected
        {
            kind: ColumnType.numeric,
            render: () => (
                <FormattedAmount
                    variant="boldLabel"
                    amount={totalCollected}
                    currency="EUR"
                />
            )
        },
        // total value
        {
            kind: ColumnType.numeric,
            render: () => (
                <FormattedAmount
                    variant="boldLabel"
                    amount={totalNotCollected}
                    currency="EUR"
                />
            )
        },
        // account value
        {
            kind: ColumnType.numeric,
            render: () => (
                <FormattedAmount
                    variant="boldLabel"
                    amount={total}
                    currency="EUR"
                />
            )
        },
        // empty cell for alignment
        {
            render: () => {}
        }
    ];

    const emptyRowFooter = [
        // empty cell for alignment
        {
            render: () => {}
        },
        // empty cell for alignment
        {
            render: () => {}
        },
        // empty cell for alignment
        {
            render: () => {}
        },
        // empty cell for alignment
        {
            render: () => {}
        },
        // empty cell for alignment
        {
            render: () => {}
        }
    ];

    const fiscalOperatingBalanceFooter =
        fiscalOperatingResult > 0
            ? [
                  // label
                  {
                      kind: ColumnType.text,
                      render: () => (
                          <Typography variant="boldLabel">
                              {fs("fiscalOperatingBalance")}
                          </Typography>
                      )
                  },
                  // empty cell for alignment
                  {
                      render: () => {}
                  },
                  // empty cell for alignment
                  {
                      render: () => {}
                  },
                  // total value
                  {
                      kind: ColumnType.numeric,
                      render: () => (
                          <FormattedAmount
                              variant="boldLabel"
                              amount={Math.abs(fiscalOperatingResult)}
                              currency="EUR"
                          />
                      )
                  },
                  // empty cell for alignment
                  {
                      render: () => {}
                  }
              ]
            : null;

    const competenceOperatingBalanceFooter =
        competenceOperatingResult > 0
            ? [
                  // label
                  {
                      kind: ColumnType.text,
                      render: () => (
                          <Typography variant="boldLabel">
                              {fs("competenceOperatingBalance")}
                          </Typography>
                      )
                  },
                  // empty cell for alignment
                  {
                      render: () => {}
                  },
                  // empty cell for alignment
                  {
                      render: () => {}
                  },
                  // total value
                  {
                      kind: ColumnType.numeric,
                      render: () => (
                          <FormattedAmount
                              variant="boldLabel"
                              amount={Math.abs(competenceOperatingResult)}
                              currency="EUR"
                          />
                      )
                  },
                  // empty cell for alignment
                  {
                      render: () => {}
                  }
              ]
            : null;

    const footer = compact([
        emptyRowFooter,
        tableFooter,
        fiscalOperatingResult > 0
            ? fiscalOperatingBalanceFooter
            : fiscalOperatingResult !== 0
            ? emptyRowFooter
            : null,
        competenceOperatingResult > 0
            ? competenceOperatingBalanceFooter
            : competenceOperatingResult !== 0
            ? emptyRowFooter
            : null
    ]);

    return (
        <HierarchyTable
            headCells={headCells}
            data={rows}
            parentColumns={parentColumns}
            parentRowKey="parentAccount"
            childrenColumns={columns}
            childrenRowKey="accounts"
            numRows={numRows}
            footer={footer}
        />
    );
}
