import { Spinner } from "@comic/precog-components";
import { Times } from "@vapor/react-icons";
import { Box, Divider, Stack } from "@vapor/react-material";
import { isEmpty } from "lodash";
import isNil from "lodash/isNil";
import { useEffect, useState } from "react";

import useGetAccountingPeriodDeferrals from "../../../core/usecases/useGetAccountingPeriodDeferrals";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import ErrorBanner from "../../components/ErrorBanner";
import LargeAmountWithLabel from "../../components/LargeAmountWithLabel";
import NotFoundBanner from "../../components/NotFoundBanner";
import OverlayDialog from "../../components/OverlayDialog";
import TitleCenteredDialog from "../../components/TitleCenteredDialog";
import DeferralsTable from "./DeferralsTable";

interface DeferredRevenuesAndExpensesOverlayProps {
    isOpen: boolean;
    onClose: () => void;
    periodId: string | null;
}

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.DeferredRevenuesAndExpensesOverlay"
);

export default function DeferredRevenuesAndExpensesOverlay({
    isOpen,
    onClose,
    periodId
}: DeferredRevenuesAndExpensesOverlayProps) {
    const {
        fetch: fetchDeferredRevenuesAndExpenses,
        loading: isFetchingDeferredRevenuesAndExpenses,
        data: deferredRevenuesAndExpenses,
        error: errorFetchingDeferredRevenuesAndExpenses
    } = useGetAccountingPeriodDeferrals({ lazy: true });

    const [hasInitialized, setHasInitialized] = useState<boolean>(false);

    useEffect(() => {
        if (!isOpen) {
            setHasInitialized(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (!hasInitialized && isOpen && !isNil(periodId)) {
            fetchDeferredRevenuesAndExpenses(periodId);
            setHasInitialized(true);
        }
    }, [hasInitialized, isOpen, periodId, fetchDeferredRevenuesAndExpenses]);

    return (
        <OverlayDialog open={isOpen} onClose={onClose}>
            <TitleCenteredDialog
                title={fs("deferrals")}
                icon={<Times />}
                onClose={onClose}
            />
            <Divider variant="fullWidth" />
            <Box>
                <Spinner loading={isFetchingDeferredRevenuesAndExpenses}>
                    <Box padding="32px">
                        {isNil(deferredRevenuesAndExpenses) ? (
                            <Box marginTop="40px"></Box>
                        ) : !isNil(errorFetchingDeferredRevenuesAndExpenses) ? (
                            <ErrorBanner
                                error={errorFetchingDeferredRevenuesAndExpenses}
                            />
                        ) : isEmpty(
                              deferredRevenuesAndExpenses.deferralList
                          ) ? (
                            <NotFoundBanner text={fs("noData")} />
                        ) : (
                            <>
                                <Stack
                                    direction="row"
                                    spacing="64px"
                                    marginBottom="32px"
                                >
                                    <LargeAmountWithLabel
                                        label={fs("totalDeferredExpenses")}
                                        labelProps={{
                                            marginBottom: "16px"
                                        }}
                                        amountProps={{
                                            amount: deferredRevenuesAndExpenses.deferredExpensesTotalAmount,
                                            currency: "EUR",
                                            color: "#005075"
                                        }}
                                    />
                                    <LargeAmountWithLabel
                                        label={fs("totalDeferredRevenues")}
                                        labelProps={{
                                            marginBottom: "16px"
                                        }}
                                        amountProps={{
                                            amount: deferredRevenuesAndExpenses.deferredRevenuesTotalAmount,
                                            currency: "EUR"
                                        }}
                                    />
                                </Stack>
                                <DeferralsTable
                                    deferrals={deferredRevenuesAndExpenses}
                                />
                            </>
                        )}
                    </Box>
                </Spinner>
            </Box>
        </OverlayDialog>
    );
}
