import { TableCell, TableRow } from "@vapor/react-material";
import { Account } from "../../../../core/usecases/dtos/ReportAccountingPeriodsDto";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import AccountType from "../../../components/AccountType";
import FormattedAmount from "../../../components/FormattedAmount";

interface CloseAndReopenAccountsLineProps {
    accounts: Account[];
}

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.CloseAndReopenAccountsTable"
);

export default function CloseAndReopenAccountsLine({
    accounts
}: CloseAndReopenAccountsLineProps) {
    return (
        <>
            {accounts.map((account) => {
                return (
                    <TableRow key={account.account.code}>
                        <TableCell> {account.account.title}</TableCell>
                        <TableCell>
                            <AccountType type={account.account.type} />{" "}
                            {fs(account.account.type)}
                        </TableCell>
                        <TableCell>
                            <FormattedAmount
                                currency={
                                    account.closingBalance.credit.currency
                                }
                                amount={
                                    account.closingBalance.credit.value -
                                    account.closingBalance.debit.value
                                }
                            />
                        </TableCell>
                        <TableCell>
                            {account.openingBalance.credit.value > 0 ? (
                                <FormattedAmount
                                    amount={account.openingBalance.credit.value}
                                    currency={
                                        account.openingBalance.credit.currency
                                    }
                                />
                            ) : (
                                "-"
                            )}
                        </TableCell>
                        <TableCell align="right">
                            {account.openingBalance.debit.value > 0 ? (
                                <FormattedAmount
                                    currency={
                                        account.openingBalance.debit.currency
                                    }
                                    amount={account.openingBalance.debit.value}
                                />
                            ) : (
                                "-"
                            )}
                        </TableCell>
                    </TableRow>
                );
            })}
        </>
    );
}
