import Drawer from "@vapor/react-material/Drawer";
import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";
import VaporHeaderBar from "@vapor/react-custom/VaporHeaderBar";
import IconButton from "@vapor/react-material/IconButton";
import Times from "@vapor/react-icons/Times";
import Box from "@vapor/react-material/Box";
import { VatSettlementDetail } from "../../../../../../../core/usecases/dtos/VatSettlementDto";
import VentilationTable from "./Table";
import { Typography } from "@vapor/react-extended";

const fs = getFormattedStringWithScope("views.VATSettlement.monoActivity");

interface VentilationDrawerProps {
    open: boolean;
    onClose: () => void;
    ventilations: VatSettlementDetail[];
}

export default function VentilationDrawer({
    open,
    onClose,
    ventilations
}: VentilationDrawerProps) {
    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            hideBackdrop
            width="50%"
        >
            <VaporHeaderBar
                divider={true}
                rightItems={[
                    <IconButton onClick={onClose} color="primary">
                        <Times />
                    </IconButton>
                ]}
                title={fs("receipt").toUpperCase()}
                size="small"
            />
            <Box marginLeft={4} marginTop={4}>
                <Typography variant={"titleSmall"}>
                    {fs("base_calc").toUpperCase()}
                </Typography>
            </Box>
            {ventilations?.map((ventilation: VatSettlementDetail) => (
                <Box margin={4}>
                    <Typography marginBottom={1} fontWeight={"bold"}>
                        {fs(ventilation.description)}
                    </Typography>
                    <VentilationTable data={ventilation} />
                </Box>
            ))}
        </Drawer>
    );
}
