import { Spinner } from "@comic/precog-components";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    useTheme
} from "@vapor/react-material";
import Box from "@vapor/react-material/Box";
import { capitalize } from "lodash";
import { useState } from "react";
import { Waypoint } from "react-waypoint";

import { AccountDto } from "../../../../core/usecases/dtos/AccountsDto";
import useDuplicateAccount from "../../../../core/usecases/useDuplicateAccount";
import { isBank, isChildAccount } from "../../../../utils/accountUtils";
import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";
import CustomButton from "../../../components/CustomButton";
import TableHeadCell from "../../../components/TableHead/TableHeadCell";
import TableHeadText from "../../../components/TableHead/TableHeadText";

interface DuplicateAccountDialogProps {
    account: AccountDto;
    open: boolean;
    onClose: () => void;
    onDuplicate: (account: AccountDto) => void;
    isDuplicating: boolean;
}
const DuplicateAccountDialog = ({
    account,
    open,
    onClose,
    isDuplicating,
    onDuplicate
}: DuplicateAccountDialogProps) => {
    const fs = useFormattedStringWithScope(
        "components.AccountsTable.DuplicateAccountDialog"
    );
    const [newDescription, setNewDescription] = useState<string>(
        capitalize(account.description ?? "")
    );

    const handleDuplicateAccount = () => {
        onDuplicate({
            ...account,
            title: newDescription,
            description: newDescription
        });
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    height: 295,
                    width: 696
                }
            }}
        >
            <DialogTitle>{fs("title")}</DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText variant="body1">
                    {account.code} - {capitalize(account.description)}
                </DialogContentText>
                <TextField
                    required
                    label="Descrizione"
                    value={newDescription}
                    onChange={(event) => setNewDescription(event.target.value)}
                    sx={{ mt: 2 }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    {fs("cancel")}
                </Button>
                <CustomButton
                    variant="contained"
                    loading={isDuplicating}
                    onClick={handleDuplicateAccount}
                >
                    {fs("duplicate")}
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

interface AccountMenuProps {
    account: AccountDto;
    onDuplicate: (account: AccountDto) => void;
}
const AccountMenu = ({ account, onDuplicate }: AccountMenuProps) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] =
        useState<boolean>(false);

    const fs = useFormattedStringWithScope(
        "components.AccountsTable.AccountMenu"
    );
    const { duplicate, loading: isDuplicatingAccount } = useDuplicateAccount();

    const canDuplicateAccount =
        !account.isSystem &&
        !isBank(account) &&
        !account.asset &&
        !isChildAccount(account);

    const handleDuplicateAccount = async (account: AccountDto) => {
        const duplicatedAccount = await duplicate(account);
        onDuplicate(duplicatedAccount);
    };

    if (canDuplicateAccount) {
        return (
            <>
                <IconButton
                    color="primary"
                    size="small"
                    onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                    sx={{
                        display: "block",
                        m: "auto"
                    }}
                >
                    <FontAwesomeIcon icon={faEllipsisV} />
                </IconButton>
                <Menu
                    open={!!menuAnchorEl}
                    anchorEl={menuAnchorEl}
                    onClose={() => setMenuAnchorEl(null)}
                >
                    <MenuItem
                        onClick={() => setIsDuplicateDialogOpen(true)}
                        disabled={isDuplicatingAccount}
                    >
                        {fs("duplicateAccount")}
                    </MenuItem>
                </Menu>
                <DuplicateAccountDialog
                    account={account}
                    open={isDuplicateDialogOpen}
                    onClose={() => setIsDuplicateDialogOpen(false)}
                    onDuplicate={handleDuplicateAccount}
                    isDuplicating={isDuplicatingAccount}
                />
            </>
        );
    }

    return null;
};

interface AccountsTableProps {
    accounts: AccountDto[];
    highlightedAccount: AccountDto | null;
    hasMore: boolean;
    loadMore: Function;
    isLoadingMore: boolean;
    onDuplicateAccount: (account: AccountDto) => void;
}

export default function AccountsTable({
    accounts,
    hasMore,
    loadMore,
    isLoadingMore,
    highlightedAccount,
    onDuplicateAccount
}: AccountsTableProps) {
    const fs = useFormattedStringWithScope("components.AccountsTable");
    const theme = useTheme();

    return (
        <Box marginRight="24px" marginTop="32px">
            <Table>
                <TableHead shadow>
                    <TableRow>
                        <TableHeadCell>
                            <TableHeadText>{fs("code")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell>
                            <TableHeadText>{fs("description")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell>
                            <TableHeadText>{fs("type")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell>
                            <TableHeadText>{fs("iresIrap")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell>
                            <TableHeadText>{fs("vat")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell>
                            <TableHeadText>{fs("standard")}</TableHeadText>
                        </TableHeadCell>
                        <TableHeadCell></TableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accounts.map((account) => {
                        const rowStyle =
                            highlightedAccount?.code === account.code
                                ? {
                                      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.primary}`
                                  }
                                : {};

                        return (
                            <TableRow key={account.code} sx={rowStyle}>
                                <TableCell size="small">
                                    {account.code}
                                </TableCell>
                                <TableCell size="small">
                                    {account.description}
                                </TableCell>
                                <TableCell size="small">
                                    {fs(`${account.type}`)}
                                </TableCell>
                                <TableCell size="small"></TableCell>
                                <TableCell size="small"></TableCell>
                                <TableCell size="small"></TableCell>
                                <TableCell size="small">
                                    <AccountMenu
                                        account={account}
                                        onDuplicate={onDuplicateAccount}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {isLoadingMore && (
                <Spinner loading={isLoadingMore}>
                    <Box marginTop="16px" width="100%" height="80px" />
                </Spinner>
            )}
            <Box marginTop="16px">
                <Waypoint
                    onEnter={() => hasMore && !isLoadingMore && loadMore()}
                />
            </Box>
        </Box>
    );
}
