import { Accordion } from "@comic/precog-components";

import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import Accounting from "../../../../components/Accounting";

interface AccountingLinesProps {
    draftId: string;
    isOpen: boolean;
    onClick: () => void;
    step: number;
}

const fs = getFormattedStringWithScope("views.NewRegistration.AccountingLines");

export default function AccountingLines({
    draftId,
    isOpen,
    onClick,
    step
}: AccountingLinesProps) {
    return (
        <Accordion
            title={`${step}. ${fs("title")}`}
            isOpen={isOpen}
            onClick={onClick}
        >
            <Accounting draftId={draftId} />
        </Accordion>
    );
}
