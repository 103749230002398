import { useRequest } from "@onefront/react-sdk";
import { useCallback, useState } from "react";

import { API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { DraftUsedGood } from "../dtos/DraftUsedGoodDto";

export type UpdateOptions = Partial<
    Pick<
        DraftUsedGood,
        | "fullDescription"
        | "identificationCode"
        | "quantity"
        | "increaseExistingGood"
        | "usedGoodsRegistryId"
    >
>;

export interface UsePatchDraftUsedGood {
    loading: boolean;
    error: any;
    result: any;
    fetch: Function;
    hasFetched: boolean;
    patchDraftUsedGoodParams: (
        usedGoodId?: string,
        draftId?: string,
        updateOptions?: UpdateOptions
    ) => Promise<void>;
    hasResponded: boolean;
}

export const usePatchDraftUsedGood = (): UsePatchDraftUsedGood => {
    const customerData = useCustomerData();
    const [hasResponded, setHasResponded] = useState<boolean>(false);

    const { fetch, data, loading, error, hasLoaded } = useRequest({
        ...useAxiosConfigRequestParams("UpdateDraftUsedGoods"),
        method: "PATCH",
        lazy: true
    });

    const safeFetch = useCallback(() => {
        setHasResponded(false);
        return fetch();
    }, [fetch]);

    const patchDraftUsedGoodParams = useCallback(
        (
            usedGoodId?: string,
            draftId?: string,
            dataToPatch?: UpdateOptions
        ) => {
            return fetch({
                url: `${API_URL}/v1/precog/drafts/${draftId}/usedGoods/${usedGoodId}`,
                params: {
                    ...customerData
                },
                data: {
                    ...dataToPatch
                }
            });
        },
        [fetch, customerData]
    );

    return {
        fetch: safeFetch,
        patchDraftUsedGoodParams,
        error: error,
        result: data,
        loading: loading,
        hasFetched: hasLoaded,
        hasResponded
    };
};
