import { Times } from "@vapor/react-icons";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    Divider,
    IconButton
} from "@vapor/react-material";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../../components/CustomButton";

interface DialogAlertCloseProps extends DialogProps {
    open: boolean;
    onClose: () => void;
    handleUpdatePeriod: () => void;
    handleClosePeriod: () => void;
}

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.CloseAndReopenAccountsOverlay.DialogAlert"
);

export default function DialogAlertClose({
    open,
    onClose,
    handleUpdatePeriod,
    handleClosePeriod
}: DialogAlertCloseProps) {
    return (
        <Dialog open={open}>
            <DialogTitle>
                {fs("title")}
                <IconButton color="primary" onClick={onClose}>
                    <Times />
                </IconButton>
            </DialogTitle>

            <Divider variant="fullWidth" />
            <DialogContent>
                <DialogContentText marginBottom={2}>
                    {fs("firstText")}
                </DialogContentText>
                <DialogContentText>{fs("secondText")}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButton
                    onClick={handleUpdatePeriod}
                    color="primary"
                    variant="outlined"
                >
                    {fs("buttonUpdate")}
                </CustomButton>
                <CustomButton
                    onClick={handleClosePeriod}
                    color="primary"
                    variant="contained"
                >
                    {fs("buttonClose")}
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
}
