import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { DraftDetail } from "../dtos/DraftDetail";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseGetPrecogDetail {
    loading: boolean;
    error: any;
    draftDetail: DraftDetail | null;
    get: (draftId: string) => Promise<DraftDetail>;
}

interface UseGetPrecogDetailOptions {
    draftId?: string;
    lazy?: boolean;
}

export const useGetPrecogDetail = ({
    draftId,
    lazy
}: UseGetPrecogDetailOptions): UseGetPrecogDetail => {
    const customerData = useCustomerData();

    const { fetch, data, loading, error } = useGet(
        `${API_URL}/v1/precog/drafts/${draftId}`,
        {
            ...useAxiosConfigRequestParams("PrecogDetail"),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: lazy
        }
    );

    const get = async (draftId: string) => {
        return fetch({
            url: `${API_URL}/v1/precog/drafts/${draftId}`,
            params: {
                ...customerData
            }
        }).then((response: AxiosResponse<DraftDetail>) => response.data);
    };

    return { get, loading: loading, error: error, draftDetail: data };
};
