import Typography from "@vapor/react-extended/ExtendedTypography";
import { Alert, AlertProps, Box } from "@vapor/react-material";
import { Snackbar } from "@vapor/react-material/Snackbar";
import isNil from "lodash/isNil";
import slice from "lodash/slice";
import uniqueId from "lodash/uniqueId";
import React, { useCallback, useContext, useState } from "react";

import { SNACKBAR_DURATION_MS } from "../../config";

interface SnackbarWithTitle {
    title: any;
    content: any;
}

type SnackbarContent = SnackbarWithTitle | any;

interface SnackbarElement {
    id: string;
    content: SnackbarContent;
    options: AlertProps;
}

interface SnackbarProviderState {
    snackbars: SnackbarElement[];
}

const SnackbarContext = React.createContext<{
    enqueueSnackbar: (content: SnackbarContent, options: AlertProps) => void;
    removeSnackbar: Function;
}>({
    enqueueSnackbar: () => {},
    removeSnackbar: () => {}
});

const SnackbarProvider = ({ children }: any) => {
    const [snackbarProviderState, setSnackbarProviderState] =
        useState<SnackbarProviderState>({ snackbars: [] });

    const removeSnackbar = useCallback((id: string) => {
        setSnackbarProviderState((state) => ({
            snackbars: state.snackbars.filter(
                (snackbar: SnackbarElement) => snackbar.id !== id
            )
        }));
    }, []);

    const enqueueSnackbar = useCallback(
        (content: SnackbarContent, options: AlertProps) => {
            const snackbarId = uniqueId();

            setSnackbarProviderState((state) => {
                const snackbars =
                    state.snackbars.length === 6
                        ? slice(state.snackbars, 1, state.snackbars.length)
                        : state.snackbars;

                return {
                    snackbars: [
                        ...snackbars,
                        {
                            id: snackbarId,
                            content: content,
                            options: options
                        }
                    ]
                };
            });

            setTimeout(() => {
                removeSnackbar(snackbarId);
            }, SNACKBAR_DURATION_MS);
        },
        [removeSnackbar]
    );

    return (
        <SnackbarContext.Provider value={{ enqueueSnackbar, removeSnackbar }}>
            <Box
                sx={{
                    zIndex: "10000"
                }}
            >
                {snackbarProviderState.snackbars.map(
                    (snackbar: SnackbarElement) => (
                        <Snackbar
                            key={snackbar.id}
                            open={true}
                            anchorOrigin={{
                                horizontal: "right",
                                vertical: "top"
                            }}
                            onClose={() => removeSnackbar(snackbar.id)}
                            autoHideDuration={SNACKBAR_DURATION_MS}
                            sx={{
                                zIndex: "10000"
                            }}
                        >
                            <Alert
                                {...snackbar.options}
                                onClose={() => removeSnackbar(snackbar.id)}
                                sx={{ width: "100%", marginTop: "30px" }}
                                variant="outlined"
                            >
                                {isNil(snackbar.content.title) ? null : (
                                    <Typography
                                        variant="boldLabel"
                                        marginRight="4px"
                                    >
                                        {snackbar.content.title}
                                    </Typography>
                                )}
                                {snackbar.content.content ?? snackbar.content}
                            </Alert>
                        </Snackbar>
                    )
                )}
            </Box>
            {children}
        </SnackbarContext.Provider>
    );
};

export const useSnackbar = () => {
    return useContext(SnackbarContext);
};

export const SnackbarService = () => ({
    target: "$REACT_ROOT_WRAPPER",
    handler: {
        component: ({ children }: any) => (
            <SnackbarProvider>{children}</SnackbarProvider>
        )
    }
});

export default SnackbarProvider;
