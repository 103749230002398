import { useRequest } from "@onefront/react-sdk";
import { useCallback } from "react";

import { format } from "date-fns";
import { API_DATE_FORMAT, ASYNC_API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

interface UsePostOnboarding {
    error: any;
    loading: boolean;
    onboard: () => Promise<void>;
}
interface UsePostOnboardingOptions {
    commencementDate: Date;
}
export default function usePostOnboarding({
    commencementDate
}: UsePostOnboardingOptions): UsePostOnboarding {
    const { enqueueSnackbar } = useSnackbar();
    const fs = useFormattedStringWithScope("notifications.PostOnboarding");

    const { fetch, loading, error } = useRequest({
        ...useAxiosConfigRequestParams("usePostOnboarding"),
        data: {
            ...omitNilProperties({
                ...useCustomerData()
            }),
            commencementDate: format(commencementDate, API_DATE_FORMAT)
        },
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        method: "post",
        lazy: true,
        url: `${ASYNC_API_URL}/onboarding`
    });

    const onboard = useCallback(async () => await fetch(), [fetch]);

    return {
        error: error,
        loading: loading,
        onboard: onboard
    };
}
