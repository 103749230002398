import { useGet, useSnackbar } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { Draft } from "../dtos/DashboardDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseGetDraft {
    loading: boolean;
    error: any;
    get: (draftId: string) => Promise<Draft>;
}

export const useGetDraft = (): UseGetDraft => {
    const { enqueueSnackbar } = useSnackbar();
    const fs = useFormattedStringWithScope("notifications.GetDraft");
    const customerData = useCustomerData();

    const { fetch, loading, error } = useGet("ignored", {
        ...useAxiosConfigRequestParams("GetDraft"),
        onError: () => {
            enqueueSnackbar(fs("error"), {
                variant: "error"
            });
        },
        lazy: true
    });

    const get = async (draftId: string) => {
        return fetch({
            url: `${API_URL}/v2/precog/dashboard-paginated/${draftId}`,
            params: omitNilProperties({
                ...customerData
            })
        }).then((response: AxiosResponse<Draft>) => response.data);
    };

    return {
        get,
        loading: loading,
        error: error
    };
};
