import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@vapor/react-material";
import { Dialog } from "@vapor/react-material/Dialog";
import { DialogActions } from "@vapor/react-material/DialogActions";
import { DialogContent } from "@vapor/react-material/DialogContent";
import { DialogContentText } from "@vapor/react-material/DialogContentText";
import { DialogTitle } from "@vapor/react-material/DialogTitle";
import { Divider } from "@vapor/react-material/Divider";
import { IconButton } from "@vapor/react-material/IconButton";

import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../../components/CustomButton";

interface DeleteDialogProps {
    isOpen: boolean;
    onDelete: () => void;
    onClose: () => void;
    isLoading: boolean;
    isXML?: boolean;
    isAccounted?: boolean;
}

const fs = getFormattedStringWithScope(
    "views.Dashboard.toBeAccounted.dialogDelete"
);

const DeleteDialog = ({
    isOpen,
    onDelete,
    onClose,
    isLoading,
    isXML,
    isAccounted
}: DeleteDialogProps) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                {fs("title")}
                <IconButton color="primary" onClick={onClose}>
                    <FontAwesomeIcon icon={faClose} />
                </IconButton>
            </DialogTitle>
            <Divider variant="fullWidth" />
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Box sx={{ minWidth: "100%", maxWidth: "100vw" }}>
                        {isAccounted
                            ? isXML
                                ? fs("xml")
                                : fs("accountedManual")
                            : fs("manual")}
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButton
                    onClick={onDelete}
                    variant="contained"
                    loading={isLoading}
                >
                    {fs("button.confirm")}
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;
