import { useGet } from "@onefront/react-sdk";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import { AxiosResponse } from "../interfaces/AxiosResponse";

import { ASYNC_API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { SetupStatusDto } from "../dtos/SetupStatusDto";

interface UseGetSetup {
    fetchStatus: () => Promise<SetupStatusDto>;
    error: any;
    loading: boolean;
}

export default function useGetSetup(): UseGetSetup {
    const fs = useFormattedStringWithScope("notifications.GetSetup");
    const { enqueueSnackbar } = useSnackbar();

    const { fetch, loading, error } = useGet(`${ASYNC_API_URL}/setup`, {
        ...useAxiosConfigRequestParams("useGetSetup"),
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        params: omitNilProperties({
            ...useCustomerData()
        }),
        lazy: true
    });

    return {
        error: error,
        fetchStatus: async () => {
            return fetch().then(
                (response: AxiosResponse<SetupStatusDto>) => response.data
            );
        },
        loading: loading
    };
}
