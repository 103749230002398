import { Accordion, Spinner } from "@comic/precog-components";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@vapor/react-material/Button";
import Dialog from "@vapor/react-material/Dialog";
import DialogActions from "@vapor/react-material/DialogActions";
import DialogContent from "@vapor/react-material/DialogContent";
import DialogTitle from "@vapor/react-material/DialogTitle";
import Divider from "@vapor/react-material/Divider";
import FormControl from "@vapor/react-material/FormControl";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import IconButton from "@vapor/react-material/IconButton";
import Radio from "@vapor/react-material/Radio";
import RadioGroup from "@vapor/react-material/RadioGroup";
import { isNil } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDraft } from "../../../core/domain/Draft/queries";

import { useExemptionNatures } from "../../../core/domain/ExemptionNatures/queries";
import {
    ExemptionNature,
    ExemptionNatureCode
} from "../../../core/usecases/dtos/ExemptionNaturesDto";
import { DraftSource, FactType } from "../../../utils/appEnums";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";

interface ExemptionNatureDialogProps {
    draftId: string;
    exemptionNature?: string;
    natureId?: string;
    isModalOpen: boolean;
    onClose: (hasSelected?: boolean) => void;
    handleSelectExemption: (selectedNature: ExemptionNature) => void;
}

const fs = getFormattedStringWithScope("components.ExemptionNatureDialog");

function filterExemptionNatures(
    exemptionNatures: ExemptionNature[] = [],
    { nature, isXML }: { nature?: string; isXML: boolean }
): ExemptionNature[] {
    let result = exemptionNatures;

    if (nature && isXML) {
        result = result?.filter((exemptionNature) => {
            return exemptionNature.nature === nature;
        });
    }

    return (
        result?.sort((a, b) => {
            if (a.nature < b.nature) {
                return -1;
            }
            if (a.nature > b.nature) {
                return 1;
            }
            return 0;
        }) ?? []
    );
}

export default function ExemptionNatureDialog({
    draftId,
    exemptionNature,
    natureId,
    isModalOpen,
    onClose,
    handleSelectExemption
}: ExemptionNatureDialogProps) {
    const { data: draft } = useDraft(draftId);
    const [opened, setOpened] = useState<string>(exemptionNature ?? "");
    const [selectedExemption, setSelectedExemption] =
        useState<ExemptionNature>();

    const { data: allExemptionNatures, isLoading } = useExemptionNatures();

    const exemptionNatures = useMemo(() => {
        return filterExemptionNatures(allExemptionNatures ?? [], {
            nature: exemptionNature,
            isXML: draft?.source === DraftSource.B2B
        });
    }, [allExemptionNatures, exemptionNature, draft?.source]);

    useEffect(() => {
        if (isModalOpen) {
            const presetExemption = exemptionNatures?.find(
                (nature) => nature.id === natureId
            );
            presetExemption &&
                setSelectedExemption(
                    exemptionNatures?.find((nature) => nature.id === natureId)
                );
        }
    }, [exemptionNatures, natureId, isModalOpen]);

    const addDisableExemption = (exemption: ExemptionNature) => {
        const disableN5 =
            exemption.id === ExemptionNatureCode.NVI000 &&
            draft?.simplifiedInvoice === false &&
            draft?.source === DraftSource.B2B &&
            draft.factType === FactType.INVOICE;

        return { ...exemption, disabled: disableN5 };
    };

    const groupedRates = () => {
        let objs = {} as {
            [key: string]: {
                description: string;
                articles: ExemptionNature[];
            };
        };
        let items = [];
        exemptionNatures?.map((exemption: ExemptionNature) => {
            if (objs && !objs[exemption?.nature]) {
                objs[exemption?.nature] = {
                    description: exemption.nature,
                    articles: []
                };
            }

            return objs[exemption?.nature].articles.push(
                addDisableExemption(exemption)
            );
        });
        for (let obj in objs) {
            if (objs && objs.hasOwnProperty(obj)) {
                items.push(objs[obj]);
            }
        }
        return items;
    };

    return (
        <Dialog
            open={isModalOpen}
            onClose={() => onClose()}
            sx={{ zIndex: "100000" }}
        >
            <DialogTitle>
                {fs("rate")}
                <IconButton color="primary" onClick={() => onClose()}>
                    <FontAwesomeIcon icon={faTimes} />
                </IconButton>
            </DialogTitle>
            <Divider variant="fullWidth" />
            <DialogContent>
                <Spinner loading={isLoading}>
                    {groupedRates().map((exemption) => {
                        return (
                            <Accordion
                                key={exemption.description}
                                isOpen={opened === exemption.description}
                                onClick={() => setOpened(exemption.description)}
                                title={exemption.description}
                                description={
                                    exemption.articles.length + " Esenzioni"
                                }
                            >
                                <FormControl>
                                    <RadioGroup
                                        defaultValue={selectedExemption?.id}
                                    >
                                        {exemption.articles.map((it) => {
                                            return (
                                                <FormControlLabel
                                                    key={it.id}
                                                    control={
                                                        <Radio
                                                            onClick={() =>
                                                                setSelectedExemption(
                                                                    it
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={it.description}
                                                    value={it.id}
                                                    checked={
                                                        it.id ===
                                                        selectedExemption?.id
                                                    }
                                                    disabled={it.disabled}
                                                />
                                            );
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </Accordion>
                        );
                    })}
                </Spinner>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => onClose()}>
                    {fs("cancel")}
                </Button>
                <Button
                    variant="contained"
                    disabled={isNil(selectedExemption)}
                    onClick={() => {
                        if (selectedExemption) {
                            handleSelectExemption(selectedExemption);
                            onClose(true);
                        } else {
                            onClose();
                        }
                    }}
                >
                    {fs("save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
