import Typography from "@vapor/react-extended/ExtendedTypography";
import { Box } from "@vapor/react-material/Box";

import { VatSettlement } from "../../../../../../core/usecases/interfaces/VatSettlement";
import getFormattedStringWithScope from "../../../../../../utils/getFormattedStringWithScope";
import InfoRecap from "./infoRecap";
import MultiActivityTable from "./Table";

const fs = getFormattedStringWithScope("commons.period");

interface MultiActivityProps {
    settlement: VatSettlement;
    onSelectBusiness: (id: string) => void;
}

const MultiActivities = ({
    settlement,
    onSelectBusiness
}: MultiActivityProps) => {
    const businessSettlements = settlement.businessSettlements;

    return (
        <Box>
            <Box paddingBottom="16px">
                <Typography
                    variant="bodyLarge500"
                    color="primary.textTitleColor"
                >
                    {`${fs(settlement.period.ordinal)} ${
                        settlement.period.year
                    }`}
                </Typography>
            </Box>
            <Box marginBottom="8px">
                <InfoRecap settlement={settlement} />
            </Box>
            <MultiActivityTable
                onSelectBusiness={onSelectBusiness}
                data={businessSettlements}
            />
        </Box>
    );
};

export default MultiActivities;
