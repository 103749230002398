import { create } from "zustand";

import { AccountDto } from "../../../../core/usecases/dtos/AccountsDto";

type State = {
    addedAccounts: AccountDto[];
};

type Actions = {
    addAccount: (account: AccountDto) => void;
    removeAccount: (code: string) => void;
    clearAccounts: () => void;
};

const useAddedAccountsStore = create<State & Actions>((set) => ({
    addedAccounts: [],
    addAccount: (account) =>
        set((state) => ({
            addedAccounts: Array.from(
                new Set([...state.addedAccounts, account])
            )
        })),
    removeAccount: (account) =>
        set((state) => ({
            addedAccounts: state.addedAccounts.filter(
                (addedAccount) => addedAccount.code !== account
            )
        })),
    clearAccounts: () => set({ addedAccounts: [] })
}));

export const useAddedAccounts = () => {
    return useAddedAccountsStore((state) => state.addedAccounts);
};

export const useAddAccountToDraft = () => {
    return useAddedAccountsStore((state) => state.addAccount);
};

export const useRemoveAccountFromDraft = () => {
    return useAddedAccountsStore((state) => state.removeAccount);
};

export const useClearAddedAccounts = () => {
    return useAddedAccountsStore((state) => state.clearAccounts);
};
