import { useGet, useSnackbar } from "@onefront/react-sdk";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AccountedForResponse } from "../dtos/AccountedForDto";
import { concatenateData, processData } from "./processData";

interface UseGetLedgerAccountedEntries {
    loading: boolean;
    loadingMore: boolean;
    hasMore: boolean;
    error: any;
    accountedForData: AccountedForResponse | null;
    fetch: Function;
    fetchMore: Function;
    hasFetched: boolean;
}

export interface UseGetLedgerAccountedEntriesFilters {
    fromDocDate?: Date | null;
    toDocDate?: Date | null;
    fromDate?: Date | null;
    toDate?: Date | null;
    includeSections?: string[] | null;
}

interface UseGetLedgerAccountedEntriesOptions {
    filters?: UseGetLedgerAccountedEntriesFilters;
    lazy: boolean;
}

const FETCH_MORE_URL_KEY = "_links.next.href";

export const useGetLedgerAccountedEntries = ({
    filters,
    lazy
}: UseGetLedgerAccountedEntriesOptions): UseGetLedgerAccountedEntries => {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope(
        "notifications.GetLedgerAccountedEntries"
    );

    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/ledger/accountedEntries`,
        {
            ...useAxiosConfigRequestParams("LedgerAccountedEntries"),
            onError: () => enqueueSnackbar(fs("error"), { variant: "error" }),

            params: omitNilProperties({
                ...useCustomerData(),
                fromDocDate:
                    filters && filters.fromDocDate && isValid(filters.fromDate)
                        ? format(filters.fromDocDate, "yyyy-MM-dd")
                        : undefined,
                toDocDate:
                    filters && filters.toDocDate && isValid(filters.fromDate)
                        ? format(filters.toDocDate, "yyyy-MM-dd")
                        : undefined,
                fromDate:
                    filters && filters.fromDate && isValid(filters.fromDate)
                        ? format(filters.fromDate, "yyyy-MM-dd")
                        : undefined,
                toDate:
                    filters &&
                    filters.fromDate &&
                    filters.toDate &&
                    isValid(filters.toDate)
                        ? format(filters.toDate, "yyyy-MM-dd")
                        : undefined,
                includeSections: filters && filters.includeSections?.join(",")
            }),
            lazy: lazy
        }
    );

    const next = get(data, "_links.next");
    const continuationUrl = get(data, FETCH_MORE_URL_KEY);
    const {
        fetch: fetchMore,
        data: moreData,
        loading: loadingMore
        // error: erroLoadingMore
    } = useGet(continuationUrl, {
        ...useAxiosConfigRequestParams("GetLedgerAccountedEntries"),
        onError: () =>
            enqueueSnackbar(fs("errorLoadingMore"), { variant: "error" }),
        lazy: lazy
    });

    const processedData = processData(data);
    const processedMoreData = processData(moreData);
    const concatenatedData = concatenateData(processedData, processedMoreData);

    const safeFetch = useCallback(() => fetch(), [fetch]);
    const safeFetchMore = useCallback(() => {
        if (!isNil(continuationUrl)) fetchMore();
    }, [fetchMore, continuationUrl]);

    return {
        fetch: safeFetch,
        fetchMore: safeFetchMore,
        loading: loading,
        loadingMore: loadingMore,
        hasMore: !isNil(next),
        error: error,
        accountedForData: concatenatedData,
        hasFetched: hasLoaded
    };
};
