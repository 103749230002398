import { isNil } from "lodash";
import { isWithinInterval } from "date-fns";
import { AssetLineDto } from "../core/usecases/dtos/AssetLinesDto";

export enum TaxRelief {
    BALANCE_LAW_2016 = "2016_BALANCE_LAW",
    BALANCE_LAW_2017 = "2017_BALANCE_LAW",
    BALANCE_LAW_2018 = "2018_BALANCE_LAW",
    BALANCE_LAW_2019 = "2019_BALANCE_LAW",
    GROWTH_DECREE_2019 = "2019_GROWTH_DECREE",
    BALANCE_LAW_2020 = "2020_BALANCE_LAW",
    STABILITY_LAW_2021 = "2021_STABILITY_LAW",
    STABILITY_LAW_2021_BIS = "2021_STABILITY_LAW_BIS",
    STABILITY_LAW_2022 = "2022_STABILITY_LAW"
}

export enum TaxReliefType {
    BASE = "BASE",
    BASE4 = "BASE4",
    SW4 = "SW4",
    BASE_SMARTWORKING = "BASE_SMARTWORKING",
    MATERIAL_GOODS_ECO4 = "MATERIAL_GOODS_ECO4"
}

export function showTaxReliefAmount(x: TaxRelief) {
    return [
        TaxRelief.BALANCE_LAW_2020,
        TaxRelief.STABILITY_LAW_2021_BIS,
        TaxRelief.STABILITY_LAW_2021,
        TaxRelief.STABILITY_LAW_2022
    ].includes(x);
}
export const rangeDateTaxRelief = new Map<
    TaxRelief,
    { start: Date; end: Date }
>([
    [
        TaxRelief.BALANCE_LAW_2016,
        { start: new Date("2015-10-15T00:00:00"), end: new Date("2016-12-31") }
    ],
    [
        TaxRelief.BALANCE_LAW_2017,
        { start: new Date("2017-01-01T00:00:00"), end: new Date("2017-12-31") }
    ],
    [
        TaxRelief.BALANCE_LAW_2018,
        { start: new Date("2018-01-01T00:00:00"), end: new Date("2018-12-31") }
    ],
    [
        TaxRelief.BALANCE_LAW_2019,
        { start: new Date("2019-01-01T00:00:00"), end: new Date("2019-12-31") }
    ],
    [
        TaxRelief.GROWTH_DECREE_2019,
        { start: new Date("2019-04-01T00:00:00"), end: new Date("2019-12-31") }
    ],
    [
        TaxRelief.BALANCE_LAW_2020,
        { start: new Date("2020-01-01T00:00:00"), end: new Date("2020-11-15") }
    ],
    [
        TaxRelief.STABILITY_LAW_2021,
        { start: new Date("2021-11-16T00:00:00"), end: new Date("2021-12-31") }
    ],
    [
        TaxRelief.STABILITY_LAW_2021_BIS,
        { start: new Date("2022-01-01T00:00:00"), end: new Date("2023-11-30") }
    ],
    [
        TaxRelief.STABILITY_LAW_2022,
        { start: new Date("2023-01-01T00:00:00"), end: new Date("2025-12-31") }
    ]
]);

export function isInRangeTaxReliefDate(
    date: Date,
    selectedTaxBreak: TaxRelief
) {
    const range = rangeDateTaxRelief.get(selectedTaxBreak);
    if (range) {
        return isWithinInterval(date, range);
    }
    return false;
}

export function getAssetsInGroup(
    assetLine: AssetLineDto,
    assets: AssetLineDto[],
    selectedAssetIds: string[]
) {
    const isAssetLineSelected = selectedAssetIds.includes(assetLine.uuid);

    return isNil(assetLine.parentId)
        ? isAssetLineSelected
            ? assets.filter((asset) => selectedAssetIds.includes(asset.uuid))
            : []
        : assets.filter(
              (asset) =>
                  !isNil(asset.parentId) &&
                  asset.parentId === assetLine.parentId
          );
}
