import { useEffect, useState } from "react";

import { isNil } from "lodash";
import usePrevious from "../../../../../core/commons/hooks/usePrevious";
import { AssetDetailDto } from "../../../../../core/usecases/dtos/AssetDetailDto";
import { AssetSubkindDto } from "../../../../../core/usecases/dtos/AssetSubkindsDto";
import { useGetAssetRelations } from "../../../../../core/usecases/useGetAssetRelations";
import { useGetSubkinds } from "../../../../../core/usecases/useGetSubkinds";
import AssetRegistry from "../../../../components/Assets/AssetRegistry";
import { ReducedDeductibilityTypes } from "../../../Assets/Registry";
import "../style.css";

interface RegistryTabProps {
    open: boolean;
    assetDetail: AssetDetailDto | null;
    setSelectedSubkindCode: Function;
    showTabs: boolean;
    setShowTabs: (showTabs: boolean) => void;
}

export default function RegistryTab({
    open,
    assetDetail,
    setSelectedSubkindCode,
    showTabs,
    setShowTabs
}: RegistryTabProps) {
    const [selectedSubkind, setSelectedSubkind] = useState<string | null>(null);
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const [openAccountRelations, setOpenAccountRelations] =
        useState<boolean>(false);

    const [showDependentMenus, setShowDependentMenus] =
        useState<boolean>(false);

    const previousAccountCode = usePrevious(assetDetail?.accountCode);

    const isReducedDeductibility = !!assetDetail?.reducedDeductibility;

    const { fetch: fetchSubkinds, subkinds } = useGetSubkinds({
        accountCode: assetDetail?.accountCode,
        group: assetDetail?.group,
        kind: assetDetail?.kind,
        lazy: true
    });

    const {
        fetch: fetchAccountRelations,
        data,
        loading
    } = useGetAssetRelations({
        accountCode: assetDetail?.accountCode,
        lazy: true
    });

    useEffect(() => {
        if (
            !hasInitialized &&
            open &&
            !isNil(assetDetail?.group) &&
            !isNil(assetDetail?.kind)
        ) {
            fetchSubkinds();
            setHasInitialized(true);
        }
    }, [
        open,
        hasInitialized,
        fetchSubkinds,
        assetDetail?.group,
        assetDetail?.kind
    ]);

    useEffect(() => {
        if (
            !loading &&
            !isNil(assetDetail?.accountCode) &&
            previousAccountCode !== assetDetail?.accountCode
        ) {
            fetchAccountRelations();
        }
    }, [
        loading,
        assetDetail?.accountCode,
        fetchAccountRelations,
        previousAccountCode
    ]);

    useEffect(() => {
        if (assetDetail?.reducedDeductibility) {
            setShowDependentMenus(
                [
                    ReducedDeductibilityTypes.EMPLOYEE,
                    ReducedDeductibilityTypes.ADMINISTRATOR,
                    ReducedDeductibilityTypes.BUILDING_AND_LAND
                ].includes(
                    assetDetail?.reducedDeductibility as ReducedDeductibilityTypes
                )
            );
        }
    }, [assetDetail?.reducedDeductibility]);

    useEffect(() => {
        const subkind = (list: AssetSubkindDto[], subKind: string) => {
            return (
                list && list.find((subkind: any) => subkind.subKind === subKind)
            );
        };

        const favouriteSubkind = subkind(
            subkinds?.favourites ?? [],
            assetDetail?.subKind ?? ""
        );
        const otherSubkind = subkind(
            subkinds?.others ?? [],
            assetDetail?.subKind ?? ""
        );
        if (favouriteSubkind) {
            setSelectedSubkind(favouriteSubkind.subKind);
            setSelectedSubkindCode(favouriteSubkind.subKind);
        } else if (otherSubkind) {
            setSelectedSubkind(otherSubkind.subKind);
            setSelectedSubkindCode(otherSubkind.subKind);
        } else {
            setSelectedSubkind(null);
        }
    }, [assetDetail, setSelectedSubkindCode, subkinds]);

    const handleOpenAccountRelationsTab = () => {
        setOpenAccountRelations(!openAccountRelations);
        setShowTabs(!showTabs);
    };

    return (
        <AssetRegistry
            openAccountRelations={openAccountRelations}
            data={data}
            onOpenAccountRelationsTab={handleOpenAccountRelationsTab}
            selectedSubkindId={selectedSubkind}
            isReducedDeductibility={isReducedDeductibility}
            readOnly={true}
            assetDetail={assetDetail}
            subspeciesList={subkinds}
            showUsageMenu={
                assetDetail?.reducedDeductibility as ReducedDeductibilityTypes
            }
            showDependentMenus={showDependentMenus}
        />
    );
}
