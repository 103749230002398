import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useGet, useRequest } from "@onefront/react-sdk";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_URL } from "../../../config";
import { PaymentType } from "../../../utils/appEnums";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { CreatePaymentsData } from "../../usecases/dtos/CreatePaymentsDto";
import { FiscalPayment } from "../../usecases/dtos/FiscalPaymentDto";

// API

interface GetFiscalPaymentOptions {
    draftId: string;
    active?: boolean;
    businessId?: string;
    factType?: string;
    reasonId?: string;
    types?: PaymentType[];
}
const useGetFiscalPayment = () => {
    const customerData = useCustomerData();

    const { fetch } = useGet<FiscalPayment[]>(`${API_URL}/v1/fiscal-payment`, {
        ...useAxiosConfigRequestParams("FiscalPayments"),
        lazy: true
    });

    const get = ({
        active,
        businessId,
        draftId,
        factType,
        reasonId,
        types
    }: GetFiscalPaymentOptions) =>
        fetch({
            params: omitNilProperties({
                ...customerData,
                active: active,
                businessId: businessId,
                draftId: draftId,
                factType: factType,
                reasonId: reasonId,
                types: types?.join(",")
            })
        }).then(({ data }) => data);

    return {
        get
    };
};

type CreateFiscalPaymentParams = {
    draftId: string;
    data: CreatePaymentsData[];
    overWrite?: boolean;
};
const useCreateFiscalPaymentAPI = () => {
    const customerData = useCustomerData();

    const { fetch } = useRequest({
        method: "POST",
        ...useAxiosConfigRequestParams("FiscalPayment"),
        lazy: true
    });

    const create = async ({
        data,
        draftId,
        overWrite = false
    }: CreateFiscalPaymentParams) =>
        fetch({
            url: `${API_URL}/v1/precog/drafts/${draftId}/fiscalPayments`,
            params: {
                ...customerData
            },
            data: {
                cleanPreviousAssociations: overWrite,
                payments: data
            }
        });

    return {
        fetch: create
    };
};

type PatchFiscalPaymentParams = {
    registeredPaymentId?: string;
    amount?: number;
    type?: string;
};
const usePatchFiscalPayment = () => {
    const customerData = useCustomerData();

    const { fetch } = useRequest({
        method: "PATCH",
        ...useAxiosConfigRequestParams("Payments"),
        lazy: true
    });

    const patch = async (
        draftId: string,
        fiscalPaymentId: string,
        data: PatchFiscalPaymentParams
    ) =>
        fetch({
            url: `${API_URL}/v1/precog/drafts/${draftId}/fiscalPayments/${fiscalPaymentId}`,
            params: {
                ...customerData
            },
            data
        });

    return {
        patch
    };
};

type DeleteFiscalPaymentParams = {
    draftId: string;
    fiscalPaymentIds: string | string[];
};
const useDeleteFiscalPaymentAPI = () => {
    const customerData = useCustomerData();

    const { fetch } = useRequest({
        method: "DELETE",
        ...useAxiosConfigRequestParams("Payments"),
        lazy: true
    });

    const deleteFiscalPayment = async ({
        draftId,
        fiscalPaymentIds
    }: DeleteFiscalPaymentParams) =>
        fetch({
            url: `${API_URL}/v1/precog/drafts/${draftId}/fiscalPayments`,
            params: {
                ...customerData
            },
            data: Array.isArray(fiscalPaymentIds)
                ? fiscalPaymentIds
                : [fiscalPaymentIds]
        });

    return {
        fetch: deleteFiscalPayment
    };
};

// HOOKS

export const fiscalPaymentsQueryKeys = createQueryKeys("fiscal-payments", {
    all: null,
    get: (params: GetFiscalPaymentOptions) => [params.draftId, { ...params }]
});

export const useFiscalPayments = (params: GetFiscalPaymentOptions) => {
    const { get } = useGetFiscalPayment();

    const { queryKey } = fiscalPaymentsQueryKeys.get(params);

    return useQuery({
        enabled: !!params.active,
        queryKey,
        queryFn: () => get(params),
        initialData: []
    });
};

export const useCreateFiscalPayment = () => {
    const queryClient = useQueryClient();
    const { fetch } = useCreateFiscalPaymentAPI();

    return useMutation({
        mutationFn: (params: CreateFiscalPaymentParams) => fetch(params),
        onMutate: async (params) => {
            // optimistically set "saved" to true
            const previousData = queryClient.getQueriesData<FiscalPayment[]>({
                exact: false,
                predicate: (query) => {
                    return (
                        query.queryKey.includes(params.draftId) &&
                        query.queryKey.includes("fiscal-payments")
                    );
                }
            });

            if (!previousData || previousData.length === 0) return;

            const newPaymentsIds = params.data.map(
                (payment) => payment.registeredPaymentId
            );

            for (const [queryKey] of previousData) {
                queryClient.setQueryData(queryKey, (old: FiscalPayment[]) => {
                    return old.map((payment) => {
                        if (newPaymentsIds.includes(payment.key)) {
                            return {
                                ...payment,
                                saved: true
                            };
                        }
                        return payment;
                    });
                });
            }

            return { previousData };
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries({
                exact: false,
                predicate: (query) => {
                    return (
                        query.queryKey.includes("fiscal-payments") &&
                        query.queryKey.includes(variables.draftId)
                    );
                }
            });
        }
    });
};

export const useMutateFiscalPayment = (params: {
    draftId: string;
    fiscalPaymentId: string;
}) => {
    const queryClient = useQueryClient();
    const { patch } = usePatchFiscalPayment();

    return useMutation({
        mutationFn: (data: Omit<PatchFiscalPaymentParams, "draftId">) =>
            patch(params.draftId, params.fiscalPaymentId, data),
        onSettled: () => {
            queryClient.invalidateQueries({
                exact: false,
                predicate: (query) => {
                    return (
                        query.queryKey.includes("fiscal-payments") &&
                        query.queryKey.includes(params.draftId)
                    );
                }
            });
        }
    });
};

export const useDeleteFiscalPayment = () => {
    const queryClient = useQueryClient();
    const { fetch } = useDeleteFiscalPaymentAPI();

    return useMutation({
        mutationFn: (params: DeleteFiscalPaymentParams) => fetch(params),
        onMutate: async (params) => {
            // optimistically set "saved" to false
            const previousData = queryClient.getQueriesData<FiscalPayment[]>({
                exact: false,
                predicate: (query) => {
                    return (
                        query.queryKey.includes(params.draftId) &&
                        query.queryKey.includes("fiscal-payments")
                    );
                }
            });

            if (!previousData || previousData.length === 0) return;

            for (const [queryKey] of previousData) {
                queryClient.setQueryData(queryKey, (old: FiscalPayment[]) => {
                    return old.map((payment) => {
                        if (
                            params.fiscalPaymentIds.includes(
                                payment.fiscalPaymentId
                            )
                        ) {
                            return {
                                ...payment,
                                saved: false
                            };
                        }
                        return payment;
                    });
                });
            }

            return { previousData };
        },
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries({
                exact: false,
                predicate: (query) => {
                    return (
                        query.queryKey.includes("fiscal-payments") &&
                        query.queryKey.includes(variables.draftId)
                    );
                }
            });
        }
    });
};
