import { Spinner } from "@comic/precog-components";
import { OneBoxPage, useCurrentWorkspace } from "@drift/oneplatfront";
import ExtendedTab from "@vapor/react-extended/ExtendedTab";
import ExtentedTabs from "@vapor/react-extended/ExtendedTabs";
import Box from "@vapor/react-material/Box";
import { useEffect, useState } from "react";

import usePrevious from "../../../core/commons/hooks/usePrevious";
import { useWithholdings } from "../../../core/contexts/Withholdings";
import { WithholdingType } from "../../../utils/appEnums";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import BackArrow from "../../components/BackArrow";
import WithholdingsTable from "./WithholdingsTable";

const fs = getFormattedStringWithScope("views.Withholdings");

export default function Withholdings() {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const [withholdingType, setWithholdingType] =
        useState<WithholdingType>("ACTIVE");
    const [isTabClicked, setIsTabClicked] = useState<boolean>(false);

    const { get, withholdings, isLoading } = useWithholdings().accounting;

    const { workspace: customer } = useCurrentWorkspace(true);
    const previousCustomer = usePrevious(customer);
    const previousWithholdingType = usePrevious(withholdingType);

    useEffect(() => {
        if (previousCustomer !== customer) {
            setHasInitialized(false);
        }
    }, [previousCustomer, customer]);

    useEffect(() => {
        if (
            (customer && !hasInitialized) ||
            previousWithholdingType !== withholdingType
        ) {
            get(withholdingType);
            setHasInitialized(true);
        }
    }, [
        get,
        hasInitialized,
        customer,
        previousWithholdingType,
        withholdingType
    ]);

    const handleTabChange = (newValue: WithholdingType) => {
        setWithholdingType(newValue);
        setIsTabClicked(true);
    };

    useEffect(() => {
        if (
            hasInitialized &&
            !isTabClicked &&
            withholdings &&
            withholdings?.length === 0
        ) {
            setWithholdingType("PASSIVE");
        }
    }, [hasInitialized, withholdings, withholdings?.length, isTabClicked]);

    return (
        <OneBoxPage headerLeft={<BackArrow />} title={fs("title")}>
            <Box margin="24px" marginTop="0">
                {withholdings ? (
                    <Box>
                        <Spinner loading={isLoading}>
                            <ExtentedTabs
                                variant="standard"
                                size="small"
                                value={withholdingType}
                                onChange={(_, value) =>
                                    handleTabChange(value as WithholdingType)
                                }
                            >
                                <ExtendedTab
                                    value="ACTIVE"
                                    label={fs("active")}
                                />
                                <ExtendedTab
                                    value="PASSIVE"
                                    label={fs("passive")}
                                />
                            </ExtentedTabs>
                            {withholdingType === "ACTIVE" && (
                                <WithholdingsTable
                                    withholdingType={withholdingType}
                                    withholding={withholdings}
                                />
                            )}
                            {withholdingType === "PASSIVE" && (
                                <WithholdingsTable
                                    withholdingType={withholdingType}
                                    withholding={withholdings}
                                />
                            )}
                        </Spinner>
                    </Box>
                ) : null}
            </Box>
        </OneBoxPage>
    );
}
