export interface CounterPartInfo {
    name: string;
    identifier: {
        taxId: string;
        vatNumber: string;
    };
}

export interface Doc {
    date: string;
    number: string;
}

export interface TotalAmount {
    value: number;
    currency: string;
}

export interface Entries {
    id: string;
    originalEntryId: string;
    postingDate: string;
    reasonId: reasonId;
    reasonDescription: string;
    totalAmount: TotalAmount;
    doc: Doc;
    customer: CounterPartInfo;
    supplier: CounterPartInfo;
}

export enum includeSectionsEnum {
    COMPETENCE_DATE = "COMPETENCE_DATE",
    WITHHOLDING = "WITHHOLDING",
    ASSET = "ASSET",
    INTRASTAT = "INTRASTAT",
    REVERSE_CHARGE = "REVERSE_CHARGE"
}
export enum reasonId {
    FE1 = "FE1",
    FE3 = "FE3",
    FE4 = "FE4",
    FE401 = "FE401",
    FE5 = "FE5",
    FE7 = "FE7",
    FE8 = "FE8",
    FE9 = "FE9",
    FE10 = "FE10",
    FA1 = "FA1",
    FA3 = "FA3",
    FA4 = "FA4",
    FA401 = "FA401",
    FA5 = "FA5",
    FA7 = "FA7",
    FA8 = "FA8",
    RF1 = "RF1",
    RAC1 = "RAC1",
    RG1 = "RG1",
    PF1 = "PF1",
    PAF1 = "PAF1",
    CG1 = "CG1",
    CR1 = "CR1",
    GC1 = "GC1",
    PF24 = "PF24",
    MG1 = "MG1",
    CO1 = "CO1",
    PB1 = "PB1",
    VB1 = "VB1",
    IP1 = "IP1",
    MI1 = "MI1",
    PS1 = "PS1"
}

export interface Links {
    next: Next;
}

export interface Next {
    href: string;
}

export interface AccountedForResponse {
    entries: Entries[];
    _link?: Links;
    workspaceId: string;
}
