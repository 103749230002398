import { ExtendedTab, ExtendedTabs } from "@vapor/react-extended";
import { Stack } from "@vapor/react-material";

import { ProcedureEntryDetail } from "../../../../core/usecases/dtos/ProcedureEntryDetail";
import useFormattedStringWithScope from "../../../../utils/useFormattedStringWithScope";
import ProcedureEntryAccoutingLinesTable from "./ProcedureEntryAccoutingLinesTable";

interface ProcedureEntryDetailAccountingProps {
    detail: ProcedureEntryDetail;
}

export default function ProcedureEntryDetailAccounting({
    detail
}: ProcedureEntryDetailAccountingProps) {
    const currentTab = "ACCOUNTING";

    const fs = useFormattedStringWithScope(
        "components.ProcedureEntryDetail.Accounting"
    );

    return (
        <Stack spacing={3}>
            <ExtendedTabs value={currentTab}>
                <ExtendedTab
                    label={fs("accounting")}
                    value={currentTab}
                ></ExtendedTab>
            </ExtendedTabs>
            <ProcedureEntryAccoutingLinesTable lines={detail.lines} />
        </Stack>
    );
}
