import { useRequest } from "@onefront/react-sdk";
import { pick } from "lodash";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { FiscalPayment } from "../dtos/FiscalPaymentDto";

interface SettleAccountPayment extends FiscalPayment {
    amount: number;
}

interface SettleAccountOptions {
    businessId: string;
    accountCode: string;
    payments: SettleAccountPayment[];
}

export interface UseSettleAccount {
    loading: boolean;
    settleAccount: (options: SettleAccountOptions) => Promise<void>;
}

export const useSettleAccount = (): UseSettleAccount => {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.SettleAccount");

    const customerData = useCustomerData();

    const { fetch: fetchData, loading } = useRequest({
        url: `${API_URL}/v1/precog/drafts/settle-account`,
        method: "post",
        ...useAxiosConfigRequestParams("SettleAccount"),
        onResponse: () =>
            enqueueSnackbar(fs("success"), { severity: "success" }),
        onError: () => enqueueSnackbar(fs("error"), { severity: "error" }),
        lazy: true
    });

    const settleAccount = useCallback(
        ({ accountCode, businessId, payments }: SettleAccountOptions) => {
            return fetchData({
                params: {
                    ...customerData
                },
                data: {
                    accountCode,
                    businessId,
                    invoiceDataList: payments.map((payment) => ({
                        ...pick(
                            payment,
                            "reasonId",
                            "currency",
                            "sectionalId",
                            "taxId",
                            "vatNumber",
                            "businessName",
                            "docNumber",
                            "amount"
                        ),
                        registeredPaymentId: payment.key,
                        currency: payment.totalDueAmount.currency,
                        docDate: payment.date
                    }))
                }
            });
        },
        [fetchData, customerData]
    );

    return {
        settleAccount,
        loading: loading
    };
};
