import { useGet, useSnackbar } from "@onefront/react-sdk";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { BusinessFlatDto } from "../dtos/BusinessFlatDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseGetWorkspaceBusinessFlat {
    loading: boolean;
    error: any;
    data: BusinessFlatDto[] | null;
    fetch: Function;
    get: (reasonId?: string) => Promise<BusinessFlatDto[] | null>;
    hasFetched: boolean;
}

interface UseGetWorkspaceBusinessFlatOptions {
    lazy: boolean;
}

export const useGetWorkspaceBusinessFlat = ({
    lazy
}: UseGetWorkspaceBusinessFlatOptions): UseGetWorkspaceBusinessFlat => {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope(
        "notifications.GetWorkspaceBusinessesFlat"
    );

    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/workspace/businesses-flat`,
        {
            ...useAxiosConfigRequestParams("WorkspaceBusinessFlat"),
            onError: () =>
                enqueueSnackbar(fs("error"), {
                    variant: "error"
                }),
            params: {
                ...useCustomerData()
            },
            lazy: lazy
        }
    );

    const get = async (reasonId?: string) => {
        return fetch({
            url: `${API_URL}/v1/workspace/businesses-flat`,
            params: {
                reasonId
            }
        }).then((res: AxiosResponse<BusinessFlatDto[] | null>) => res.data);
    };

    const safeFetch = useCallback(() => fetch(), [fetch]);

    return {
        fetch: safeFetch,
        loading: loading,
        error: error,
        data: data,
        get,
        hasFetched: hasLoaded
    };
};
