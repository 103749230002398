import {
    faChevronRight,
    faPlusCircle,
    faTrash
} from "@fortawesome/pro-regular-svg-icons";
import VaporHeaderBar from "@vapor/react-custom/VaporHeaderBar";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Times, VaporIcon } from "@vapor/react-icons";
import {
    Box,
    Breadcrumbs,
    Button,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    Link,
    Stack,
    TextField
} from "@vapor/react-material";
import { DatePicker } from "@vapor/react-x-date-pickers";
import { cloneDeep, isEmpty, isNaN, isNil } from "lodash";
import { useState } from "react";

import { AccountDto } from "../../../core/usecases/dtos/AccountsDto";
import { useAllocateNetIncome } from "../../../core/usecases/useAllocateNetIncome";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import SelectAccount from "../../components/Accounts/SelectAccount";
import BackArrow from "../../components/BackArrow";
import FormattedAmount from "../../components/FormattedAmount";

interface DestinationAccount {
    account: AccountDto;
    value?: string;
}

interface AllocateGainProps {
    onClose: () => void;
    onBack: () => void;
    previousPeriodResult: number;
    onAllocateGain: () => void;
}

const fs = getFormattedStringWithScope("views.AccountingPeriods.AllocateGain");

export default function AllocateGain({
    onClose,
    onBack,
    previousPeriodResult,
    onAllocateGain
}: AllocateGainProps) {
    const placeHolderAccount: DestinationAccount = {
        account: { code: "", title: "" }
    };

    const [destinationAccounts, setDestinationAccounts] = useState<
        DestinationAccount[]
    >([
        // TODO: identify default account
        placeHolderAccount
    ]);

    const [registrationDate, setRegistrationDate] = useState<Date | null>(null);

    const { fetch: allocateNetIncome } = useAllocateNetIncome({ lazy: true });

    const handleSelectAccount = (account: AccountDto, index: number) => {
        let modifiedDestinationAccounts = cloneDeep(destinationAccounts);
        modifiedDestinationAccounts[index].account = account;
        setDestinationAccounts(modifiedDestinationAccounts);
    };

    const handleAddAccount = () => {
        setDestinationAccounts(
            destinationAccounts.concat(cloneDeep(placeHolderAccount))
        );
    };

    const handleRemoveAccount = (indexToRemove: number) => {
        setDestinationAccounts(
            destinationAccounts.filter((_, index) => index !== indexToRemove)
        );
    };

    const handleDestinationAccountValueChange = (
        value: string,
        index: number
    ) => {
        let modifiedDestinationAccounts = [...destinationAccounts];
        const parsedValue = parseFloat(value);
        modifiedDestinationAccounts[index].value =
            value !== ""
                ? isNaN(parsedValue)
                    ? destinationAccounts[index].value
                    : value
                : value;
        setDestinationAccounts(modifiedDestinationAccounts);
    };

    const handleAccountButtonClick = async () => {
        await allocateNetIncome({
            postingDate: registrationDate!,
            allocationLines: destinationAccounts.map((destinationAccount) => ({
                accountId: destinationAccount.account.code,
                debit: {
                    EUR:
                        previousPeriodResult < 0
                            ? 0
                            : parseFloat(destinationAccount.value!)
                },
                credit: {
                    EUR:
                        previousPeriodResult < 0
                            ? 0
                            : parseFloat(destinationAccount.value!)
                }
            }))
        });
        onAllocateGain();
    };

    const isAccountButtonDisabled =
        isNil(registrationDate) ||
        !isEmpty(
            destinationAccounts.filter(
                (account) =>
                    account.account.code === placeHolderAccount.account.code ||
                    account.value === "" ||
                    isNil(account.value)
            )
        ) ||
        destinationAccounts
            .filter((account) => !isNil(account.value))
            .map((account) => (account.value ? parseFloat(account.value) : 0))
            .reduce((a, b) => (a ?? 0) + (b ?? 0), 0) !== previousPeriodResult;

    return (
        <>
            <VaporHeaderBar
                divider
                title={fs("title")}
                leftItems={<BackArrow onClick={onBack} />}
                rightItems={[
                    <IconButton onClick={onClose}>
                        <Times color="primary" />
                    </IconButton>
                ]}
            />
            <Box paddingX="40px" paddingTop="24px">
                <Breadcrumbs
                    separator={<VaporIcon size="12px" icon={faChevronRight} />}
                >
                    <Link underline="hover" onClick={onBack}>
                        <Typography>{fs("periodClosure")}</Typography>
                    </Link>
                    <Typography>{fs("allocateGain")}</Typography>
                </Breadcrumbs>
                <Stack direction="row" marginTop="24px">
                    <Typography>
                        {fs("reason")}{" "}
                        <Typography variant="bodyLarge500">
                            {fs("transfer")}
                        </Typography>
                    </Typography>
                </Stack>
                <Stack width="304px" marginTop="16px">
                    <Typography variant="body500" marginBottom="8px">
                        {fs("registrationDate")}
                    </Typography>
                    <DatePicker
                        value={registrationDate}
                        onChange={(date: any) => setRegistrationDate(date)}
                    />
                </Stack>
                <Stack display="flex" direction="row" marginTop="32px">
                    <Box height="140px" width="100%">
                        <Stack direction="row" justifyContent="space-between">
                            <Typography>{fs("account")}</Typography>
                            <Typography>
                                {previousPeriodResult > 0
                                    ? fs("give")
                                    : fs("take")}
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="bodyLarge500">
                                {fs("periodResult")}
                            </Typography>
                            <FormattedAmount
                                variant="bodyLarge500"
                                amount={previousPeriodResult}
                                currency="EUR"
                            />
                        </Stack>
                    </Box>
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ marginX: "16px" }}
                    />
                    <Box width="100%">
                        {destinationAccounts.map(
                            (destinationAccount, index) => (
                                <Stack
                                    key={index}
                                    direction="row"
                                    spacing="16px"
                                    marginBottom="16px"
                                    alignItems="center"
                                >
                                    <Box width="296px">
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                {fs("account")}
                                            </InputLabel>
                                            {/* 
                                                WARNIING: this change is only temporary
                                                because this section will be revisited
                                            */}
                                            <SelectAccount
                                                value={
                                                    destinationAccount.account
                                                }
                                                onChange={(account) =>
                                                    handleSelectAccount(
                                                        account,
                                                        index
                                                    )
                                                }
                                            />
                                        </FormControl>
                                    </Box>
                                    <FormControl>
                                        <InputLabel>
                                            {previousPeriodResult > 0
                                                ? fs("take")
                                                : fs("give")}
                                        </InputLabel>
                                        <TextField
                                            value={
                                                destinationAccount.value ?? ""
                                            }
                                            onChange={(event) =>
                                                event.target
                                                    ? handleDestinationAccountValueChange(
                                                          event.target.value,
                                                          index
                                                      )
                                                    : null
                                            }
                                        />
                                    </FormControl>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            handleRemoveAccount(index)
                                        }
                                        disabled={index === 0}
                                        sx={{
                                            marginTop: "28px !important"
                                        }}
                                    >
                                        <VaporIcon
                                            color={
                                                index === 0
                                                    ? "disabled"
                                                    : "critical"
                                            }
                                            icon={faTrash}
                                        />
                                    </IconButton>
                                </Stack>
                            )
                        )}
                        <Box marginTop="48px">
                            <Button
                                color="secondary"
                                onClick={handleAddAccount}
                            >
                                <VaporIcon
                                    color="primary"
                                    icon={faPlusCircle}
                                />
                                <Typography marginLeft="16px">
                                    {fs("addAccount")}
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Stack>
            </Box>
            <VaporToolbar
                contentRight={[
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={onBack}
                    >
                        {fs("cancel")}
                    </Button>,
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isAccountButtonDisabled}
                        onClick={handleAccountButtonClick}
                    >
                        {fs("accountButton")}
                    </Button>
                ]}
            />
        </>
    );
}
