import { useRequest } from "@onefront/react-sdk";

import { ASYNC_API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

export interface CancelDepreciationOptions {
    workspaceId: string;
    period: string;
}

export interface CloseAccountingPeriod {
    cancelDepreciation: (options: CancelDepreciationOptions) => Promise<void>;
}

export const useCancelDepreciation = (): CloseAccountingPeriod => {
    const customerData = useCustomerData();

    const { fetch } = useRequest({
        url: `${ASYNC_API_URL}/asset/depreciation`,
        method: "delete",
        ...useAxiosConfigRequestParams("CancelDepreciation"),
        lazy: true
    });

    const cancelDepreciation = async ({
        workspaceId,
        period
    }: CancelDepreciationOptions) => {
        return fetch({
            params: {
                ...customerData
            },
            data: {
                workspaceId,
                period
            }
        });
    };

    return {
        cancelDepreciation
    };
};
