import { faHexagon, faHexagonMinus } from "@fortawesome/pro-regular-svg-icons";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import {
    Box,
    Checkbox,
    IconButton,
    Stack,
    TableCell,
    TableRow,
    useTheme
} from "@vapor/react-material";
import { Tooltip } from "@vapor/react-material/Tooltip";
import { useEffect, useState } from "react";

import { FT_SHOW_DASHBOARD_STATUS_COLUMN } from "../../../../../config";
import usePoll from "../../../../../core/commons/hooks/usePoll";
import { Draft } from "../../../../../core/usecases/dtos/DashboardDto";
import { usePatchDraft } from "../../../../../core/usecases/usePatchDraft";
import { CloseReason } from "../../../../../services/DocumentDetail";
import {
    DraftAccountingStatus,
    DraftSource,
    Reason
} from "../../../../../utils/appEnums";
import { parseBusinessSectional } from "../../../../../utils/businessSectionalUtils";
import getFormattedStringWithScope from "../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../components/FormattedAmount";
import FormattedDate from "../../../../components/FormattedDate";
import AccountingLinesCollapse from "../AccountingLinesCollapse";
import ReasonCell from "./Cells/ReasonCell";
import SectionalCell from "./Cells/SectionalCell";

interface DraftRowProps {
    draft: Draft;
    isOpen: boolean;
    isSelected: boolean;
    canBeSelected: boolean;
    isDisabled: boolean;
    isBeingAccounted: boolean;
    onToggleRow: (draftId: string) => void;
    onRowClick: (draftId: string) => void;
    onDeleteButtonClick: (draftId: string) => void;
    onConfirmFromPreview: (draftId: string) => void;
    onCloseDocumentDetail: (
        closeReason: CloseReason
    ) => (draftId: string) => void;
    fetchSingleDraft: (draftId: string) => Promise<void>;
}

const fs = getFormattedStringWithScope("views.Dashboard.toBeAccounted.table");

export default function DraftRow({
    draft,
    isOpen,
    isSelected,
    canBeSelected,
    isDisabled,
    isBeingAccounted,
    onToggleRow,
    onRowClick,
    onDeleteButtonClick,
    onConfirmFromPreview,
    onCloseDocumentDetail,
    fetchSingleDraft
}: DraftRowProps) {
    const theme = useTheme();
    const openBorderColor = theme.palette.primary.borderActive;
    const errorBorderColor = theme.palette.error[theme.palette.mode];
    const isError = draft.status === DraftAccountingStatus.ERROR;

    const [loadingDraftId, setLoadingDraftId] = useState<string | null>(null);

    const { poll, isPolling, stop } = usePoll();

    const { update } = usePatchDraft({
        lazy: true
    });

    useEffect(() => {
        if (
            !isPolling &&
            draft.status === DraftAccountingStatus.PROCESS_CONFIRM
        ) {
            poll(() => fetchSingleDraft(draft.id));
        } else if (
            [
                DraftAccountingStatus.ERROR,
                DraftAccountingStatus.CONFIRMED
            ].includes(draft.status)
        ) {
            stop();
        }
    }, [draft.status, draft.id, fetchSingleDraft, poll, isPolling, stop]);

    return draft.status === DraftAccountingStatus.CONFIRMED ? null : (
        <>
            <TableRow
                hover={!isDisabled}
                onClick={() => !isDisabled && onRowClick(draft.id)}
                sx={{
                    cursor: isDisabled ? "not-allowed" : "pointer",
                    ...(isDisabled
                        ? {
                              "* ": {
                                  opacity: "0.5"
                              }
                          }
                        : {}),
                    ...(isOpen
                        ? {
                              borderLeft: `2px solid ${openBorderColor}`,
                              borderRight: `2px solid ${openBorderColor}`,
                              borderTop: `3px solid ${openBorderColor}`
                          }
                        : {}),
                    ...(isError && !isOpen
                        ? {
                              borderLeft: `2px solid ${errorBorderColor}`,
                              borderRight: `2px solid ${errorBorderColor}`,
                              borderTop: `3px solid ${errorBorderColor}`,
                              borderBottom: `3px solid ${errorBorderColor}`
                          }
                        : {})
                }}
            >
                <TableCell size="small">
                    {isBeingAccounted ? (
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                "* ": {
                                    opacity: "1 !important"
                                }
                            }}
                        >
                            <Tooltip title={fs("accountingInProgress")} arrow>
                                <div>
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        spin
                                        color="#004e75"
                                    />
                                </div>
                            </Tooltip>
                        </Stack>
                    ) : (
                        <Stack alignItems="center" justifyContent="center">
                            <Checkbox
                                size="small"
                                disabled={isDisabled || !canBeSelected}
                                checked={isSelected}
                                onClick={(event) => {
                                    // click on checkbox should not trigger the opening of a row
                                    event.stopPropagation();
                                    onToggleRow(draft.id);
                                }}
                            />
                        </Stack>
                    )}
                </TableCell>
                {FT_SHOW_DASHBOARD_STATUS_COLUMN ? (
                    <TableCell size="small">
                        {draft.status === DraftAccountingStatus.WAITING ? (
                            <FontAwesomeIcon icon={faHexagon} />
                        ) : (
                            <FontAwesomeIcon icon={faHexagonMinus} />
                        )}
                    </TableCell>
                ) : null}
                <ReasonCell draft={draft} />
                <TableCell size="small">
                    <Stack>
                        <Typography variant="body" marginBottom="-4px">
                            {draft.reasonId === Reason.PF24
                                ? draft.doc.bank
                                : draft.doc.company}
                        </Typography>
                        {draft.reasonId === Reason.PF24 ? null : (
                            <Typography variant="body">
                                {draft.doc.companyVatNumber
                                    ? `${fs("vatNumber")}  ${
                                          draft.doc.companyVatNumber
                                      }`
                                    : null}
                            </Typography>
                        )}
                    </Stack>
                </TableCell>
                <TableCell size="small" align="right">
                    <Typography variant="body">{draft.doc.number}</Typography>
                </TableCell>
                <TableCell size="small" align="right">
                    <FormattedDate variant="body" date={draft.doc.date} />
                </TableCell>
                <TableCell size="small">
                    <SectionalCell
                        loadingSingleDraft={loadingDraftId === draft.id}
                        businessId={draft.businessId}
                        sectionalId={draft.sectionalId}
                        onChange={(sectional) => {
                            const { businessId, sectionalId } =
                                parseBusinessSectional(sectional);

                            setLoadingDraftId(draft.id);

                            update(draft.id, {
                                businessId: businessId,
                                sectionalId: sectionalId
                            }).then(() => {
                                fetchSingleDraft(draft.id).then(() => {
                                    setLoadingDraftId(null);
                                });
                            });
                        }}
                        sectionals={draft.sectionals}
                        isDisabled={isDisabled}
                    />
                </TableCell>
                <TableCell size="small" align="right">
                    <Stack>
                        <FormattedAmount
                            variant="body"
                            amount={draft.amount}
                            currency={draft.refCurrency || "EUR"}
                        />
                        {isError ? (
                            <Typography variant="bodySmall" color="error">
                                {fs("accountingProcessNotSucceeded")}
                            </Typography>
                        ) : null}
                    </Stack>
                </TableCell>
                <TableCell size="small">
                    {draft.doc.source === DraftSource.MANUAL && (
                        <IconButton
                            size="small"
                            disabled={isDisabled}
                            onClick={(event) => {
                                // click on delete icon should not trigger the opening of a row
                                event.stopPropagation();
                                onDeleteButtonClick(draft.id);
                            }}
                        >
                            <VaporIcon
                                color="primary"
                                size="14px"
                                iconName="trash-alt"
                            />
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>
            {isOpen ? (
                <TableCell
                    colSpan={9}
                    sx={{
                        borderLeft: `2px solid ${openBorderColor}`,
                        borderRight: `2px solid ${openBorderColor}`,
                        borderBottom: `3px solid ${openBorderColor}`
                    }}
                >
                    <Box width="100%">
                        {draft.doc?.description && (
                            <>
                                <Box sx={{ margin: "16px 0" }}>
                                    <Typography>
                                        {draft?.doc?.description}
                                    </Typography>
                                </Box>
                            </>
                        )}
                        <AccountingLinesCollapse
                            draft={draft}
                            onConfirm={onConfirmFromPreview}
                            onCloseDocumentDetail={onCloseDocumentDetail}
                        />
                    </Box>
                </TableCell>
            ) : null}
        </>
    );
}
