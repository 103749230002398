import { Spinner } from "@comic/precog-components";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Box, Drawer, Stack } from "@vapor/react-material";
import { compact, find, isEmpty, isNil } from "lodash";
import { useState } from "react";

import {
    AccountingPeriodTaskDto,
    TaskId,
    TaskState
} from "../../../../core/usecases/dtos/AccountingPeriodTasksDto";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import ErrorBanner from "../../../components/ErrorBanner";
import FormattedAmount from "../../../components/FormattedAmount";

import { TaskStatus } from "../../../../core/usecases/dtos/TaskStatus";
import NotFoundBanner from "../../../components/NotFoundBanner";
import AllocateGain from "../AllocateGain";
import TasksList, { TaskLabel, TaskListItem } from "./TasksList";

interface AccountingPeriodTasksProps {
    accountingPeriodTasks: AccountingPeriodTaskDto[] | null;
    isLoadingTasks: boolean;
    errorLoadingTasks: Error | null;
    sequenceNumber: number;
    onOpenDeferredRevenuesAndExpenses: (taskId: string) => void;
    onOpenDepreciation: () => void;
    onAllocateGain: () => void;
    onOpenPeriod: () => void;
}

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.AccountingPeriodTasks"
);

export default function AccountingPeriodTasks({
    accountingPeriodTasks,
    isLoadingTasks,
    errorLoadingTasks,
    sequenceNumber,
    onOpenDeferredRevenuesAndExpenses,
    onOpenDepreciation,
    onOpenPeriod,
    onAllocateGain
}: AccountingPeriodTasksProps) {
    const [allocateGainOpen, setAllocateGainOpen] = useState<boolean>(false);

    const getPreviousPeriodResultAmount = () => {
        const previousPeriodResult = find(
            accountingPeriodTasks,
            (accountingPeriodTask) =>
                accountingPeriodTask.taskId === TaskId.OPENING_NET_INCOME
        );

        return previousPeriodResult?.amount["EUR"] ?? 0;
    };

    const getPerviousPeriodResult = (): TaskListItem | null => {
        const previousPeriodResult = find(
            accountingPeriodTasks,
            (accountingPeriodTask) =>
                accountingPeriodTask.taskId === TaskId.OPENING_NET_INCOME
        );

        // labels
        const openingNetIncomeText = fs(
            "accountingPeriodTask.openingNetIncome"
        );
        const totalText = fs("tot");

        return isNil(previousPeriodResult)
            ? null
            : {
                  completed:
                      previousPeriodResult.state === TaskStatus.COMPLETED,
                  title: (
                      <TaskLabel
                          date={previousPeriodResult.date}
                          title={openingNetIncomeText}
                      />
                  ),
                  subtitle: (
                      <Stack direction="row">
                          <Typography color="primary.textSubduedColor">
                              {totalText}
                          </Typography>
                          <FormattedAmount
                              amount={previousPeriodResult.amount["EUR"]}
                              color="primary.textSubduedColor"
                              currency={"EUR"}
                              marginLeft="8px"
                          />
                      </Stack>
                  ),
                  onArrowClick:
                      previousPeriodResult.amount["EUR"] !== 0
                          ? () => setAllocateGainOpen(true)
                          : undefined,
                  status: previousPeriodResult.state
              };
    };

    const getDeferredRevenuesAndExpenses = (): TaskListItem | null => {
        const deferredRevenues = find(
            accountingPeriodTasks,
            (accountingPeriodTask) =>
                accountingPeriodTask.taskId === TaskId.DEFERRED_REVENUES
        );
        const deferredExpenses = find(
            accountingPeriodTasks,
            (accountingPeriodTask) =>
                accountingPeriodTask.taskId === TaskId.DEFERRED_EXPENSES
        );

        // labels
        const deferredRevenuesAndExpensesText = fs(
            "accountingPeriodTask.deferredRevenuesAndExpenses"
        );
        const totDeferredExpensesText = fs(
            "accountingPeriodTask.totDeferredExpenses"
        );
        const totDeferredRevenuesText = fs(
            "accountingPeriodTask.totDeferredRevenues"
        );

        return isNil(deferredRevenues) && isNil(deferredExpenses)
            ? null
            : {
                  completed:
                      (!!deferredRevenues &&
                          deferredRevenues.state === TaskStatus.COMPLETED) ||
                      (!!deferredExpenses &&
                          deferredExpenses.state === TaskStatus.COMPLETED),
                  title: (
                      <TaskLabel
                          date={
                              deferredRevenues?.date ?? deferredExpenses?.date
                          }
                          title={deferredRevenuesAndExpensesText}
                      />
                  ),
                  subtitle: (
                      <Stack direction="row" spacing={6}>
                          {deferredExpenses ? (
                              <Stack direction="row">
                                  <Typography color="primary.textSubduedColor">
                                      {totDeferredExpensesText}
                                  </Typography>
                                  <FormattedAmount
                                      amount={deferredExpenses.amount["EUR"]}
                                      color="primary.textSubduedColor"
                                      currency={"EUR"}
                                      marginLeft="8px"
                                  />
                              </Stack>
                          ) : null}
                          {deferredRevenues ? (
                              <Stack direction="row">
                                  <Typography color="primary.textSubduedColor">
                                      {totDeferredRevenuesText}
                                  </Typography>
                                  <FormattedAmount
                                      amount={deferredRevenues.amount["EUR"]}
                                      color="primary.textSubduedColor"
                                      currency={"EUR"}
                                      marginLeft="8px"
                                  />
                              </Stack>
                          ) : null}
                      </Stack>
                  ),
                  onArrowClick: () => {
                      const taskId =
                          deferredRevenues?.taskId ?? deferredExpenses?.taskId;
                      taskId && onOpenDeferredRevenuesAndExpenses(taskId);
                  },
                  status:
                      (!!deferredRevenues &&
                          deferredRevenues.state === TaskStatus.COMPLETED) ||
                      (!!deferredExpenses &&
                          deferredExpenses.state === TaskStatus.COMPLETED)
                          ? TaskState.COMPLETED
                          : TaskState.ACTION_REQUIRED
              };
    };

    const getDepreciation = (): TaskListItem | null => {
        const depreciation = find(
            accountingPeriodTasks,
            (accountingPeriodTask) =>
                accountingPeriodTask.taskId === TaskId.DEPRECIATION
        );

        const depreciationTextTitle = fs("accountingPeriodTask.depreciation");
        const totalText = fs("tot");

        return isNil(depreciation)
            ? null
            : {
                  completed: depreciation.state === TaskStatus.GENERATED,
                  title: <TaskLabel title={depreciationTextTitle} />,
                  subtitle: (
                      <Stack direction="row">
                          <Typography color="primary.textSubduedColor">
                              {totalText}
                          </Typography>
                          <FormattedAmount
                              amount={depreciation.amount["EUR"]}
                              color="primary.textSubduedColor"
                              currency={"EUR"}
                              marginLeft="8px"
                          />
                      </Stack>
                  ),
                  onArrowClick: () => onOpenDepreciation(),
                  status: depreciation.state
              };
    };

    const getCloseAndReopenAccounts = (): TaskListItem | null => {
        const accountCloseOpen = find(
            accountingPeriodTasks,
            (accountTask) =>
                accountTask.taskId === TaskId.CLOSING_OPENING_ACCOUNTS
        );

        const accountCloseOpenText = fs(
            "accountingPeriodTask.accountCloseOpen"
        );
        const periodText = fs("accountingPeriodTask.period");

        return isNil(accountCloseOpen)
            ? null
            : {
                  completed: accountCloseOpen.state === TaskStatus.COMPLETED,
                  title: <Typography>{accountCloseOpenText}</Typography>,
                  subtitle: (
                      <Stack direction="row" gap={1}>
                          <Typography color="primary.textSubduedColor">
                              {periodText}
                          </Typography>
                          <Typography color="primary.textSubduedColor">
                              {sequenceNumber}
                          </Typography>
                      </Stack>
                  ),
                  onArrowClick: () =>
                      accountCloseOpen.state !== TaskStatus.INACTIVE
                          ? onOpenPeriod()
                          : null,
                  status: accountCloseOpen.state
              };
    };

    const tasks: TaskListItem[] = compact([
        getPerviousPeriodResult(),
        getDeferredRevenuesAndExpenses(),
        getDepreciation(),
        getCloseAndReopenAccounts()
    ]);

    const handleClose = () => {
        setAllocateGainOpen(false);
    };

    const handleAllocateGain = () => {
        setAllocateGainOpen(false);
        onAllocateGain();
    };

    return (
        <Box>
            <Spinner loading={isLoadingTasks} />
            {allocateGainOpen ? (
                <Drawer
                    open={allocateGainOpen}
                    anchor="right"
                    onClose={handleClose}
                >
                    <AllocateGain
                        onBack={() => setAllocateGainOpen(false)}
                        onClose={handleClose}
                        onAllocateGain={handleAllocateGain}
                        previousPeriodResult={getPreviousPeriodResultAmount()}
                    />
                </Drawer>
            ) : (
                <>
                    {errorLoadingTasks ? (
                        <ErrorBanner error={errorLoadingTasks} />
                    ) : isNil(accountingPeriodTasks) ? null : isEmpty(tasks) ? (
                        <NotFoundBanner text={fs("noTaskFound")} />
                    ) : (
                        <TasksList tasks={tasks} />
                    )}
                </>
            )}
        </Box>
    );
}
