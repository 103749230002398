import { debounce } from "lodash";
import React from "react";
import {
    useAccounts,
    UseAccountsOptions
} from "../../../../core/domain/Accounts/queries";

import { AccountDto } from "../../../../core/usecases/dtos/AccountsDto";
import { default as DumbSelectAccount } from "../../../components/SelectAccount";

export type SelectAccountProps = {
    value?: AccountDto | null;
    onChange: (account: AccountDto) => void;
    shouldHideAccount?: (account: AccountDto) => boolean;
    readOnly?: boolean;
    disabled?: boolean;
    transparent?: boolean;
    autoFocus?: boolean;
} & Omit<UseAccountsOptions, "searchString">;

export default React.forwardRef<HTMLInputElement, SelectAccountProps>(
    function SelectAccount(
        {
            value,
            onChange,
            shouldHideAccount = () => false,
            readOnly,
            disabled,
            transparent = true,
            autoFocus = false,
            ...options
        }: SelectAccountProps,
        ref
    ) {
        const [searchString, setSearchString] = React.useState<string>("");
        const { data: accounts, isLoading } = useAccounts({
            ...options,
            searchString
        });

        const handleChangeSearchString = debounce(setSearchString, 300);

        const displayableAccounts =
            accounts?.filter((account) => !shouldHideAccount(account)) ?? [];

        return (
            <DumbSelectAccount
                value={value}
                accounts={displayableAccounts}
                isLoading={isLoading}
                onChange={onChange}
                onSearchStringChange={handleChangeSearchString}
                hideAccountCode={false}
                showPopupIcon={false}
                transparent={transparent}
                disabled={disabled}
                readOnly={readOnly}
                ref={ref}
                autoFocus={autoFocus}
            />
        );
    }
);
