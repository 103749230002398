import { Box, Tooltip } from "@vapor/react-material";
import Autocomplete from "@vapor/react-material/Autocomplete";
import TextField from "@vapor/react-material/TextField";
import { capitalize } from "lodash";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";

import { AccountDto } from "../../../core/usecases/dtos/AccountsDto";

interface SelectAccountProps {
    value?: AccountDto | null;
    accounts: AccountDto[];
    isLoading: boolean;
    onSearchStringChange: (selectString: string) => void;
    onChange: (selectedAccount: AccountDto) => void;
    hideAccountCode?: boolean;
    showPopupIcon?: boolean;
    transparent?: boolean;
    disabled?: boolean;
    disablePortal?: boolean;
    readOnly?: boolean;
    autoFocus?: boolean;
}

export default React.forwardRef<HTMLInputElement, SelectAccountProps>(
    function SelectAccount(
        {
            value,
            accounts,
            isLoading,
            onSearchStringChange,
            onChange,
            hideAccountCode,
            showPopupIcon = true,
            transparent = false,
            disabled,
            disablePortal = true,
            readOnly,
            autoFocus = false
        }: SelectAccountProps,
        ref
    ) {
        const [searchString, setSearchString] = useState<string>("");

        const [inputRef, setInputRef] = useState<
            MutableRefObject<HTMLInputElement>
        >(ref as MutableRefObject<HTMLInputElement>);

        const internalRef = useRef<HTMLInputElement | null>(null);

        const [tooltipTitle, setTooltipTitle] = useState<string | null>(null);

        useEffect(() => {
            onSearchStringChange(searchString);
        }, [searchString, onSearchStringChange]);

        const handleSelect = (newSearchString: string) => {
            if (searchString !== newSearchString)
                setSearchString(newSearchString);
        };

        const compareOptionAndValue = (option: any, value: any) => {
            return option.code === value.code;
        };

        useEffect(() => {
            const forwardedRef = ref as MutableRefObject<HTMLInputElement>;
            if (forwardedRef && forwardedRef.current) {
                setInputRef(forwardedRef);
            } else {
                setInputRef(internalRef as MutableRefObject<HTMLInputElement>);
            }
        }, [ref]);

        useEffect(() => {
            if (inputRef && inputRef.current) {
                const isOverflowing =
                    inputRef.current.offsetWidth < inputRef.current.scrollWidth;
                setTooltipTitle(
                    isOverflowing ? value?.description ?? "" : null
                );
            }
        }, [inputRef, value]);

        return (
            <Autocomplete
                value={value}
                isOptionEqualToValue={compareOptionAndValue}
                options={accounts}
                getOptionLabel={(account: any) =>
                    `${capitalize(account.title)}`
                }
                renderOption={(props, account: any) => (
                    <Box component="li" {...props}>
                        {hideAccountCode
                            ? `${capitalize(account.title)}`
                            : `${account.code} ${capitalize(account.title)}`}
                    </Box>
                )}
                renderInput={(params) => (
                    <Tooltip
                        arrow
                        title={tooltipTitle}
                        sx={{
                            "& .MuiTooltip-tooltip": {
                                zIndex: "9999"
                            }
                        }}
                    >
                        <TextField
                            {...params}
                            type="search"
                            onChange={(event: any) =>
                                handleSelect(event.target.value)
                            }
                            inputRef={inputRef}
                            autoFocus={autoFocus}
                            disabled={disabled}
                            readOnly={readOnly}
                        />
                    </Tooltip>
                )}
                onChange={(_: any, account: any) => onChange(account)}
                loading={isLoading}
                popupIcon={showPopupIcon}
                // filterOptions defaulted as per https://mui.com/material-ui/react-autocomplete/
                filterOptions={(x) => x}
                disablePortal={disablePortal}
                disabled={disabled}
                readOnly={readOnly}
                componentsProps={{
                    popper: {
                        placement: "bottom-start",
                        sx: {
                            minWidth: "max-content"
                        }
                    }
                }}
                sx={{
                    width: "100%",
                    ...(transparent
                        ? {
                              "*": {
                                  background: "none !important",
                                  border: "none"
                              },
                              "& .MuiAutocomplete-endAdornment": {
                                  display: "none"
                              }
                          }
                        : {})
                }}
            />
        );
    }
);
