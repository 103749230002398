import { createContext, useContext } from "react";

import { ProcedureEntryDetailComponent } from "./ProcedureEntryDetailComponent";

interface ProcedureEntryDetailProvider {
    open: (draftId: string) => void;
}

export interface ProcedureEntryDetailComponentProps {
    children: any;
}

export const ProcedureEntryDetailContext =
    createContext<ProcedureEntryDetailProvider>({
        open: () => {}
    });

export const useProcedureEntryDetail = (): ProcedureEntryDetailProvider => {
    return useContext(ProcedureEntryDetailContext);
};

export const ProcedureEntryDetailService = () => ({
    target: "$REACT_ROOT_WRAPPER",
    handler: {
        component: ({ children }: any) => (
            <ProcedureEntryDetailComponent>
                {children}
            </ProcedureEntryDetailComponent>
        )
    }
});

export default ProcedureEntryDetailComponent;
