import { API_URL } from "../../../config";

import { useRequest } from "@onefront/react-sdk";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

import { useCallback, useState } from "react";
import { isNil } from "lodash";
import { ShellCompany } from "../../../utils/appEnums";

export interface UsePatchDraftAsset {
    loading: boolean;
    patchDraftAssetParams: (
        assetId?: string | null,
        parentId?: string | null,
        draftId?: string | null,
        dataToPatch?: DraftAssetPatch | null
    ) => Promise<void>;
    deleteFields: (
        draftId: string,
        parentId?: string,
        assetId?: string,
        fieldsToDelete?: (keyof DraftAssetPatch)[]
    ) => Promise<void>;
    error: any;
    result: any;
    patchDraftAsset: () => Promise<void>;
    hasFetched: boolean;
    hasResponded: boolean;
}

export interface ReducedDeductibilityGoods {
    mixedUse?: string | null;
    assignmentDate?: string | null;
    revocationDate?: string | null;
}

export interface DraftAssetPatch {
    articleDesc?: string;
    alias?: string | null;
    description?: string | null;
    subKind?: string;
    newGood?: boolean;
    assetFullDescription?: string;
    assetRegistryId?: string | null;
    activationDate?: string;
    suspensionDate?: string;
    availabilityDate?: string;
    hasAdditionalCharge?: boolean;
    isReducedDeductibility?: boolean;
    reducedDeductibility?: string;
    taxDepreciationRate?: number | null;
    taxDepreciationRateType?: string;
    sellingDepreciation?: boolean;
    halfFirstYear?: boolean;
    statutoryDepreciationType?: string;
    statutoryDepreciationValue?: string | null;
    sellingPercent?: number | null;
    distributeCapitalGains?: boolean;
    increaseExistingAsset?: boolean;
    reducedDeductibilityGoods?: ReducedDeductibilityGoods[];
    irapPercent?: number | null;
    reportInDays?: boolean | null;
    taxRelief?: string;
    taxReliefType?: string;
    taxReliefDate?: string;
    taxReliefAmount?: number;
    reducedDeductibilityExtraField?: string;
    fieldsToDelete?: (keyof DraftAssetPatch)[];
    hasISA?: boolean;
    shellCompany?: ShellCompany;
}

export interface UsePatchDraftAssetOptions {
    assetId?: string | null;
    draftId?: string | null;
    dataToPatch?: DraftAssetPatch | null;
    lazy: boolean;
}

export const usePatchDraftAsset = ({
    assetId,
    draftId,
    dataToPatch,
    lazy
}: UsePatchDraftAssetOptions): UsePatchDraftAsset => {
    const customerData = useCustomerData();
    const [hasResponded, setHasResponded] = useState<boolean>(false);

    const { fetch, data, loading, error, hasLoaded } = useRequest({
        ...useAxiosConfigRequestParams("PatchDraftAsset"),
        method: "patch",
        url: `${API_URL}/v1/precog/drafts/${draftId}/asset/${assetId}`,
        onResponse: () => setHasResponded(true),
        params: {
            ...useCustomerData()
        },
        data: {
            ...dataToPatch
        },
        lazy: lazy
    });

    const safeFetch = useCallback(async () => {
        setHasResponded(false);
        return fetch();
    }, [fetch]);

    const deleteFields = async (
        draftId: string,
        parentId?: string,
        assetId?: string,
        fieldsToDelete?: (keyof DraftAssetPatch)[]
    ) => {
        return fetch({
            url: `${API_URL}/v1/precog/drafts/${draftId}/asset/${
                !isNil(parentId) ? parentId : assetId
            }`,
            params: {
                ...customerData
            },
            data: {
                fieldsToDelete
            }
        });
    };

    const patchDraftAssetParams = useCallback(
        (
            assetId?: string | null,
            parentId?: string | null,
            draftId?: string | null,
            dataToPatch?: DraftAssetPatch | null
        ) => {
            return fetch({
                url: `${API_URL}/v1/precog/drafts/${draftId}/asset/${
                    !isNil(parentId) ? parentId : assetId
                }`,
                params: {
                    ...customerData
                },
                data: {
                    ...dataToPatch
                }
            });
        },
        [fetch, customerData]
    );

    return {
        patchDraftAsset: safeFetch,
        patchDraftAssetParams,
        deleteFields,
        loading: loading,
        error: error,
        result: data,
        hasFetched: hasLoaded,
        hasResponded: hasResponded
    };
};
