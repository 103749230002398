import { OneBoxPage } from "@drift/oneplatfront";
import { styled } from "@mui/system";
import { Box } from "@vapor/react-material";

const PageHeaderHeight = "78px";

const WidgetContainerHeight = "286px";

const TabsContainerHeight = "72px";

export const DashboardPage = styled(OneBoxPage)({
    height: `calc(100vh + ${WidgetContainerHeight})`
});

export const ToBeAccountedTableContainer = styled(Box)({
    marginTop: "32px",
    height: `calc(100vh - ${PageHeaderHeight} - ${WidgetContainerHeight} + ${TabsContainerHeight})`,
    overflow: "scroll",
    overflowX: "hidden",
    "& > table > thead:first-of-type": {
        position: "sticky",
        top: "0px",
        bgcolor: "white",
        // needed, otherwise svgs keep showing over the thead
        zIndex: "1"
    },
    "& th": {
        boxShadow: "inset 0px 0.5px 0px 0px hsl(199, 100%, 20%)"
    }
});
