import {
    ColumnElement,
    ColumnType,
    HeadCell,
    Table
} from "@comic/precog-components";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import IconButton from "@vapor/react-material/IconButton";

import { BusinessSettlementDto } from "../../../../../../../core/usecases/dtos/VatSettlementsDto";
import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../../../components/FormattedAmount";

const fs = getFormattedStringWithScope(
    "views.VATSettlement.multiActivity.table"
);

interface MultiActivityTableProps {
    onSelectBusiness: (id: string) => void;
    data: BusinessSettlementDto[];
}

const MultiActivityTable = ({
    onSelectBusiness,
    data
}: MultiActivityTableProps) => {
    const totalCreditAmount = data.reduce(
        (acc, curr) => acc + (curr.vatAmountCredit ?? 0),
        0
    );

    const totalDebitAmount = data.reduce(
        (acc, curr) => acc + (curr.vatAmountDebit ?? 0),
        0
    );

    const headCells: HeadCell[] = [
        {
            id: "businessDescription",
            kind: ColumnType.text,
            label: fs("activity")
        },
        {
            id: "vatAmountCredit",
            kind: ColumnType.numeric,
            label: fs("creditAmount")
        },
        {
            id: "vatAmountDebit",
            kind: ColumnType.numeric,
            label: fs("debitAmount")
        },
        {
            id: "",
            kind: ColumnType.cta,
            label: ""
        }
    ];

    const columns: ColumnElement[] = [
        // activity
        {
            kind: ColumnType.text,
            render: (detail: BusinessSettlementDto) => (
                <Typography>{detail.businessDescription}</Typography>
            )
        },
        // creditAmount
        {
            kind: ColumnType.numeric,
            render: (detail: BusinessSettlementDto) => (
                <FormattedAmount
                    amount={detail.vatAmountCredit ?? 0}
                    currency="EUR"
                />
            )
        },
        // debitAmount
        {
            kind: ColumnType.numeric,
            render: (detail: BusinessSettlementDto) => (
                <FormattedAmount
                    amount={detail.vatAmountDebit ?? 0}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.cta,
            render: (detail: BusinessSettlementDto) => (
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => onSelectBusiness(detail.id)}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </IconButton>
            )
        }
    ];

    const footer: ColumnElement[] = [
        { kind: ColumnType.empty },
        // creditAmount
        {
            kind: ColumnType.numeric,
            render: () => (
                <FormattedAmount
                    fontWeight="bold"
                    amount={totalCreditAmount}
                    currency="EUR"
                />
            )
        },
        // debitAmount
        {
            kind: ColumnType.numeric,
            render: () => (
                <FormattedAmount
                    fontWeight="bold"
                    amount={totalDebitAmount}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.cta,
            render: () => <VaporIcon icon={faCheck} color="success" />
        }
    ];

    return (
        <Table
            headCells={headCells}
            columns={columns}
            data={data}
            footer={footer}
        />
    );
};

export default MultiActivityTable;
