import { SxProps } from "@mui/system";
import {
    Autocomplete,
    FormControl,
    InputLabel,
    TextField
} from "@vapor/react-material";
import { capitalize, isNil, omit } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useVatDestinations } from "../../../core/domain/VatDestinations/queries";

import { VatDestination } from "../../../core/usecases/dtos/VatDestinationsDto";
import { FormField } from "./types";

interface FormSelectVatDestinationProps extends FormField {
    sx?: SxProps;
    disablePortal?: boolean;
}

export default function FormSelectVatDestination({
    name,
    label,
    required,
    disabled,
    sx,
    disablePortal
}: FormSelectVatDestinationProps) {
    const { control } = useFormContext();

    const { data: vatDestinations } = useVatDestinations();

    return (
        <Controller
            control={control}
            shouldUnregister={true}
            name={name}
            rules={{ required: required }}
            render={({ field, fieldState }) => (
                <FormControl
                    fullWidth
                    error={!isNil(fieldState.error)}
                    disabled={disabled}
                >
                    <InputLabel required={required} disabled={disabled}>
                        {label}
                    </InputLabel>
                    <Autocomplete
                        options={vatDestinations ?? []}
                        renderInput={(params) => <TextField {...params} />}
                        getOptionLabel={(vatDestination: any) =>
                            capitalize(
                                (vatDestination as VatDestination).description
                            )
                        }
                        isOptionEqualToValue={(option, value) =>
                            (option as VatDestination).id ===
                            (value as VatDestination).id
                        }
                        onChange={(_: any, vatDestination: any) =>
                            field.onChange(vatDestination as VatDestination)
                        }
                        disablePortal={disablePortal}
                        autoComplete={true}
                        autoHighlight={true}
                        autoSelect={true}
                        openOnFocus={true}
                        disabled={disabled}
                        readOnly={disabled}
                        {...omit(field, "onChange", "disabled")}
                        sx={{ width: "100%", ...sx }}
                        componentsProps={{
                            popper: {
                                placement: "bottom-start",
                                sx: {
                                    width: "fit-content !important"
                                }
                            }
                        }}
                    />
                </FormControl>
            )}
        />
    );
}
