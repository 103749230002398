import { useGet, useSnackbar } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import { WithholdingType } from "../../../utils/appEnums";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { Withholding, Withholdings } from "../dtos/WithholdingsDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseGetWithholdings {
    loading: boolean;
    error: any;
    withholdings: Withholding[];
    get: (status: WithholdingType) => Promise<Withholding[]>;
}

export const useGetWithholdings = (): UseGetWithholdings => {
    const { enqueueSnackbar } = useSnackbar();

    const customerData = useCustomerData();

    const fs = useFormattedStringWithScope("notifications.GetWithholdings");

    const { fetch, data, loading, error } = useGet(
        `${API_URL}/v1/withholding`,
        {
            ...useAxiosConfigRequestParams("Withholding"),
            onError: () =>
                enqueueSnackbar(fs("error"), {
                    variant: "error"
                }),
            lazy: true
        }
    );

    const get = async (status: WithholdingType) => {
        return fetch({
            params: omitNilProperties({
                ...customerData,
                status: status === "ACTIVE" ? "active" : "passive"
            })
        }).then(
            (response: AxiosResponse<Withholdings>) =>
                response.data.withholdings
        );
    };

    return {
        get,
        loading: loading,
        error: error,
        withholdings: data ? data.withholdings : []
    };
};
