import { useLocation } from "react-router-dom";
import { useMemo } from "react";

import { QueryParam } from "./appEnums";

interface UseQueryParam {
    get: (name: QueryParam) => string | null;
}

export default function useQueryParam(): UseQueryParam {
    const { search } = useLocation();

    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    return {
        get: (name) => searchParams.get(name)
    };
}
