import { useGet } from "@onefront/react-sdk";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { Config } from "../dtos/ConfigDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseGetConfig {
    loading: boolean;
    error: any;
    getConfig: () => Promise<Config>;
    config: Config;
}

export const useGetConfig = (): UseGetConfig => {
    const customerData = useCustomerData();

    const { fetch, data, loading, error } = useGet(
        `${API_URL}/v1/config/precog`,
        {
            ...useAxiosConfigRequestParams("GetConfig"),
            lazy: true
        }
    );

    const getConfig = useCallback(() => {
        return fetch({
            params: {
                ...customerData
            }
        }).then((response: AxiosResponse<Config>) => response.data);
    }, [fetch, customerData]);

    return {
        getConfig,
        loading: loading,
        config: data,
        error: error
    };
};
