import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    CircularProgress,
    DialogActions,
    Stack,
    Table,
    TableBody
} from "@vapor/react-material";
import { useState } from "react";
import {
    useAccountingPeriodClosureSimulation,
    useCloseAccountingPeriods,
    useGetReportSimulatePeriod
} from "../../../../core/domain/CloseAndReopenAccount/queries";
import { AccountingPeriodDto } from "../../../../core/usecases/dtos/AccountingPeriodsDto";
import { TaskStatus } from "../../../../core/usecases/dtos/TaskStatus";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../../components/CustomButton";
import FormattedDate from "../../../components/FormattedDate";
import TaskStatusAlertBanner from "../TaskStatusAlertBanner";
import { CloseAndReopenAccountTableHead } from "./CloseAndReopenAccountTableHead";
import { CloseAndReopenAccountsHeader } from "./CloseAndReopenAccountsHeader";
import CloseAndReopenAccountsLine from "./CloseAndReopenAccountsLines";
import DialogAlertClose from "./DialogAlertClose";
import SummaryCloseAndReopenAccountsTable from "./SummaryCloseAndReopenAccountsTable";

interface CloseAndReopenAccountsSimulationProps {
    workspaceId: string;
    selectedAccountingPeriod: AccountingPeriodDto;
    onClose: () => void;

    statusTask: TaskStatus;
}
const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.CloseAndReopenAccountsTable"
);

const fsButton = getFormattedStringWithScope(
    "views.AccountingPeriods.CloseAndReopenAccountsOverlay"
);

export const CloseAndReopenAccountsSimulation = ({
    workspaceId,
    selectedAccountingPeriod,
    onClose,

    statusTask
}: CloseAndReopenAccountsSimulationProps) => {
    const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);
    const { data: simulateReport, isLoading: loadingSimulateReport } =
        useGetReportSimulatePeriod({
            workspaceId,
            accountingPeriodId: selectedAccountingPeriod.id
        });

    const {
        mutateAsync: fetchSimulatePeriod,
        isPending: simulatePeriodLoading
    } = useAccountingPeriodClosureSimulation(selectedAccountingPeriod.id);
    const { mutateAsync: closePeriod } = useCloseAccountingPeriods(
        selectedAccountingPeriod.id
    );
    const handleSimulatePeriod = async () => {
        setIsOpenAlertDialog(false);
        await fetchSimulatePeriod({
            workspaceId,
            accountingPeriodId: selectedAccountingPeriod.id
        });
        onClose();
    };
    const handleClosePeriod = async () => {
        setIsOpenAlertDialog(false);
        await closePeriod({
            workspaceId,
            accountingPeriodId: selectedAccountingPeriod.id
        });

        onClose();
    };

    return (
        <>
            <CloseAndReopenAccountsHeader
                selectedAccountingPeriod={selectedAccountingPeriod}
                report={simulateReport?.result.report}
            />
            <TaskStatusAlertBanner status={statusTask} />
            <Stack gap={4} marginBottom={2} marginTop={2}>
                <Typography variant="h6" color="primary.textTitleColor">
                    {fs("title")}
                </Typography>

                {!loadingSimulateReport && simulateReport && (
                    <Table>
                        <CloseAndReopenAccountTableHead />
                        <TableBody>
                            <CloseAndReopenAccountsLine
                                accounts={
                                    simulateReport?.result.report.accounts
                                }
                            />
                            <SummaryCloseAndReopenAccountsTable
                                report={simulateReport.result.report}
                            />
                        </TableBody>
                    </Table>
                )}
                {loadingSimulateReport && (
                    <Box
                        justifyContent="center"
                        display="flex"
                        alignContent="center"
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Stack>
            <DialogActions
                sx={{
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                    width: "100%"
                }}
            >
                <>
                    {simulateReport?.simulationDate && (
                        <>
                            <Typography>
                                {fsButton("dateSimulation")}
                            </Typography>
                            <FormattedDate
                                date={simulateReport.simulationDate}
                            />
                        </>
                    )}

                    <CustomButton
                        onClick={handleSimulatePeriod}
                        loading={
                            simulatePeriodLoading ||
                            statusTask === TaskStatus.SIMULATING
                        }
                        disabled={
                            simulatePeriodLoading ||
                            statusTask === TaskStatus.SIMULATING ||
                            statusTask === TaskStatus.REOPENING ||
                            statusTask === TaskStatus.GENERATING
                        }
                        color="primary"
                        variant="contained"
                    >
                        {statusTask === TaskStatus.SIMULATED
                            ? fsButton("updateSimulationPeriod")
                            : fsButton("generateSimulationPeriod")}
                    </CustomButton>
                </>

                <CustomButton
                    onClick={() => setIsOpenAlertDialog(true)}
                    loading={statusTask === TaskStatus.GENERATING}
                    color="primary"
                    disabled={statusTask !== TaskStatus.SIMULATED}
                    variant="contained"
                >
                    {statusTask === TaskStatus.GENERATING
                        ? fsButton("generatePeriodPending")
                        : fsButton("generatePeriod")}
                </CustomButton>
            </DialogActions>
            <DialogAlertClose
                open={isOpenAlertDialog}
                onClose={() => setIsOpenAlertDialog(false)}
                handleUpdatePeriod={handleSimulatePeriod}
                handleClosePeriod={handleClosePeriod}
            />
        </>
    );
};
