import { useGet } from "@onefront/react-sdk";

import { API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AccountDto } from "../dtos/AccountsDto";

interface UseGetAccount {
    loading: boolean;
    error: any;
    account: AccountDto | null;
    fetch: Function;
}

interface UseGetAccountOptions {
    accountCode: string;
    lazy?: boolean;
}

export const useGetAccount = ({
    accountCode,
    lazy
}: UseGetAccountOptions): UseGetAccount => {
    const { fetch, data, loading, error } = useGet(
        `${API_URL}/v1/accounting/accounts/account`,
        {
            ...useAxiosConfigRequestParams("Accounts"),
            params: {
                ...useCustomerData(),
                accountCode: accountCode
            },
            lazy: lazy
        }
    );

    return {
        fetch: fetch,
        loading: loading,
        error: error,
        account: data
    };
};
