import {
    ColumnElement,
    ColumnType,
    HeadCell,
    Table
} from "@comic/precog-components";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import IconButton from "@vapor/react-material/IconButton";

import { BusinessId } from "../../../../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";
import { BusinessDto } from "../../../../../../../core/usecases/dtos/BusinessDto";

const fs = getFormattedStringWithScope(
    "views.VATSettlement.multiActivity.table"
);

interface MultiBusinessesTableProps {
    onSelectBusiness: (id: string) => void;
    data: BusinessDto[];
}

const MultiBusinessesTable = ({
    onSelectBusiness,
    data
}: MultiBusinessesTableProps) => {
    const headCells: HeadCell[] = [
        {
            id: "description",
            kind: ColumnType.text,
            label: fs("activity")
        },
        {
            id: "",
            kind: ColumnType.cta,
            label: ""
        }
    ];

    const columns: ColumnElement[] = [
        // activity
        {
            kind: ColumnType.text,
            render: (detail: BusinessDto) => (
                <Typography>{detail.description}</Typography>
            )
        },
        {
            kind: ColumnType.cta,
            render: (detail: BusinessDto) => (
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => onSelectBusiness(detail.id)}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </IconButton>
            )
        }
    ];

    return (
        <Table
            headCells={headCells}
            columns={columns}
            // TODO: refactor the addition of "all businesses"
            data={[
                {
                    id: BusinessId.ALL,
                    description: fs("all")
                },
                ...data
            ]}
        />
    );
};

export default MultiBusinessesTable;
