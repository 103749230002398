import Card from "@vapor/react-material/Card";

interface WidgetCardProps {
    children?: any;
    error?: boolean;
}

export default function WidgetCard({ children, error }: WidgetCardProps) {
    return (
        <Card
            variant="outlined"
            sx={{
                height: "286px",
                width: "286px",
                border: error ? "2px solid #D82829" : undefined
            }}
        >
            {children}
        </Card>
    );
}
