import { ComponentsLibraryWrapperProvider } from "@comic/precog-components";

const ComponentsLibraryWrapperComponent = ({ children }: any) => {
    return (
        <ComponentsLibraryWrapperProvider>
            {children}
        </ComponentsLibraryWrapperProvider>
    );
};

export const ComponentsLibraryWrapperService = () => ({
    target: "$REACT_ROOT_WRAPPER",
    handler: {
        component: ({ children }: any) => (
            <ComponentsLibraryWrapperComponent>
                {children}
            </ComponentsLibraryWrapperComponent>
        )
    }
});
