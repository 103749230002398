import { useRequest } from "@onefront/react-sdk";
import { isArray, isEmpty, isNil } from "lodash";

import { API_URL } from "../../../config";
import { useSnackbar } from "../../../services/SnackbarService";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { AccountingLine } from "../dtos/AccountingLinesDto";

interface UseEditAccountingLine {
    loading: boolean;
    error: any;
    edit: (
        draftId: string,
        idOrIds: string | string[],
        data: Partial<AccountingLine>
    ) => Promise<void>;
    deleteFields: (
        draftId: string,
        idOrIds: string | string[],
        fieldsToDelete?: (keyof AccountingLine)[]
    ) => Promise<void>;
}

const DEFAULT_ARTICLE_DESC = "Imponibile";

export const useEditAccountingLine = (): UseEditAccountingLine => {
    const customerData = useCustomerData();

    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.PatchAccountingLine");

    const { fetch, loading, error } = useRequest({
        ...useAxiosConfigRequestParams("AccountingLine"),
        method: "patch",
        onError: (error: any) =>
            enqueueSnackbar(
                {
                    title: fs("error"),
                    content: error.response.data.message ?? ""
                },
                { severity: "error" }
            ),
        lazy: true
    });

    const edit = async (
        draftId: string,
        idOrIds: string | string[],
        data: Partial<AccountingLine>
    ) => {
        const updateData = {
            ...data,
            credit: data.debit ? 0 : data.credit,
            debit: data.credit ? 0 : data.debit,
            articleDesc:
                !isNil(data.articleDesc) && isEmpty(data.articleDesc)
                    ? DEFAULT_ARTICLE_DESC
                    : data.articleDesc
        } as AccountingLine;

        return fetch({
            url: `${API_URL}/v2/precog/drafts/${draftId}/accountingLines`,
            params: {
                ...customerData,
                accountingLineIdList: isArray(idOrIds)
                    ? idOrIds.join(",")
                    : idOrIds
            },
            data: updateData
        });
    };

    const deleteFields = async (
        draftId: string,
        idOrIds: string | string[],
        fieldsToDelete?: (keyof AccountingLine)[]
    ) => {
        return fetch({
            url: `${API_URL}/v2/precog/drafts/${draftId}/accountingLines`,
            params: {
                ...customerData,
                accountingLineIdList: isArray(idOrIds)
                    ? idOrIds.join(",")
                    : idOrIds
            },
            data: {
                fieldsToDelete
            }
        });
    };

    return {
        edit,
        loading: loading,
        error: error,
        deleteFields
    };
};
