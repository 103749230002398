import { isAfter } from "date-fns";

import { isXML } from "../../../utils/draftUtils";
import { canCreateAccountingLines } from "../../../utils/reasonUtils";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import { useAccountingLinesDraftStatus } from "../AccountingLines/queries";
import { useDraft } from "./queries";

export const useCanCreateAccountingLines = (draftId: string) => {
    const { data: draft, isLoading, isSuccess } = useDraft(draftId);

    if (isLoading) {
        return false;
    }

    if (isSuccess) {
        return !isXML(draft) && canCreateAccountingLines(draft.reasonId, draft);
    }

    return false;
};

export const useCanConfirmDraft = (draftId: string) => {
    const {
        data: draft,
        isFetching: isFetchingDraft,
        dataUpdatedAt: dataUpdatedAtDraft
    } = useDraft(draftId);
    const {
        data: draftStatusFromAccountingLines,
        isFetching: isFetchingAccountingLines,
        dataUpdatedAt: dataUpdatedAtAccountingLines
    } = useAccountingLinesDraftStatus({ draftId });
    const fs = useFormattedStringWithScope("commons.draftStatusMessage");

    if (isFetchingDraft && isFetchingAccountingLines) {
        return { canConfirmDraft: false };
    }

    const latestDraftStatus = isAfter(
        dataUpdatedAtDraft,
        dataUpdatedAtAccountingLines
    )
        ? draft?.draftStatus
        : draftStatusFromAccountingLines;

    if (!latestDraftStatus) {
        return { canConfirmDraft: false };
    }

    const canConfirmDraft = latestDraftStatus.isConfirmable;
    const message = canConfirmDraft
        ? undefined
        : fs(latestDraftStatus.messageCode);

    return { canConfirmDraft, message };
};
