import Typography from "@vapor/react-extended/ExtendedTypography";
import { DialogActions, Stack, Table } from "@vapor/react-material";

import { useAccountingPeriodClosureSimulation } from "../../../../core/domain/CloseAndReopenAccount/queries";
import { AccountingPeriodDto } from "../../../../core/usecases/dtos/AccountingPeriodsDto";
import { TaskStatus } from "../../../../core/usecases/dtos/TaskStatus";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../../components/CustomButton";
import TaskStatusAlertBanner from "../TaskStatusAlertBanner";
import { CloseAndReopenAccountsHeader } from "./CloseAndReopenAccountsHeader";
import { CloseAndReopenAccountTableHead } from "./CloseAndReopenAccountTableHead";

interface CloseAndReopenAccountsEmptyProps {
    workspaceId: string;
    selectedAccountingPeriod: AccountingPeriodDto;
    statusTask: TaskStatus;
    onClose: () => void;
}

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.CloseAndReopenAccountsOverlay"
);

export default function CloseAndReopenAccountsEmpty({
    workspaceId,
    selectedAccountingPeriod,
    onClose,

    statusTask
}: CloseAndReopenAccountsEmptyProps) {
    const {
        mutateAsync: fetchSimulatePeriod,
        isPending: simulatePeriodLoading
    } = useAccountingPeriodClosureSimulation(selectedAccountingPeriod.id);

    const handleSimulatePeriod = async () => {
        await fetchSimulatePeriod({
            workspaceId,
            accountingPeriodId: selectedAccountingPeriod.id
        });

        onClose();
    };
    return (
        <>
            <CloseAndReopenAccountsHeader
                selectedAccountingPeriod={selectedAccountingPeriod}
                report={undefined}
            />
            <TaskStatusAlertBanner status={statusTask} />
            <Stack gap={4} marginBottom={2} marginTop={2}>
                <Typography variant="h6" color="primary.textTitleColor">
                    {fs("title")}
                </Typography>

                <Table>
                    <CloseAndReopenAccountTableHead />
                </Table>
            </Stack>
            <DialogActions
                sx={{
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                    width: "100%"
                }}
            >
                {(statusTask === TaskStatus.TODO ||
                    TaskStatus.SIMULATION_ERROR) && (
                    <CustomButton
                        onClick={handleSimulatePeriod}
                        loading={simulatePeriodLoading}
                        disabled={simulatePeriodLoading}
                        color="primary"
                        variant="contained"
                    >
                        {fs("generateSimulationPeriod")}
                    </CustomButton>
                )}
                {statusTask === TaskStatus.REOPENING && (
                    <CustomButton
                        color="primary"
                        variant="contained"
                        disabled={true}
                        loading={true}
                    >
                        {fs("openPeriod")}
                    </CustomButton>
                )}
            </DialogActions>
        </>
    );
}
