import { isEmpty, isNil, uniqBy } from "lodash";

import { ListAssetsResponse } from "../dtos/ListAssetsDto";

export function concatenateData(
    data: ListAssetsResponse,
    moreData?: ListAssetsResponse
): ListAssetsResponse {
    return isNil(moreData) || isEmpty(data)
        ? data
        : {
              ...data,
              _embedded: {
                  ...data._embedded,
                  elements: uniqBy(
                      data._embedded.elements.concat(
                          moreData._embedded.elements
                      ),
                      "id"
                  )
              }
          };
}
