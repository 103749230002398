import { useGet } from "@onefront/react-sdk";
import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";

import { AssetRelationsDto } from "../dtos/AssetRelationsDto";

interface UseGetAssetRelations {
    loading: boolean;
    error: any;
    data: AssetRelationsDto | null;
    fetch: Function;
    hasLoaded: boolean;
}

interface UseGetAssetRelationsOptions {
    accountCode?: string;
    lazy?: boolean;
}

export const useGetAssetRelations = ({
    accountCode,
    lazy
}: UseGetAssetRelationsOptions): UseGetAssetRelations => {
    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/accounting/relations/assets/accounts?accountCode=${accountCode}`,
        {
            ...useAxiosConfigRequestParams("AssetRelations"),
            params: omitNilProperties({
                ...useCustomerData()
            }),
            lazy: lazy
        }
    );

    return {
        fetch: fetch,
        loading: loading,
        data: data,
        error: error,
        hasLoaded: hasLoaded
    };
};
