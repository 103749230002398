import Typography from "@vapor/react-extended/ExtendedTypography";
import { Table as VaporTable } from "@vapor/react-material/Table";
import { TableBody } from "@vapor/react-material/TableBody";
import { TableCell } from "@vapor/react-material/TableCell";
import { TableHead } from "@vapor/react-material/TableHead";
import { TableRow } from "@vapor/react-material/TableRow";
import classNames from "classnames";
import { get, range } from "lodash";
import { v4 } from "uuid";

import "./style.css";

export enum ColumnType {
    text = "text",
    numeric = "numeric",
    cta = "cta"
}

interface HeadCell {
    id: string;
    kind: ColumnType;
    label: string;
}

interface ColumnElement {
    kind?: ColumnType;
    render: (datum: any) => JSX.Element;
}

interface HierarchyTableProps {
    data: any[];
    parentColumns: ColumnElement[];
    parentRowKey: string;
    childrenColumns: ColumnElement[];
    childrenRowKey: string;
    numRows?: number;
    headCells: HeadCell[];
    footer?: any[];
}

export default function HierarchyTable({
    headCells,
    data,
    parentColumns,
    parentRowKey,
    childrenColumns,
    childrenRowKey,
    numRows,
    footer
}: HierarchyTableProps) {
    const wrappedFooter = footer && footer[0]?.length ? footer : [footer];

    const numOfRenderedRows = data
        .map((datum) => get(datum, childrenRowKey).length + 1)
        .reduce((a, b) => a + b, 0);

    const getTableCellAlignment = (cell: HeadCell | ColumnElement) => {
        switch (cell.kind) {
            case ColumnType.numeric:
                return "right";
            case ColumnType.cta:
                return "center";
            default:
                return "left";
        }
    };

    const renderCell = (column: ColumnElement, datum: any) => {
        return (
            <TableCell
                // TableCells must have the size prop set
                // (in this case, to "small") to have a fixed height
                size="small"
                padding="none"
                key={v4()}
                align={getTableCellAlignment(column)}
                classes={{
                    sizeSmall: classNames(
                        "c-HierarchyTable-body-cell",
                        column.kind === ColumnType.cta ? "cta" : undefined
                    )
                }}
            >
                {column.render(datum)}
            </TableCell>
        );
    };

    return (
        <VaporTable
            sx={{
                margin: "16px"
            }}
        >
            <TableHead shadow>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id + v4()}
                            align={getTableCellAlignment(headCell)}
                        >
                            <Typography
                                variant="boldLabel"
                                color="primary.textTitleColor"
                            >
                                {headCell.label}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data
                    .map((datum) => (
                        <>
                            <TableRow key={v4()}>
                                {parentColumns.map((parentColumn) =>
                                    renderCell(
                                        parentColumn,
                                        get(datum, parentRowKey)
                                    )
                                )}
                            </TableRow>
                            {get(datum, childrenRowKey).map(
                                (childDatum: any) => (
                                    <TableRow key={v4()}>
                                        {childrenColumns.map((childrenColumn) =>
                                            renderCell(
                                                childrenColumn,
                                                childDatum
                                            )
                                        )}
                                    </TableRow>
                                )
                            )}
                        </>
                    ))
                    // insert filler rows if numRows exceeds the
                    // number of rendered rows
                    .concat(
                        numRows
                            ? range(numRows - numOfRenderedRows).map((_) => (
                                  <TableRow key={v4()}>
                                      {childrenColumns.map((_) => (
                                          <TableCell
                                              size="small"
                                              key={v4()}
                                              classes={{
                                                  sizeSmall:
                                                      "c-HierarchyTable-body-cell"
                                              }}
                                          />
                                      ))}
                                  </TableRow>
                              ))
                            : []
                    )
                    .concat(
                        wrappedFooter?.map((footerRow) => (
                            <TableRow key={v4()}>
                                {footerRow.map((footerElement: any) => (
                                    <TableCell
                                        size="small"
                                        padding="none"
                                        key={v4()}
                                        align={getTableCellAlignment(
                                            footerElement
                                        )}
                                        classes={{
                                            sizeSmall:
                                                "c-HierarchyTable-body-cell"
                                        }}
                                    >
                                        {footerElement.render()}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )) ?? []
                    )}
            </TableBody>
        </VaporTable>
    );
}
