import { groupBy, isNaN } from "lodash";

import {
    AccountDto,
    AccountsTotalDto,
    BalanceDto,
    GroupedAccountDto,
    GroupedBalanceDto
} from "../dtos/BalanceDto";

export default function groupBalanceRows(
    balance: BalanceDto
): GroupedBalanceDto {
    return {
        balanceSheet: {
            credit: {
                accounts: groupByAccountId(balance.balanceSheet.credit),
                totalAmount:
                    balance.balanceSheet.credit.totalAmount ||
                    balance.balanceSheet.credit.total!,
                totalSuspense: balance.balanceSheet.credit.totalSuspense,
                totalValue:
                    balance.balanceSheet.credit.totalAmount +
                        balance.balanceSheet.credit.totalSuspense ||
                    balance.balanceSheet.credit.total!
            },
            debit: {
                accounts: groupByAccountId(balance.balanceSheet.debit),
                totalAmount:
                    balance.balanceSheet.debit.totalAmount ||
                    balance.balanceSheet.debit.total!,
                totalSuspense: balance.balanceSheet.debit.totalSuspense,
                totalValue:
                    balance.balanceSheet.debit.totalAmount +
                        balance.balanceSheet.debit.totalSuspense ||
                    balance.balanceSheet.debit.total!
            }
        },
        incomeStatement: {
            credit: {
                accounts: groupByAccountId(balance.incomeStatement.credit),
                totalAmount:
                    balance.incomeStatement.credit.totalAmount ||
                    balance.incomeStatement.credit.total!,
                totalSuspense: balance.incomeStatement.credit.totalSuspense,
                totalValue:
                    balance.incomeStatement.credit.totalAmount +
                        balance.incomeStatement.credit.totalSuspense ||
                    balance.incomeStatement.credit.total!
            },
            debit: {
                accounts: groupByAccountId(balance.incomeStatement.debit),
                totalAmount:
                    balance.incomeStatement.debit.totalAmount ||
                    balance.incomeStatement.debit.total!,
                totalSuspense: balance.incomeStatement.debit.totalSuspense,
                totalValue:
                    balance.incomeStatement.debit.totalAmount +
                        balance.incomeStatement.debit.totalSuspense ||
                    balance.incomeStatement.debit.total!
            }
        }
    };
}

function computeTotalForAccount(account: AccountDto): GroupedAccountDto {
    const accountValue = account.amount + account.suspense;
    return {
        ...account,
        value: isNaN(accountValue) ? account.value! : accountValue,
        amount: isNaN(accountValue) ? account.value! : account.amount
    };
}

function groupByAccountId(creditOrDebit: AccountsTotalDto) {
    if (!creditOrDebit.accounts) {
        return [];
    }

    // the API also returns some accounts that have accountId like "01",
    // which may be the parent of the parent accounts, but we don't have to
    // show them or consider them in our calculations, so it is safe
    // to just ignore them
    const creditOrDebitWithoutNamingRows = creditOrDebit.accounts.filter(
        (account) => account.accountId.length > 2
    );
    const groupedRows = groupBy(
        creditOrDebitWithoutNamingRows,
        (accountEntry) => accountEntry.accountId.substring(0, 5)
    );

    return Object.keys(groupedRows).map((rowKey) => {
        const groupedRow = groupedRows[rowKey];
        const parentAccount = groupedRow.filter(
            (row) => row.accountId.length < 6
        )[0];
        const childrenAccounts = groupedRow.filter(
            (row) => row.accountId.length >= 6
        );
        return {
            parentAccount: computeTotalForAccount(parentAccount),
            accounts: childrenAccounts.map(computeTotalForAccount)
        };
    });
}
