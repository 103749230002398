import { DialogProps } from "@mui/material/Dialog";
import { Dialog } from "@vapor/react-material";
import omit from "lodash/omit";

interface OverlayDialogProps extends DialogProps {}

export default function OverlayDialog(props: OverlayDialogProps) {
    return (
        <Dialog
            {...omit(props, "sx", "maxWidth")}
            maxWidth={false}
            sx={{
                "& .MuiDialog-container": {
                    width: "100vw",
                    height: "100vh"
                },
                "& .MuiPaper-root": {
                    margin: 0,
                    maxWidth: "none",
                    maxHeight: "none",
                    width: "100%",
                    height: "100%"
                }
            }}
        >
            {props.children}
        </Dialog>
    );
}
