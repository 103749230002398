import React, { ReactNode, useContext } from "react";

import { WithholdingType } from "../../../utils/appEnums";
import { DraftWithholdingDto } from "../../usecases/dtos/DraftWithholdingDto";
import { WithholdingDetails } from "../../usecases/dtos/WithholdingDetailsDto";
import { WithholdingParametersDto } from "../../usecases/dtos/WithholdingParametersDto";
import { Withholding } from "../../usecases/dtos/WithholdingsDto";
import { useGetDraftWithholdings } from "../../usecases/useGetDraftWithholdings";
import { useGetWithholdingDetails } from "../../usecases/useGetWithholdingDetails";
import {
    UseGetWithholdingWithParameters,
    useGetWithholdingWithParameters
} from "../../usecases/useGetWithholdingWithParameters";
import { useGetWithholdings } from "../../usecases/useGetWithholdings";
import {
    PatchData,
    usePatchDraftWithholding
} from "../../usecases/usePatchDraftWithholding";
import { useGetDraftWithholding } from "../../usecases/useGetDraftWithholding";

interface WithholdingsContextType {
    draft: {
        withholdings: DraftWithholdingDto[];
        get: (draftId: string) => Promise<DraftWithholdingDto[]>;
        getDetail: (
            draftId: string,
            id: string
        ) => Promise<DraftWithholdingDto>;
        detail: DraftWithholdingDto | null;
        getDetailSimulation: UseGetWithholdingWithParameters["get"];
        detailSimulation: WithholdingParametersDto | null;
        isLoading: boolean;
        patch: (
            draftId: string,
            withholdingId: string,
            data: PatchData
        ) => Promise<void>;
        isPatching: boolean;
    };
    accounting: {
        withholdings: Withholding[];
        get: (status: WithholdingType) => Promise<Withholding[]>;
        getDetail: (
            status: WithholdingType,
            id: string
        ) => Promise<WithholdingDetails>;
        withholdingDetails: WithholdingDetails | null;
        isLoading: boolean;
        isLoadingDetail: boolean;
        errorLoadingDetail: Error | null;
    };
}

interface WithholdingsProviderProps {
    children: ReactNode[] | ReactNode;
}

const WithholdingsContext = React.createContext<WithholdingsContextType>({
    draft: {
        withholdings: [],
        get: () => Promise.resolve([]),
        isLoading: false,
        patch: () => Promise.resolve(),
        isPatching: false,
        getDetail: () => Promise.resolve({} as DraftWithholdingDto),
        detail: {} as DraftWithholdingDto,
        getDetailSimulation: () =>
            Promise.resolve({} as WithholdingParametersDto),
        detailSimulation: {} as WithholdingParametersDto
    },
    accounting: {
        withholdings: [],
        get: () => Promise.resolve([]),
        getDetail: () => Promise.resolve({} as WithholdingDetails),
        withholdingDetails: {} as WithholdingDetails,
        isLoading: false,
        isLoadingDetail: false,
        errorLoadingDetail: null
    }
});

export default function WithholdingsProvider({
    children
}: WithholdingsProviderProps) {
    // draft
    const useGetDraftWithholdingsHook = useGetDraftWithholdings();
    const usePatchDraftWithholdingHook = usePatchDraftWithholding();
    const useGetWithholdingWithParametersHook =
        useGetWithholdingWithParameters();
    const useGetDraftWithholdingDetailsHook = useGetDraftWithholding();

    // accounting
    const useGetWithholdingsHook = useGetWithholdings();
    const useGetWithholdingDetailsHook = useGetWithholdingDetails();

    return (
        <WithholdingsContext.Provider
            value={{
                draft: {
                    get: useGetDraftWithholdingsHook.get,
                    getDetail: useGetDraftWithholdingDetailsHook.get,
                    patch: usePatchDraftWithholdingHook.patch,
                    getDetailSimulation:
                        useGetWithholdingWithParametersHook.get,
                    detail: useGetDraftWithholdingDetailsHook.withholding,
                    detailSimulation:
                        useGetWithholdingWithParametersHook.parameters,
                    withholdings: useGetDraftWithholdingsHook.withholdings,
                    isLoading:
                        useGetDraftWithholdingsHook.loading ||
                        useGetWithholdingWithParametersHook.loading,
                    isPatching: usePatchDraftWithholdingHook.loading
                },
                accounting: {
                    get: useGetWithholdingsHook.get,
                    isLoading: useGetWithholdingsHook.loading,
                    getDetail:
                        useGetWithholdingDetailsHook.getWithholgingDetails,
                    isLoadingDetail: useGetWithholdingDetailsHook.loading,
                    errorLoadingDetail: useGetWithholdingDetailsHook.error,
                    withholdings: useGetWithholdingsHook.withholdings,
                    withholdingDetails:
                        useGetWithholdingDetailsHook.withholdingDetails
                }
            }}
        >
            {children}
        </WithholdingsContext.Provider>
    );
}

export const useWithholdings = () => {
    return useContext(WithholdingsContext);
};
