import { useGet, useSnackbar } from "@onefront/react-sdk";
import { useCallback, useState } from "react";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { Count } from "../interfaces/Count";
import { convertCount } from "./convertCount";

interface UseGetCount {
    loading: boolean;
    error: any;
    count: Count | null;
    fetch: Function;
    hasFetched: boolean;
    hasResponded: boolean;
}

interface UseGetCountOptions {
    lazy?: boolean;
}

export const useGetCount = ({ lazy }: UseGetCountOptions): UseGetCount => {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.GetCount");

    const [hasResponded, setHasResponded] = useState<boolean>(false);

    const {
        fetch: fetchData,
        data,
        loading,
        error,
        hasLoaded
    } = useGet(`${API_URL}/v1/precog/count`, {
        ...useAxiosConfigRequestParams("GetCount"),
        onResponse: () => setHasResponded(true),
        onError: () => {
            enqueueSnackbar(fs("error"), {
                variant: "error"
            });
            setHasResponded(true);
        },
        params: omitNilProperties({
            ...useCustomerData()
        }),
        lazy: lazy
    });

    const fetch = useCallback(() => {
        setHasResponded(false);
        fetchData();
    }, [fetchData]);

    return {
        fetch,
        loading: loading,
        error: error,
        count: data ? convertCount(data) : null,
        hasFetched: hasLoaded,
        hasResponded
    };
};
