import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack } from "@vapor/react-material";
import { Dialog } from "@vapor/react-material/Dialog";
import { DialogActions } from "@vapor/react-material/DialogActions";
import { DialogContent } from "@vapor/react-material/DialogContent";
import { DialogContentText } from "@vapor/react-material/DialogContentText";
import { DialogTitle } from "@vapor/react-material/DialogTitle";
import { Divider } from "@vapor/react-material/Divider";
import { IconButton } from "@vapor/react-material/IconButton";

import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../CustomButton";

interface CloseClonedDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onNotSave: () => void;
    onCofirmModifications: () => void;
}

const fs = getFormattedStringWithScope(
    "components.Accounting.CloseClonedDialog"
);

const CloseClonedDialog = ({
    isOpen,
    onClose,
    onNotSave,
    onCofirmModifications
}: CloseClonedDialogProps) => {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>
                {fs("title")}
                <IconButton color="primary" onClick={onClose}>
                    <FontAwesomeIcon icon={faClose} />
                </IconButton>
            </DialogTitle>
            <Divider variant="fullWidth" />
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Stack>
                        <Typography variant="body">
                            {fs("closingWithoutSaving")}
                        </Typography>
                        <Typography variant="body">
                            {fs("modificationWillBeLostUnlessSaved")}
                        </Typography>
                    </Stack>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButton onClick={onNotSave} variant="outlined">
                    {fs("doNotSave")}
                </CustomButton>
                <CustomButton
                    onClick={onCofirmModifications}
                    variant="contained"
                >
                    {fs("confirmModifications")}
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

export default CloseClonedDialog;
