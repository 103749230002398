import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";
import Divider from "@vapor/react-material/Divider";
import Typography from "@vapor/react-extended/ExtendedTypography";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import Button from "@vapor/react-material/Button";
import { InputLabel, TextareaAutosize } from "@vapor/react-material";
import { Spinner } from "@comic/precog-components";

import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";
import getFormattedDate from "../../../../../../../utils/getFormattedDate";
import DrawerDatePicker from "../../../../../../components/DrawerDatePicker";
import { AssetLogsDto } from "../../../../../../../core/usecases/dtos/AssetLogsDto";
import { IPostData } from "../../../../../../../core/usecases/usePostAssetSaleSimulation";
import PercentageField from "../../../../../../components/PercentageField";
import BoldAmountWithLabel from "../../../../../../components/BoldAmountWithLabel";
import { Elements } from "../../../../../../../core/usecases/dtos/ListAssetsDto";

const fs = getFormattedStringWithScope(
    "views.ListAssets.movementDrawer.eliminationSubsection"
);

interface SimulationSalePageProps {
    logs: AssetLogsDto | null;
    onClickNext: () => void;
    dataToPost: IPostData;
    isLoading: boolean;
    asset: Elements | null;
    simulationData: any;
    setDataToPost: Function;
    setHasFetched: Function;
}

export default function SimulationSalePage({
    logs,
    onClickNext,
    dataToPost,
    isLoading,
    asset,
    simulationData,
    setDataToPost,
    setHasFetched
}: SimulationSalePageProps) {
    const handleDateChange = (newDate: Date) => {
        setDataToPost({ ...dataToPost, date: newDate });
        setHasFetched(false);
    };

    const handleRateChange = (disposalRate: number) => {
        setDataToPost({ ...dataToPost, disposalRate: disposalRate });
        setHasFetched(false);
    };

    return (
        <>
            <Box margin={5}>
                <Stack direction="row" width={"60%"}>
                    <Box marginRight={5}>
                        <DrawerDatePicker
                            label={fs("elimination")}
                            date={dataToPost?.date}
                            onChangeDate={(date: Date) =>
                                handleDateChange(date)
                            }
                            showIcon={false}
                        />
                    </Box>
                    <Box width={"50%"}>
                        <InputLabel required>
                            {fs("eliminationPercent")}
                        </InputLabel>
                        <PercentageField
                            value={
                                dataToPost.disposalRate
                                    ? dataToPost.disposalRate
                                    : 100
                            }
                            onChange={(disposalRate: number | null) => {
                                if (disposalRate) {
                                    handleRateChange(disposalRate);
                                }
                            }}
                        />
                    </Box>
                </Stack>
                {isLoading ? (
                    <Spinner loading={isLoading}>
                        <Box marginTop={10} height={150}></Box>
                    </Spinner>
                ) : (
                    <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        justifyItems={"flex-end"}
                    >
                        <Stack direction={"row"} marginLeft={7} height="220px">
                            <Stack
                                marginTop={4.5}
                                marginRight={4}
                                alignItems={"flex-end"}
                            >
                                <BoldAmountWithLabel
                                    label={fs("existingAssetValue")}
                                    amount={asset?.amount ?? 0}
                                    currency={"EUR"}
                                />
                            </Stack>
                            <Divider
                                sx={{
                                    marginTop: 4.5,
                                    marginBottom: 4.5
                                }}
                                orientation="vertical"
                                flexItem
                            />
                        </Stack>
                        <Stack direction={"row"}>
                            <Stack
                                justifyContent={"space-around"}
                                paddingLeft="10px"
                                height="220px"
                            >
                                <Stack alignItems={"flex-end"}>
                                    <BoldAmountWithLabel
                                        label={fs("depreciationDuring")}
                                        amount={
                                            asset?.accumulatedDepreciation ?? 0
                                        }
                                        currency={"EUR"}
                                    />
                                </Stack>
                                <Stack alignItems={"flex-end"}>
                                    <BoldAmountWithLabel
                                        label={fs("taxLoss")}
                                        amount={
                                            simulationData?.taxCapitalLoss ?? 0
                                        }
                                        currency={"EUR"}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack
                            justifyContent={"space-around"}
                            paddingLeft="10px"
                            height="220px"
                        >
                            <Stack alignItems={"flex-end"}>
                                <BoldAmountWithLabel
                                    label={
                                        fs("residualAt") +
                                        " " +
                                        getFormattedDate(
                                            logs?.residualReferenceDate || ""
                                        )
                                    }
                                    amount={
                                        asset
                                            ? asset?.amount -
                                              asset?.accumulatedDepreciation
                                            : 0
                                    }
                                    currency={"EUR"}
                                />
                            </Stack>
                            <Stack alignItems={"flex-end"}>
                                <BoldAmountWithLabel
                                    label={fs("civilLoss")}
                                    amount={
                                        simulationData?.statutoryCapitalLoss ??
                                        0
                                    }
                                    currency={"EUR"}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                )}
                <Stack>
                    <Typography variant="body500">
                        {fs("description")}
                    </Typography>
                    <TextareaAutosize
                        minRows={5}
                        placeholder={fs("operationDescription")}
                        value={
                            dataToPost.operationDescription
                                ? dataToPost.operationDescription
                                : ""
                        }
                        onChange={(event) => {
                            if (event.target.value)
                                setDataToPost({
                                    ...dataToPost,
                                    operationDescription: event.target.value
                                });
                        }}
                    />
                </Stack>
            </Box>
            <Box>
                <VaporToolbar
                    variant="surface"
                    withoutAppBar={false}
                    contentRight={
                        <>
                            <Button
                                onClick={onClickNext}
                                variant="contained"
                                disabled={
                                    !dataToPost.date || !dataToPost.disposalRate
                                }
                            >
                                {fs("drawerButton")}
                            </Button>
                        </>
                    }
                />
            </Box>
        </>
    );
}
