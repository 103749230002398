import { faAddressCard } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    Button,
    CircularProgress,
    Stack,
    useTheme
} from "@vapor/react-material";
import { DatePicker } from "@vapor/react-x-date-pickers";
import { useState } from "react";
import usePostOnboarding from "../../../core/usecases/usePostOnboarding";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import { OnboardingStepContract } from "./OnboardingStepContract";

export default function OnboardingStepCommencementDate({
    onCompletedStep,
    onboardingStepStatus
}: OnboardingStepContract) {
    const fs = useFormattedStringWithScope("components.Onboarding");
    const theme = useTheme();
    const [commencementDate, setCommencementDate] = useState<Date>(new Date());

    const usePostOnboardingUseCase = usePostOnboarding({
        commencementDate: commencementDate
    });

    return (
        <Stack>
            {onboardingStepStatus === "TODO" && (
                <Stack
                    alignSelf="center"
                    direction="row"
                    m={8}
                    spacing={4}
                    width="33%"
                >
                    <Stack>
                        <FontAwesomeIcon
                            color={theme.palette.primary.textDisabledColor}
                            icon={faAddressCard}
                            size="4x"
                        />
                    </Stack>
                    <Stack spacing={1}>
                        <Typography
                            color={theme.palette.primary.yankeesBlue}
                            variant="titleSmall"
                        >
                            {fs("steps.commencementDate.title")}
                        </Typography>

                        <Box>
                            <DatePicker
                                label={fs(
                                    "steps.commencementDate.datePicker.label"
                                )}
                                onChange={(newDate) =>
                                    setCommencementDate(newDate as Date)
                                }
                                value={commencementDate}
                            />
                        </Box>

                        <Box>
                            <Button
                                disabled={usePostOnboardingUseCase.loading}
                                onClick={async () => {
                                    await usePostOnboardingUseCase.onboard();
                                    onCompletedStep();
                                }}
                                variant="contained"
                            >
                                {fs("steps.commencementDate.buttons.confirm")}
                                {usePostOnboardingUseCase.loading && (
                                    <CircularProgress
                                        color="inherit"
                                        sx={{ ml: 1 }}
                                        size={16}
                                    />
                                )}
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}
