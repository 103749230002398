import { useRequest } from "@onefront/react-sdk";
import { format } from "date-fns";

import { API_DATE_FORMAT, ASYNC_API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { DepreciationSimulationResponseDto } from "../dtos/DepreciationSimulationDto";

export interface GenerateDepreciationOptions {
    date: Date;
    period: string;
    map?: DepreciationMap;
}

export interface DepreciationMap {
    [k: string]: DepreciationConfig;
}

export interface DepreciationConfig {
    percentage: number;
    applicationScope: string;
}

interface UseGenerateDepreciation {
    generateDepreciation: (
        options: GenerateDepreciationOptions
    ) => Promise<DepreciationSimulationResponseDto>;
}

export const useGenerateDepreciation = (): UseGenerateDepreciation => {
    const customerData = useCustomerData();

    const { fetch } = useRequest({
        url: `${ASYNC_API_URL}/asset/depreciation`,
        method: "post",
        ...useAxiosConfigRequestParams("GenerateDepreciation"),
        lazy: true
    });

    const generateDepreciation = ({
        map = {},
        date,
        period
    }: GenerateDepreciationOptions) => {
        return fetch({
            params: {
                ...customerData
            },
            data: {
                workspaceId: customerData.workspaceId,
                period: period,
                date: format(date, API_DATE_FORMAT),
                map
            }
        });
    };

    return {
        generateDepreciation
    };
};
