import { useGet, useSnackbar } from "@onefront/react-sdk";
import format from "date-fns/format";

import { API_URL } from "../../../config";
import { BalanceEntryMode } from "../../../utils/appEnums";
import useFormattedStringWithScope from "../../../utils/useFormattedStringWithScope";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { GroupedBalanceDto } from "../dtos/BalanceDto";
import groupBalanceRows from "./groupBalanceRows";

interface UseGetBalance {
    loading: boolean;
    error: any;
    balance: GroupedBalanceDto | null;
    fetch: Function;
}

interface UseGetBalanceOptions {
    fromDate: Date | null;
    toDate: Date | null;
    requestByCompetence: boolean;
    includeProvisional: boolean;
    lazy?: boolean;
}

export const useGetBalance = ({
    fromDate,
    toDate,
    requestByCompetence,
    includeProvisional,
    lazy
}: UseGetBalanceOptions): UseGetBalance => {
    const { enqueueSnackbar } = useSnackbar();

    const fs = useFormattedStringWithScope("notifications.GetBalance");

    const { fetch, data, loading, error } = useGet(`${API_URL}/v1/ledger`, {
        ...useAxiosConfigRequestParams("Balance"),
        onError: () => enqueueSnackbar(fs("error"), { variant: "error" }),
        params: {
            ...useCustomerData(),
            fromDate: fromDate ? format(fromDate, "yyyy-MM-dd") : undefined,
            toDate: toDate ? format(toDate, "yyyy-MM-dd") : undefined,
            mode: requestByCompetence
                ? BalanceEntryMode.COMPETENCE
                : BalanceEntryMode.ENTRY,
            includeProvisional: includeProvisional
        },
        lazy: lazy
    });

    return {
        fetch: fetch,
        loading: loading,
        error: error,
        balance: data ? groupBalanceRows(data) : null
    };
};
