import {
    CollapsibleRowType,
    ColumnElement,
    ColumnType,
    HeadCell,
    Table
} from "@comic/precog-components";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import { capitalize, isEmpty } from "lodash";

import { VatSettlementDetail } from "../../../../../../../core/usecases/dtos/VatSettlementDto";
import { JournalType } from "../../../../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../../../../components/FormattedAmount";
import AliquotesTable from "./AliquotesTable";

const fs = getFormattedStringWithScope("views.VATSettlement.monoActivity");

interface MonoActivityTableProps {
    data: VatSettlementDetail[];
    onAliquotesDetailClick: Function;
}

export default function MonoActivityTable({
    data,
    onAliquotesDetailClick
}: MonoActivityTableProps) {
    const headCells: HeadCell[] = [
        {
            id: "",
            kind: ColumnType.cta,
            label: ""
        },
        {
            id: "description",
            kind: ColumnType.text,
            label: fs("table.description")
        },
        {
            id: "",
            kind: ColumnType.text,
            label: fs("table.vatRateOrExemption")
        },
        {
            id: "",
            kind: ColumnType.text,
            label: fs("table.vatDescription")
        },
        {
            id: "taxableAmount",
            kind: ColumnType.text,
            label: fs("table.taxable")
        },
        {
            id: "vatAmount",
            kind: ColumnType.numeric,
            label: fs("table.tax")
        },
        {
            id: "totalAmount",
            kind: ColumnType.numeric,
            label: fs("table.total")
        },
        {
            id: "",
            kind: ColumnType.cta,
            label: ""
        }
    ];

    const columns: ColumnElement[] = [
        {
            kind: ColumnType.collapse,
            type: CollapsibleRowType.CHILD_ROWS,
            hasCollapseContent: (detail: VatSettlementDetail) =>
                !isEmpty(detail.aliquotes),
            render: (detail: VatSettlementDetail) => (
                <AliquotesTable
                    journalType={detail.description as JournalType}
                    aliquotes={detail.aliquotes}
                    onAliquotesDetailClick={onAliquotesDetailClick}
                />
            )
        },
        // description
        {
            kind: ColumnType.text,
            render: (detail: VatSettlementDetail) => (
                <Typography>
                    {capitalize(fs(detail.description.toLowerCase()))}
                </Typography>
            )
        },
        // vatRateOrExemption
        {
            kind: ColumnType.empty
        },
        // vatDescription
        {
            kind: ColumnType.empty
        },
        // taxable
        {
            kind: ColumnType.numeric,
            render: (detail: VatSettlementDetail) => (
                <FormattedAmount
                    variant="body500"
                    amount={detail.taxableAmount}
                    currency="EUR"
                />
            )
        },
        // tax
        {
            kind: ColumnType.numeric,
            render: (detail: VatSettlementDetail) => (
                <FormattedAmount
                    variant="body500"
                    amount={detail.vatAmount}
                    currency="EUR"
                />
            )
        },
        // total
        {
            kind: ColumnType.numeric,
            render: (detail: VatSettlementDetail) => (
                <FormattedAmount
                    variant="body500"
                    amount={detail.totalAmount}
                    currency="EUR"
                />
            )
        },
        {
            kind: ColumnType.empty
        }
    ];

    return (
        <Box marginTop="32px">
            <Table headCells={headCells} columns={columns} data={data} />
        </Box>
    );
}
