import {
    faCircleCheck,
    faCircleHalfStroke,
    faCirclePlus,
    faHourglassHalf,
    faSpinner,
    faTriangleExclamation,
    IconDefinition
} from "@fortawesome/pro-regular-svg-icons";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useTheme
} from "@vapor/react-material";
import { difference } from "lodash";
import { useEffect } from "react";
import { useIntl } from "react-intl";

import usePrevious from "../../../../core/commons/hooks/usePrevious";
import {
    useCreateSetupDraft,
    useSetupActions
} from "../../../../core/domain/AccountingSetup/queries";
import {
    PreviousBalance,
    Status
} from "../../../../core/usecases/dtos/ActionsDto";
import { useSnackbar } from "../../../../services/SnackbarService";
import { DraftAccountingStatus } from "../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import { Action, ActionsListDivider } from "../../../components/Action";
import { useAccountingSetup } from "../context";

const fs = getFormattedStringWithScope("views.AccountingSetup");
const fsNotifications = getFormattedStringWithScope(
    "notifications.AccountingSetup.NewRegistration.success"
);

const StatusIconMap: { [key in Status]: IconDefinition } = {
    WAITING: faCircleHalfStroke,
    PROCESS_CONFIRM: faHourglassHalf,
    CONFIRMED: faCircleCheck,
    CLONED: faCircleHalfStroke,
    DEPRECATED: faCircleHalfStroke,
    PROCESS_DELETE: faHourglassHalf,
    PROCESS_UPDATE: faHourglassHalf,
    ERROR: faTriangleExclamation
};

export default function PreviousBalances() {
    const theme = useTheme();
    const { selectAction, action } = useAccountingSetup();
    const { data: actions, isSuccess } = useSetupActions();
    const previousActions: typeof actions = usePrevious(actions);
    const { mutateAsync: create, isPending } = useCreateSetupDraft();
    const { formatDate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const notificationTitle = fsNotifications("title");

    useEffect(() => {
        if (
            previousActions?.PREVIOUS_BALANCES?.registrations?.length &&
            previousActions?.PREVIOUS_BALANCES.registrations.length !==
                actions?.PREVIOUS_BALANCES.registrations.length &&
            isSuccess
        ) {
            const [newElement] = difference(
                actions?.PREVIOUS_BALANCES.registrations,
                previousActions?.PREVIOUS_BALANCES.registrations
            );

            if (
                newElement &&
                newElement.status === DraftAccountingStatus.CONFIRMED
            ) {
                enqueueSnackbar(notificationTitle, { severity: "success" });
            }
        }
    }, [
        actions,
        previousActions,
        enqueueSnackbar,
        isSuccess,
        notificationTitle
    ]);

    if (isSuccess) {
        const { PREVIOUS_BALANCES } = actions || {};
        const preciousBalanceAction =
            action?.type === "PREVIOUS_BALANCES" ? action : null;
        const selectedAction = preciousBalanceAction?.action;

        const handleCreateNewRegistration = async () => {
            await create();
        };

        if (!PREVIOUS_BALANCES) {
            return null;
        }

        const getSubtitle = (action: PreviousBalance) => {
            switch (action.status) {
                case DraftAccountingStatus.ERROR:
                    return fs("registrationError");
                case DraftAccountingStatus.CONFIRMED:
                    return `${fs("confirmedOn")} ${formatDate(
                        action.referenceDate
                    )}`;
                case DraftAccountingStatus.PROCESS_CONFIRM:
                    return fs("processing");
                case DraftAccountingStatus.WAITING:
                    return `${fs("draftOf")} ${formatDate(
                        action.referenceDate
                    )}`;
                default:
                    return undefined;
            }
        };

        return (
            <List disablePadding>
                {PREVIOUS_BALANCES.registrations?.map((action) => {
                    const isRegistrationError = action.status === "ERROR";

                    return (
                        <Action
                            key={action.draftId ?? action.originalEntryId}
                            selected={
                                selectedAction?.draftId === action.draftId &&
                                selectedAction?.originalEntryId ===
                                    action.originalEntryId
                            }
                            onClick={() => {
                                selectAction({
                                    type: "PREVIOUS_BALANCES",
                                    action
                                });
                            }}
                            icon={StatusIconMap[action.status]}
                            title={fs("previousBalance")}
                            subtitle={getSubtitle(action)}
                            error={isRegistrationError}
                            ctaIcon={faArrowRight}
                        />
                    );
                })}
                {PREVIOUS_BALANCES.canCreateNewRegistration && (
                    <>
                        <ListItemButton
                            disabled={isPending}
                            onClick={handleCreateNewRegistration}
                            sx={{
                                backgroundColor: theme.palette.grey["100"]
                            }}
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={isPending ? faSpinner : faCirclePlus}
                                    spin={isPending}
                                    color={theme.palette.primary.primary}
                                />
                            </ListItemIcon>
                            <ListItemText
                                color={theme.palette.primary.primary}
                                primaryTypographyProps={{
                                    color: `${theme.palette.primary.primary} !important`
                                }}
                                primary={fs("newRegistration")}
                            />
                        </ListItemButton>
                        <ActionsListDivider />
                    </>
                )}
            </List>
        );
    }

    return null;
}
