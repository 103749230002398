import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack, TableCell, TableRow } from "@vapor/react-material";
import { capitalize, debounce } from "lodash";

import { useMutateAccountingLine } from "../../../../core/domain/AccountingLines/queries";
import { AccountingLine } from "../../../../core/usecases/dtos/AccountingLinesDto";
import { isCredit, isDebit } from "../../../../utils/tributeUtils";
import AccountDetailButton from "../AccountingLinesTable/AccountDetailButton";
import DeleteButton from "../AccountingLinesTable/DeleteButton";
import { AmountCell, CreditAndDebitCells } from "../AccountingLinesTable/table";
import SelectTribute from "./SelectTribute";
import SelectTributeYear from "./SelectTributeYear";

type F24AmountCellProps = {
    draftId: string;
    accountingLineId: string;
    readOnly?: boolean;
    tributeCode: string;
    type: "debit" | "credit";
};
const F24AmountCell = ({
    draftId,
    accountingLineId,
    readOnly,
    tributeCode,
    type
}: F24AmountCellProps) => {
    if (
        (isDebit(tributeCode) && type === "debit") ||
        (isCredit(tributeCode) && type === "credit")
    ) {
        return (
            <AmountCell
                accountingLinesIds={accountingLineId}
                draftId={draftId}
                type={type}
                readOnly={readOnly}
            />
        );
    }

    return <TableCell></TableCell>;
};

type F24CreditAndDebitCellsProps = {
    draftId: string;
    line: AccountingLine;
    readOnly?: boolean;
};
const F24CreditAndDebitCells = ({
    draftId,
    line,
    readOnly = false
}: F24CreditAndDebitCellsProps) => {
    const accountingLineId = [line.uuid!];
    const isCustomerOrSupplierOrBank =
        line.isCustomer || line.isSupplier || line.isBank;

    // F24 lines with customer, supplier or bank account
    // should always display the amount in the credit column
    // non editable, and the debit column should be empty
    if (isCustomerOrSupplierOrBank) {
        return (
            <CreditAndDebitCells
                draftId={draftId}
                accountingLineIds={accountingLineId}
                readOnly={true}
            />
        );
    } else {
        return (
            <>
                <F24AmountCell
                    draftId={draftId}
                    accountingLineId={line.uuid!}
                    readOnly={readOnly}
                    tributeCode={line.tributeCode ?? ""}
                    type="debit"
                />
                <F24AmountCell
                    draftId={draftId}
                    accountingLineId={line.uuid!}
                    readOnly={readOnly}
                    tributeCode={line.tributeCode ?? ""}
                    type="credit"
                />
            </>
        );
    }
};

interface F24RowProps {
    draftId: string;
    line: AccountingLine;
    readOnly: boolean;
}

export default function F24Row({ draftId, line, readOnly }: F24RowProps) {
    const accountingLineId = line.uuid ? [line.uuid] : [];

    const { mutate: update } = useMutateAccountingLine({
        draftId,
        accountingLineId
    });

    const debouncedUpdate = debounce(update, 400);

    return (
        <TableRow>
            <TableCell size="small" width="10%">
                {line.isCustomer || line.isSupplier ? null : (
                    <SelectTribute
                        draftId={draftId}
                        lineId={line.uuid}
                        value={line.tributeCode ?? null}
                        readOnly={readOnly}
                    />
                )}
            </TableCell>
            <TableCell size="small" width="30%">
                <Typography>{capitalize(line.accountDesc)}</Typography>
            </TableCell>
            <TableCell size="small" width="10%">
                {line.isCustomer || line.isSupplier ? null : (
                    <SelectTributeYear
                        value={
                            line.tributeYear ??
                            new Date().getFullYear().toString()
                        }
                        readOnly={readOnly}
                        onChange={(value) => {
                            debouncedUpdate({
                                tributeYear: value
                            });
                        }}
                    />
                )}
            </TableCell>
            <F24CreditAndDebitCells
                draftId={draftId}
                line={line}
                readOnly={readOnly}
            />
            <TableCell width="5%" align="center" size="small">
                <Stack direction="row">
                    <AccountDetailButton
                        accountCode={line.accountCode}
                        draftId={draftId}
                        readOnly={readOnly}
                    />
                    {!readOnly && (
                        <DeleteButton
                            draftId={draftId}
                            accountingLineId={accountingLineId[0]}
                        />
                    )}
                </Stack>
            </TableCell>
        </TableRow>
    );
}
