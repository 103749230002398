import { useGet } from "@onefront/react-sdk";

import { useEffect, useState } from "react";
import { API_URL } from "../../../config";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { BusinessDto } from "../dtos/BusinessDto";
import { AxiosResponse } from "../interfaces/AxiosResponse";

interface UseGetBusinesses {
    getBusinesses: () => Promise<BusinessDto[]>;
    businesses: BusinessDto[];
    loading: boolean;
}

interface UseGetBusinessesOptions {
    lazy?: boolean;
}

export default function useGetBusinesses(
    { lazy }: UseGetBusinessesOptions = { lazy: false }
): UseGetBusinesses {
    const customerData = useCustomerData();

    const { fetch, data, loading } = useGet(
        `${API_URL}/v1/workspace/businesses`,
        {
            ...useAxiosConfigRequestParams("GetBusinesses"),
            params: {
                ...useCustomerData()
            },
            lazy: lazy
        }
    );

    const getBusinesses = () => {
        return fetch({
            params: {
                ...customerData
            }
        }).then((response: AxiosResponse<BusinessDto[]>) => response.data);
    };

    const [businesses, setBusinesses] = useState<BusinessDto[]>([]);

    useEffect(() => {
        if (data) setBusinesses(data);
        return () => setBusinesses([]);
    }, [data]);

    return {
        businesses: businesses,
        getBusinesses,
        loading: loading
    };
}
