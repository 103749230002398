import Typography from "@vapor/react-extended/ExtendedTypography";
import { TextareaAutosize } from "@vapor/react-material";
import Box from "@vapor/react-material/Box";
import InputLabel from "@vapor/react-material/InputLabel";
import Stack from "@vapor/react-material/Stack";
import { DatePicker } from "@vapor/react-x-date-pickers";
import { useEffect, useState } from "react";

import usePrevious from "../../../core/commons/hooks/usePrevious";
import { AssetInfoResponse } from "../../../core/usecases/dtos/AssetInfoDto";
import {
    DraftAssetPatch,
    usePatchDraftAsset
} from "../../../core/usecases/usePatchDraftAsset";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import CurrencyField from "../../components/CurrencyField";

interface PurchaseIncrementalSpendingProps {
    assetId: string;
    draftId: string | null;
    businessId?: string;
    refCurrency: string | null;
    assetInfo: AssetInfoResponse | null;
    readOnly?: boolean;
}

const fs = getFormattedStringWithScope(
    "views.Detail.assets.drawer.incrementalSpending"
);

const PurchaseIncrementalSpending = ({
    assetId,
    draftId,
    refCurrency,
    assetInfo,
    readOnly
}: PurchaseIncrementalSpendingProps) => {
    const [draftAssetPatch, setDraftAssetPatch] =
        useState<DraftAssetPatch | null>(null);
    const previousDraftAssetPatch = usePrevious(draftAssetPatch);

    const [currentAlias, setCurrentAlias] = useState<string | null>(null);

    const { patchDraftAsset, hasResponded: hasPatchResponded } =
        usePatchDraftAsset({
            assetId: assetId,
            draftId: draftId,
            dataToPatch: draftAssetPatch,
            lazy: true
        });

    useEffect(() => {
        if (
            !!draftAssetPatch &&
            JSON.stringify(previousDraftAssetPatch) !==
                JSON.stringify(draftAssetPatch) &&
            draftId
        ) {
            patchDraftAsset();
            if (hasPatchResponded) {
                setDraftAssetPatch(null);
            }
        }
    }, [
        draftAssetPatch,
        draftId,
        patchDraftAsset,
        previousDraftAssetPatch,
        hasPatchResponded
    ]);
    return (
        <>
            <Stack>
                <Typography
                    color="primary.textTitleColor"
                    variant="bodyLarge500"
                >
                    {" "}
                    {fs("newOperation")}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between !important",
                        width: "90%",
                        marginLeft: "10px"
                    }}
                >
                    <Stack marginTop="17px">
                        <InputLabel>{fs("type")}</InputLabel>
                        {assetInfo?.increaseExistingAsset && (
                            <Typography variant="body">
                                {fs("incrementalSpending")}
                            </Typography>
                        )}
                    </Stack>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        width="90%"
                    >
                        <Stack marginTop="20px">
                            <InputLabel>{fs("purchaseDate")}</InputLabel>
                            {assetInfo && (
                                <DatePicker
                                    readOnly
                                    value={new Date(assetInfo?.operationDate)}
                                    sx={{
                                        width: "300px"
                                    }}
                                />
                            )}
                        </Stack>
                        <Stack marginTop="20px" minWidth="300px">
                            <InputLabel>{fs("value")}</InputLabel>
                            {assetInfo && (
                                <CurrencyField
                                    value={assetInfo?.amount}
                                    readOnly
                                    onChange={() => {}}
                                    currency={refCurrency ?? ""}
                                />
                            )}
                        </Stack>
                    </Box>
                </Box>
                <Box marginLeft="10px" marginTop="25px">
                    <Stack>
                        <InputLabel>{fs("description")}</InputLabel>
                        <TextareaAutosize
                            defaultValue={assetInfo?.alias}
                            placeholder={fs("description")}
                            style={{
                                width: 750
                            }}
                            minRows={3}
                            onChange={(event) => {
                                if (event.target.value)
                                    setCurrentAlias(event.target.value);
                            }}
                            onBlur={() => {
                                if (assetInfo?.alias !== currentAlias)
                                    setDraftAssetPatch({
                                        alias: currentAlias
                                    });
                            }}
                            readOnly={readOnly}
                        />
                    </Stack>
                </Box>
            </Stack>
        </>
    );
};

export default PurchaseIncrementalSpending;
