import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@vapor/react-material";

import { FT_HANDLE_ASSET_DEPRECIATION_SUSPENSION } from "../../../../config";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../../components/FormattedAmount";
import TableHeadCell from "../../../components/TableHead/TableHeadCell";
import TableHeadText from "../../../components/TableHead/TableHeadText";
import { DepreciationData } from "./DepreciationOverlay";
import DepreciationRow from "./DepreciationRow";

interface DepreciationFormProps {
    depreciations: DepreciationData[];
    onChangeDepreciation: (depreciation: DepreciationData) => void;
}

const fs = getFormattedStringWithScope(
    "views.AccountingPeriods.DepreciationForm"
);

export default function DepreciationForm({
    depreciations,
    onChangeDepreciation
}: DepreciationFormProps) {
    const depreciationTotal = depreciations
        .map((depreciation) => depreciation.depreciationTotalValue)
        .reduce((a, b) => a + b, 0);

    const actualDepreciationTotal = depreciations
        .map((depreciation) => depreciation.effectiveDepreciationValue)
        .reduce((a, b) => a + b, 0);

    return (
        <Table>
            <TableHead shadow>
                <TableRow>
                    <TableHeadCell size="small">
                        <TableHeadText>{fs("depreciation")}</TableHeadText>
                    </TableHeadCell>
                    {FT_HANDLE_ASSET_DEPRECIATION_SUSPENSION ? (
                        <>
                            <TableHeadCell size="small"></TableHeadCell>
                            <TableHeadCell size="small" align="right">
                                <TableHeadText>
                                    {fs("suspension")}
                                </TableHeadText>
                            </TableHeadCell>
                        </>
                    ) : null}
                    <TableHeadCell size="small" align="right">
                        <TableHeadText>
                            {fs("actualDepreciation")}
                        </TableHeadText>
                    </TableHeadCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {depreciations.map((depreciation) => (
                    <DepreciationRow
                        key={depreciation.key}
                        isSelected={false}
                        onToggleRow={() => console.log("TODO")}
                        depreciation={depreciation}
                        onChange={onChangeDepreciation}
                    />
                ))}
                <TableRow>
                    <TableCell size="small"></TableCell>
                    {FT_HANDLE_ASSET_DEPRECIATION_SUSPENSION ? (
                        <>
                            <TableCell size="small" align="right">
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                >
                                    <Typography variant="bodyLarge700">
                                        {fs("total")}
                                        <FormattedAmount
                                            marginLeft={1}
                                            variant="bodyLarge700"
                                            amount={depreciationTotal}
                                            currency="EUR"
                                        />
                                    </Typography>
                                </Stack>
                            </TableCell>
                            <TableCell size="small"></TableCell>
                        </>
                    ) : null}
                    <TableCell size="small" align="right">
                        <Stack direction="row" justifyContent="flex-end">
                            <Typography variant="bodyLarge700">
                                {fs("total")}
                                <FormattedAmount
                                    marginLeft={1}
                                    variant="bodyLarge700"
                                    amount={actualDepreciationTotal}
                                    currency="EUR"
                                />
                            </Typography>
                        </Stack>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}
