import { Spinner } from "@comic/precog-components";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import { useEffect, useState } from "react";

import { AsssetLogSequence } from "../../../../../../core/usecases/dtos/AssetLogsSequenceDto";
import { useGetAssetLogsSequence } from "../../../../../../core/usecases/useGetAssetLogsSequence";
import { AssetLogsSequenceOptions } from "../../../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../../../utils/getFormattedStringWithScope";
import ErrorBanner from "../../../../../components/ErrorBanner";
import DepreciationLogsDetails from "./depreciationLogsDetails";
import DisposalLogsDetails from "./disposalLogsDetails";
import PurchaseLogsDetails from "./purchaseLogsDetails";
import RevaluationLogsDetails from "./revaluationLogsDetails";
import SaleLogsDetails from "./saleLogsDetails";

const fs = getFormattedStringWithScope("views.ListAssets.movementDrawer");

interface AssetLogsSequenceProps {
    selectedSequence: string | null;
    selectedAssetId: string | undefined;
    selectedBusinessId: string | undefined;
    selectedChip: string | null;
    showSequenceDrawer: boolean;
    setShowSequenceDrawer: Function;
}

export default function AssetLogsSequence({
    selectedSequence,
    selectedAssetId,
    selectedBusinessId,
    selectedChip,
    showSequenceDrawer,
    setShowSequenceDrawer
}: AssetLogsSequenceProps) {
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);

    const {
        fetch: fetchLogsSequnce,
        data,
        loading,
        error
    } = useGetAssetLogsSequence({
        assetId: selectedAssetId,
        businessId: selectedBusinessId,
        type: selectedChip,
        sequence: selectedSequence,
        lazy: true
    });

    useEffect(() => {
        if (!hasInitialized) {
            fetchLogsSequnce();
            setHasInitialized(true);
        }
    }, [fetchLogsSequnce, hasInitialized]);

    const filteredLogs = data?.assetLogs
        ?.filter(
            (log: AsssetLogSequence) =>
                log?.trackType === selectedChip || log?.trackType === null
        )
        .map((log: AsssetLogSequence) => ({
            ...log,
            trackType: selectedChip
        }));

    return (
        <Box marginTop="11px" marginLeft="45px">
            <Button onClick={() => setShowSequenceDrawer(!showSequenceDrawer)}>
                <Box display="flex">
                    <FontAwesomeIcon
                        style={{ marginTop: "3px", marginRight: "5px" }}
                        icon={faChevronLeft}
                    />
                    <Typography>{fs("backButton")}</Typography>
                </Box>
            </Button>
            {error && <ErrorBanner error={error} />}
            <Spinner loading={loading}>
                {filteredLogs &&
                    [
                        AssetLogsSequenceOptions.PURCHASE,
                        AssetLogsSequenceOptions.INCREASE
                    ].includes(filteredLogs[0]?.type) && (
                        <PurchaseLogsDetails filteredLogs={filteredLogs} />
                    )}
                {filteredLogs &&
                    [AssetLogsSequenceOptions.DEPRECIATION].includes(
                        filteredLogs[0]?.type
                    ) && (
                        <DepreciationLogsDetails filteredLogs={filteredLogs} />
                    )}
                {filteredLogs &&
                    filteredLogs[0]?.type ===
                        AssetLogsSequenceOptions.REVALUATE && (
                        <RevaluationLogsDetails filteredLogs={filteredLogs} />
                    )}
                {filteredLogs &&
                    [
                        AssetLogsSequenceOptions.DISMISS,
                        AssetLogsSequenceOptions.PARTIALLY_DISMISS,
                        AssetLogsSequenceOptions.DECOMISSIONED,
                        AssetLogsSequenceOptions.PARTIALLY_DECOMISSIONED
                    ].includes(filteredLogs[0]?.type) && (
                        <DisposalLogsDetails filteredLogs={filteredLogs} />
                    )}
                {filteredLogs &&
                    [
                        AssetLogsSequenceOptions.SALE,
                        AssetLogsSequenceOptions.PARTIALLY_SALE
                    ].includes(filteredLogs[0]?.type) && (
                        <SaleLogsDetails filteredLogs={filteredLogs} />
                    )}
            </Spinner>
        </Box>
    );
}
