import { AccountingLine } from "../core/usecases/dtos/AccountingLinesDto";
import { PrecogDraftVat } from "../core/usecases/dtos/PrecogDraftVatDto";
import { VatRate } from "./appEnums";

export const NatureGroupForReverseCharge = "N6";

export function computeTaxableAmount(vatLines: PrecogDraftVat[]) {
    return vatLines
        .map((vatLine) => vatLine.taxable)
        .reduce((acc, tax) => acc + tax, 0);
}

export function computeTaxAmount(vatLines: PrecogDraftVat[]) {
    return vatLines
        .map((vatLine) => vatLine.tax)
        .reduce((acc, tax) => acc + tax, 0);
}

export function isReverseCharge(
    accountingLine: AccountingLine,
    isDraftActive: boolean,
    hasReverseCharge?: boolean
) {
    return (
        !isDraftActive &&
        accountingLine.vatRate === VatRate["0.0"] &&
        accountingLine.exemptionNature?.includes(NatureGroupForReverseCharge) &&
        hasReverseCharge
    );
}
