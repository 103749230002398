import Chip from "@vapor/react-material/Chip";
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";

import { BusinessFlatDto } from "../../../../../../../core/usecases/dtos/BusinessFlatDto";
import { SectionalType } from "../../../../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../../../../utils/getFormattedStringWithScope";

const fs = getFormattedStringWithScope(
    "views.VATReport.vatEntries.filter.sectional"
);

interface FilterReportSectionalTypeProps {
    businesses: BusinessFlatDto[] | null;
    sectionalFilter: string;
    onSelectSectional: (value: { id: string; description: string }) => void;
}

const FilterReportSectionalType = ({
    businesses,
    sectionalFilter,
    onSelectSectional
}: FilterReportSectionalTypeProps) => {
    const handleSelectChip = (sectionalFilter: {
        id: string;
        description: string;
    }) => {
        onSelectSectional(sectionalFilter);
    };

    return (
        <Stack direction="row" spacing={1}>
            <Grid container={true} wrap="wrap">
                {businesses && businesses?.length > 1 ? (
                    <Chip
                        key="all"
                        label={fs("all")}
                        sx={{ margin: "8px 4px" }}
                        clickable
                        variant={!sectionalFilter ? "outlined" : "filled"}
                        onClick={() =>
                            handleSelectChip({
                                id: SectionalType.ALL,
                                description: SectionalType.ALL
                            })
                        }
                    />
                ) : null}
                {businesses &&
                    businesses.map((business: BusinessFlatDto) => {
                        return (
                            business.sectionalId && (
                                <Chip
                                    key={business.sectionalId}
                                    label={`${fs("sectional")} ${
                                        business.sectionalDescription
                                    }`}
                                    sx={{ margin: "8px 4px" }}
                                    clickable
                                    variant={
                                        sectionalFilter === business.sectionalId
                                            ? "outlined"
                                            : "filled"
                                    }
                                    onClick={() =>
                                        handleSelectChip({
                                            id: business.sectionalId,
                                            description:
                                                business.sectionalDescription
                                        })
                                    }
                                />
                            )
                        );
                    })}
            </Grid>
        </Stack>
    );
};

export default FilterReportSectionalType;
