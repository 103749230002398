import { useGet } from "@onefront/react-sdk";
import { useCallback } from "react";

import { API_URL } from "../../../config";
import omitNilProperties from "../../../utils/omitNilProperties";
import useAxiosConfigRequestParams from "../../commons/hooks/useAxiosConfigRequestParams";
import useCustomerData from "../../commons/hooks/useCustomerData";
import { UsedGoods } from "../dtos/UsedGoodsDto";

interface UseGetAllUsedGoods {
    loading: boolean;
    error: Error;
    usedGoods: UsedGoods;
    fetch: () => void;
    hasFetched: boolean;
}

export enum Status {
    ALL = "ALL",
    SOLD = "SOLD",
    IN_PLACE = "IN_PLACE"
}

interface UsedGetAllUsedGoodsOptions {
    businessId?: string;
    description?: string | null;
    startDate?: Date | null;
    endDate?: Date | null;
    status?: Status | null;
    supplierId?: string;
    page?: number;
    pageSize?: number;
    lazy?: boolean;
}

export const useGetAllUsedGoods = ({
    businessId,
    description,
    startDate,
    endDate,
    status,
    supplierId,
    page,
    pageSize,
    lazy
}: UsedGetAllUsedGoodsOptions): UseGetAllUsedGoods => {
    const { fetch, data, loading, error, hasLoaded } = useGet(
        `${API_URL}/v1/usedGoods`,
        {
            ...useAxiosConfigRequestParams("GetAllUsedGoods"),
            params: omitNilProperties({
                ...useCustomerData(),
                businessId,
                description: description,
                startDate: startDate,
                endDate: endDate,
                status: status,
                supplierId: supplierId,
                page: page,
                pageSize: pageSize
            }),
            lazy: lazy
        }
    );

    const safeFetch = useCallback(() => fetch(), [fetch]);

    return {
        fetch: safeFetch,
        loading: loading,
        error: error,
        usedGoods: data,
        hasFetched: hasLoaded
    };
};
