import { Accordion } from "@comic/precog-components";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    InputLabel,
    Stack,
    TextField
} from "@vapor/react-material";
import { omit } from "lodash";
import isNil from "lodash/isNil";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { ReasonDto } from "../../../../core/usecases/dtos/ReasonsListDto";
import { Reason, ReasonType } from "../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";

interface ReasonForm {
    reason: string;
}

interface SelectReasonProps {
    onClick: () => void;
    step: number;
    isOpen: boolean;
    reasons: ReasonDto[];
    value: Reason | null;
    onConfirmReasonSelection: (reason: Reason) => void;
    onReset: () => void;
    isLoading: boolean;
    factType?: string;
    active?: boolean;
    disabled?: boolean;
}

const fs = getFormattedStringWithScope("views.NewRegistration.SelectReason");

export default function SelectReason({
    onClick,
    step,
    isOpen,
    reasons,
    value,
    onConfirmReasonSelection,
    onReset,
    isLoading,
    factType,
    active,
    disabled
}: SelectReasonProps) {
    const { control, unregister, reset, handleSubmit } = useForm<ReasonForm>({
        defaultValues: {
            reason: ""
        }
    });

    useEffect(() => {
        return () => {
            unregister("reason");
        };
    }, [unregister]);

    const onSubmit = (data: any) => onConfirmReasonSelection(data.reason);

    const handleReset = () => {
        reset();
        onReset();
    };

    const filteredReasons = reasons.filter((reason) =>
        isNil(factType)
            ? true
            : reason.factType === factType &&
              reason.types.includes(
                  active ? ReasonType.ACTIVE : ReasonType.PASSIVE
              )
    );

    return (
        <Accordion
            onClick={onClick}
            title={`${step}. ${fs("title")}`}
            description={
                reasons.find((reason) => reason.code === value)?.description ??
                ""
            }
            isOpen={isOpen}
            defaultExpanded={isOpen}
        >
            <Typography variant="bodySmall" marginBottom="24px">
                {fs("selectReasonWarning")}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box width="324px">
                    <Controller<ReasonForm>
                        control={control}
                        name="reason"
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                            <FormControl
                                margin="normal"
                                fullWidth
                                error={!isNil(fieldState.error)}
                            >
                                <InputLabel required>
                                    {fs("selectReason")}
                                </InputLabel>
                                <Autocomplete
                                    readOnly={disabled}
                                    value={
                                        filteredReasons.find(
                                            (reason) =>
                                                !isNil(field) &&
                                                reason.code === field.value
                                        ) ?? null
                                    }
                                    options={filteredReasons}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            type="search"
                                            error={!isNil(fieldState.error)}
                                        />
                                    )}
                                    getOptionLabel={(reason: any) =>
                                        (reason as ReasonDto).description ?? ""
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        (option as ReasonDto).code ===
                                        (value as ReasonDto).code
                                    }
                                    onChange={(_: any, reason: any) =>
                                        field.onChange(reason.code)
                                    }
                                    autoComplete={true}
                                    autoHighlight={true}
                                    autoSelect={true}
                                    openOnFocus={true}
                                    {...omit(field, "onChange", "value")}
                                />
                            </FormControl>
                        )}
                    />
                </Box>
                <Stack width="100%" direction="row-reverse" spacing={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <FontAwesomeIcon
                                icon={faCircleNotch}
                                className="fa-spin"
                            />
                        ) : null}
                        {fs("goOn")}
                    </Button>
                    <Button
                        disabled={disabled}
                        variant="outlined"
                        color="secondary"
                        onClick={handleReset}
                    >
                        {fs("cancel")}
                    </Button>
                </Stack>
            </form>
        </Accordion>
    );
}
